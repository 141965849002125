import type { ReactNode } from 'react'
import React from 'react'

import styled from 'styled-components'

interface Props {
  children: ReactNode
}

const StyledTimelineGuideBars = styled.div`
  height: 100%;
  left: 0;
  position: absolute;
  top: 0;
  width: 100%;
  z-index: -1;
`

const TimelineGuideBars = ({ children }: Props) => (
  <StyledTimelineGuideBars>{children}</StyledTimelineGuideBars>
)

export default TimelineGuideBars
