// @flow
import { makeAction } from 'elder/actions'

import { addNamespace } from 'utils'

export const NAMESPACE = 'CARER_BASIC_INFO'

export const UPDATE_BASIC_PROFILE = addNamespace(
  NAMESPACE,
  'UPDATE_BASIC_PROFILE',
)

export const UPDATE_CONTACT_DETAILS = addNamespace(
  NAMESPACE,
  'UPDATE_CONTACT_DETAILS',
)

export const UPDATE_CARER_DETAILS = addNamespace(
  NAMESPACE,
  'UPDATE_CARER_DETAILS',
)

export const UPDATE_PROFILE_PICTURE = addNamespace(
  NAMESPACE,
  'UPDATE_PROFILE_PICTURE',
)

export const REMOVE_PROFILE_PICTURE = addNamespace(
  NAMESPACE,
  'REMOVE_PROFILE_PICTURE',
)

export const SET_BASIC_PROFILE = addNamespace(NAMESPACE, 'SET_BASIC_PROFILE')

export const UPDATE_STATUS = addNamespace(NAMESPACE, 'UPDATE_STATUS')

export const GET_BASIC_INFO = addNamespace(NAMESPACE, 'GET_BASIC_INFO')

export const FETCH_PROFESSIONAL = addNamespace(NAMESPACE, 'FETCH_PROFESSIONAL')

export const UPDATE_WORKER_STATUS = addNamespace(
  NAMESPACE,
  'UPDATE_WORKER_STATUS',
)

export const SET_TESTIMONIAL_STATUS = addNamespace(
  NAMESPACE,
  'SET_TESTIMONIAL_STATUS',
)

export const UPDATE_ACCOUNT_MANAGER = addNamespace(
  NAMESPACE,
  'UPDATE_ACCOUNT_MANAGER',
)

export const updateBasicProfile = (value: Object) => ({
  type: UPDATE_BASIC_PROFILE,
  value,
})

export const updateContactDetails = (value: Object) => ({
  type: UPDATE_CONTACT_DETAILS,
  value,
})

export const updateCarerDetails = (value: Object) => ({
  type: UPDATE_CARER_DETAILS,
  value,
})

export const updateProfilePicture = (picture: Object) => ({
  type: UPDATE_PROFILE_PICTURE,
  picture,
})

export const removeProfilePicture = (value: Object) => ({
  type: REMOVE_PROFILE_PICTURE,
  value,
})

export const setBasicProfile = (value: Object) => ({
  type: SET_BASIC_PROFILE,
  value,
})

export const updateStatus = (status: string) => ({
  type: UPDATE_STATUS,
  status,
})

export const getBasicInfo = makeAction(GET_BASIC_INFO, (carerId: string) => ({
  carerId,
}))

export const fetchProfessional = makeAction(
  FETCH_PROFESSIONAL,
  (carerId: string) => ({
    carerId,
  }),
)

export const setStatus = (value: Object) => ({
  type: SET_TESTIMONIAL_STATUS,
  value,
})

export const updateWorkerStatus = (workerStatus: string) => ({
  type: UPDATE_WORKER_STATUS,
  workerStatus,
})

export const updateAccountManager = makeAction(
  UPDATE_ACCOUNT_MANAGER,
  (accountManagerEmail: ?string) => ({
    accountManagerEmail,
  }),
)
