// @flow
import * as Sentry from '@sentry/react'
import type { Saga } from 'redux-saga'

import { call, select, takeLatest, put } from 'elder/effects'

import { carerBasicInfoClient } from 'app/saga/serviceClients'
import {
  updateAccountManager,
  fetchProfessional,
  NAMESPACE,
} from 'features/carerBasicInfo/store/actions'
import { getId } from 'features/carerDetailsHeader/store/selectors'
import {
  gettingCarer,
  setCarer,
} from 'features/carerPageGenerics/store/actions'
import { clearLoading, setLoading } from 'features/loading/actions'
import {
  applicationError,
  applicationSuccess,
} from 'features/snackbar/snackbar'
import { describeServiceError } from 'utils/services'

export function* handleUpdateAccountManager({
  accountManagerEmail,
}: {
  +accountManagerEmail: ?string,
}): Saga<*> {
  const carerId = yield* select(getId)

  try {
    yield* put(setLoading(NAMESPACE))

    const carerBasicInfo = yield* call(
      carerBasicInfoClient.updateAccountManager,
      {
        carerId,
        accountManagerEmail,
      },
    )

    yield* put(setCarer(carerBasicInfo))
    applicationSuccess({
      title: 'Account Manager updated',
      message: 'Carer Account Manager was updated successfully.',
    })
  } catch (error) {
    applicationError(
      describeServiceError(error, 'Failed to update Account Manager'),
    )
  } finally {
    yield* put(clearLoading(NAMESPACE))
  }
}

function* handleGetProfessional({ carerId }): Saga<*> {
  yield* put(gettingCarer())
  try {
    Sentry.addBreadcrumb({ message: 'Getting carer overview' })
    const response = yield* call(carerBasicInfoClient.getProfessional, carerId)
    yield* put(setCarer(response))
    Sentry.addBreadcrumb({ message: 'Successfully retrieved carer overview' })
  } catch (error) {
    applicationError(
      describeServiceError(error, 'Failed to get carer overview'),
    )
    Sentry.addBreadcrumb({ message: 'Failed to get carer overview' })
  }
}

export function* carerBasicInfoSaga(): Saga<*> {
  yield* takeLatest(updateAccountManager, handleUpdateAccountManager)
  yield* takeLatest(fetchProfessional, handleGetProfessional)
}
