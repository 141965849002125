// @flow
import { replace } from 'connected-react-router'
import type { Saga } from 'redux-saga'
import { take, call, put } from 'redux-saga/effects'

import {
  applicationError,
  applicationSuccess,
} from 'features/snackbar/snackbar'
import { fetchFlow, describeServiceError } from 'utils/services'

import { CREATE } from './actions'

export function* postCreateAccount(): Generator<Object, void, Object> {
  const endpoint = '/et/accounts/'
  const method = 'POST'

  try {
    const responseBody = yield call(fetchFlow, endpoint, method, {})
    const { accountId } = responseBody
    applicationSuccess({
      title: 'Account created',
      message: 'Account was created successfully.',
    })
    yield put(replace(`/accounts/${accountId}`))
  } catch (error) {
    applicationError(describeServiceError(error))
  }
}

export function* watchCreateAccount(): Saga<*> {
  while (true) {
    yield take(CREATE)
    yield call(postCreateAccount)
  }
}

export default watchCreateAccount
