import { colors } from '@elder/common'
import { Form } from 'formik'
import styled from 'styled-components'

// Override global input styles
export const StyledFormForGlobalOverride = styled(Form)`
  input[type='text'] {
    color: initial;
    border-radius: 4px;
    border: 1px solid ${colors.lightSmoke};
    &::placeholder {
      color: ${colors.lightSmoke};
      text-transform: none;
    }
  }
  textarea::placeholder {
    color: ${colors.lightSmoke};
    text-transform: none;
  }
`
