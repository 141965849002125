// @flow
import React from 'react'

import { colors } from '@elder/common'
import { WarningOutlined } from '@mui/icons-material'
import styled from 'styled-components'

import { AccountEntry } from 'components/styledComponentsTranslatedClasses/AccountEntry'
import { AccountSection } from 'components/styledComponentsTranslatedClasses/AccountSection'
import { SectionHeader } from 'components/styledComponentsTranslatedClasses/SectionHeader'
import type { Account } from 'routes/account/summary/Summary/props'

const Row = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`

const Notification = styled.div`
  display: flex;
  align-items: center;
  color: ${colors.errorDark};
`

const StyledWarning = styled(WarningOutlined)`
  margin-right: 8px;
`

type Props = {|
  +account: Account,
|}

const CareEnvironment = ({ account }: Props) => (
  <AccountSection>
    <SectionHeader>
      <h3>Care Environment</h3>
    </SectionHeader>
    {account.prettyGenderPreference &&
      account.prettyGenderPreference.length > 0 && (
        <AccountEntry>
          <span>Gender requirement:</span>
          <div>{account.prettyGenderPreference}</div>
        </AccountEntry>
      )}
    <AccountEntry>
      <span>Placement requirements:</span>
      <div>
        {account.prettyPlacementRequirements.map((value, index) => (
          // eslint-disable-next-line react/no-array-index-key
          <div key={index.toString()}>{value}</div>
        ))}
      </div>
    </AccountEntry>
    <AccountEntry>
      <span>Matching notes</span>
      <Row>
        <p>{account.matchingNotes}</p>
      </Row>
    </AccountEntry>
    <AccountEntry>
      <span>Care Location:</span>
      <Row>
        <div>
          {account.careLocation.map((value, index) => (
            // eslint-disable-next-line react/no-array-index-key
            <div key={index.toString()}>{value}</div>
          ))}
        </div>
        {account.postcode && !account.latLong && (
          <Notification>
            <StyledWarning />
            Invalid postcode
          </Notification>
        )}
      </Row>
    </AccountEntry>
  </AccountSection>
)

export default CareEnvironment
