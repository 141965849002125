// @flow

import * as Sentry from '@sentry/react'
import { saveAs } from 'file-saver'
import type { Saga } from 'redux-saga'
import { delay } from 'redux-saga'
import URI from 'urijs'

import { call, race } from 'elder/effects'

import { getConfig } from 'app/config'
import { ACCESS_TOKEN } from 'app/login/sagas'
import {
  ElderServiceError,
  HttpServiceError,
  NetworkServiceError,
  NoSessionServiceError,
} from 'app/saga/errors'
import { getLocalItem } from 'utils/storage'

const TIMEOUT = 30000

export type HttpMethod = 'HEAD' | 'GET' | 'POST' | 'PUT' | 'PATCH'

/**
 * Executes a fetch with timeout, returning either a Response, or a NetworkServiceError
 */
function* fetchResponse(
  url: string,
  method: HttpMethod,
  headers: { [string]: string },
  body: ?mixed = null,
): Saga<Response> {
  let uri = new URI(url)
  if (uri.is('relative')) {
    // Relative means relative to our base API endpoint (not the hosted application location)
    uri = uri.absoluteTo(getConfig().rest.baseEndpoint)
  }

  let requestParams = {
    method,
    headers,
  }

  if (body) {
    if (method === 'HEAD' || method === 'GET') {
      throw new Error('Attempt to pass a body to a HEAD or GET request denied')
    }
    requestParams = {
      ...requestParams,
      body,
    }
  }

  try {
    const { response } = yield* race({
      // $FlowOptOut
      response: () => call(fetch, uri.toString(), requestParams),
      timeout: () => call(delay, TIMEOUT),
    })

    if (response) {
      // Or, we have a timeout
      return response
    }
  } catch (error) {
    // Fetch only throws on network level errors (offline, CORS)
    throw new NetworkServiceError(error.message, 'NETWORK', error)
  }

  throw new NetworkServiceError(
    'No response from server within configured timeout',
    'TIMEOUT',
  )
}

/**
 * Executes a fetch with all details specified, returning either a response, or throws one of our errors
 * (HttpServiceError, ElderServiceError, NetworkServiceError)
 */
function* fetchResponseBody<T>(
  url: string,
  method: HttpMethod,
  headers: { [string]: string },
  responseReader: (Response) => Promise<T>,
  body: ?mixed = null,
): Saga<{| body: T, headers: Headers |}> {
  let responseBody = null
  const response = yield* call(fetchResponse, url, method, headers, body)
  // We have a response, we haven't actually read it yet though or checked the response code
  if (response.ok) {
    // Read the response body through the callback, it will work out the right type. Check for error again, we may
    // get additional network failures
    try {
      responseBody = yield* call(responseReader, response)
      return { body: responseBody, headers: response.headers }
    } catch (error) {
      throw new NetworkServiceError(error.message, 'NETWORK', error)
    }
  }
  // Try to read the response body, but don't fail if we can't - keep as much details as possible
  try {
    responseBody = yield* call([response, response.text])
  } catch (readError) {
    Sentry.captureException(
      readError,
      'Error reading details of error response (will be ignored)',
    )
  }

  // Is it an Elder specific error? Check for JSON and our tag
  let responseObject
  if (responseBody) {
    try {
      responseObject = JSON.parse(responseBody)
    } catch (jsonError) {
      // Not a valid JSON payload, must be a native error, don't complain
    }
  }

  if (
    typeof responseObject === 'object' &&
    responseObject.responseType === 'ELDER_ERROR'
  ) {
    throw new ElderServiceError(
      responseObject.message,
      response.status,
      responseObject.errorCode,
      responseObject.errorDetails,
      responseObject,
    )
  }

  // Plain old HTTP error here, no more detail to extract
  throw new HttpServiceError(
    `Server returned status ${response.status}`,
    response.status,
    responseBody,
  )
}

/**
 * Executes a fetch with all details specified, returning either a response, or throws one of our errors
 * (HttpServiceError, ElderServiceError, NetworkServiceError). Additionally, this method will automatically apply logic
 * related to authentication, it will capture the current token, and will handle authentication errors specifically
 */
function* fetchResponseBodyAuthenticated<T>(
  url: string,
  method: HttpMethod,
  headers: { [string]: string },
  responseReader: (Response) => Promise<T>,
  body: ?mixed = null,
): Saga<{| body: T, headers: Headers |}> {
  const etToken = getLocalItem(ACCESS_TOKEN)

  if (!etToken) {
    // We've been asked to call an authenticated service before we have a valid
    // session, or after it has expired - no point trying now!
    throw new NoSessionServiceError()
  }

  const headersWithAuth = {
    Authorization: `Bearer ${etToken}`,
    ...headers,
  }
  // eslint-disable-next-line no-useless-catch
  try {
    return yield* call(
      fetchResponseBody,
      url,
      method,
      headersWithAuth,
      responseReader,
      body,
    )
  } catch (error) {
    // No valid token found, we must have been logged out. Fire the error so that the request ends
    // TODO: Would we want to retry these automatically, or leave that
    // responsibility to the caller? The system will try to get another token
    // when this happens, if possible
    throw error
  }
}

function* getResponse<T>(
  url: string,
  method: HttpMethod,
  headers: { [string]: string } = {},
  reader: (Response) => Promise<T>,
  body: ?mixed = null,
  authenticated: boolean = true,
): Saga<{| body: T, headers: Headers |}> {
  if (authenticated) {
    return yield* fetchResponseBodyAuthenticated(
      url,
      method,
      headers,
      reader,
      body,
    )
  } else {
    return yield* fetchResponseBody(url, method, headers, reader, body)
  }
}

export function* fetchJson(
  url: string,
  method: HttpMethod = 'GET',
  headers: { [string]: string } = {},
  body: ?mixed = null,
  authenticated: boolean = true,
): Saga<any> {
  const reader = (response: Response) => response.json()
  const response = yield* getResponse(
    url,
    method,
    headers,
    reader,
    body,
    authenticated,
  )
  return response.body
}

export function* fetchText(
  url: string,
  method: HttpMethod,
  headers: { [string]: string } = {},
  body: ?mixed = null,
  authenticated: boolean = true,
): Saga<string> {
  const reader = (response: Response) => response.text()
  const response = yield* getResponse(
    url,
    method,
    headers,
    reader,
    body,
    authenticated,
  )
  return response.body
}

export function* fetchBlob(
  url: string,
  method: HttpMethod,
  headers: { [string]: string } = {},
  body: ?mixed = null,
  authenticated: boolean = true,
): Saga<Blob> {
  const reader = (response: Response) => response.blob()
  const response = yield* getResponse(
    url,
    method,
    headers,
    reader,
    body,
    authenticated,
  )
  return response.body
}
export function* fetchFile(
  url: string,
  method: HttpMethod,
  headers: { [string]: string } = {},
  body: ?mixed = null,
  authenticated: boolean = true,
): Saga<Blob> {
  const reader = (response: Response) => response.blob()
  const response = yield* getResponse(
    url,
    method,
    headers,
    reader,
    body,
    authenticated,
  )
  const filename = response.headers.get('x-filename')
  if (filename) {
    saveAs(response.body, filename)
  } else {
    throw new Error('No file found in the response body')
  }
  return response.body
}

export function* fetchNoResponseBody(
  url: string,
  method: HttpMethod,
  headers: { [string]: string } = {},
  body: ?mixed = null,
  authenticated: boolean = true,
): Saga<Response> {
  const reader = (response: Response) => Promise.resolve(response)
  const response = yield* getResponse(
    url,
    method,
    headers,
    reader,
    body,
    authenticated,
  )
  return response.body
}
