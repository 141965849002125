// @flow

import type { RefereeDetails } from 'features/carerSkills/domain/referenceDetails'
import type {
  FullWorkExperience,
  ElderDecision,
  ReferenceUploadData,
} from 'features/carerSkills/domain/workExperience'
import type { ActiveSection } from 'features/carerSkills/store/workExperience/reducer'
import { addNamespace } from 'utils'

export const NAMESPACE = 'WORK_EXPERIENCE'

export const GET_WORK_EXPERIENCES = addNamespace(
  NAMESPACE,
  'GET_WORK_EXPERIENCES',
)
export const SET_WORK_EXPERIENCES = addNamespace(
  NAMESPACE,
  'SET_WORK_EXPERIENCES',
)
export const SAVE_DECISION = addNamespace(NAMESPACE, 'SAVE_DECISION')
export const ACCEPT_REFERENCE = addNamespace(NAMESPACE, 'ACCEPT_REFERENCE')
export const ARCHIVE_REFERENCE = addNamespace(NAMESPACE, 'ARCHIVE_REFERENCE')
export const UNARCHIVE_REFERENCE = addNamespace(
  NAMESPACE,
  'UNARCHIVE_REFERENCE',
)
export const REQUEST_WORK_REFERENCE = addNamespace(
  NAMESPACE,
  'REQUEST_WORK_REFERENCE',
)
export const REQUEST_CHARACTER_REFERENCE = addNamespace(
  NAMESPACE,
  'REQUEST_CHARACTER_REFERENCE',
)
export const UPLOAD_REFERENCE = addNamespace(NAMESPACE, 'UPLOAD_REFERENCE')
export const REJECT_REFERENCE = addNamespace(NAMESPACE, 'REJECT_REFERENCE')
export const OPEN_FILES = addNamespace(NAMESPACE, 'OPEN_FILES')
export const CLEAR_LOADING = addNamespace(NAMESPACE, 'CLEAR_LOADING')
export const SET_ACTIVE_SECTION = addNamespace(NAMESPACE, 'SET_ACTIVE_SECTION')
export const SET_ARCHIVED = addNamespace(NAMESPACE, 'SET_ARCHIVED')

export const getWorkExperiences = (carerId: string) => ({
  type: GET_WORK_EXPERIENCES,
  carerId,
})

export const setWorkExperiences = ({
  carerId,
  workExperiencesResponse,
}: {|
  +carerId: string,
  +workExperiencesResponse: {|
    +workExperiences: Array<FullWorkExperience>,
    +numberOfReferees: number,
  |},
|}) => ({
  type: SET_WORK_EXPERIENCES,
  workExperiencesResponse,
  carerId,
})

export const saveDecision = ({
  elderDecision,
  id,
}: {|
  +elderDecision: ElderDecision,
  +id: string,
|}) => ({
  type: SAVE_DECISION,
  elderDecision,
  id,
})

export const requestWorkReference = ({
  refereeDetails,
  workExperienceId,
}: {|
  +refereeDetails: RefereeDetails,
  +workExperienceId: string,
|}) => ({
  type: REQUEST_WORK_REFERENCE,
  refereeDetails,
  workExperienceId,
})

export const acceptReference = (id: string, body: ?Object) => ({
  type: ACCEPT_REFERENCE,
  id,
  body,
})

export const rejectReference = (id: string, body: ?Object) => ({
  type: REJECT_REFERENCE,
  id,
  body,
})

export const archiveReference = (id: string) => ({
  type: ARCHIVE_REFERENCE,
  id,
})

export const unarchiveReference = (id: string) => ({
  type: UNARCHIVE_REFERENCE,
  id,
})

export const clearLoading = () => ({
  type: CLEAR_LOADING,
})

export const setActiveSection = (section: ActiveSection) => ({
  type: SET_ACTIVE_SECTION,
  section,
})

export const setArchived = (
  archived: boolean,
  workExperienceId: string,
  carerId: string,
) => ({
  type: SET_ARCHIVED,
  archived,
  workExperienceId,
  carerId,
})

export const requestCharacterReference = (body: RefereeDetails) => ({
  type: REQUEST_CHARACTER_REFERENCE,
  body,
})

export const uploadReference = (body: ReferenceUploadData) => ({
  type: UPLOAD_REFERENCE,
  body,
})

export const openFiles = (id: string) => ({
  type: OPEN_FILES,
  id,
})
