import { css } from 'styled-components'

export const styles = css`
  .acct__wrapper {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
  }

  .acct__wrapper--edit-mode {
    .acct__section:not(.acct__section--in-edit),
    .acct__section--full:not(.acct__section--in-edit) {
      opacity: 0.1;
      pointer-events: none;
    }
  }

  .acct__section {
    flex: 1 0 40%;
    margin-bottom: 30px;

    h3 {
      text-transform: capitalize;

      span {
        color: #73819c;
        font-size: 16px;
      }
    }
    &--dialog {
      margin-bottom: 0;
    }

    select,
    input[type='text'],
    input[type='email'],
    textarea {
      transition: border-color 0.3s ease;
      background-color: #ffffff;
      border: solid 2px #cad9f8;
      box-sizing: border-box;
      padding: 8px 10px;
      width: 100%;

      &:focus {
        border: solid 2px #19305e;
      }

      &:disabled {
        opacity: 0.5;
      }

      &::placeholder {
        color: #cad9f8;
        text-transform: capitalize;
      }
    }
  }

  .acct__section--full {
    flex: 1 0 100%;
    margin-bottom: 30px;

    h3 {
      text-transform: capitalize;

      span {
        color: #73819c;
        font-size: 16px;
      }
    }
  }

  .acct__header {
    align-items: center;
    border-bottom: 2px solid #19305e;
    display: flex;
    justify-content: space-between;
    margin: 20px 0 10px;
    padding-bottom: 10px;

    h3 {
      text-transform: none;
    }
  }

  .acct__header__sub {
    align-items: center;
    display: flex;
    justify-content: space-between;
    margin: 20px 0 10px;
    border-bottom: 0;
    border-top: 2px solid #19305e;
    padding-bottom: 0;
    padding-top: 10px;
  }

  // TODO: Remove once all usages have been updated to use Entry (See BankDetails.js for example usage)
  .acct__entry {
    align-items: center;
    border-bottom: 1px solid #eaf0fc;
    display: flex;
    line-height: 32px;
    padding: 10px 0;
    white-space: pre-wrap;

    > span,
    > label,
    > div {
      width: 50%;
    }

    span,
    label,
    strong {
      color: #73819c;
    }

    input[type='text'],
    input[type='email'],
    input[type='date'],
    textarea {
      width: 100%;
    }
    & > .edit-button {
      display: inline-block;
      max-width: 50px;
    }
  }
  // TODO: Remove once all usages have been updated to use EntrySmallEdit (See BankDetails.js for example usage)
  .acct__entry__small-edit {
    align-items: center;
    display: flex;
    justify-content: space-between;

    & > div {
      margin-right: 10px;

      &:last-child {
        margin-right: 0;
      }
    }
  }

  .acct__entry__small-new-line-edit {
    align-items: center;
    display: flex;
    justify-content: space-between;

    & > div {
      margin-right: 10px;

      &:last-child {
        margin-right: 0;
      }
    }
    display: inline-block;
  }

  .acct__entry__select > div {
    border: 2px solid #cad9f8;
    border-radius: 0px;
  }

  .acct__link {
    color: #3e86fa;
    cursor: pointer;
  }

  // TODO: Use src/components/styledComponentsTranslatedClasses/FormButtons instead
  .acct__form-buttons {
    display: flex;
    justify-content: flex-end;
    margin-top: 20px;

    & > div:not(:last-child) {
      margin-right: 15px;
    }
  }

  .acct__table {
    display: flex;
  }

  .acct__change {
    white-space: pre-wrap;
  }

  .acct__text--loud {
    color: #ef6461;
  }

  .acct__section__element--hidden {
    display: none;
  }

  .acct__entry__group {
    .acct__entry {
      border: 0;

      > span {
        margin-right: 3%;
        text-align: right;
        width: 47%;
      }

      > div {
        line-height: 25px;
      }
    }

    .acct__entry__group__title {
      > span {
        margin-right: 0;
        text-align: left;
        width: 50%;
      }
    }
  }

  .is-open .Select-control {
    border-color: #19305e;
  }

  .Select-placeholder {
    color: #cad9f8;
  }

  .Select-arrow,
  .Select-arrow-zone:hover > .Select-arrow {
    border-top-color: #cad9f8;
  }

  .is-open > .Select-control .Select-arrow {
    border-bottom-color: #cad9f8;
  }

  /**
 * react-select related
 */
  .Select-control {
    transition: border-color 0.3s ease;
    border: 2px solid #cad9f8;
    border-radius: 0px;

    &:hover {
      box-shadow: 0 0 0 0;
    }
  }

  /**
 * formsy related
 */
  .form-group {
    &.has-feedback {
      .validation-message {
        color: #ef6461;
      }
    }

    &.has-error {
      input[type='text'],
      input[type='email'],
      input[type='date'],
      textarea {
        border-color: #ef6461;
      }
    }
  }

  .form-group.row {
    align-items: center;
    border-bottom: 1px solid #eaf0fc;
    display: flex;
    line-height: 32px;
    padding: 10px 0;
    white-space: pre-wrap;

    > span,
    > label,
    > div {
      width: 50%;
    }

    span,
    label,
    strong {
      color: #73819c;
    }

    input[type='text'],
    input[type='email'],
    input[type='date'],
    textarea {
      width: 100%;
    }

    &.has-feedback {
      .validation-message {
        display: block;
        line-height: 20px;
        text-transform: none;
        width: 100%;
      }
    }

    &.is-date-picker > div > div {
      display: block;

      & > div {
        display: block;

        & > div {
          display: block;
        }
      }
    }

    .radio-inline {
      margin-right: 15px;

      &:last-child {
        margin-right: 0px;
      }
    }
  }

  .form-group__container {
    .form-group {
      border-bottom: 0;
      padding: 0;

      .checkbox,
      .radio {
        line-height: 25px;
      }
    }

    .form-group__entry {
      padding-top: 15px;

      > .control-label {
        margin-right: 3%;
        text-align: right;
        width: 47%;
      }
    }
  }

  .main-layout {
    display: flex;
    flex-direction: column;
    width: 100%;
  }
`
