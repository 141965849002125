// @flow
import { createSelector } from 'reselect'

import {
  getPrettyDrivingRequirements,
  getPrettyPets,
  getPrettyGenderPreference,
  getPrettyCarerFood,
  getPrettyLanguageFluencyPreference,
  getPrettyCarerExperiencePreference,
  getPrettyEthnicitiesAtRisk,
} from 'app/referenceData'
import { makeSelectors } from 'modules/pageEdit'
import {
  prettyAddressArray,
  prettyYesNoNull,
  prettySpareBedrooms,
} from 'utils/decorators'

import { NAMESPACE } from './actions'

const getAccountEnvironmentState = (state) => state.get(NAMESPACE)

export const pageSelectors = makeSelectors((state) =>
  getAccountEnvironmentState(state).get('accountEnvironment'),
)
const getAccountEnvironmentValidationError = (state) =>
  getAccountEnvironmentState(state).get('accountEnvironmentValidationError')

const getJsEnvironment = createSelector([pageSelectors.get], (environment) =>
  environment.toJS(),
)

export const getEnvironment = createSelector(
  [
    getJsEnvironment,
    getPrettyDrivingRequirements,
    getPrettyPets,
    getPrettyGenderPreference,
    getPrettyCarerFood,
    getPrettyLanguageFluencyPreference,
    getPrettyCarerExperiencePreference,
    getPrettyEthnicitiesAtRisk,
  ],
  (
    environment,
    prettyDrivingRequirements,
    prettyPets,
    prettyGenderPreference,
    prettyCarerFood,
    prettyLanguageFluencyPreference,
    prettyCarerExperiencePreference,
    prettyEthnicitiesAtRisk,
  ) => {
    const {
      address = {},
      genderPreference = null,
      spareBedrooms = null,
      carerFood = null,
      experiencePreference = null,
      languageFluencyPreference = null,
      ethnicitiesAtRisk,
    } = environment
    const spareBedroomsString = spareBedrooms ? spareBedrooms.toString() : ''

    let petsArray = []
    // have to manually push NONE as an option as BE returns [] when None is selected
    if (environment.pets && environment.pets.length === 0) {
      petsArray.push('NONE')
    } else if (environment.pets && environment.pets.length) {
      const { pets } = environment
      petsArray = pets
    }
    const getEthnicitiesListToShow = () => {
      if (ethnicitiesAtRisk && !!ethnicitiesAtRisk.length) {
        const pretty = ethnicitiesAtRisk.map(prettyEthnicitiesAtRisk)
        return `- ${pretty.join('\n- ')}`
      }
      return ''
    }

    return {
      ...environment,
      petsArray,
      genderPreference,
      experiencePreference,
      languageFluencyPreference,
      address,
      spareBedrooms: spareBedroomsString,
      careLocation: prettyAddressArray(
        address.address1,
        address.address2,
        address.city,
        address.postcode,
      ),
      prettyDrivingRequirements: prettyDrivingRequirements(
        environment.drivingRequirements,
      ),
      prettyWifi: prettyYesNoNull(environment.wifi),
      prettyPhoneReception: prettyYesNoNull(environment.phoneReception),
      prettyPets: petsArray.map((pet) => prettyPets(pet)).sort(),
      prettySmoking: prettyYesNoNull(environment.smoking),
      prettyGenderPreference: prettyGenderPreference(genderPreference),
      prettyLanguageFluencyPreference: prettyLanguageFluencyPreference(
        languageFluencyPreference,
      ),
      prettyExperiencePreference:
        prettyCarerExperiencePreference(experiencePreference),
      prettyCarerFood: prettyCarerFood(carerFood),
      prettySpareBedrooms: prettySpareBedrooms(spareBedrooms),
      prettyEthnicitiesAtRisk: getEthnicitiesListToShow(),
    }
  },
)

export const getJsAccountEnvironmentValidationError = createSelector(
  [getAccountEnvironmentValidationError],
  (validationErrors) => ({
    acctEnv: validationErrors.get('acctEnv').toJS(),
  }),
)

export const difficultMatchSelector = createSelector(
  [getEnvironment],
  (environment) => (environment && environment.difficultMatch) || false,
)
