// @flow

import styled from 'styled-components'

const ContentPage = styled.div`
  margin-left: auto;
  margin-right: auto;
  width: 95%;

  @media only screen and (min-width: 601px) {
    width: 95%;
  }

  display: flex;
  flex-direction: column;
  height: 100%;
  margin-top: 20px;

  h2 {
    margin-bottom: 10px;

    span {
      color: #73819c;
      font-size: 16px;
    }
  }
`

export default ContentPage
