import React from 'react'

import { colors } from '@elder/common'
import PropTypes from 'prop-types'
import scriptLoader from 'react-async-script-loader'
import styled from 'styled-components'

const Container = styled.div`
  .StripeElement {
    transition: border-color 0.3s ease;
    background-color: #ffffff;
    border: solid 2px #cad9f8;
    box-sizing: border-box;
    padding: 8px 10px;
    width: 100%;

    &:focus {
      border: solid 2px #19305e;
    }

    &:disabled {
      opacity: 0.5;
    }

    &::placeholder {
      color: #cad9f8;
      text-transform: capitalize;
    }
  }

  .StripeElement--invalid {
    border-color: #ef6461;
  }
`

const Form = styled.div`
  width: 100%;
`
const Card = styled.div`
  margin-bottom: 10px;
`
const Error = styled.div`
  color: ${colors.logoGreen};
`
const Feedback = styled.div`
  color: ${colors.errorDark};
`

// eslint-disable-next-line no-restricted-properties
class StripeCardInner extends React.Component {
  constructor(props) {
    super(props)
    this.stripe = null
    this.card = null

    this.generateToken = this.generateToken.bind(this)
  }

  UNSAFE_componentWillReceiveProps({ isScriptLoaded, isScriptLoadSucceed }) {
    if (isScriptLoaded && !this.props.isScriptLoaded) {
      if (isScriptLoadSucceed) {
        // Create a Stripe client
        this.stripe = window.Stripe(this.props.stripeKey)
        // Create an instance of Elements
        const elements = this.stripe.elements()
        const style = {
          base: {
            '::placeholder': {
              color: '#CAD9F8',
            },
          },
          invalid: {
            color: '#EF6461',
            iconColor: '#EF6461',
          },
        }

        // Create an instance of the card Element
        this.card = elements.create('card', { style })

        // Add an instance of the card Element into the `card-element` <div>
        this.card.mount('#card-element')

        // Handle real-time validation errors from the card Element.
        this.card.addEventListener('change', (event) => {
          if (event.error) {
            this.setState({
              localerror: event.error.message,
            })
          } else {
            this.setState({
              localerror: '',
            })

            this.props.cardComplete(event.complete, this.generateToken)
          }
        })
      } else {
        this.setState({
          localerror: "Stripe API wasn't loaded properly.",
        })
      }
    }
  }

  UNSAFE_componentWillMount() {
    this.setState({
      localerror: '',
      feedback: '',
    })
  }

  generateToken = () => {
    this.stripe.createToken(this.card).then((result) => {
      if (result.error) {
        // Inform the user if there was an error
        this.setState({
          localerror: result.error.message,
        })
      } else {
        // Send the token to your server
        this.props.callback(result)
      }
    })
  }

  render() {
    return (
      <Container>
        <Form
          ref={(stripeRender) => {
            // eslint-disable-next-line react/no-unused-class-component-methods
            this.stripeRender = stripeRender
          }}
        >
          <Card>
            <div id="card-element" />
            <Error className="stripe__card-error">
              {this.state.localerror}
            </Error>
            {this.state.feedback && (
              <Feedback className="stripe__card-feedback">
                {this.state.feedback}
              </Feedback>
            )}
          </Card>
        </Form>
      </Container>
    )
  }
}

StripeCardInner.propTypes = {
  stripeKey: PropTypes.string,
  isScriptLoaded: PropTypes.bool,
  isScriptLoadSucceed: PropTypes.bool,
  callback: PropTypes.func.isRequired,
  cardComplete: PropTypes.func,
}

export const StripeCard = scriptLoader('https://js.stripe.com/v3/')(
  StripeCardInner,
)
