// @flow
import * as Sentry from '@sentry/react'
import { push } from 'connected-react-router'
import { delay } from 'redux-saga'
import { call, cancel, fork, put, take } from 'redux-saga/effects'

import { restart } from 'app'
import {
  AUTH_SUCCESS,
  AUTH_FAIL,
  AUTH_ERRORS,
  setRoles,
  setEmail,
  authSuccess,
  AUTH_TYPES,
  authFail,
} from 'app/login/actions'
import {
  applicationError,
  applicationWarning,
} from 'features/snackbar/snackbar'
import * as routes from 'routes'
import { getFlow, describeServiceError } from 'utils/services'
import { getLocalItem, removeLocalItem, setLocalItem } from 'utils/storage'

import { processAuth0Error, permissionDeniedError } from './errors'

function* getRolesFlow() {
  try {
    const response = yield call(getFlow, '/et/login/validate')
    yield put(setRoles(response.roles))
    yield put(setEmail(response.email))
  } catch (error) {
    if (error.status === 403) {
      applicationError(permissionDeniedError)
      yield put(restart())
    } else {
      applicationError(
        describeServiceError(error, 'Error with /et/login/validate'),
      )
    }
  }
}

const REDIRECT_PATH = 'REDIRECT_PATH'
export const ACCESS_TOKEN = 'ACCESS_TOKEN'

function* handleAuthCallback(webAuth) {
  const callbackPromise = new Promise((resolve, reject) => {
    webAuth.parseHash((err, authResult) => {
      if (authResult) {
        resolve(authResult)
      } else if (err) {
        reject(err)
      }
    })
  })

  try {
    const authResult = yield callbackPromise

    setLocalItem(ACCESS_TOKEN, authResult.accessToken)
  } catch (err) {
    yield put(authFail(AUTH_ERRORS.NOT_AUTHORIZED, err.errorText))
  }
}

function* verifyProfile({ accessToken, webAuth }) {
  const profilePromise = new Promise((resolve, reject) => {
    webAuth.client.userInfo(accessToken, (err, profile) => {
      if (profile) {
        resolve(profile)
      } else if (err) {
        reject(err)
      }
    })
  })

  try {
    const profile = yield profilePromise
    if (profile.email_verified) {
      yield put(authSuccess(profile, AUTH_TYPES.USERNAME_PASSWORD))
    } else {
      yield put(authFail(AUTH_ERRORS.VERIFY_EMAIL, JSON.stringify(profile)))
    }
  } catch (e) {
    yield put(authFail(AUTH_ERRORS.INVALID_AUTH_TOKEN, JSON.stringify(e)))
  }
}

function* authenticationFlowWrapper(webAuth) {
  if (window.location.hash.includes('access_token')) {
    const hashParserTask = yield fork(handleAuthCallback, webAuth)
    yield call(delay, 1000)
    yield cancel(hashParserTask)
  }

  const { pathname, search, hash } = window.location
  const potentialRedirect = `${pathname}${search}${hash}`
  if (
    potentialRedirect &&
    potentialRedirect !== '/' &&
    !potentialRedirect.includes('access_token')
  ) {
    setLocalItem(REDIRECT_PATH, potentialRedirect)
  }

  const accessToken = getLocalItem(ACCESS_TOKEN)

  if (accessToken) {
    yield call(verifyProfile, { accessToken, webAuth })
  } else {
    webAuth.authorize()
  }
}

export function* authenticationFlow(webAuth: Object): Generator<*, void, *> {
  const flowWrapperTask = yield fork(authenticationFlowWrapper, webAuth)
  const authResult = yield take([AUTH_SUCCESS, AUTH_FAIL])

  yield cancel(flowWrapperTask)

  if (authResult.type === AUTH_SUCCESS) {
    yield call(getRolesFlow)
    Sentry.setUser({
      id: authResult.profile.email,
      email: authResult.profile.email,
      username: authResult.profile.name,
      ip: '{{auto}}',
    })

    const redirectLocation = getLocalItem(REDIRECT_PATH)
    if (redirectLocation) {
      removeLocalItem(REDIRECT_PATH)
    }

    yield put(push(redirectLocation || routes.matchingCentre))
  } else {
    Sentry.setUser(null)
    switch (authResult.errorType) {
      case AUTH_ERRORS.NOT_AUTHORIZED:
        applicationError(permissionDeniedError)
        break
      case AUTH_ERRORS.INVALID_AUTH_TOKEN:
        applicationWarning({ message: 'Login expired. Please log in again.' })
        break
      case AUTH_ERRORS.VERIFY_EMAIL:
        applicationWarning({ message: 'Please verify your email address' })
        break
      default:
        applicationError(processAuth0Error(authResult.errorText))
        break
    }
  }
}
