// @flow
import { getLocation } from 'connected-react-router/immutable'
import type { Saga } from 'redux-saga'
import { call, select, takeLatest, put } from 'redux-saga/effects'

import { clearLoading, setLoading } from 'features/loading/actions'
import {
  applicationError,
  applicationSuccess,
} from 'features/snackbar/snackbar'
import { GET } from 'modules/pageEdit'
import { pageSelectors } from 'routes/account/selectors'
import {
  NAMESPACE,
  pageActions,
  UPDATE_AUTOMATED_COMMUNICATIONS,
  UPDATE_CAN_ACCESS_PORTAL_WITH_READ_ONLY_PERMISSIONS,
  readOnlyAccessSpinnerActions,
  UPDATE_APPLICATION_QUESTION,
  UPDATE_PAYMENT_ASSURANCE_REQUIRED,
} from 'routes/account/summary/actions'
import { addNamespace } from 'utils'
import {
  describeServiceError,
  getAndSetFlow,
  postFlow,
  putFlow,
} from 'utils/services'
import { queryToObject } from 'utils/urlManipulation'

const serviceError = (error, message) =>
  applicationError(describeServiceError(error, message))

function* fetchAccount(): Generator<*, void, *> {
  const accountId = yield select(pageSelectors.getId)
  yield call(getAndSetFlow, `/et/accounts/${accountId}`, pageActions.set)
  const location = yield select(getLocation)
  const searchObject = queryToObject(location.search)
  if (searchObject.section) {
    yield put(pageActions.editSection(searchObject.section))
  }
}

function* updateAutomatedCommunications({ isEnabled }): Generator<*, void, *> {
  const accountId = yield select(pageSelectors.getId)
  const endpoint = `/et/accounts/${accountId}/automatedCommunications`
  try {
    const response = yield call(postFlow, endpoint, {
      communicationsEnabled: isEnabled,
    })
    yield put(pageActions.set(response))
  } catch (error) {
    serviceError(error, `Error with service call ${endpoint}`)
  }
}

function* updateCanAccessPortalWithReadOnlyPermissions({
  canAccessPortalWithReadOnlyPermissions,
}): Generator<*, void, *> {
  const accountId = yield select(pageSelectors.getId)
  const endpoint = `/et/accounts/${accountId}/portalaccess`
  try {
    yield put(readOnlyAccessSpinnerActions.setStartLoading())

    const response = yield call(putFlow, endpoint, {
      canAccessPortalWithReadOnlyPermissions,
    })
    yield put(pageActions.merge(response))

    applicationSuccess({
      message: 'Successfully set MyElder access',
    })
    yield put(pageActions.cancelEdit())
  } catch (error) {
    serviceError(error, `Error with service call ${endpoint}`)
  } finally {
    yield put(readOnlyAccessSpinnerActions.setStopLoading())
  }
}

function* handleUpdateApplicationQuestion({
  updateApplicationQuestion,
}): Generator<*, void, *> {
  const accountId = yield select(pageSelectors.getId)
  const endpoint = `/et/accounts/${accountId}/applicationQuestion`

  try {
    const accountSummary = yield call(putFlow, endpoint, {
      applicationQuestion: updateApplicationQuestion,
    })
    yield put(pageActions.set(accountSummary))
    yield put(pageActions.cancelEdit())
  } catch (error) {
    applicationError(
      describeServiceError(error, `Error with service call ${endpoint}`),
    )
  }
}

function* handleUpdatePaymentAssuranceRequired({
  paymentAssuranceRequired,
}): Generator<*, void, *> {
  const accountId = yield select(pageSelectors.getId)
  const endpoint = `/et/accounts/${accountId}/paymentAssuranceRequired`
  yield put(setLoading(UPDATE_PAYMENT_ASSURANCE_REQUIRED))
  try {
    const accountSummary = yield call(putFlow, endpoint, {
      paymentAssuranceRequired,
    })
    yield put(pageActions.set(accountSummary))
    yield put(pageActions.cancelEdit())
  } catch (error) {
    applicationError(
      describeServiceError(error, `Error with service call ${endpoint}`),
    )
  } finally {
    yield put(clearLoading(UPDATE_PAYMENT_ASSURANCE_REQUIRED))
  }
}
export function* watchAccount(): Saga<*> {
  yield takeLatest(addNamespace(NAMESPACE, GET), fetchAccount)
  yield takeLatest(
    UPDATE_AUTOMATED_COMMUNICATIONS,
    updateAutomatedCommunications,
  )
  yield takeLatest(
    UPDATE_CAN_ACCESS_PORTAL_WITH_READ_ONLY_PERMISSIONS,
    updateCanAccessPortalWithReadOnlyPermissions,
  )
  yield takeLatest(UPDATE_APPLICATION_QUESTION, handleUpdateApplicationQuestion)
  yield takeLatest(
    UPDATE_PAYMENT_ASSURANCE_REQUIRED,
    handleUpdatePaymentAssuranceRequired,
  )
}
