// @flow
import React from 'react'

import { LoadingButton } from '@mui/lab'

const getColor = (loud: boolean, safe: boolean) => {
  if (loud) return 'warning'
  if (safe) return 'success'
  return 'primary'
}

type Props = {|
  +text: string,
  +onClick: () => void,
  +quiet?: boolean,
  +loud?: boolean,
  +safe?: boolean,
  +inline?: boolean,
  +isLoading?: boolean,
  +disabled?: boolean,
  +style?: Object,
  +className?: string,
|}

const GenericButton = ({
  text,
  onClick,
  quiet = false,
  loud = false,
  safe = false,
  inline,
  isLoading,
  disabled,
  style,
  className,
}: Props) => (
  <LoadingButton
    loading={isLoading}
    disabled={disabled}
    style={style}
    onClick={onClick}
    className={className}
    variant={quiet ? 'outlined' : 'contained'}
    color={getColor(loud, safe)}
    sx={{
      display: inline ? 'inline-block' : 'block',
    }}
  >
    {text}
  </LoadingButton>
)

export default GenericButton
