// @flow
import Immutable from 'immutable'

import {
  SET,
  CANCEL_EDIT,
  UPDATE_STATUS,
  UPDATE_STATUS_VALIDATION_FAIL,
} from 'modules/pageEdit'
import {
  NAMESPACE,
  UPDATE_BASIC_ACCT_INFO,
  UPDATE_BASIC_ACCT_INFO_VALIDATION_FAIL,
  UPDATE_BILLING_CYCLE,
  UPDATE_BILLING_CYCLE_VALIDATION_FAIL,
  UPDATE_PAYMENT_TYPE,
  UPDATE_PAYMENT_TYPE_VALIDATION_FAIL,
  UPDATE_FUNDING,
  UPDATE_FUNDING_VALIDATION_FAIL,
  UPDATE_CONTACTS,
  UPDATE_CONTACTS_VALIDATION_FAIL,
} from 'routes/account/summary/actions'
import { addNamespace } from 'utils'

const InitialStateRecord = Immutable.Record({
  basicAcctInfo: Immutable.Map(),
  billingCycle: Immutable.Map(),
  paymentDetails: Immutable.Map(),
  funding: Immutable.Map(),
  updateStatus: Immutable.Map(),
})

export const accountValidationErrorReducer = (
  state: Object = new InitialStateRecord(),
  action: Object,
) => {
  switch (action.type) {
    case UPDATE_BASIC_ACCT_INFO_VALIDATION_FAIL:
      return state.set(
        'basicAcctInfo',
        Immutable.fromJS(action.error.basicAcctInfo),
      )
    case UPDATE_BILLING_CYCLE_VALIDATION_FAIL:
      return state.set(
        'billingCycle',
        Immutable.fromJS(action.error.billingCycle),
      )
    case UPDATE_PAYMENT_TYPE_VALIDATION_FAIL:
      return state.set(
        'paymentDetails',
        Immutable.fromJS(action.error.paymentDetails),
      )
    case UPDATE_FUNDING_VALIDATION_FAIL:
      return state.set('funding', Immutable.fromJS(action.error.funding))
    case UPDATE_CONTACTS_VALIDATION_FAIL:
      return state.set(
        'updateContacts',
        Immutable.fromJS(action.error.updateContacts),
      )
    case addNamespace(NAMESPACE, UPDATE_STATUS_VALIDATION_FAIL):
      return state.set(
        'updateStatus',
        Immutable.fromJS(action.error.updateStatus),
      )
    case addNamespace(NAMESPACE, SET):
    case addNamespace(NAMESPACE, CANCEL_EDIT):
    case addNamespace(NAMESPACE, UPDATE_STATUS):
    case UPDATE_BASIC_ACCT_INFO:
    case UPDATE_BILLING_CYCLE:
    case UPDATE_PAYMENT_TYPE:
    case UPDATE_FUNDING:
    case UPDATE_CONTACTS:
      return new InitialStateRecord()
    default:
      return state
  }
}

export default accountValidationErrorReducer
