// @flow
import React from 'react'

import type { ReferenceDataOptions } from '@elder/common'
import { Form } from 'formsy-react-components'

import { getConfig } from 'app/config'
import { ButtonSubmit, FormsySelect, GenericButton } from 'components'
import { FormButtons } from 'components/styledComponentsTranslatedClasses/FormButtons'
import { Section } from 'components/styledComponentsTranslatedClasses/Section'
import { SectionHeader } from 'components/styledComponentsTranslatedClasses/SectionHeader'
import { forms } from 'messages/forms'

const { featureFlags } = getConfig()
type Props = {|
  +careType: string,
  +careTypeOptions: ReferenceDataOptions,
  +updateBasicAccountInfo: ({| +careType: string |}) => void,
  +cancelEdit: () => void,
|}

const CareTypeForm = ({
  careType,
  careTypeOptions,
  cancelEdit,
  updateBasicAccountInfo,
}: Props) => {
  const options = featureFlags.includes('THIRD_PARTY_CARE')
    ? careTypeOptions.options
    : careTypeOptions.options.filter((option) => option.value !== 'THIRD_PARTY')
  return (
    <Section>
      <Form
        onSubmit={(data) => {
          updateBasicAccountInfo(data)
          cancelEdit()
        }}
      >
        <SectionHeader>
          <h3>Type of care (edit mode)</h3>
        </SectionHeader>
        <FormsySelect
          name="careType"
          label="Type of care:"
          value={careType}
          options={options}
          clearable={false}
          validationErrors={{
            isDefaultRequiredValue: forms.validation.isRequired,
          }}
          required
        />
        <FormButtons>
          <GenericButton text="Cancel" onClick={cancelEdit} quiet />
          <ButtonSubmit text="Save" />
        </FormButtons>
      </Form>
    </Section>
  )
}

export default CareTypeForm
