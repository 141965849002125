import React from 'react'

import { spacings } from '@elder/common'
import { Button } from '@mui/material'
import { Form, Input } from 'formsy-react-components'
import RPT from 'prop-types'
import styled from 'styled-components'

import { forms } from 'messages/forms'

const Wrapper = styled.div`
  .pro__wrapper {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
  }

  .pro__wrapper--edit-mode {
    .pro__section:not(.pro__section--in-edit) {
      opacity: 0.1;
      pointer-events: none;
    }
  }

  .pro__section {
    flex: 1 0 100%;
    margin-bottom: 30px;

    h3 {
      text-transform: capitalize;

      span {
        color: #73819c;
        font-size: 16px;
      }
    }
    @media only screen and (min-width: 1051px) {
      flex: 0.4 0 40%;
    }
  }

  .pro__section--full {
    flex: 1 0 100%;
    margin-bottom: 30px;

    h3 {
      text-transform: capitalize;

      span {
        color: #73819c;
        font-size: 16px;
      }
    }
  }

  .pro__header {
    align-items: center;
    border-bottom: 2px solid #19305e;
    display: flex;
    justify-content: space-between;
    margin: 20px 0 10px;
    padding-bottom: 10px;
  }

  .pro__entry {
    align-items: center;
    border-bottom: 1px solid #eaf0fc;
    display: flex;
    line-height: 32px;
    padding: 10px 0;
    white-space: pre-wrap;

    > span,
    > label,
    > div {
      width: 50%;
    }

    span,
    label,
    strong {
      color: #73819c;
    }

    input[type='text'],
    input[type='email'],
    input[type='date'],
    textarea {
      width: 100%;
    }
  }

  .pro__entry__small-edit {
    align-items: center;
    display: flex;
    justify-content: space-between;

    & > div {
      margin-right: 10px;

      &:last-child {
        margin-right: 0;
      }
    }
  }

  .pro__link {
    color: #3e86fa;
    cursor: pointer;
  }

  .pro__table {
    display: flex;
  }

  .pro__change {
    white-space: pre-wrap;
  }

  .pro__long_string {
    overflow: hidden;
    text-overflow: ellipsis;
  }

  .pro__entry__wrapper {
    border-bottom: 1px solid #eaf0fc;
    padding: 10px 0;
  }

  .pro__language__entry {
    align-items: center;
    display: flex;
    margin-bottom: 8px;
  }

  .pro__select {
    width: 100%;

    > div {
      border: 2px solid #cad9f8;
      border-radius: 0px;
    }
  }

  .pro__select--language {
    margin-left: 5px;
    margin-right: 15px;
  }

  .pro__education {
    align-items: center;
    display: flex;
  }

  .pro__education__entry {
    margin-right: 15px;
    width: 100%;
  }

  .pro__education__institution {
    width: 100%;
    margin: 5px 0px;
  }

  .pro__dropzone__wrapper {
    display: flex;
    justify-content: center;
    margin-top: 10px;
  }

  .pro__dropzone__image {
    border-radius: 100px;
    height: 200px;
    width: 200px;
  }

  .pro__dropzone__text {
    padding: 15px;
  }

  .pro__dropzone__text--error {
    color: #ef6461;
  }

  .pro__entry--bs {
    align-items: center;
    border-bottom: 1px solid #eaf0fc;
    display: flex;
    line-height: 32px;
    padding: 10px 0;
    white-space: pre-wrap;

    > span,
    > label,
    > div {
      width: 50%;
    }

    span,
    label,
    strong {
      color: #ef6461;
    }

    input[type='text'],
    input[type='email'],
    input[type='date'],
    textarea {
      width: 100%;
    }
  }

  .pro__entry__url {
    a {
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
      padding-right: 15px;
    }
    display: flex;
    flex-direction: row;
    justify-content: space-between;
  }

  /**
 * formsy related
 */
  .form-group {
    &.has-feedback {
      .validation-message {
        color: #ef6461;
      }
    }

    &.has-error {
      input[type='text'],
      input[type='email'],
      input[type='date'],
      textarea {
        border-color: #ef6461;
      }
    }
  }

  .form-group.row {
    align-items: center;
    border-bottom: 1px solid #eaf0fc;
    display: flex;
    line-height: 32px;
    padding: 10px 0;
    white-space: pre-wrap;

    > span,
    > label,
    > div {
      width: 50%;
    }

    span,
    label,
    strong {
      color: #73819c;
    }

    input[type='text'],
    input[type='email'],
    input[type='date'],
    textarea {
      width: 100%;
    }

    &.has-feedback {
      .validation-message {
        display: block;
        line-height: 20px;
        text-transform: none;
        width: 100%;
      }
    }

    &.is-date-picker > div > div {
      display: block;

      & > div {
        display: block;

        & > div {
          display: block;
        }
      }
    }

    .radio-inline {
      margin-right: 15px;

      &:last-child {
        margin-right: 0px;
      }
    }
  }

  .form-group__container {
    .form-group {
      border-bottom: 0;
      padding: 0;

      .checkbox,
      .radio {
        line-height: 25px;
      }
    }

    .form-group__entry {
      padding-top: 15px;

      > .control-label {
        margin-right: 3%;
        text-align: right;
        width: 47%;
      }
    }
  }

  select,
  input[type='text'],
  input[type='email'],
  textarea {
    transition: border-color 0.3s ease;
    background-color: #ffffff;
    border: solid 2px #cad9f8;
    box-sizing: border-box;
    padding: 8px 10px;
    width: 100%;

    &:focus {
      border: solid 2px #19305e;
    }

    &:disabled {
      opacity: 0.5;
    }

    &::placeholder {
      color: #cad9f8;
      text-transform: capitalize;
    }
  }
`

const ButtonsWrapper = styled.div`
  display: flex;
  justify-content: flex-end;
  align-items: center;
  margin: ${spacings.small} 0 ${spacings.small};
`

const StyledTertiaryButton = styled(Button)`
  margin-right: ${spacings.small};
`

// eslint-disable-next-line no-restricted-properties
class ProfessionalNewForm extends React.Component {
  UNSAFE_componentWillMount() {
    this.setState({
      canSubmit: false,
    })
  }

  UNSAFE_componentWillReceiveProps(props) {
    this.form.formsyForm.updateInputsWithError(props.validationErrors)
  }

  setValid = () => this.setState({ canSubmit: true })

  setInvalid = () => this.setState({ canSubmit: false })

  submitForm = (data) => {
    if (this.state.canSubmit) {
      this.props.createNewProfessional(data)
    }
  }

  render() {
    return (
      <Wrapper>
        <Form
          ref={(form) => {
            this.form = form
          }}
          onSubmit={this.submitForm}
          onValid={this.setValid}
          onInvalid={this.setInvalid}
        >
          <div className="pro__header">
            <h3>Basic Profile</h3>
          </div>
          <Input
            name="firstName"
            label="First name:"
            validationErrors={{
              isDefaultRequiredValue: forms.validation.isRequired,
            }}
            required
          />
          <Input
            name="lastName"
            label="Last name:"
            validationErrors={{
              isDefaultRequiredValue: forms.validation.isRequired,
            }}
            required
          />
          <Input name="phone" label="Phone:" />
          <Input
            name="email"
            label="Email:"
            type="email"
            validations="isEmail"
            validationErrors={{
              isEmail: forms.validation.isEmail,
              isDefaultRequiredValue: forms.validation.isRequired,
            }}
            required
          />
          <Input name="address" label="Address:" />
          <Input name="city" label="City:" />
          <Input
            name="postcode"
            label="Postcode:"
            validations={{
              matchRegexp: /^[A-Z]{1,2}[0-9][0-9A-Z]?\s?[0-9][A-Z]{2}$/i,
            }}
            validationErrors={{
              matchRegexp: forms.validation.isPostCode,
            }}
          />
          <div className="pro__header">
            <h3>Bank Details</h3>
          </div>
          <Input
            name="sortCode"
            label="Sort Code:"
            value=""
            placeholder="XXXXXX"
            validations={{
              maxLength: 6,
              minLength: 6,
              isNumeric: true,
            }}
            validationErrors={{
              maxLength: forms.validation.maxLength,
              minLength: forms.validation.minLength,
              isNumeric: forms.validation.isNumeric,
            }}
          />
          <Input
            name="bankAccount"
            label="Bank Account:"
            value=""
            placeholder="XXXXXXXX"
            validations={{
              maxLength: 8,
              minLength: 8,
              isNumeric: true,
            }}
            validationErrors={{
              maxLength: forms.validation.maxLength,
              minLength: forms.validation.minLength,
              isNumeric: forms.validation.isNumeric,
            }}
          />
          <ButtonsWrapper>
            <StyledTertiaryButton
              variant="text"
              label=""
              onClick={this.props.cancelEdit}
            >
              Cancel
            </StyledTertiaryButton>
            <Button
              variant="contained"
              disabled={!this.state.canSubmit}
              type="submit"
            >
              Save
            </Button>
          </ButtonsWrapper>
        </Form>
      </Wrapper>
    )
  }
}

ProfessionalNewForm.propTypes = {
  createNewProfessional: RPT.func.isRequired,
  cancelEdit: RPT.func.isRequired,
  validationErrors: RPT.object.isRequired, // eslint-disable-line react/forbid-prop-types
}

export default ProfessionalNewForm
