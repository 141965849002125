// @flow
import { endpointBuilder, noParameters } from 'elder/services'

import {
  billingAccountResponseType,
  editInvoiceDetailsRequestType,
  getBillingAccountRequestType,
  updateDisplayNameRequestType,
  updatePayeeDetailsRequestType,
  updateBillingCycleRequestType,
  directDebitMandateRequestType,
  editStripeRequestType,
  editDirectDebitRequestType,
  cancelDirectDebitRequestType,
  editBankTransferRequestType,
  editManualInvoicingRequestType,
  editStripeBankTransferRequestType,
} from 'features/billingAccounts/domain'

export const billingAccountsService = {
  getBillingAccount: endpointBuilder(
    getBillingAccountRequestType,
    billingAccountResponseType,
  )
    .withUrlTemplate('/et/billingaccounts/{id}', ({ id }) => ({ id }))
    .build(),
  updateDisplayName: endpointBuilder(
    updateDisplayNameRequestType,
    billingAccountResponseType,
  )
    .withUrlTemplate('/et/billingaccounts/{id}/displayName', ({ id }) => ({
      id,
    }))
    .withBodyBuilder(({ displayName }) => ({ displayName }))
    .withHttpMethod('PUT')
    .build(),
  updatePayeeDetails: endpointBuilder(
    updatePayeeDetailsRequestType,
    billingAccountResponseType,
  )
    .withUrlTemplate('/et/billingaccounts/{id}/payeeDetails', ({ id }) => ({
      id,
    }))
    .withBodyBuilder(({ details }) => ({ details }))
    .withHttpMethod('PUT')
    .build(),
  updateBillingCycle: endpointBuilder(
    updateBillingCycleRequestType,
    billingAccountResponseType,
  )
    .withUrlTemplate('/et/billingaccounts/{id}/billingCycle', ({ id }) => ({
      id,
    }))
    .withBodyBuilder(({ cycle }) => ({ cycle }))
    .withHttpMethod('PUT')
    .build(),
  editDirectDebitMandate: endpointBuilder(
    directDebitMandateRequestType,
    billingAccountResponseType,
  )
    .withUrlTemplate(
      '/et/billingaccounts/{id}/paymentMethod/directDebit/mandate',
      ({ id }) => ({
        id,
      }),
    )
    .withBodyBuilder(({ payload }) => ({ ...payload }))
    .withHttpMethod('PUT')
    .build(),
  editStripe: endpointBuilder(editStripeRequestType, billingAccountResponseType)
    .withUrlTemplate(
      '/et/billingaccounts/{id}/paymentMethod/stripe',
      ({ id }) => ({
        id,
      }),
    )
    .withBodyBuilder(({ payload }) => ({ ...payload }))
    .withHttpMethod('PUT')
    .build(),
  editDirectDebit: endpointBuilder(
    editDirectDebitRequestType,
    billingAccountResponseType,
  )
    .withUrlTemplate(
      '/et/billingaccounts/{id}/paymentMethod/directDebit',
      ({ id }) => ({
        id,
      }),
    )
    .withBodyBuilder(({ payload }) => ({ ...payload }))
    .withHttpMethod('PUT')
    .build(),
  editBankTransfer: endpointBuilder(
    editBankTransferRequestType,
    billingAccountResponseType,
  )
    .withUrlTemplate(
      '/et/billingaccounts/{id}/paymentMethod/bankTransfer',
      ({ id, payload }) => ({
        id,
        payload,
      }),
    )
    .withBodyBuilder(({ payload }) => ({ ...payload }))
    .withHttpMethod('PUT')
    .build(),
  editStripeBankTransfer: endpointBuilder(
    editStripeBankTransferRequestType,
    billingAccountResponseType,
  )
    .withUrlTemplate(
      '/et/billingaccounts/{id}/paymentMethod/stripeBankTransfer',
      ({ id, payload }) => ({
        id,
        payload,
      }),
    )
    .withBodyBuilder(({ payload }) => ({ ...payload }))
    .withHttpMethod('PUT')
    .build(),
  editManualInvoicing: endpointBuilder(
    editManualInvoicingRequestType,
    billingAccountResponseType,
  )
    .withUrlTemplate(
      '/et/billingaccounts/{id}/paymentMethod/manualInvoicing',
      ({ id }) => ({
        id,
      }),
    )
    .withBodyBuilder(({ payload }) => ({ ...payload }))
    .withHttpMethod('PUT')
    .build(),
  cancelDirectDebit: endpointBuilder(
    cancelDirectDebitRequestType,
    billingAccountResponseType,
  )
    .withUrlTemplate(
      '/et/billingaccounts/{id}/paymentMethod/directDebit',
      ({ id }) => ({
        id,
      }),
    )
    .withHttpMethod('DELETE')
    .build(),
  createBillingAccount: endpointBuilder(
    noParameters,
    billingAccountResponseType,
  )
    .withHttpMethod('POST')
    .withUrlTemplate('/et/billingaccounts/create')
    .build(),
  editInvoiceDetails: endpointBuilder(
    editInvoiceDetailsRequestType,
    billingAccountResponseType,
  )
    .withUrlTemplate(
      '/et/billingaccounts/{billingAccountId}/invoicePdfColumns',
      ({ billingAccountId }) => ({ billingAccountId }),
    )
    .withHttpMethod('PUT')
    .withBodyBuilder(({ pdfPreview }) => pdfPreview)
    .build(),
}
