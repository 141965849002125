// @flow
import { replace } from 'connected-react-router'
import type { Saga } from 'redux-saga'
import { take, call, put } from 'redux-saga/effects'

import { applicationSuccess } from 'features/snackbar/snackbar'
import { expectedBasicProfileFields } from 'routes/profiles/shared/validFields'
import { manageErrorWithFieldValidation } from 'utils/fieldValidationErrors'
import { fetchFlow } from 'utils/services'

import { CREATE, createNewValidationFail } from './actions'

export function* postProfileNew(data: Object): Generator<Object, void, Object> {
  const endpoint = '/et/profiles/'
  const method = 'POST'

  try {
    const responseBody = yield call(fetchFlow, endpoint, method, data)
    const { profileId } = responseBody
    applicationSuccess({
      title: 'Profile created',
      message: 'Profile was created successfully.',
    })
    yield put(replace(`/profiles/${profileId}`))
  } catch (error) {
    yield call(
      manageErrorWithFieldValidation,
      error,
      'profileNewValidationError',
      expectedBasicProfileFields,
      createNewValidationFail,
    )
  }
}

export function* watchProfileNew(): Saga<*> {
  while (true) {
    const payload = yield take(CREATE)
    yield call(postProfileNew, payload.data)
  }
}
