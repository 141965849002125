/* eslint-disable react/forbid-prop-types */
import React from 'react'

import { Autocomplete, TextField } from '@mui/material'
import { Form } from 'formsy-react-components'
import PropTypes from 'prop-types'
import styled from 'styled-components'

import { ButtonSubmit, GenericButton } from 'components'
import { styles } from 'routes/accounts/createAccount/styles'

const Wrapper = styled.div`
  ${styles}
`

// eslint-disable-next-line no-restricted-properties
class RecommendedResourcesForm extends React.Component {
  state = {
    canSubmit: true,
    recommendedResources: this.props.recommendedResources,
  }

  setValid = () => this.setState({ canSubmit: true })

  setInvalid = () => this.setState({ canSubmit: false })

  submitForm = () => {
    if (!this.state.canSubmit) {
      return
    }

    this.props.updateRecommendedResources(this.state.recommendedResources)
    this.props.cancelEdit()
  }

  onChange = (recommendedResources) => {
    this.setState({ recommendedResources })
  }

  render() {
    const { cancelEdit, options } = this.props
    return (
      <Wrapper className="acct__section acct__section--in-edit">
        <Form
          onSubmit={this.submitForm}
          onValid={this.setValid}
          onInvalid={this.setInvalid}
        >
          <div className="acct__header">
            <h3>Recommended Resources (edit mode)</h3>
          </div>
          <Autocomplete
            multiple
            id="conditionExpertise"
            options={options}
            getOptionLabel={(option) => option.label}
            filterSelectedOptions
            renderInput={(params) => <TextField {...params} />}
            value={this.state.recommendedResources.map((value) =>
              options.find((option) => option.value === value),
            )}
            onChange={(_, newValue) => {
              this.onChange(newValue.map((option) => option.value))
            }}
            size="small"
          />
          <div className="acct__form-buttons">
            <GenericButton text="Cancel" onClick={cancelEdit} quiet />
            <ButtonSubmit text="Save" disabled={!this.state.canSubmit} />
          </div>
        </Form>
      </Wrapper>
    )
  }
}

RecommendedResourcesForm.propTypes = {
  recommendedResources: PropTypes.array.isRequired,
  options: PropTypes.array.isRequired,
  updateRecommendedResources: PropTypes.func.isRequired,
  cancelEdit: PropTypes.func.isRequired,
}

export default RecommendedResourcesForm
