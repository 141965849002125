// @flow
import { addNamespace } from 'utils'

export const GET_AUTOCOMPLETE_PROFILES = 'GET_AUTOCOMPLETE_PROFILES'
export const GET_AUTOCOMPLETE_PROFILES_FAIL = 'GET_AUTOCOMPLETE_PROFILES_FAIL'
export const SET_AUTOCOMPLETE_PROFILES = 'SET_AUTOCOMPLETE_PROFILES'

export const makeActions = (namespace: string) => ({
  getAutocompleteProfiles: (queryString: string) => ({
    type: addNamespace(namespace, GET_AUTOCOMPLETE_PROFILES),
    queryString,
  }),

  getAutocompleteProfilesFail: (error: Object) => ({
    type: addNamespace(namespace, GET_AUTOCOMPLETE_PROFILES_FAIL),
    error,
  }),

  setAutocompleteProfiles: (autocompleteProfiles: Object) => ({
    type: addNamespace(namespace, SET_AUTOCOMPLETE_PROFILES),
    autocompleteProfiles,
  }),
})

export default makeActions
