// @flow
import * as Sentry from '@sentry/react'
import type { Saga } from 'redux-saga'

import { takeLatest, call, put } from 'elder/effects'

import { experimentClient } from 'app/saga/serviceClients'
import {
  getTestGroup,
  getTestGroupError,
  setTestGroup,
} from 'features/experiments/store/actions'

function* handleGetTestGroup({
  experimentName,
  accountId,
}: {
  +experimentName: string,
  +accountId: string,
}): Saga<*> {
  try {
    const response = yield* call(experimentClient.getTestGroup, {
      accountId,
      experimentName,
    })
    yield* put(setTestGroup(response, experimentName))
  } catch (error) {
    Sentry.captureException(error)
    yield* put(getTestGroupError(error))
  }
}

export function* experimentSaga(): Saga<*> {
  yield* takeLatest(getTestGroup, handleGetTestGroup)
}
