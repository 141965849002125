// @flow
// $FlowOptOut
import React, { useEffect } from 'react'

import { connect } from 'react-redux'

import { Changelogs } from 'components'

import { changelogActions } from './actions'
import { changelogSelectors } from './selectors'

const mapActionCreators = {
  getChangelog: changelogActions.getChangelog,
}

const mapStateToProps = (state) => ({
  changelogs: changelogSelectors.getChangelogs(state),
})

type Props = {|
  changelogs: any,
  getChangelog: () => void,
|}

const ChangelogContainer = ({ changelogs, getChangelog }: Props) => {
  useEffect(() => {
    getChangelog()
  }, [])

  return <Changelogs changelogs={changelogs} />
}

export default connect(mapStateToProps, mapActionCreators)(ChangelogContainer)
