// @flow
import React from 'react'

import type { ReferenceDataOptions } from '@elder/common'
import { Header, FormikSelect, spacings } from '@elder/common'
import { Button } from '@mui/material'
import { Formik, Form } from 'formik'
import styled from 'styled-components'
import * as Yup from 'yup'

import { SectionHeader } from 'components/styledComponentsTranslatedClasses/SectionHeader'

const StyledPrimaryButton = styled(Button)`
  padding: ${spacings.tiny};
  margin: ${spacings.halfTiny};
`

const StyledTertiaryButton = styled(Button)`
  padding: ${spacings.tiny};
  margin: ${spacings.halfTiny};
`

const StyledButtonGroup = styled.div`
  display: flex;
  justify-content: flex-end;
  margin: ${spacings.tiny};
`

const StyledFormikSelect = styled(FormikSelect)`
  width: 370px;
`

type Props = {|
  +cancelEdit: () => void,
  +updateCareModelType: (string, string) => void,
  +accountId: string,
  +careModelFlag: string,
  +careModelOptions: ReferenceDataOptions,
|}

export const CareModelTypeForm = ({
  cancelEdit,
  updateCareModelType,
  accountId,
  careModelFlag,
  careModelOptions,
}: Props) => {
  const handleFormSubmit = (data) => {
    updateCareModelType(accountId, data.careModelType)
    cancelEdit()
  }

  return (
    <Formik
      onSubmit={handleFormSubmit}
      initialValues={{ careModelType: careModelFlag }}
      validationSchema={Yup.object({ careModelType: Yup.string() })}
    >
      {({ submitCount, handleSubmit, errors }) => (
        <Form>
          <SectionHeader>
            <Header label="Model of Care (edit mode)" level="h3" />
          </SectionHeader>
          <StyledFormikSelect
            name="careModelType"
            label="Select model of care:"
            options={careModelOptions.options}
            hideSelectedOptions
            errorLabel={submitCount > 0 ? errors.accountExecutive : null}
          />

          <StyledButtonGroup>
            <StyledTertiaryButton onClick={cancelEdit} variant="text">
              Cancel
            </StyledTertiaryButton>
            <StyledPrimaryButton onClick={handleSubmit} variant="contained">
              Save
            </StyledPrimaryButton>
          </StyledButtonGroup>
        </Form>
      )}
    </Formik>
  )
}
