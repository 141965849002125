// @flow
import React from 'react'

import { Stack } from '@mui/material'
import { Form } from 'formsy-react-components'

import { ButtonSubmit, GenericButton, ContentPage } from 'components'
import { BreadcrumbsBar } from 'features/header/BreadcrumbsBar'
import * as routes from 'routes'

type Props = {|
  +postNewAccount: () => void,
  cancel: () => void,
|}

export const CreateAccount = ({ postNewAccount, cancel }: Props) => (
  <>
    <BreadcrumbsBar
      breadcrumbs={[
        { title: 'Accounts', to: routes.accounts },
        { title: 'New', to: routes.createAccount },
      ]}
    />
    <ContentPage>
      <h2>Create New Account</h2>
      <p>
        Creating accounts should normally be done automatically from Salesforce,
        if you do need to create one manually, hit the button below and add
        details on the newly created account as needed.
      </p>

      <Form onSubmit={postNewAccount}>
        <Stack direction="row" spacing={1} justifyContent="end" mt={4}>
          <GenericButton text="Cancel" onClick={cancel} quiet />
          <ButtonSubmit text="Create account" />
        </Stack>
      </Form>
    </ContentPage>
  </>
)

export default CreateAccount
