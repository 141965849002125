// @flow
import type { ConnectedComponentClass } from 'react-redux'
import { connect } from 'react-redux'

import type { Props as InnerProps } from 'features/notes/AddNoteModal'
import { AddNoteModal } from 'features/notes/AddNoteModal'
import type { Template } from 'features/notes/domain'
import { saveNote } from 'features/notes/store/actions'
import { savingSelector } from 'features/notes/store/selectors'

export type Props = {|
  +closeDialog: () => void,
  +notesEntityType: string,
  +notesEntityId: string,
  +templates?: Array<Template>,
|}

const mapActionCreators = (
  dispatch,
  { notesEntityType, notesEntityId, closeDialog }: Props,
) => ({
  saveNote: (noteParams) =>
    dispatch(
      saveNote({
        notesEntityType,
        notesEntityId,
        noteParams,
        onSuccess: () => closeDialog(),
      }),
    ),
})

const mapStateToProps = (state) => ({
  saving: savingSelector(state),
})

export const AddNoteModalContainer: ConnectedComponentClass<Props, InnerProps> =
  connect(mapStateToProps, mapActionCreators)(AddNoteModal)
