import React, { useContext } from 'react'

import { Box } from '@mui/material'
import { groupBy } from 'lodash'
import type { Moment } from 'moment'
import moment from 'moment'
import { useHistory } from 'react-router-dom'

import { SolutionTimeline } from 'components/solutions/SolutionTimeline/SolutionTimeline'
import type { TimelineBlock } from 'components/solutions/SolutionTimeline/SolutionTimeline'
import { getColorLookupBy } from 'features/solutionsTimeline/placement-colors'
import * as routes from 'routes'
import { useAccountContext } from 'routes/account/accountContext'
import { useSolutionContext } from 'routes/account/solutions/contexts/solution'
import { workflowContext } from 'routes/account/solutions/contexts/workflow'
import { formatLink } from 'utils/links'

import { CreateBlock, makeCreateBlocks } from './CreateBlock'
import type { VisitingData, VisitingPlacement } from './types'
import { VisitingBlock } from './VisitingBlock'

type VisitingTimelineBlock = TimelineBlock<VisitingData>

function getSaturation(status?: string) {
  switch (status) {
    case 'DRAFT':
      return '50%'
    case 'CANCELLED':
      return '0%'
    default:
      return '100%'
  }
}

const makeBlocks = (
  placements: VisitingPlacement[],
  daysDisplayed: number,
  accountId: string,
  focusedDate?: Moment,
): VisitingTimelineBlock[] => {
  if (!focusedDate) throw new Error('No focusedDate given')
  const viewStart = focusedDate.clone().subtract(daysDisplayed / 2, 'days')
  const colorLookup = getColorLookupBy(
    placements.map((p) => ({
      startDateInclusive:
        p.type === 'regular' ? p.details.startDateInclusive : p.details.date,
      professionalId: p.carerId!,
      id: p.id,
    })),
    'professionalId',
  )

  return Object.entries(placements).map(([placementId, placement]) => {
    const { details, type } = placement
    const isMatching = Boolean(placement.matchRequestId)
    const colors = colorLookup[placement.id]

    if (type === 'regular') {
      const start = moment(details.startDateInclusive)

      const end = details.endDateExclusive
        ? moment(details.endDateExclusive)
        : null

      const blockStart = start.clone()
      const blockEnd = end?.clone() || moment(start).clone().add(10, 'years')

      const diffFromStartDate = blockStart.diff(viewStart, 'days')
      const from = diffFromStartDate

      const diffToEndDate = end
        ? end.clone().diff(viewStart, 'days')
        : daysDisplayed
      const to = diffToEndDate

      const data: VisitingData = {
        isMatching,
        timing: `${details.hoursPerWeek}hrs`,
        placement,
      }

      const regularVisitBlock = {
        id: placementId,
        from,
        to,
        isInfinite: !end,
        startDateInclusive: blockStart,
        endDateExclusive: blockEnd,
        colors,
        data,
      } as VisitingTimelineBlock

      return regularVisitBlock
    } else {
      const date = moment(details.date)

      const blockStart = date.clone().startOf('isoWeek')
      const blockEnd = blockStart.clone().add(1, 'week')

      const diffFromStartDate = blockStart.diff(viewStart, 'days')

      const from = diffFromStartDate

      const diffToEndDate = blockEnd.diff(viewStart, 'days')
      const to = diffToEndDate

      const data: VisitingData = {
        isMatching,
        timing: `${details.durationHours}hrs`,
        placement,
      }

      const singleVisitBlock = {
        id: placementId,
        from,
        to,
        isInfinite: false,
        startDateInclusive: blockStart,
        endDateExclusive: blockEnd,
        colors,
        data,
      } as VisitingTimelineBlock

      return singleVisitBlock
    }
  })
}

const isBetween = (date: Moment, start: Moment, end?: Moment): boolean =>
  date.isBetween(start, end, 'day', '[)')

const blocksOverlap = (
  blockA: VisitingTimelineBlock,
  blockB: VisitingTimelineBlock,
): boolean =>
  isBetween(
    blockA.startDateInclusive,
    blockB.startDateInclusive,
    blockB.endDateExclusive,
  ) ||
  isBetween(
    blockA.endDateExclusive,
    blockB.startDateInclusive,
    blockB.endDateExclusive,
  )

const blockFitsIntoLane = (
  lane: VisitingTimelineBlock[],
  blockToFit: VisitingTimelineBlock,
): boolean => !lane.some((block) => blocksOverlap(blockToFit, block))

type Lane = VisitingTimelineBlock[]
const sortBlocksIntoLanes = (blocks: VisitingTimelineBlock[]): Lane[] => {
  const regularLanes: Lane[] = []

  const regulars = blocks.filter(
    (block) => block.data.placement.type === 'regular',
  )
  const regularGroups = groupBy(regulars, (block) => block.data.placement.id)
  for (const group of Object.values(regularGroups)) {
    const lane = regularLanes.find((lane) =>
      group.every((block) => blockFitsIntoLane(lane, block)),
    )
    if (lane) lane.push(...group)
    else regularLanes.push(group)
  }

  const singlesLanes: Lane[] = []

  const singles = blocks.filter(
    (block) => block.data.placement.type === 'single',
  )

  for (const block of singles) {
    const lane = singlesLanes.find((lane) => blockFitsIntoLane(lane, block))
    if (lane) lane.push(block)
    else singlesLanes.push([block])
  }

  const lanes = [...regularLanes, ...singlesLanes]

  return lanes
}

const height = 36 // px

type Props = {
  daysDisplayed: number
  setFocus: (date: Moment) => void
  focus: Moment
  placementId?: string
  visitingPlacements: VisitingPlacement[]
  checkedBlocks?: string[]
  setCheckedBlocks?: (checkedBlocks: string[]) => void
}

export const VisitingTimeline = ({
  daysDisplayed,
  setFocus,
  focus,
  placementId,
  visitingPlacements,
  checkedBlocks,
  setCheckedBlocks,
}: Props) => {
  const { accountId } = useAccountContext()

  const { solution: currentSolution } = useSolutionContext()
  const { status } = currentSolution
  const saturation = getSaturation(status)
  const blocks = makeBlocks(visitingPlacements, daysDisplayed, accountId, focus)

  const lanes = sortBlocksIntoLanes(blocks)

  const createBlocks = makeCreateBlocks(daysDisplayed, focus)

  const workflowContextValue = useContext(workflowContext)

  const { push } = useHistory()
  const goToPlacement = (placementId: string) =>
    push(
      formatLink(routes.placement, {
        accountId,
        solutionId: currentSolution.id,
        placementId,
      }),
    )

  const toggleBlockChecked = (blockId: string) => {
    if (!setCheckedBlocks) return
    if (!checkedBlocks) return

    const alreadyChecked = checkedBlocks.includes(blockId)

    if (!alreadyChecked) setCheckedBlocks([...checkedBlocks, blockId])
    else setCheckedBlocks(checkedBlocks.filter((id) => id !== blockId))
  }

  return (
    <>
      {lanes.map((blocks) => (
        <Box mb={1} key={blocks.map((b) => b.id).join('_')}>
          <SolutionTimeline
            height={36}
            blocks={blocks}
            saturation={saturation}
            daysDisplayed={daysDisplayed}
            onBeforeDotClick={(block) =>
              block.endDateExclusive && setFocus(block.endDateExclusive)
            }
            onAfterDotClick={(block) => setFocus(block.startDateInclusive)}
            disableStops
          >
            {(blocksInView: VisitingTimelineBlock[]) =>
              blocksInView.map((block) => {
                const { id } = block.data.placement
                const isSelected = !!placementId && id === placementId
                return (
                  <VisitingBlock
                    key={block.id}
                    isSelected={isSelected}
                    block={block}
                    height={height}
                    daysDisplayed={daysDisplayed}
                    onClick={(e) => {
                      if (e.ctrlKey) toggleBlockChecked(id)
                      else goToPlacement(id)
                    }}
                    onCheckChange={() => toggleBlockChecked(id)}
                    checked={checkedBlocks?.includes(id)}
                  />
                )
              })
            }
          </SolutionTimeline>
        </Box>
      ))}
      {workflowContextValue && (
        <Box>
          <SolutionTimeline
            height={36}
            blocks={createBlocks}
            saturation={saturation}
            daysDisplayed={daysDisplayed}
            disableStops
            disableDots
          >
            {(blocksInView: VisitingTimelineBlock[]) =>
              blocksInView.map((block) => {
                return (
                  <CreateBlock
                    key={block.id}
                    block={block}
                    height={height}
                    daysDisplayed={daysDisplayed}
                    onClick={() =>
                      workflowContextValue.setActiveWorkflow({
                        name: 'addVisitPlacement',
                        slot: currentSolution.placementSlots[0],
                        date: block.startDateInclusive,
                      })
                    }
                  />
                )
              })
            }
          </SolutionTimeline>
        </Box>
      )}
    </>
  )
}
