// @flow
import { makeAction } from 'elder/actions'

import { addNamespace } from 'utils'

export const NAMESPACE = 'LOADING'
export const SET_LOADING = addNamespace(NAMESPACE, 'SET_LOADING')
export const CLEAR_LOADING = addNamespace(NAMESPACE, 'CLEAR_LOADING')

export const setLoading = makeAction(SET_LOADING, (id: string) => ({
  id,
}))

export const clearLoading = makeAction(CLEAR_LOADING, (id: string) => ({
  id,
}))
