import { delay } from 'redux-saga'
import { call, put, race } from 'redux-saga/effects'
import 'whatwg-fetch'

import { restart } from 'app'
import { getConfig } from 'app/config'
import { ACCESS_TOKEN } from 'app/login/sagas'
import { applicationError } from 'features/snackbar/snackbar'
import { getLocalItem } from 'utils/storage'

import { buildQuery } from './buildQuery'
import {
  describeServiceError,
  ElderServiceError,
  NativeServiceError,
} from './errors'

import { SERVICE_ERROR } from '.'

const { rest: elderRestConfig } = getConfig()

const TIMEOUT = 30000

const performFetch = (url, method, headers, body) => {
  const checkStatus = (response) => {
    if (response.status === 401) {
      throw new Error(SERVICE_ERROR.UNAUTHORIZED)
    } else {
      return response
    }
  }

  return fetch(url, {
    method,
    headers,
    body: body ? JSON.stringify(body) : null,
  }).then(checkStatus)
}

const performExternalFetch = (url, method, headers, body) => {
  const checkStatus = (response) => {
    if (!response.ok || !(response.status >= 200 && response.status <= 299)) {
      throw new NativeServiceError(
        `Service returned error ${response.status}`,
        response.status,
        response,
      )
    }
  }

  return fetch(url, {
    method,
    headers,
    body,
  }).then(checkStatus)
}

const getResponseBody = (promise) => promise.then((response) => response)

function* verifyResponse(response, readResponse) {
  if (response.ok && response.status >= 200 && response.status <= 299) {
    if (response.status === 204) {
      // This happens when no response body is returned from the server.
      return null
    }

    const responseBody = yield call(getResponseBody, readResponse(response))
    if (responseBody) {
      return responseBody
    }
    throw new Error(SERVICE_ERROR.INVALID_RESPONSE)
  } else {
    // this is to get the body in case of error
    let errorResponseBody = null
    try {
      errorResponseBody = yield call(getResponseBody, response.json())
    } catch (error) {
      // Don't double die if the payload isn't a valid JSON, it may just mean its not
      // an Elder error ...
    }

    if (errorResponseBody && errorResponseBody.responseType === 'ELDER_ERROR') {
      throw new ElderServiceError(
        errorResponseBody.message,
        response.status,
        errorResponseBody.errorCode,
        errorResponseBody.errorDetails,
        response,
      )
    }

    throw new NativeServiceError(
      `Service returned error ${response.status}`,
      response.status,
      response,
    )
  }
}

export function* externalFetchFlow(
  url,
  method,
  headers,
  body,
  timeout = TIMEOUT,
) {
  const { timedOut } = yield race({
    response: call(performExternalFetch, url, method, headers, body),
    timedOut: call(delay, timeout),
  })

  if (timedOut) {
    throw new Error(SERVICE_ERROR.TIMEOUT)
  }
}

/**
 * Generates a random 64-bit lowercase hex string.
 */
const generateSpanId = () =>
  Math.round(Math.random() * 0xffffffff)
    .toString(16)
    .padStart(8, '0') +
  Math.round(Math.random() * 0xffffffff)
    .toString(16)
    .padStart(8, '0')

export function* fetchFlow(endpoint, method, body, expectedType = 'json') {
  const url = elderRestConfig.baseEndpoint + endpoint
  const authToken = getLocalItem(ACCESS_TOKEN)
  const spanId = generateSpanId()
  const traceId = generateSpanId() + spanId

  const headers = {
    Authorization: `Bearer ${authToken}`,
    'Content-Type': 'application/json',
    'X-B3-TraceId': traceId,
    'X-B3-SpanId': spanId,
  }

  try {
    const { response, timeout } = yield race({
      response: call(performFetch, url, method, headers, body),
      timeout: call(delay, TIMEOUT),
    })

    if (timeout) {
      throw new Error(SERVICE_ERROR.TIMEOUT)
    } else {
      const resolver = {
        none: (r) => Promise.resolve(r),
        text: (r) => r.text(),
        json: (r) => r.json(),
        blob: (r) => r.blob(),
      }[expectedType]

      return yield call(verifyResponse, response, resolver)
    }
  } catch (e) {
    if (e.message === SERVICE_ERROR.UNAUTHORIZED) {
      yield put(restart())
    }
    throw e
  }
}

export function* putFlow(url, data, responseType = 'json') {
  return yield call(fetchFlow, url, 'PUT', data, responseType)
}

export function* postFlow(url, data, responseType = 'json') {
  return yield call(fetchFlow, url, 'POST', data, responseType)
}

export function* getFlow(endpoint, params = null, expectedType = 'json') {
  let url = endpoint
  if (params) {
    url += buildQuery(params)
  }
  return yield call(fetchFlow, url, 'GET', null, expectedType)
}

export function* deleteFlow(endpoint, params = null, expectedType = 'json') {
  let url = endpoint
  if (params) {
    url += buildQuery(params)
  }
  return yield call(fetchFlow, url, 'DELETE', null, expectedType)
}

export function* getBlobFlow(endpoint, params) {
  return yield call(getFlow, endpoint, params, 'blob')
}

export function* getTextFlow(endpoint, params) {
  return yield call(getFlow, endpoint, params, 'text')
}

export function* getAndSetFlow(endpoint, setter, params) {
  try {
    const result = yield call(getFlow, endpoint, params, 'json')
    yield put(setter(result))
  } catch (error) {
    applicationError(
      describeServiceError(error, `Error with service call ${endpoint}`),
    )
  }
}

export default fetchFlow
