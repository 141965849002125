// @flow
import { createSelector } from 'reselect'

import { makeSelectors } from 'modules/pageIdAndIdentity'

import { NAMESPACE } from './actions'

export const pageSelectors = makeSelectors((state) => state.get(NAMESPACE))

export const getProfileFullName = createSelector(
  [pageSelectors.getIdentity],
  (profileIdentity) => (profileIdentity ? profileIdentity.get('fullName') : ''),
)
