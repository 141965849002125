// @flow
import type { Saga } from 'redux-saga'
import { call, put, takeLatest } from 'redux-saga/effects'

import { setCarer } from 'features/carerPageGenerics/store/actions'
import { setLoading, clearLoading } from 'features/loading/actions'
import {
  applicationError,
  applicationSuccess,
} from 'features/snackbar/snackbar'
import { fetchFlow } from 'utils/services'
import { describeServiceError } from 'utils/services/errors'

import { SET_COMMUNICATION_PREFERENCES, NAMESPACE } from './store/actions'

function* handleSetCommunicationPreferences({
  preferences,
  carerId,
}: {
  +preferences: Object,
  +carerId: string,
}): Generator<Object, void, Object> {
  const endpoint = `/et/professionals/${carerId}/setCommunicationPreferences`
  const method = 'PUT'
  try {
    yield put(setLoading(NAMESPACE))
    const response = yield call(fetchFlow, endpoint, method, preferences)
    yield put(setCarer(response))
    applicationSuccess({
      title: 'Preferences saved',
      message: 'Notification preferences were updated successfully.',
    })
  } catch (error) {
    applicationError(
      describeServiceError(error, `Error with service call ${endpoint}`),
    )
  } finally {
    yield put(clearLoading(NAMESPACE))
  }
}

export function* saga(): Saga<*> {
  yield takeLatest(
    SET_COMMUNICATION_PREFERENCES,
    handleSetCommunicationPreferences,
  )
}
