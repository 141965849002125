// @flow

import { Type, ValidationError } from 'flow-validator'

export type Action<T: string, P: Object> = $ReadOnly<{ type: T } & P>

// TODO: Extend this with as many case as we need for more parameters
export type ActionCreator<AT: string> = { actionType: AT }
export type ActionCreator0<AT, A: Action<AT, *>> = {
  (): A,
  actionType: AT,
  arity: 0,
}
export type ActionCreator1<AT, A: Action<AT, *>, T1> = {
  (p1: T1): A,
  actionType: AT,
  arity: 1,
}

export type ActionCreator2<AT, A: Action<AT, *>, T1, T2> = {
  (p1: T1, p2: T2): A,
  actionType: AT,
  arity: 2,
}

export type ActionCreator3<AT, A: Action<AT, *>, T1, T2, T3> = {
  (p1: T1, p2: T2, p3: T3): A,
  actionType: AT,
  arity: 3,
}

export type ActionCreator4<AT, A: Action<AT, *>, T1, T2, T3, T4> = {
  (p1: T1, p2: T2, p3: T3, p4: T4): A,
  actionType: AT,
  arity: 4,
}

export type ActionCreatorAny<AT, A: Action<AT, *>> =
  | ActionCreator0<AT, A>
  | ActionCreator1<AT, A, any>
  | ActionCreator2<AT, A, any, any>
  | ActionCreator3<AT, A, any, any, any>
  | ActionCreator4<AT, A, any, any, any, any>

export type Reducer<S> = (state: S | void, action: Action<any, any>) => S

export type Selector<S, T> = (S) => T

export type Dispatch<T: Action<*, *>> = (T) => void

export type UnwrapType = <T>(Type<T>) => T

// Gets the static Flow type from a runtime Flow Validated type
export type FlowOfType<T> = $Call<UnwrapType, T>

// Gets the static Flow type from a flow-validated runtime object
export type TypeOfValidated<T> = $Exact<FlowOfType<T>>

// Gets the action type from an action creator
export type ActionOf<T> = $Call<T, *, *, *, *, *, *, *, *, *>

// Gets the type of state produced by a reducer
export type ReducerStateOf<T> = $Call<T, *, *>

// Define validator for enums
export const enumFromObjectKeys = <O: Object>(o: O): Type<$Keys<O>> => {
  const en = new Type('enum', (v) => {
    const keys = Object.keys(o)
    if (~keys.indexOf(v)) return ((v: any): $Keys<O>) // eslint-disable-line no-bitwise
    throw new ValidationError({ expected: en, got: v })
  })
  return en
}

// https://github.com/facebook/flow/issues/5276#issuecomment-346975015
export type EnumMap<K: string, V, O: Object = *> = O & {
  [K]: V & $ElementType<O, K>,
}

// Make T exact and readonly
export type PropsOf<T> = $Exact<$ReadOnly<T>>
