// @flow
import type { RefDataEnum } from '@elder/common'
import * as Sentry from '@sentry/react'
import { replace } from 'connected-react-router'
import type { Saga } from 'redux-saga'

import { call, takeLatest, put } from 'elder/effects'

import { professionalClient } from 'app/saga/serviceClients'
import {
  getInvoices,
  setInvoices,
  closeInvoice,
  createManualTransaction,
  cancelTransaction,
  invoicesError,
} from 'features/carerFinances/finance/store/actions'
import type { ManualTransactionType } from 'features/carerFinances/finance/types'
import {
  applicationError,
  applicationSuccess,
} from 'features/snackbar/snackbar'
import { describeServiceError } from 'utils/services'

function* handleGetInvoices({ carerId }: { +carerId: string }): Saga<*> {
  try {
    const response = yield* call(professionalClient.getInvoices, {
      carerId,
    })
    yield* put(setInvoices(response.invoices))
  } catch (error) {
    Sentry.captureException(error)
    applicationError(describeServiceError(error, 'Error with service call'))
    yield* put(invoicesError())
  }
}

function* handleCloseInvoice({
  carerId,
  regulatoryModel,
}: {
  +carerId: string,
  +regulatoryModel: RefDataEnum,
}): Saga<*> {
  try {
    const response = yield* call(professionalClient.closeInvoice, {
      carerId,
      regulatoryModel,
    })
    yield* put(setInvoices(response.invoices))
    applicationSuccess({
      title: 'Professional Finance updated',
      message: 'Invoice closed successfully.',
    })
  } catch (error) {
    Sentry.captureException(error)
    applicationError(describeServiceError(error, 'Error with service call'))
    yield* put(invoicesError())
  }
}

function* handleCreateManualTransaction({
  carerId,
  regulatoryModel,
  data,
}: {
  +carerId: string,
  +regulatoryModel: string,
  +data: ManualTransactionType,
}): Saga<*> {
  try {
    yield* call(professionalClient.createManualTransaction, {
      carerId,
      regulatoryModel,
      data,
    })
    applicationSuccess({
      title: 'Transaction created',
      message: 'Transaction was created successfully.',
    })
    yield* put(replace(`/professionals/${carerId}/finance?expanded=true`))
  } catch (error) {
    Sentry.captureException(error)
    applicationError(describeServiceError(error, 'Error with service call'))
    yield* put(invoicesError())
  }
}

function* handleCancelTransaction({
  carerId,
  regulatoryModel,
  transactionId,
}: {
  +carerId: string,
  +regulatoryModel: RefDataEnum,
  +transactionId: string,
}): Saga<*> {
  try {
    const response = yield* call(professionalClient.cancelTransaction, {
      carerId,
      regulatoryModel,
      transactionId,
    })
    yield* put(setInvoices(response.invoices))
    applicationSuccess({
      title: 'Professional Finance updated',
      message: 'Transaction cancelled successfully.',
    })
  } catch (error) {
    Sentry.captureException(error)
    applicationError(describeServiceError(error, 'Error with service call'))
    yield* put(invoicesError())
  }
}

export function* carerFinanceSaga(): Saga<*> {
  yield* takeLatest(getInvoices, handleGetInvoices)
  yield* takeLatest(closeInvoice, handleCloseInvoice)
  yield* takeLatest(createManualTransaction, handleCreateManualTransaction)
  yield* takeLatest(cancelTransaction, handleCancelTransaction)
}
