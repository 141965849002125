// @flow
import Immutable from 'immutable'

import { GET_IDENTITY, SET_IDENTITY } from 'modules/pageIdAndIdentity/actions'
import { addNamespace } from 'utils'

export const makeIdReducer = (namespace: string) => {
  const initialState = Immutable.Map()
  const getIdentity = addNamespace(namespace, GET_IDENTITY)
  const setIdentity = addNamespace(namespace, SET_IDENTITY)

  return (state: Object = initialState, action: Object) => {
    switch (action.type) {
      case getIdentity:
        return initialState
      case setIdentity:
        return Immutable.fromJS(action.identity)
      default:
        return state
    }
  }
}

export default makeIdReducer
