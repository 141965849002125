// @flow
import { colors } from '@elder/common'
import styled from 'styled-components'

export const AccountEntry = styled.div`
  align-items: center;
  border-bottom: 1px solid ${colors.blue200};
  display: flex;
  padding: 10px 0;
  white-space: pre-wrap;
  word-break: break-word;

  > span,
  > label,
  > div {
    width: 50%;
  }

  > span,
  > label,
  > strong {
    color: ${colors.lightSmoke};
  }

  input[type='text'],
  input[type='email'],
  input[type='date'],
  textarea {
    width: 100%;
  }

  & > .edit-button {
    display: inline-block;
    max-width: 50px;
  }
`
