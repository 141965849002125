// @flow
import type { ConnectedComponentClass } from 'react-redux'
import { connect } from 'react-redux'

import { getIsLoadingSelector } from 'features/loading/selectors'
import type { Note, Template } from 'features/notes/domain'
import { EditNoteModal } from 'features/notes/EditNoteModal'
import { editNote, editNoteModal } from 'features/notes/store/actions'

export const loadingId = 'edit-notes-loading-state'

const mapActionCreators = (
  dispatch,
  { notesEntityType, notesEntityId, note },
) => ({
  close: (noteId) => dispatch(editNoteModal({ noteId, isOpen: false })),
  editNote: (noteParams) =>
    dispatch(
      editNote({ notesEntityType, notesEntityId, noteId: note.id, noteParams }),
    ),
})

const mapStateToProps = (state) => ({
  isSaving: getIsLoadingSelector(state, loadingId),
})

type Props = {|
  +notesEntityType: string,
  +notesEntityId: string,
  +note: Note,
  +templates?: Array<Template>,
|}

export const EditNoteModalContainer: ConnectedComponentClass<Props, *> =
  connect(mapStateToProps, mapActionCreators)(EditNoteModal)
