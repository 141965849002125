// @flow
import type { ConnectedComponentClass } from 'react-redux'
import { connect } from 'react-redux'

import type { Template } from 'features/notes/domain'
import type { Props as InnerProps } from 'features/notes/NotesSection'
import { NotesSection } from 'features/notes/NotesSection'
import {
  editNoteModal,
  setArchived,
  setPinnedNote,
} from 'features/notes/store/actions'
import {
  makeGetNotesByEntity,
  makeGetNotesStatusByEntity,
  notesUpdatingSelector,
  noteToEditSelector,
} from 'features/notes/store/selectors'

const mapActionCreators = (dispatch, { notesEntityType, notesEntityId }) => ({
  pinNote: (noteId) =>
    dispatch(
      setPinnedNote({ notesEntityType, notesEntityId, noteId, pinned: true }),
    ),
  unpinNote: (noteId) =>
    dispatch(
      setPinnedNote({ notesEntityType, notesEntityId, noteId, pinned: false }),
    ),
  archiveNote: (noteId) =>
    dispatch(
      setArchived({ notesEntityType, notesEntityId, noteId, archive: true }),
    ),
  unarchiveNote: (noteId) =>
    dispatch(
      setArchived({ notesEntityType, notesEntityId, noteId, archive: false }),
    ),
  openEditNoteModal: (noteId) =>
    dispatch(editNoteModal({ noteId, isOpen: true })),
})

// As per https://github.com/reduxjs/reselect#sharing-selectors-with-props-across-multiple-component-instances
const mapStateToProps = () => {
  const statusSelector = makeGetNotesStatusByEntity()
  const notesSelector = makeGetNotesByEntity()

  return (state, props) => {
    const status = statusSelector(state, props)
    const notes = notesSelector(state, props)
    const notesUpdating = notesUpdatingSelector(state, props)

    return {
      loading: status === 'LOADING' || status === 'INITIAL',
      failed: status === 'FAILED',
      notes,
      notesUpdating,
      editNoteId: noteToEditSelector(state),
    }
  }
}

type Props = {|
  +notesEntityType: string,
  +notesEntityId: string,
  +templates?: Array<Template>,
  +isReadOnly?: boolean,
  +createNote: () => void,
|}

export const NotesSectionContainer: ConnectedComponentClass<Props, InnerProps> =
  connect(mapStateToProps, mapActionCreators)(NotesSection)
