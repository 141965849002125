// @flow
import type { Saga } from 'redux-saga'
import { call, select, put, takeLatest } from 'redux-saga/effects'

import { pageSelectors } from 'routes/account/selectors'
import {
  DOWNLOAD_CARE_INFO_CARER_PDF,
  buttonSpinnerCareInfoCarerActions,
} from 'routes/account/summary/actions'
import { prettyCompactTodayDate } from 'utils/dateDecorators'
import { downloadFile } from 'utils/downloadFile'
import { getBlobFlow } from 'utils/services'

function* fetchPdfFlow(): Generator<*, void, *> {
  yield put(buttonSpinnerCareInfoCarerActions.setStartLoading())

  const acctId = yield select(pageSelectors.getId)
  const response = yield call(
    getBlobFlow,
    `/et/accounts/${acctId}/placementinformation`,
  )

  const date = prettyCompactTodayDate()
  const filename = `Placement Information - ${date} - ${acctId}`
  yield call(downloadFile, response, filename)

  yield put(buttonSpinnerCareInfoCarerActions.setStopLoading())
}

export function* watchDownloadCareInfoCarerPdf(): Saga<*> {
  yield takeLatest(DOWNLOAD_CARE_INFO_CARER_PDF, fetchPdfFlow)
}
