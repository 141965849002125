// @flow
import { replace } from 'connected-react-router'
import type { Saga } from 'redux-saga'
import { call, takeLatest, put } from 'redux-saga/effects'

import {
  applicationError,
  applicationSuccess,
} from 'features/snackbar/snackbar'
import { fetchFlow, describeServiceError } from 'utils/services'

import { SET_SF_ID } from './actions'

function* fetchImportedAccountIdFlow(action: Object): Generator<*, void, *> {
  const endpoint = `/et/accounts/salesforce/${action.sfId}`
  const method = 'POST'

  try {
    const responseBody = yield call(fetchFlow, endpoint, method)
    applicationSuccess({
      title: 'Account imported',
      message: `Redirecting to imported account ${responseBody.accountId}`,
    })
    yield put(replace(`/accounts/${responseBody.accountId}`))
  } catch (error) {
    applicationError(
      describeServiceError(error, `Error with service call ${endpoint}`),
    )
  }
}

export function* watchAccountSalesforce(): Saga<*> {
  yield takeLatest(SET_SF_ID, fetchImportedAccountIdFlow)
}

export default watchAccountSalesforce
