// @flow
import type { Saga } from 'redux-saga'

import { takeLatest, put, call } from 'elder/effects'

import { accountExecutiveClient } from 'app/saga/serviceClients'
import {
  updateAccountExecutiveHierarchy,
  updateAccountExecutiveHierarchyError,
  generateAccountExecutiveHierarchy,
  generateAccountExecutiveHierarchyError,
  NAMESPACE,
} from 'features/accountExecutive/store/actions'
import { clearLoading, setLoading } from 'features/loading/actions'
import {
  applicationError,
  applicationSuccess,
} from 'features/snackbar/snackbar'
import { pageActions } from 'routes/account/summary/actions'
import { describeServiceError } from 'utils/services'

function* handleUpdateHierarchy({ accountId, hierarchy }): Saga<*> {
  yield* put(setLoading(NAMESPACE))
  try {
    yield* call(accountExecutiveClient.updateHierarchy, {
      accountId,
      hierarchy,
    })
    yield* put(pageActions.get(accountId))
    applicationSuccess({
      title: 'Hierarchy Saved',
      message: 'Account executive hierarchy successfully updated',
    })
  } catch (error) {
    yield* put(updateAccountExecutiveHierarchyError(error))
    applicationError(
      describeServiceError(
        error,
        'Unable to update account executive hierarchy',
      ),
    )
  } finally {
    yield* put(clearLoading(NAMESPACE))
  }
}

function* handleGenerateHierarchy({ accountId }): Saga<*> {
  yield* put(setLoading(NAMESPACE))
  try {
    yield* call(accountExecutiveClient.generateHierarchy, {
      accountId,
    })
    yield* put(pageActions.get(accountId))
    applicationSuccess({
      title: 'Hierarchy Generated',
      message: 'Account executive hierarchy successfully generated',
    })
  } catch (error) {
    yield* put(generateAccountExecutiveHierarchyError(error))

    applicationError(
      describeServiceError(
        error,
        'Unable to generate account executive hierarchy',
      ),
    )
  } finally {
    yield* put(clearLoading(NAMESPACE))
  }
}

export function* accountExecutiveHierarchySagas(): Saga<*> {
  yield* takeLatest(updateAccountExecutiveHierarchy, handleUpdateHierarchy)
  yield* takeLatest(generateAccountExecutiveHierarchy, handleGenerateHierarchy)
}
