// @flow
import type { Saga } from 'redux-saga'
import { call, put, select, takeLatest } from 'redux-saga/effects'

import { applicationError } from 'features/snackbar/snackbar'
import { pageSelectors } from 'routes/account/selectors'
import {
  UPDATE_RECOMMENDED_RESOURCE,
  pageActions,
} from 'routes/account/summary/actions'
import { putFlow, describeServiceError } from 'utils/services'

function* updateRecommendedResources(action): Generator<*, void, *> {
  const { recommendedResources } = action

  const accountId = yield select(pageSelectors.getId)
  const endpoint = `/et/accounts/${accountId}/recommendedResources`
  const data = { recommendedResources }

  try {
    const accountSummary = yield call(putFlow, endpoint, data)
    yield put(pageActions.set(accountSummary))
  } catch (error) {
    applicationError(
      describeServiceError(error, `Error with service call ${endpoint}`),
    )
  }
}

export default function* (): Saga<*> {
  yield takeLatest(UPDATE_RECOMMENDED_RESOURCE, updateRecommendedResources)
}
