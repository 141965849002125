// @flow
import React from 'react'

import { colors } from '@elder/common'
import { Link } from 'react-router-dom'
import styled from 'styled-components'

import { AccountSection } from 'components/styledComponentsTranslatedClasses/AccountSection'
import { Entry } from 'components/styledComponentsTranslatedClasses/Entry'
import { SectionHeader } from 'components/styledComponentsTranslatedClasses/SectionHeader'
import type { InvoiceSummary as InvoiceSummaryProp } from 'domain/invoice'

export const HeaderLink = styled.h3`
  color: ${colors.blue600};
  cursor: pointer;
`

const StyledSectionHeader = styled(SectionHeader)`
  h3 {
    text-transform: none;
  }
`

const StyledEntry = styled(Entry)`
  & > .edit-button {
    display: inline-block;
    max-width: 50px;
  }
`

const TextLoud = styled.div`
  color: ${colors.coral600};
`

type Props = {|
  invoiceSummaryProp: InvoiceSummaryProp,
  financeUrl?: string,
|}

export const InvoiceSummary = ({ invoiceSummaryProp, financeUrl }: Props) => (
  <AccountSection>
    {financeUrl && (
      <Link to={financeUrl}>
        <StyledSectionHeader>
          <HeaderLink>Invoice Summary</HeaderLink>
        </StyledSectionHeader>
      </Link>
    )}
    <StyledEntry>
      <span>
        Outstanding balance
        {invoiceSummaryProp.includesBankTransfer &&
          ' (Excl. bank transfer invoices)'}
        :
      </span>
      <div>{invoiceSummaryProp.outstandingBalance}</div>
    </StyledEntry>
    <StyledEntry>
      <span>Last invoice / Payment Date:</span>
      <div>{invoiceSummaryProp.lastInvoice}</div>
    </StyledEntry>
    <StyledEntry>
      <span>
        Overdue balance
        {invoiceSummaryProp.includesBankTransfer &&
          ' (Excl. bank transfer invoices)'}
        :
      </span>
      <div>{invoiceSummaryProp.overdueBalance}</div>
    </StyledEntry>
    <StyledEntry>
      <span>Oldest unpaid invoice:</span>
      <TextLoud>{invoiceSummaryProp.oldestUnpaid}</TextLoud>
    </StyledEntry>
  </AccountSection>
)
