// @flow
import React from 'react'

import type { InputWrapperProps } from '@elder/common'
import { InputWrapper, typography } from '@elder/common'
import { Field } from 'formik'
import moment from 'moment'
import DatePicker from 'react-datepicker'
import styled from 'styled-components'

const DatePickerWrapper = styled.div`
  .react-datepicker-wrapper {
    ${({ fullWidth }) => fullWidth && 'display: block;'};
  }
  .react-datepicker__input-container {
    ${({ fullWidth }) => fullWidth && 'display: block;'};
  }
  input {
    background-image: none;
    background-color: ${({ theme }) => theme.backgroundColor};
    box-shadow: none;
    ${typography.paragraph1};
    width: 100%;
    border-radius: 4px !important;
    color: ${({ theme }) => theme.color};
    border: 1px solid ${({ theme }) => theme.borderColor} !important;
    pointer-events: ${({ theme }) => theme.pointerEvents};
    min-height: 48px;
    &:focus {
      transition:
        background-color 0.23s ease-in-out,
        transform 0.16s ease-in-out;
      outline: none;
      transform: none;
    }
  }
  .react-datepicker-popper {
    z-index: 100;
  }
`

type Props = $ReadOnly<{|
  +fullWidth?: boolean,
  +name: string,
  +monthsShown?: number,
  +minDate?: ?Object,
  +maxDate?: ?Object,
  +dateFormat?: string,
  +withPortal?: boolean,
  +popperPlacement?: string,
  +openToDate?: Object,
  +rangeAnchor?: {|
    // Whether the date specified below is the start or end of a range.
    +type: 'start' | 'end',
    +date: string,
  |},
  +clearable?: boolean,
  +disabled?: boolean,
  +className?: string,
  ...$Exact<InputWrapperProps>,
|}>

export const FormikDatePicker = ({
  fullWidth,
  name,
  monthsShown,
  minDate,
  maxDate,
  dateFormat = 'DD/MM/YYYY',
  withPortal,
  popperPlacement,
  openToDate,
  rangeAnchor,
  clearable = true,
  disabled,
  className,
  ...inputWrapperProps
}: Props) => {
  const dataDateFormat = 'YYYY-MM-DD'
  return (
    <Field name={name}>
      {({ field: { value }, form: { setFieldValue } }) => {
        const selected = value ? moment(value, dataDateFormat) : null

        // If we are specifying one extreme of a range, set the existing start/end dates according to the anchor (other side of the range).
        let startDateValue = null
        let endDateValue = null
        if (rangeAnchor) {
          const anchorDate = moment(rangeAnchor.date, dataDateFormat)
          if (rangeAnchor.type === 'start') {
            startDateValue = anchorDate
            endDateValue = selected
          } else {
            startDateValue = selected
            endDateValue = anchorDate
          }
        }

        return (
          <InputWrapper {...inputWrapperProps}>
            <DatePickerWrapper className={className} fullWidth={fullWidth}>
              <DatePicker
                selected={selected}
                disabled={disabled}
                selectsStart={rangeAnchor && rangeAnchor.type === 'end'}
                selectsEnd={rangeAnchor && rangeAnchor.type === 'start'}
                startDate={startDateValue}
                endDate={endDateValue}
                dateFormat={dateFormat}
                disabledKeyboardNavigation
                isClearable={clearable}
                locale="en-gb"
                minDate={minDate}
                maxDate={maxDate}
                onChange={(momentObj) => {
                  if (momentObj) {
                    setFieldValue(name, momentObj.format(dataDateFormat))
                  } else {
                    setFieldValue(name, null)
                  }
                }}
                popperPlacement={popperPlacement || 'top'}
                peekNextMonth
                showMonthDropdown
                showYearDropdown
                scrollableYearDropdown
                dropdownMode="select"
                todayButton="Today"
                withPortal={withPortal}
                monthsShown={monthsShown || 1}
                openToDate={openToDate}
              />
            </DatePickerWrapper>
          </InputWrapper>
        )
      }}
    </Field>
  )
}
