// @flow
import React from 'react'

import { colors } from '@elder/common'
import { Add } from '@mui/icons-material'
import { Button } from '@mui/material'
import styled from 'styled-components'

import { SummaryCard } from 'components'
import { SummaryCardActions } from 'components/SummaryCard/SummaryCardActions'
import { Table } from 'components/tables/Table'
import { LinkContainer } from 'containers'
import type { Account } from 'domain/account'
import type { SetActiveWorkflow } from 'features/activeWorkflow/types'
import { prettyCompactDate } from 'utils/dateDecorators'

const Icon = styled.div`
  cursor: pointer;
  color: ${colors.blue600};
`

const Wrap = styled.div`
  white-space: normal;
  word-wrap: break-word;
`

type Props = {|
  +account: Account,
  +canEdit: boolean,
  +setActiveWorkflow: SetActiveWorkflow,
  +defocused: boolean,
|}

export const CarerBlacklist = ({
  account,
  canEdit,
  setActiveWorkflow,
  defocused,
}: Props) => {
  const { carerBlacklist = [] } = account

  return (
    <SummaryCard
      width="FULL"
      heading="Carer BlackList"
      defocused={defocused}
      actionChildren={
        <SummaryCardActions>
          {canEdit && (
            <Button
              variant="contained"
              onClick={() => setActiveWorkflow('blacklistCarer')}
              startIcon={<Add />}
            >
              Add to blacklist
            </Button>
          )}
        </SummaryCardActions>
      }
    >
      <Table
        columnConfiguration={[
          { header: 'Name' },
          { header: 'Reason', width: '60%' },
          { header: 'Date' },
          {},
        ]}
        rows={carerBlacklist.map(
          ({ name, professionalId, details, timestamp }) => ({
            id: professionalId,
            columns: [
              <LinkContainer
                goto={`/professionals/${professionalId}`}
                text={name}
              />,
              <Wrap>{details}</Wrap>,
              prettyCompactDate(timestamp),
              <Icon
                onClick={() =>
                  setActiveWorkflow('removeCarerFromBlacklist', {
                    accountId: account.accountId,
                    professionalName: name,
                    professionalId,
                  })
                }
              >
                X
              </Icon>,
            ],
          }),
        )}
      />
    </SummaryCard>
  )
}
