// @flow
import type { Saga } from 'redux-saga'
import { fork } from 'redux-saga/effects'

import changeHistory from './changeHistory'
import sendPasswordResetEmail from './sendPasswordResetEmail'
import { watchProfile } from './watchProfile'
import { watchProfileOverview } from './watchProfileOverview'
import { watchProfileStatus } from './watchProfileStatus'

export default function* (): Saga<*> {
  yield fork(watchProfile)
  yield fork(watchProfileStatus)
  yield fork(watchProfileOverview)
  yield fork(changeHistory)
  yield fork(sendPasswordResetEmail)
}
