// @flow
import type { Node } from 'react'
import React from 'react'

import { DialogCard } from 'components/dialog/DialogCard'
import { ModalWrapper } from 'components/modals/ModalWrapper'

type Props = {|
  +children: Node,
  +heading: string,
  +takePriority?: boolean,
  +className?: string,
|}

export const ElderModalDialog = ({
  children,
  heading,
  takePriority,
  className,
}: Props) => (
  <ModalWrapper takePriority={takePriority}>
    <DialogCard title={heading} className={className}>
      {children}
    </DialogCard>
  </ModalWrapper>
)
