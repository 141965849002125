// @flow
import {
  ElderServiceError,
  NativeServiceError,
  describeServiceError,
} from './errors'
import {
  externalFetchFlow,
  fetchFlow,
  getFlow,
  getAndSetFlow,
  getBlobFlow,
  getTextFlow,
  putFlow,
  postFlow,
  deleteFlow,
} from './fetch'

const SERVICE_ERROR = {
  GENERIC: 'GENERIC',
  INVALID_RESPONSE: 'INVALID_RESPONSE',
  TIMEOUT: 'TIMEOUT',
  UNAUTHORIZED: 'UNAUTHORIZED',
}

export {
  externalFetchFlow,
  fetchFlow,
  getFlow,
  getAndSetFlow,
  getBlobFlow,
  getTextFlow,
  deleteFlow,
  ElderServiceError,
  NativeServiceError,
  putFlow,
  postFlow,
  describeServiceError,
  SERVICE_ERROR,
}

export default fetchFlow
