// @flow

import styled from 'styled-components'

export const PageWrapper = styled.div`
  line-height: 22px;
  width: 1000px;
  max-width: 90vw;
  margin: 0 auto;
  flex: 0 1 auto;
  padding-bottom: 30px;
  .react-datepicker-wrapper,
  .react-datepicker__input-container {
    width: 100%;
  }
  .radio,
  .checkbox {
    margin-bottom: 3px;
  }
  .radio-inline {
    margin-right: 15px;
  }
`
