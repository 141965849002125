// @flow
/* eslint-disable flowtype/no-weak-types */
import flatMap from 'array.prototype.flatmap'
import { createSelector } from 'reselect'

import {
  getPrettyWorkExperienceTypes,
  getPrettyMedicalConditions,
  getPrettySpecialistSkills,
} from 'app/referenceData'
import { getActiveWorkflow } from 'features/activeWorkflow/selectors'
import { getId } from 'features/carerDetailsHeader/store/selectors'
import type {
  WorkExperienceResponse,
  LoadingStates,
} from 'features/carerSkills/domain/workExperience'
import { BREAK } from 'features/carerSkills/domain/workExperience'
import type { ActiveSection } from 'features/carerSkills/store/workExperience/reducer'

const getWorkExperiencesState = (state) => state.get('workExperienceState')

export const getActiveSection = (state: any): ActiveSection =>
  getWorkExperiencesState(state).get('activeSection')

// Until we sort out immutable typing any is being used
export const makeGetWorkExperiences =
  (carerId: string) =>
  (state: any): WorkExperienceResponse => {
    const workExperience = getWorkExperiencesState(state)
      .get('workExperiencesResponses')
      .toJS()[carerId]
    const prettyWorkExperienceType = getPrettyWorkExperienceTypes(state)
    const prettyMedicalConditions = getPrettyMedicalConditions(state)
    const prettySpecialistSkills = getPrettySpecialistSkills(state)

    if (workExperience) {
      return {
        ...workExperience,
        experiences: workExperience.experiences.map(
          ({ experience, ...restOfExperience }) => ({
            ...restOfExperience,
            experience: {
              ...experience,
              details: {
                ...experience.details,
                prettyTypeOfCare:
                  experience.details.typeOfCare &&
                  prettyWorkExperienceType(experience.details.typeOfCare),
                prettyConditions:
                  experience.details.conditions &&
                  experience.details.conditions.map(prettyMedicalConditions),
                prettySpecialistSkills:
                  experience.details.specialistSkills &&
                  experience.details.specialistSkills.map(
                    prettySpecialistSkills,
                  ),
              },
            },
          }),
        ),
      }
    }
    return workExperience
  }

// Until we sort out immutable typing any is being used
export const getLoading = (state: any): ?LoadingStates =>
  getWorkExperiencesState(state).get('loading')

export const getFullWorkExperience = createSelector(
  getId,
  getActiveWorkflow,
  (state) => state,
  (carerId, activeWorkflow, state) => {
    const workExperienceResponse = makeGetWorkExperiences(carerId)(state)
    if (!workExperienceResponse) {
      return null
    }
    const { experiences } = workExperienceResponse
    const workExperienceId = (activeWorkflow.payload || {}).id
    return (
      experiences.find(({ id: testId }) => workExperienceId === testId) || null
    )
  },
)

export const getEnableElderDecision = createSelector(
  getActiveSection,
  getFullWorkExperience,
  ({ name }, fullWorkExperience) => {
    if (!fullWorkExperience) {
      return false
    }
    const {
      experience: {
        details: { type },
      },
      references,
    } = fullWorkExperience

    const acceptedReferences = flatMap(
      references,
      ({ responses }) => responses,
    ).filter(({ acceptanceStatus }) => acceptanceStatus === 'ACCEPTED')

    // If we're not in the process of requesting a work reference, accepting or rejecting a reference
    // and if the work type is not break we have an accepted reference already, allow the
    // user to make an Elder decision.
    return (
      !['REQUEST_WORK_REFERENCE', 'ACCEPT_REJECT_REFERENCE'].includes(name) &&
      (type !== BREAK ? acceptedReferences.length > 0 : true)
    )
  },
)

export const makeGetReferenceIdsForWorkExperience =
  ({
    workExperienceId,
    carerId,
  }: {|
    +workExperienceId: string,
    +carerId: string,
  |}) =>
  (state: any) => {
    const workExperience = makeGetWorkExperiences(carerId)(
      state,
    ).experiences.find(({ id: testId }) => workExperienceId === testId)
    return workExperience
      ? flatMap(workExperience.references, ({ responses }) => responses).map(
          ({ responseId }) => responseId,
        )
      : []
  }

export const getCharacterReferences = createSelector(
  getId,
  (state) => state,
  (carerId, state) => {
    const workExperience = getWorkExperiencesState(state)
      .get('workExperiencesResponses')
      .toJS()[carerId]
    if (!workExperience) {
      return null
    }
    const { characterReferences } = workExperience
    return characterReferences
  },
)
