export const booleanOptions = [
  { value: 'true', label: 'Yes' },
  { value: 'false', label: 'No' },
]

export const yesOrDefaultOptions = [
  { value: 'true', label: 'Yes' },
  { value: 'false', label: 'Use default' },
]

export const setValueBoolean = (val) =>
  typeof val === 'boolean' ? val.toString() : null

export const transformStringToBoolean = (str) => {
  if (str === 'true') {
    return true
  }
  if (str === 'false') {
    return false
  }

  return null
}

export const yesNoSometimesOptions = [
  { value: 'YES', label: 'Yes' },
  { value: 'NO', label: 'No' },
  { value: 'SOMETIMES', label: 'Sometimes' },
]

export const daysOfWeekOptions = [
  { value: 'MONDAY', label: 'Monday' },
  { value: 'TUESDAY', label: 'Tuesday' },
  { value: 'WEDNESDAY', label: 'Wednesday' },
  { value: 'THURSDAY', label: 'Thursday' },
  { value: 'FRIDAY', label: 'Friday' },
  { value: 'SATURDAY', label: 'Saturday' },
  { value: 'SUNDAY', label: 'Sunday' },
]

export const medicalTreatmentsOptions = [
  { value: 'Stoma', label: 'Stoma' },
  { value: 'PEG', label: 'PEG' },
  { value: 'NONE', label: 'None' },
]

export const toiletingOptions = [
  { value: 'YES', label: 'Yes' },
  { value: 'CATHETER', label: 'Catheter' },
  { value: 'PADS', label: 'Pads' },
  { value: 'URINARY_INCONTINENCE', label: 'Urinary incontinence' },
  { value: 'FAECAL_INCONTINENCE', label: 'Faecal incontinence' },
  { value: 'NO_ASSISTANCE_REQUIRED', label: 'No assistance required' },
]

export const dietaryRequirementsOptions = [
  { value: 'VEGETARIAN', label: 'Vegetarian' },
  { value: 'VEGAN', label: 'Vegan' },
  { value: 'HALAL', label: 'Halal' },
  { value: 'GLUTEN_FREE', label: 'Gluten Free' },
  { value: 'DAIRY_FREE', label: 'Dairy Free' },
  { value: 'DIABETIC', label: 'Diabetic' },
  { value: 'NONE', label: 'None' },
]

export const mobilityOptions = [
  { value: 'HOIST', label: 'Hoist' },
  { value: 'STAIRLIFT', label: 'Stairlift' },
  { value: 'WALKING_STICK', label: 'Walking stick' },
  { value: 'ZIMMER_FRAME', label: 'Zimmer frame' },
  { value: 'WHEELCHAIR', label: 'Wheelchair' },
  { value: 'NO_ASSISTANCE_REQUIRED', label: 'No assistance required' },
]

export const medicationPromptingOptions = [
  { value: 'YES', label: 'Yes' },
  { value: 'BLISTER_PACK', label: 'Blister pack' },
  { value: 'LOG_BOOK', label: 'Log book' },
  { value: 'NONE_REQUIRED', label: 'None required' },
]

export const workWithPetsOptions = [
  { value: 'CATS', label: 'Cats' },
  { value: 'DOGS', label: 'Dogs' },
  { value: 'NO', label: 'No' },
]

export const workWithGenderOptions = [
  { value: 'FEMALE', label: 'Female' },
  { value: 'MALE', label: 'Male' },
]

export const matchGenderOptions = [
  ...workWithGenderOptions,
  { value: 'ANY', label: 'Any' },
]

export const matchDrivingOptions = [
  { value: 'HAS_OWN_CAR', label: 'Has car' },
  { value: 'HAS_LICENCE', label: 'Has licence' },
  { value: 'NOT_REQUIRED', label: 'Not required' },
]

export const summarizedViewOptions = [
  { value: 'true', label: 'Summarized' },
  { value: 'false', label: 'Detailed' },
]
