// @flow
import type { Saga } from 'redux-saga'
import { call, put, takeLatest } from 'redux-saga/effects'

import { accountPaymentsClient } from 'app/saga/serviceClients'
import {
  CREATE_PAYMENT,
  CONFIRM_CREATE_PAYMENT,
  CANCEL_PAYMENT,
  CONFIRM_CANCEL_PAYMENT,
  GET_INVOICE_DETAILS,
  setInvoiceDetails,
  setCreatePaymentConfirmation,
  setCancelPaymentConfirmation,
  CLOSE_INVOICE,
} from 'features/accountPayments/store/actions'
import {
  applicationError,
  applicationSuccess,
} from 'features/snackbar/snackbar'
import { fetchFlow, describeServiceError } from 'utils/services'

export function* handleCreatePayment({
  invoiceId,
}: {
  +invoiceId: string,
}): Generator<Object, void, Object> {
  const endpoint = `/et/accounts/${invoiceId}/invoice/createPayment`
  const method = 'POST'
  try {
    const response = yield call(fetchFlow, endpoint, method)
    yield put(setCreatePaymentConfirmation(response))
  } catch (error) {
    applicationError(
      describeServiceError(error, `Error with service call ${endpoint}`),
    )
  }
}

export function* handleConfirmCreatePayment({
  invoiceId,
  accountId,
}: {
  +invoiceId: string,
  +accountId: string,
}): Generator<Object, void, Object> {
  const endpoint = `/et/accounts/${invoiceId}/invoice/confirmCreatePayment`
  const method = 'POST'
  try {
    const response = yield call(fetchFlow, endpoint, method, {
      accountId,
    })
    yield put(setInvoiceDetails(response))
    applicationSuccess({
      title: 'Success',
      message: 'Payment successfully created',
    })
  } catch (error) {
    applicationError(
      describeServiceError(error, `Error with service call ${endpoint}`),
    )
  }
}

export function* handleCancelPayment({
  invoiceId,
  paymentId,
}: {
  +invoiceId: string,
  +paymentId: string,
}): Generator<Object, void, Object> {
  const endpoint = `/et/accounts/${invoiceId}/invoice/cancelPayment`
  const method = 'POST'
  try {
    const response = yield call(fetchFlow, endpoint, method, { paymentId })
    yield put(setCancelPaymentConfirmation(response))
  } catch (error) {
    applicationError(
      describeServiceError(error, `Error with service call ${endpoint}`),
    )
  }
}

export function* handleConfirmCancelPayment({
  invoiceId,
  paymentId,
  accountId,
}: {
  +invoiceId: string,
  +paymentId: string,
  +accountId: string,
}): Generator<Object, void, Object> {
  const endpoint = `/et/accounts/${invoiceId}/invoice/confirmCancelPayment`
  const method = 'POST'
  try {
    const response = yield call(fetchFlow, endpoint, method, {
      paymentId,
      accountId,
    })
    yield put(setInvoiceDetails(response))
    applicationSuccess({
      title: 'Success',
      message: 'Payment successfully cancelled',
    })
  } catch (error) {
    applicationError(
      describeServiceError(error, `Error with service call ${endpoint}`),
    )
  }
}

export function* handleGetInvoiceDetails({
  accountId,
}: {
  +accountId: string,
}): Generator<Object, void, Object> {
  try {
    const accountInvoices = yield call(
      accountPaymentsClient.getAccountInvoices,
      accountId,
    )
    yield put(setInvoiceDetails(accountInvoices))
  } catch (error) {
    applicationError(
      describeServiceError(error, 'Failed to fetch account invoices'),
    )
  }
}

export function* handleCloseInvoice({
  accountId,
}: {
  +accountId: string,
}): Generator<Object, void, Object> {
  try {
    const accountDetailsUpdated = yield call(
      accountPaymentsClient.closeAccountInvoice,
      accountId,
    )

    yield put(setInvoiceDetails(accountDetailsUpdated))
    applicationSuccess({
      title: 'Success!',
      message: 'Invoice close requested.',
    })
  } catch (error) {
    applicationError(
      describeServiceError(error, 'Failed to close account invoices'),
    )
  }
}

export function* saga(): Saga<*> {
  yield takeLatest(CREATE_PAYMENT, handleCreatePayment)
  yield takeLatest(CONFIRM_CREATE_PAYMENT, handleConfirmCreatePayment)
  yield takeLatest(CANCEL_PAYMENT, handleCancelPayment)
  yield takeLatest(CONFIRM_CANCEL_PAYMENT, handleConfirmCancelPayment)
  yield takeLatest(GET_INVOICE_DETAILS, handleGetInvoiceDetails)
  yield takeLatest(CLOSE_INVOICE, handleCloseInvoice)
}
