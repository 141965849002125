import { useEtGetSolutionsUsingGET } from '@elder/et-facade-et'
import { useQueryParam, StringParam } from 'use-query-params'

import { useAccountContext } from 'routes/account/accountContext'

import { SolutionTabs } from './SolutionTabs'

export const SolutionTabsContainer = () => {
  const { accountId } = useAccountContext()
  const solutionsQuery = useGetSolutions(accountId)
  const solutions = solutionsQuery.data?.solutions || []
  const [solutionId, setSolutionId] = useQueryParam('solutionId', StringParam)
  const [, setPlacementId] = useQueryParam('placementId', StringParam)
  const handleSolutionIdChange = (solutionId: string) => {
    setSolutionId(solutionId)
    setPlacementId(undefined)
  }

  return (
    <SolutionTabs
      solutions={solutions}
      solutionId={solutionId || undefined}
      setSolutionId={handleSolutionIdChange}
    />
  )
}

export const useGetSolutions = (accountId: string) => {
  return useEtGetSolutionsUsingGET(accountId, { includeCancelled: true })
}
