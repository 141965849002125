// @flow
import type { Saga } from 'redux-saga'
import { call, select, takeLatest, put } from 'redux-saga/effects'

import { setProfileCommunicationPreferences } from 'features/relatedAccounts/store/actions'
import { GET } from 'modules/pageEdit'
import { pageSelectors } from 'routes/profile/profileWrapper/selectors'
import { NAMESPACE, pageActions } from 'routes/profile/summary/actions'
import { addNamespace } from 'utils'
import { getFlow } from 'utils/services'

function* fetchProfile(): Generator<*, void, *> {
  const profileId = yield select(pageSelectors.getId)
  const endpoint = `/et/profiles/${profileId}`
  const response = yield call(getFlow, endpoint)
  yield put(pageActions.set(response))
  yield put(setProfileCommunicationPreferences(response.accountLinks))
}

export function* watchProfile(): Saga<*> {
  yield takeLatest(addNamespace(NAMESPACE, GET), fetchProfile)
}
