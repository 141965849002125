// @flow
import { makeAction } from 'elder/actions'
import type { ActionOf } from 'elder/types'

// get test group
export const getTestGroup = makeAction(
  'experimentTestGroups/getTestGroup',
  (experimentName: string, accountId: string) => ({
    experimentName,
    accountId,
  }),
)

// get test group failed
export const getTestGroupError = makeAction(
  'experimentTestGroups/getTestGroupError',
  (error) => ({ error }),
)

// get test group successful - set test group in state
export const setTestGroup = makeAction(
  'experimentTestGroups/setTestGroup',
  (testGroup, experimentName) => ({
    testGroup,
    experimentName,
  }),
)

// export all test group action types
export type GetTestGroupAction = ActionOf<typeof getTestGroup>
export type GetTestGroupErrorAction = ActionOf<typeof getTestGroupError>
export type SetTestGroupAction = ActionOf<typeof setTestGroup>

export type GetTestGroupActions =
  | GetTestGroupAction
  | GetTestGroupErrorAction
  | SetTestGroupAction
