// @flow
import { type StatelessFunctionalComponent } from 'react'

import { typography, colors } from '@elder/common'
import { Field } from 'formik'
import styled, { css } from 'styled-components'

export const fieldResets = css`
  border: none;
  width: 100%;
  margin: 0;
  outline: none;
  border-radius: 0;
  padding: 0;
  background-color: unset;
  border: unset;

  /* stylelint-disable-next-line property-no-vendor-prefix */
  -webkit-appearance: none;

  &::-ms-clear {
    display: none;
  }
`

export const fieldStyles = css`
  ${fieldResets};
  transition: 0.1s;
  ${typography.paragraph1};
  border: 2px solid
    ${({ error }) => (error ? colors.errorDark : colors.lightSmoke)} !important;
  padding: 8px 10px;
  line-height: normal;
  &:focus {
    border: 2px solid ${colors.blue800} !important;
  }

  ${({ disabled }) =>
    disabled &&
    css`
      opacity: 0.7;
    `};
`

export const StyledField: StatelessFunctionalComponent<*> = styled(Field)`
  ${fieldStyles};
`
