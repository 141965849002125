// @flow
import { object, string } from 'flow-validator'

import { endpointBuilder, noResponse } from 'elder/services'

import { recipient, recipientData } from 'features/recipient/domain'

const createRecipientRequest = object({
  accountId: string,
  data: recipientData,
})

const updateRecipientRequest = object({
  accountId: string,
  recipientId: string,
  data: recipientData,
})

const recipientRequest = object({
  accountId: string,
  recipientId: string,
})

const changeStatusRequest = object({
  accountId: string,
  recipientId: string,
  status: string,
})

export const recipientService = {
  getRecipient: endpointBuilder(recipientRequest, recipient)
    .withUrlTemplate(
      '/et/accounts/{accountId}/recipients/{recipientId}',
      ({ accountId, recipientId }) => ({
        accountId,
        recipientId,
      }),
    )
    .build(),
  createRecipient: endpointBuilder(createRecipientRequest, recipient)
    .withHttpMethod('POST')
    .withBodyBuilder((req) => req.data)
    .withUrlTemplate(
      '/et/accounts/{accountId}/recipients',
      ({ accountId }) => ({
        accountId,
      }),
    )
    .build(),
  updateRecipient: endpointBuilder(updateRecipientRequest, recipient)
    .withHttpMethod('PUT')
    .withBodyBuilder((req) => req.data)
    .withUrlTemplate(
      '/et/accounts/{accountId}/recipients/{recipientId}',
      ({ accountId, recipientId }) => ({
        accountId,
        recipientId,
      }),
    )
    .build(),
  deleteRecipient: endpointBuilder(recipientRequest, noResponse)
    .withHttpMethod('DELETE')
    .withUrlTemplate(
      '/et/accounts/{accountId}/recipients/{recipientId}',
      ({ accountId, recipientId }) => ({
        accountId,
        recipientId,
      }),
    )
    .withNoResponseBody()
    .build(),
  changeStatus: endpointBuilder(changeStatusRequest, recipient)
    .withHttpMethod('PUT')
    .withBodyBuilder((req) => ({
      status: req.status,
    }))
    .withUrlTemplate(
      '/et/accounts/{accountId}/recipients/{recipientId}/status',
      ({ accountId, recipientId }) => ({
        accountId,
        recipientId,
      }),
    )
    .build(),
}
