import type { ReactNode } from 'react'
import React from 'react'

import moment from 'moment'
import styled from 'styled-components'

import type { DateRangeEntryType } from 'components/solutions/SolutionDateLabels'

interface Props {
  children?: ReactNode
  type: DateRangeEntryType
  position: number
  total: number
}

type PropLookup = Record<DateRangeEntryType, string>

const borderPositions: PropLookup = {
  today: 'border-left',
  monday: 'border-left',
  solution: 'border-left',
}

const borderWidths: PropLookup = {
  today: '5px',
  monday: '1px',
  solution: '5px',
}

const borderColors: PropLookup = {
  today: '#598DFD',
  monday: '#73819C',
  solution: '#000000',
}

const borderStyles: PropLookup = {
  today: 'solid',
  monday: 'dashed',
  solution: 'solid',
}

const StyledTimelineGuideBar = styled.div`
  ${({ type, left, isOutside }) =>
    `
    transition: all 0.3s ease;
    position: absolute;
    top: 0;
    height: 100%;
    opacity: 1;
    width: 0.01px;
    left: ${left}%;
    opacity: ${isOutside ? 0 : 100};
    ${borderPositions[type]}:
      ${borderWidths[type]} ${borderStyles[type]} ${borderColors[type]};
  `}
`

const getLeft = (
  position: number,
  total: number,
  variation: DateRangeEntryType,
): number => {
  if (position === 0 || !total) return 0

  const now = moment()
  const dayProgress = 1 - now.get('hour') / 24
  if (variation === 'today') return (100 * (position - dayProgress)) / total

  return (100 * position) / total
}

export const TimelineGuideBar = ({
  children,
  type,
  position,
  total,
}: Props) => {
  const isOutside = position < 0 || position > total
  const left = getLeft(position, total, type)

  return (
    <StyledTimelineGuideBar type={type} left={left} isOutside={isOutside}>
      {children}
    </StyledTimelineGuideBar>
  )
}
