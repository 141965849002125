import { useState } from 'react'

import type { ElderUser } from '@elder/et-facade-et'
import { EditOutlined } from '@mui/icons-material'
import {
  Button,
  Chip,
  Container,
  Dialog,
  IconButton,
  Stack,
  Typography,
  useTheme,
} from '@mui/material'

type ElderUserForHierarchy = ElderUser & {
  isPrimary: boolean
  isCurrent: boolean
}

export const ExecutivesHierarchy = ({
  executives,
  editSection,
}: {
  executives: ElderUserForHierarchy[]
  editSection: () => void
}) => {
  const [open, setOpen] = useState(false)

  return (
    <>
      <Stack>
        <Stack direction="row" justifyContent="flex-end">
          <IconButton
            onClick={editSection}
            title="Edit sales executive hierarchy"
            color="primary"
          >
            <EditOutlined />
          </IconButton>
        </Stack>
        {executives
          .filter(({ isPrimary, isCurrent }) => isPrimary || isCurrent)
          .map((executive) => (
            <ExecutiveCard executive={executive} key={executive.username} />
          ))}
        <Button
          variant="outlined"
          onClick={() => setOpen(true)}
          sx={{ alignSelf: 'flex-end' }}
        >
          View all
        </Button>
      </Stack>
      <ExecutivesModal
        open={open}
        onClose={() => setOpen(false)}
        executives={executives}
      />
    </>
  )
}

interface ExecutivesModalProps {
  open: boolean
  onClose: () => void
  executives: ElderUserForHierarchy[]
}

const ExecutivesModal = ({
  open,
  onClose,
  executives,
}: ExecutivesModalProps) => {
  return (
    <Dialog open={open} onClose={onClose}>
      <Container component={Stack} py={3}>
        <Typography variant="subtitle1">Sales Executive Hierarchy</Typography>

        {executives.map((executive) => (
          <ExecutiveCard executive={executive} key={executive.username} />
        ))}

        <Button variant="text" onClick={onClose} sx={{ alignSelf: 'flex-end' }}>
          Close
        </Button>
      </Container>
    </Dialog>
  )
}

export const ExecutiveCard = ({
  executive,
  children,
}: {
  executive: ElderUserForHierarchy
  children?: React.ReactNode
}) => {
  const { firstName, lastName, elderStatus, isPrimary, isCurrent } = executive
  const { palette } = useTheme()

  if (!elderStatus) {
    throw new Error(
      `SE Hierarchy: elder status is missing for Executive: ${lastName}`,
    )
  }

  const statusMapper = {
    ACTIVE: {
      background: palette.background.paper,
      textColor: palette.grey[600],
      label: 'Active',
    },
    NON_WORKING_DAY: {
      background: palette.grey[200],
      textColor: palette.grey[600],
      label: 'Not working today',
    },
    AWAY: {
      background: palette.grey[200],
      textColor: palette.grey[600],
      label: 'Away',
    },
    ARCHIVED: {
      background: palette.grey[700],
      textColor: palette.grey[400],
      label: 'Archived',
    },
  }

  return (
    <Stack
      sx={{
        background: statusMapper[elderStatus].background,
        border: '1px solid',
        borderColor: palette.divider,
        borderRadius: 4,
      }}
      p={2}
    >
      <Stack
        direction="row"
        justifyContent="space-between"
        sx={{
          color: isCurrent
            ? palette.text.primary
            : statusMapper[elderStatus].textColor,
        }}
      >
        <Typography>
          {[firstName, lastName].filter(Boolean).join(' ')}
        </Typography>
        <Typography
          variant="body2"
          sx={{
            whiteSpace: 'nowrap',
          }}
        >
          {statusMapper[elderStatus].label}
        </Typography>
      </Stack>
      <Stack direction="row" spacing={1}>
        {children}
        {isPrimary && (
          <Chip
            variant="filled"
            label="Primary"
            color="primary"
            sx={{ width: 'fit-content' }}
          />
        )}
        {isCurrent && (
          <Chip
            variant="outlined"
            label="Current"
            color="primary"
            sx={{ width: 'fit-content' }}
          />
        )}
      </Stack>
    </Stack>
  )
}
