// @flow
import React from 'react'

import { EditOutlined } from '@mui/icons-material'
import { Button, IconButton, Stack } from '@mui/material'

import { areAccountExecutivesUnavailable } from 'features/accountExecutive/store/selectors'
import { ExecutiveCard } from 'features/accountInfo/ExecutivesHierarchy'
import type { ElderUser } from 'features/elderUsers/domain'

type Props = {|
  +hierarchy: Array<ElderUser>,
  +fallback: ElderUser,
  +canEdit: boolean,
  +editSection: () => void,
  +generateAccountExecutiveHierarchy: () => void,
|}

export const AccountExecutiveHierarchy = ({
  hierarchy,
  fallback,
  canEdit,
  editSection,
  generateAccountExecutiveHierarchy,
}: Props) => {
  const areRegularAEsUnavailable = areAccountExecutivesUnavailable(hierarchy)
  const isAEHierarchyEmpty = hierarchy.length === 0
  const shouldShowFallback = areRegularAEsUnavailable && !isAEHierarchyEmpty
  return (
    <Stack direction="column" justifyContent="end" spacing={3}>
      {canEdit && (
        <Stack direction="row" justifyContent="flex-end" spacing={2}>
          {hierarchy.length < 2 && (
            <Button
              variant="outlined"
              onClick={generateAccountExecutiveHierarchy}
            >
              Generate
            </Button>
          )}
          <IconButton
            onClick={editSection}
            title="Edit account executive hierarchy"
            color="primary"
          >
            <EditOutlined />
          </IconButton>
        </Stack>
      )}
      <Stack direction="column" sx={{ flex: 1 }} spacing={2}>
        {(hierarchy || []).map((ae) => (
          <ExecutiveCard executive={ae} />
        ))}
        {fallback && shouldShowFallback && (
          <ExecutiveCard executive={fallback} />
        )}
      </Stack>
    </Stack>
  )
}
