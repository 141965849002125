// @flow
/* eslint-disable flowtype/no-weak-types */
import { createSelector } from 'reselect'

export const getApp = (state: Object): Object => state.get('app')

const getRouter = (state: Object): Object => state.get('router').toJS()

// TODO: remove
export const getLocationBeforeTransitions = (state: Object): Object =>
  state.get('routing').get('locationBeforeTransitions')

export const getLocation = createSelector(
  getRouter,
  (router: Object): ?Object => router.location,
)
export const getPathname = createSelector(
  getLocation,
  (location: ?Object): string => (location ? location.pathname : ''),
)

export const getPathHistory = createSelector(getApp, (app: Object) =>
  app.get('pathHistory'),
)

export const getPrevPath = createSelector(
  getPathHistory,
  (app: Object) => app.previousPath,
)
