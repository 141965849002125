// @flow
import {
  SET,
  STARTED_LOADING,
  FINISHED_LOADING,
} from 'modules/pageEdit/actions'
import { addNamespace } from 'utils'

export const makeReducer = (namespace: string) => {
  const initialState = false
  const set = addNamespace(namespace, SET)
  const startedLoading = addNamespace(namespace, STARTED_LOADING)
  const finishedLoading = addNamespace(namespace, FINISHED_LOADING)

  return (state: boolean = initialState, action: Object) => {
    switch (action.type) {
      case startedLoading:
        return true
      case set:
      case finishedLoading:
        return false
      default:
        return state
    }
  }
}

export default makeReducer
