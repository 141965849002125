// @flow
import type { Saga } from 'redux-saga'
import { fork } from 'redux-saga/effects'

import watchProfileIdentity from './watchProfileIdentity'

export function* allSagas(): Saga<*> {
  yield fork(watchProfileIdentity)
}

export default allSagas
