// @flow
// $FlowOptOut
import React, { useState } from 'react'

import { colors } from '@elder/common'
import { AddOutlined } from '@mui/icons-material'
import { Button } from '@mui/material'
import styled from 'styled-components'

import { Checkbox } from 'components/forms/Checkbox'
import { Section } from 'components/styledComponentsTranslatedClasses/Section'
import type { WidthEnum } from 'components/SummaryCard/SummaryCard'
import SummaryCardModule from 'components/SummaryCard/SummaryCard'
import { SummaryCardActions } from 'components/SummaryCard/SummaryCardActions'
import { Instants } from 'domain/instant'
import type { Note, Template } from 'features/notes/domain'
import { EditNoteModalContainer } from 'features/notes/EditNoteModalContainer'

import { NoteCard } from './NoteCard'

const StyledNoteCard = styled(NoteCard)`
  margin-top: 20px;
`

export type Props = {|
  +notes: Array<Note>,
  +createNote: () => void,
  +pinNote: (string) => void,
  +unpinNote: (string) => void,
  +archiveNote: (string) => void,
  +unarchiveNote: (string) => void,
  +openEditNoteModal: (string) => void,
  +loading: boolean,
  +failed: boolean,
  +notesUpdating: { [string]: boolean },
  +editNoteId: string,
  +notesEntityType: string,
  +notesEntityId: string,
  +templates?: Array<Template>,
  +isReadOnly?: boolean,
  +width?: WidthEnum,
|}

export const NotesSection = ({
  notes,
  createNote,
  pinNote,
  unpinNote,
  archiveNote,
  unarchiveNote,
  openEditNoteModal,
  loading,
  failed,
  notesUpdating,
  width,
  editNoteId,
  notesEntityId,
  notesEntityType,
  isReadOnly,
  templates,
}: Props) => {
  const [showArchived, setShowArchived] = useState(false)
  const noteToEdit = notes.find(({ id }) => id === editNoteId)
  return (
    <Section>
      <SummaryCardModule
        width={width}
        heading="Notes"
        loading={loading}
        failed={failed}
        actionChildren={
          <SummaryCardActions>
            <Button
              variant="contained"
              onClick={() => createNote()}
              disabled={isReadOnly}
              startIcon={<AddOutlined />}
            >
              Add note
            </Button>
          </SummaryCardActions>
        }
      >
        {noteToEdit && (
          <EditNoteModalContainer
            notesEntityType={notesEntityType}
            notesEntityId={notesEntityId}
            note={noteToEdit}
            templates={templates}
          />
        )}
        <div>
          <Checkbox
            checkboxLabel="Show archived notes"
            onChange={(value) => setShowArchived(value)}
            checked={showArchived}
            colourAndContrast={{
              primary: colors.blue800,
              contrast: colors.white,
              outline: colors.blue800,
            }}
            noFormik
          />
          {notes
            .filter((note) => showArchived || !note.archived)
            .sort(
              (
                { createdAt: createdAtA, pinned: pinnedA },
                { createdAt: createdAtB, pinned: pinnedB },
              ) => {
                if (pinnedA && !pinnedB) {
                  return -1
                }
                if (!pinnedA && pinnedB) {
                  return 1
                }
                return Instants.compare(
                  Instants.ofString(createdAtA),
                  Instants.ofString(createdAtB),
                )
              },
            )
            .map(({ id, ...note }) => (
              <StyledNoteCard
                key={id}
                id={id}
                {...note}
                pinNote={() => pinNote(id)}
                unpinNote={() => unpinNote(id)}
                archiveNote={() => archiveNote(id)}
                unarchiveNote={() => unarchiveNote(id)}
                editNote={() => openEditNoteModal(id)}
                disableActions={!!notesUpdating[id] || isReadOnly}
              />
            ))}
        </div>
      </SummaryCardModule>
    </Section>
  )
}
