// @flow
import addNamespace from 'utils/addNamespace'

export const NAMESPACE = 'PAYMENTS'
export const DOWNLOAD_PAYMENTS_FILE = addNamespace(
  NAMESPACE,
  'DOWNLOAD_PAYMENTS_FILE',
)
export const GET_PAYMENT_BATCHES = addNamespace(
  NAMESPACE,
  'GET_PAYMENT_BATCHES',
)
export const SET_PAYMENT_BATCHES = addNamespace(
  NAMESPACE,
  'SET_PAYMENT_BATCHES',
)

export const downloadPaymentsFile = (name: string, batchId: string) => ({
  type: DOWNLOAD_PAYMENTS_FILE,
  name,
  batchId,
})

export const getPaymentBatches = () => ({
  type: GET_PAYMENT_BATCHES,
})

export const setPaymentBatches = (paymentBatches: Object) => ({
  type: SET_PAYMENT_BATCHES,
  paymentBatches,
})
