// @flow
import { call } from 'redux-saga/effects'

import { getAndSetFlow } from 'utils/services'

import { setReferenceData } from './actions'

export function* referenceDataFlow(): Generator<Object, void, void> {
  yield call(
    getAndSetFlow,
    '/et/referencedata/?solutionPlans=true',
    setReferenceData,
  )
}

export default referenceDataFlow
