// @flow
import type { Saga } from 'redux-saga'
import { call, fork, take, takeLatest } from 'redux-saga/effects'

import { downloadFile } from 'utils/downloadFile'
import { getAndSetFlow, getBlobFlow } from 'utils/services/fetch'

import {
  DOWNLOAD_PAYMENTS_FILE,
  GET_PAYMENT_BATCHES,
  setPaymentBatches,
} from './actions'

const BANK_TRANSFERS_PATH = '/et/payments'

function* fetchPaymentsTsv(name, relativePath): Saga<*> {
  const response = yield call(
    getBlobFlow,
    `${BANK_TRANSFERS_PATH}/${relativePath}`,
  )
  yield call(downloadFile, response, name)
}

export function* watchDownloadPayments(): Generator<Object, void, Object> {
  while (true) {
    const action = yield take(DOWNLOAD_PAYMENTS_FILE)
    yield fork(fetchPaymentsTsv, action.name, action.batchId)
  }
}

function* fetchPaymentBatches(): Generator<Object, void, void> {
  yield call(
    getAndSetFlow,
    `${BANK_TRANSFERS_PATH}/banktransfers`,
    setPaymentBatches,
  )
}

export default function* watchPayments(): Saga<*> {
  yield fork(watchDownloadPayments)
  yield takeLatest(GET_PAYMENT_BATCHES, fetchPaymentBatches)
}
