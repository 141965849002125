import { useEffect } from 'react'

import { useHistory } from 'react-router-dom'
import type { Inbox } from 'talkjs/all'

import * as routes from 'routes'
import { formatLink } from 'utils/links'

export const useGoToAccount = (inbox?: Inbox) => {
  const { push } = useHistory()

  useEffect(() => {
    if (!inbox) return undefined

    const sub = inbox.onCustomConversationAction('goToAccount', (event) => {
      const { conversation } = event
      if (!conversation) return

      const { participants } = conversation

      const account = Object.keys(participants).find(
        (participant) => participant !== 'elder',
      )

      if (!account) return

      if (account.startsWith('u')) {
        push(formatLink(routes.profile, { profileId: account }))
      }

      if (account.startsWith('p')) {
        push(formatLink(routes.carer, { carerId: account }))
      }
    })

    return () => {
      sub.unsubscribe()
    }
  }, [inbox, push])
}
