// @flow
import { addValidationRule } from 'formsy-react'
import moment from 'moment'

const isValidDate = (values, value, format) => {
  if (!value || (typeof value === 'string' && !value.trim())) {
    // Blank is OK
    return true
  }

  if (moment.isMoment(value)) {
    // Moment instance is OK
    return true
  }

  try {
    // String in expected format is OK
    moment(value, format)
    return true
  } catch (ex) {
    return false
  }
}

const isBeforeOtherDate = (values, value, otherDateKey) => {
  const otherDate = values[otherDateKey]
  return !value || !otherDate || moment(value, 'YYYY-MM-DD').isBefore(otherDate)
}

const isAfterOtherDate = (values, value, otherDateKey) => {
  const otherDate = values[otherDateKey]
  return !value || !otherDate || moment(value, 'YYYY-MM-DD').isAfter(otherDate)
}

export const initializeValidators = () => {
  addValidationRule('isValidDate', isValidDate)
  addValidationRule('isBeforeOtherDate', isBeforeOtherDate)
  addValidationRule('isAfterOtherDate', isAfterOtherDate)
}
