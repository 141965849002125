// @flow
import { connect } from 'react-redux'

import {
  getCanEdit,
  getCanManagePaymentAssurance,
  getCanManagePayments,
  getCanEditAutomatedComms,
} from 'app'
import {
  getCanClearAccountHistory,
  getCanChangeCommsOption,
} from 'app/login/selectors'
import {
  getCareTypesOptions,
  getContactRolesOptions,
  getCustomerChurnReasonsOptions,
  getCustomerChurnOutcomesOptions,
  getPrettyFundingSourceOptions,
  getPrettyFundingMethodTypeOptions,
  getCustomerLearningResourcesOptions,
  getRegulatoryModelOptions,
  getOnwardProviderOptions,
} from 'app/referenceData'
import {
  updateAccountExecutiveHierarchy as updateAccountExecutiveHierarchyAction,
  generateAccountExecutiveHierarchy as generateAccountExecutiveHierarchyAction,
} from 'features/accountExecutive/store/actions'
import {
  getAccountExecutiveHierarchy,
  getFallbackAccountExecutive,
} from 'features/accountExecutive/store/selectors'
import {
  setActiveWorkflow,
  clearActiveWorkflow,
} from 'features/activeWorkflow/actions'
import { getActiveWorkflow } from 'features/activeWorkflow/selectors'
import { getElderUsers as getAccountExecutives } from 'features/elderUsers/store/actions'
import { elderUsersSelector } from 'features/elderUsers/store/selectors'
import { getTestGroup as getTestGroupAction } from 'features/experiments/store/actions'
import { getIsLoadingSelector } from 'features/loading/selectors'
import { getNotes as getNotesAction } from 'features/notes/store/actions'
import * as routes from 'routes'
import { pageSelectors as accountPageSelectors } from 'routes/account/selectors'
import {
  pageActions,
  autocompleteProfilesActions,
  updateBasicAccountInfo,
  updatePaymentType,
  updateFunding,
  updatePaymentAssuranceRequired,
  cancelDirectDebit,
  updateBillingCycle,
  cancelArchive,
  addBlacklistedCarer,
  removeBlacklistedCarer,
  updateAccountManager,
  updateRecommendedResources,
  deleteChangeHistory,
  getAccountManagers as getAccountManagersAction,
  updateArchiveDetails,
  updateAutomatedCommunications as updateAutomatedCommunicationsAction,
  reactivateAccount,
  updateCanAccessPortalWithReadOnlyPermissions as updateCanAccessPortalWithReadOnlyPermissionsAction,
  updateApplicationQuestion as updateApplicationQuestionAction,
  UPDATE_FUNDING,
  UPDATE_PAYMENT_ASSURANCE_REQUIRED,
  updateCareModelType as updateCareModelTypeAction,
} from 'routes/account/summary/actions'
import {
  pageSelectors,
  autocompleteProfilesSelectors,
  readOnlyAccessSpinnerSelectors,
  getAccountSummary,
  getJsAccountValidationError,
  getRecipientRowHeightMultiplier,
  getIsUpdatingApplicationQuestion,
  getPaymentTypeOptions,
} from 'routes/account/summary/selectors'
import { Summary } from 'routes/account/summary/Summary/Summary'
import { formatLink } from 'utils/links'

const mapActionCreators = (dispatch, props) => ({
  // $FlowOptOut
  getSummaryData: () => dispatch(pageActions.get()),
  getNotes: () =>
    dispatch(
      getNotesAction({
        notesEntityType: 'customer',
        notesEntityId: props.match.params.accountId,
      }),
    ),
  getRecipientUrl: (accountId, recipientId) =>
    formatLink(routes.careRecipientPage, { accountId, recipientId }),
  updateStatus: (data) => dispatch(pageActions.updateStatus(data)),
  reactivateAccount: () => dispatch(reactivateAccount()),
  editSection: (section) => dispatch(pageActions.editSection(section)),
  cancelEdit: () => dispatch(pageActions.cancelEdit()),
  cancelArchive: () => dispatch(cancelArchive()),
  updateBasicAccountInfo: (data) => dispatch(updateBasicAccountInfo(data)),
  updateBillingCycle: (data) => dispatch(updateBillingCycle(data)),
  updatePaymentType: (data) => dispatch(updatePaymentType(data)),
  updateFunding: (data) => dispatch(updateFunding(data)),
  updatePaymentAssuranceRequired: (data) =>
    dispatch(updatePaymentAssuranceRequired(data)),
  onProfilesInputChange: (queryString) =>
    dispatch(autocompleteProfilesActions.getAutocompleteProfiles(queryString)),
  cancelDirectDebit: () => dispatch(cancelDirectDebit()),
  addBlacklistedCarer: (accountId, professionalId, reason) =>
    dispatch(addBlacklistedCarer(accountId, professionalId, reason)),
  removeBlacklistedCarer: (accountId, professionalId) =>
    dispatch(removeBlacklistedCarer(accountId, professionalId)),
  setActiveWorkflow: (name, payload) =>
    dispatch(setActiveWorkflow(name, payload)),
  clearActiveWorkflow: () => dispatch(clearActiveWorkflow()),
  updateAccountManager: (email) => dispatch(updateAccountManager(email)),
  updateRecommendedResources: (recommendedResources) =>
    dispatch(updateRecommendedResources(recommendedResources)),
  deleteChangeHistory: () => dispatch(deleteChangeHistory()),
  getAccountManagers: () => dispatch(getAccountManagersAction()),
  getAccountExecutives: () => dispatch(getAccountExecutives()),
  updateArchiveDetails: (details) => dispatch(updateArchiveDetails(details)),
  updateAutomatedCommunications: (isEnabled) =>
    dispatch(updateAutomatedCommunicationsAction(isEnabled)),
  updateCanAccessPortalWithReadOnlyPermissions: (
    canAccessPortalWithReadOnlyPermissions,
  ) =>
    dispatch(
      updateCanAccessPortalWithReadOnlyPermissionsAction(
        canAccessPortalWithReadOnlyPermissions,
      ),
    ),
  updateApplicationQuestion: (updateApplicationQuestion) =>
    dispatch(updateApplicationQuestionAction(updateApplicationQuestion)),
  updateAccountExecutiveHierarchy: (hierarchy, accountId) =>
    dispatch(updateAccountExecutiveHierarchyAction(hierarchy, accountId)),
  generateAccountExecutiveHierarchy: (accountId) =>
    dispatch(generateAccountExecutiveHierarchyAction(accountId)),
  updateCareModelType: (accountId, careModelType) =>
    dispatch(updateCareModelTypeAction(accountId, careModelType)),
  getTestGroup: (experimentName, string) =>
    dispatch(getTestGroupAction(experimentName, string)),
})

const mapStateToProps = (state, props) => ({
  accountId: props.match.params.accountId,
  accountDetails: accountPageSelectors.getIdentityToJS(state),
  customerId: accountPageSelectors.getId(state),
  account: getAccountSummary(state),
  canEdit: getCanEdit(state),
  canEditPaymentAssuranceRequired: getCanManagePaymentAssurance(state),
  canManagePayments: getCanManagePayments(state),
  paymentTypeOptions: getPaymentTypeOptions(state),
  canEditAutomatedComms: getCanEditAutomatedComms(state),
  sectionEditMode: pageSelectors.getEditMode(state),
  validationErrors: getJsAccountValidationError(state),
  isReadOnlyAccessSaving:
    readOnlyAccessSpinnerSelectors.getLoadingInProgress(state),
  profileOptions:
    autocompleteProfilesSelectors.getJsAutocompleteProfiles(state),
  isLoadingProfiles: autocompleteProfilesSelectors.getIsLoading(state),
  careTypesOptions: getCareTypesOptions(state),
  contactRolesOptions: getContactRolesOptions(state),
  customerChurnReasonsOptions: getCustomerChurnReasonsOptions(state),
  customerChurnOutcomesOptions: getCustomerChurnOutcomesOptions(state),
  onwardProviderOptions: getOnwardProviderOptions(state),
  fundingSourceOptions: getPrettyFundingSourceOptions(state),
  fundingMethodTypeOptions: getPrettyFundingMethodTypeOptions(state),
  recipientRowHeightMultiplier: (index) =>
    getRecipientRowHeightMultiplier(state, index),
  activeWorkflow: getActiveWorkflow(state),
  financeUrl: `${props.match.url}/finance`,
  changelogUrl: `${props.match.url}/changelog`,
  environmentUrl: `${props.match.url}/environment`,
  newRecipientUrl: `${props.match.url}/new-recipient`,
  elderUsers: elderUsersSelector(state),
  accountExecutiveHierarchy: getAccountExecutiveHierarchy(state),
  fallbackAccountExecutive: getFallbackAccountExecutive(state),
  recommendedResourcesOptions: getCustomerLearningResourcesOptions(
    state,
  ).options.filter((option) => JSON.parse(option.metadata.recommendable)),
  canClearAccountHistory: getCanClearAccountHistory(state),
  isUpdatingApplicationQuestion: getIsUpdatingApplicationQuestion(state),
  canChangeAutoCommsOption: getCanChangeCommsOption(state),
  isUpdatingFunding: getIsLoadingSelector(state, UPDATE_FUNDING),
  isUpdatingPaymentAssuranceRequired: getIsLoadingSelector(
    state,
    UPDATE_PAYMENT_ASSURANCE_REQUIRED,
  ),
  regulatoryModelOptions: getRegulatoryModelOptions(state),
})

export default connect(mapStateToProps, mapActionCreators)(Summary)
