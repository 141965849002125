// @flow
import { makeAction } from 'elder/actions'

import { addNamespace } from 'utils'

export const GET = 'GET'
export const SET = 'SET'
export const MERGE = 'MERGE'
export const UPDATE_STATUS = 'UPDATE_STATUS'
export const UPDATE_STATUS_VALIDATION_FAIL = 'UPDATE_STATUS_VALIDATION_FAIL'
export const EDIT_SECTION = 'EDIT_SECTION'
export const EDIT_COMPLETED = 'EDIT_COMPLETED'
export const CANCEL_EDIT = 'CANCEL_EDIT'
export const STARTED_LOADING = 'STARTED_LOADING'
export const FINISHED_LOADING = 'FINISHED_LOADING'

export const makeActions = (namespace: string) => ({
  get: makeAction(addNamespace(namespace, GET), (id: string) => ({
    id,
  })),

  set: makeAction(addNamespace(namespace, SET), (value: Object) => ({
    value,
  })),

  merge: makeAction(addNamespace(namespace, MERGE), (value: Object) => ({
    value,
  })),

  updateStatus: makeAction(
    addNamespace(namespace, UPDATE_STATUS),
    (data: Object) => ({
      data,
    }),
  ),

  updateStatusValidationFail: makeAction(
    addNamespace(namespace, UPDATE_STATUS_VALIDATION_FAIL),
    (error: Object) => ({
      error,
    }),
  ),

  editSection: makeAction(
    addNamespace(namespace, EDIT_SECTION),
    (section: string) => ({
      section,
    }),
  ),

  editCompleted: makeAction(addNamespace(namespace, EDIT_COMPLETED)),

  cancelEdit: makeAction(addNamespace(namespace, CANCEL_EDIT)),

  startedLoading: makeAction(addNamespace(namespace, STARTED_LOADING)),

  finishedLoading: makeAction(addNamespace(namespace, FINISHED_LOADING)),
})

export default makeActions
