// @flow
import type { FlowOfType } from 'elder/types'
import { enumFromObjectKeys } from 'elder/types'

type TimelineBlockColor = $FlowFixMe

export type PlacementSummary = {|
  +placementId: string,
  +startDate: ?string,
  +live: ?boolean,
|}

export const APPLIED_PLACEMENT_STATUS = {
  PENDING: 'PENDING',
  CLIENT_REVIEW: 'CLIENT_REVIEW',
  DATES_CHANGE: 'DATES_CHANGE',
  EXPIRED: 'EXPIRED',
  NOT_SELECTED: 'NOT_SELECTED',
  UNAVAILABLE: 'UNAVAILABLE',
  CANCELLED: 'CANCELLED',
  NOTIFICATION_LOCK: 'NOTIFICATION_LOCK',
  ALLOCATED: 'ALLOCATED',
  MANUALLY_ALLOCATED: 'MANUALLY_ALLOCATED',
  SELECTED: 'SELECTED',
}

export const appliedPlacementStatus = enumFromObjectKeys(
  APPLIED_PLACEMENT_STATUS,
)

export type AppliedPlacementStatuses = FlowOfType<typeof appliedPlacementStatus>

export type AppliedPlacement = {|
  +placementAdId: string,
  +status: AppliedPlacementStatuses,
  +postcodeSector: ?string,
  +startDate: string,
  +endDate: ?string,
  +placementId: string,
  +customerAccountId: string,
  +primaryContactName: {|
    +firstName: ?string,
    +lastName: ?string,
  |},
  +matchRequestId: string,
  +rejectionReason: string,
  +applicationContentApproved?: boolean,
|}

export type RateComponent = {
  +sourceId: string,
  +rate: {
    +providerRate: { +displayText: string },
    +subscriberRate: { +displayText: string },
  },
  +sourceType: string,
  +description: string,
}

export type CarerBiddingSummary = {
  +highestPossibleBid: string,
  +lowestPossibleBid: string,
  +totalNumberOfAppliedCarers: number,
}

export type Advert = {
  +published: boolean,
  +carerPlacementAdBiddingSummary?: CarerBiddingSummary,
}

export type RateBreakdown = {
  +prettyStartDate: string,
  +prettyEndDate: string,
  +rateComponents: Array<RateComponent>,
  +rateComponentTotals: {
    +providerRate: { +displayText: string },
    +subscriberRate: { +displayText: string },
  },
}

export type PlacementDetails = {
  +id: string,
  +professionalId: string,
  +professionalName: ?string,
  +adverts?: Array<Advert>,
  +activeMatchRequestId?: string,
  +rateBreakdown: Array<RateBreakdown>,
  +oneOffPayments: Array<{
    +id: string,
    +prettyPaymentType: string,
    +providerAmount: { +displayText: string },
    +intendedPayDate?: string,
  }>,
  +canBeSignedOff: boolean,
  +isSignedOff: boolean,
  +isMatchPublished: boolean,
  +type: string,
  +signedOffBy?: string,
  +publishedBy?: string,
  +publishedAt?: string,
  +prettyStartDateInclusive: string,
  +prettyEndDateExclusive: string,
  +prettyDuration: string,
  +prettySuspensionReason?: string,
  +suspensionReasonDetails?: string,
  +prettySuspensionCover?: string,
  +matchAutomationEnabled: boolean,
  +effectiveStartDateInclusive: string,
  +effectiveEndDateExclusive: string,
  +changelog?: Array<{
    +id: string,
    +prettyTimestamp: string,
    +description: string,
    +author: string,
  }>,
  +lockingModeStrategy?: string,
  +carerFilterTypeStrategy?: string,
  +lockingMode?: string,
  +carerFilterType?: string,
  +colors: TimelineBlockColor,
  +carerBiddingEnabled?: boolean,
}

// This is a work in progress type, please add to it as you use it
export type PlacementSlot = {|
  +description: string,
  +id: string,
  +slotId: string,
  +placements: Array<PlacementDetails>,
  +serviceType: string,
  +slotId: string,
  +placementSelected?: string,
  +placementLookup?: {|
    +[string]: Object,
  |},
|}
