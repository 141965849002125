import { useState, useEffect } from 'react'

import type { SetCareAppraisalVersionParams } from '@elder/et-facade-et'
import {
  useEtSetCareAppraisalVersionUsingPUT as setCaVersion,
  useEtGetCareAppraisalVersionsUsingGET as useCaVersions,
} from '@elder/et-facade-et'
import {
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  Stack,
  Typography,
  Button,
} from '@mui/material'
import { useSnackbar } from 'notistack'
import { useDispatch } from 'react-redux'

import { SectionHeader } from 'components/styledComponentsTranslatedClasses/SectionHeader'
import { pageActions } from 'routes/account/summary/actions'

export const getLabeledCaVersion = (version: string): string => {
  const labels = {
    V2_3_0: 'V2_3_0: Matching ASAP without Disclaimers',
    V2_3_1: 'V2_3_1: Matching ASAP with Disclaimers',
    V2_4_0: 'V2_4_0: Visiting care 1.0',
    V2_5_0: 'V2_5_0: Onboarding 6/24 + Visiting care 2.0',
    NOT_SET: 'Not set',
  }

  return labels[version] || version
}

export const CareAppraisalVersionForm = ({
  accountId,
  currentVersion,
  cancelEdit,
}: {
  accountId: string
  currentVersion: string
  cancelEdit: () => void
}) => {
  const [version, setVersion] = useState(currentVersion)
  const [displayWarning, setDisplayWarning] = useState(false)
  const { enqueueSnackbar } = useSnackbar()
  const { data: caVersions, isLoading, isError } = useCaVersions()
  const dispatch = useDispatch()

  useEffect(() => {
    if (currentVersion !== version) {
      setDisplayWarning(true)
    }
  }, [version, setDisplayWarning, currentVersion])

  const mutation = setCaVersion({
    mutation: {
      onSuccess: () => {
        enqueueSnackbar('Care Appraisal version updated', {
          variant: 'success',
        })
        dispatch(pageActions.get())
        cancelEdit()
      },
      onError: () => {
        enqueueSnackbar('Error updating Care Appraisal version', {
          variant: 'error',
        })
      },
    },
  })

  const handleSave = () => {
    mutation.mutate({
      accountId,
      data: {
        appraisalVersion: version === 'NOT_SET' ? null : version,
      } as SetCareAppraisalVersionParams,
    })
  }

  return (
    <Stack spacing={4}>
      <SectionHeader>
        <Typography variant="h6">Care Appraisal Version</Typography>
      </SectionHeader>
      <FormControl>
        <InputLabel id="care-appraisal-version-label">
          Select version
        </InputLabel>
        <Select
          value={version}
          label="Current version"
          onChange={(e) => setVersion(e.target.value)}
          disabled={isLoading || isError}
        >
          {caVersions?.map((caVersion) => (
            <MenuItem key={caVersion} value={caVersion}>
              {getLabeledCaVersion(caVersion)}
            </MenuItem>
          ))}
        </Select>
      </FormControl>

      <Typography
        variant="subtitle2"
        component="p"
        color="error"
        hidden={!displayWarning}
        maxWidth="400px"
      >
        This will change things such as required and optional questions in the
        Care Appraisal, please make sure you are aware of what will change
        before submitting.
      </Typography>

      <Stack direction="row" justifyContent="flex-end">
        <Button
          onClick={cancelEdit}
          variant="outlined"
          disabled={mutation.isPending}
        >
          Cancel
        </Button>
        <Button
          variant="contained"
          onClick={handleSave}
          disabled={mutation.isPending}
        >
          Save
        </Button>
      </Stack>
    </Stack>
  )
}
