// @flow
import { endpointBuilder } from 'elder/services'

import {
  getNotesRequest,
  getNotesResponse,
  saveNoteRequest,
  pinNoteRequest,
  archiveNoteRequest,
  editNoteRequest,
} from 'features/notes/domain'

export const notesService = {
  getNotes: endpointBuilder(getNotesRequest, getNotesResponse)
    .withUrlTemplate('/et/notes/{type}/{id}', ({ type, id }) => ({
      type,
      id,
    }))
    .build(),
  saveNote: endpointBuilder(saveNoteRequest, getNotesResponse)
    .withHttpMethod('POST')
    .withUrlTemplate('/et/notes/')
    .withBodyBuilder((data) => data)
    .build(),
  pinNote: endpointBuilder(pinNoteRequest, getNotesResponse)
    .withHttpMethod('PUT')
    .withUrlTemplate('/et/notes/{noteId}/pin', ({ noteId }) => ({
      noteId,
    }))
    .withBodyBuilder(({ pinned, pinEntity }) => ({
      pinned,
      pinEntity,
    }))
    .build(),
  archiveNote: endpointBuilder(archiveNoteRequest, getNotesResponse)
    .withHttpMethod('PUT')
    .withUrlTemplate('/et/notes/{noteId}/archiveStatus', ({ noteId }) => ({
      noteId,
    }))
    .withBodyBuilder(({ archived }) => ({ archived }))
    .build(),
  editNote: endpointBuilder(editNoteRequest, getNotesResponse)
    .withHttpMethod('PUT')
    .withUrlTemplate('/et/notes/{noteId}', ({ noteId }) => ({
      noteId,
    }))
    .withBodyBuilder(({ parentEntity, tags, text, title }) => ({
      parentEntity,
      tags,
      text,
      title,
    }))
    .build(),
}
