// @flow
import { string } from 'flow-validator'

import { endpointBuilder } from 'elder/services'

import { accountInvoicesResponseType } from 'features/accountPayments/store/types'

export const accountPaymentsService = {
  getAccountInvoices: endpointBuilder(string, accountInvoicesResponseType)
    .withUrlTemplate(
      '/et/billingaccounts/{billingAccountId}/invoices',
      (billingAccountId) => ({ billingAccountId }),
    )
    .build(),
  closeAccountInvoice: endpointBuilder(string, accountInvoicesResponseType)
    .withUrlTemplate(
      '/et/billingaccounts/{accountId}/invoices/close',
      (accountId) => ({
        accountId,
      }),
    )
    .withHttpMethod('POST')
    .build(),
}
