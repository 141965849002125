// @flow
/* eslint-disable flowtype/no-weak-types */
import { createSelector } from 'reselect'

import { getLoginDetails } from 'app/selectors'

export const emailSelector = (state: Object) =>
  getLoginDetails(state).get('email')
export const getRoles = (state: Object) => getLoginDetails(state).get('roles')
export const getRolesToJs = createSelector([getRoles], (roles) => roles.toJS())

export const getCanEdit = createSelector([getRoles], (roles) =>
  roles.includes('ROLE_et_basic_readwrite'),
)

export const getCanReactivatePro = createSelector([getRoles], (roles) =>
  roles.includes('ROLE_et_pro_reactivation'),
)

export const getCanCreatePro = createSelector([getRoles], (roles) =>
  roles.includes('ROLE_et_pro_creation'),
)

export const getCanCreateCustomer = createSelector([getRoles], (roles) =>
  roles.includes('ROLE_et_customer_creation'),
)

export const getCanManagePayments = createSelector([getRoles], (roles) =>
  roles.includes('ROLE_payments_manual_transaction'),
)

export const getCanManagePaymentsManualTransaction = createSelector(
  [getRoles],
  (roles) => roles.includes('ROLE_payments_manual_transaction'),
)

export const getCanManagePaymentsBankTransfer = createSelector(
  [getRoles],
  (roles) => roles.includes('ROLE_payments_bank_transfer'),
)

export const getCanManagePaymentAssurance = createSelector(
  [getRoles],
  (roles) => roles.includes('ROLE_payment_assurance_required'),
)

export const getCanManageArchivedTasks = createSelector([getRoles], (roles) =>
  roles.includes('ROLE_et_task_archive_management'),
)

export const getCanUnsafeEditSolutions = createSelector([getRoles], (roles) =>
  roles.includes('ROLE_unsafe_solutions_editor'),
)

export const getCanImpersonateCarer = createSelector([getRoles], (roles) =>
  roles.includes('ROLE_et_bypass_proid_validation'),
)

export const getCanImpersonateCustomer = createSelector([getRoles], (roles) =>
  roles.includes('ROLE_et_bypass_user_profile_id_validation'),
)

export const getCanClearAccountHistory = createSelector([getRoles], (roles) =>
  roles.includes('ROLE_et_clear_account_history'),
)

export const getCanActionRestrictions = createSelector([getRoles], (roles) =>
  roles.includes('ROLE_customer_experience_managers'),
)

export const getCanChangeCommsOption = createSelector([getRoles], (roles) =>
  roles.includes('ROLE_customer_experience_managers'),
)

export const getCanUseLegacyMatching = createSelector([getRoles], (roles) =>
  roles.includes('ROLE_customer_experience_managers'),
)

export const getCanGiveCustomSolutionPricing = createSelector(
  [getRoles],
  (roles) => roles.includes('ROLE_et_custom_solution_pricing'),
)

export const getCanManageEvidence = createSelector([getRoles], (roles) =>
  roles.includes('ROLE_et_evidence_management'),
)

export const getCanEditAutomatedComms = createSelector([getRoles], (roles) =>
  roles.includes('ROLE_et_communications_editor'),
)

export const getCanEditCareAppraisalReviewStatus = createSelector(
  [getRoles],
  (roles) => roles.includes('ROLE_et_ca_signoff'),
)

export const getCanEditCarerBankDetails = createSelector([getRoles], (roles) =>
  roles.includes('ROLE_et_carer_bank_details_editor'),
)

export const getIsFinancialController = createSelector([getRoles], (roles) =>
  roles.includes('ROLE_financial_controllers'),
)

export const getIsBillingArrangementManager = createSelector(
  [getRoles],
  (roles) => roles.includes('ROLE_billing_arrangement_management'),
)

export const getCanBatchEditAccounts = createSelector([getRoles], (roles) =>
  roles.includes('ROLE_batch_account_management'),
)

export const getCanManageBillingAccountsPaymentTerms = createSelector(
  [getRoles],
  (roles) => roles.includes('ROLE_et_billing_account_management'),
)
