// @flow
/* eslint-disable react/prop-types */

import React from 'react'

type Props = {|
  className?: string,
  width?: number,
  height?: number,
|}

export const File = ({ className, width = 48, height = 62 }: Props) => (
  <svg
    className={className}
    width={width}
    height={height}
    viewBox="0 0 48 62"
    version="1.1"
    xmlns="http://www.w3.org/2000/svg"
    xmlnsXlink="http://www.w3.org/1999/xlink"
  >
    <title>File</title>
    <g stroke="#979797" fill="none" fillRule="evenodd">
      <rect fill="#E3F6FB" x="8.5" y=".5" width="39" height="52.333" rx="2" />
      <path
        d="M39.5 24.212L23.794 8.5H2A1.5 1.5 0 0 0 .5 10v49.333a1.5 1.5 0 0 0 1.5 1.5h36a1.5 1.5 0 0 0 1.5-1.5V24.212z"
        fill="#FFF"
      />
      <path
        strokeLinecap="round"
        strokeLinejoin="round"
        d="M9 27h22.5v12H9zM9.333 42.667h21.334M9.333 46.667h21.334M9.333 50.667h8.478"
      />
      <path
        d="M24.5 9.207V22a1.5 1.5 0 0 0 1.5 1.5h12.793L24.5 9.207z"
        fill="#FFF"
      />
      <path
        fill="#FFB0D2"
        strokeLinecap="round"
        strokeLinejoin="round"
        d="M15.287 39l4.328-4.5 3.656 2.25 4.154-5.523 4.089 3.273V39z"
      />
      <circle fill="#FFB0D2" cx="14.25" cy="32.25" r="2.25" />
    </g>
  </svg>
)
