// @flow

// Profiles
export const profiles = '/profiles'
export const newProfile = `${profiles}/new`
export const wrongCaseProfile = `${profiles}/:profileId(U[0-9]+)`
export const profile = `${profiles}/:profileId(u[0-9]+)`
export const profileChangelog = `${profile}/changelog`

// Carers
export const professionals = '/professionals'
export const carersNew = `${professionals}/new`
// Try to fix the casing for carer ids when copied in incorrectly
export const wrongCaseCarer = `${professionals}/:carerId(P[0-9]+)`
// Render carers only on valid carer ids, 404 on others
export const carer = `${professionals}/:carerId(p[0-9]+)`
export const carerBasicInfo = `${carer}/basicinfo`
export const carerChangelog = `${carer}/history`
export const carerSkills = `${carer}/skillsandexperience`
export const carerSolutionsDetails = `${carer}/solutions`
export const carerMatching = `${carer}/matching`
export const carerFeedback = `${carer}/feedback`
export const carerActivity = `${carer}/activity`
export const carerDocuments = `${carer}/documents`
export const carerPreferences = `${carer}/preferences`
export const carerSolutions = `${carer}/solutions`
export const carerSolutionsPlacement = `${carerSolutions}/#?placementId=:placementId`
export const carerFinance = `${carer}/finance`
export const carerFinanceNew = `${carerFinance}/new-transaction`
export const carerMessages = `${carer}/messages`

// Accounts
export const accounts = '/accounts'
// Try to fix the casing for account ids when copied in incorrectly
export const wrongCaseAccount = `${accounts}/:accountId(C[0-9]+)`
export const createAccount = `${accounts}/new`
// Render account only on valid account ids, 404 on others
export const account = `${accounts}/:accountId(c[0-9]+)`
export const accountWithSectionOpen = `${account}?section=:sectionId`
export const solutions = `${account}/solutions`
export const solution = `${solutions}?solutionId=:solutionId`
export const placement = `${solution}&placementId=:placementId`
export const careRecipient = `${account}/recipient-:recipientId`
export const createCareRecipient = `${account}/new-recipient`
export const accountChangelog = `${account}/changelog`
export const accountEnvironment = `${account}/environment`
export const accountFinance = `${account}/finance`
export const accountFinanceNew = `${accountFinance}/new-transaction`

// Tasks
export const taskDeepLink = '/tasks/:taskId'

// Recipients
export const careRecipientPage = `${account}/recipient/:recipientId`

export const healthOverview = `${careRecipientPage}/careAppraisal/:version`
export const homeEnvironment = `${careRecipientPage}/homeEnvironment/:version`

// Salesforce
export const salesForce = '/salesforce/account/:salesforceId'

// Matching
export const matchingCentre = '/matching'

// Elder users
export const elderUsers = '/elderUsers'

// Billing Accounts
export const billingAccounts = '/billingAccounts'
export const billingAccountDetails = `${billingAccounts}/:accountId([bc][0-9]+)`
export const billingAccountFinance = `${billingAccountDetails}/finance`

// Recents
export const recents = '/recents'

// Leasing
export const leasing = '/leasing'

// Other
export const index = '/'
export const newToLive = '/newtolive'
export const payments = '/payments'
export const logout = '/logout'
export const notFound = '/404'

// Partnerships
export const partner = '/partners/:accountId'

// Chat
export const chat = '/chat'
export const chatWithId = `${chat}/:conversationId?`
