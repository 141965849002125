// @flow
import { createSelector } from 'reselect'

import { NAMESPACE } from '.'

const contactsStateSelector = (state) => state.get(NAMESPACE).toJS()

export const dataSelector = createSelector(
  [contactsStateSelector],
  (state) => state.data,
)

export const isLoadingSelector = createSelector(
  [contactsStateSelector],
  (state) => state.isLoading,
)

export const idSelector = createSelector(
  [contactsStateSelector],
  (state) => state.id,
)

export const errorSelector = createSelector(
  [contactsStateSelector],
  (state) => state.error,
)

export const failedSelector = createSelector(
  [contactsStateSelector],
  (state) => !!state.error,
)

export const getContactsSummary = createSelector(
  [dataSelector],
  (contactsState) => {
    const contacts = contactsState || []
    return contacts.map((contact) => {
      let phoneNumber = ''
      if (contact.phoneNumbers && contact.phoneNumbers.length) {
        const { phoneNumbers } = contact
        phoneNumber = phoneNumbers[0] // eslint-disable-line prefer-destructuring
        if (contact.phoneNumbers.length > 1) {
          phoneNumber = `${phoneNumber} (${
            contact.phoneNumbers.length - 1
          } more)`
        }
      }

      return {
        profile: {
          id: contact.profileId,
          text: contact.name,
        },
        phoneNumber,
        email: contact.email,
        relationship: contact.relationship || '',
        roles: contact.roles,
      }
    })
  },
)
