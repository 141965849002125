import { useMemo } from 'react'

import type {
  EntityReferenceType,
  TalkJsConfigResponse,
} from '@elder/et-facade-et'
import {
  etCreateConversationFromEtUsingPUT as createConversation,
  useEtGetTalkJsConfigForCustomerPortalFromEtUsingGET as useConfigForCustomerChat,
  useEtGetTalkJsConfigForProPortalFromEtUsingGET as useConfigForCarerChat,
} from '@elder/et-facade-et'
import { useQuery } from '@tanstack/react-query'
import type { ConversationPredicate } from 'talkjs/all'

import { ChatComponent, ChatSkeleton } from './Chat'

type ConversationTypes = 'CUSTOMER_TO_ELDER' | 'CARER_TO_ELDER'
type UserTypes = 'customer' | 'carer'

interface ConversationType {
  userType: UserTypes
  includeElder: boolean
  configEndpoint
}

const typesMapper: Record<ConversationTypes, ConversationType> = {
  CUSTOMER_TO_ELDER: {
    userType: 'customer',
    configEndpoint: useConfigForCustomerChat,
    includeElder: true,
  },
  CARER_TO_ELDER: {
    userType: 'carer',
    configEndpoint: useConfigForCarerChat,
    includeElder: true,
  },
}

interface Props {
  identifier: string
  conversationType: ConversationTypes
}

export const ChatContainer = ({ identifier, conversationType }: Props) => {
  const { userType, configEndpoint, includeElder } =
    typesMapper[conversationType]

  const {
    data: conversationData,
    error: conversationError,
    isError: conversationIsError,
    isPending: conversationIsLoading,
  } = useQuery({
    queryFn: () =>
      createConversation({
        primaryEntityReference: {
          identifier,
          type: userType as unknown as EntityReferenceType,
        },
        accountIds: [],
        carerIds: [],
        includeElder,
        conversationType,
      }),
    queryKey: ['createConversation', identifier],
    enabled: Boolean(identifier),
  })

  const {
    data: configData,
    error: configError,
    isError: configIsError,
    isPending: configIsLoading,
  } = configEndpoint(identifier)

  if (conversationIsError) throw conversationError

  if (configIsError) throw configError

  const feedFilter = useFeedFilter(conversationType, configData)

  if (configIsLoading || conversationIsLoading) return <ChatSkeleton />

  return (
    <ChatComponent
      conversationId={conversationData as unknown as string}
      feedFilter={feedFilter}
    />
  )
}
const useFeedFilter = (
  conversationType: ConversationTypes,
  configData: TalkJsConfigResponse | undefined,
): ConversationPredicate => {
  const feedFilter = useMemo(() => {
    if (conversationType === 'CUSTOMER_TO_ELDER') {
      return {
        custom: {
          encryptedCustomerAccountId: [
            '==',
            configData?.encryptedCustomerAccountId as string,
          ] as ['==' | '!=', string],
        },
      } as ConversationPredicate
    }

    if (conversationType === 'CARER_TO_ELDER') {
      return {
        custom: {
          encryptedCarerId: ['==', configData?.encryptedCarerId as string],
        },
      } as ConversationPredicate
    }

    throw new Error('Chat: error getting feed filter')
  }, [conversationType, configData])

  return feedFilter
}
