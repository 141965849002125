// @flow

import { enumFromObjectKeys } from '@elder/common'
import { object, boolean, string, arrayOf, number } from 'flow-validator'

import type { FlowOfType } from 'elder/types'

import { name } from 'domain/name'

const address = object({
  address1: string.optional(),
  address2: string.optional(),
  postcode: string.optional(),
  city: string.optional(),
  country: string.optional(),
})

export const billingCycleOptions = {
  none: 'None',
  weekly: 'Weekly',
  monthly: 'Monthly',
}
export type BillingCycle = $Keys<typeof billingCycleOptions>

const paymentTerms = object({
  daysUntilDue: number.optional(),
}).optional()
export type PaymentTerms = FlowOfType<typeof paymentTerms>

export const paymentTypes = {
  none: 'none',
  invoice: 'invoice', // Bank transfer directly with Elder's bank account
  stripeBankTransfer: 'stripeBankTransfer', // Bank transfer via Stripe
  stripe: 'stripe',
  stripeV2: 'stripeV2',
  goCardless: 'goCardless', // direct debit
  manualInvoicing: 'manualInvoicing',
}
export type PaymentType = $Keys<typeof paymentTypes>

export const paymentMethods = {
  // the stripe flow hasn't been implemented yet for non-customer account
  // billing accounts
  stripe: 'stripe',
  // the direct debit flow hasn't been implemented yet for non-customer account
  // billing accounts
  directDebit: 'directDebit',
  bankTransfer: 'bankTransfer',
  stripeBankTransfer: 'stripeBankTransfer',
  manualInvoicing: 'manualInvoicing',
  mandate: 'mandate',
}

export type PaymentMethods = $Keys<typeof paymentMethods>

export const BILLING_ACCOUNT_TYPE = {
  // Billing account that does not have a single customer account, and therefore must be updated
  // manually.
  CUSTOM: 'CUSTOM',
  // Billing account that gets automatic updates from its customer account, e.g. pdf details such as
  // care recipient names and care address.
  AUTO: 'AUTO',
}

export type BillingAccountType = $Keys<typeof BILLING_ACCOUNT_TYPE>

const BILLING_ACCOUNT_REGULATORY_MODEL = {
  INTRODUCTORY: 'INTRODUCTORY',
  REGULATED: 'REGULATED',
}

const pdfColumn = object({
  header: string.optional(),
  body: string.optional(),
})

export const pdfPreview = object({
  column1: pdfColumn.optional(),
  column2: pdfColumn.optional(),
  column3: pdfColumn.optional(),
})

export type BillingAccountPdfDetails = FlowOfType<typeof pdfPreview>

const billingCycle = object({
  type: enumFromObjectKeys(billingCycleOptions),
  dayOfMonth: number.optional(),
})

const regulatoryModel = enumFromObjectKeys(
  BILLING_ACCOUNT_REGULATORY_MODEL,
).optional()

const phoneNumber = object({
  number: string.optional(),
  description: string.optional(),
})

export type PhoneNumber = FlowOfType<typeof phoneNumber>

const contactDetails = object({
  name,
  email: string.optional(),
  phoneNumbers: arrayOf(phoneNumber).optional(),
  address: address.optional(),
})

export type PayeeDetails = FlowOfType<typeof contactDetails>

const paymentMethod = object({
  type: enumFromObjectKeys(paymentTypes),
  mandateId: string.optional(),
  stripeCustomerId: string.optional(),
  cardLast4Digits: string.optional(),
  nameOnCard: string.optional(),
  description: string.optional(),
  directDebitBankAccount: object({
    bankName: string.optional(),
    accountHolderName: string.optional(),
    accountNumberEnding: string.optional(),
  }).optional(),
}).optional()

export const billingAccountResponseType = object({
  id: string,
  type: enumFromObjectKeys(BILLING_ACCOUNT_TYPE),
  createdAt: string,
  displayName: string.optional(),
  payeeDetails: contactDetails.optional(),
  stripeKey: string.optional(),
  billingCycle,
  paymentTerms,
  paymentMethod,
  /**
   * Details customer should use to transfer funds when paying by Stripe bank transfer.
   */
  stripeBankTransferBankDetails: object({
    accountHolderName: string,
    accountNumber: string,
    sortCode: string,
  }).optional(),
  regulatoryModel,
  directDebitPending: boolean.optional(),
  invoicePdf: object({
    // this only returns overrides, if any
    customColumns: pdfPreview.optional(),
    // this is what will be displayed on the pdf
    preview: pdfPreview,
  }),
  // deprecated
  pdfPreview: pdfPreview.optional(),
})

export type BillingAccount = FlowOfType<typeof billingAccountResponseType>

export const getBillingAccountRequestType = object({
  id: string,
})

export const updateDisplayNameRequestType = object({
  id: string,
  displayName: string.optional(),
})

export const updatePayeeDetailsRequestType = object({
  id: string,
  details: contactDetails,
})

export const updateBillingCycleRequestType = object({
  id: string,
  cycle: object({
    type: enumFromObjectKeys(billingCycleOptions),
    dayOfMonth: number.optional(),
  }),
})

type StripePayload = {|
  +type: 'stripe',
  +payload: {|
    stripeCardToken: string,
    cardLast4: string,
    nameOnCard: string,
  |},
|}

type DirectDebitPayload = {|
  +type: 'directDebit',
  +payload: {|
    +directDebitBankAccount: {|
      +bankName: string,
      +accountHolderName: string,
      +accountNumberEnding: string,
    |},
  |},
|}

type DirectDebitMandatePayload = {|
  +type: 'mandate',
  +payload: {
    mandateId: string,
  },
|}
type ManualInvoicingPayload = {|
  +type: 'manualInvoicing',
  payload: {
    description: string,
  },
|}

// type: invoice
type BankTransferPayload = {|
  +type: 'bankTransfer',
  +payload: {},
|}

// type: Stripe bank transfer
type StripeBankTransferPayload = {|
  +type: 'stripeBankTransfer',
  +payload: {},
|}

export type PaymentMethodsPayloads =
  | StripePayload
  | ManualInvoicingPayload
  | StripeBankTransferPayload
  | BankTransferPayload
  | DirectDebitPayload
  | DirectDebitMandatePayload

export const directDebitMandateRequestType = object({
  id: string,
  payload: object({ mandateId: string }),
})

export const editStripeRequestType = object({
  id: string,
  payload: object({
    stripeCardToken: string,
    cardLast4: string,
    nameOnCard: string,
  }),
})

export const editDirectDebitRequestType = object({
  id: string,
  payload: object({}),
})

export const editBankTransferRequestType = object({
  id: string,
  payload: object({}),
})

export const editStripeBankTransferRequestType = object({
  id: string,
  payload: object({}),
})

export const editManualInvoicingRequestType = object({
  id: string,
  payload: object({
    description: string,
  }),
})

export const cancelDirectDebitRequestType = object({
  id: string,
})

export const editInvoiceDetailsRequestType = object({
  billingAccountId: string,
  pdfPreview,
})
