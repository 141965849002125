// @flow

import React from 'react'

import type { ActiveWorkflow } from 'features/activeWorkflow/types'
import { AddNoteModalContainer } from 'features/notes/AddNoteModalContainer'
import { customerTemplates } from 'features/notes/templates/customerTemplates'
import { ADD_NOTE_FLOW } from 'features/notes/workflows'

type ModalsProps = {|
  +customerId: string,
  +activeWorkflow: ActiveWorkflow,
  +clearActiveWorkflow: () => void,
|}

export const Modals = ({
  customerId,
  activeWorkflow,
  clearActiveWorkflow,
}: ModalsProps) => {
  if (activeWorkflow.name === ADD_NOTE_FLOW) {
    return (
      <AddNoteModalContainer
        closeDialog={() => clearActiveWorkflow()}
        notesEntityType="customer"
        notesEntityId={customerId}
        templates={customerTemplates}
      />
    )
  }
  return null
}
