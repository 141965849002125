// @flow
import { addNamespace } from 'utils'

export const SET_ID = 'SET_ID'
export const GET_IDENTITY = 'GET_IDENTITY'
export const SET_IDENTITY = 'SET_IDENTITY'

export const makeActions = (namespace: string) => ({
  setId: (id: string) => ({
    type: addNamespace(namespace, SET_ID),
    id,
  }),

  getIdentity: () => ({
    type: addNamespace(namespace, GET_IDENTITY),
  }),

  setIdentity: (identity: Object) => ({
    type: addNamespace(namespace, SET_IDENTITY),
    identity,
  }),
})

export default makeActions
