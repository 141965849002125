// @flow

// $FlowOptOut
import React, { useEffect, useRef } from 'react'

import { Box, InputAdornment, Tooltip } from '@mui/material'

import { logAmplitudeEvent } from 'features/analytics/amplitude'

export const usePressKeyToFocus = (keyCode: number) => {
  const target = useRef()

  useEffect(() => {
    const handler = (e: KeyboardEvent) => {
      if (e.keyCode === keyCode) {
        target.current.focus()
        target.current.select()
        logAmplitudeEvent('Field Focused Through Keypress', { keyCode })
        e.preventDefault()
      }
    }
    document.addEventListener('keydown', handler)
    return () => {
      document.removeEventListener('keydown', handler)
    }
  }, [])

  return { inputRef: target }
}

export const FocusKeyInputAdornment = ({ label }: { label: string }) => (
  <InputAdornment
    position="end"
    sx={{ '.Mui-focused>&': { visibility: 'hidden' } }}
  >
    <Tooltip
      title={
        <span>
          Press <code>{label}</code> to focus thie field
        </span>
      }
    >
      <Box
        sx={{
          background: 'rgba(0,0,0,0.12)',
          px: 1,
          borderRadius: 1,
        }}
      >
        <code>{label}</code>
      </Box>
    </Tooltip>
  </InputAdornment>
)
