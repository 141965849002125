// @flow
import { refDataEnum } from '@elder/common'
import { object, string, Type } from 'flow-validator'

import { endpointBuilder } from 'elder/services'

import {
  invoicesResponse,
  manualTransaction,
} from 'features/carerFinances/finance/types'

const getInvoicesRequest: Type<*> = object({
  carerId: string,
})
const closeInvoiceRequest: Type<*> = object({
  carerId: string,
  regulatoryModel: refDataEnum,
})
const createManualTransactionRequest: Type<*> = object({
  carerId: string,
  regulatoryModel: string,
  data: manualTransaction,
})
const cancelTransactionRequest: Type<*> = object({
  carerId: string,
  regulatoryModel: refDataEnum,
  transactionId: string,
})

export const professionalService = {
  getInvoices: endpointBuilder(getInvoicesRequest, invoicesResponse)
    .withUrlTemplate('/et/professionals/{carerId}/invoices')
    .build(),
  closeInvoice: endpointBuilder(closeInvoiceRequest, invoicesResponse)
    .withHttpMethod('POST')
    .withUrlTemplate('/et/professionals/{carerId}/invoices/close')
    .withBodyBuilder(({ regulatoryModel }) => ({
      regulatoryModel,
    }))
    .build(),
  createManualTransaction: endpointBuilder(
    createManualTransactionRequest,
    invoicesResponse,
  )
    .withHttpMethod('POST')
    .withUrlTemplate('/et/professionals/{carerId}/invoices/manualTransaction')
    .withBodyBuilder(({ regulatoryModel, data }) => ({
      regulatoryModel,
      ...data,
    }))
    .build(),
  cancelTransaction: endpointBuilder(cancelTransactionRequest, invoicesResponse)
    .withHttpMethod('DELETE')
    .withUrlTemplate(
      '/et/professionals/{carerId}/invoices/transaction/{regulatoryModel}/{transactionId}',
    )
    .build(),
}
