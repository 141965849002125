const addParam = (currentQuery, key, value) => {
  const prefix = !currentQuery ? '?' : `${currentQuery}&`
  return `${prefix + key}=${encodeURIComponent(value)}`
}

const createArrayParamAccumulator = (param) => (query, value) =>
  addParam(query, param, value)

export const buildQuery = (params) => {
  let res = ''
  // eslint-disable-next-line no-restricted-syntax
  for (const param in params) {
    // eslint-disable-next-line no-prototype-builtins
    if (params.hasOwnProperty(param)) {
      const value = params[param]
      if (value instanceof Array) {
        res = value.reduce(createArrayParamAccumulator(param), res)
      } else if (value || value === false) {
        res = addParam(res, param, value)
      }
    }
  }
  return res
}

export default buildQuery
