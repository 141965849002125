// @flow
import type { Saga } from 'redux-saga'

import { call, put, takeLatest } from 'elder/effects'

import { carerMatchingClient } from 'app/saga/serviceClients'
import { clearActiveWorkflow } from 'features/activeWorkflow/actions'
import type { Restriction } from 'features/carerMatching/domain'
import {
  addRestriction,
  editRestriction,
  deleteRestriction,
  restrictionButtonSpinnerActions,
  getAppliedPlacements,
  cancelAppliedPlacement,
  cancelAppliedPlacementComplete,
  setAppliedPlacements,
  clearLoading,
} from 'features/carerMatching/store/actions'
import { setCarer } from 'features/carerPageGenerics/store/actions'
import { applicationError } from 'features/snackbar/snackbar'
import { describeServiceError } from 'utils/services/errors'

function* handleAddRestriction({
  restriction,
  carerId,
}: {
  +restriction: Restriction,
  +carerId: string,
}): Saga<*> {
  try {
    yield* put(restrictionButtonSpinnerActions.setStartLoading())
    const response = yield* call(carerMatchingClient.addRestriction, {
      carerId,
      description: restriction.description,
      endDateInclusive: restriction.endDateInclusive,
      startDateInclusive: restriction.startDateInclusive,
      reason: restriction.reason,
    })
    yield* put(setCarer(response))
    yield* put(clearActiveWorkflow())
  } catch (error) {
    applicationError(
      describeServiceError(
        error,
        `Unable to add restriction for carer ${carerId}`,
      ),
    )
  } finally {
    yield* put(restrictionButtonSpinnerActions.setStopLoading())
  }
}

function* handleDeleteRestriction({
  restrictionId,
  carerId,
}: {
  +restrictionId: string,
  +carerId: string,
}): Saga<*> {
  try {
    yield* put(restrictionButtonSpinnerActions.setStartLoading())
    const response = yield* call(carerMatchingClient.deleteRestriction, {
      carerId,
      restrictionId,
    })
    yield* put(setCarer(response))
    yield* put(clearActiveWorkflow())
  } catch (error) {
    applicationError(
      describeServiceError(
        error,
        `Unable to delete restriction for carer ${carerId}`,
      ),
    )
  } finally {
    yield* put(restrictionButtonSpinnerActions.setStopLoading())
  }
}

function* handleEditRestriction({
  restriction: {
    restrictionId,
    description,
    endDateInclusive,
    startDateInclusive,
    reason,
  },
  carerId,
}: {
  +restriction: Restriction,
  +carerId: string,
}): Saga<*> {
  try {
    yield* put(restrictionButtonSpinnerActions.setStartLoading())
    const response = yield* call(carerMatchingClient.editRestriction, {
      carerId,
      restrictionId,
      description,
      endDateInclusive,
      startDateInclusive,
      reason,
    })
    yield* put(setCarer(response))
    yield* put(clearActiveWorkflow())
  } catch (error) {
    applicationError(
      describeServiceError(
        error,
        `Unable to edit restriction for carer ${carerId}`,
      ),
    )
  } finally {
    yield* put(restrictionButtonSpinnerActions.setStopLoading())
  }
}

function* handleCancelAppliedPlacement({
  matchRequestId,
  carerId,
}: {
  +matchRequestId: string,
  +carerId: string,
}): Saga<*> {
  try {
    yield* call(carerMatchingClient.cancelAppliedPlacement, {
      matchRequestId,
      carerId,
    })
    yield* put(getAppliedPlacements(carerId))
  } catch (error) {
    applicationError(
      describeServiceError(
        error,
        `Unable to cancel applied placement for carer ${carerId}`,
      ),
    )
  } finally {
    yield* put(cancelAppliedPlacementComplete(matchRequestId))
  }
}

function* handleGetAppliedPlacements({
  carerId,
}: {
  +carerId: string,
}): Saga<*> {
  try {
    const response = yield* call(carerMatchingClient.getAppliedPlacements, {
      carerId,
    })
    yield* put(setAppliedPlacements(response))
  } catch (error) {
    applicationError(
      describeServiceError(
        error,
        `Unable to get applied placements for carer ${carerId}`,
      ),
    )
  } finally {
    yield* put(clearLoading())
  }
}

export function* sagas(): Saga<*> {
  yield* takeLatest(addRestriction, handleAddRestriction)
  yield* takeLatest(deleteRestriction, handleDeleteRestriction)
  yield* takeLatest(editRestriction, handleEditRestriction)
  yield* takeLatest(cancelAppliedPlacement, handleCancelAppliedPlacement)
  yield* takeLatest(getAppliedPlacements, handleGetAppliedPlacements)
}
