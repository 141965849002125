// @flow
import { object, number, string, Type, arrayOf, boolean } from 'flow-validator'

import type { FlowOfType } from 'elder/types'
import { enumFromObjectKeys } from 'elder/types'

export const USER_STATUS = {
  ACTIVE: 'ACTIVE',
  AWAY: 'AWAY',
  NON_WORKING_DAY: 'NON_WORKING_DAY',
}

const userStatus = enumFromObjectKeys(USER_STATUS)

export type UserStatus = FlowOfType<typeof userStatus>

const elderUser: Type<*> = object({
  accountLoad: number,
  disabled: boolean,
  elderPhotoUrl: string.optional(),
  elderStatus: userStatus.optional(),
  firstName: string.optional(),
  lastName: string.optional(),
  username: string,
  acceptingNewAccounts: boolean.optional(),
  calendlyUrl: string.optional(),
  primaryAccountManagerLoad: number,
  primaryAccountManagerMaxLoad: number,
  primaryAccountManagerLoadPercentage: number.optional(),
  workingDays: arrayOf(string).optional(),
  worksBankHolidays: boolean.optional(),
})

export type ElderUser = FlowOfType<typeof elderUser>

export const elderUsers: Type<*> = arrayOf(elderUser)

export type ElderUsers = FlowOfType<typeof elderUsers>

export const updateUserRequest = object({
  username: string,
  status: userStatus,
})

export type ElderUserFilters = {|
  +textSearch: string,
  +activeAccounts: boolean,
  +awayAccounts: boolean,
  +nonWorkingDayAccounts: boolean,
  +disabledAccounts: boolean,
|}

export const updateMaxLoadRequest = object({
  username: string,
  maxLoad: number,
})

export const PRETTY_STATUS = {
  ALL: 'All (exc. archived)',
  ACTIVE: 'Active',
  AWAY: 'Away',
  NON_WORKING_DAY: 'Non-working day',
  DISABLED: 'Archived',
}
