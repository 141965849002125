// @flow

import styled from 'styled-components'

export const Row = styled.div`
  display: flex;
  flex-direction: row;
  & > * {
    flex: 1;
  }
  & > *:not(:last-child) {
    margin-right: 20px;
  }
`
