import {
  Typography,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  MenuItem,
  Select,
  Stack,
} from '@mui/material'

interface Props {
  callbackUrl: string
  setCallbackUrl: (url: string) => void
  isOpen: boolean
  setIsOpen: (open: boolean) => void
  urls: string[]
}

export const ImpersonationOptionsDialog = ({
  callbackUrl,
  setCallbackUrl,
  isOpen,
  setIsOpen,
  urls,
}: Props) => {
  return (
    <Dialog open={isOpen} onClose={() => setIsOpen(false)}>
      <DialogTitle>Custom impersonation callback url</DialogTitle>
      <DialogContent>
        <Stack>
          <Typography>
            Update the url we will redirect back to once ET has generated your
            impersonation token. This is useful to test impersonation in a local
            environment.
            <br />
          </Typography>
          <Select
            value={callbackUrl}
            onChange={(event) => {
              setCallbackUrl(event.target.value)
            }}
            fullWidth
          >
            {urls.map((url) => (
              <MenuItem key={url} value={url}>
                {url}
              </MenuItem>
            ))}
          </Select>
        </Stack>
      </DialogContent>
      <DialogActions>
        <Button onClick={() => setIsOpen(false)} color="primary">
          Back
        </Button>
      </DialogActions>
    </Dialog>
  )
}
