// @flow

import { makeAction } from 'elder/actions'
import type { ActionOf } from 'elder/types'

import type {
  PayeeDetails,
  BillingCycle,
  PaymentMethodsPayloads,
  BillingAccountPdfDetails,
  BillingAccount,
} from 'features/billingAccounts/domain'

export const getAccountDetails = makeAction(
  'BILLING_ACCOUNTS/GET_ACCOUNT_DETAILS',
  (id: string) => ({ id }),
)

export const setAccountDetails = makeAction(
  'BILLING_ACCOUNTS/SET_ACCOUNT_DETAILS',
  (accountDetails: BillingAccount) => ({
    accountDetails,
  }),
)

export const setUpdatedStripeAccountDetails = makeAction(
  'BILLING_ACCOUNTS/SET_UPDATED_STRIPE_DETAILS',
  (accountDetails: BillingAccount) => ({ accountDetails }),
)

export const setUpdatedMandateAccountDetails = makeAction(
  'BILLING_ACCOUNTS/SET_UPDATED_MANDATE_DETAILS',
  (accountDetails: BillingAccount) => ({ accountDetails }),
)

export const setUpdatedDirectDebitAccountDetails = makeAction(
  'BILLING_ACCOUNTS/SET_UPDATED_DIRECT_DEBIT_DETAILS',
  (accountDetails: BillingAccount) => ({ accountDetails }),
)

export const setUpdatedBankTransferAccountDetails = makeAction(
  'BILLING_ACCOUNTS/SET_UPDATED_ACCOUNT_TRANSFER_DETAILS',
  (accountDetails: BillingAccount) => ({ accountDetails }),
)

export const setUpdatedManualInvoicingAccountDetails = makeAction(
  'BILLING_ACCOUNTS/SET_UPDATED_MANUAL_INVOICING_DETAILS',
  (accountDetails: BillingAccount) => ({ accountDetails }),
)

export const updateDisplayName = makeAction(
  'BILLING_ACCOUNTS/UPDATE_DISPLAY_NAME',
  (id: string, displayName: ?string) => ({ id, displayName }),
)

export const updatePayeeDetails = makeAction(
  'BILLING_ACCOUNTS/UPDATE_PAYEE_DETAILS',
  (id: string, details: PayeeDetails) => ({
    id,
    details,
  }),
)

export type Cycle = {| type: BillingCycle, dayOfMonth: ?number |}
export const updateBillingCycle = makeAction(
  'BILLING_ACCOUNTS/UPDATE_BILLING_CYCLE',
  (id: string, cycle: Cycle) => ({
    id,
    cycle,
  }),
)

export const editPaymentMethod = makeAction(
  'BILLING_ACCOUNTS/EDIT_PAYMENT_METHOD',
  (id: string, paymentMethod: PaymentMethodsPayloads) => ({
    id,
    paymentMethod,
  }),
)

export const cancelDirectDebit = makeAction(
  'BILLING_ACCOUNTS/CANCEL_DIRECT_DEBIT',
  (id: string) => ({
    id,
  }),
)

export const createNewBillingAccount = makeAction(
  'BILLING_ACCOUNTS/CREATE_NEW_BILLING_ACCOUNT',
)

export const editInvoiceDetails = makeAction(
  'BILLING_ACCOUNTS/EDIT_INVOICE_DETAILS',
  (billingAccountId: string, pdfPreview: BillingAccountPdfDetails) => ({
    billingAccountId,
    pdfPreview,
  }),
)

export type BillingAccountAction =
  | ActionOf<typeof setAccountDetails>
  | ActionOf<typeof updateDisplayName>
  | ActionOf<typeof updatePayeeDetails>
  | ActionOf<typeof updateBillingCycle>
  | ActionOf<typeof editPaymentMethod>
  | ActionOf<typeof cancelDirectDebit>
  | ActionOf<typeof createNewBillingAccount>
  | ActionOf<typeof editInvoiceDetails>
