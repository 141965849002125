// @flow

import type { Type } from 'flow-validator'

// Created as default flow validator throws a very strange type of exception
// with no real detail in the message. This rudely forces any such error
// thrown to contain a message that is useful to us and to Sentry
export const validate = <T>(type: Type<T>, unvalidated: any): T => {
  try {
    return type.parse(unvalidated)
  } catch (ex) {
    if (ex.payload && ex.payload.errors) {
      ex.message = `Validation Error: ${JSON.stringify(ex.payload.errors)}`
      // eslint-disable-next-line no-console
      console.error(ex.message)
    }
    throw ex
  }
}
