// @flow
import { SET_IS_EXPANDED } from 'routes/profile/summary/actions'

export const isExpandedReducer = (
  initialState: Object = {},
  action: Object,
) => {
  switch (action.type) {
    case SET_IS_EXPANDED:
      return { [action.index]: true }
    default:
      return initialState
  }
}

export default isExpandedReducer
