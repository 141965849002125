import type { ReactNode } from 'react'
import React from 'react'

import type { Moment } from 'moment'
import styled from 'styled-components'

import { TimelineDots } from 'components/timelines/TimelineDots'

import { Dot } from './Dot'

export interface TimelineBlockColor {
  default: string
  hover?: string
  selected?: string
  text: string
}

export interface TimelineBlock<TData> {
  id: string
  key?: string
  from: number
  to: number
  colors: TimelineBlockColor
  isInfinite?: boolean
  startDateInclusive: Moment
  endDateExclusive: Moment
  data: TData
}

const StyledSolutionTimeline = styled.div`
  box-sizing: content-box;
  position: relative;
  z-index: 5;
  height: ${({ $height }) => `${$height}px`};
  width: 100%;
  filter: saturate(${({ saturation }) => saturation});
`

const StyledTimelineBlocks = styled.div`
  display: flex;
  height: ${({ $height }) => `${$height}px`};
  width: 100%;
  overflow-x: hidden;
  position: relative;
`
const StyledStopMarker = styled.span`
  background-color: black;
  cursor: pointer;
  height: 28px;
  margin: 0 10px;
  width: 6px;
  z-index: 1;
`

const StyledInfinityMarker = styled.span`
  background-color: white;
  display: block;
  font-size: 22px;
  margin: 0 10px 2px 5px;
  z-index: 1;
`

type TimelineBlockPositions<TData> = {
  blocksBefore: TimelineBlock<TData>[]
  blocksAfter: TimelineBlock<TData>[]
  blocksInView: TimelineBlock<TData>[]
}

/**
 * Map the blocks to the block positions object so we can decide which blocks should currently be displayed and
 * which blocks should be positioned as before or after blocks.
 */
const getBlockPositions = <TData,>(
  blocks: TimelineBlock<TData>[],
  daysDisplayed: number,
): TimelineBlockPositions<TData> => {
  const blockPositions: TimelineBlockPositions<TData> = {
    blocksBefore: [],
    blocksAfter: [],
    blocksInView: [],
  }

  blocks.forEach((block) => {
    if (block.from < 0 && block.to <= 0) {
      blockPositions.blocksBefore.push(block)
    } else if (block.from >= daysDisplayed && block.to > daysDisplayed) {
      blockPositions.blocksAfter.push(block)
    }

    blockPositions.blocksInView.push(block)
  })

  return blockPositions
}

export interface Props<TBlockData> {
  blocks: TimelineBlock<TBlockData>[]
  children: (blocksInView: TimelineBlock<TBlockData>[]) => ReactNode
  daysDisplayed: number
  disableStops?: boolean
  disableDots?: boolean
  height: number
  saturation?: string
  onBeforeDotClick?: (block: TimelineBlock<TBlockData>) => void
  onAfterDotClick?: (block: TimelineBlock<TBlockData>) => void
  onStartStopClick?: (block: TimelineBlock<TBlockData>) => void
  onEndStopClick?: (block: TimelineBlock<TBlockData>) => void
}

export const SolutionTimeline = <TBlockData,>({
  blocks,
  children,
  daysDisplayed,
  disableStops = false,
  disableDots = false,
  height,
  saturation,
  onBeforeDotClick,
  onAfterDotClick,
  onStartStopClick,
  onEndStopClick,
}: Props<TBlockData>) => {
  const blockPositions = getBlockPositions(blocks, daysDisplayed)
  const { blocksBefore, blocksAfter, blocksInView } = blockPositions

  const firstBlock = blocksInView[0]
  const lastBlock = blocksInView[blocksInView.length - 1]

  const isLeftCutoff = firstBlock && firstBlock.from < 0 && firstBlock.to > 0
  const isRightCutoff =
    lastBlock && lastBlock.from < daysDisplayed && lastBlock.to > daysDisplayed

  const showStartStop =
    !disableStops &&
    !!(blocksInView.length && (blocksBefore.length || isLeftCutoff))
  const showEndStop =
    !disableStops &&
    blocksInView.length &&
    !lastBlock.isInfinite &&
    (blocksAfter.length || isRightCutoff)

  const showInfinity = !!(blocksInView.length && lastBlock.isInfinite)

  // Define some extra height to be passed to the timeline blocks wrapper to allow for any shadow to display correctly
  const shadow = 4 // px

  return (
    <StyledSolutionTimeline $height={height} saturation={saturation}>
      {!disableDots && (
        <TimelineDots side="left" height={height}>
          {showStartStop && (
            <StyledStopMarker // eslint-disable-line jsx-a11y/click-events-have-key-events, jsx-a11y/no-static-element-interactions
              onClick={onStartStopClick}
            />
          )}
          {blocksBefore.map((block) => (
            <Dot key={block.id} block={block} onClick={onBeforeDotClick} />
          ))}
        </TimelineDots>
      )}

      <StyledTimelineBlocks $height={height + shadow}>
        {children(blocksInView)}
      </StyledTimelineBlocks>

      {!disableDots && (
        <TimelineDots side="right" height={height}>
          {blocksAfter.map((block) => (
            <Dot key={block.id} block={block} onClick={onAfterDotClick} />
          ))}
          {!!showEndStop && (
            <StyledStopMarker // eslint-disable-line jsx-a11y/click-events-have-key-events, jsx-a11y/no-static-element-interactions
              onClick={onEndStopClick}
            />
          )}
          {showInfinity && <StyledInfinityMarker>∞</StyledInfinityMarker>}
        </TimelineDots>
      )}
    </StyledSolutionTimeline>
  )
}
