// @flow
import { endpointBuilder } from 'elder/services'

import {
  addRestrictionRequest,
  carerMatchingBasicInfo,
  deleteRestrictionRequest,
  editRestrictionRequest,
  cancelAppliedPlacementRequest,
  getAppliedPlacementsRequest,
  getAppliedPlacementsResponse,
} from 'features/carerMatching/domain'

export const carerMatchingService = {
  addRestriction: endpointBuilder(addRestrictionRequest, carerMatchingBasicInfo)
    .withHttpMethod('POST')
    .withUrlTemplate(
      '/et/professionals/{carerId}/restriction',
      ({ carerId }) => ({
        carerId,
      }),
    )
    .withBodyBuilder(
      ({ description, endDateInclusive, startDateInclusive, reason }) => ({
        description,
        endDateInclusive,
        startDateInclusive,
        reason,
      }),
    )
    .build(),
  deleteRestriction: endpointBuilder(
    deleteRestrictionRequest,
    carerMatchingBasicInfo,
  )
    .withHttpMethod('DELETE')
    .withUrlTemplate(
      '/et/professionals/{carerId}/restriction/{restrictionId}',
      ({ carerId, restrictionId }) => ({
        carerId,
        restrictionId,
      }),
    )
    .build(),
  editRestriction: endpointBuilder(
    editRestrictionRequest,
    carerMatchingBasicInfo,
  )
    .withHttpMethod('PUT')
    .withUrlTemplate(
      '/et/professionals/{carerId}/restriction/{restrictionId}',
      ({ carerId, restrictionId }) => ({
        carerId,
        restrictionId,
      }),
    )
    .withBodyBuilder(
      ({ description, endDateInclusive, startDateInclusive, reason }) => ({
        description,
        endDateInclusive,
        startDateInclusive,
        reason,
      }),
    )
    .build(),
  cancelAppliedPlacement: endpointBuilder(
    cancelAppliedPlacementRequest,
    carerMatchingBasicInfo,
  )
    .withHttpMethod('POST')
    .withUrlTemplate(
      '/et/matching/{matchRequestId}/cancelCarerApplication',
      ({ matchRequestId }) => ({
        matchRequestId,
      }),
    )
    .withBodyBuilder(({ carerId }) => ({
      carerId,
    }))
    .withNoResponseBody()
    .build(),
  getAppliedPlacements: endpointBuilder(
    getAppliedPlacementsRequest,
    getAppliedPlacementsResponse,
  )
    .withUrlTemplate(
      '/et/professionals/{carerId}/appliedPlacements',
      ({ carerId }) => ({ carerId }),
    )
    .build(),
}
