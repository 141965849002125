// @flow
import type { Node } from 'react'
import React from 'react'

import { typography, colors } from '@elder/common'
import styled from 'styled-components'

const Card = styled.div`
  background: ${colors.white};
  border-radius: 2px;
  min-width: 800px;
  padding: 24px 16px;
  &:not(:last-child) {
    margin-bottom: 16px;
  }
`

const Title = styled.div`
  ${typography.h3};
  color: ${colors.blue700};
`

type Props = {|
  +title?: string | Node,
  +children: Node,
  +className?: string,
|}

export const DialogCard = ({ children, title, className }: Props) => (
  <Card className={className}>
    {title && <Title>{title}</Title>}
    {children}
  </Card>
)
