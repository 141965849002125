// @flow
import { addNamespace } from 'utils'

import { SET_START_LOADING, SET_STOP_LOADING } from './actions'

export const makeReducer = (namespace: string, id: string) => {
  const setStartLoading = addNamespace(namespace, id, SET_START_LOADING)
  const setStopLoading = addNamespace(namespace, id, SET_STOP_LOADING)

  return (state: boolean = false, action: Object) => {
    switch (action.type) {
      case setStartLoading:
        return true
      case setStopLoading:
        return false
      default:
        return state
    }
  }
}
