import { colors } from '@elder/common'
import * as muiColors from '@mui/material/colors'
import { chain, omit } from 'lodash'

import type { TimelineBlockColor } from 'components/solutions/SolutionTimeline/SolutionTimeline'

export interface ColorLookup {
  [id: string]: TimelineBlockColor
}

export const getColorLookupBy = (
  placements: {
    id: string
    startDateInclusive?: string
    professionalId?: string
    subscriberId?: string
    type?: 'ACTIVE' | 'SUSPENDED'
  }[],
  by: 'professionalId' | 'subscriberId',
) => {
  const byLookup = chain(placements)
    .sortBy('startDateInclusive')
    .uniqBy(by)
    .reduce((acc, placement, i) => {
      const id = placement[by]
      if (!id) return acc
      return { ...acc, [id]: placementColors[i % placementColors.length] }
    }, {} as ColorLookup)
    .value()

  const placementColorLookup = placements.reduce((acc, placement) => {
    const id = placement[by]
    const noAssigneeColor =
      placement.type === 'SUSPENDED'
        ? placementColorSuspended
        : placementColorNotAssigned
    const color = id ? byLookup[id] : noAssigneeColor
    return { ...acc, [placement.id]: color }
  }, {} as ColorLookup)

  return placementColorLookup
}

export const getSaturation = (status?: string) => {
  switch (status) {
    case 'DRAFT':
      return '50%'
    case 'CANCELLED':
      return '0%'
    default:
      return '100%'
  }
}

export const solutionRateColors: Array<TimelineBlockColor> = [
  {
    default: 'hsl(230,44%,64%)',
    hover: 'hsl(230,44%,74%)',
    selected: 'hsl(230,44%,44%)',
    text: '#FFF',
  },
  {
    default: 'hsl(231,44%,56%)',
    hover: 'hsl(231,44%,66%)',
    selected: 'hsl(231,44%,36%)',
    text: '#FFF',
  },
  {
    default: 'hsl(231,48%,48%)',
    hover: 'hsl(231,48%,58%)',
    selected: 'hsl(231,48%,28%)',
    text: '#FFF',
  },
  {
    default: 'hsl(232,54%,41%)',
    hover: 'hsl(232,54%,51%)',
    selected: 'hsl(232,54%,21%)',
    text: '#FFF',
  },
  {
    default: 'hsl(230,49%,30%)',
    hover: 'hsl(230,49%,40%)',
    selected: 'hsl(230,49%,10%)',
    text: '#FFF',
  },
]

export const getContrastColor = (bgColor: string) => {
  if (typeof bgColor !== 'string')
    throw new Error(`Invalid color value ${bgColor}`)

  const r = parseInt(bgColor.substring(0, 2), 16)
  const g = parseInt(bgColor.substring(2, 4), 16)
  const b = parseInt(bgColor.substring(4, 6), 16)

  const contrast =
    (Math.round(r * 299) + Math.round(g * 587) + Math.round(b * 114)) / 1000

  return contrast >= 128 ? '#000000' : '#FFFFFF'
}

export const placementColors: Array<TimelineBlockColor> = [
  600, 900, 300,
].flatMap((shade) =>
  Object.values(omit(muiColors, ['grey', 'common'])).map((hue) => ({
    default: hue[shade],
    selected: hue[shade],
    text: getContrastColor(hue[shade]),
  })),
)

export const placementColorNotAssigned: TimelineBlockColor = {
  default: '#FFF',
  text: colors.marceline,
  selected: colors.lightSmoke,
}

export const placementColorSuspended: TimelineBlockColor = {
  default: '#a0a0a0',
  text: '#D4D4D4',
  selected: '#808080',
}

export const placementColorUnavailable: TimelineBlockColor = {
  default: '#39434F',
  text: '#FFF',
  selected: '#39434F',
}

export const billingSetupColors: Array<TimelineBlockColor> = [
  {
    default: '#E9EBF1',
    text: colors.marceline,
  },
  {
    default: '#FFC3B6',
    text: colors.marceline,
  },
  {
    default: '#FEDB82',
    text: colors.marceline,
  },
]
