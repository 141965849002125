import React from 'react'

import { SolutionIcon } from '@elder/common'
import type { AccountSolutionOverview as Solution } from '@elder/et-facade-et'
import { EditNote, CancelOutlined } from '@mui/icons-material'
import { Chip, Stack } from '@mui/material'
import moment from 'moment'

import { LocalDates } from 'domain/dates'

const statusSx = {
  CANCELLED: { textDecoration: 'line-through' },
  DRAFT: { fontStyle: 'italic' },
  ACTIVE: {},
}

const statusIcons = {
  CANCELLED: <CancelOutlined />,
  DRAFT: <EditNote />,
  ACTIVE: <SolutionIcon />,
}

const compareSolutionDates = (a: Solution, b: Solution) => {
  if (a.status === 'CANCELLED' && b.status !== 'CANCELLED') return 1
  if (b.status === 'CANCELLED' && a.status !== 'CANCELLED') return -1
  if (a.status === 'DRAFT' && b.status !== 'DRAFT') return 1
  if (b.status === 'DRAFT' && a.status !== 'DRAFT') return -1

  const date = LocalDates.compare(
    LocalDates.ofString(b.startDateInclusive),
    LocalDates.ofString(a.startDateInclusive),
    'days',
  )

  return date
}

interface Props {
  solutions: Solution[]
  solutionId?: string
  setSolutionId: (solutionId: string) => void
}

export const SolutionTabs = ({
  solutions,
  solutionId,
  setSolutionId,
}: Props) => {
  return (
    <Stack direction="row" spacing={1} sx={{ flexWrap: 'wrap' }} useFlexGap>
      {[...solutions]
        .sort((a, b) => compareSolutionDates(a, b))
        .map((solution) => {
          const {
            startDateInclusive,
            endDateExclusive,
            solutionId: id,
            status,
          } = solution
          const start = moment(startDateInclusive).format('ll')
          const end = endDateExclusive
            ? moment(endDateExclusive).format('ll')
            : null

          return (
            <Chip
              label={`${start} - ${end || '∞'}`}
              onClick={() => setSolutionId(id!)}
              icon={statusIcons[status || 'DRAFT']}
              color={status === 'ACTIVE' ? 'primary' : 'default'}
              variant="filled"
              sx={{
                ...statusSx[status || 'DRAFT'],
                boxShadow: ({ shadows }) =>
                  id === solutionId ? shadows[4] : 'none',
                border:
                  id === solutionId
                    ? '2px solid #777'
                    : '2px solid transparent',
              }}
              key={id}
            />
          )
        })}
    </Stack>
  )
}
