// @flow
import { addNamespace } from 'utils'

export const NAMESPACE = 'CARER_DETAILS_HEADER'
export const TIMELINE_START_DATE = 'TIMELINE_START_DATE'
export const IMPERSONATE_CARER = addNamespace(NAMESPACE, 'IMPERSONATE_CARER')
export const SET_CARER_ID = addNamespace(NAMESPACE, 'SET_CARER_ID')
export const GET_CARER_IDENTITY = addNamespace(NAMESPACE, 'GET_CARER_IDENTITY')
export const SET_CARER_IDENTITY = addNamespace(NAMESPACE, 'SET_CARER_IDENTITY')

export const impersonateCarer = (carerId: string) => ({
  type: IMPERSONATE_CARER,
  carerId,
})

export const setCarerId = (id: string) => ({
  type: SET_CARER_ID,
  id,
})

export const getCarerIdentity = (carerId: string) => ({
  type: GET_CARER_IDENTITY,
  carerId,
})

export const setCarerIdentity = (identity: Object) => ({
  type: SET_CARER_IDENTITY,
  identity,
})
