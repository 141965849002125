/* eslint-disable react/forbid-prop-types, react/prop-types */
// $FlowOptOut
import React, { useEffect, useState } from 'react'

import RPT from 'prop-types'
import styled, { css } from 'styled-components'

import { ChangeSummary, GenericButton } from 'components'
import { DeleteChangeHistoryConfirmation } from 'components/ChangeSummary/DeleteChangeHistoryConfirmation'
import { RelatedAccountsContainer } from 'features/relatedAccounts/RelatedAccountsContainer'
import OverviewForm from 'routes/profiles/shared/OverviewForm'

const Wrapper = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;

  ${({ editMode }) =>
    editMode &&
    css`
      .profile__section:not(.profile__section--in-edit) {
        opacity: 0.1;
        pointer-events: none;
      }
    `}

  .profile__section {
    flex: 1 0 100%;
    margin-bottom: 30px;

    h3 {
      text-transform: capitalize;

      span {
        color: #73819c;
        font-size: 16px;
      }
    }
    @media only screen and (min-width: 1051px) {
      flex: 0.4 0 40%;
    }
  }

  .profile__header {
    align-items: center;
    border-bottom: 2px solid #19305e;
    display: flex;
    justify-content: space-between;
    margin: 20px 0 10px;
    padding-bottom: 10px;
  }

  .profile__entry {
    align-items: center;
    border-bottom: 1px solid #eaf0fc;
    display: flex;
    line-height: 32px;
    padding: 10px 0;
    white-space: pre-wrap;

    > span,
    > label,
    > div {
      width: 50%;
    }

    span,
    label,
    strong {
      color: #73819c;
    }

    input[type='text'],
    input[type='email'],
    input[type='date'],
    textarea {
      width: 100%;
    }
  }

  .profile__entry__small-edit {
    align-items: center;
    display: flex;
    justify-content: space-between;

    & > div {
      margin-right: 10px;

      &:last-child {
        margin-right: 0;
      }
    }
  }

  .profile__table {
    border-collapse: collapse;
    width: 100%;

    .profile__head {
      tr > th {
        border-bottom: 1px solid #19305e;
        font-size: 16px;
        padding: 10px;
        text-align: center;
        text-transform: capitalize;
      }
    }

    .profile__body {
      tr > td {
        border-bottom: 1px solid #eaf0fc;
        padding: 10px;
        text-align: center;
      }
    }
  }
`

const Overview = (
  profile,
  canEdit,
  updateStatus,
  editSection,
  sendPasswordReset,
) => {
  let EditStatusButtons = null
  if (canEdit) {
    switch (profile.status) {
      case 'ACTIVE':
        EditStatusButtons = (
          <div className="profile__entry__small-edit">
            <GenericButton
              text="Archive"
              onClick={() => {
                updateStatus({ status: 'ARCHIVED' })
              }}
              loud
            />
          </div>
        )
        break
      case 'ARCHIVED':
        EditStatusButtons = (
          <div className="profile__entry__small-edit">
            <GenericButton
              text="Reopen"
              onClick={() => {
                updateStatus({ status: 'ACTIVE' })
              }}
            />
          </div>
        )
        break
      default:
        EditStatusButtons = null
    }
  }

  return (
    <div className="profile__section">
      <div className="profile__header">
        <h3>Overview</h3>
        {canEdit && (
          <GenericButton text="Edit" onClick={() => editSection('overview')} />
        )}
      </div>
      <div className="profile__entry">
        <span>Status:</span>
        <div className="profile__entry__small-edit">
          <div>{profile.status}</div>
          {EditStatusButtons}
        </div>
      </div>
      <div className="profile__entry">
        <span>Name:</span>
        <div>{profile.fullName}</div>
      </div>
      <div className="profile__entry">
        <span>Company:</span>
        <div>{profile.company}</div>
      </div>
      <div className="profile__entry">
        <span>Email:</span>
        <div>{profile.email}</div>
      </div>
      <div className="profile__entry">
        <span>Address:</span>
        <div>
          {profile.prettyAddress.map((value, index) => (
            // eslint-disable-next-line react/no-array-index-key
            <div key={index.toString()}>{value}</div>
          ))}
        </div>
      </div>
      <div className="profile__entry">
        <span>Phone Numbers:</span>
        <div>
          {profile.prettyPhoneNumbers.map((phoneNumber, index) => (
            // eslint-disable-next-line react/no-array-index-key
            <div key={index.toString()}>{phoneNumber}</div>
          ))}
        </div>
      </div>
      <div className="profile__entry">
        <span>Password Reset:</span>
        <div className="profile__entry__small-edit">
          <div>{profile.resetPasswordEmailStatus}</div>
          <GenericButton
            text="Send password email"
            disabled={!!profile.resetPasswordEmailStatus}
            isLoading={profile.resetPasswordEmailStatus === 'REQUESTING'}
            onClick={() => {
              sendPasswordReset(profile.email)
            }}
          />
        </div>
      </div>
    </div>
  )
}

const Accounts = (displayContactRole) => {
  const [expandedRelatedAccountIndex, setExpandedRelatedAccountIndex] =
    useState(-1)
  return (
    <div className="profile__section">
      <div className="profile__header">
        <h3>Related Accounts</h3>
      </div>
      <RelatedAccountsContainer
        expandedRelatedAccountIndex={expandedRelatedAccountIndex}
        setExpandedRelatedAccountIndex={setExpandedRelatedAccountIndex}
        displayContactRole={displayContactRole}
      />
    </div>
  )
}

export const Summary = ({
  profile,
  canEdit,
  sectionEditMode,
  displayContactRole,
  validationErrors,
  updateStatus,
  editSection,
  cancelEdit,
  changelogUrl,
  updateOverview,
  deleteChangeHistory,
  canClearAccountHistory,
  sendPasswordReset,
  getData,
  setId,
  match: {
    params: { profileId },
  },
}) => {
  useEffect(() => {
    setId(profileId)
    getData()
  }, [])

  const isEditModeOn = sectionEditMode.length > 0
  const OverviewToDisplay =
    sectionEditMode === 'overview' ? (
      <OverviewForm
        profile={profile}
        submit={updateOverview}
        cancel={cancelEdit}
        validationErrors={validationErrors.overview}
      />
    ) : (
      Overview(profile, canEdit, updateStatus, editSection, sendPasswordReset)
    )

  let ChangeSummaryToDisplay = null
  if (sectionEditMode === 'deleteHistory') {
    ChangeSummaryToDisplay = (
      <DeleteChangeHistoryConfirmation
        cancelEdit={cancelEdit}
        deleteChangeHistory={deleteChangeHistory}
      />
    )
  } else {
    ChangeSummaryToDisplay = (
      <ChangeSummary
        isEditModeOn={isEditModeOn}
        lastChangedBy={profile.changelog.lastChangedBy}
        lastChangedAt={profile.changelog.lastChangedAt}
        lastChangeDescription={profile.changelog.lastChangeDescription}
        changelogUrl={changelogUrl}
        showClearAccountHistory={canClearAccountHistory}
        canClearAccountHistory={profile.status === 'ARCHIVED'}
        deleteAccountHistory={() => editSection('deleteHistory')}
      />
    )
  }

  return (
    <Wrapper editMode={isEditModeOn}>
      {OverviewToDisplay}
      {Accounts(displayContactRole)}
      {ChangeSummaryToDisplay}
    </Wrapper>
  )
}

Summary.propTypes = {
  profile: RPT.shape({
    status: RPT.string,
    fullName: RPT.string,
    company: RPT.string,
    email: RPT.string,
    prettyPhoneNumbers: RPT.arrayOf(RPT.string),
    prettyAddress: RPT.arrayOf(RPT.string),
    address: RPT.shape({
      address1: RPT.string,
      address2: RPT.string,
      postcode: RPT.string,
      city: RPT.string,
    }).isRequired,
    accounts: RPT.arrayOf(
      RPT.shape({
        customerId: RPT.string,
        relationship: RPT.string,
        roles: RPT.arrayOf(RPT.string),
        communicationPreferences: RPT.shape({ BILLING: RPT.string }),
      }).isRequired,
    ),
    changelogSummary: RPT.shape({
      lastChangedBy: RPT.string,
      lastChangedAt: RPT.string,
      lastChangeDescription: RPT.string,
    }),
  }).isRequired,
  canEdit: RPT.bool.isRequired,
  sectionEditMode: RPT.string,
  updateStatus: RPT.func.isRequired,
  editSection: RPT.func.isRequired,
  cancelEdit: RPT.func.isRequired,
  validationErrors: RPT.shape({
    overview: RPT.shape({
      firstName: RPT.array,
      lastName: RPT.array,
      companyName: RPT.array,
      emailAddress: RPT.array,
      phoneNumbers: RPT.array,
      address1: RPT.array,
      address2: RPT.array,
      city: RPT.array,
      postcode: RPT.array,
    }).isRequired,
  }).isRequired,
  changelogUrl: RPT.string.isRequired,
  updateOverview: RPT.func.isRequired,
  deleteChangeHistory: RPT.func.isRequired,
  canClearAccountHistory: RPT.bool.isRequired,
  sendPasswordReset: RPT.func.isRequired,
  getData: RPT.func.isRequired,
  setId: RPT.func.isRequired,
  match: RPT.object.isRequired,
  displayContactRole: RPT.func.isRequired,
}

export default Summary
