// @flow
export const expectedBasicAcctInfo = {
  expectedStartDate: true,
  careType: true,
  agreedRateOfCarePerWeek: true,
  rateDeviationReason: true,
  standardHoursDeviationDescription: true,
}

export const expectedBillingCycle = {
  expectedStartDate: true,
}

export const expectedUpdateStatus = {
  status: true,
  archiveDate: true,
  churnReason: true,
  churnDescription: true,
}
