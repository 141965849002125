import React from 'react'

import { Form } from 'formsy-react-components'
import RPT from 'prop-types'

import { ButtonSubmit, FormsySelect, GenericButton } from 'components'
import { forms } from 'messages/forms'
import {
  billingCycleOptions,
  billingDayOfMonthOptions,
} from 'utils/selectOptions'

// eslint-disable-next-line no-restricted-properties
class BillingCycleForm extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      billingCycle: props.billingCycle,
    }
  }

  UNSAFE_componentWillMount() {
    this.setState({
      canBCSubmit: true,
    })
  }

  UNSAFE_componentWillReceiveProps(props) {
    this.form.formsyForm.updateInputsWithError(props.validationErrors)
  }

  setBCValid = () => this.setState({ canBCSubmit: true })

  setBCInvalid = () => this.setState({ canBCSubmit: false })

  submitBCForm = (data) => {
    if (this.state.canBCSubmit) {
      this.props.updateBillingCycle(data)
    }
  }

  setBillingCycle = (event) => {
    this.setState({
      billingCycle: event.value,
    })
  }

  render() {
    return (
      <div>
        <Form
          ref={(form) => {
            this.form = form
          }}
          onSubmit={this.submitBCForm}
          onValid={this.setBCValid}
          onInvalid={this.setBCInvalid}
          validatePristine
        >
          <div>
            <h3>Billing Cycle (edit mode)</h3>
          </div>
          <FormsySelect
            name="type"
            label="Billing Cycle:"
            value={this.state.billingCycle}
            options={billingCycleOptions}
            onChange={this.setBillingCycle}
            clearable={false}
            validationErrors={{
              isDefaultRequiredValue: forms.validation.isRequired,
            }}
            required
          />
          {this.state.billingCycle === 'MONTHLY' && (
            <FormsySelect
              name="dayOfMonth"
              label="Day Of Month (Direct Debit Only):"
              value={this.props.billingDayOfMonth.toString()}
              options={billingDayOfMonthOptions}
              clearable={false}
              validationErrors={{
                isDefaultRequiredValue: forms.validation.isRequired,
              }}
              required
            />
          )}
          <div className="acct__form-buttons">
            <GenericButton
              text="Cancel"
              onClick={this.props.cancelEdit}
              quiet
            />
            <ButtonSubmit text="Save" disabled={!this.state.canBCSubmit} />
          </div>
        </Form>
      </div>
    )
  }
}

BillingCycleForm.propTypes = {
  billingCycle: RPT.string.isRequired,
  billingDayOfMonth: RPT.number,
  updateBillingCycle: RPT.func.isRequired,
  cancelEdit: RPT.func.isRequired,
  validationErrors: RPT.object.isRequired, // eslint-disable-line react/forbid-prop-types
}

export default BillingCycleForm
