// @flow

import * as Yup from 'yup'

import type { Template } from 'features/notes/domain'
import { createTagOptions, createAdapter } from 'features/notes/helpers'

const matchingRequirementsSections = [
  {
    title: 'Matching Requirements',
    fields: [
      {
        type: 'TEXT_AREA',
        name: 'matchingUpdates',
        label: 'Matching updates',
        info: 'e.g. SS 22/01 - Customer is unavailable until 23rd so please reserve carers only until then',
        validation: Yup.string().required('Required'),
      },
      {
        type: 'TEXT_AREA',
        name: 'specificSkills',
        label: 'Does the carer need any specific skills or experience?',
        info: 'e.g. Must have three years verified dementia experience',
        validation: Yup.string().required('Required'),
      },
      {
        type: 'TEXT_AREA',
        name: 'languageRequirement',
        label: 'How important are the language requirements?',
        info: "e.g. It's essential to be a 1:1. The CR is hard of hearing so they need someone who is a native speaker.",
        validation: Yup.string().required('Required'),
      },
      {
        type: 'TEXT_AREA',
        name: 'personality',
        label: 'What personality is likely to bond with the CR?',
        info: 'e.g. Would prefer an introvert.',
        validation: Yup.string().required('Required'),
      },

      {
        type: 'TEXT_AREA',
        name: 'flexibility',
        label: 'How flexible is the gender requirement?',
        info: "e.g. They would prefer a female, but they wouldn't mind a male as a last resort.",
        validation: Yup.string().required('Required'),
      },
      {
        type: 'TEXT_AREA',
        name: 'language',
        label:
          'If the carer needs to speak a language other than English, how flexible is this requirement?',
        info: 'e.g. It is essential because the CR can only speak Spanish.',
        validation: Yup.string(),
      },
      {
        type: 'TEXT_AREA',
        name: 'interests',
        label: 'Does the CR have any particular interests or hobbies?',
        info: 'e.g. The CR loves to watch tennis on TV.',
        validation: Yup.string().required('Required'),
      },
      {
        type: 'TEXT_AREA',
        name: 'dietaryRequirements',
        label:
          'Does the carer need to have any dietary requirements or know how to cook certain foods?',
        info: 'e.g. The carer must be vegan and know how to cook seitan!',
        validation: Yup.string(),
      },
      {
        type: 'TEXT_AREA',
        name: 'driving',
        label: 'If applicable, how flexible is the driving requirement?',
        info: "e.g. It's a nice to have but not essential!",
        validation: Yup.string(),
      },
      {
        type: 'TEXT_AREA',
        name: 'livingArrangments',
        label:
          'Do others live at the property? If yes, please provide details.',
        info: 'e.g. Son and wife live there too.',
        validation: Yup.string().required('Required'),
      },
      {
        type: 'TEXT_AREA',
        name: 'hardRequirements',
        label: 'Any other hard requirements or flags to look out for?',
        info: 'e.g. Needs to be someone local.',
        validation: Yup.string(),
      },
      {
        type: 'TEXT_AREA',
        name: 'carerSellingPoints',
        label: 'Are there any quirky carer selling points?',
        info: 'e.g. A big house with large garden and ocean views! ',
        validation: Yup.string(),
      },
      {
        type: 'TEXT_AREA',
        name: 'questionsToAskCarer',
        label: 'Questions to ask the carer before presenting?',
        info: 'e.g. Would the carer be happy to feed the pigeons? ',
        validation: Yup.string(),
      },
      {
        type: 'TEXT_AREA',
        name: 'partnershipsAccount',
        label:
          "Is this a partnerships account? If yes, please can you confirm how many carers are required? And, will Elder be signing off the carer/s on the LA's behalf?",
        validation: Yup.string().required('Required'),
      },
      {
        type: 'SELECT',
        name: 'tags',
        label: 'Tags',
        multi: true,
        options: createTagOptions(
          'None',
          'COVID-19',
          'Not spoken to CR',
          'CR not keen on live-in',
          'Not spoken to all KDMs',
          'Not all KDMs keen on live-in',
          'Not spoken to discharge nurse',
          'Discharge date not set',
          'Not spoken to social worker',
          'No clinical sign-off',
          'High needs - considering nursing care',
          'Low needs - considering hourly',
          'CQC Concerns',
          'Not committed to Elder, just browsing',
          'Competitors involved',
          'Funding not confirmed',
          'Environment uncertainty',
          'Specific language request',
        ),
        initialValue: [],
      },
    ],
  },
]

const matchingRequirementsTitle = 'Matching Requirements'

export const matchingRequirements: Template = {
  value: 'MATCHING_REQUIREMENTS',
  label: matchingRequirementsTitle,
  adapter: ({ tags, ...textValues }) => ({
    title: matchingRequirementsTitle,
    tags,
    text: createAdapter(matchingRequirementsSections, textValues),
  }),
  sections: matchingRequirementsSections,
}
