// @flow
import { NAMESPACE as ACCOUNT_NAMESPACE } from 'routes/account/actions'
import addNamespace from 'utils/addNamespace'

import ContactsContainer from './ContactsContainer'
import reducer from './reducers'
import saga from './sagas'

const createOverviewUrl = (accountId: string) =>
  `/et/accounts/${accountId}/accountContacts`

export const SECTION_ID = 'contacts'
export const NAMESPACE = addNamespace(
  ACCOUNT_NAMESPACE,
  SECTION_ID.toUpperCase(),
)
export const KNOWN_FIELDS = { contacts: true }

export const params = {
  getUrlFactory: createOverviewUrl,
  updateUrlFactory: createOverviewUrl,
  updateConfirmationTitle: 'Account updated',
  updateConfirmationText: 'List of contacts was updated successfully.',
  validationField: 'contacts',
  expectedValidationFields: KNOWN_FIELDS,
}

export { ContactsContainer, saga, reducer }
