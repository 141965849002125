// @flow
import type {
  CommunicationPreferencesType,
  RelatedAccountData,
} from 'features/relatedAccounts/types'
import { addNamespace } from 'utils'

export const NAMESPACE = 'RELATED_ACCOUNTS'

export const UPDATE_PROFILE_COMMUNICATION_PREFERENCES = addNamespace(
  NAMESPACE,
  'UPDATE_PROFILE_COMMUNICATION_PREFERENCES',
)

export const SET_PROFILE_COMMUNICATION_PREFERENCES = addNamespace(
  NAMESPACE,
  'SET_PROFILE_COMMUNICATION_PREFERENCES',
)

export const updateProfileCommunicationPreferences = ({
  profileId,
  accountId,
  communicationPreferences,
}: {
  +profileId: string,
  +accountId: string,
  +communicationPreferences: CommunicationPreferencesType,
}) => ({
  type: UPDATE_PROFILE_COMMUNICATION_PREFERENCES,
  profileId,
  accountId,
  communicationPreferences,
})

export const setProfileCommunicationPreferences = (
  relatedAccounts: RelatedAccountData,
) => ({
  type: SET_PROFILE_COMMUNICATION_PREFERENCES,
  relatedAccounts,
})
