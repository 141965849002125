// @flow
import type { Saga } from 'redux-saga'
import { take, call, select, put } from 'redux-saga/effects'

import {
  applicationError,
  applicationSuccess,
} from 'features/snackbar/snackbar'
import { pageSelectors } from 'routes/account/selectors'
import {
  UPDATE_ARCHIVE_DETAILS,
  pageActions,
} from 'routes/account/summary/actions'
import { fetchFlow, describeServiceError } from 'utils/services'

export function* updateArchiveDetails(details: Object): Generator<*, void, *> {
  const acctId = yield select(pageSelectors.getId)
  const endpoint = `/et/accounts/${acctId}/archive/update`
  const method = 'PUT'

  try {
    const responseBody = yield call(fetchFlow, endpoint, method, details)
    yield put(pageActions.set(responseBody))
    applicationSuccess({
      title: 'Account updated',
      message: 'Archive details were successfully updated.',
    })
  } catch (error) {
    applicationError(
      describeServiceError(error, `Error with service call ${endpoint}`),
    )
  }
}

export function* watchUpdateArchiveDetails(): Saga<*> {
  while (true) {
    const { details } = yield take(UPDATE_ARCHIVE_DETAILS)
    yield call(updateArchiveDetails, details)
  }
}
