// @flow

import * as Yup from 'yup'

import type { Template } from 'features/notes/domain'
import { createTagOptions, createAdapter } from 'features/notes/helpers'

const initialSalesCallSections = [
  {
    title: 'Initial Sales Call',
    fields: [
      {
        type: 'TEXT_AREA',
        name: 'text',
        label: 'Description',
        validation: Yup.string()
          .required('Required')
          .test(
            'text-len',
            'Less than 8191 characters please',
            (val = '') => val.length < 8191,
          ),
      },
      {
        type: 'SELECT',
        name: 'tags',
        label: 'Tags',
        multi: true,
        options: createTagOptions('Initial call', 'R2L', 'COVID-19'),
        initialValue: ['Initial call', 'R2L'],
      },
    ],
  },
]

const caCallSections = [
  {
    title: 'Planning: Overview',
    fields: [
      {
        type: 'DATE',
        name: 'expectedStartDate',
        label: 'Expected start date',
        validation: Yup.date(),
      },
      {
        type: 'TEXT',
        name: 'priceTier',
        label: 'Price tier',
        validation: Yup.string(),
      },
      {
        type: 'TEXT',
        name: 'typeOfCare',
        label: 'Type of care',
        validation: Yup.string(),
      },
      {
        type: 'TEXT_AREA',
        name: 'improvementToQualityOfLifeDescription',
        label: "How will care improve the recipients's quality of life?",
        validation: Yup.string(),
      },
      {
        type: 'TEXT_AREA',
        name: 'keyObjectionToAddress',
        label: 'Key objections to address from the initial call',
        validation: Yup.string(),
      },
      {
        type: 'TEXT_AREA',
        name: 'opinionsOfElder',
        label: 'What are their opinions of Elder?',
        validation: Yup.string(),
      },
    ],
  },
  {
    title: 'Questioning: Essential',
    info: 'These questions should be addressed in every CA call',
    fields: [
      {
        type: 'TEXT_AREA',
        name: 'recipientFeelings',
        label: 'How does the recipient feel?',
        validation: Yup.string(),
      },
      {
        type: 'TEXT',
        name: 'directlySpeakToCR',
        label: 'Can you speak to the recipient directly?',
        validation: Yup.string(),
      },
      {
        type: 'TEXT',
        name: 'familyMembersInvolved',
        label: 'Which other family members are involved?',
        validation: Yup.string(),
      },
      {
        type: 'TEXT',
        name: 'officialSecondContact',
        label:
          'Who is going to be the official second contact for this account?',
        validation: Yup.string(),
      },
      {
        type: 'TEXT_AREA',
        name: 'previousCarersSuccessDescription',
        label: 'How have previous carers succeeded with this recipient?',
        validation: Yup.string(),
      },
      {
        type: 'TEXT_AREA',
        name: 'cRLocation',
        label: 'Where is the recipient now?',
        validation: Yup.string(),
      },
      {
        type: 'TEXT_AREA',
        name: 'thirdPartiesInvolvement',
        label: 'Any involvement from NHS/council? Have we spoken to them?',
        validation: Yup.string(),
      },
      {
        type: 'TEXT_AREA',
        name: 'customerFeelings',
        label: 'How does the customer feel?',
        validation: Yup.string(),
      },
    ],
  },
  {
    title: 'Questioning: Specific',
    info: 'These questions should be based on points from the CA that require clarification',
    fields: [
      {
        type: 'TEXT_AREA',
        name: 'caClarification',
        label:
          'What additional questions were asked during the call? What were the answers?',
        validation: Yup.string(),
      },
    ],
  },
  {
    title: 'Ensuring long term success',
    info: 'The following topics should be covered to ensure the long term success of the account',
    fields: [
      {
        type: 'TEXT_AREA',
        name: 'carerBreaks',
        label: 'Carer breaks',
        validation: Yup.string(),
      },
      {
        type: 'TEXT_AREA',
        name: 'mobility',
        label: 'Mobility',
        validation: Yup.string(),
      },
      {
        type: 'TEXT_AREA',
        name: 'nursingCare',
        label: 'Nursing care (Wound dressing, PEG, etc)',
        validation: Yup.string(),
      },
      {
        type: 'TEXT_AREA',
        name: 'nightTime',
        label: 'Night time',
        validation: Yup.string(),
      },
      {
        type: 'TEXT_AREA',
        name: 'challengingBehaviour',
        label: 'Challenging behaviour',
        validation: Yup.string(),
      },
    ],
  },
  {
    title: 'Next Steps',
    info: 'Please provide details on the recommended next steps for the account',
    fields: [
      {
        type: 'TEXT_AREA',
        name: 'matchingNotes',
        label: 'Matching notes',
        validation: Yup.string(),
      },
      {
        type: 'TEXT_AREA',
        name: 'coolOffPeriod',
        label: 'What type of cool off period is required?',
        validation: Yup.string(),
      },
      {
        type: 'TEXT_AREA',
        name: 'nextSteps',
        label: 'What are the next steps for the account?',
        validation: Yup.string(),
      },
      {
        type: 'TEXT_AREA',
        name: 'solutionReady',
        label: 'Is this account ready to have a solution?',
        validation: Yup.string(),
      },
      {
        type: 'SELECT',
        name: 'tags',
        label: 'Tags',
        multi: true,
        options: createTagOptions('Care needs', 'R2L', 'COVID-19'),
        initialValue: ['Care needs', 'R2L'],
      },
    ],
  },
]

const initialSalesCallTitle = 'Initial Sales Call'

export const initialSalesCall: Template = {
  value: 'INITIAL_SALES_CALL',
  label: initialSalesCallTitle,
  adapter: ({ tags, ...textValues }) => ({
    title: initialSalesCallTitle,
    tags,
    text: createAdapter(initialSalesCallSections, textValues),
  }),
  sections: initialSalesCallSections,
}

const caCallTitle = 'CA Call'

export const caCall: Template = {
  value: 'CA_CALL',
  label: caCallTitle,
  adapter: ({ tags, ...textValues }) => ({
    title: caCallTitle,
    tags,
    text: createAdapter(caCallSections, textValues),
  }),
  sections: caCallSections,
}
