// @flow
import { combineReducers } from 'redux'

import {
  SET_MEDICINES,
  GET_MEDICINES,
  GET_MEDICINES_ERROR,
} from 'features/recipient/medication/store/actions'
import { ElderServiceError } from 'utils/services'

const medicines = (state = [], action: Object) => {
  switch (action.type) {
    case SET_MEDICINES:
      return action.medicines
    default:
      return state
  }
}

const isLoading = (state: boolean = false, action: Object) => {
  switch (action.type) {
    case GET_MEDICINES:
      return true
    case SET_MEDICINES:
      return false
    default:
      return state
  }
}

const errors = (state: ?ElderServiceError = null, action: Object) => {
  switch (action.type) {
    case GET_MEDICINES_ERROR:
      return action.error
    case GET_MEDICINES:
    case SET_MEDICINES:
      return null
    default:
      return state
  }
}

export const reducer = combineReducers({ medicines, isLoading, errors })
