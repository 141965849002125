import moment from 'moment'
import { delay } from 'redux-saga'
import { put, call, spawn } from 'redux-saga/effects'

import { LocalDates } from 'domain/dates'
import { setCurrentDate } from 'store/currentDate/actions'

export function* keepCurrentDateAccurate() {
  const MILLISECONDS_TILL_NEXT_DAY = moment()
    .add(1, 'days')
    .startOf('day')
    .diff(moment())

  yield put(setCurrentDate(LocalDates.currentDate()))
  yield call(delay, MILLISECONDS_TILL_NEXT_DAY)
  yield spawn(keepCurrentDateAccurate)
}
