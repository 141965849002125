// @flow
import type { Saga } from 'redux-saga'

import { takeLatest, put, call } from 'elder/effects'

import { elderUsersClient } from 'app/saga/serviceClients'
import {
  getElderUsers,
  updateUserStatus,
  updateMaxLoad,
  setElderUsers,
  getElderUsersError,
  updateUserStatusError,
  NAMESPACE,
} from 'features/elderUsers/store/actions'
import { clearLoading, setLoading } from 'features/loading/actions'
import {
  applicationError,
  applicationSuccess,
} from 'features/snackbar/snackbar'
import { describeServiceError } from 'utils/services'

function* handleGetElderUsers(): Saga<*> {
  yield* put(setLoading(NAMESPACE))
  try {
    const response = yield* call(elderUsersClient.getElderUsers)
    yield* put(setElderUsers(response))
  } catch (error) {
    yield* put(getElderUsersError())
    applicationError(describeServiceError(error, 'Unable to get elder users'))
  } finally {
    yield* put(clearLoading(NAMESPACE))
  }
}

function* handleUpdateUserStatus({ username, status }): Saga<*> {
  yield* put(setLoading(NAMESPACE))
  try {
    yield* call(elderUsersClient.updateStatus, { username, status })
    applicationSuccess({
      title: 'Elder status saved',
      message: `${username}'s status sucessfully updated to ${status}`,
    })
    const response = yield* call(elderUsersClient.getElderUsers)
    yield* put(setElderUsers(response))
  } catch (error) {
    yield* put(updateUserStatusError())
    applicationError(
      describeServiceError(
        error,
        `Unable to update status for user ${username}`,
      ),
    )
  } finally {
    yield* put(clearLoading(NAMESPACE))
  }
}

function* handleUpdateMaxLoad({ username, maxLoad }): Saga<*> {
  yield* put(setLoading(NAMESPACE))
  try {
    yield* call(elderUsersClient.updateMaxLoad, { username, maxLoad })
    applicationSuccess({
      title: 'Elder status saved',
      message: `${username}'s max load sucessfully updated to ${maxLoad}`,
    })
    const response = yield* call(elderUsersClient.getElderUsers)
    yield* put(setElderUsers(response))
  } catch (error) {
    yield* put(updateUserStatusError())
    applicationError(
      describeServiceError(
        error,
        `Unable to update max load for user ${username}`,
      ),
    )
  } finally {
    yield* put(clearLoading(NAMESPACE))
  }
}

export function* elderUsersSagas(): Saga<*> {
  yield* takeLatest(getElderUsers, handleGetElderUsers)
  yield* takeLatest(updateUserStatus, handleUpdateUserStatus)
  yield* takeLatest(updateMaxLoad, handleUpdateMaxLoad)
}
