// @flow
// $FlowOptOut
import React, { useEffect } from 'react'

import { connect } from 'react-redux'

import { Changelogs } from 'components'
import { pageActions as wrapperPageActions } from 'routes/profile/profileWrapper/actions'

import { changelogActions } from './actions'
import { changelogSelectors } from './selectors'

const mapActionCreators = {
  getChangelog: () => changelogActions.getChangelog(),
  setId: (profileId) => wrapperPageActions.setId(profileId),
}

const mapStateToProps = (state) => ({
  changelogs: changelogSelectors.getChangelogs(state),
})

export default connect(
  mapStateToProps,
  mapActionCreators,
)(({
  changelogs,
  getChangelog,
  setId,
  match: {
    params: { profileId },
  },
}) => {
  useEffect(() => {
    setId(profileId)
    getChangelog()
  }, [])

  return <Changelogs changelogs={changelogs} />
})
