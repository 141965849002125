// @flow
import { addNamespace } from 'utils'

export const TOGGLE_FILTER = 'TOGGLE_FILTER'
export const SET_FILTER = 'SET_FILTER'
export const SET_COMPLEX_FILTER = 'SET_COMPLEX_FILTER'

export const makeActions = (namespace: string) => ({
  toggleFilter: (id: string) => ({
    type: addNamespace(namespace, TOGGLE_FILTER),
    id,
  }),

  setFilter: (id: string, value: Object) => ({
    type: addNamespace(namespace, SET_FILTER),
    id,
    value,
  }),

  setComplexFilter: (id: string, objectOrArray: Object) => ({
    type: addNamespace(namespace, SET_COMPLEX_FILTER),
    id,
    objectOrArray,
  }),
})

export default makeActions
