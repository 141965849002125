// @flow
export const forms = {
  validation: {
    isEmail: 'This doesn’t look like a valid email address',
    maxLength: 'Too many characters',
    minLength: 'Not enough characters',
    isNumeric: 'Only numbers allowed',
    isRequired: 'This field is required',
    isPrice: 'Needs to end with .00 or .50',
    isPostCode: 'This does not look like a valid postcode',
    isDate: 'The date is not well formatted',
    isValidUkDate: 'That does not look like a valid date (format DD/YY/MMMM)',
    isAfterOtherDate: 'Date must be later than the start/from date',
    isBeforeOtherDate: 'Date must be earlier than the end/to date',
    isTodayOrFuture: 'You can not select a day in the past',
    isYesterdayOrFuture: 'You can not select a day past yesterday',
    minLengthCheckboxGroup: 'At least one entry must be checked',
    isURL: 'This does not look like a valid URL',
    isNotNoEmail:
      "Don't use dummy emails - Emails are not required for secondary contacts",
  },
}

export const isNotInRange = (val: number) =>
  `The current value ${val} is not a valid value from the range`
export const isTooMuch = (max: number) => `Cannot be above ${max}`
export const isNotEnough = (min: number) => `Cannot be under ${min}`
