// @flow
import styled from 'styled-components'

export const FormButtons = styled.div`
  display: flex;
  justify-content: flex-end;
  margin-top: 20px;

  & > div:not(:last-child) {
    margin-right: 15px;
  }
`
