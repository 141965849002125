// @flow
import type { Node } from 'react'
import React from 'react'

import { CircularProgress } from '@mui/material'
import styled, { css } from 'styled-components'

import type { EnumMap } from 'elder/types'

const mixin = css`
  margin-bottom: 30px;
  h3 {
    text-transform: capitalize;
    span {
      color: #73819c;
      font-size: 16px;
    }
  }
  margin-bottom: 30px;
  h3 {
    text-transform: capitalize;
    span {
      color: #73819c;
      font-size: 16px;
    }
  }
  margin-bottom: 30px;
  h3 {
    text-transform: capitalize;
    span {
      color: #73819c;
      font-size: 16px;
    }
  }
`

const StyledSummaryCard = styled.div`
  .summaryCard {
    ${mixin}
    flex: 1 0 100%;
    @media only screen and (min-width: 1051px) {
      flex: 0.4 0 40%;
    }
  }

  .summaryCardFull {
    ${mixin}
    flex: 1 0 100%;
  }

  .summaryCardHalf {
    ${mixin}
    flex: 0 0 50%;
  }

  .deprecated {
    text-decoration: line-through;
  }

  .defocused {
    opacity: 0.1;
    pointer-events: none;
  }

  .error {
    color: red;
    text-align: center;
  }

  .loaderWrapper {
    opacity: 1;
  }

  .header {
    align-items: center;
    border-bottom: 2px solid #19305e;
    display: flex;
    justify-content: space-between;
    margin: 20px 0 10px;
    padding-bottom: 10px;
  }
`

const getBodyStyle = (defocused) => {
  if (defocused) {
    return 'defocused'
  }
  return null
}

const getActionsStyle = (loading, failed) => {
  if (loading || failed) {
    return 'defocused'
  }
  return null
}

export type WidthEnum = 'FULL' | 'HALF' | 'DEFAULT'

const widthClasses: EnumMap<WidthEnum, string> = {
  FULL: 'summaryCardFull',
  HALF: 'summaryCardHalf',
  DEFAULT: 'summaryCard',
}

type Props = {|
  +actionChildren?: Node,
  +children?: Node,
  +heading: string,
  +deprecated?: boolean,
  +width?: WidthEnum,
  +defocused?: boolean,
  +loading?: boolean,
  +failed?: boolean,
|}

/**
 * A summary card is an individual summary section on an overview page such as the
 * account or profile overview. Summary cards are aware of the edit state of the overview
 * page as a whole and can be either small (default) or full width.
 */
export const SummaryCard = ({
  actionChildren,
  children,
  heading,
  deprecated = false,
  width,
  defocused = false,
  loading = false,
  failed = false,
}: Props) => {
  const headerStyle = deprecated ? 'deprecated' : ''

  return (
    <StyledSummaryCard className={widthClasses[width || 'DEFAULT']}>
      <div className={getBodyStyle(defocused)}>
        <div className="header">
          <h3 className={headerStyle}>{heading}</h3>
          <div className={getActionsStyle(loading, failed)}>
            {actionChildren || null}
          </div>
        </div>
        {failed || loading ? null : <div> {children || null} </div>}
        {loading && (
          <div>
            {' '}
            <CircularProgress />{' '}
          </div>
        )}
        {failed && <div className="error">Failed to get data</div>}
      </div>
    </StyledSummaryCard>
  )
}

export default SummaryCard
