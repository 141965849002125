// @flow
import { INIT, LOGIN, APPLICATION_READY } from 'app/actions'

export const APP_STATES = {
  INIT: 'INIT',
  LOGIN: 'LOGIN',
  APPLICATION_READY: 'APPLICATION_READY',
}

const initialState = APP_STATES.INIT
export const appStateReducer = (
  state: string = initialState,
  action: Object,
) => {
  switch (action.type) {
    case INIT:
      return APP_STATES.INIT
    case LOGIN:
      return APP_STATES.LOGIN
    case APPLICATION_READY:
      return APP_STATES.APPLICATION_READY
    default:
      return state
  }
}

export default appStateReducer
