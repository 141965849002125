// @flow
import { makeAction } from 'elder/actions'

import { addNamespace } from 'utils'

export const NAMESPACE = 'WORKFLOW'

export const SET_ACTIVE_WORKFLOW = addNamespace(
  NAMESPACE,
  'SET_ACTIVE_WORKFLOW',
)
export const CLEAR_ACTIVE_WORKFLOW = addNamespace(
  NAMESPACE,
  'CLEAR_ACTIVE_WORKFLOW',
)

export const setActiveWorkflow = makeAction(
  SET_ACTIVE_WORKFLOW,
  (name: string, payload: ?Object) => ({ name, payload }),
)

export const clearActiveWorkflow = makeAction(CLEAR_ACTIVE_WORKFLOW)
