// @flow
import React from 'react'

import type { Option } from '@elder/common'
import { colors, Header, Checkbox, Paragraph } from '@elder/common'
import styled from 'styled-components'

import type {
  CommunicationPreferencesType,
  CommunicationPreferencesToUpdate,
} from 'features/relatedAccounts/types'

const Container = styled.div``

const StyledHeader = styled(Header)``

const CheckboxBlock = styled.div`
  display: flex;
  flex-direction: column;
`

const CheckboxLabel = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
`

const isDisabled = (type, communicationPreferences) =>
  !(type.value in communicationPreferences) ||
  communicationPreferences[type.value] === 'REQUIRED'

const isChecked = (type, communicationPreferences) =>
  communicationPreferences[type.value] === 'ON' ||
  communicationPreferences[type.value] === 'REQUIRED'

const onSavePreferences = (
  type,
  currentCommunicationPreferences,
  onSaveCommunicationPreferences,
  accountId,
  profileId,
  customerCommunicationTypes,
  setExpandedRelatedAccountIndex,
  index,
) => {
  const toggled = currentCommunicationPreferences[type.value] !== 'ON'

  const requestTypes = customerCommunicationTypes
    .map((communicationType) => communicationType.value)
    .filter((value) => value in currentCommunicationPreferences)
    .filter((value) => currentCommunicationPreferences[value] !== 'REQUIRED')
    .map((element) => ({
      [element]: currentCommunicationPreferences[element] === 'ON',
    }))
    .reduce((acc, curr) => ({ ...acc, ...curr }), {})

  const communicationPreferences = { ...requestTypes, [type.value]: toggled }

  onSaveCommunicationPreferences({
    profileId,
    accountId,
    communicationPreferences,
  })
  setExpandedRelatedAccountIndex(index)
}

type Props = {|
  +profileId: string,
  +accountId: string,
  +onSaveCommunicationPreferences: (CommunicationPreferencesToUpdate) => void,
  +communicationPreferences: CommunicationPreferencesType,
  +customerCommunicationTypes: Array<Option>,
  +index: number,
  +setExpandedRelatedAccountIndex: (number) => void,
|}

export const CommunicationPreferences = ({
  profileId,
  accountId,
  onSaveCommunicationPreferences,
  communicationPreferences,
  customerCommunicationTypes,
  setExpandedRelatedAccountIndex,
  index,
}: Props) => (
  <Container>
    <StyledHeader label="Notification preferences" level="h3" />
    <CheckboxBlock>
      {customerCommunicationTypes.map((type) => (
        <CheckboxLabel key={type.value}>
          <Paragraph label={type.label} level="p1" />
          <Checkbox
            onClick={() =>
              onSavePreferences(
                type,
                communicationPreferences,
                onSaveCommunicationPreferences,
                accountId,
                profileId,
                customerCommunicationTypes,
                setExpandedRelatedAccountIndex,
                index,
              )
            }
            checked={isChecked(type, communicationPreferences)}
            disabled={isDisabled(type, communicationPreferences)}
            colorScheme={{
              color: colors.dirtyWhite,
              contrast: colors.dirtyWhite,
              backgroundColor: colors.dirtyWhite,
              backgroundContrast: colors.dirtyWhite,
            }}
          />
        </CheckboxLabel>
      ))}
    </CheckboxBlock>
  </Container>
)
