// @flow
import PropTypes from 'prop-types'

export const rptOptions = PropTypes.arrayOf(
  PropTypes.shape({
    value: PropTypes.string,
    label: PropTypes.string,
  }),
)

export const rptOptionsExtended = PropTypes.shape({
  default: PropTypes.string,
  options: rptOptions,
})

export type RptOptions = Array<{|
  +value?: string,
  +label?: string,
|}>

export type RptOptionsNotOptional = Array<{|
  +value: string,
  +label: string,
|}>

export type RptOptionsExtended = {|
  +default?: string,
  +options?: RptOptions,
|}

export type RptOptionsExtendedNotOptional = {|
  +default?: string,
  +options: RptOptionsNotOptional,
|}
