// @flow
import { SET_EMAIL } from 'app/login/actions'

export const email = (state: string = '', action: Object) => {
  switch (action.type) {
    case SET_EMAIL:
      return action.email
    default:
      return state
  }
}
