// @flow
import moment from 'moment'
import type { Saga } from 'redux-saga'
import { call, select, put, takeLatest } from 'redux-saga/effects'

import {
  applicationError,
  applicationSuccess,
  applicationWarning,
} from 'features/snackbar/snackbar'
import { UPDATE_STATUS } from 'modules/pageEdit'
import { pageSelectors } from 'routes/account/selectors'
import {
  REACTIVATE_ACCOUNT,
  NAMESPACE,
  pageActions,
} from 'routes/account/summary/actions'
import { expectedUpdateStatus } from 'routes/account/summary/validFields'
import { addNamespace } from 'utils'
import { manageErrorWithFieldValidation } from 'utils/fieldValidationErrors'
import { fetchFlow, describeServiceError, putFlow } from 'utils/services'

export function* putAccountStatus({
  data: { timestamp, ...restOfForm },
}: {|
  +data: { +timestamp: string, +status: string },
|}): Generator<*, void, *> {
  const acctId = yield select(pageSelectors.getId)
  const endpoint = `/et/accounts/${acctId}/status`
  const method = 'PUT'
  try {
    const responseBody = yield call(fetchFlow, endpoint, method, {
      ...restOfForm,
      timestamp: moment(timestamp),
    })
    yield put(pageActions.set(responseBody))
    applicationSuccess({
      title: 'Account updated',
      message: 'Account status was updated successfully.',
    })
  } catch (error) {
    if (restOfForm.status === 'ARCHIVED') {
      yield call(
        manageErrorWithFieldValidation,
        error,
        'updateStatus',
        expectedUpdateStatus,
        pageActions.updateStatusValidationFail,
      )
    } else {
      applicationError(
        describeServiceError(error, `Error with service call ${endpoint}`),
      )
    }
  }
}

export function* reactivateAccountSaga(): Generator<*, void, *> {
  const accountId = yield select(pageSelectors.getId)
  const endpoint = `/et/accounts/${accountId}/reopen`
  try {
    const responseBody = yield call(putFlow, endpoint)
    yield put(pageActions.set(responseBody))
    if (responseBody.status === 'ACTIVE') {
      applicationSuccess({
        title: 'Account updated',
        message: 'Account successfully reopened.',
      })
    } else {
      applicationWarning({
        title: 'Account updated',
        message: `Account has been reopened and now has status ${responseBody.status}`,
      })
    }
  } catch (error) {
    applicationError(
      describeServiceError(error, `Error with service call ${endpoint}`),
    )
  }
}

export function* watchAccountStatus(): Saga<*> {
  const updateStatus = addNamespace(NAMESPACE, UPDATE_STATUS)
  yield takeLatest(updateStatus, putAccountStatus)
  yield takeLatest(REACTIVATE_ACCOUNT, reactivateAccountSaga)
}
