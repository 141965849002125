// @flow
import { refDataEnum } from '@elder/common'
import { object, string, Type, arrayOf, boolean } from 'flow-validator'

import type { FlowOfType } from 'elder/types'

import { localDate } from 'domain/dates'

export type EvidenceType =
  | 'PASSPORT'
  | 'ENHANCED_DBS'
  | 'DBS_UPDATE_CHECK'
  | 'PVG'
  | 'IDENTITY_CARD'
  | 'VISA'
  | 'FULL_CARE_CERTIFICATE'
  | 'OTHER_CERTIFICATE'
  | 'PROOF_OF_ADDRESS'
  | 'CV'
  | 'CAR_DRIVERS_LICENSE'
  | 'NATIONAL_INSURANCE'
  | 'NATIONAL_INSURANCE_CARD'
  | 'REFERENCE'
  | 'BIRTH_CERTIFICATE'
  | 'SHARE_CODE'
  | 'CERTIFICATE_OF_ADOPTION'
  | 'CERTIFICATE_OF_NATURALISATION'
  | 'CREDIT_CARD_STATEMENT'
  | 'P45'
  | 'NEW_STARTER_FORM'
  | 'GENERAL_CERTIFICATE'
  | 'OTHER'
  | 'RECEIPT'
  | 'CARER_INSURANCE'

const metadata: Type<*> = object({
  type: string,
  countryOfIssue: string.optional(),
  firstName: string.optional(),
  familyName: string.optional(),
  dateOfBirth: localDate.optional(),
  issueDate: localDate.optional(),
  expiryDate: localDate.optional(),
  visaType: string.optional(),
  typeOfVisa: string.optional(),
  rightToWork: boolean.optional(),
  certificateNumber: string.optional(),
  checkResult: string.optional(),
  childrensBarredListCheck: string.optional(),
  onAdultsBarredList: boolean.optional(),
  adultsBarredListCheck: string.optional(),
  warningsOrConvictions: boolean.optional(),
  registeredWithUpdateService: boolean.optional(),
  pvgMembershipNumber: string.optional(),
  disclosureNumber: string.optional(),
  issuingOrganization: string.optional(),
  description: string.optional(),
  postcode: string.optional(),
  automaticOnly: boolean.optional(),
  licenseType: string.optional(),
  documentTypeName: string.optional(),
  nationalInsuranceNumber: string.optional(),
  shareCodeNumber: string.optional(),
  company: string.optional(),
  startDate: localDate.optional(),
  endDate: localDate.optional(),
  refereeEmail: string.optional(),
  condition: refDataEnum.optional(),
  status: refDataEnum.optional(),
  comment: string.optional(),
  leavingDate: localDate.optional(),
  gender: string.optional(),
  employeeStatement: string.optional(),
  taxCode: string.optional(),
  totalPayToDate: string.optional(),
  totalTaxToDate: string.optional(),
  studentLoanPlan: refDataEnum.optional(),
  studentLoanPostgraduate: boolean.optional(),
  documentType: string.optional(),
  lastDoseType: string.optional(),
  certification: string.optional(),
  certificationProvider: string.optional(),
  otherCertification: string.optional(),
  otherCertificationProvider: string.optional(),
  amount: string.optional(),
  miles: string.optional(),
  amountPerMile: string.optional(),
  travelDirection: string.optional(),
  travelModes: arrayOf(string).optional(),
  insuranceProvider: string.optional(),
  policyType: string.optional(),
  policyId: string.optional(),
  policyStartDate: localDate.optional(),
  policyEndDate: localDate.optional(),
})

const evidence: Type<*> = object({
  createdAt: string,
  documents: arrayOf(string),
  id: string,
  signedOffBy: string.optional(),
  signedOffAt: string.optional(),
  metadata,
})

export type Evidence = FlowOfType<typeof evidence>

export type EvidenceMetadata = FlowOfType<typeof metadata>

export const getEvidenceRequest: Type<*> = object({ carerId: string })

export const getEvidenceResponse: Type<*> = object({
  evidence: arrayOf(evidence),
})

const document = object({
  files: arrayOf(
    object({
      contentType: string,
    }),
  ),
})

export const preUploadRequestData = object({
  document,
  metadata,
})

export const preUploadRequest = object({
  carerId: string,
  data: preUploadRequestData,
})

const fileToUpload = object({
  id: string,
  uploadUrl: string,
})

export type FileToUpload = FlowOfType<typeof fileToUpload>

export const preUploadResponse = object({
  evidenceId: string,
  files: arrayOf(fileToUpload),
})

export const carerAndEvidenceIdRequest = object({
  carerId: string,
  evidenceId: string,
})

export const editEvidenceRequest = object({
  evidenceId: string,
  carerId: string,
  metadata,
  addedDocuments: document.optional(),
  removedDocuments: arrayOf(string).optional(),
})

export const documentRequest = object({ documentId: string })

export const documentResponse = object({
  files: arrayOf(
    object({
      id: string,
      contentType: string,
      downloadUrl: string.optional(),
    }),
  ),
  id: string,
  name: string,
})
