// @flow
import React from 'react'

import { Stack } from '@mui/material'

import { GenericButton } from 'components'

type Props = {|
  +canEdit: boolean,
  +status: string,
  +archiveScheduled: boolean,
  +updateStatus: (any) => void,
  +editSection: (string) => void,
  +cancelArchive: () => void,
  +reactivateAccount: () => void,
|}

export const StatusButtons = ({
  canEdit,
  status,
  archiveScheduled,
  updateStatus,
  editSection,
  cancelArchive,
  reactivateAccount,
}: Props) => {
  if (!canEdit) {
    return null
  }

  if (archiveScheduled) {
    return (
      <Stack spacing={1}>
        <GenericButton
          text="Update closure"
          onClick={() => editSection('editScheduledArchive')}
          inline
        />
        <GenericButton text="Cancel closure" onClick={cancelArchive} inline />
      </Stack>
    )
  }

  switch (status) {
    case 'NEW':
      return (
        <Stack spacing={1}>
          <GenericButton
            text="Activate"
            onClick={() => updateStatus({ status: 'ACTIVE' })}
          />
          <GenericButton
            text="Archive"
            onClick={() => editSection('archiveAccount')}
            loud
          />
        </Stack>
      )
    case 'ACTIVE':
      return (
        <GenericButton
          text="Archive"
          onClick={() => editSection('archiveAccount')}
          loud
        />
      )
    case 'ARCHIVED':
      return (
        <Stack spacing={1} direction="row">
          <GenericButton text="Reopen account" onClick={reactivateAccount} />
          <GenericButton
            text="Update closure"
            onClick={() => editSection('editArchive')}
            inline
          />
        </Stack>
      )
    default:
      return null
  }
}
