export const downloadFile = (file, filename) => {
  const a = document.createElement('a')
  document.body.appendChild(a)
  a.style = 'display: none'
  const url = window.URL.createObjectURL(file) // eslint-disable-line compat/compat
  a.href = url
  // periods cause browser to not add file extension
  a.download = filename.replace('.', '')
  a.click()
  window.URL.revokeObjectURL(url) // eslint-disable-line compat/compat
  document.body.removeChild(a)
}
