// @flow

import type { Template } from 'features/notes/domain'
import {
  caCall,
  initialSalesCall,
} from 'features/notes/templates/customerCalls'
import { defaultTemplate } from 'features/notes/templates/defaultTemplate'
import { initialTransfer } from 'features/notes/templates/initialTransfer'
import { matchingRequirements } from 'features/notes/templates/matchingRequirements'
import { tempInitialTransfer } from 'features/notes/templates/tempInitialTransfer'
import { firstWeekPlan, weeklyPlan } from 'features/notes/templates/weeklyPlans'
import {
  weeklyReview,
  firstWeekReview,
} from 'features/notes/templates/weeklyReviews'

export const customerTemplates: Array<Template> = [
  defaultTemplate,
  initialTransfer,
  tempInitialTransfer,
  weeklyPlan,
  firstWeekPlan,
  weeklyReview,
  firstWeekReview,
  caCall,
  initialSalesCall,
  matchingRequirements,
]
