import { useEffect, useState } from 'react'

import { CloseableDialog } from '@elder/common'
import { useEtResolveConversationUsingPUT as useResolveConversationMutation } from '@elder/et-facade-et'
import { LoadingButton } from '@mui/lab'
import { TextField } from '@mui/material'
import { useSnackbar } from 'notistack'
import type { Inbox } from 'talkjs/all'

interface ResolveDialogProps {
  inbox: Inbox | undefined
}

export const ResolveConversationDialog = ({ inbox }: ResolveDialogProps) => {
  const { enqueueSnackbar } = useSnackbar()

  const [reason, setReason] = useState<string>('')
  const mutation = useResolveConversationMutation()
  const [conversationId, setConversationId] = useState<string | null>(null)

  useEffect(() => {
    if (!inbox) return undefined

    const sub = inbox.onCustomConversationAction(({ action, conversation }) => {
      if (action === 'markAsResolved') {
        if (!conversation) return
        setConversationId(conversation.id)
      }
    })

    return () => {
      sub.unsubscribe()
    }
  }, [inbox, mutation])

  useEffect(() => {
    if (mutation.isSuccess) {
      setConversationId(null)
      setReason('')
      mutation.reset()
      inbox?.select(undefined)
    }
  }, [mutation, inbox])

  useEffect(() => {
    if (!mutation.error) return
    enqueueSnackbar(
      `Failed to resolve conversation: ${mutation.error.message}`,
      { variant: 'error' },
    )
  }, [enqueueSnackbar, mutation.error])

  return (
    <CloseableDialog
      title="Resolve conversation"
      open={Boolean(conversationId)}
      onClose={() => {
        setConversationId(null)
      }}
      extraActions={
        <LoadingButton
          onClick={() => {
            if (!conversationId) throw new Error('No conversation to close')
            mutation.mutate({ conversationId, data: { reason } })
          }}
          loading={mutation.isPending}
          variant="contained"
          disabled={!reason.trim()}
        >
          Resolve conversation
        </LoadingButton>
      }
    >
      <TextField
        label="Reason"
        value={reason}
        onChange={(e) => setReason(e.target.value)}
        multiline
      />
    </CloseableDialog>
  )
}
