// @flow
import React from 'react'

import type { Match } from 'react-router'

import { AccountContainer } from 'routes/account'

import { NAMESPACE } from './actions'
import reducer from './reducers'
import saga from './sagas'
import SummaryContainer from './SummaryContainer'

type Props = {|
  +match: Match,
|}

const SummaryWithHeader = ({ match }: Props) => (
  <AccountContainer match={match}>
    <SummaryContainer match={match} />
  </AccountContainer>
)

export { SummaryWithHeader as Summary, saga, reducer, NAMESPACE }
