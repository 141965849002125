const common = {
  position: 'sticky',
  zIndex: 2,
  background: 'white',
}

export const stickyMuiTableHeader = {
  table: {
    borderCollapse: 'separate',
  },
  '.MuiTableHead-root': {
    ...common,
    top: 0,
  },
  '.MuiTableHead-root::after': {
    content: '""',
    position: 'absolute',
    left: '0',
    bottom: '0',
    width: '100%',
    borderBottom: '1px solid #c0c0c0',
  },
}

export const stickyMuiTableFooter = {
  '.MuiTableFooter-root': {
    ...common,
    bottom: 0,
  },
  '.MuiTableFooter-root::before': {
    content: '""',
    position: 'absolute',
    left: '0',
    top: '0',
    width: '100%',
    borderTop: '1px solid #c0c0c0',
  },
}
