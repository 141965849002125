import dateFormat from 'dateformat'
import moment from 'moment'

const shortDayMonthYearFromUTC = (utcDate) => {
  const date = new Date(utcDate)
  return dateFormat(date, "d mmm 'yy")
}

const shortMonthYearFromNumberMonthYear = (monthYear) => {
  const date = new Date(monthYear)
  return dateFormat(date, 'mmm yyyy')
}

const compactDayMonthYearFromUTC = (utcDate) => {
  const date = new Date(utcDate)
  return dateFormat(date, 'dS mmm, yyyy')
}

const compactDowDayMonthYearFromUTC = (utcDate) => {
  const date = new Date(utcDate)
  return dateFormat(date, 'ddd dS mmm, yyyy')
}

const compactDayMonthYearHourMinFromUTC = (utcDate) => {
  const date = new Date(utcDate)
  return dateFormat(date, 'dS mmm, yyyy - HH:MM')
}

const compactDayMonthYearFromDate = (date) => {
  const newDate = new Date(date)
  return dateFormat(newDate, 'ddd dS mmm, yyyy')
}

const compactDayMonthYearLocalTime = () => {
  const date = new Date()
  return dateFormat(date, 'dS mmm yyyy')
}

const dayMonthYearFromUTC = (utcDate) => {
  const date = new Date(utcDate)
  return dateFormat(date, 'dS mmmm, yyyy')
}

const fullDayMonthYearFromUTC = (utcDate) => {
  const date = new Date(utcDate)
  return dateFormat(date, 'dddd dS mmmm, yyyy')
}

export const prettyFullDate = (utcDate) => {
  if (!utcDate) {
    return ''
  }
  return fullDayMonthYearFromUTC(utcDate)
}

export const prettyDate = (utcDate) => {
  if (!utcDate) {
    return ''
  }
  return dayMonthYearFromUTC(utcDate)
}

export const prettyCompactDate = (utcDate) => {
  if (!utcDate) {
    return ''
  }
  return compactDayMonthYearFromUTC(utcDate)
}

export const prettyCompactDateWithDow = (utcDate) => {
  if (!utcDate) {
    return ''
  }
  return compactDowDayMonthYearFromUTC(utcDate)
}

export const prettyCompactDateWithDowOrInf = (utcDate) => {
  if (!utcDate) {
    return '∞'
  }
  return compactDowDayMonthYearFromUTC(utcDate)
}

export const prettyShortDate = (utcDate) => {
  if (!utcDate) {
    return ''
  }
  return shortDayMonthYearFromUTC(utcDate)
}

export const prettyCompactDayDate = (date) => {
  if (!date) {
    return ''
  }
  return compactDayMonthYearFromDate(date)
}

export const prettyCompactDateTime = (utcDate) => {
  if (!utcDate) {
    return ''
  }
  return compactDayMonthYearHourMinFromUTC(utcDate)
}

export const prettyCompactTodayDate = () => compactDayMonthYearLocalTime()

export const prettyDurationWeeks = (startMoment, endMoment) => {
  const ndays = (n) => (n === 1 ? `${n} day` : `${n} days`)
  const days = moment(endMoment, 'YYYY-MM-DD').diff(
    moment(startMoment, 'YYYY-MM-DD'),
    'days',
  )

  if (days < 14) {
    return ndays(days)
  }
  const weeks = Math.floor(days / 7)
  const extraDays = days % 7
  return extraDays ? `${weeks} weeks, ${ndays(extraDays)}` : `${weeks} weeks`
}

export const prettyMonthYear = (monthYear) => {
  if (!monthYear) {
    return ''
  }
  return shortMonthYearFromNumberMonthYear(monthYear)
}
