/* eslint-disable no-use-before-define */
// @flow

import { isUndefined, string, object, Type, isAny } from 'flow-validator'
import urlTemplate from 'url-template'

export type HttpMethod = 'POST' | 'GET' | 'PUT' | 'DELETE' | 'PATCH'
export type Headers = { [string]: string }
export type ServiceEndpoint<TRequest, TResponse> = {|
  requestType: Type<TRequest>,
  responseType: Type<TResponse>,
  httpMethod: HttpMethod,
  urlBuilder: (TRequest) => string,
  headerBuilder: ?(TRequest) => Headers,
  bodyBuilder: ?(TRequest) => Object,
  externalService: boolean,
  textResponseBody: boolean,
  blobResponseBody: boolean,
  fileResponseBody: boolean,
  noResponseBody: boolean,
  rawRequestBody: boolean,
  formDataBody: boolean,
|}

/**
 * Private state for the builder, fields are only set if explicitly specified, so that we can
 * easily either require fields to be set (such as URL), or have a default (HTTP method).
 */
type BuilderState<TRequest, TResponse> = {|
  requestType: Type<TRequest>,
  responseType: Type<TResponse>,
  httpMethod: HttpMethod | void,
  urlBuilder: ((TRequest) => string) | void,
  headerBuilder: ((TRequest) => Headers) | void,
  bodyBuilder: ((TRequest) => Object) | void,
  externalService: boolean | void,
  textResponseBody: boolean | void,
  blobResponseBody: boolean | void,
  fileResponseBody: boolean | void,
  noResponseBody: boolean | void,
  rawRequestBody: boolean | void,
  formDataBody: boolean | void,
|}

const withDefault = <T>(value: T | void, defaultValue: T): T =>
  typeof value === 'undefined' ? defaultValue : value

function required<T>(value: T | void, field: string): T {
  if (typeof value === 'undefined') {
    throw new Error(
      `Required field ${field} has not been set, cannot construct service`,
    )
  }

  return value
}

class EndpointBuilder<TRequest, TResponse> {
  state: BuilderState<TRequest, TResponse>

  constructor(state: Object) {
    this.state = state
  }

  withHttpMethod(httpMethod: HttpMethod): EndpointBuilder<TRequest, TResponse> {
    return new EndpointBuilder({
      httpMethod,
      ...this.state,
    })
  }

  withUrlBuilder(
    urlBuilder: (TRequest) => string,
  ): EndpointBuilder<TRequest, TResponse> {
    return new EndpointBuilder({
      urlBuilder,
      ...this.state,
    })
  }

  withUrl(url: string): EndpointBuilder<TRequest, TResponse> {
    // Flow needs a bit help to understand that 0 argument function is compatible with one of our expected type
    const urlBuilder: (TRequest) => string = () => url
    return new EndpointBuilder({
      urlBuilder,
      ...this.state,
    })
  }

  withDynamicUrl(
    urlMapper: (TRequest) => string,
  ): EndpointBuilder<TRequest, TResponse> {
    return new EndpointBuilder({
      urlBuilder: urlMapper,
      ...this.state,
    })
  }

  withUrlTemplate(
    templateStr: string,
    urlParamsMapper: (TRequest) => Object = (request: any) => request,
  ): EndpointBuilder<TRequest, TResponse> {
    const template = urlTemplate.parse(templateStr)
    return new EndpointBuilder({
      urlBuilder: (params) => {
        const templateParams = urlParamsMapper(params)
        return template.expand(templateParams)
      },
      ...this.state,
    })
  }

  withHeaderBuilder(
    headerBuilder: (TRequest) => Headers,
  ): EndpointBuilder<TRequest, TResponse> {
    return new EndpointBuilder({
      headerBuilder,
      ...this.state,
    })
  }

  withBodyBuilder(
    bodyBuilder: (TRequest) => Object,
  ): EndpointBuilder<TRequest, TResponse> {
    return new EndpointBuilder({
      bodyBuilder,
      ...this.state,
    })
  }

  asExternal(): EndpointBuilder<TRequest, TResponse> {
    return new EndpointBuilder({
      externalService: true,
      ...this.state,
    })
  }

  withTextResponseBody(): EndpointBuilder<TRequest, string> {
    return new EndpointBuilder({
      textResponseBody: true,
      responseType: string,
      ...this.state,
    })
  }

  withBlobResponseBody(): EndpointBuilder<TRequest, string> {
    return new EndpointBuilder({
      blobResponseBody: true,
      responseType: isAny,
      ...this.state,
    })
  }

  withFileResponseBody(): EndpointBuilder<TRequest, string> {
    return new EndpointBuilder({
      fileResponseBody: true,
      responseType: isAny,
      ...this.state,
    })
  }

  withRawBody(): EndpointBuilder<TRequest, string> {
    return new EndpointBuilder({
      rawRequestBody: true,
      ...this.state,
    })
  }

  withFormDataBody(): EndpointBuilder<TRequest, string> {
    return new EndpointBuilder({
      formDataBody: true,
      ...this.state,
    })
  }

  withNoResponseBody(): EndpointBuilder<TRequest, string> {
    return new EndpointBuilder({
      ...this.state,
      noResponseBody: true,
      responseType: object({}),
    })
  }

  build(): ServiceEndpoint<TRequest, TResponse> {
    const params = this.state
    const { requestType, responseType, headerBuilder, bodyBuilder } = params
    const httpMethod = withDefault(params.httpMethod, 'GET')
    const urlBuilder = required(params.urlBuilder, 'URL')
    const externalService = withDefault(params.externalService, false)
    const textResponseBody = withDefault(params.textResponseBody, false)
    const blobResponseBody = withDefault(params.blobResponseBody, false)
    const fileResponseBody = withDefault(params.fileResponseBody, false)
    const noResponseBody = withDefault(params.noResponseBody, false)
    const rawRequestBody = withDefault(params.rawRequestBody, false)
    const formDataBody = withDefault(params.formDataBody, false)

    return {
      requestType,
      responseType,
      httpMethod,
      urlBuilder,
      headerBuilder,
      bodyBuilder,
      externalService,
      textResponseBody,
      blobResponseBody,
      fileResponseBody,
      noResponseBody,
      rawRequestBody,
      formDataBody,
    }
  }
}

export const endpointBuilder = <TRequest, TResponse>(
  requestType: Type<TRequest>,
  responseType: Type<TResponse>,
): EndpointBuilder<TRequest, TResponse> =>
  new EndpointBuilder({ requestType, responseType })

// Utility aliases for flow-validates to improve readability in service definitions
export const noParameters = isUndefined
export const noResponse = object({})
