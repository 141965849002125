// @flow
import type { Option } from '@elder/common'
import { object, boolean, string, arrayOf } from 'flow-validator'

import type { FlowOfType } from 'elder/types'

import type { Amount } from 'domain/invoice'

export type Entry = {|
  +transactionId: string,
  +description: string,
  +internalDescription: string,
  +carerName: string,
  +carerId: string,
  +amount: Amount,
  +summarizedView: boolean,
  +detailedView: boolean,
|}

export type Payment = {|
  +id: string,
  +amount: Amount,
  +paidAmount?: Amount,
  +remainingAmount?: Amount,
  +paymentMethod: string,
  +status: string,
  +substatus?: string,
  +createdBy: string,
  +createdAt: string,
  +completedAt?: string,
  +cancellable: boolean,
  +description?: string,
|}

export type Invoice = {|
  +id: string,
  +creationDate: string,
  +dueDate: string,
  +invoiceTotal: Amount,
  +overdue: boolean,
  +invoiceStatus: string,
  +invoiceStatusOption: Option,
  +invoicePdfUrl?: string,
  +entries: Array<Entry>,
  +payments: Array<Payment>,
  +canCreatePayment: boolean,
|}

export type PaymentConfirmations = {|
  +amount: Amount,
  +invoiceId: string,
  +paymentMethod: string,
|}

export const amount = object({
  amount: string,
  currency: string,
  displayText: string,
})

export const entry = object({
  amount,
  carerId: string.optional(),
  carerName: string.optional(),
  description: string.optional(),
  detailedView: boolean,
  internalDescription: string.optional(),
  summarizedView: boolean,
  transactionId: string,
})

export const payment = object({
  amount,
  paidAmount: amount.optional(),
  remainingAmount: amount.optional(),
  cancellable: boolean,
  completedAt: string.optional(),
  createdAt: string,
  createdBy: string,
  description: string.optional(),
  id: string,
  paymentMethod: string,
  status: string.optional(),
  substatus: string.optional(),
})

export const invoice = object({
  id: string,
  creationDate: string,
  dueDate: string.optional(),
  invoiceTotal: amount,
  overdue: boolean,
  invoiceStatus: string,
  entries: arrayOf(entry).optional(),
  invoicePdfUrl: string.optional(),
  payments: arrayOf(payment).optional(),
  canCreatePayment: boolean,
})

export const overview = object({
  outstandingBalance: amount.optional(),
  overdueBalance: amount.optional(),
  lastInvoiceAmount: amount.optional(),
  lastInvoiceDate: string.optional(),
  lastInvoiceExpectedPaymentDate: string.optional(),
  oldestUnpaidInvoiceDate: string.optional(),
  includesBankTransfer: boolean.optional(),
})

export const accountInvoicesResponseType = object({
  invoices: arrayOf(invoice).optional(),
  overview,
})

export type AccountInvoices = FlowOfType<typeof accountInvoicesResponseType>
export type InvoiceOverview = FlowOfType<typeof overview>
export type InvoiceType = FlowOfType<typeof invoice>
export type EntryType = FlowOfType<typeof entry>
