// @flow
import { SET_ID } from 'modules/pageIdAndIdentity/actions'
import { addNamespace } from 'utils'

export const makeIdReducer = (namespace: string) => {
  const initialState = ''
  const setId = addNamespace(namespace, SET_ID)

  return (state: string = initialState, action: Object) => {
    switch (action.type) {
      case setId:
        return action.id
      default:
        return state
    }
  }
}

export default makeIdReducer
