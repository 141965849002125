// @flow
import { object, string } from 'flow-validator'

import { endpointBuilder } from 'elder/services'

const sendCarerMessageRequest = object({
  carerId: string,
  title: string,
  body: string,
  url: string.optional(),
})

const sendCarerMessageResponse = object({})

export const carerMessagingService = {
  sendCarerMessage: endpointBuilder(
    sendCarerMessageRequest,
    sendCarerMessageResponse,
  )
    .withHttpMethod('POST')
    .withBodyBuilder(({ title, body, url }) => ({ title, body, url }))
    .withUrlTemplate('/et/professionals/{carerId}/message', ({ carerId }) => ({
      carerId,
    }))
    .build(),
}
