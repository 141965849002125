// @flow

import styled from 'styled-components'

export const BottomButtonsWrapper = styled.div`
  display: flex;
  justify-content: flex-end;
  margin-top: 20px;

  & > div:not(:last-child) {
    margin-right: 15px;
  }
  position: sticky;
  bottom: 0px;
  background-color: white;
  padding: 10px 0px 30px;
  border-top: 1px solid #efefef;
`
