// @flow
import * as Sentry from '@sentry/react'
import type { Saga } from 'redux-saga'
import { takeLatest, put } from 'redux-saga/effects'

import { call } from 'elder/effects'

import { medicationClient } from 'app/saga/serviceClients'
import {
  GET_MEDICINES,
  getMedicinesError,
  setMedicines,
} from 'features/recipient/medication/store/actions'

function* handleGetMedicines({
  queryText,
  paginationToken,
  maxSize,
}: {
  +queryText: string,
  +paginationToken?: string,
  +maxSize?: string,
}): Saga<*> {
  try {
    const response = yield* call(medicationClient.getMedicines, {
      queryText,
      paginationToken,
      maxSize,
    })
    yield put(setMedicines(response.items))
  } catch (error) {
    Sentry.captureException(error)
    yield put(getMedicinesError(error))
  }
}

export function* medicationSaga(): Saga<*> {
  yield takeLatest(GET_MEDICINES, handleGetMedicines)
}
