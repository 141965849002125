// @flow

import ExtendableError from 'es6-error'

export type ElderErrorResponse = {
  message: string,
  errorCode: string,
  errorDetails: string,
}

export class ElderServiceError extends ExtendableError {
  +status: number;
  +errorCode: string;
  +details: string;
  +response: Object

  constructor(
    message: string,
    status: number,
    errorCode: string,
    details: string,
    response: Object,
  ) {
    super(message)
    this.message = message
    this.status = status
    this.errorCode = errorCode
    this.details = details
    this.response = response
  }
}

/**
 * Thrown when we have no valid session with the backend, and we've been asked to provide an
 * authenticated call. It is up to the caller to decide to either signal this as an error, or to
 * silently ignore it, given that the application will also have signalled to the user that the
 * session has ended and a new login is required.
 */
export class NoSessionServiceError extends ExtendableError {}

export class HttpServiceError extends ExtendableError {
  +status: number;
  +response: ?string = null

  constructor(message: string, status: number, response: ?string = null) {
    super(message)
    this.status = status
    this.response = response
  }
}

// Used for exceptions thrown by fetch (no network) and timeouts
export type NetworkServiceErrorType = 'TIMEOUT' | 'NETWORK'

export class NetworkServiceError extends ExtendableError {
  +errorType: NetworkServiceErrorType;
  +error: ?Object = null

  constructor(
    message: string,
    errorType: NetworkServiceErrorType,
    error: ?Object = null,
  ) {
    super(message)
    this.errorType = errorType
    this.error = error
  }
}
