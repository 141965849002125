// @flow
import React from 'react'

import { EditOutlined } from '@mui/icons-material'
import { IconButton } from '@mui/material'
import styled from 'styled-components'

import { AccountEntry } from 'components/styledComponentsTranslatedClasses/AccountEntry'

const AccountEntrySmallEdit = styled.div`
  align-items: center;
  display: flex;
  justify-content: space-between;

  & > div {
    margin-right: 10px;

    &:last-child {
      margin-right: 0;
    }
  }
`

type Props = {|
  +careType: string,
  +canEdit: boolean,
  +editSection: (string) => void,
|}

export const CareAgreementSection = ({
  careType,
  canEdit,
  editSection,
}: Props) => (
  <div>
    <AccountEntry>
      <span>Type of care:</span>
      <AccountEntrySmallEdit>
        {careType || '<not set>'}
        {canEdit && (
          <IconButton
            onClick={() => editSection('careType')}
            title="Edit type of care"
            color="primary"
            sx={{ ml: 2 }}
          >
            <EditOutlined
              sx={{
                fontSize: '24px',
              }}
            />
          </IconButton>
        )}
      </AccountEntrySmallEdit>
    </AccountEntry>
  </div>
)
