// @flow
import { object, string } from 'flow-validator'

import { endpointBuilder } from 'elder/services'

import { testGroup } from 'features/experiments/domain'

export const experimentService = {
  getTestGroup: endpointBuilder(
    object({ accountId: string, experimentName: string }),
    testGroup,
  )
    .withUrlTemplate(
      '/et/accounts/{accountId}/experimentParticipation?experimentName={experimentName}',
      ({ accountId, experimentName }) => ({ accountId, experimentName }),
    )
    .build(),
}
