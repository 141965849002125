// @flow
import type { Saga } from 'redux-saga'
import { call, takeLatest, put } from 'redux-saga/effects'

import { setCarer } from 'features/carerPageGenerics/store/actions'
import { applicationError } from 'features/snackbar/snackbar'
import { getAndSetFlow, postFlow, describeServiceError } from 'utils/services'

import {
  GET_CHANGELOG,
  DELETE_CHANGE_HISTORY,
  setChangelog,
} from './store/actions'

function* fetchProfessionalChangelogFlow({ carerId }): Saga<*> {
  yield call(
    getAndSetFlow,
    `/et/professionals/${carerId}/changelog`,
    setChangelog,
  )
}

function* deleteChangeHistory({ carerId }): Saga<*> {
  const endpoint = `/et/professionals/${carerId}/clearHistory`

  try {
    const profile = yield call(postFlow, endpoint, {})
    yield put(setCarer(profile))
  } catch (error) {
    applicationError(
      describeServiceError(error, `Error with service call ${endpoint}`),
    )
  }
}

export function* saga(): Saga<*> {
  yield takeLatest(GET_CHANGELOG, fetchProfessionalChangelogFlow)
  yield takeLatest(DELETE_CHANGE_HISTORY, deleteChangeHistory)
}
