// @flow

import * as Sentry from '@sentry/react'
import { replace } from 'connected-react-router'
import type { Saga } from 'redux-saga'
import { put, select, takeLatest } from 'redux-saga/effects'

import { call } from 'elder/effects'

import { recipientClient } from 'app/saga/serviceClients'
import type { RecipientData } from 'features/recipient/domain'
import {
  CHANGE_STATUS,
  changingStatusActions,
  clearActiveWorkflow,
  DELETE_RECIPIENT,
  NAMESPACE,
  pageActions,
  UPDATE_RECIPIENT,
} from 'features/recipient/recipientExisting/actions'
import { getRecipientId } from 'features/recipient/recipientExisting/selectors'
import {
  applicationError,
  applicationSuccess,
} from 'features/snackbar/snackbar'
import { GET } from 'modules/pageEdit'
import { pageSelectors as pageAccountSelectors } from 'routes/account/selectors'
import { addNamespace } from 'utils'
import { describeServiceError } from 'utils/services'

function* handleGetRecipient({ id }: {| +id: string |}): Saga<*> {
  const accountId = yield select(pageAccountSelectors.getId)
  const recipientId = id
  try {
    const response = yield* call(recipientClient.getRecipient, {
      accountId,
      recipientId,
    })
    yield put(pageActions.set(response))
  } catch (error) {
    Sentry.captureException(error)
    applicationError(describeServiceError(error, 'Error with service call'))
  }
}

function* handleUpdateRecipient({ data }: {| +data: RecipientData |}): Saga<*> {
  const accountId = yield select(pageAccountSelectors.getId)
  const recipientId = yield select(getRecipientId)
  try {
    const response = yield* call(recipientClient.updateRecipient, {
      accountId,
      recipientId,
      data,
    })
    yield put(pageActions.set(response))
    applicationSuccess({
      title: 'Recipient Saved',
      message: 'Recipient profile was updated successfully.',
    })
  } catch (error) {
    Sentry.captureException(error)
    applicationError(describeServiceError(error, 'Error with service call'))
  }
}

function* handleDeleteRecipient(): Saga<*> {
  const accountId = yield select(pageAccountSelectors.getId)
  const recipientId = yield select(getRecipientId)
  try {
    yield* call(recipientClient.deleteRecipient, {
      accountId,
      recipientId,
    })
    applicationSuccess({
      title: 'Recipient Deleted',
      message: 'Recipient profile was deleted successfully.',
    })
    yield put(clearActiveWorkflow())
    yield put(replace(`/accounts/${accountId}`))
  } catch (error) {
    Sentry.captureException(error)
    applicationError(describeServiceError(error, 'Error with service call'))
  }
}

function* handleChangeStatus({ status }: { +status: string }): Saga<*> {
  yield put(changingStatusActions.setStartLoading())
  const accountId = yield select(pageAccountSelectors.getId)
  const recipientId = yield select(getRecipientId)

  try {
    const response = yield* call(recipientClient.changeStatus, {
      accountId,
      recipientId,
      status,
    })
    yield put(pageActions.set(response))
    yield put(clearActiveWorkflow())
    applicationSuccess({
      title: 'Recipient status changed',
      message: 'Recipient status was changed successfully.',
    })
  } catch (error) {
    Sentry.captureException(error)
    applicationError(describeServiceError(error, 'Error with service call'))
  } finally {
    yield put(changingStatusActions.setStopLoading())
  }
}

export function* recipientDetailSaga(): Saga<*> {
  yield takeLatest(addNamespace(NAMESPACE, GET), handleGetRecipient)
  yield takeLatest(UPDATE_RECIPIENT, handleUpdateRecipient)
  yield takeLatest(DELETE_RECIPIENT, handleDeleteRecipient)
  yield takeLatest(CHANGE_STATUS, handleChangeStatus)
}
