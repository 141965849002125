// @flow
import React from 'react'

import type { Option } from '@elder/common'
import { Header, FormikSelect, spacings } from '@elder/common'
import { Button } from '@mui/material'
import { Formik, Form, FieldArray } from 'formik'
import styled from 'styled-components'

import { SectionHeader } from 'components/styledComponentsTranslatedClasses/SectionHeader'
import { AccountExecutiveSelectionList } from 'features/accountExecutive/AccountExecutiveSelectionList'
import type { ElderUser } from 'features/elderUsers/domain'

const accountExecutiveLookup = (
  user: string,
  accountExecutives: Array<ElderUser>,
): ElderUser => {
  const ae: ?ElderUser =
    accountExecutives &&
    accountExecutives.find(
      (accountExecutive) => accountExecutive.username === user,
    )
  return (
    ae || {
      elderStatus: null,
      username: user,
      firstName: user,
      lastName: '',
      disabled: true,
      accountLoad: 0,
      elderPhotoUrl: null,
      calendlyUrl: null,
      primaryAccountManagerLoad: 0,
      primaryAccountManagerMaxLoad: 0,
      primaryAccountManagerLoadPercentage: null,
      acceptingNewAccounts: null,
      workingDays: [],
      worksBankHolidays: null,
    }
  )
}

const StyledPrimaryButton = styled(Button)`
  padding: 8px;
  margin: 4px;
`

const StyledTertiaryButton = styled(Button)`
  padding: 8px;
  margin: 4px;
`

const StyledButtonGroup = styled.div`
  display: flex;
  justify-content: flex-end;
  margin: 8px;
`

const AEWrapper = styled.div`
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
  margin: 8px;
`

const StyledFormikSelect = styled(FormikSelect)`
  width: 430px;
`

const StackedItems = styled.div`
  display: flex;
  flex-direction: column;
  gap: ${spacings.small};
  width: 100%;
`

const filterSelectedOptions = (
  accountManagers: Array<Option>,
  values: {|
    accountExecutive: string,
    hierarchy: Array<ElderUser | string>,
  |},
) => {
  const filteredOptions = accountManagers.filter((accountManager) => {
    const selectedOptions = values.hierarchy.map((user) =>
      typeof user === 'string' ? user : user.username,
    )
    return !selectedOptions.includes(accountManager.value)
  })
  return filteredOptions
}
type Props = {|
  +title: string,
  +cancelEdit: () => void,
  +updateAccountExecutiveHierarchy: (Array<string>) => void,
  +accountManagers?: Array<Option>,
  +accountExecutives?: Array<ElderUser>,
  +accountExecutiveHierarchy: Array<ElderUser>,
  +canRemove?: boolean,
  +canMove?: boolean,
  +canAdd?: boolean,
  +elderUserSelectorLabel?: string,
|}

export const ExecutiveHierarchyForm = ({
  title,
  cancelEdit,
  updateAccountExecutiveHierarchy,
  accountManagers = [],
  accountExecutives = [],
  accountExecutiveHierarchy,
  canRemove = true,
  canMove = true,
  canAdd = true,
  elderUserSelectorLabel,
}: Props) => {
  const handleFormSubmit = (data) => {
    if (accountExecutiveHierarchy.length === 0) {
      updateAccountExecutiveHierarchy(data.hierarchy)
    } else {
      const newHierarchy = data.hierarchy.map((user) =>
        typeof user === 'string' ? user : user.username,
      )
      updateAccountExecutiveHierarchy(newHierarchy)
    }
    cancelEdit()
  }

  return (
    <Formik
      onSubmit={handleFormSubmit}
      initialValues={{ hierarchy: accountExecutiveHierarchy }}
    >
      {({ submitCount, handleSubmit, errors, values }) => (
        <Form>
          <SectionHeader>
            <Header label={title} level="h3" />
          </SectionHeader>
          <FieldArray name="hierarchy">
            {(arrayHelpers) => (
              <AEWrapper>
                <StackedItems>
                  {canAdd ? (
                    <StyledFormikSelect
                      name="accountExecutive"
                      label={elderUserSelectorLabel}
                      options={filterSelectedOptions(accountManagers, values)}
                      hideSelectedOptions
                      errorLabel={
                        submitCount > 0 ? errors.accountExecutive : null
                      }
                      onChangeCallback={(val) => arrayHelpers.push(val)}
                    />
                  ) : null}
                  {values.hierarchy &&
                    values.hierarchy.length > 0 &&
                    values.hierarchy
                      .map((ae) =>
                        typeof ae === 'string'
                          ? accountExecutiveLookup(ae, accountExecutives)
                          : ae,
                      )
                      .map((detailedAccountExecutive, index) => (
                        <AccountExecutiveSelectionList
                          key={detailedAccountExecutive.username}
                          ae={detailedAccountExecutive}
                          isFirst={index === 0}
                          onClose={
                            canRemove
                              ? () => arrayHelpers.remove(index)
                              : undefined
                          }
                          onMoveUp={
                            canMove
                              ? () => arrayHelpers.move(index, index - 1)
                              : undefined
                          }
                        />
                      ))}
                </StackedItems>
              </AEWrapper>
            )}
          </FieldArray>

          <StyledButtonGroup>
            <StyledTertiaryButton onClick={cancelEdit} variant="text">
              Cancel
            </StyledTertiaryButton>
            <StyledPrimaryButton onClick={handleSubmit} variant="contained">
              Save
            </StyledPrimaryButton>
          </StyledButtonGroup>
        </Form>
      )}
    </Formik>
  )
}
