// @flow
import React from 'react'

import { EditOutlined } from '@mui/icons-material'
import { IconButton } from '@mui/material'
import styled from 'styled-components'

import { EntryShowBoolean, GenericButton, SummaryCard } from 'components'
import { Entry } from 'components/styledComponentsTranslatedClasses/Entry'
import { EntrySmallEdit } from 'components/styledComponentsTranslatedClasses/EntrySmallEdit'
import type { Account } from 'routes/account/summary/Summary/props'

const EntryWithSubtitle = styled(Entry)`
  ${({ isSub }) => isSub && 'border-bottom: none;'}
`

type Props = {|
  +account: Account,
  +canEdit: boolean,
  +canManagePayments: boolean,
  +canEditPaymentAssuranceRequired: boolean,
  +editSection: (string) => void,
  +cancelDirectDebit: () => void,
|}

const BankDetails = ({
  account,
  canEdit,
  canManagePayments,
  canEditPaymentAssuranceRequired,
  editSection,
  cancelDirectDebit,
}: Props) => {
  const { paymentMethodType } = account.paymentDetails
  const canHaveFundingProof =
    account.paymentDetails.fundingMethodType === 'DIRECT_PAYMENTS' ||
    account.paymentDetails.fundingMethodType === 'COMMISSIONED_SERVICES'
  return (
    <SummaryCard heading="Billing And Payment Details" width="HALF">
      <EntryWithSubtitle isSub>
        <span>Funding Source:</span>
        <EntrySmallEdit>
          {account.paymentDetails.prettyFundingSource}
          {canEdit && (
            <IconButton
              title="Edit funding source"
              onClick={() => editSection('bankDetailsFunding')}
              color="primary"
            >
              <EditOutlined
                sx={{
                  fontSize: '24px',
                }}
              />
            </IconButton>
          )}
        </EntrySmallEdit>
      </EntryWithSubtitle>
      {account.paymentDetails.fundingLocalAuthority && (
        <EntryWithSubtitle isSub>
          <span>Local Authority Name:</span>
          <EntrySmallEdit>
            {account.paymentDetails.fundingLocalAuthority}
          </EntrySmallEdit>
        </EntryWithSubtitle>
      )}
      <EntryWithSubtitle
        isSub={account.paymentDetails.fundingMethodType === 'DIRECT_PAYMENTS'}
      >
        <span>Funding Method</span>
        {account.paymentDetails.prettyFundingMethodType}
      </EntryWithSubtitle>
      {canHaveFundingProof && (
        <EntryWithSubtitle>
          <span>Funding Proof:</span>
          {account.paymentDetails.prettyFundingProof}
        </EntryWithSubtitle>
      )}
      <EntryWithSubtitle>
        <span>Payment Assurance Required:</span>
        <EntrySmallEdit>
          {account.paymentDetails.prettyPaymentAssuranceRequired}
          {canEdit && canEditPaymentAssuranceRequired && (
            <IconButton
              title="Edit payment assurance requirement"
              onClick={() => editSection('paymentAssuranceRequired')}
              color="primary"
            >
              <EditOutlined
                sx={{
                  fontSize: '24px',
                }}
              />
            </IconButton>
          )}
        </EntrySmallEdit>
      </EntryWithSubtitle>
      <EntryWithSubtitle>
        <span>Billing Cycle:</span>
        <EntrySmallEdit>
          {account.paymentDetails.prettyBillingCycleType}
          {canEdit && canManagePayments && (
            <IconButton
              title="Edit billing cycle"
              onClick={() => editSection('bankDetailsBillingCycle')}
              color="primary"
            >
              <EditOutlined
                sx={{
                  fontSize: '24px',
                }}
              />
            </IconButton>
          )}
        </EntrySmallEdit>
      </EntryWithSubtitle>
      <EntryWithSubtitle>
        <span>Payment Type:</span>
        <EntrySmallEdit>
          {account.paymentDetails.prettyPaymentMethodType}
          {canEdit && !account.paymentDetails.directDebitPending && (
            <IconButton
              title="Edit payment type"
              onClick={() => editSection('bankDetailsPaymentDetails')}
              color="primary"
            >
              <EditOutlined
                sx={{
                  fontSize: '24px',
                }}
              />
            </IconButton>
          )}
        </EntrySmallEdit>
      </EntryWithSubtitle>
      {paymentMethodType === 'CARD' ||
        (paymentMethodType === 'STRIPE_CARD' && (
          <EntryWithSubtitle>
            <span>Card Number:</span>
            <div>{account.paymentDetails.cardDetails}</div>
          </EntryWithSubtitle>
        ))}
      {paymentMethodType === 'MANUAL_INVOICING' && (
        <EntryWithSubtitle>
          <span>Invoice Arrangement:</span>
          <div>{account.paymentDetails.description}</div>
        </EntryWithSubtitle>
      )}
      {account.paymentDetails.directDebitPending && (
        <EntryWithSubtitle>
          <span>Direct Debit Requested And Pending:</span>
          <EntrySmallEdit>
            <EntryShowBoolean val={account.paymentDetails.directDebitPending} />
            {canEdit && (
              <GenericButton
                text="Cancel"
                onClick={() => cancelDirectDebit()}
                loud
              />
            )}
          </EntrySmallEdit>
        </EntryWithSubtitle>
      )}
    </SummaryCard>
  )
}

export default BankDetails
