import { useState } from 'react'

import {
  Autocomplete,
  Button,
  Dialog,
  DialogActions,
  Stack,
  TextField,
  DialogTitle,
  DialogContent,
} from '@mui/material'

import type { AutocompleteCarer } from 'modules/autocompletePros/useCarerAutocomplete'
import { useCarerAutocomplete } from 'modules/autocompletePros/useCarerAutocomplete'

interface Props {
  clearActiveWorkflow: () => void
  addBlacklistedCarer: (professionalId: string, reason: string) => void
  activeWorkflow: { name: string }
}

const BlacklistCarerDialog = ({
  clearActiveWorkflow,
  addBlacklistedCarer,
  activeWorkflow,
}: Props) => {
  const [searchQuery, setSearchQuery] = useState('')
  const [selectedCarer, setSelectedCarer] = useState<AutocompleteCarer | null>(
    null,
  )
  const [reason, setReason] = useState('')

  const {
    carers,
    query: { isLoading },
  } = useCarerAutocomplete(searchQuery)
  const professionalOptions = carers || []

  const canSubmit = selectedCarer && reason.trim().length > 0

  const submit = () => {
    addBlacklistedCarer(selectedCarer!.value, reason)
    setSelectedCarer(null)
    setReason('')
  }

  return (
    activeWorkflow.name === 'blacklistCarer' && (
      <Dialog open onClose={clearActiveWorkflow}>
        <DialogTitle>Blacklist a carer from this account</DialogTitle>
        <DialogContent>
          <Stack pt={1}>
            <Autocomplete
              renderInput={(params) => (
                <TextField label="Search carer" autoFocus {...params} />
              )}
              value={selectedCarer}
              options={professionalOptions}
              getOptionLabel={(o) => o.label}
              onInputChange={(_, value) => setSearchQuery(value)}
              onChange={(_, value: AutocompleteCarer | null) =>
                setSelectedCarer(value)
              }
              loading={isLoading}
              sx={{ minWidth: 520 }}
            />
            <TextField
              multiline
              rows={4}
              label="Reason for blacklisting:"
              value={reason}
              onChange={(e) => setReason(e.target.value)}
            />
          </Stack>
        </DialogContent>
        <DialogActions>
          <Button onClick={clearActiveWorkflow}>Cancel</Button>
          <Button disabled={!canSubmit} onClick={submit} variant="contained">
            Confirm Blacklist
          </Button>
        </DialogActions>
      </Dialog>
    )
  )
}

export default BlacklistCarerDialog
