// @flow
import React from 'react'

import { colors } from '@elder/common'
import { Add } from '@mui/icons-material'
import { Button } from '@mui/material'
import { Link } from 'react-router-dom'
import styled from 'styled-components'

import { SummaryCard, SummaryCardActions } from 'components'
import { Status } from 'components/Status'
import { Table } from 'components/tables/Table'
import { LinkContainer } from 'containers'
import type { RecipientSummary } from 'domain/recipient'
import { prettyGender } from 'utils/decorators'

const statusColourMapper = {
  ACTIVE: colors.successDark,
  DECEASED: colors.errorDark,
}

const WrapThisRow = styled.div`
  white-space: pre-wrap;
`

const Recipient = ({
  id,
  name,
  url,
}: {
  id: string,
  name: ?string,
  url: string,
}) => (
  <>
    {name}
    <LinkContainer goto={url} text={`#${id}`} />
  </>
)

type Props = {|
  +accountId: string,
  +recipients: Array<RecipientSummary>,
  +defocused: boolean,
  +getRecipientUrl: (accountId: string, recipientId: string) => string,
  +canEdit: boolean,
  +newRecipientUrl: () => void,
|}

export const RecipientsTable = ({
  accountId,
  recipients,
  defocused,
  getRecipientUrl,
  canEdit,
  newRecipientUrl,
}: Props) => (
  <SummaryCard
    width="FULL"
    heading="Recipients"
    defocused={defocused}
    actionChildren={
      <SummaryCardActions>
        {canEdit && (
          <Button
            variant="contained"
            component={Link}
            to={newRecipientUrl}
            startIcon={<Add />}
          >
            New Recipient
          </Button>
        )}
      </SummaryCardActions>
    }
  >
    <Table
      rows={recipients.map(
        ({
          recipientId,
          prettyName,
          gender,
          medicalConditions,
          prettyStatus,
          status,
          risks,
        }) => ({
          id: recipientId,
          columns: [
            <Status
              backgroundColour={statusColourMapper[status] || colors.blue600}
            >
              {prettyStatus}
            </Status>,
            recipientId && (
              <Recipient
                name={prettyName}
                id={recipientId}
                url={getRecipientUrl(accountId, recipientId)}
              />
            ),
            prettyGender(gender),
            <WrapThisRow>{medicalConditions.join(', ')}</WrapThisRow>,
            <WrapThisRow>{risks || <>&#8212;</>}</WrapThisRow>,
          ],
        }),
      )}
      columnConfiguration={[
        { header: '' },
        { header: 'Recipient' },
        { header: 'Gender' },
        { header: 'Medical Conditions', width: '50%' },
        { header: 'Risks' },
      ]}
    />
  </SummaryCard>
)
