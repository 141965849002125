// @flow
import type { Saga } from 'redux-saga'
import { call, put, takeLatest } from 'redux-saga/effects'

import {
  ADD_BLACKLISTED_CARER,
  REMOVE_BLACKLISTED_CARER,
  pageActions,
} from 'routes/account/summary/actions'
import { putFlow } from 'utils/services'

function* addBlacklistedCarer(action): Generator<Object, void, Object> {
  const { accountId, professionalId, reason } = action
  const endpoint = `/et/accounts/${accountId}/blacklist/add`
  const data = { professionalId, reason }

  const responseBody = yield call(putFlow, endpoint, data)
  yield put(pageActions.set(responseBody))
}

function* removeBlacklistedCarer(action): Generator<Object, void, Object> {
  const { accountId, professionalId } = action
  const endpoint = `/et/accounts/${accountId}/blacklist/remove`
  const data = { professionalId }

  const responseBody = yield call(putFlow, endpoint, data)
  yield put(pageActions.set(responseBody))
}

export function* carerBlacklistSaga(): Saga<*> {
  yield takeLatest(ADD_BLACKLISTED_CARER, addBlacklistedCarer)
  yield takeLatest(REMOVE_BLACKLISTED_CARER, removeBlacklistedCarer)
}

export default carerBlacklistSaga
