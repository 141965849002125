// @flow
import { min, colors } from '@elder/common'
import styled, { css } from 'styled-components'

export const Section = styled.div`
  flex: 1 0 100%;
  margin-bottom: 30px;

  h3 {
    text-transform: capitalize;

    span {
      color: ${colors.lightSmoke};
      font-size: 16px;
    }
  }
  ${min.desktop(css`
    flex: 0.4 0 40%;
  `)};

  /**
 * formsy related
 */
  .form-group {
    &.has-feedback {
      .validation-message {
        color: #ef6461;
      }
    }

    &.has-error {
      input[type='text'],
      input[type='email'],
      input[type='date'],
      textarea {
        border-color: #ef6461;
      }
    }
  }

  .form-group.row {
    align-items: center;
    border-bottom: 1px solid #eaf0fc;
    display: flex;
    line-height: 32px;
    padding: 10px 0;
    white-space: pre-wrap;

    > span,
    > label,
    > div {
      width: 50%;
    }

    span,
    label,
    strong {
      color: #73819c;
    }

    input[type='text'],
    input[type='email'],
    input[type='date'],
    textarea {
      width: 100%;
    }

    &.has-feedback {
      .validation-message {
        display: block;
        line-height: 20px;
        text-transform: none;
        width: 100%;
      }
    }

    &.is-date-picker > div > div {
      display: block;

      & > div {
        display: block;

        & > div {
          display: block;
        }
      }
    }

    .radio-inline {
      margin-right: 15px;

      &:last-child {
        margin-right: 0px;
      }
    }
  }

  .form-group__container {
    .form-group {
      border-bottom: 0;
      padding: 0;

      .checkbox,
      .radio {
        line-height: 25px;
      }
    }

    .form-group__entry {
      padding-top: 15px;

      > .control-label {
        margin-right: 3%;
        text-align: right;
        width: 47%;
      }
    }
  }
`
