// @flow
import { combineReducers } from 'redux-immutable'

import { CLEAR_LOADING, SET_LOADING } from './actions'

type State = Array<string>
const loading = (state: State = [], action: Object): State => {
  switch (action.type) {
    case SET_LOADING:
      return [...state, action.id]
    case CLEAR_LOADING:
      return state.filter((item) => item !== action.id)
    default:
      return state
  }
}

export const reducer = combineReducers({
  loading,
})
