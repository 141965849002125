// @flow
import type { Node } from 'react'
// $FlowOptOut
import React, { useEffect, useState } from 'react'

import { etPrepareCustomerProfileImpersonationUsingPOST } from '@elder/et-facade-et/lib/react-query-client/profile-facade-service/profile-facade-service'
import { PersonOutlined, MoreVertOutlined } from '@mui/icons-material'
import { Button, IconButton } from '@mui/material'
import type { Match } from 'react-router'

import { getConfig } from 'app/config'
import { ContentPage } from 'components'
import { BreadcrumbsBar } from 'features/header/BreadcrumbsBar'
import { useLogVisitToRecents } from 'features/recents/recents'
import * as routes from 'routes'
import { formatLink } from 'utils/links'
import { openNewWindow } from 'utils/openNewWindow'
import { useSetWindowTitle } from 'utils/useSetWindowTitle'

import { ImpersonationOptionsDialog } from './ImpersonationOptionsDialog'

type Props = {|
  +children: Node,
  +profile: {|
    +profileId: string,
    +fullName: string,
  |},
  +canImpersonate: boolean,
  +setId: (string) => void,
  +getIdentity: () => void,
  +match: Match,
|}

const getMyElderUrl = (environment: string) => {
  if (environment.toLocaleLowerCase() === 'development') {
    return 'https://dev.elder.org'
  }

  if (environment.toLocaleLowerCase() === 'staging') {
    return 'https://stg.elder.org'
  }

  if (environment.toLocaleLowerCase() === 'live') {
    return 'https://my.elder.org'
  }

  throw new Error(
    `Unknown environment: ${environment} when creating impersonation callback URL`,
  )
}

export const ProfileWrapper = ({
  children,
  profile,
  canImpersonate,
  setId,
  getIdentity,
  match: {
    params: { profileId },
  },
}: Props) => {
  useEffect(() => {
    // $FlowOptOut
    setId(profileId)
    getIdentity()
  }, [])
  useLogVisitToRecents('profile', profileId)
  useSetWindowTitle(`${profile.fullName || profileId || 'Profile'} - ET3`)

  const { environment } = getConfig()

  const initialImpersonationCallbackDomain = getMyElderUrl(environment)

  const [impersonationCallbackUrl, setImpersonationCallbackUrl] = useState(
    `${initialImpersonationCallbackDomain}/api/auth/impersonate`,
  )

  const [openImpersonationOptionsDialog, setOpenImpersonationOptionsDialog] =
    useState(false)

  const isDev = environment.toLowerCase() === 'development'

  const handleImpersonation = async () => {
    await etPrepareCustomerProfileImpersonationUsingPOST(profileId)

    openNewWindow(impersonationCallbackUrl, 'Something went wrong')
  }
  return (
    <>
      <BreadcrumbsBar
        breadcrumbs={[
          { title: 'Profile', to: routes.profiles },
          {
            title: <code>{profileId}</code> || 'unknown',
            to: formatLink(routes.profile, {
              profileId: profileId || 'unknown',
            }),
          },
        ]}
      >
        {canImpersonate && (
          <>
            <Button
              onClick={handleImpersonation}
              startIcon={<PersonOutlined />}
              color="inherit"
            >
              Sign in as customer
            </Button>
            {isDev && (
              <IconButton
                aria-label="Impersonation options"
                size="small"
                onClick={() => setOpenImpersonationOptionsDialog(true)}
                color="inherit"
              >
                <MoreVertOutlined />
              </IconButton>
            )}
          </>
        )}
      </BreadcrumbsBar>
      <ContentPage>
        <h2>
          {profile.fullName} <span>#{profile.profileId}</span>
        </h2>
        {children}
      </ContentPage>
      <ImpersonationOptionsDialog
        isOpen={openImpersonationOptionsDialog}
        setIsOpen={setOpenImpersonationOptionsDialog}
        callbackUrl={impersonationCallbackUrl}
        setCallbackUrl={setImpersonationCallbackUrl}
        urls={[
          'https://dev.elder.org/api/auth/impersonate',
          'http://localhost:8080/api/auth/impersonate',
          'http://localhost:3000/api/auth/impersonate',
        ]}
      />
    </>
  )
}
