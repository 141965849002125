import type { ReactNode } from 'react'
import React from 'react'

import styled from 'styled-components'

import type { TimelineBlock } from 'components/solutions/SolutionTimeline/SolutionTimeline'

const Container = styled.div`
  overflow: hidden;
  cursor: pointer;
  z-index: ${({ block }) => block.to};
`

const Block = styled.div`
  position: absolute;
  align-items: center;
  box-sizing: border-box;
  margin-top: 0;
  padding: 0;
  height: ${({ $height, isSelected }) => $height + (isSelected ? 1 : 0)}px;
  left: ${({ block, daysDisplayed }) => (100 * block.from) / daysDisplayed}%;
  width: ${({ block, daysDisplayed }) =>
    (100 * (block.to - block.from)) / daysDisplayed}%;
  pointer-events: ${({ block }) => (block.isOutsideView ? 'none' : 'auto')};
  background-color: ${({ block }) => block.colors.default};
  box-shadow: ${({ isSelected }) =>
    isSelected ? '0 4px 7px -3px rgba(44, 66, 143, 1)' : '0 0 0 0 #0f1121'};
  z-index: ${({ isSelected }) => (isSelected ? 2 : 1)};
  border: 3px solid ${({ block }) => block.colors.selected};
  transition: all 0.3s ease;
  transform: skewX(-45deg);

  &:hover {
    box-shadow: 0 4px 7px -3px rgba(44, 66, 143, 1);
    height: ${({ $height }) => $height + 1}px;
  }

  > * {
    transform: skewX(45deg) translateX(1px);
  }
`

const Content = styled.div`
  position: absolute;
  width: 100%;
  color ${({ block }) => block.colors.text};
  font-size: ${({ isSelected }) => (isSelected ? '15px' : 'inherit')};
  font-weight: ${({ isSelected }) => (isSelected ? 'bold' : 'inherit')};
  transition: all 0.3s ease;
  z-index: 20;
`

export interface Props<TBlockData> {
  block: TimelineBlock<TBlockData>
  className?: string
  children?: ReactNode
  height: number
  isSelected?: boolean
  onClick?: (block: TimelineBlock<TBlockData>) => void
  daysDisplayed: number
}

export const AngleBlock = <TBlockData,>({
  block,
  className,
  children,
  height,
  isSelected,
  onClick,
  daysDisplayed,
}: Props<TBlockData>) => {
  const isInViewLeft = block.to >= 0
  return (
    <Container
      block={block}
      onClick={() => {
        if (onClick) {
          onClick(block)
        }
      }}
    >
      <Block
        block={block}
        className={className}
        isSelected={isSelected}
        $height={height}
        daysDisplayed={daysDisplayed}
      >
        <Content
          block={block}
          isSelected={isSelected}
          daysDisplayed={daysDisplayed}
          isInViewLeft={isInViewLeft}
        >
          {children}
        </Content>
      </Block>
    </Container>
  )
}
