// @flow
// $FlowOptOut
import React, { useState } from 'react'

import { typography, colors } from '@elder/common'
import {
  PushPinOutlined,
  ArchiveOutlined,
  EditOutlined,
  CalendarMonthOutlined,
  EmailOutlined,
} from '@mui/icons-material'
import { Button, Chip, Stack } from '@mui/material'
import { OverflowDetector } from 'react-overflow'
import styled, { css } from 'styled-components'

import { Instants } from 'domain/instant'
import { DisplayMarkdownAsHtml } from 'features/notes/DisplayMarkdownAsHtml'
import type { Note } from 'features/notes/domain'

const Card = styled.div`
  display: flex;
  flex-direction: column;
  border-top: solid 8px
    ${({ borderColour = colors.blue700 } = {}) => borderColour};
  box-shadow: 1px 1px 5px 0 rgba(36, 40, 41, 0.3);
  padding: 16px;
  border-radius: 4px;
  word-break: break-word;
`

const Title = styled.div`
  ${typography.paragraph1Bold};
`

const Header = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: 16px;
`

const Footer = styled.div`
  display: flex;
  flex-wrap: nowrap;
  margin-top: 16px;
`

const SpaceFiller = styled.div`
  flex-grow: 10;
`

const StyledCalendar = styled(CalendarMonthOutlined)`
  margin-right: 4px;
`

const StyledEmail = styled(EmailOutlined)`
  margin-right: 4px;
`

const IconAndTextWithMargin = styled.div`
  display: flex;
  align-items: flex-end;
  flex-shrink: 0;
  margin-right: 8px;
`

const Tags = styled.div`
  display: flex;
  flex-wrap: wrap;
  > * {
    margin: 0 4px;
  }
`

const LeftPin = styled(PushPinOutlined)`
  margin-right: 8px;
`

const StyledCard = styled(Card)`
  ${({ archived }) =>
    archived &&
    css`
      background-color: ${colors.dirtyWhite};
    `};
`

const StyledSecondaryButton = styled(Button)`
  display: inline-block;
  margin: 16px auto;
`

const ControlOverflow = styled(OverflowDetector)`
  position: relative;
  overflow: hidden;
  ${({ minimisationStatus }) =>
    minimisationStatus !== 'FULL' &&
    css`
      max-height: 150px;
    `};
  ${({ minimisationStatus }) =>
    minimisationStatus === 'MINIMISED' &&
    css`
      ::after {
        content: '';
        height: 30px;
        width: 100%;
        position: absolute;
        top: 120px;
        left: 0;
        background-image: ${({ backgroundColour }) =>
          `linear-gradient(to bottom, transparent, ${backgroundColour})`};
      }
    `};
`

type Props = {|
  +className?: string,
  +pinNote: () => void,
  +unpinNote: () => void,
  +archiveNote: () => void,
  +unarchiveNote: () => void,
  +editNote: () => void,
  +disableActions: boolean,
  ...Note,
|}

type MinimisationStatus = 'NONE' | 'FULL' | 'MINIMISED'

export const NoteCard = ({
  title,
  text,
  createdBy,
  createdAt,
  tags,
  id,
  pinned,
  archived,
  unpinNote,
  pinNote,
  archiveNote,
  unarchiveNote,
  editNote,
  disableActions,
  className,
}: Props) => {
  const [minimisationStatus, setMinimisationStatus] = useState('NONE')
  const [isOpen, setIsOpen] = useState(false)

  const typedSetMinimisationStatus: (MinimisationStatus) => void =
    setMinimisationStatus

  let borderColour = colors.blue700

  if (archived) {
    borderColour = colors.lightSmoke
  } else if (pinned) {
    borderColour = colors.successDark
  }

  return (
    <StyledCard
      className={className}
      borderColour={borderColour}
      archived={archived}
    >
      <Header>
        {pinned && <LeftPin sx={{ color: colors.successDark }} />}
        <Title>{title}</Title>
        <SpaceFiller />
        <Stack direction="row">
          {id !== 'migrated-internal-notes' && (
            <Button
              disabled={disableActions}
              onClick={editNote}
              endIcon={<EditOutlined />}
            >
              Edit
            </Button>
          )}
          {pinned ? (
            <Button
              disabled={disableActions}
              onClick={unpinNote}
              endIcon={<PushPinOutlined />}
            >
              Unpin note
            </Button>
          ) : (
            <Button
              disabled={disableActions}
              onClick={pinNote}
              endIcon={<PushPinOutlined />}
            >
              Pin note
            </Button>
          )}
          {archived ? (
            <Button
              disabled={disableActions}
              onClick={unarchiveNote}
              endIcon={<ArchiveOutlined />}
            >
              Unarchive note
            </Button>
          ) : (
            <Button
              disabled={disableActions}
              onClick={archiveNote}
              endIcon={<ArchiveOutlined />}
            >
              Archive note
            </Button>
          )}
        </Stack>
      </Header>
      <ControlOverflow
        backgroundColour={archived ? colors.dirtyWhite : colors.white}
        minimisationStatus={minimisationStatus}
        onOverflowChange={(isOverflowing) => {
          if (isOpen && !isOverflowing) {
            setMinimisationStatus('FULL')
          } else if (isOverflowing) {
            setMinimisationStatus('MINIMISED')
          }
        }}
      >
        <DisplayMarkdownAsHtml markdown={text} />
      </ControlOverflow>
      {minimisationStatus === 'MINIMISED' && (
        <StyledSecondaryButton
          onClick={() => {
            setIsOpen(true)
            typedSetMinimisationStatus('FULL')
          }}
          variant="outlined"
        >
          Show full note
        </StyledSecondaryButton>
      )}
      {minimisationStatus === 'FULL' && (
        <StyledSecondaryButton
          onClick={() => {
            setIsOpen(false)
            typedSetMinimisationStatus('MINIMISED')
          }}
          variant="outlined"
        >
          Hide full note
        </StyledSecondaryButton>
      )}
      <Footer>
        <Tags>
          {tags.map((tag) => (
            <Chip label={tag} key={tag} />
          ))}
        </Tags>
        <SpaceFiller />
        <IconAndTextWithMargin>
          <StyledCalendar />
          {Instants.toLongDateAndTime(Instants.ofString(createdAt))}
        </IconAndTextWithMargin>
        <IconAndTextWithMargin>
          <StyledEmail />
          {createdBy}
        </IconAndTextWithMargin>
      </Footer>
    </StyledCard>
  )
}
