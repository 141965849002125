// @flow
import React from 'react'

import { FormikPlainRadioButtons } from '@elder/common'
import { LoadingButton } from '@mui/lab'
import { Button } from '@mui/material'
import { Formik } from 'formik'

import { StyledFormForGlobalOverride } from 'components/forms/StyledFormForGlobalOverride'
import { FormButtons } from 'components/styledComponentsTranslatedClasses/FormButtons'
import { Section } from 'components/styledComponentsTranslatedClasses/Section'
import {
  booleanOptions,
  setValueBoolean,
  transformStringToBoolean,
} from 'utils/groupOptions'

type Props = {|
  +paymentAssuranceRequired: boolean,
  +isUpdating: boolean,
  +onCancel: () => void,
  +onUpdate: (boolean) => void,
|}

export const PaymentAssuranceRequiredForm = ({
  paymentAssuranceRequired,
  isUpdating,
  onCancel,
  onUpdate,
}: Props) => {
  const initialValues = {
    paymentAssuranceRequired: setValueBoolean(paymentAssuranceRequired),
  }
  return (
    <Section>
      <Formik
        initialValues={initialValues}
        onSubmit={(data) =>
          onUpdate(transformStringToBoolean(data.paymentAssuranceRequired))
        }
      >
        {() => (
          <StyledFormForGlobalOverride>
            <FormikPlainRadioButtons
              name="paymentAssuranceRequired"
              label="Payment assurance required?"
              options={booleanOptions}
              isInline
            />
            <FormButtons>
              <Button onClick={onCancel} variant="text">
                Cancel
              </Button>
              <LoadingButton
                type="submit"
                loading={isUpdating}
                variant="contained"
              >
                Save
              </LoadingButton>
            </FormButtons>
          </StyledFormForGlobalOverride>
        )}
      </Formik>
    </Section>
  )
}
