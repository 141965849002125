// @flow
import { combineReducers } from 'redux'

import { SET_SF_ID } from './actions'

const initialState = ''

export const accountSalesforceReducer = (
  state: string = initialState,
  action: Object,
) => {
  switch (action.type) {
    case SET_SF_ID:
      return action.sfId
    default:
      return state
  }
}

export default combineReducers({
  accountSalesforceReducer,
})
