// @flow
import { object, string } from 'flow-validator'

export const name = object({
  firstName: string.optional(),
  lastName: string.optional(),
  salutation: string.optional(),
})
export type Name = {|
  +firstName: string,
  +lastName: string,
  +salutation?: string,
|}
