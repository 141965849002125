// @flow
import type { Node } from 'react'
import React from 'react'

import styled from 'styled-components'

const Wrapper = styled.div`
  border-bottom: 1px solid #b4bcca;
  display: flex;
  flex-direction: column;
  padding: 8px 0;
  font-size: 12px;

  &:last-child {
    border-bottom: 0;
  }

  .tfe--disabled {
    opacity: 0.5;
  }

  .tfe__title {
    color: #73819c;
    font-size: 12px;
    margin-bottom: 6px;
  }

  .tfe__wrapper {
    display: flex;
    flex-wrap: wrap;
  }

  .is-open .Select-control {
    border-color: #19305e;
  }

  .Select-placeholder {
    color: #cad9f8;
  }

  .Select-arrow,
  .Select-arrow-zone:hover > .Select-arrow {
    border-top-color: #cad9f8;
  }

  .is-open > .Select-control .Select-arrow {
    border-bottom-color: #cad9f8;
  }

  /**
 * react-select related
 */
  .Select-control {
    transition: border-color 0.3s ease;
    border: 2px solid #cad9f8;
    border-radius: 0px;

    &:hover {
      box-shadow: 0 0 0 0;
    }
  }
`

type Props = {|
  +title: string,
  +disabled?: boolean,
  +inlined?: boolean,
  +children?: Node,
|}

export const TableFiltersElement = ({
  title,
  disabled = false,
  inlined = false,
  children,
}: Props) => {
  const mainStyle = disabled ? ' tfe--disabled' : ''
  const wrapperStyle = inlined ? 'tfe__wrapper' : ''

  return (
    <Wrapper className={mainStyle}>
      <p className="tfe__title">{title}</p>
      <div className={wrapperStyle}>{children || null}</div>
    </Wrapper>
  )
}

export default TableFiltersElement
