// @flow

import React from 'react'

import { ModalDialog } from 'components'
import type { NoteRequestParams, Template } from 'features/notes/domain'

import { AddNoteDialog } from './AddNoteDialog'

export type Props = {|
  +closeDialog: () => void,
  +saveNote: (NoteRequestParams) => void,
  +saving: boolean,
  +templates?: Array<Template>,
|}

export const AddNoteModal = ({
  closeDialog,
  saveNote,
  saving,
  templates,
}: Props) => (
  <ModalDialog heading="Add new note">
    <AddNoteDialog
      cancel={closeDialog}
      saveNote={saveNote}
      saving={saving}
      templates={templates}
    />
  </ModalDialog>
)
