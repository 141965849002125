// @flow

import { makeSelectors as makeButtonSpinnerSelectors } from 'modules/buttonSpinner'

import { NAMESPACE } from './actions'

const getProfessionalsState = (state) => state.get(NAMESPACE)

export const downloadCsvButtonSpinnerSelectors = makeButtonSpinnerSelectors(
  (state) => getProfessionalsState(state).get('buttonSpinner'),
)
