// @flow

import { type Node } from 'react'

import { connect } from 'react-redux'

import type { EnumMap } from 'elder/types'

import {
  getCarerStudentLoanPlans,
  getCountriesOptions,
  getFullCareCertificateIssuingOrganizationOptions,
  getSolutionEndingReasonsOptions,
  getSolutionPlansOptions,
  getSolutionTariffOptions,
  getVisaTypesOptions,
  getCertificationProviderOptions,
  getCertificationOptions,
} from 'app/referenceData'
import type { Option } from 'domain/option'

export type SupportedRefDataTypes =
  | 'fullCareCertificateIssuingOrganisations'
  | 'countries'
  | 'carerStudentLoanPlans'
  | 'visaType'
  | 'solutionPlans'
  | 'solutionTariffs'
  | 'solutionEndingReasons'
  | 'certificationProvider'
  | 'certification'

const selectorMapper: EnumMap<SupportedRefDataTypes, Function> = {
  fullCareCertificateIssuingOrganisations:
    getFullCareCertificateIssuingOrganizationOptions,
  countries: getCountriesOptions,
  carerStudentLoanPlans: getCarerStudentLoanPlans,
  visaType: getVisaTypesOptions,
  solutionPlans: getSolutionPlansOptions,
  solutionTariffs: getSolutionTariffOptions,
  solutionEndingReasons: getSolutionEndingReasonsOptions,
  certificationProvider: getCertificationProviderOptions,
  certification: getCertificationOptions,
}

type OwnProps = {|
  +render: ({ options: Array<Option> }) => Node,
  +name: SupportedRefDataTypes,
|}

type Props = $ReadOnly<{|
  +refData: Array<Option>,
  ...OwnProps,
|}>

const WithRefDataImpl = ({ refData = [], render }: Props) =>
  render({ options: refData })

const mapActionCreators = {}

const mapStateToProps = (state, ownProps: OwnProps) => ({
  refData: selectorMapper[ownProps.name](state).options,
})

export const WithRefData = connect(
  mapStateToProps,
  mapActionCreators,
)(WithRefDataImpl)
