// @flow
import React from 'react'

import type { ReferenceDataOptions } from '@elder/common'

import type { Contact } from 'domain/contacts'
import type { ActiveWorkflow } from 'features/activeWorkflow/types'
import type { ProfileSuggestion } from 'routes/account/contacts/ContactsContainer'
import { EditModal } from 'routes/account/contacts/EditModal'
import { UsersTable } from 'routes/account/contacts/UsersTable'

type Props = {|
  +contacts: Array<Contact>,
  +activeWorkflow: ActiveWorkflow,
  +setActiveWorkflow: (string, ?string) => void,
  +displayContactRole: (string) => string,
  +contactRolesOptions: ReferenceDataOptions,
  +updateContacts: ({| +contacts: Array<Contact> |}) => void,
  +profileSuggestions: Array<ProfileSuggestion>,
  +updateProfileSuggestions: (string) => void,
  +loadingProfileSuggestions: boolean,
  +canEdit: boolean,
|}
const ContactsSummary = ({
  contacts,
  activeWorkflow,
  setActiveWorkflow,
  displayContactRole,
  contactRolesOptions,
  updateContacts,
  profileSuggestions,
  updateProfileSuggestions,
  loadingProfileSuggestions,
  canEdit,
}: Props) => (
  <>
    <UsersTable
      openModal={() => setActiveWorkflow('editContactsModal')}
      contacts={contacts}
      canEdit={canEdit}
      displayContactRole={displayContactRole}
    />
    <EditModal
      contacts={contacts}
      showModal={activeWorkflow.name === 'editContactsModal'}
      closeModal={() => setActiveWorkflow('')}
      contactRolesOptions={contactRolesOptions}
      profileSuggestions={profileSuggestions}
      updateProfileSuggestions={updateProfileSuggestions}
      loadingProfileSuggestions={loadingProfileSuggestions}
      submit={(data) => {
        updateContacts(data)
      }}
    />
  </>
)

export default ContactsSummary
