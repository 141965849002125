// @flow
import { makeAction } from 'elder/actions'

import { addNamespace } from 'utils'

export const NAMESPACE = 'CARER_PREFERENCES'

export const SET_COMMUNICATION_PREFERENCES = addNamespace(
  NAMESPACE,
  'SET_COMMUNICATION_PREFERENCES',
)

export const setCommunicationPreferences = (
  preferences: Object,
  carerId: string,
) => ({
  type: SET_COMMUNICATION_PREFERENCES,
  preferences,
  carerId,
})

export const GET_COMMUNICATION_PREFERENCES = addNamespace(
  NAMESPACE,
  'GET_COMMUNICATION_PREFERENCES',
)

export const getCommunicationPreferences = makeAction(
  GET_COMMUNICATION_PREFERENCES,
  (carerId: string) => ({
    carerId,
  }),
)
