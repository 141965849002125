// @flow
// $FlowOptOut
import React, { useEffect } from 'react'

import { ContentPage } from 'components'
import { BreadcrumbsBar } from 'features/header/BreadcrumbsBar'
import * as routes from 'routes'

import ProfessionalNewForm from './ProfessionalNewForm'

type Props = {|
  +clearValidation: () => void,
  +cancelEdit: () => void,
  +createNew: () => void,
  validationErrors: {|
    +firstName?: ?Array<string>,
    +lastName?: ?Array<string>,
    +phone?: ?Array<string>,
    +email?: ?Array<string>,
    +address?: ?Array<string>,
    +city?: ?Array<string>,
    +postcode?: ?Array<string>,
    +bankAccount?: ?Array<string>,
    +sortCode?: ?Array<string>,
  |},
|}

export const ProfessionalNew = ({
  cancelEdit,
  createNew,
  validationErrors,
  clearValidation,
}: Props) => {
  useEffect(() => {
    clearValidation()
  }, [])
  return (
    <>
      <BreadcrumbsBar
        breadcrumbs={[
          { title: 'Carers', to: routes.professionals },
          { title: 'New', to: routes.carersNew },
        ]}
      />
      <ContentPage>
        <h2>Creating a new professional</h2>
        <ProfessionalNewForm
          createNewProfessional={createNew}
          cancelEdit={cancelEdit}
          validationErrors={validationErrors}
        />
      </ContentPage>
    </>
  )
}
