// @flow
import React from 'react'

import { colors, spacings } from '@elder/common'
import styled, { css } from 'styled-components'

const Wrapper = styled.div`
  ${({ horizontal }) =>
    horizontal &&
    css`
      margin-right: ${spacings.small};
    `}
`

const Input = styled.input`
  ${({ displayError }) =>
    displayError &&
    // !important is needed to override the global styles
    css`
      border-color: ${colors.errorDark} !important;
    `}
  transition: border-color .3s ease;
  background-color: #ffffff;
  border: solid 2px #cad9f8;
  box-sizing: border-box;
  padding: 8px 10px;
  width: 100%;

  &:focus {
    border: solid 2px #19305e;
  }

  &:disabled {
    opacity: 0.5;
  }

  &::placeholder {
    color: #cad9f8;
    text-transform: capitalize;
  }
`

const Error = styled.div`
  color: ${colors.errorDark};
  display: block;
  width: 100%;
`

type Props = {|
  text: string,
  placeholder: string,
  errorMessage?: string,
  horizontal?: boolean,
  onChange: (string) => void,
|}

export const FilterSearchBox = ({
  text,
  placeholder,
  errorMessage,
  horizontal,
  onChange,
}: Props) => {
  const onChangeHandle = (e) => {
    onChange(e.target.value)
  }

  const displayError = errorMessage && errorMessage.length

  return (
    <Wrapper horizontal={horizontal}>
      <Input
        displayError={displayError}
        placeholder={placeholder}
        value={text}
        type="text"
        onChange={onChangeHandle}
      />
      {displayError && <Error>{errorMessage}</Error>}
    </Wrapper>
  )
}
