// @flow
import type { Saga } from 'redux-saga'
import { take, call, select, put } from 'redux-saga/effects'

import { applicationSuccess } from 'features/snackbar/snackbar'
import {
  UPDATE_ACCT_ENV,
  updateAccountEnvironmentValidationFail,
  pageActions,
} from 'routes/account/environment/actions'
import { expectedUpdateFields } from 'routes/account/environment/validFields'
import { pageSelectors } from 'routes/account/selectors'
import { manageErrorWithFieldValidation } from 'utils/fieldValidationErrors'
import { fetchFlow } from 'utils/services'

export function* putAccountEnvironment(
  data: Object,
): Generator<Object, void, *> {
  const acctId = yield select(pageSelectors.getId)
  const endpoint = `/et/accounts/${acctId}/environment`
  const method = 'PUT'

  const reformedData = {
    environment: data.accountOverview,
  }

  // There is a difference bewteen NONE and null. None is a choice that is represented by
  // [] from BE. If sending null it means no choice has been made.
  if (!reformedData.environment.pets || !reformedData.environment.pets.length) {
    reformedData.environment.pets = null
  } else if (reformedData.environment.pets.indexOf('NONE') !== -1) {
    reformedData.environment.pets = []
  }

  try {
    const responseBody = yield call(fetchFlow, endpoint, method, reformedData)
    yield put(pageActions.set(responseBody))
    applicationSuccess({
      title: 'Account updated',
      message: 'Account environment was updated successfully.',
    })
  } catch (error) {
    yield call(
      manageErrorWithFieldValidation,
      error,
      'acctEnv',
      expectedUpdateFields,
      updateAccountEnvironmentValidationFail,
    )
  }
}

export function* watchAccountEnvironment(): Saga<*> {
  while (true) {
    const payload = yield take(UPDATE_ACCT_ENV)
    yield call(putAccountEnvironment, payload.data)
  }
}
