// @flow
import React from 'react'

import styled from 'styled-components'

const ChangelogTable = styled.table`
  border-collapse: collapse;
  width: 100%;

  .cl__head {
    tr > th {
      border-bottom: 2px solid #19305e;
      font-size: 21px;
      padding: 8px 16px;
      text-align: left;
      text-transform: capitalize;
    }
  }

  .cl__body {
    tr > td {
      border-bottom: 1px solid #eaf0fc;
      padding: 8px 16px;
    }
  }

  .cl__description {
    white-space: pre-wrap;
  }
`

type Props = {|
  +changelogs: Array<{|
    +user: string,
    +impersonator: string,
    +timestamp: string,
    +description: string,
  |}>,
|}

export const Changelogs = ({ changelogs }: Props) => (
  <ChangelogTable>
    <thead className="cl__head">
      <tr>
        <th>User</th>
        <th>Impersonator</th>
        <th>Timestamp</th>
        <th>Change</th>
      </tr>
    </thead>
    <tbody className="cl__body">
      {changelogs.map((changelog, index) => (
        // eslint-disable-next-line react/no-array-index-key
        <tr key={index.toString()}>
          <td>{changelog.user}</td>
          <td>{changelog.impersonator}</td>
          <td>{changelog.timestamp}</td>
          <td className="cl__description">{changelog.description}</td>
        </tr>
      ))}
    </tbody>
  </ChangelogTable>
)

export default Changelogs
