import React from 'react'

import styled from 'styled-components'

import type { TimelineBlock } from 'components/solutions/SolutionTimeline/SolutionTimeline'

interface Props<TBlockData> {
  block: TimelineBlock<TBlockData>
  onClick?: (block: TimelineBlock<TBlockData>) => void
}

const StyledDot = styled.button`
  border: 1px solid #0F1121;
  background: ${({ block }) => block.colors.default};
  border-radius: 10px;
  cursor: pointer;
  height: 13px;
  margin: 0 5px;
  min-height: 13px;
  min-width: 13px;
  width: 13px;
  z-index: 1;
  padding: 0;

  &:disabled {
    cursor: default
  }
}`

export const Dot = <TBlockData,>({ block, onClick }: Props<TBlockData>) => {
  const disabled = !onClick
  return (
    <StyledDot
      block={block}
      disabled={disabled}
      onClick={() => {
        if (block && onClick) {
          onClick(block)
        }
      }}
    />
  )
}
