// @flow
import React from 'react'

import type { Option } from '@elder/common'
import { ExpandableCard } from '@elder/common'
import { connect } from 'react-redux'

import { getCustomerCommunicationCategories } from 'app/referenceData'
import { CommunicationPreferences } from 'features/relatedAccounts/components/CommunicationPreferences'
import { RelatedAccountOverview } from 'features/relatedAccounts/components/RelatedAccountOverview'
import { updateProfileCommunicationPreferences as updateProfileCommunicationPreferencesAction } from 'features/relatedAccounts/store/actions'
import { getCustomerRelatedAccounts } from 'features/relatedAccounts/store/selectors'

import type {
  CommunicationPreferencesToUpdate,
  RelatedAccountData,
} from './types'

const mapActionCreators = (dispatch) => ({
  updateProfileCommunicationPreferences: (
    profileId,
    accountId,
    communicationPreferences,
  ) =>
    dispatch(
      updateProfileCommunicationPreferencesAction(
        profileId,
        // $FlowOptOut
        accountId,
        communicationPreferences,
      ),
    ),
})

const mapStateToProps = (state) => ({
  customerCommunicationTypes: getCustomerCommunicationCategories(state).options,
  relatedAccounts: getCustomerRelatedAccounts(state),
})

type Props = {|
  +expandedRelatedAccountIndex: boolean,
  +setExpandedRelatedAccountIndex: (number) => void,
  +updateProfileCommunicationPreferences: (CommunicationPreferencesToUpdate) => void,
  +customerCommunicationTypes: Array<Option>,
  +relatedAccounts: Array<RelatedAccountData>,
  +displayContactRole: (string) => void,
|}

export const RelatedAccountsContainer = connect(
  mapStateToProps,
  mapActionCreators,
)(
  ({
    expandedRelatedAccountIndex,
    setExpandedRelatedAccountIndex,
    updateProfileCommunicationPreferences,
    relatedAccounts,
    customerCommunicationTypes,
    displayContactRole,
  }: Props) =>
    relatedAccounts.map((relatedAccount, index) => (
      <ExpandableCard
        // eslint-disable-next-line react/no-array-index-key
        key={index}
        overviewComponent={
          <RelatedAccountOverview
            accountId={relatedAccount.customerId}
            relationship={relatedAccount.relationship || ''}
            roles={relatedAccount.roles}
            displayContactRole={displayContactRole}
          />
        }
        expandedComponent={
          <div>
            <CommunicationPreferences
              customerCommunicationTypes={customerCommunicationTypes}
              communicationPreferences={relatedAccount.communicationPreferences}
              onSaveCommunicationPreferences={
                updateProfileCommunicationPreferences
              }
              accountId={relatedAccount.customerId}
              profileId={relatedAccount.profileId}
              setExpandedRelatedAccountIndex={setExpandedRelatedAccountIndex}
              index={index}
            />
          </div>
        }
        showExpandText={false}
        expanded={index === expandedRelatedAccountIndex}
      />
    )),
)
