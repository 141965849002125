// @flow
import { refDataEnum } from '@elder/common'
import {
  object,
  string,
  boolean,
  isAny,
  arrayOf,
  mapping,
  number,
} from 'flow-validator'

import { endpointBuilder } from 'elder/services'

import { localDate } from 'domain/dates'
import { carerMatchingBasicInfo } from 'features/carerMatching/domain'

const updateAccountManagerRequest = object({
  carerId: string,
  accountManagerEmail: string.optional(),
})

const professional = object({
  accountActivityOverview: isAny.optional(),
  accountManager: string.optional(),
  approvedForRegulated: boolean.optional(),
  availability: isAny.optional(),
  bankDetails: object({
    sortCode: string.optional(),
    bankAccount: string.optional(),
    nationalInsuranceNumber: string.optional(),
  }),
  basicInfo: isAny.optional(),
  benefitTierData: object({
    currentBenefitTier: string.optional(),
    currentBenefitTierSinceDate: localDate.optional(),
    nextBenefitTierPeriodWorkedDays: number,
    benefitTierHistory: mapping(
      localDate,
      object({
        benefitTier: string.optional(),
        qualifyingWorkingDays: number,
      }),
    ),
  }).optional(),
  carerDetails: isAny.optional(),
  carerProfilePdfAvailable: boolean.optional(),
  changeSummary: object({
    lastChangedAt: string.optional(),
    lastChangedBy: string.optional(),
    lastChangeDescription: string.optional(),
  }),
  churnDescription: string.optional(),
  churnReason: string.optional(),
  communicationPreferences: isAny.optional(),
  createdAt: isAny.optional(),
  isHiddenInPublicCarerSearch: boolean.optional(),
  professionalId: string.optional(),
  restrictions: isAny.optional(),
  reviews: arrayOf(isAny),
  skillsAndExpertise: object({
    careerStartDate: string.optional(),
    careExpertise: arrayOf(string).optional(),
    conditionExpertise: arrayOf(string).optional(),
  }),
  starred: boolean.optional(),
  status: string.optional(),
  testimonials: isAny.optional(),
  updatedAt: isAny.optional(),
  workerStatus: refDataEnum.optional(),
  covidVaccinationSummary: object({
    fullVaccinationStatus: refDataEnum.optional(),
    doseType: refDataEnum.optional(),
    doseDate: string.optional(),
  }).optional(),
})

export const carerBasicInfoService = {
  getProfessional: endpointBuilder(string, professional)
    .withUrlTemplate('/et/professionals/{carerId}', (carerId) => ({ carerId }))
    .build(),
  updateAccountManager: endpointBuilder(
    updateAccountManagerRequest,
    carerMatchingBasicInfo,
  )
    .withHttpMethod('PUT')
    .withBodyBuilder(({ accountManagerEmail }) => ({ accountManagerEmail }))
    .withUrlTemplate(
      '/et/professionals/{carerId}/accountManager',
      ({ carerId }) => ({
        carerId,
      }),
    )
    .build(),
}
