// @flow
import Immutable from 'immutable'

import { addNamespace } from 'utils'

import { TOGGLE_FILTER, SET_FILTER, SET_COMPLEX_FILTER } from './actions'

export const makeReducer = (namespace: string, filterRecord: Object) => {
  const toggleFilter = addNamespace(namespace, TOGGLE_FILTER)
  const setFilter = addNamespace(namespace, SET_FILTER)
  const setComplexFilter = addNamespace(namespace, SET_COMPLEX_FILTER)

  return (state: Object = filterRecord, action: Object) => {
    switch (action.type) {
      case toggleFilter:
        return state.set(action.id, !state[action.id])
      case setFilter:
        return state.set(action.id, action.value)
      case setComplexFilter:
        return state.set(action.id, Immutable.fromJS(action.objectOrArray))
      default:
        return state
    }
  }
}

export default makeReducer
