// @flow
// $FlowOptOut
import React, { useEffect, useState } from 'react'

import { Box, Button } from '@mui/material'
import PropTypes from 'prop-types'

import {
  Instructions,
  Value,
  Entry,
  Heading,
  Indicator,
} from 'components/form-2018'
import { rptOptionsExtended } from 'utils/reactHelpers'

import AccountEnvironmentForm from './forms/AccountEnvironmentForm'

const AcctEnv = (environment, canEdit, editSection, editMode, setEditMode) => (
  <Box mt={2} sx={{ display: 'flex', flexDirection: 'column' }}>
    <Button
      onClick={() => setEditMode(true)}
      disabled={!canEdit || editMode}
      variant="contained"
      disableElevation
      sx={{ alignSelf: 'flex-start' }}
    >
      Edit
    </Button>
    <Heading heading="Care Environment">
      <Indicator completed={environment.completed2018} />
    </Heading>

    <Entry>
      <h4>Address</h4>
      <Value
        value={environment.careLocation.map((value, index) => (
          // eslint-disable-next-line react/no-array-index-key
          <div key={index.toString()}>{value}</div>
        ))}
      />
    </Entry>

    <Entry>
      <h4>Address instructions</h4>
      <Value value={environment.addressInfo} />
    </Entry>

    <Heading heading="Household" />

    <Entry>
      <h4>Household Description</h4>
      <Instructions>
        Please describe the household and property. e.g. who lives in the home,
        description of the property, and its immediate surroundings.
      </Instructions>
      <Value value={environment.householdDescription} />
    </Entry>

    <Entry>
      <h4>Number of spare bedrooms</h4>
      <Instructions>
        A spare bedroom is required for a live-in carer.
      </Instructions>
      <Value value={environment.prettySpareBedrooms} />
    </Entry>
    <Entry>
      <h4>Wifi</h4>
      <Instructions>
        Elder recommends providing wifi in the home so that carers can keep in
        touch with family, friends, and Elder.
      </Instructions>
      <Value value={environment.prettyWifi} />
    </Entry>
    <Entry>
      <h4>Smoking</h4>
      <Instructions>Does anybody in the house smoke inside?</Instructions>
      <Value value={environment.prettySmoking} />
    </Entry>
    <Entry>
      <h4>Pets</h4>
      <Instructions>
        Please provide a description of any animals as well as any assistance
        required of the carer.
      </Instructions>
      <Value value={`${environment.prettyPets.join(', ')}.`} />
      <Value value={environment.petsText} />
    </Entry>
    <Heading heading="Domestic Support" />
    <Entry>
      <h4>Domestic support description</h4>
      <Instructions>
        Please describe the domestic arrangements and support required from the
        carer (e.g. any assistance with laundry and housework)
      </Instructions>
      <Value value={environment.domesticSupportDescription} />
    </Entry>
    <Entry>
      <h4>Groceries</h4>
      <Instructions>
        Please describe how the household food will be provided and what support
        is required from the carer (e.g. assistance with shopping, paying for
        groceries)
      </Instructions>
      <Value value={environment.groceries} />
    </Entry>
    <Entry>
      <h4>Food for carer</h4>
      <Instructions>
        As part of the Elder service, customers are expected to provide food for
        the carer in household shopping, or to provide a separate budget of £40
        per week for the carer.
      </Instructions>
      <Value value={environment.prettyCarerFood} />
    </Entry>
    <Entry>
      <h4>Carer Break Cover:</h4>
      <Instructions>
        Elder carers work an average of eight to ten (not necessarily
        consecutive) hours a day but will always be on hand when you need them.
        To ensure that they are well-rested and fit to take care of your loved
        one, it is a statutory requirement that our carers are able to take
        breaks of up to two hours each day when it is safe to do so.
      </Instructions>
      <Instructions>
        Describe any considerations for how carers should take their breaks
        during the day. (e.g. preferred times, to be mutually agreed on arrival,
        cover provision if care recipient cannot be left alone)
      </Instructions>
      <Value value={environment.carerBreakDescription} />
    </Entry>
  </Box>
)

export const AccountEnvironment = ({
  environment,
  canEdit,
  editSection,
  options,
  getEnvironment,
}: any) => {
  useEffect(() => {
    getEnvironment()
  }, [])
  const [editMode, setEditMode] = useState(false)
  return editMode ? (
    <AccountEnvironmentForm
      environment={environment}
      options={options}
      setEditMode={setEditMode}
    />
  ) : (
    AcctEnv(environment, canEdit, editSection, editMode, setEditMode)
  )
}

AccountEnvironment.propTypes = {
  environment: PropTypes.shape({
    completed2018: PropTypes.bool,
    internalNotes: PropTypes.string,
    careLocation: PropTypes.arrayOf(PropTypes.string),
    prettyDrivingRequirements: PropTypes.string,
    prettyWifi: PropTypes.string,
    prettyPhoneReception: PropTypes.string,
    prettyPets: PropTypes.arrayOf(PropTypes.string),
    prettySmoking: PropTypes.string,
    prettyCarerFood: PropTypes.string,
    householdDescription: PropTypes.string,
    domesticSupportDescription: PropTypes.string,
    groceries: PropTypes.string,
    carerBreakDescription: PropTypes.string,
    carerBreakCoverNeeded: PropTypes.bool,
    prettySpareBedrooms: PropTypes.string,
    prettyEthnicitiesAtRisk: PropTypes.string,
  }).isRequired,
  canEdit: PropTypes.bool,
  editSection: PropTypes.func.isRequired,
  getEnvironment: PropTypes.func.isRequired,
  options: PropTypes.shape({
    bedroomType: rptOptionsExtended,
    bathroomType: rptOptionsExtended,
    pets: rptOptionsExtended,
    driving: rptOptionsExtended,
  }),
}
