// @flow
import type { Saga } from 'redux-saga'

import { takeLatest, put, call } from 'elder/effects'

import { notesClient } from 'app/saga/serviceClients'
import { clearLoading, setLoading } from 'features/loading/actions'
import { loadingId } from 'features/notes/EditNoteModalContainer'
import {
  getNotes,
  saveNote,
  setPinnedNote,
  setArchived,
  editNote,
  getNotesCompleted,
  savingNote,
  savingNoteFailed,
  savingNoteCompleted,
  completeAction,
  getNotesFailed,
  editNoteModal,
} from 'features/notes/store/actions'
import {
  applicationError,
  applicationSuccess,
} from 'features/snackbar/snackbar'
import { describeServiceError } from 'utils/services'

function* handleGetNotes({
  notesEntityType,
  notesEntityId,
}: {
  +notesEntityType: string,
  +notesEntityId: string,
}): Saga<*> {
  try {
    const { notes } = yield* call(notesClient.getNotes, {
      type: notesEntityType,
      id: notesEntityId,
    })
    yield* put(
      getNotesCompleted({
        notesEntityType,
        notesEntityId,
        notes,
      }),
    )
  } catch (error) {
    yield* put(getNotesFailed({ notesEntityType, notesEntityId }))
    applicationError(describeServiceError(error, 'Unable to get notes'))
  }
}

function* handleSaveNote({
  notesEntityType,
  notesEntityId,
  noteParams,
  onSuccess,
}): Saga<*> {
  yield* put(savingNote())
  try {
    // TODO: get display text for entity. Once we start linking notes to other accounts this is required.
    const request = {
      ...noteParams,
      parentEntity: {
        type: notesEntityType,
        identifier: notesEntityId,
        displayText: '',
      },
      relatedEntities: [],
    }
    yield* call(notesClient.saveNote, request)
    yield* call(handleGetNotes, { notesEntityType, notesEntityId })
    applicationSuccess({
      title: 'Note saved',
      message: 'Note was created successfully',
    })

    if (onSuccess) {
      yield* call(onSuccess)
    }
  } catch (error) {
    yield* put(savingNoteFailed())
    applicationError(describeServiceError(error, 'Unable to create note'))
  } finally {
    yield* put(savingNoteCompleted())
  }
}

function* handleSetPinnedNote({
  notesEntityType,
  notesEntityId,
  noteId,
  pinned,
}): Saga<*> {
  try {
    const { notes } = yield* call(notesClient.pinNote, {
      noteId,
      pinned,
      pinEntity: { entityType: notesEntityType, entityId: notesEntityId },
    })

    yield* put(
      getNotesCompleted({
        notesEntityType,
        notesEntityId,
        notes,
      }),
    )

    applicationSuccess({
      title: 'Success',
      message: 'Note was pinned',
    })
  } catch (error) {
    applicationError(describeServiceError(error, 'Unable to pin note'))
  } finally {
    yield* put(completeAction({ noteId }))
  }
}

function* handleSetArchivedNote({
  notesEntityType,
  notesEntityId,
  noteId,
  archive,
}): Saga<*> {
  try {
    const { notes } = yield* call(notesClient.archiveNote, {
      noteId,
      archived: archive,
    })

    yield* put(
      getNotesCompleted({
        notesEntityType,
        notesEntityId,
        notes,
      }),
    )

    applicationSuccess({
      title: 'Success',
      message: `Note was ${archive ? 'archived' : 'unarchived'}`,
    })
  } catch (error) {
    applicationError(
      describeServiceError(
        error,
        `Unable to ${archive ? 'archive' : 'unarchive'} note`,
      ),
    )
  } finally {
    yield* put(completeAction({ noteId }))
  }
}

function* handleEditNote({
  notesEntityType,
  notesEntityId,
  noteId,
  noteParams,
}): Saga<*> {
  yield* put(setLoading(loadingId))
  try {
    const request = {
      ...noteParams,
      noteId,
      parentEntity: {
        type: notesEntityType,
        identifier: notesEntityId,
        displayText: '',
      },
    }
    yield* call(notesClient.editNote, request)
    yield* call(handleGetNotes, { notesEntityType, notesEntityId })
    applicationSuccess({
      title: 'Note edited',
      message: 'Note was edited successfully',
    })
    yield* put(editNoteModal({ noteId, isOpen: false }))
  } catch (error) {
    applicationError(describeServiceError(error, 'Unable to create note'))
  } finally {
    yield* put(clearLoading(loadingId))
  }
}

export function* notesSaga(): Saga<*> {
  yield* takeLatest(getNotes, handleGetNotes)
  yield* takeLatest(saveNote, handleSaveNote)
  yield* takeLatest(setPinnedNote, handleSetPinnedNote)
  yield* takeLatest(setArchived, handleSetArchivedNote)
  yield* takeLatest(editNote, handleEditNote)
}
