// @flow
/* eslint-disable react/prop-types */
import React from 'react'

import { InputWrapper, type InputWrapperProps } from '@elder/common'
import { Field } from 'formik'
import moment from 'moment'
import styled from 'styled-components'

import { ErrorLabel } from 'components/forms/ErrorLabel'
import { fieldStyles } from 'components/forms/StyledField'

const SubinputsWrapper = styled.div`
  display: flex;
`

const SubinputWrapper = styled.div`
  flex: 1;

  &:not(:last-child) {
    margin-right: 16px;
  }
`

const DateSubInput = styled.input`
  ${fieldStyles};
`

type Props = $ReadOnly<{|
  +name: string,
  +label: string,
  +disabled?: boolean,
  +showErrors?: boolean,
  +errorLabel?: ?string,
  +info?: ?string,
  +className?: string,
  ...$Exact<InputWrapperProps>,
|}>

type DateInputEvent = SyntheticInputEvent<HTMLInputElement>
type DateInputKeyEvent = SyntheticKeyboardEvent<HTMLInputElement>

type DateInputInnerProps = {|
  +name: string,
  // TODO: update linting rules, this prop is used in a React 16 lifecycle method
  // eslint-disable-next-line react/no-unused-prop-types
  +value: ?string,
  +showErrors?: boolean,
  +setFieldValue: (string, string, ?boolean) => void,
  +errorLabel?: ?string,
  +disabled?: boolean,
|}

type DateInputInnerState = {|
  year: string,
  month: string,
  day: string,
  yearError: boolean,
  monthError: boolean,
  dayError: boolean,
  touchMode: boolean,
  backspacePressed: boolean,
|}

// Only exported so that we can fiddle in tests - use `<DateInput />` instead pls
export class DateInputInner extends React.PureComponent<
  DateInputInnerProps,
  DateInputInnerState,
> {
  state = {
    year: '',
    month: '',
    day: '',
    yearError: false,
    monthError: false,
    dayError: false,
    touchMode: false,
    backspacePressed: false,
  }

  UNSAFE_componentWillMount() {
    const { value } = this.props
    const [year = '', month = '', day = ''] = (value || '').split('-')

    this.setState({
      year,
      month,
      day,
    })
  }

  inputWrapperRef = null

  changeFocusedInput = (focusDelta: -1 | 1) => {
    const refElement = this.inputWrapperRef
    if (!refElement) {
      return
    }

    const inputs = [...refElement.querySelectorAll('input')]
    const currentIndex = inputs.indexOf(document.activeElement)

    if (currentIndex === -1) {
      return
    }

    const toFocus = inputs[currentIndex + focusDelta]

    // setTimeout stops the current event from being applied to the new input
    if (toFocus) {
      // $FlowOptOut
      const caretPosition = toFocus.value.length
      // $FlowOptOut
      toFocus.setSelectionRange(caretPosition, caretPosition)
      toFocus.focus()
    }
  }

  handleKeyPress = (event: DateInputKeyEvent) => {
    // Need to know if backspace was pressed when handling change event
    if (event.key === 'Backspace') {
      this.setState({ backspacePressed: true })
    }
  }

  handleChange = (name: 'day' | 'month' | 'year', event: DateInputEvent) => {
    const { backspacePressed, touchMode } = this.state
    const { value, maxLength } = event.currentTarget
    const filteredValue = value.replace(/[^0-9]/g, '')
    const trimmedValue = filteredValue.substr(0, maxLength)

    // No good way to implement this on mobile, due to defensive browsers
    // Let the user change inputs themselves
    if (!touchMode) {
      if (trimmedValue.length === maxLength) {
        this.changeFocusedInput(1)
      } else if (trimmedValue.length === 0 && backspacePressed) {
        this.changeFocusedInput(-1)
      }
    }

    this.setState(
      { [name]: trimmedValue, backspacePressed: false },
      this.updateFieldValue,
    )
  }

  updateFieldValue = () => {
    const { name, setFieldValue } = this.props
    const { year, month, day } = this.state
    const dateObj = moment(`${year}-${month}-${day}`, 'YYYY-MM-DD')

    const numberOfFilledFields = [year, month, day].filter(Boolean).length
    const noFields = numberOfFilledFields === 0
    const allFields = numberOfFilledFields === 3

    const dateError = !dateObj.isValid()
    const hasError = !noFields && (dateError || !allFields)

    if (!hasError) {
      const value = noFields ? '' : dateObj.format('YYYY-MM-DD')
      setFieldValue(name, value)
    }
  }

  handleTouchEnd = () => this.setState({ touchMode: true })

  render() {
    const { showErrors, errorLabel, disabled } = this.props
    const { year, month, day, yearError, monthError, dayError } = this.state
    const commonInputProps = {
      onKeyDown: this.handleKeyPress,
      pattern: '[0-9]*',
      inputMode: 'numeric',
    }
    const showDayError = dayError && showErrors
    const showMonthError = monthError && showErrors
    const showYearError = yearError && showErrors

    const hasInternalErrors = showDayError || showMonthError || showYearError

    return (
      <SubinputsWrapper
        ref={(element) => {
          this.inputWrapperRef = element
        }}
        onTouchEnd={this.handleTouchEnd}
      >
        <SubinputWrapper>
          <DateSubInput
            {...commonInputProps}
            onChange={(event) => this.handleChange('day', event)}
            value={day}
            maxLength="2"
            placeholder="DD"
            error={showDayError}
            aria-invalid={dayError}
            aria-label="Day"
            disabled={disabled}
          />

          {showDayError && <ErrorLabel errorLabel="Invalid day" />}
          {!hasInternalErrors &&
            errorLabel &&
            typeof errorLabel === 'string' && (
              <ErrorLabel errorLabel={errorLabel} />
            )}
        </SubinputWrapper>

        <SubinputWrapper>
          <DateSubInput
            {...commonInputProps}
            onChange={(event) => this.handleChange('month', event)}
            value={month}
            maxLength="2"
            placeholder="MM"
            error={showMonthError}
            aria-invalid={monthError}
            aria-label="Month"
            disabled={disabled}
          />

          {showMonthError && <ErrorLabel errorLabel="Invalid month" />}
        </SubinputWrapper>

        <SubinputWrapper>
          <DateSubInput
            {...commonInputProps}
            onChange={(event) => this.handleChange('year', event)}
            value={year}
            maxLength="4"
            placeholder="YYYY"
            error={showYearError}
            aria-invalid={yearError}
            aria-label="Year"
            disabled={disabled}
          />

          {showYearError && <ErrorLabel errorLabel="Invalid year" />}
        </SubinputWrapper>
      </SubinputsWrapper>
    )
  }
}

export const DateInput = ({
  name,
  showErrors,
  errorLabel,
  disabled,
  ...inputWrapperProps
}: Props) => (
  <InputWrapper disabled={disabled} {...inputWrapperProps}>
    <Field name={name}>
      {({ field: { value }, form: { setFieldValue } }) => (
        <DateInputInner
          name={name}
          value={value}
          showErrors={showErrors}
          setFieldValue={setFieldValue}
          errorLabel={errorLabel}
          disabled={disabled}
        />
      )}
    </Field>
  </InputWrapper>
)
