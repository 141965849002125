import { createContext, useContext, useMemo } from 'react'

import type { SolutionDetails } from '@elder/et-facade-et'
import { useSelector } from 'react-redux'

import {
  getPrettyOneOffOptions,
  getSuspensionReasonOptions,
  getPrettySuspensionReason,
  getPrettySuspensionCover,
} from 'app/referenceData'

import type { OptionLookupFn, TransformedSolution } from './transformSolution'
import { transformSolution } from './transformSolution'

interface SolutionContext {
  solution: TransformedSolution
  setSolutionData: (solution: SolutionDetails) => void
}

export const solutionContext = createContext<SolutionContext | null>(null)

interface SolutionProviderProps {
  solution: SolutionDetails
  setSolutionData: (solution: SolutionDetails) => void
  children: React.ReactNode
}

export const SolutionContextProvider = ({
  solution: solutionFromAPI,
  setSolutionData,
  children,
}: SolutionProviderProps) => {
  const dateFocus = null // ? What is this?

  const prettyOneOffOptions: OptionLookupFn = useSelector(
    getPrettyOneOffOptions,
  )
  const suspensionReasonOptions = useSelector(getSuspensionReasonOptions)
  const prettySuspensionReason: OptionLookupFn = useSelector(
    getPrettySuspensionReason,
  )
  const prettySuspensionCover: OptionLookupFn = useSelector(
    getPrettySuspensionCover,
  )

  const value = useMemo(
    () => ({
      solution: transformSolution(
        solutionFromAPI,
        dateFocus,
        prettyOneOffOptions,
        suspensionReasonOptions,
        prettySuspensionReason,
        prettySuspensionCover,
      ),
      setSolutionData,
    }),
    [
      prettyOneOffOptions,
      prettySuspensionCover,
      prettySuspensionReason,
      solutionFromAPI,
      suspensionReasonOptions,
      setSolutionData,
    ],
  )

  return (
    <solutionContext.Provider value={value}>
      {children}
    </solutionContext.Provider>
  )
}

export const useSolutionContext = () => {
  const context = useContext(solutionContext)
  if (!context) {
    throw new Error(
      'useSolutionContext must be used within a SolutionContextProvider',
    )
  }
  return context
}
