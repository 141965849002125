import { Add } from '@mui/icons-material'
import { Box, Stack, alpha, styled } from '@mui/material'
import { times } from 'lodash'
import type { Moment } from 'moment'
import moment from 'moment'

import type { TimelineBlock } from 'components/solutions/SolutionTimeline/SolutionTimeline'

import { BaseRectBlock } from './VisitingBlock'

interface CreateBlockData {}

interface CreateBlockProps {
  block: TimelineBlock<CreateBlockData>
  height: number
  onClick?: (block: TimelineBlock<CreateBlockData>) => void
  daysDisplayed: number
}

const Container = styled(Box)({
  overflow: 'visible',
  cursor: 'pointer',
})

const RectBlock = styled(BaseRectBlock)(({ theme }) => ({
  color: theme.palette.text.primary,
  backgroundColor: alpha(
    theme.palette.primary.main,
    theme.palette.action.activatedOpacity,
  ),
  opacity: 0,
  '&:hover': {
    opacity: 1,
  },
}))

export const makeCreateBlocks = (
  daysDisplayed: number,
  focusedDate?: Moment,
): TimelineBlock<CreateBlockData>[] => {
  if (!focusedDate) throw new Error('No focusedDate given')
  const viewStart = focusedDate.clone().subtract(daysDisplayed / 2, 'days')

  const blocks = times(daysDisplayed / 7).map((index) => {
    const date = moment(focusedDate).add(index - 2, 'weeks')
    if (date.isBefore(moment(), 'week')) return null

    const blockStart = date.clone().startOf('isoWeek')
    const blockEnd = blockStart.clone().add(1, 'week')

    const diffFromStartDate = blockStart.diff(viewStart, 'days')

    const from = diffFromStartDate

    const diffToEndDate = blockEnd.diff(viewStart, 'days')
    const to = diffToEndDate

    const data: CreateBlockData = {}

    const block = {
      id: `create-block-${index}`,
      from,
      to,
      isInfinite: false,
      startDateInclusive: blockStart,
      endDateExclusive: blockEnd,
      data,
    } as TimelineBlock<CreateBlockData>
    return block
  })
  const filteredBlocks = blocks.filter(
    Boolean,
  ) as TimelineBlock<CreateBlockData>[]
  return filteredBlocks
}

export const CreateBlock = ({
  block,
  height,
  onClick,
  daysDisplayed,
}: CreateBlockProps) => {
  return (
    <Container
      block={block}
      onClick={() => {
        if (onClick) {
          onClick(block)
        }
      }}
    >
      <RectBlock
        block={block}
        height={height}
        daysDisplayed={daysDisplayed}
        bgcolor="blue"
        color="white"
        from={block.from}
        to={block.to}
      >
        <Stack direction="row" alignItems="center" justifyContent="center">
          <Add />
          Add placement
        </Stack>
      </RectBlock>
    </Container>
  )
}
