// @flow
import { createSelector } from 'reselect'

import { NAMESPACE } from './actions'

const getProfileNewValidationError = (state) =>
  state.get(NAMESPACE).get('profileNewValidationError')

export const getProfileNewValidationErrorJs = createSelector(
  [getProfileNewValidationError],
  (profileNewValidationError) => profileNewValidationError.toJS(),
)
