import { useEffect, useState } from 'react'

import { CloseableDialog, useLocalStorage } from '@elder/common'
import { LoadingButton } from '@mui/lab'
import { Button, TextField, Typography } from '@mui/material'
import { useMutation } from '@tanstack/react-query'
import { omit } from 'lodash'
import type { ConversationData, Inbox } from 'talkjs/all'

import { getConfig } from 'app/config'
import { useNotifications } from 'features/snackbar/useNotifications'

const { environment, talkJSAppId } = getConfig()

interface SetConversationAttributesArgs {
  talkJSAPIKey: string
  conversationId: string
  body: string
}

const setConversationData = async ({
  talkJSAPIKey,
  conversationId,
  body,
}: SetConversationAttributesArgs) => {
  JSON.parse(body) // Throw SyntaxError if body is invalid JSON
  const response = await fetch(
    `https://api.talkjs.com/v1/${talkJSAppId}/conversations/${conversationId}`,
    {
      method: 'PUT',
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${talkJSAPIKey}`,
      },
      body,
    },
  )
  if (!response.ok) {
    const text = await response.text()
    const cause = [response.status, response.statusText, text].join(' ')
    throw new Error('Failed to set conversation data', { cause })
  }

  return response.json()
}

const conversationDataToString = (conversation: ConversationData) => {
  const noId = omit(conversation, 'id')
  const arrayParticipants = {
    ...noId,
    participants: Object.keys(noId.participants),
  }
  const json = JSON.stringify(arrayParticipants, null, 2)
  return json
}

interface InfoDialogProps {
  inbox: Inbox | undefined
}

export const InfoDialog = ({ inbox }: InfoDialogProps) => {
  const { showError, showSuccess } = useNotifications()

  const [conversationId, setConversationId] = useState('')
  const [initialValue, setInitialValue] = useState('')
  const [value, setValue] = useState('')

  const [apiKey, setApiKey] = useLocalStorage('talkJSAPIKey', '')

  useEffect(() => {
    if (!inbox) return undefined

    const sub = inbox.onCustomConversationAction(({ action, conversation }) => {
      if (action !== 'info') return
      if (!conversation) return
      setConversationId(conversation.id)
      const value = conversationDataToString(conversation)
      setInitialValue(value)
      setValue(value)
    })

    return () => {
      sub.unsubscribe()
    }
  }, [inbox])

  const editable = environment === 'DEVELOPMENT'

  const mutation = useMutation({ mutationFn: setConversationData })

  const save = () => {
    if (!conversationId) return
    if (apiKey.trim() === '') {
      showError('API key is required')
      return
    }

    mutation.mutate({ talkJSAPIKey: apiKey, conversationId, body: value })
  }

  useEffect(() => {
    if (mutation.isSuccess) {
      showSuccess('Saved')
      setConversationId('')
    }
  }, [mutation.isSuccess, showSuccess])

  useEffect(() => {
    if (mutation.error)
      showError(`${mutation.error.message}: ${mutation.error.cause}`)
  }, [mutation.error, showError])

  return (
    <CloseableDialog
      title="Conversation info"
      open={Boolean(conversationId)}
      onClose={() => setConversationId('')}
      maxWidth="lg"
      extraActions={
        editable && (
          <>
            <Button
              onClick={() => setValue(initialValue)}
              disabled={value === initialValue}
              variant="outlined"
            >
              Reset
            </Button>
            <LoadingButton
              onClick={save}
              loading={mutation.isPending}
              disabled={value === initialValue}
              variant="contained"
            >
              Save
            </LoadingButton>
          </>
        )
      }
    >
      <Typography sx={{ wordWrap: 'break-word' }}>
        <strong>ID</strong>:&nbsp;<code>{conversationId}</code>
      </Typography>
      <TextField
        label="Details"
        multiline
        // Need to set row number manually for now.
        // See https://github.com/mui/base-ui/issues/167
        rows={20}
        fullWidth
        value={value}
        onChange={(e) => setValue(e.target.value)}
        disabled={!editable}
        InputProps={{ sx: { fontFamily: 'monospace', fontSize: 12 } }}
        // eslint-disable-next-line react/jsx-no-duplicate-props
        inputProps={{
          autocomplete: 'off',
          autocorrect: 'off',
          autocapitalize: 'off',
          spellcheck: 'false',
        }}
      />
      {editable && (
        <TextField
          label="API key"
          fullWidth
          value={apiKey}
          onChange={(e) => setApiKey(e.target.value)}
          InputProps={{ sx: { fontFamily: 'monospace' } }}
        />
      )}
    </CloseableDialog>
  )
}
