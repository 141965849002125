// @flow

import { makeAction } from 'elder/actions'

import { makeActions as makeActionsButtonSpinner } from 'modules/buttonSpinner'
import { addNamespace } from 'utils'

export const NAMESPACE = 'PROFESSIONALS'

export const buttonSpinnerActions = makeActionsButtonSpinner(NAMESPACE)

export const DOWNLOAD_CSV = addNamespace(NAMESPACE, 'DOWNLOAD_CSV')

export const downloadCsv = makeAction(DOWNLOAD_CSV, (params: $FlowFixMe) => ({
  params,
}))
