// @flow
import { LOCATION_CHANGE } from 'connected-react-router'

import { INIT } from 'app/actions'
import * as routes from 'routes'

const initialState = {
  path: routes.matchingCentre,
  previousPath: undefined,
}

export const pathHistoryReducer = (
  state: Object = initialState,
  action: Object,
) => {
  let pathname
  switch (action.type) {
    case INIT:
      // This will need to be updated if the app is moved away from using hash history
      pathname =
        window.location.hash.length > 2
          ? window.location.hash
          : routes.matchingCentre
      pathname = pathname.slice(1, pathname.indexOf('?'))
      return {
        path: pathname,
        previousPath: undefined,
      }
    case LOCATION_CHANGE:
      // eslint-disable-next-line no-case-declarations
      const {
        payload: { location },
      } = action
      pathname = (location || {}).pathname // eslint-disable-line prefer-destructuring
      if (pathname && pathname !== state.path) {
        return {
          path: pathname,
          previousPath: state.path,
        }
      }
      return state

    default:
      return state
  }
}
