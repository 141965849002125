// @flow
import type { Saga } from 'redux-saga'
import { take, call, select, put } from 'redux-saga/effects'

import { applicationSuccess } from 'features/snackbar/snackbar'
import { pageSelectors } from 'routes/account/selectors'
import {
  pageActions,
  UPDATE_BASIC_ACCT_INFO,
  updateExpectedStartDateValidationFail,
} from 'routes/account/summary/actions'
import { expectedBasicAcctInfo } from 'routes/account/summary/validFields'
import { manageErrorWithFieldValidation } from 'utils/fieldValidationErrors'
import { fetchFlow } from 'utils/services'

export function* putBasicAccountInfo(data: Object): Generator<*, void, *> {
  const acctId = yield select(pageSelectors.getId)
  const endpoint = `/et/accounts/${acctId}/careagreement`
  const method = 'PUT'

  try {
    const responseBody = yield call(fetchFlow, endpoint, method, data)
    yield put(pageActions.set(responseBody))
    applicationSuccess({
      title: 'Account updated',
      message: 'Account info were updated successfully.',
    })
  } catch (error) {
    yield call(
      manageErrorWithFieldValidation,
      error,
      'basicAcctInfo',
      expectedBasicAcctInfo,
      updateExpectedStartDateValidationFail,
    )
  }
}

export function* watchBasicAccountInfo(): Saga<*> {
  while (true) {
    const payload = yield take(UPDATE_BASIC_ACCT_INFO)
    yield call(putBasicAccountInfo, payload.data)
  }
}
