import { useState, useEffect } from 'react'

import type { SetCareAppraisalVersionParams } from '@elder/et-facade-et'
import {
  useEtSetCareAppraisalTargetVersionUsingPUT as setCaTargetVersion,
  useEtGetCareAppraisalVersionsUsingGET as useCaVersions,
} from '@elder/et-facade-et'
import {
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  Stack,
  Typography,
  Button,
} from '@mui/material'
import { useSnackbar } from 'notistack'
import { useDispatch } from 'react-redux'

import { SectionHeader } from 'components/styledComponentsTranslatedClasses/SectionHeader'
import { pageActions } from 'routes/account/summary/actions'

import { getLabeledCaVersion } from './CareAppraisalVersion'

export const CareAppraisalTargetVersionForm = ({
  accountId,
  currentTargetVersion,
  cancelEdit,
}: {
  accountId: string
  currentTargetVersion: string | null
  cancelEdit: () => void
}) => {
  const [version, setVersion] = useState(currentTargetVersion)
  const [displayWarning, setDisplayWarning] = useState(false)
  const { enqueueSnackbar } = useSnackbar()
  const { data: caVersions, isLoading, isError } = useCaVersions()
  const dispatch = useDispatch()

  const CURRENTLY_DISABLED = true

  useEffect(() => {
    if (currentTargetVersion) {
      setDisplayWarning(true)
    }
  }, [setDisplayWarning, currentTargetVersion])

  const mutation = setCaTargetVersion({
    mutation: {
      onSuccess: () => {
        enqueueSnackbar('Care Appraisal target version updated', {
          variant: 'success',
        })
        dispatch(pageActions.get())
        cancelEdit()
      },
      onError: () => {
        enqueueSnackbar('Error updating Care Appraisal target version', {
          variant: 'error',
        })
      },
    },
  })

  const handleSave = () => {
    mutation.mutate({
      accountId,
      data: {
        appraisalVersion: version === 'NOT_SET' ? null : version,
      } as SetCareAppraisalVersionParams,
    })
  }

  if (!caVersions) return null
  const options = [...caVersions, 'NOT_SET']

  return (
    <Stack spacing={2}>
      <SectionHeader>
        <Typography variant="h6">Care Appraisal Target Version</Typography>
      </SectionHeader>
      <FormControl>
        <InputLabel id="care-appraisal-version-label">
          Select version
        </InputLabel>
        <Select
          value={version}
          label="Current version"
          onChange={(e) => setVersion(e.target.value)}
          disabled={isLoading || isError}
        >
          {options.map((caVersion) => (
            <MenuItem key={caVersion} value={caVersion}>
              {getLabeledCaVersion(caVersion)}
            </MenuItem>
          ))}
        </Select>
      </FormControl>

      <Typography
        variant="subtitle2"
        component="p"
        color="error"
        hidden={!displayWarning}
        maxWidth="400px"
      >
        Use with caution: the customer will be shown a prompt to fill in the
        Care Appraisal again using the latest version.
      </Typography>

      <Stack direction="row" justifyContent="flex-end">
        <Button
          onClick={cancelEdit}
          variant="outlined"
          disabled={mutation.isPending}
        >
          Cancel
        </Button>
        <Button
          variant="contained"
          onClick={handleSave}
          disabled={mutation.isPending || CURRENTLY_DISABLED}
        >
          Save
        </Button>
      </Stack>
      <Typography
        variant="subtitle2"
        component="p"
        color="error"
        alignSelf="flex-end"
        mt={-2}
      >
        This feature will be enabled soon.
      </Typography>
    </Stack>
  )
}
