// @flow
import addNamespace from 'utils/addNamespace'

export const NAMESPACE = 'CARER_CHANGELOG'

export const GET_CHANGELOG = addNamespace(NAMESPACE, 'GET_CHANGELOG')
export const SET_CHANGELOG = addNamespace(NAMESPACE, 'SET_CHANGELOG')
export const DELETE_CHANGE_HISTORY = addNamespace(
  NAMESPACE,
  'DELETE_CHANGE_HISTORY',
)

export const getChangelog = (carerId: string) => ({
  type: GET_CHANGELOG,
  carerId,
})

export const setChangelog = (changelogQuery: Object) => {
  let changelogs

  if (changelogQuery.entries && changelogQuery.entries.length > 0) {
    changelogs = changelogQuery.entries
  } else {
    changelogs = []
  }

  return {
    type: SET_CHANGELOG,
    changelogs,
  }
}

export const deleteChangeHistory = (carerId: string) => ({
  type: DELETE_CHANGE_HISTORY,
  carerId,
})
