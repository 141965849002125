// @flow
import moment from 'moment'
import type { Saga } from 'redux-saga'

import { takeLatest, put, call } from 'elder/effects'

import {
  downloadCsv,
  buttonSpinnerActions,
} from 'features/professionals/actions'
import { downloadFile } from 'utils/downloadFile'
import { getBlobFlow } from 'utils/services'

const getCsvFilename = (): string => {
  const baseName = 'Professionals'

  const todayMoment = moment()
  const dateFormat = 'DD-MM-YYYY'

  const date = todayMoment.format(dateFormat)

  return `${baseName}_${date}.csv`
}

function* handleDownloadCsv({ params }): Saga<*> {
  yield* put(buttonSpinnerActions.setStartLoading())
  const response = yield* call(
    getBlobFlow,
    '/et/professionals/matching/export',
    params,
  )
  yield* call(downloadFile, response, getCsvFilename())
  yield* put(buttonSpinnerActions.setStopLoading())
}

export function* watchDownloadCsv(): Saga<*> {
  yield* takeLatest(downloadCsv, handleDownloadCsv)
}
