// @flow
import type { Saga } from 'redux-saga'
import { call, put, takeLatest } from 'redux-saga/effects'

import { applicationError } from 'features/snackbar/snackbar'
import { addNamespace } from 'utils'
import { describeServiceError, getFlow } from 'utils/services'

import { GET_AUTOCOMPLETE_PROFILES, makeActions } from './actions'

export const makeWatchAutocompleteProfiles = (namespace: string) => {
  const actions = makeActions(namespace)

  function* fetchAutocompleteProfilesFlow(
    action,
  ): Generator<Object, void, Object> {
    const query = action.queryString
    const endpoint = '/et/profiles/autocomplete'
    try {
      const result = yield call(getFlow, endpoint, { query })
      yield put(actions.setAutocompleteProfiles(result.items))
    } catch (error) {
      yield put(actions.getAutocompleteProfilesFail(error))
      applicationError(describeServiceError(error))
    }
  }

  function* getAutocompleteProfiles(): Saga<*> {
    yield takeLatest(
      addNamespace(namespace, GET_AUTOCOMPLETE_PROFILES),
      fetchAutocompleteProfilesFlow,
    )
  }

  return getAutocompleteProfiles
}
