// @flow
// $FlowOptOut
import React, { useState, useEffect } from 'react'

import type { ReferenceDataOptions } from '@elder/common'
import {
  colors,
  Header,
  Paragraph,
  FormikInput,
  FormikSelect,
  ErrorMessage,
} from '@elder/common'
import { Button, Card, CircularProgress } from '@mui/material'
import { Form, Formik } from 'formik'
import { Link } from 'react-router-dom'
import styled, { css } from 'styled-components'

import { ModalWrapper } from 'components/modals/ModalWrapper'
import type { Contact } from 'domain/contacts'
import { newProfile } from 'routes'
import type { ProfileSuggestion } from 'routes/account/contacts/ContactsContainer'

const StyledCard = styled(Card)`
  width: 880px;
  min-height: 608px;
  padding: 16px;
  display: flex;
  flex-direction: column;
  overflow: visible;
`
const HeaderWrapper = styled.div`
  padding-bottom: 8px;
  border-bottom: 2px solid black;
`
const Content = styled.ul`
  flex: 1;
  list-style: none;
  margin: 0 0 16px;
  padding: 0;
`
const ListItem = styled.li`
  display: flex;
  padding: 16px 0;
  border-bottom: 1px solid ${colors.blue600}30;
`
const LiElement = styled.span`
  flex: 1;
  display: flex;
  flex-direction: column;
  :first-of-type {
    margin-right: 8px;
  }
`
const StyledInput = styled(FormikInput)`
  width: 90%;
  margin-bottom: 0;
`
const customSelectInput = css`
  /* Necessary evil to add custom styles to react-select. Due to our personalization layer
  in the component library, the 'styles' API of react-select cannot override the default styles */
  > div > div > div {
    min-height: 44px !important;
    border: solid 2px ${colors.blue300} !important;
    border-radius: 0 !important;
    color: ${colors.blue700} !important;
  }
`
const StyledSelect = styled(FormikSelect)`
  width: 90%;
  margin-bottom: 0;
  ${customSelectInput}

  /* Only show one selected label as all of them are the same */
  .Select__multi-value {
    :not(:first-of-type) {
      display: none;
    }
  }
`
const RemoveButton = styled(Button)`
  color: ${colors.errorDark};
  margin: auto;
`
const AddUserSelect = styled(FormikSelect)`
  width: 35%;
  ${customSelectInput}
`
const AddUserWrapper = styled.div`
  display: flex;
  align-items: end;
`

const ButtonsWrapper = styled.div`
  width: 100%;
  align-self: flex-end;
  display: flex;
  justify-content: flex-end;
  margin-top: 16px;
`
const StyledErrorMessage = styled(ErrorMessage)`
  > svg {
    display: none;
  }
`
// Make all labels the same and then show only one
const MultiValueLabel = ({
  selectProps: { value },
}: {
  +selectProps: { +value: Array<Object> },
}) =>
  value.length > 1 ? (
    <span>{`${value.length} selected`}</span>
  ) : (
    <span>{value[0].label}</span>
  )

const Loading = styled.div`
  width: 100%;
  padding: 16px;
`

const noOptionsMessage = (loading) => {
  if (loading) {
    return () => (
      <Loading>
        <CircularProgress sx={{ mx: 'auto' }} />
      </Loading>
    )
  }
  return undefined
}

// Get rid of the 'remove' component for each option
const MultiValueRemove = () => null

const addContact = (contacts, newContact) =>
  newContact && [
    ...contacts,
    {
      profile: {
        id: newContact.value,
        text: newContact.fullName,
      },
      relationship: '',
      email: newContact.email,
      phoneNumber: '',
      roles: [],
    },
  ]

const validToSubmit = (values) => {
  const primaryContact = values
    .reduce((acc, curr) => acc.concat(curr.roles), [])
    .filter((elm) => elm === 'PRIMARY_POINT_OF_CONTACT')
  if (primaryContact.length > 1) {
    return [false, 'Only one primary contact is allowed on each account']
  }
  if (primaryContact.length < 1) {
    return [false, 'A primary contact is required on the account']
  }
  return [true, '']
}
const transformToOption = (suggestions) =>
  suggestions.map(({ value, label }) => ({
    value,
    label,
  }))

type Props = {|
  +contacts: Array<Contact>,
  +showModal: boolean,
  +closeModal: () => void,
  +contactRolesOptions: ReferenceDataOptions,
  +submit: ({| +contacts: Array<Contact> |}) => void,
  +profileSuggestions: Array<ProfileSuggestion>,
  +updateProfileSuggestions: (string) => void,
  +loadingProfileSuggestions: boolean,
|}

export const EditModal = ({
  contacts,
  closeModal,
  showModal,
  contactRolesOptions,
  submit,
  profileSuggestions,
  updateProfileSuggestions,
  loadingProfileSuggestions,
}: Props) => {
  const [localContacts, setlocalContacts] = useState(contacts)
  const [validarionError, setValidationError] = useState(null)
  const [localSuggestions, setLocalSuggestions] = useState([profileSuggestions])
  useEffect(() => {
    updateProfileSuggestions('')
  }, [])
  useEffect(() => {
    const updated = profileSuggestions.filter(
      ({ value }) => !localContacts.some(({ profile }) => profile.id === value),
    )
    setLocalSuggestions(updated)
  }, [JSON.stringify(profileSuggestions)])
  useEffect(() => {
    setlocalContacts(contacts)
  }, [JSON.stringify(contacts)]) // Deep check
  return (
    showModal && (
      <Formik
        onSubmit={(data) => {
          const updatedContacts = localContacts.map(({ profile: { id } }) => ({
            profileId: id,
            relationship: data[`${id}_relationship`] || '',
            roles: data[`${id}_roles`] || [],
          }))
          const [isValid, error] = validToSubmit(updatedContacts)
          if (isValid) {
            setValidationError(null)
            submit({ contacts: updatedContacts })
          } else {
            setValidationError(error)
          }
        }}
        initialValues={contacts.reduce(
          (acc, curr) => ({
            ...acc,
            [`${curr.profile.id}_relationship`]: curr.relationship,
            [`${curr.profile.id}_roles`]: curr.roles,
          }),
          {},
        )}
      >
        {() => (
          <Form>
            <ModalWrapper>
              <StyledCard>
                <HeaderWrapper>
                  <Header level="h2" label="Edit contacts" />
                </HeaderWrapper>
                <Content>
                  {localContacts.map(
                    ({ email, profile: { id, text: name } }) => (
                      <ListItem key={id}>
                        <LiElement>
                          <Header level="h3" label={name} />
                          <Paragraph level="p2" label={email} />
                        </LiElement>
                        <LiElement>
                          <StyledInput
                            name={`${id}_relationship`}
                            label="Relationship"
                          />
                        </LiElement>
                        <LiElement>
                          <StyledSelect
                            name={`${id}_roles`}
                            label="Roles"
                            isMulti
                            isSearchable={false}
                            options={contactRolesOptions.options}
                            multiValueLabelComponent={
                              (MultiValueLabel: $FlowFixMe)
                            }
                            multiValueRemoveComponent={MultiValueRemove}
                            hideSelectedOptions={false}
                            styles={{
                              option: (base, state) => ({
                                ...base,
                                display: 'flex',
                                ':before': {
                                  content: '""',
                                  display: 'inline-block',
                                  height: '10px',
                                  backgroundColor: state.isSelected
                                    ? colors.blue600
                                    : 'transparent',
                                  border: '1px solid #A6A6A6A6',
                                  borderRadius: '2px',
                                  margin: 'auto 8px auto 0',
                                  flex: '0 0 10px',
                                },
                                backgroundColor: 'transparent',
                                color: 'initial',
                              }),
                            }}
                          />
                        </LiElement>
                        <LiElement>
                          <RemoveButton
                            onClick={() =>
                              setlocalContacts(
                                localContacts.filter(
                                  (contact) => contact.profile.id !== id,
                                ),
                              )
                            }
                            variant="text"
                          >
                            Remove
                          </RemoveButton>
                        </LiElement>
                      </ListItem>
                    ),
                  )}
                </Content>
                <AddUserWrapper>
                  <AddUserSelect
                    name="newContact"
                    label="Add user"
                    options={transformToOption(localSuggestions)}
                    isSearchable
                    placeholder=""
                    isMulti={false}
                    styles={{
                      indicatorsContainer: () => ({
                        display: 'none',
                      }),
                      singleValue: () => ({
                        display: 'none',
                      }),
                    }}
                    onInputChange={(input) => {
                      updateProfileSuggestions(input)
                    }}
                    noOptionsMessageComponent={noOptionsMessage(
                      loadingProfileSuggestions,
                    )}
                    onChangeCallback={(selected) => {
                      const newContact = localSuggestions.find(
                        (item) => item.value === selected,
                      )
                      const newContactsList = addContact(
                        localContacts,
                        newContact,
                      )
                      setlocalContacts(newContactsList)
                    }}
                  />
                  <Button
                    component={Link}
                    to={newProfile}
                    target="_blank"
                    // We want this button to be center-aligned with the textbox
                    // _excluding the label_, so we have to fuge a little bit
                    // here
                    sx={{ mb: '4px' }}
                  >
                    Create new
                  </Button>
                </AddUserWrapper>
                {validarionError && (
                  <StyledErrorMessage errorMessage={validarionError} />
                )}
                <ButtonsWrapper>
                  <Button
                    onClick={() => {
                      setlocalContacts(contacts)
                      setValidationError(null)
                      closeModal()
                    }}
                    variant="text"
                  >
                    Cancel
                  </Button>
                  <Button type="submit" variant="contained">
                    Save
                  </Button>
                </ButtonsWrapper>
              </StyledCard>
            </ModalWrapper>
          </Form>
        )}
      </Formik>
    )
  )
}
