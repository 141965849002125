// @flow
import type { Node } from 'react'
import React from 'react'

import { colors } from '@elder/common'
import styled from 'styled-components'

const Wrapper = styled.div`
  background-color: ${colors.blue200};
  display: flex;
  flex-direction: column;
  padding: 15px;
  width: 285px;

  h5 {
    margin-bottom: 5px;
    font-size: 16px;
  }
`

type Props = {|
  +title: string,
  +children: Array<Node>,
|}

export const TableFiltersSidebar = ({ title, children }: Props) => (
  <Wrapper>
    <h5>{title}</h5>
    {children}
  </Wrapper>
)
