// @flow
import { object, string, arrayOf } from 'flow-validator'

export const updateHierarchyRequest = object({
  accountId: string,
  hierarchy: arrayOf(string),
})

export const generateHierarchyRequest = object({
  accountId: string,
})
