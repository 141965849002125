import React from 'react'

import type { DateGuide } from 'components/solutions/SolutionDateLabels'
import { TimelineGuideBar } from 'components/timelines/TimelineGuideBar'
import TimelineGuideBars from 'components/timelines/TimelineGuideBars'

interface Props {
  dateGuides: DateGuide[]
}

export const SolutionDateGuides = ({ dateGuides }: Props) => (
  <TimelineGuideBars>
    {dateGuides.map((date) => (
      <TimelineGuideBar
        key={date.key}
        position={date.position}
        total={28}
        type={date.type}
      />
    ))}
  </TimelineGuideBars>
)
