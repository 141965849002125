// @flow
import { combineReducers } from 'redux-immutable'

import { email } from './email'
import roles from './roles'

export default combineReducers({
  roles,
  email,
})
