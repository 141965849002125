// @flow
export const expectedProfessionalNewFields = {
  firstName: true,
  lastName: true,
  phone: true,
  email: true,
  address: true,
  city: true,
  postcode: true,
  bankAccount: true,
  sortCode: true,
}
