// @flow
/* eslint-disable react/prop-types */
import React from 'react'

import type { InputWrapperProps } from '@elder/common'
import { colors, InputWrapper } from '@elder/common'
import { CheckOutlined } from '@mui/icons-material'
import { Field } from 'formik'
import styled, { css } from 'styled-components'

import type { ColourAndContrast } from 'domain/colourAndContrast'

const StyledCheckboxLabel = styled.label`
  max-height: 100px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  cursor: pointer;
  ${({ disabled }) =>
    disabled &&
    css`
      color: ${colors.darkSmoke};
      cursor: not-allowed;
    `};
`

const StyledCheckbox = styled.div`
  flex-shrink: 0;
  border: solid 1px ${({ colourAndContrast }) => colourAndContrast.primary};
  height: 25px;
  width: 25px;
  margin-right: 8px;
  border-radius: 4px;
  display: flex;
  justify-content: space-around;
  align-items: center;
  background-color: ${({ checked, colourAndContrast }) =>
    checked ? colourAndContrast.primary : colourAndContrast.contrast};
`

const TextAndImage = styled.div`
  display: flex;
  justify-content: flex-start;
  align-items: center;
  flex-grow: 1;
  *:first-child {
    margin-right: 20px;
  }
`

const HiddenInput = styled.input`
  display: none;
`

const NEUTRAL_COLOR_SCHEME = {
  primary: colors.lightSmoke,
  contrast: colors.lightSmoke,
  outline: colors.lightSmoke,
}

const chooseIconToDisplay = ({
  checked,
  colourAndContrast,
  disabled,
}: {|
  +checked?: boolean,
  +colourAndContrast: ColourAndContrast,
  +disabled: ?boolean,
|}) => {
  if (checked) {
    return (
      <StyledCheckbox
        checked
        colourAndContrast={disabled ? NEUTRAL_COLOR_SCHEME : colourAndContrast}
      >
        <CheckOutlined
          sx={{
            color: disabled ? colors.lightSmoke : 'white',
          }}
        />
      </StyledCheckbox>
    )
  }
  return (
    <StyledCheckbox
      colourAndContrast={disabled ? NEUTRAL_COLOR_SCHEME : colourAndContrast}
    />
  )
}

type InnerProps = {|
  +checkboxLabel?: string,
  +disabled?: boolean,
  +checked?: boolean,
  +colourAndContrast?: ColourAndContrast,
  +onChange?: (checked: boolean) => void,
|}

const CheckboxInner = ({
  checked,
  colourAndContrast = {
    primary: colors.successDark,
    contrast: colors.white,
    outline: colors.successDark,
  },
  disabled,
  // If no onChange is passed we set an empty function to avoid warnings in the console (onChange can't be undefined)
  // eslint-disable-next-line no-empty-function
  onChange = () => {},
  checkboxLabel,
}: InnerProps) => (
  <StyledCheckboxLabel
    checked={checked}
    colourAndContrast={colourAndContrast}
    disabled={disabled}
  >
    <HiddenInput
      type="checkbox"
      checked={checked}
      disabled={disabled}
      onChange={(event) => onChange(event.target.checked)}
    />
    {chooseIconToDisplay({
      checked,
      colourAndContrast,
      disabled,
    })}
    <TextAndImage>{checkboxLabel && <span>{checkboxLabel}</span>}</TextAndImage>
  </StyledCheckboxLabel>
)

type Props = $ReadOnly<{|
  +name?: string,
  +noFormik?: boolean,
  +checkboxLabel?: string,
  +checked?: boolean,
  +colourAndContrast?: ColourAndContrast,
  +onChange?: (checked: boolean) => void,
  +validate?: boolean,
  ...$Exact<InputWrapperProps>,
|}>

export const Checkbox = ({
  name,
  checkboxLabel,
  disabled,
  checked,
  colourAndContrast,
  onChange,
  noFormik,
  validate = true,
  ...inputWrapperProps
}: Props) => {
  if (noFormik) {
    return (
      <InputWrapper {...inputWrapperProps} disabled={disabled}>
        <CheckboxInner
          checked={checked}
          colourAndContrast={colourAndContrast}
          disabled={disabled}
          onChange={onChange}
          checkboxLabel={checkboxLabel}
        />
      </InputWrapper>
    )
  }
  return (
    <InputWrapper {...inputWrapperProps} disabled={disabled}>
      <Field name={name}>
        {({ field: { value }, form: { setFieldValue } }) => (
          <CheckboxInner
            checked={typeof value === 'string' ? value === 'true' : value}
            colourAndContrast={colourAndContrast}
            disabled={disabled}
            onChange={(newValue) => {
              // https://codesandbox.io/s/m4mzpn166j
              // setFieldValue has to happen before onChange for form submission on click
              setFieldValue(name, newValue, validate) // validate set to false prevents form validation
              if (onChange) {
                onChange(newValue)
              }
            }}
            checkboxLabel={checkboxLabel}
          />
        )}
      </Field>
    </InputWrapper>
  )
}
