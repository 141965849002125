// @flow
import { makeActions } from 'modules/pageIdAndIdentity'
import { addNamespace } from 'utils'

export const NAMESPACE = 'PROFILE'

export const pageActions = makeActions(NAMESPACE)

export const IMPERSONATE_CUSTOMER = addNamespace(
  NAMESPACE,
  'IMPERSONATE_CUSTOMER',
)

export const impersonateCustomer = (callbackUrl: string) => ({
  type: IMPERSONATE_CUSTOMER,
  callbackUrl,
})
