import type { ReactNode } from 'react'
import React, { useContext } from 'react'

import type { PlacementDetails } from '@elder/et-facade-et'
import { keyBy } from 'lodash'
import type { Moment } from 'moment'
import moment from 'moment'
import styled from 'styled-components'
import { StringParam, useQueryParam } from 'use-query-params'

import { AngleBlock } from 'components/solutions/SolutionTimeline/blocks/AngleBlock'
import { SolutionTimeline } from 'components/solutions/SolutionTimeline/SolutionTimeline'
import type { TimelineBlock } from 'components/solutions/SolutionTimeline/SolutionTimeline'
import type { ColorLookup } from 'features/solutionsTimeline/placement-colors'
import { getSaturation } from 'features/solutionsTimeline/placement-colors'
import type { TransformedPlacementForTimeline } from 'features/solutionsTimeline/transformPlacementsForTimeline'
import { transformPlacementsForTimeline } from 'features/solutionsTimeline/transformPlacementsForTimeline'
import { solutionContext } from 'routes/account/solutions/contexts/solution'

export type PlacementBlockData = TransformedPlacementForTimeline

type PlacementTimelineBlock = TimelineBlock<PlacementBlockData>
interface RenderPlacementTimelineBlockProps {
  block: PlacementTimelineBlock
}

function makeBlocks(
  placements: TransformedPlacementForTimeline[],
  daysDisplayed: number,
  focusedDate?: Moment,
): PlacementTimelineBlock[] {
  if (!focusedDate) throw new Error('No focusedDate given')
  const momentDateViewStart = focusedDate
    .clone()
    .subtract(daysDisplayed / 2, 'days')

  return placements.map((placement) => {
    const momentStartDate = moment(placement.effectiveStartDateInclusive)
    const diffFromStartDate = momentStartDate.diff(momentDateViewStart, 'days')

    const from = diffFromStartDate
    let to: number

    if (placement.effectiveEndDateExclusive) {
      const momentEndDate = moment(placement.effectiveEndDateExclusive)
      const diffToEndDate = momentEndDate.diff(momentDateViewStart, 'days')
      to = diffToEndDate
    } else {
      // Always just beyond reach!
      to = daysDisplayed + 1
    }

    return {
      id: placement.id,
      from,
      to,
      colors: placement.colors,
      isInfinite: !placement.effectiveEndDateExclusive,
      startDateInclusive: moment(placement.effectiveStartDateInclusive),
      endDateExclusive: moment(placement.effectiveEndDateExclusive),
      data: placement,
    } as PlacementTimelineBlock
  })
}

const PlacementDetailsBlockContent = styled.div`
  width: 100%;
  color: ${({ colors }) => colors.text};
  padding: 0 10px 0;
  text-overflow: ellipsis;
  white-space: nowrap;
`

const height = 26 // px

type Props = {
  renderBlock: ({ block }: RenderPlacementTimelineBlockProps) => ReactNode
  daysDisplayed: number
  setFocus: (date: Moment) => void
  focus: Moment
  placementId?: string
  placements: PlacementDetails[]
  colorLookup: ColorLookup
}

export const PlacementDetailsTimeline = ({
  renderBlock,
  daysDisplayed,
  setFocus,
  focus,
  placementId,
  placements,
  colorLookup,
}: Props) => {
  const [, setPlacementId] = useQueryParam('placementId', StringParam)

  const solutionContextValue = useContext(solutionContext)
  const status = solutionContextValue?.solution.status || 'ACTIVE'
  const saturation = getSaturation(status)

  const { placements: transformedPlacements } = transformPlacementsForTimeline({
    placementSet: placements,
    momentViewStart: focus?.clone().subtract(daysDisplayed / 2, 'days'),
    daysInView: daysDisplayed,
    colorSelector: (placement) => colorLookup[placement.id],
    placementsLookup: keyBy(placements, 'id'),
  })

  const blocks = makeBlocks(transformedPlacements, daysDisplayed, focus)

  return (
    <SolutionTimeline
      height={height}
      blocks={blocks}
      saturation={saturation}
      daysDisplayed={daysDisplayed}
      onBeforeDotClick={(block) =>
        block.endDateExclusive && setFocus(block.endDateExclusive)
      }
      onAfterDotClick={(block) => setFocus(block.startDateInclusive)}
      onStartStopClick={() => {
        const end = transformedPlacements.at(0)?.effectiveStartDateInclusive
        if (!end) return
        setFocus(moment(end))
      }}
      onEndStopClick={() => {
        const start = placements.at(-1)?.effectiveEndDateExclusive
        if (!start) return
        setFocus(moment(start))
      }}
    >
      {(blocksInView: PlacementTimelineBlock[]) =>
        blocksInView.map((block) => {
          const isSelected = !!placementId && block.id === placementId

          const blockLeftInView =
            block.to > 0 && block.from < 0
              ? `${(100 * -block.from) / (block.to - block.from)}%`
              : 0

          return (
            <AngleBlock
              key={block.id}
              isSelected={isSelected}
              block={block}
              height={height}
              daysDisplayed={daysDisplayed}
              onClick={() =>
                setPlacementId(block.id !== placementId ? block.id : undefined)
              }
            >
              <PlacementDetailsBlockContent
                left={blockLeftInView}
                colors={block.colors}
              >
                {renderBlock({ block })}
              </PlacementDetailsBlockContent>
            </AngleBlock>
          )
        })
      }
    </SolutionTimeline>
  )
}
