/* eslint-disable react/forbid-prop-types */
import React from 'react'

import { withFormsy } from 'formsy-react'
import { Row } from 'formsy-react-components'
import moment from 'moment'
import PropTypes from 'prop-types'
import DatePicker from 'react-datepicker'
import styled from 'styled-components'

import { FormsyErrorMessages } from '..'

const Container = styled.div`
  /**
  * formsy related
  */
  .form-group {
    &.has-feedback {
      .validation-message {
        color: #ef6461;
      }
    }

    &.has-error {
      input[type='text'],
      input[type='email'],
      input[type='date'],
      textarea {
        border-color: #ef6461;
      }
    }
  }

  .form-group.row {
    align-items: center;
    border-bottom: 1px solid #eaf0fc;
    display: flex;
    line-height: 32px;
    padding: 10px 0;
    white-space: pre-wrap;

    > span,
    > label,
    > div {
      width: 50%;
    }

    span,
    label,
    strong {
      color: #73819c;
    }

    input[type='text'],
    input[type='email'],
    input[type='date'],
    textarea {
      width: 100%;
    }

    &.has-feedback {
      .validation-message {
        display: block;
        line-height: 20px;
        text-transform: none;
        width: 100%;
      }
    }

    &.is-date-picker > div > div {
      display: block;

      & > div {
        display: block;

        & > div {
          display: block;
        }
      }
    }

    .radio-inline {
      margin-right: 15px;

      &:last-child {
        margin-right: 0px;
      }
    }
  }

  .form-group__container {
    .form-group {
      border-bottom: 0;
      padding: 0;

      .checkbox,
      .radio {
        line-height: 25px;
      }
    }

    .form-group__entry {
      padding-top: 15px;

      > .control-label {
        margin-right: 3%;
        text-align: right;
        width: 47%;
      }
    }
  }
`

const StyledDatePicker = styled(DatePicker)`
  .react-datepicker-popper {
    z-index: 100;
  }
`

// eslint-disable-next-line no-restricted-properties
class FormsyDatePicker extends React.Component {
  UNSAFE_componentWillMount() {
    const initialValue = this.props.getValue()
    const validInitialValue = !!(initialValue && initialValue.length)

    if (this.props.required && !validInitialValue) {
      const todayMoment = moment()
      this.props.setValue(todayMoment.format('YYYY-MM-DD'))

      if (this.props.additionalValueListener) {
        this.props.additionalValueListener(todayMoment)
      }
    } else if (this.props.additionalValueListener) {
      const momentObj = validInitialValue ? moment(initialValue) : null
      this.props.additionalValueListener(momentObj)
    }
  }

  changeValue = (momentObj) => {
    if (momentObj) {
      this.props.setValue(momentObj.format('YYYY-MM-DD'))
    } else if (!this.props.required) {
      this.props.setValue(null)
    }

    if (this.props.additionalValueListener) {
      this.props.additionalValueListener(momentObj)
    }
  }

  render() {
    const errorMessages = this.props.getErrorMessages()
    const className =
      errorMessages.length > 0 ? 'is-date-picker has-error' : 'is-date-picker'
    const selected = this.props.getValue()
      ? moment(this.props.getValue(), 'YYYY-MM-DD')
      : null
    let startDate = null
    let endDate = null
    if (this.props.rangeType === 'start') {
      startDate = selected
      endDate = this.props.endDate // eslint-disable-line prefer-destructuring
    } else if (this.props.rangeType === 'end') {
      startDate = this.props.startDate // eslint-disable-line prefer-destructuring
      endDate = selected
    }

    return (
      <Container>
        <Row
          layout={this.props.layout || 'horizontal'}
          {...this.props}
          rowClassName={`${className} ${this.props.className}`}
        >
          <StyledDatePicker
            selected={selected}
            disabled={this.props.disabled}
            selectsStart={this.props.rangeType === 'start'}
            selectsEnd={this.props.rangeType === 'end'}
            startDate={startDate}
            endDate={endDate}
            dateFormat="DD/MM/YYYY"
            disabledKeyboardNavigation
            isClearable={!this.props.required}
            locale="en-gb"
            minDate={this.props.minDate}
            maxDate={this.props.maxDate}
            onChange={this.changeValue}
            popperPlacement={this.props.popperPlacement || 'top'}
            peekNextMonth
            showMonthDropdown
            showYearDropdown
            scrollableYearDropdown
            dropdownMode="select"
            todayButton="Today"
            withPortal={this.props.withPortal}
            monthsShown={this.props.monthsShown || 1}
            openToDate={this.props.openToDate}
          />
          <FormsyErrorMessages errorMessages={errorMessages} />
        </Row>
      </Container>
    )
  }
}

FormsyDatePicker.propTypes = {
  name: PropTypes.string.isRequired,
  value: PropTypes.string,
  required: PropTypes.bool,
  monthsShown: PropTypes.number,
  additionalValueListener: PropTypes.func,
  minDate: PropTypes.object,
  maxDate: PropTypes.object,
  rangeType: PropTypes.string,
  startDate: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
  endDate: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
  dateFormat: PropTypes.string,
  withPortal: PropTypes.bool,
  popperPlacement: PropTypes.string,
  openToDate: PropTypes.object,
  // forsmy-react functions
  getValue: PropTypes.func.isRequired,
  setValue: PropTypes.func.isRequired,
  getErrorMessages: PropTypes.func.isRequired,
  className: PropTypes.string,
  layout: PropTypes.string,
  disabled: PropTypes.bool,
}

export default withFormsy(FormsyDatePicker)
