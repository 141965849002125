import React from 'react'

import PropTypes from 'prop-types'
import styled from 'styled-components'

import { ModalDialog, GenericButton } from 'components'
import { styles } from 'routes/accounts/createAccount/styles'

const Wrapper = styled.div`
  ${styles}
`

class RemoveCarerFromBlacklistDialog extends React.PureComponent {
  static propTypes = {
    clearActiveWorkflow: PropTypes.func.isRequired,
    removeBlacklistedCarer: PropTypes.func.isRequired,
    activeWorkflow: PropTypes.object.isRequired, // eslint-disable-line react/forbid-prop-types
  }

  render() {
    const { clearActiveWorkflow, removeBlacklistedCarer, activeWorkflow } =
      this.props

    if (activeWorkflow.name !== 'removeCarerFromBlacklist') {
      return null
    }

    const { accountId, professionalName, professionalId } =
      activeWorkflow.payload

    return (
      <ModalDialog heading="Remove a carer from blacklist">
        <Wrapper className="acct__section acct__section--dialog">
          <p>
            Are you sure you want to remove {professionalName} from this
            account&apos;s blacklist? They will be able to accept placements for
            this account.
          </p>
          <div className="acct__form-buttons">
            <GenericButton text="Cancel" onClick={clearActiveWorkflow} quiet />
            <GenericButton
              text="Confirm Removal"
              onClick={() => {
                removeBlacklistedCarer(accountId, professionalId)
                clearActiveWorkflow()
              }}
            />
          </div>
        </Wrapper>
      </ModalDialog>
    )
  }
}

export default RemoveCarerFromBlacklistDialog
