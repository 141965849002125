// @flow
import { replace } from 'connected-react-router'
import { connect } from 'react-redux'

import * as routes from 'routes'

import { createNew, clearValidation as clearValidationAction } from './actions'
import ProfessionalNew from './ProfessionalNew'
import { getProfessionalNewValidationErrorJs } from './selectors'

const mapActionCreators = (dispatch) => ({
  cancelEdit: () => dispatch(replace(routes.professionals)),
  createNew: (data) => dispatch(createNew(data)),
  clearValidation: () => dispatch(clearValidationAction()),
})

const mapStateToProps = (state) => ({
  validationErrors: getProfessionalNewValidationErrorJs(state),
})

export default connect(mapStateToProps, mapActionCreators)(ProfessionalNew)
