// @flow
// $FlowOptOut
import React, { useEffect } from 'react'

import { colors, Paragraph, spacings } from '@elder/common'
import { NoteOutlined, DeleteOutlineOutlined } from '@mui/icons-material'
import { Box, Button } from '@mui/material'
import { Field } from 'formik'
import Dropzone from 'react-dropzone'
import styled from 'styled-components'

import { File } from 'components/FileIcon'
import { ErrorLabel } from 'components/forms/ErrorLabel'
import type { File as FileType, FileDetails } from 'domain/file'

const messages = {
  instruction: 'Drag and drop files or click here.',
  noFiles: 'No files uploaded.',
}

const StyleTheDropzone = styled.div`
  > * {
    width: 100% !important;
    height: 170px !important;
  }
`

const InnerDropzone = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;
  margin-top: 32px;
  cursor: pointer;
`

const StyledFile = styled(File)`
  margin-bottom: ${spacings.small};
`

const StyledParagraph = styled(Paragraph)`
  color: ${colors.lightSmoke};
`

const Errors = styled.div`
  padding-bottom: ${spacings.tiny};
`

const Previews = styled.div`
  padding-top: ${spacings.small};
  padding-bottom: ${spacings.tiny};
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  gap: ${spacings.tiny};
`

const Thumbnail = styled.object`
  width: 100%;
  text-align: center;
  color: ${colors.lightSmoke};
  svg {
    width: 100%;
    font-size: 48px;
    padding-top: 80px;
  }
`

const NoFiles = styled(Paragraph)`
  color: ${colors.lightSmoke};
  padding-bottom: ${spacings.small};
`

type PreviewProps = {| +url: string |}

const Preview = ({ url }: PreviewProps) => (
  <Box
    sx={{
      display: 'flex',
      flexDirection: 'column',
      gap: 1,
      justifyContent: 'space-between',
      border: `1px solid ${colors.lightSmoke}`,
      padding: '2px',
      minHeight: '275px',
    }}
  >
    <Thumbnail data={url} type="image/png">
      <NoteOutlined />
      Unknown File Type
    </Thumbnail>
    <Button onClick={() => window.open(url)} small>
      View
    </Button>
  </Box>
)

// Existing files are string (as they have IDs)
// New files are FileType
type NewOrExistingFile = string | FileType

type Props = {|
  +name: string,
  +value?: Array<NewOrExistingFile>,
  +maxSize: ?number,
  +error?: string,
  +className?: string,
  +multiple?: boolean,
  +disabled?: boolean,
  +documentsToPreview?: Array<string>,
  +clearDocumentToPreview?: () => void,
|}

export const DocumentUpload = ({
  name,
  value = [],
  error,
  maxSize,
  className,
  multiple = true,
  disabled = false,
  documentsToPreview = [],
  clearDocumentToPreview = () => undefined,
}: Props) => {
  const maxSizeWithDefault = maxSize || 1024
  const MAX_DOCUMENT_FILE_SIZE_B = maxSizeWithDefault * 1024 * 1024
  useEffect(() => () => {
    if (value) {
      value.forEach((file) => {
        if (typeof file === 'object') {
          URL.revokeObjectURL(file.preview)
        }
      })
    }
  })

  const getFieldValue = (
    acceptedFiles: Array<FileDetails>,
    existingFiles: Array<NewOrExistingFile>,
  ): Array<NewOrExistingFile> => {
    const fieldValue = []
    if (acceptedFiles) {
      fieldValue.push(
        ...acceptedFiles.map((file) => ({
          // Flow is complaining about FileDetails not being compatible with createObjectURL
          // createObjectURL takes three types of object: File, Blob and MediaSource. Our FileDetails is compatible
          // with the File type expected by createObjectURL - but flow is complaining about FileDetails not being
          // compatible with Blob and MediaSource. Not sure how to make flow happy here.
          // $FlowOptOut
          preview: URL.createObjectURL(file),
          file,
        })),
      )
    }
    if (existingFiles) {
      fieldValue.push(...existingFiles)
    }
    return fieldValue
  }

  const handleDropPicture = (
    acceptedFiles: Array<FileDetails> = [],
    rejectedFiles: Array<FileDetails> = [],
    existingFiles: Array<NewOrExistingFile>,
    setFieldValue: (string, Array<NewOrExistingFile>, ?boolean) => void,
    setFieldError: (string, string) => void,
  ) => {
    const hasTooLargeFiles = rejectedFiles.some(
      (rejectedFile) => rejectedFile.size >= MAX_DOCUMENT_FILE_SIZE_B,
    )
    const fieldValue = getFieldValue(acceptedFiles, existingFiles)
    if (!hasTooLargeFiles) {
      setFieldValue(name, fieldValue)
      return
    }
    setFieldError(
      name,
      `One or more of the files was over ${maxSizeWithDefault}MB.`,
    )
    // We don't want to validate the field further if we've already identified
    // that there is a file  that is too large, because our 'file too large' error will be
    // overridden with a 'you have no files' error in the case that the document is a required field,
    // and that all files uploaded in that drop were too large
    setFieldValue(name, fieldValue, false)
  }

  const removeFile = (
    files: Array<NewOrExistingFile>,
    setFieldValue: (string, Array<NewOrExistingFile>) => void,
  ) => {
    files.forEach((file) => {
      if (typeof file === 'object') {
        URL.revokeObjectURL(file.preview)
      }
    })
    setFieldValue(name, [])
  }

  return (
    <Field name={name}>
      {({ form: { setFieldValue, setFieldError } }) => (
        <div className={className}>
          <div>
            <StyleTheDropzone>
              {!disabled && (
                <Dropzone
                  onDrop={(acceptedFiles, rejectedFiles) =>
                    // react-dropzone doesn't use flow types, so we can't use their types. Our types are basically correct,
                    // but flow is complaining about the fact that our objects are exact and our properties are read-only - couldn't
                    // find a way around this
                    handleDropPicture(
                      // $FlowOptOut
                      acceptedFiles,
                      // $FlowOptOut
                      rejectedFiles,
                      value,
                      setFieldValue,
                      setFieldError,
                    )
                  }
                  multiple={multiple}
                  maxSize={MAX_DOCUMENT_FILE_SIZE_B}
                >
                  <InnerDropzone>
                    <StyledFile />
                    <StyledParagraph label={messages.instruction} />
                  </InnerDropzone>
                </Dropzone>
              )}
            </StyleTheDropzone>
          </div>
          {error && (
            <Errors>
              <ErrorLabel key={error} errorLabel={error} />
            </Errors>
          )}
          {documentsToPreview.length > 0 && (
            <Previews>
              {documentsToPreview.map((file) => (
                <Preview key={file} url={file} />
              ))}
            </Previews>
          )}
          {value && value.length > 0 && (
            <Box mb={4}>
              <Previews>
                {value.map(
                  (file) =>
                    typeof file === 'object' && (
                      <Preview
                        key={file.file.preview}
                        url={file.file.preview}
                      />
                    ),
                )}
              </Previews>
              {!disabled && (
                <Button
                  onClick={() => {
                    removeFile(value, setFieldValue)
                    clearDocumentToPreview()
                  }}
                  startIcon={<DeleteOutlineOutlined />}
                >
                  Remove All Files
                </Button>
              )}
            </Box>
          )}
          {!value ||
            (value.length === 0 && disabled && (
              <NoFiles label={messages.noFiles} />
            ))}
        </div>
      )}
    </Field>
  )
}
