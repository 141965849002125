// @flow
export const expectedBasicProfileFields = {
  firstName: true,
  lastName: true,
  companyName: true,
  emailAddress: true,
  phoneNumbers: true,
  address1: true,
  address2: true,
  city: true,
  postcode: true,
}
