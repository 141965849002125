// @flow
import React from 'react'

import { colors, typography } from '@elder/common'
import showdown from 'showdown'
import styled from 'styled-components'

// set options here as per https://github.com/showdownjs/showdown
const converter = new showdown.Converter({
  emoji: true,
  tasklists: true,
  tables: true,
  simpleLineBreaks: true,
  strikethrough: true,
})

export const markdownConverter = converter

const MarkdownStyles = styled.div`
  p {
    ${typography.paragraph1};
    color: ${colors.marceline};
    white-space: pre-wrap;
  }

  strong {
    font-weight: 700;
  }

  h1 {
    ${typography.h2};
    color: ${colors.blue700};
    :not(:first-child) {
      margin-top: 24px;
    }
  }

  h2 {
    :not(:first-child) {
      margin-top: 16px;
    }
    ${typography.h3};
    color: ${colors.blue700} !important;
    margin-bottom: 0 !important;
  }

  h3 {
    color: ${colors.marceline} !important;
    ${typography.paragraph1Bold};
    :not(:first-child) {
      margin-top: 16px;
    }
  }

  h4 {
    color: ${colors.marceline} !important;
    ${typography.paragraph1Bold};
    :not(:first-child) {
      margin-top: 16px;
    }
  }

  h5 {
    color: ${colors.marceline} !important;
    ${typography.paragraph1Bold};
    :not(:first-child) {
      margin-top: 16px;
    }
  }
`

type Props = {|
  +markdown: string,
|}

export const DisplayMarkdownAsHtml = ({ markdown }: Props) => (
  <MarkdownStyles>
    <div // eslint-disable-next-line react/no-danger
      dangerouslySetInnerHTML={{
        __html: markdownConverter.makeHtml(markdown),
      }}
    />
  </MarkdownStyles>
)
