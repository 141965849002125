// @flow

// Heavily inspired by https://gist.github.com/matttti/ae0d65bf534cb84a0dbe4ddd3b0f759c
// referenced from https://github.com/redux-saga/redux-saga/issues/773, with addition of type safe takes() using
// an action factory as parameter over just plain string.

import { delay } from 'redux-saga'
import type { Saga } from 'redux-saga'
import * as Effects from 'redux-saga/effects'

import type { Action, ActionCreatorAny, Selector } from 'elder/types'

type Context = Object

// Note: Sagas are not currently typed strictly on the type of store, e.g. you can use selectors that takes any object
// as argument.
type Store = Object

export type Task<T> = {|
  isRunning: () => boolean,
  isCancelled: () => boolean,
  result: ?T,
  error: ?mixed,
  done: Promise<T>,
  cancel: () => void,
|}

// TODO: Add cases for more arguments as needed
type Take = {
  <AT: string, A: Action<AT, *>>(
    actionCreator: ActionCreatorAny<AT, A>,
  ): Saga<A>,
}

// type SagaFunction0<R> = () => Saga<R>
type SagaFunction1<T1, R> = (p1: T1) => Saga<R>
type SagaFunction2<T1, T2, R> = (p1: T1, p2: T2) => Saga<R>

type TakeLatest = {
  <AT: string, A: Action<AT, *>, R>(
    actionCreator: ActionCreatorAny<AT, A>,
    saga: SagaFunction1<A, R>,
  ): Saga<R>,
  <AT: string, A: Action<AT, *>, T1, R>(
    actionCreator: ActionCreatorAny<AT, A>,
    saga: SagaFunction2<T1, A, R>,
    p1: T1,
  ): Saga<R>,
}

type Callable0<R> = () => Promise<R> | Saga<R> | R
type Callable1<T1, R> = (p1: T1) => Promise<R> | Saga<R> | R
type Callable2<T1, T2, R> = (p1: T1, p2: T2) => Promise<R> | Saga<R> | R
type Callable3<T1, T2, T3, R> = (
  p1: T1,
  p2: T2,
  p3: T3,
) => Promise<R> | Saga<R> | R
type Callable4<T1, T2, T3, T4, R> = (
  p1: T1,
  p2: T2,
  p3: T3,
  p4: T4,
) => Promise<R> | Saga<R> | R
type Callable5<T1, T2, T3, T4, T5, R> = (
  p1: T1,
  p2: T2,
  p3: T3,
  p4: T4,
  p5: T5,
) => Promise<R> | Saga<R> | R

type Call = {
  // Without context (function as first argument)
  <R, C: Callable0<R>>(fun: C): Saga<R>,
  <T1, R, C: Callable1<T1, R>>(fun: C, p1: T1): Saga<R>,
  <T1, T2, R, C: Callable2<T1, T2, R>>(fun: C, p1: T1, p2: T2): Saga<R>,
  <T1, T2, T3, R, C: Callable3<T1, T2, T3, R>>(
    fun: C,
    p1: T1,
    p2: T2,
    p3: T3,
  ): Saga<R>,
  <T1, T2, T3, T4, R, C: Callable4<T1, T2, T3, T4, R>>(
    fun: C,
    p1: T1,
    p2: T2,
    p3: T3,
    p4: T4,
  ): Saga<R>,
  <T1, T2, T3, T4, T5, R, C: Callable5<T1, T2, T3, T4, T5, R>>(
    fun: C,
    p1: T1,
    p2: T2,
    p3: T3,
    p4: T4,
    p5: T5,
  ): Saga<R>,

  // With context (array as first argument)
  <R, T: Context, C: Callable0<R>>(cFun: [T, C]): Saga<R>,
  <T1, R, T: Context, C: Callable1<T1, R>>(cFun: [T, C], p1: T1): Saga<R>,
  <T1, T2, R, T: Context, C: Callable2<T1, T2, R>>(
    cFun: [T, C],
    p1: T1,
    p2: T2,
  ): Saga<R>,
  <T1, T2, T3, R, T: Context, C: Callable3<T1, T2, T3, R>>(
    cFun: [T, C],
    p1: T1,
    p2: T2,
    p3: T3,
  ): Saga<R>,

  <T1, T2, T3, T4, R, T: Context, C: Callable4<T1, T2, T3, T4, R>>(
    cFun: [T, C],
    p1: T1,
    p2: T2,
    p3: T3,
    p4: T4,
  ): Saga<R>,

  <T1, T2, T3, T4, T5, R, T: Context, C: Callable5<T1, T2, T3, T4, T5, R>>(
    cFun: [T, C],
    p1: T1,
    p2: T2,
    p3: T3,
    p4: T4,
    p5: T5,
  ): Saga<R>,
}

type Forkable0<R> = () => Promise<R> | Saga<R>
type Forkable1<T1, R> = (p1: T1) => Promise<R> | Saga<R>
type Forkable2<T1, T2, R> = (p1: T1, p2: T2) => Promise<R> | Saga<R>
type Forkable3<T1, T2, T3, R> = (p1: T1, p2: T2, p3: T3) => Promise<R> | Saga<R>
type Forkable4<T1, T2, T3, T4, R> = (
  p1: T1,
  p2: T2,
  p3: T3,
  p4: T4,
) => Promise<R> | Saga<R>
type Forkable5<T1, T2, T3, T4, T5, R> = (
  p1: T1,
  p2: T2,
  p3: T3,
  p4: T4,
  p5: T5,
) => Promise<R> | Saga<R>

type Fork = {
  // Without context (function as first argument)
  <R, C: Forkable0<R>>(fun: C): Saga<Task<R>>,
  <T1, R, C: Forkable1<T1, R>>(fun: C, p1: T1): Saga<Task<R>>,
  <T1, T2, R, C: Forkable2<T1, T2, R>>(fun: C, p1: T1, p2: T2): Saga<Task<R>>,
  <T1, T2, T3, R, C: Forkable3<T1, T2, T3, R>>(
    fun: C,
    p1: T1,
    p2: T2,
    p3: T3,
  ): Saga<Task<R>>,
  <T1, T2, T3, T4, R, C: Forkable4<T1, T2, T3, T4, R>>(
    fun: C,
    p1: T1,
    p2: T2,
    p3: T3,
    p4: T4,
  ): Saga<Task<R>>,
  <T1, T2, T3, T4, T5, R, C: Forkable5<T1, T2, T3, T4, T5, R>>(
    fun: C,
    p1: T1,
    p2: T2,
    p3: T3,
    p4: T4,
    p5: T5,
  ): Saga<Task<R>>,

  // With context (array as first argument)
  <R, T: Context, C: Forkable0<R>>(cFun: [T, C]): Saga<Task<R>>,
  <T1, R, T: Context, C: Forkable1<T1, R>>(cFun: [T, C], p1: T1): Saga<Task<R>>,
  <T1, T2, R, T: Context, C: Forkable2<T1, T2, R>>(
    cFun: [T, C],
    p1: T1,
    p2: T2,
  ): Saga<Task<R>>,
  <T1, T2, T3, R, T: Context, C: Forkable3<T1, T2, T3, R>>(
    cFun: [T, C],
    p1: T1,
    p2: T2,
    p3: T3,
  ): Saga<Task<R>>,

  <T1, T2, T3, T4, R, T: Context, C: Forkable4<T1, T2, T3, T4, R>>(
    cFun: [T, C],
    p1: T1,
    p2: T2,
    p3: T3,
    p4: T4,
  ): Saga<Task<R>>,

  <T1, T2, T3, T4, T5, R, T: Context, C: Forkable5<T1, T2, T3, T4, T5, R>>(
    cFun: [T, C],
    p1: T1,
    p2: T2,
    p3: T3,
    p4: T4,
    p5: T5,
  ): Saga<Task<R>>,
}

type Put = (Action<*, *>) => Saga<void>

type Debug = (any) => Saga<void>

type MaybeExtractSagaResponse = <R>(sagaFactory: () => Saga<R>) => ?R
type Race = <O: { [key: string]: () => Saga<any> }>(
  O,
) => Saga<$ReadOnly<$ObjMap<O, MaybeExtractSagaResponse>>>

type All = <T>(Array<Saga<T>>) => Saga<Array<T>>

type Select = <S: Store, T>(Selector<S, T>) => Saga<T>

function* takeGenerator<AT>(actionCreator: ActionCreator<AT>): Saga<*> {
  const { actionType } = actionCreator
  return yield Effects.take(actionType)
}

function* putGenerator(action: Action<*, *>): Saga<void> {
  yield Effects.put(action)
}

function* debugGenerator(payload: any): Saga<void> {
  yield Effects.put({
    type: `@@DEBUG:${
      typeof payload === 'string' ? payload : JSON.stringify(payload)
    }`,
    payload,
  })
}

function* callGenerator(...args): Saga<*> {
  return yield (Effects.call: any)(...args)
}

function* forkGenerator(...args): Saga<*> {
  return yield (Effects.fork: any)(...args)
}

function* takeLatestGenerator(
  actionCreator: ActionCreatorAny<*, *>,
  saga: () => Saga<*>,
  ...args
) {
  return yield (Effects.takeLatest: any)(
    actionCreator.actionType,
    saga,
    ...args,
  )
}

function* raceGenerator(sagas) {
  const effects = Object.keys(sagas).reduce(
    (acc, k) => Object.assign(acc, { [k]: Effects.call(sagas[k]) }),
    {},
  )

  return yield Effects.race(effects)
}

function* allGenerator(sagas) {
  return yield Effects.all(sagas)
}

function* selectGenerator(selector) {
  return yield Effects.select(selector)
}

export const take: Take = (takeGenerator: any)
export const takeLatest: TakeLatest = (takeLatestGenerator: any)
export const put: Put = putGenerator
export const debug: Debug = debugGenerator
export const call: Call = (callGenerator: any)
export const fork: Fork = (forkGenerator: any)
export const race: Race = (raceGenerator: any)
export const all: All = (allGenerator: any)
export const select: Select = (selectGenerator: any)

/* Higher order utility effects */
type CallWithMinDuration = {
  // Without context (function as first argument)
  <R, C: Callable0<R>>(minDuration: number, fun: C): Saga<R>,
  <T1, R, C: Callable1<T1, R>>(minDuration: number, fun: C, p1: T1): Saga<R>,
  <T1, T2, R, C: Callable2<T1, T2, R>>(
    minDuration: number,
    fun: C,
    p1: T1,
    p2: T2,
  ): Saga<R>,
  <T1, T2, T3, R, C: Callable3<T1, T2, T3, R>>(
    minDuration: number,
    fun: C,
    p1: T1,
    p2: T2,
    p3: T3,
  ): Saga<R>,
  <T1, T2, T3, T4, R, C: Callable4<T1, T2, T3, T4, R>>(
    minDuration: number,
    fun: C,
    p1: T1,
    p2: T2,
    p3: T3,
    p4: T4,
  ): Saga<R>,
  <T1, T2, T3, T4, T5, R, C: Callable5<T1, T2, T3, T4, T5, R>>(
    minDuration: number,
    fun: C,
    p1: T1,
    p2: T2,
    p3: T3,
    p4: T4,
    p5: T5,
  ): Saga<R>,

  // With context (array as first argument)
  <R, T: Context, C: Callable0<R>>(minDuration: number, cFun: [T, C]): Saga<R>,
  <T1, R, T: Context, C: Callable1<T1, R>>(
    minDuration: number,
    cFun: [T, C],
    p1: T1,
  ): Saga<R>,
  <T1, T2, R, T: Context, C: Callable2<T1, T2, R>>(
    minDuration: number,
    cFun: [T, C],
    p1: T1,
    p2: T2,
  ): Saga<R>,
  <T1, T2, T3, R, T: Context, C: Callable3<T1, T2, T3, R>>(
    minDuration: number,
    cFun: [T, C],
    p1: T1,
    p2: T2,
    p3: T3,
  ): Saga<R>,

  <T1, T2, T3, T4, R, T: Context, C: Callable4<T1, T2, T3, T4, R>>(
    minDuration: number,
    cFun: [T, C],
    p1: T1,
    p2: T2,
    p3: T3,
    p4: T4,
  ): Saga<R>,

  <T1, T2, T3, T4, T5, R, T: Context, C: Callable5<T1, T2, T3, T4, T5, R>>(
    minDuration: number,
    cFun: [T, C],
    p1: T1,
    p2: T2,
    p3: T3,
    p4: T4,
    p5: T5,
  ): Saga<R>,
}

function* callWithMinDurationGenerator(minDurationMillis, ...args): Saga<*> {
  const minDelay = yield* fork(delay, minDurationMillis)
  try {
    return yield Effects.call(...args)
  } finally {
    yield Effects.join(minDelay)
  }
}

export const callWithMinDuration: CallWithMinDuration =
  (callWithMinDurationGenerator: any)
