// @flow
import { replace } from 'connected-react-router'
import type { Saga } from 'redux-saga'
import { take, call, put } from 'redux-saga/effects'

import { getConfig } from 'app/config'
import {
  CREATE,
  createNewValidationFail,
} from 'features/professionals/professionalNew/actions'
import { expectedProfessionalNewFields } from 'features/professionals/professionalNew/validFields'
import { applicationSuccess } from 'features/snackbar/snackbar'
import { manageErrorWithFieldValidation } from 'utils/fieldValidationErrors'
import { fetchFlow } from 'utils/services'

const { environment } = getConfig()

export function* postProfessionalNew(data: Object): Generator<*, void, *> {
  const newCarerEndpoint = '/et/professionals/'

  try {
    const responseBody = yield call(fetchFlow, newCarerEndpoint, 'POST', data)
    const proId = responseBody.professionalId
    applicationSuccess({
      title: 'Professional created',
      message: 'Professional was created successfully.',
    })

    // Set carer status to ONBOARDING when not on live
    // This is done during the fountain part of the onboarding process on live
    if (environment !== 'LIVE') {
      const updateCarerStatusEndpoint = `/et/professionals/${proId}/status`
      yield call(fetchFlow, updateCarerStatusEndpoint, 'PUT', {
        status: 'ONBOARDING',
      })
      applicationSuccess({
        title: 'Professional status set to onboarding',
        message: 'Professional status was updated successfully.',
      })
    }

    yield put(replace(`/professionals/${proId}`))
  } catch (error) {
    yield call(
      manageErrorWithFieldValidation,
      error,
      'professionalNewValidationError',
      expectedProfessionalNewFields,
      createNewValidationFail,
    )
  }
}

export function* watchProfessionalNew(): Saga<*> {
  while (true) {
    const payload = yield take(CREATE)
    yield call(postProfessionalNew, payload.data)
  }
}
