// @flow
import type { Saga } from 'redux-saga'
import { takeLatest, put, call } from 'redux-saga/effects'

import { clearLoading, setLoading } from 'features/loading/actions'
import {
  applicationError,
  applicationSuccess,
} from 'features/snackbar/snackbar'
import {
  NAMESPACE,
  UPDATE_CARE_MODEL_TYPE,
  updateCareModelError,
  pageActions,
} from 'routes/account/summary/actions'
import { putFlow, describeServiceError, getAndSetFlow } from 'utils/services'

const serviceError = (error, message) =>
  applicationError(describeServiceError(error, message))

function* handleUpdateCareModel({ accountId, regulatoryModel }): Saga<*> {
  const endpoint = `/et/accounts/${accountId}/regulatoryModel`

  yield put(setLoading(NAMESPACE))
  try {
    yield call(putFlow, endpoint, { regulatoryModel }, 'none')

    // update account details
    yield call(getAndSetFlow, `/et/accounts/${accountId}`, pageActions.set)

    // successful update notification
    applicationSuccess({
      title: 'Care model saved',
      message: `Sucessfully updated care model type to ${regulatoryModel}`,
    })
  } catch (error) {
    yield put(updateCareModelError())
    serviceError(error, 'Unable to update care model type')
  } finally {
    yield put(clearLoading(NAMESPACE))
  }
}

export function* careModelTypeSagas(): Saga<*> {
  yield takeLatest(UPDATE_CARE_MODEL_TYPE, handleUpdateCareModel)
}
