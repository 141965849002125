// @flow
import type { Saga } from 'redux-saga'
import { call, takeLatest } from 'redux-saga/effects'

import {
  GET_CARER_IDENTITY,
  setCarerIdentity,
} from 'features/carerDetailsHeader/store/actions'
import { getAndSetFlow } from 'utils/services'

function* getCarerIdentitySaga({ carerId }): Saga<*> {
  yield call(
    getAndSetFlow,
    `/et/professionals/${carerId}/identity`,
    setCarerIdentity,
  )
}

export function* sagas(): Saga<*> {
  yield takeLatest(GET_CARER_IDENTITY, getCarerIdentitySaga)
}
