// @flow
import React from 'react'

import type { ReferenceDataOptions } from '@elder/common'
import { Paragraph, FormikSelect, FormikTextArea, colors } from '@elder/common'
import { Stack, Button } from '@mui/material'
import { Formik, Form } from 'formik'
import { uniq } from 'lodash'
import styled from 'styled-components'
import * as Yup from 'yup'

import { FormikDatePicker } from 'components/FormikDatePicker'
import { Checkbox } from 'components/forms/Checkbox'
import { SectionHeader } from 'components/styledComponentsTranslatedClasses/SectionHeader'

const RowStack = styled(Stack)({
  flexDirection: 'row',
  gap: 16,
  '&>*': { flex: 1 },
})

const getCategoryOfChurnReason = (
  churnOptions: ReferenceDataOptions,
  churnReason: string,
) => {
  const churnReasonOption = churnOptions.options.find(
    (option) => option.value === churnReason,
  )
  if (!churnReasonOption) {
    throw new Error(`Couldn't find churn option with value ${churnReason}`)
  }
  return churnReasonOption.metadata?.category
}

type Props = {|
  archiveAccount: ({|
    archiveDate: string,
    churnDescription: string,
    churnOutcome: string,
    onwardProvider?: string,
    churnReason: string,
    status: string,
    potentialWinback: ?boolean,
    churnSecondaryReasons: Array<string>,
  |}) => void,
  cancelEdit: () => void,
  title: string,
  text: string,
  churnOptions: ReferenceDataOptions,
  closureOutcomesOptions: ReferenceDataOptions,
  onwardProviderOptions: ReferenceDataOptions,
  editMode?: boolean,
  archiveDetails?: {|
    churnReason: string,
    churnOutcome: string,
    onwardProvider?: string,
    churnDescription: string,
    archiveDate: string,
    archiveNoticeAt: string,
    potentialWinback: boolean,
    churnSecondaryReasons: Array<string>,
  |},
|}

export const ArchiveForm = ({
  archiveAccount,
  cancelEdit,
  title,
  text,
  churnOptions,
  closureOutcomesOptions,
  onwardProviderOptions,
  archiveDetails,
  editMode,
}: Props) => (
  <Formik
    onSubmit={(data) => {
      data.status = 'ARCHIVED' // eslint-disable-line no-param-reassign
      if (!data.onwardProvider) {
        delete data.onwardProvider // eslint-disable-line no-param-reassign
      }
      archiveAccount(data)
    }}
    initialValues={{
      churnReasonCategory:
        archiveDetails && archiveDetails.churnReason
          ? getCategoryOfChurnReason(churnOptions, archiveDetails.churnReason)
          : '',
      churnReason: archiveDetails ? archiveDetails.churnReason : '',
      churnOutcome: archiveDetails ? archiveDetails.churnOutcome : '',
      onwardProvider: archiveDetails ? archiveDetails.onwardProvider : '',
      churnDescription: archiveDetails ? archiveDetails.churnDescription : '',
      archiveDate: archiveDetails ? archiveDetails.archiveDate : '',
      potentialWinback: archiveDetails
        ? archiveDetails.potentialWinback
        : false,
      churnSecondaryReasons: archiveDetails?.churnSecondaryReasons || [],
    }}
    validationSchema={Yup.object({
      churnReasonCategory: Yup.string().required('Churn type is required'),
      churnReason: Yup.string().required('Churn reason is required'),
      churnSecondaryReasons: Yup.array(Yup.string()),
      churnDescription: Yup.string().required('Additional context is required'),
      churnOutcome: Yup.string().required('Onward care is required'),
      onwardProvider: Yup.string(),
      archiveDate: Yup.date().required('Last visit date is required'),
      potentialWinback: Yup.boolean(),
    })}
  >
    {({ submitCount, handleSubmit, errors, values, isValid, dirty }) => {
      const churnCategories = uniq(
        churnOptions.options.map((option) => option.metadata?.category),
      )
      const churnCategoryOptions = churnCategories.map((category) => ({
        value: category,
        label: category,
      }))
      const churnReasonsInCategory = churnOptions.options.filter(
        (option) => option.metadata?.category === values.churnReasonCategory,
      )
      return (
        <Stack component={Form} spacing={2}>
          <SectionHeader>
            <h3>{title}</h3>
          </SectionHeader>

          <Paragraph label={text} />
          <RowStack useFlexGap>
            <FormikSelect
              name="churnReasonCategory"
              label="Churn type"
              options={churnCategoryOptions}
              errorLabel={submitCount > 0 ? errors.churnReasonCategory : null}
            />
            <FormikSelect
              name="churnReason"
              label="Churn reason"
              options={churnReasonsInCategory}
              errorLabel={submitCount > 0 ? errors.churnReason : null}
            />
          </RowStack>

          <FormikSelect
            label="Other influencing factors"
            name="churnSecondaryReasons"
            options={churnOptions.options}
            errorLabel={submitCount > 0 ? errors.churnSecondaryReasons : null}
            isMulti
          />

          <FormikTextArea
            name="churnDescription"
            label="Additional Context"
            errorLabel={submitCount > 0 ? errors.churnDescription : null}
            rows={3}
          />

          <RowStack useFlexGap>
            <FormikSelect
              name="churnOutcome"
              label="Onward care"
              options={closureOutcomesOptions.options}
              errorLabel={submitCount > 0 ? errors.churnReason : null}
            />
            <FormikSelect
              name="onwardProvider"
              label="Onward care provider"
              options={onwardProviderOptions.options}
              errorLabel={submitCount > 0 ? errors.onwardProvider : null}
            />
          </RowStack>

          <RowStack useFlexGap>
            <FormikDatePicker
              name="archiveDate"
              label="Last visit "
              errorLabel={submitCount > 0 ? errors.archiveDate : null}
              popperPlacement="right"
              disabled={!!editMode}
              clearable={false}
            />
            <Checkbox
              name="potentialWinback"
              label="Winback potential"
              colourAndContrast={{
                primary: colors.marceline,
                contrast: 'white',
                outline: colors.marceline,
              }}
              errorLabel={submitCount > 0 ? errors.potentialWinback : null}
            />
          </RowStack>

          <Stack direction="row" spacing={2} justifyContent="flex-end">
            <Button onClick={cancelEdit} variant="text">
              Cancel
            </Button>
            <Button
              disabled={!isValid || !dirty}
              onClick={handleSubmit}
              variant="contained"
            >
              {editMode ? 'Confirm details' : 'Archive'}
            </Button>
          </Stack>
        </Stack>
      )
    }}
  </Formik>
)
