// @flow
import React from 'react'

import { withFormsy } from 'formsy-react'
import { Row } from 'formsy-react-components'
import Select from 'react-select'
import styled from 'styled-components'

import { FormsyErrorMessages } from 'components/FormsyErrorMessages/FormsyErrorMessages'
import type { RptOptions } from 'utils/reactHelpers'

const StyledRow = styled(Row)`
  .formsy-select {
    @select-menu-max-height: 1000px;

    &.has-error {
      > div {
        border-color: #ef6461;
      }
    }
  }

  .is-open .Select-control {
    border-color: #19305e;
  }

  .Select-placeholder {
    color: #cad9f8;
  }

  .Select-arrow,
  .Select-arrow-zone:hover > .Select-arrow {
    border-top-color: #cad9f8;
  }

  .is-open > .Select-control .Select-arrow {
    border-bottom-color: #cad9f8;
  }

  /**
 * react-select related
 */
  .Select-control {
    transition: border-color 0.3s ease;
    border: 2px solid #cad9f8;
    border-radius: 0px;

    &:hover {
      box-shadow: 0 0 0 0;
    }
  }

  /**
 * formsy related
 */
  .form-group {
    &.has-feedback {
      .validation-message {
        color: #ef6461;
      }
    }

    &.has-error {
      input[type='text'],
      input[type='email'],
      input[type='date'],
      textarea {
        border-color: #ef6461;
      }
    }
  }

  .form-group.row {
    align-items: center;
    border-bottom: 1px solid #eaf0fc;
    display: flex;
    line-height: 32px;
    padding: 10px 0;
    white-space: pre-wrap;

    > span,
    > label,
    > div {
      width: 50%;
    }

    span,
    label,
    strong {
      color: #73819c;
    }

    input[type='text'],
    input[type='email'],
    input[type='date'],
    textarea {
      width: 100%;
    }

    &.has-feedback {
      .validation-message {
        display: block;
        line-height: 20px;
        text-transform: none;
        width: 100%;
      }
    }

    &.is-date-picker > div > div {
      display: block;

      & > div {
        display: block;

        & > div {
          display: block;
        }
      }
    }

    .radio-inline {
      margin-right: 15px;

      &:last-child {
        margin-right: 0px;
      }
    }
  }

  .form-group__container {
    .form-group {
      border-bottom: 0;
      padding: 0;

      .checkbox,
      .radio {
        line-height: 25px;
      }
    }

    .form-group__entry {
      padding-top: 15px;

      > .control-label {
        margin-right: 3%;
        text-align: right;
        width: 47%;
      }
    }
  }

  select {
    transition: border-color 0.3s ease;
    background-color: #ffffff;
    border: solid 2px #cad9f8;
    box-sizing: border-box;
    padding: 8px 10px;
    width: 100%;

    &:focus {
      border: solid 2px #19305e;
    }

    &:disabled {
      opacity: 0.5;
    }

    &::placeholder {
      color: #cad9f8;
      text-transform: capitalize;
    }
  }
`

type Props = {|
  +name: string,
  +value?: string,
  +clearable?: boolean,
  +isLoading?: boolean,
  +onInputChange?: () => void,
  +onChange?: (any) => void,
  +options: RptOptions,
  +getValue: () => void,
  +setValue: (?string) => void,
  +getErrorMessages: () => Array<string>,
  +required?: boolean,
  +disabled?: boolean,
  +className?: string,
  +layout?: string,
|}

// eslint-disable-next-line no-restricted-properties
class FormsySelect extends React.Component<Props> {
  changeValue = (event) => {
    this.props.setValue(event ? event.value : null)

    if (this.props.onChange) {
      this.props.onChange(event)
    }
  }

  render() {
    const errorMessages = this.props.getErrorMessages()

    return (
      <StyledRow
        rowClassName={this.props.className}
        layout={this.props.layout || 'horizontal'}
        {...this.props}
      >
        <Select
          name={this.props.name}
          value={this.props.getValue()}
          onChange={this.changeValue}
          clearable={this.props.clearable}
          isLoading={this.props.isLoading}
          onInputChange={this.props.onInputChange}
          options={this.props.options}
          classNames={
            errorMessages.length > 0
              ? 'formsy-select has-error'
              : 'formsy-select'
          }
          required={this.props.required}
          disabled={!!this.props.disabled}
        />
        <FormsyErrorMessages errorMessages={errorMessages} />
      </StyledRow>
    )
  }
}

export default withFormsy(FormsySelect)
