// @flow
import { createSelector } from 'reselect'

import type { ElderUsers } from 'features/elderUsers/domain'
import { NAMESPACE } from 'features/elderUsers/store/actions'

const getElderUserStateSelector = (state) => state.get(NAMESPACE)

const getElderUsersState = createSelector(getElderUserStateSelector, (state) =>
  state.toJS(),
)

export const elderUsersSelector = createSelector(
  [getElderUsersState],
  (elderUsers): ElderUsers => elderUsers.elderUsers,
)

export const hasErrorSelector = createSelector(
  [getElderUsersState],
  (elderUsers): boolean => elderUsers.hasError,
)

const getFiltersState = (state) =>
  getElderUserStateSelector(state).get('filters')

export const getFilters = createSelector([getFiltersState], (filtersState) =>
  filtersState.toJS(),
)
