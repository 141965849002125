// @flow
/* eslint-disable flowtype/no-weak-types */
import { createSelector } from 'reselect'

export const makeSelectors = (
  autocompleteProfilesSelector: (Object) => Object,
) => {
  const getAutocompleteProfiles = (state) =>
    autocompleteProfilesSelector(state).get('autocompleteProfiles')

  return {
    getJsAutocompleteProfiles: createSelector(
      [getAutocompleteProfiles],
      (autocompleteProfiles) =>
        autocompleteProfiles
          .map((profile) => {
            const profileId = profile.get('profileId')

            return {
              value: profileId,
              fullName: profile.get('fullName'),
              label: `${profile.get('fullName')} #${profileId}`,
              email: profile.get('email'),
              phoneNumber: profile.get('phoneNumber'),
            }
          })
          .toJS(),
    ),
    getIsLoading: (state: Object) =>
      autocompleteProfilesSelector(state).get('isLoading'),
  }
}
