// @flow
import { createSelector } from 'reselect'

import { NAMESPACE } from '.'

const getLoadingState = (state) => state.get(NAMESPACE).toJS()

export const getIsLoadingSelector = createSelector(
  [getLoadingState, (_, id) => id],
  (state, id) => state.loading.includes(id),
)

export const getLoadingGroupSelector = createSelector(
  [getLoadingState, (_, matchingString) => matchingString],
  (state, matchingString) =>
    state.loading.filter((item) => item.includes(matchingString)),
)
