// @flow
import React from 'react'

import { EditOutlined } from '@mui/icons-material'
import { Button } from '@mui/material'
import styled from 'styled-components'

import { AccountEntry } from 'components/styledComponentsTranslatedClasses/AccountEntry'

const AccountEntrySmallEdit = styled.div`
  align-items: center;
  display: flex;
  justify-content: space-between;

  & > div {
    margin-right: 10px;

    &:last-child {
      margin-right: 0;
    }
  }
`

type Props = {|
  +canEditAutomatedComms: boolean,
  +canEdit: boolean,
  +editSection: (string) => void,
|}

export const CanAccessPortalWithReadOnlyPermissionsEnabled = ({
  canEditAutomatedComms,
  canEdit,
  editSection,
}: Props) => (
  <AccountEntry>
    <span>My Elder Access When Archived:</span>
    <AccountEntrySmallEdit>
      {canEditAutomatedComms ? 'Yes' : 'No'}
      {canEdit && (
        <Button
          title="Edit access when archived"
          onClick={() =>
            editSection('canAccessPortalWithReadOnlyPermissionsEnabled')
          }
        >
          <EditOutlined
            sx={{
              fontSize: '24px',
            }}
          />
        </Button>
      )}
    </AccountEntrySmallEdit>
  </AccountEntry>
)
