// @flow
import Immutable from 'immutable'

import { SET_REFERENCE_DATA } from './actions'

const initialState = Immutable.Map()
export const referenceDataReducer = (
  state: Object = initialState,
  action: Object,
) => {
  switch (action.type) {
    case SET_REFERENCE_DATA:
      return Immutable.fromJS(action.referenceData.definitions)
    default:
      return state
  }
}

export default referenceDataReducer
