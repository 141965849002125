// @flow
import { addNamespace } from 'utils'

export const GET_CHANGELOG = 'GET_CHANGELOG'
export const SET_CHANGELOG = 'SET_CHANGELOG'

export const makeActions = (namespace: string) => ({
  getChangelog: () => ({
    type: addNamespace(namespace, GET_CHANGELOG),
  }),

  setChangelog: (changelogQuery: Object) => {
    let changelogs

    if (changelogQuery.entries && changelogQuery.entries.length > 0) {
      changelogs = changelogQuery.entries
    } else {
      changelogs = []
    }

    return {
      type: addNamespace(namespace, SET_CHANGELOG),
      changelogs,
    }
  },
})

export default makeActions
