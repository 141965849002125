// @flow
import makeServiceClient from 'app/saga/serviceClient'
import { accountExecutiveService } from 'features/accountExecutive/service'
import { accountPaymentsService } from 'features/accountPayments/service'
import { billingAccountsService } from 'features/billingAccounts/service'
import { carerBasicInfoService } from 'features/carerBasicInfo/service'
import { carerDocumentsService } from 'features/carerDocuments/service'
import { professionalService } from 'features/carerFinances/finance/service'
import { carerMatchingService } from 'features/carerMatching/service'
import { carerMessagingService } from 'features/carerNotifications/service'
import { elderUsersService } from 'features/elderUsers/service'
import { experimentService } from 'features/experiments/service'
import { notesService } from 'features/notes/service'
import { medicationService } from 'features/recipient/medication/service'
import { recipientService } from 'features/recipient/service'
import { recipientDocumentsService } from 'features/recipientDocuments/service'

export const recipientClient = makeServiceClient(recipientService)
export const medicationClient = makeServiceClient(medicationService)
export const carerDocumentsClient = makeServiceClient(carerDocumentsService)
export const carerBasicInfoClient = makeServiceClient(carerBasicInfoService)
export const carerMessagingClient = makeServiceClient(carerMessagingService)
export const recipientDocumentsClient = makeServiceClient(
  recipientDocumentsService,
)
export const notesClient = makeServiceClient(notesService)
export const professionalClient = makeServiceClient(professionalService)
export const elderUsersClient = makeServiceClient(elderUsersService)
export const accountExecutiveClient = makeServiceClient(accountExecutiveService)
export const billingAccountsClient = makeServiceClient(billingAccountsService)
export const accountPaymentsClient = makeServiceClient(accountPaymentsService)
export const carerMatchingClient = makeServiceClient(carerMatchingService)
export const experimentClient = makeServiceClient(experimentService)
