// @flow
import Immutable from 'immutable'

import { GET, SET, MERGE } from 'modules/pageEdit/actions'
import { addNamespace } from 'utils'

export const makeValueReducer = (namespace: string) => {
  const initialState = Immutable.Map()
  const get = addNamespace(namespace, GET)
  const set = addNamespace(namespace, SET)
  const merge = addNamespace(namespace, MERGE)
  return (state: Object = initialState, action: Object) => {
    switch (action.type) {
      case get:
        return initialState
      case set:
        return Immutable.fromJS(action.value)
      case merge:
        return Immutable.fromJS({ ...state.toJS(), ...action.value })
      default:
        return state
    }
  }
}

export default makeValueReducer
