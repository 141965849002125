// @flow
export const SET_ROLES = 'SET_ROLES'

export const AUTH_SUCCESS = 'AUTH_SUCCESS'
export const AUTH_FAIL = 'AUTH_FAIL'

export const AUTH_ERRORS = {
  INVALID_AUTH_TOKEN: 'INVALID_TOKEN',
  NOT_AUTHORIZED: 'NOT_AUTHORIZED',
  UNRECOVERABLE: 'UNRECOVERABLE',
  VERIFY_EMAIL: 'VERIFY_EMAIL',
}

export const AUTH_TYPES = {
  USERNAME_PASSWORD: 'USERNAME_PASSWORD',
}

export const authSuccess = (profile: string, authType: string) => ({
  type: AUTH_SUCCESS,
  profile,
  authType,
})

export const authFail = (errorType: string, errorText: string) => ({
  type: AUTH_FAIL,
  errorType,
  errorText,
})

export const setRoles = (roles: Object) => ({
  type: SET_ROLES,
  roles,
})

export const SET_EMAIL = 'SET_EMAIL'

export const setEmail = (email: string) => ({
  type: SET_EMAIL,
  email,
})
