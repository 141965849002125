// @flow
import type { Saga } from 'redux-saga'
import { fork } from 'redux-saga/effects'

import { watchAccountEnvironment } from './watchAccountEnvironment'
import { watchAccountEnvironment as watchAccountEnvironmentUpdate } from './watchAccountEnvironmentUpdate'

export function* watchAccountEnvironmentSummaryFlow(): Saga<*> {
  yield fork(watchAccountEnvironment)
  yield fork(watchAccountEnvironmentUpdate)
}

export default watchAccountEnvironmentSummaryFlow
