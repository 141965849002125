// @flow
import React from 'react'

import { colors, spacings, ElderLogoVertical } from '@elder/common'
import { CircularProgress } from '@mui/material'
import styled from 'styled-components'

const Wrapper = styled.div`
  background-color: ${colors.white};
  bottom: 0;
  height: 100%;
  left: 0;
  position: absolute;
  right: 0;
  top: 0;
`

const Content = styled.div`
  left: 50%;
  position: absolute;
  text-align: center;
  top: 50%;
  transform: translate(-50%, -50%);
`

const StyledElderLogo = styled(ElderLogoVertical)`
  margin-bottom: ${spacings.extraLarge};
`

const Text = styled.div`
  margin-bottom: ${spacings.large};
`

type Props = {|
  +text?: string,
|}

export const LoadingScreen = ({ text }: Props) => (
  <Wrapper>
    <Content>
      <StyledElderLogo width={203} height={157} />
      {text && <Text>{text}</Text>}
      <CircularProgress sx={{ mx: 'auto', display: 'block' }} />
    </Content>
  </Wrapper>
)
