// @flow
import React from 'react'

import type { Option } from '@elder/common'
import {
  Header,
  FormikInput,
  FormikTextArea,
  FormikPlainRadioButtons,
  FormikSelect,
  FormikCheckboxes,
  colors,
  Label,
  Paragraph,
  postcodeValidation,
  spacings,
} from '@elder/common'
import { useEtPatchAccountOverviewUsingPATCH } from '@elder/et-facade-et'
import { Button } from '@mui/material'
import { Formik, Form } from 'formik'
import { useSnackbar } from 'notistack'
import { useDispatch } from 'react-redux'
import { useParams } from 'react-router-dom'
import styled from 'styled-components'
import * as Yup from 'yup'

import { BottomButtonsWrapper } from 'components/form-2018'
import type { Environment } from 'domain/account'
import { forms } from 'messages/forms'
import { pageActions } from 'routes/account/environment/actions'
import { booleanOptions, setValueBoolean } from 'utils/groupOptions'
import { spareBedroomsOptions } from 'utils/selectOptions'

const StyledHeader = styled(Header)`
  color: ${colors.marceline};
  font-weight: normal;
  border-bottom: 2px solid ${colors.marceline};
  padding: 8px 0;
  margin-bottom: 24px;
`
const StyledForm = styled(Form)`
  margin: 40px auto 0 auto;
  padding: ${spacings.base};
  > * {
    margin-bottom: ${spacings.base};
  }
`
const CityAndPostcodeWrapper = styled.div`
  display: flex;
  > div {
    flex: 1;
  }
  > div:first-of-type {
    margin-right: 20px;
  }
`

const StyledFormikCheckboxes = styled(FormikCheckboxes)`
  padding: 0;
  > h5 {
    color: ${colors.marceline};
    font-weight: normal;
  }
`

type OptionsExtended = {|
  +default: string,
  +options: Array<Option>,
|}
type Props = {|
  +environment: Environment,
  +setEditMode: (prop: boolean) => void,
  +options?: {|
    +bedroomType: OptionsExtended,
    +bathroomType: OptionsExtended,
    +pets: OptionsExtended,
    +driving: OptionsExtended,
    +genderPreference: OptionsExtended,
    +carerFood: OptionsExtended,
    +languageFluency: OptionsExtended,
    +carerExperience: OptionsExtended,
    +ethnicitiesAtRisk: OptionsExtended,
  |},
|}

const AccountEnvironmentForm = ({
  environment,
  options,
  setEditMode,
}: Props) => {
  const petsOnChange = (
    values: Array<string>,
    target: any,
    arrayHelpers: any,
  ) => {
    const NO_PETS = 'NO_PETS'
    // when selecting NO_PETS, the other options get unticked.
    if (target.checked && target.value === NO_PETS) {
      values.forEach((value) => {
        if (value !== NO_PETS) {
          arrayHelpers.remove(0)
        }
      })
    }
    // when selecting any other option, NO_PETS gets unticked.
    else if (target.checked && values.includes(NO_PETS) && values.length > 1) {
      arrayHelpers.remove(values.indexOf(NO_PETS))
    }
  }

  const { accountId } = useParams<{ accountId: string }>()
  const { enqueueSnackbar } = useSnackbar()
  const dispatch = useDispatch()

  const { mutate, isPending: patchIsLoading } =
    useEtPatchAccountOverviewUsingPATCH({
      mutation: {
        onSuccess: () => {
          enqueueSnackbar('Care environment information was updated', {
            variant: 'success',
          })

          setEditMode(false)
          dispatch(pageActions.get(accountId))
        },
        onError: (submitError) => {
          enqueueSnackbar(submitError.message, { variant: 'error' })
        },
      },
    })

  const handleSubmit = (data: $FlowFixMe) => {
    setEditMode(false)
    mutate({ accountId, data })
  }

  return (
    <Formik
      onSubmit={handleSubmit}
      initialValues={{
        careEnvironment: {
          address: {
            address1: environment.address.address1,
            address2: environment.address.address2,
            city: environment.address.city,
            postcode: environment.address.postcode,
          },
          addressInstructions: environment.addressInfo,
        },
        household: {
          householdDescription: environment.householdDescription,
          pets: environment.pets,
          petsDescription: environment.petsText, // mismatch?
          smoking: setValueBoolean(environment.smoking),
          spareBedrooms: environment.spareBedrooms,
          wifi: setValueBoolean(environment.wifi),
        },
        domesticSupport: {
          carerBreakCoverNeeded: environment.carerBreakCoverNeeded,
          carerBreakDescription: environment.carerBreakDescription,
          carerFood: environment.carerFood,
          description: environment.domesticSupportDescription, // mismatch
          groceries: environment.groceries,
        },
      }}
      validationSchema={Yup.object().shape({
        address: Yup.object().shape({
          postcode: postcodeValidation(forms.validation.isPostCode),
        }),
      })}
      disableFields={patchIsLoading}
    >
      {({ errors }) => (
        <StyledForm>
          <StyledHeader level="h3" label="Care Environment" />
          <FormikInput
            name="careEnvironment.address.address1"
            label="Address line 1"
          />
          <FormikInput
            name="careEnvironment.address.address2"
            label="Address line 2"
          />
          <CityAndPostcodeWrapper>
            <FormikInput name="careEnvironment.address.city" label="City" />
            <FormikInput
              name="careEnvironment.address.postcode"
              label="Postcode"
              errorLabel={(errors.address || {}).postcode || null}
            />
          </CityAndPostcodeWrapper>
          <FormikTextArea
            name="careEnvironment.addressInstructions"
            label="Access instructions"
          />
          <StyledHeader level="h3" label="Household" />
          <FormikTextArea
            name="household.householdDescription"
            label={
              ((
                <>
                  <Label label="Household description" />
                  <Paragraph
                    level="p2"
                    label="Please describe the household and property. e.g. who lives in the home, description of the property, and it's immediate surroundings."
                  />
                </>
              ): $FlowFixMe)
            }
          />
          <FormikSelect
            name="household.spareBedrooms"
            options={spareBedroomsOptions}
            label={
              ((
                <>
                  <Label label="Number of spare bedrooms" />
                  <Paragraph
                    level="p2"
                    label="A spare bedroom is required for a live-in carer."
                  />
                </>
              ): $FlowFixMe)
            }
          />
          <FormikPlainRadioButtons
            name="household.wifi"
            options={booleanOptions}
            label={
              ((
                <>
                  <Label label="Wifi" />
                  <Paragraph
                    level="p2"
                    label="Elder recommends providing wifi in the home so that carers can keep in touch with family, friends, and Elder."
                  />
                </>
              ): $FlowFixMe)
            }
          />
          <FormikPlainRadioButtons
            name="household.smoking"
            options={booleanOptions}
            label={
              ((
                <>
                  <Label label="Smoking" />
                  <Paragraph
                    level="p2"
                    label="Does anybody in the house smoke inside?"
                  />
                </>
              ): $FlowFixMe)
            }
          />
          <StyledFormikCheckboxes
            name="household.pets"
            label={(<Label label="Pets" />: $FlowFixMe)}
            options={options ? options.pets.options : []}
            onChange={petsOnChange}
          />
          <FormikTextArea
            name="household.petsDescription"
            label="Please provide a description of any animals as well as any assistance required of the carer."
          />
          <StyledHeader level="h3" label="Domestic Support" />
          <FormikTextArea
            name="domesticSupport.description"
            label={
              ((
                <>
                  <Label label="Domestic support description" />
                  <Paragraph
                    level="p2"
                    label="Please describe the domestic arrangements and support required from the carer (e.g. any assistance with laundry and housework)"
                  />
                </>
              ): $FlowFixMe)
            }
          />
          <FormikTextArea
            name="domesticSupport.groceries"
            label={
              ((
                <>
                  <Label label="Groceries" />
                  <Paragraph
                    level="p2"
                    label="Please describe how the household food will be provided and what support is required from the carer (e.g. assistance with shopping, paying for groceries)"
                  />
                </>
              ): $FlowFixMe)
            }
          />
          <FormikPlainRadioButtons
            name="domesticSupport.carerFood"
            options={options ? options.carerFood.options : []}
            label={
              ((
                <>
                  <Label label="Food for carer" />
                  <Paragraph
                    level="p2"
                    label="As part of the Elder service, customers are expected to provide food for the carer in household shopping, or to provide a separate budget of £40 per week for the carer."
                  />
                </>
              ): $FlowFixMe)
            }
          />
          <FormikTextArea
            name="domesticSupport.carerBreakDescription"
            label={
              ((
                <>
                  <Label label="Carer Break Cover:" />
                  <Paragraph
                    level="p2"
                    label="Elder carers work an average of eight to ten (not necessarily
             consecutive) hours a day but will always be on hand when you need
             them. To ensure that they are well-rested and fit to take care of
             your loved one, it is a statutory requirement that our carers are
             able to take breaks of up to two hours each day when it is safe to
             do so."
                  />
                  <Paragraph
                    level="p2"
                    label="Describe any considerations for how carers should take their breaks
               during the day. (e.g. preferred times, to be mutually agreed on
               arrival, cover provision if care recipient cannot be left alone)"
                  />
                </>
              ): $FlowFixMe)
            }
          />
          <BottomButtonsWrapper>
            <Button onClick={() => setEditMode(false)} variant="text">
              Cancel
            </Button>
            <Button type="submit" variant="contained">
              Save
            </Button>
          </BottomButtonsWrapper>
        </StyledForm>
      )}
    </Formik>
  )
}

export default AccountEnvironmentForm
