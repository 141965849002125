// @flow
import { createSelector } from 'reselect'

import { NAMESPACE } from './actions'

const getPaymentsState = (state) => state.get(NAMESPACE)

export const getPaymentBatches = createSelector(
  [getPaymentsState],
  (paymentsState) => paymentsState.get('paymentBatches').toJS(),
)

export const getIsLoading = createSelector(
  [getPaymentsState],
  (paymentsState) => paymentsState.get('isLoading'),
)
