// @flow
import React from 'react'

import { colors, Paragraph, typography } from '@elder/common'
import styled from 'styled-components'

import { TertiaryLink } from 'components/legacyLinkButton'
import * as routes from 'routes'
import { formatLink } from 'utils/links'

const Container = styled.div`
  flex: 1;
  display: grid;
  grid-template-columns: 3fr 3fr 3fr;
  grid-column-gap: 32px;
  padding-bottom: 12px;
`

const DataWithTitle = styled.div`
  display: flex;
  flex-direction: column;
`

const DataHeader = styled(Paragraph)`
  ${typography.label};
  color: ${colors.lightSmoke};
`

const AccountLink = styled(TertiaryLink)`
  ${typography.paragraph1};
  color: ${colors.blue700};
  padding: 0;
`

const RelatedInfo = styled(Paragraph)`
  color: ${colors.marceline};
`

type Props = {|
  +accountId: string,
  +relationship: ?string,
  +roles: Array<string>,
  +displayContactRole: (string) => void,
|}

export const RelatedAccountOverview = ({
  accountId,
  relationship,
  roles,
  displayContactRole,
}: Props) => (
  <Container>
    <DataWithTitle>
      <DataHeader label="Account ID" />
      <AccountLink
        label={accountId}
        to={formatLink(routes.account, {
          accountId,
        })}
      />
    </DataWithTitle>
    <DataWithTitle>
      <DataHeader label="Relationship" />
      <RelatedInfo label={relationship || ''} level="p1" />
    </DataWithTitle>
    <DataWithTitle>
      <DataHeader label="Role" />
      {roles.map((role, roleIndex) => (
        <RelatedInfo
          // eslint-disable-next-line react/no-array-index-key
          key={roleIndex.toString()}
          label={displayContactRole(role) || ''}
          level="p1"
        />
      ))}
    </DataWithTitle>
  </Container>
)
