// @flow
// $FlowOptOut
import React, { useEffect } from 'react'

import {
  Box,
  Button,
  CircularProgress,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from '@mui/material'
import moment from 'moment'

import { BreadcrumbsBar } from 'features/header/BreadcrumbsBar'
import { CenterBox } from 'features/layout/CenterBox'
import { stickyMuiTableHeader } from 'features/layout/stickyTable'
import * as routes from 'routes'

const Row = ({
  downloadPaymentsFile,
  paymentBatch: {
    id,
    invoiceDate,
    payments,
    totalAmount: { displayText },
  },
}: {
  downloadPaymentsFile: (string, string) => void,
  paymentBatch: Object,
}) => {
  const consolidatedFileName = `pro-payments-${id}.tsv`
  const bankdetailsFileName = `pro-payments-${id}-bankdetails.tsv`
  const banktransfersFileName = `pro-payments-${id}-banktransfers.tsv`
  return (
    <TableRow key={id}>
      <TableCell>{moment(invoiceDate).format('ll')}</TableCell>
      <TableCell>{payments}</TableCell>
      <TableCell>{displayText}</TableCell>
      <TableCell align="right">
        <Button
          size="small"
          title={`Download ${consolidatedFileName}`}
          onClick={() =>
            downloadPaymentsFile(consolidatedFileName, `banktransfers/${id}`)
          }
        >
          {consolidatedFileName}
        </Button>
      </TableCell>
      <TableCell align="right">
        <Button
          size="small"
          title={`Download ${bankdetailsFileName}`}
          onClick={() =>
            downloadPaymentsFile(bankdetailsFileName, `bankdetails/${id}/xero`)
          }
        >
          {bankdetailsFileName}
        </Button>
      </TableCell>
      <TableCell align="right">
        <Button
          size="small"
          title={`Download ${banktransfersFileName}`}
          onClick={() =>
            downloadPaymentsFile(
              banktransfersFileName,
              `banktransfers/${id}/xero`,
            )
          }
        >
          {banktransfersFileName}
        </Button>
      </TableCell>
    </TableRow>
  )
}

type Props = {|
  +paymentBatches: Array<Object>,
  +downloadPaymentsFile: (string, string) => void,
  +getPaymentBatchesAction: () => void,
  +isLoading: boolean,
|}

export const OutboundPaymentsTable = ({
  paymentBatches,
  downloadPaymentsFile,
  isLoading,
  getPaymentBatchesAction,
}: Props) => {
  useEffect(() => {
    getPaymentBatchesAction()
  }, [])

  return (
    <Box sx={{ height: '100%', display: 'flex', flexDirection: 'column' }}>
      <BreadcrumbsBar
        breadcrumbs={[{ title: 'Pro Payments', to: routes.payments }]}
      />

      {isLoading && (
        <CenterBox>
          <CircularProgress />
        </CenterBox>
      )}

      {(!paymentBatches || paymentBatches.length === 0) && !isLoading && (
        <CenterBox>No results to display</CenterBox>
      )}

      {paymentBatches && paymentBatches.length > 0 && (
        <TableContainer>
          <Table
            aria-label="Pro Payments"
            size="small"
            sx={stickyMuiTableHeader}
          >
            <TableHead>
              <TableRow>
                <TableCell>Invoice Date</TableCell>
                <TableCell>No. payments</TableCell>
                <TableCell>Total Amount</TableCell>
                <TableCell align="right">Consolidated</TableCell>
                <TableCell align="right">Bank Details</TableCell>
                <TableCell align="right">Bank Transfers</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {paymentBatches.map((paymentBatch) => (
                <Row
                  paymentBatch={paymentBatch}
                  downloadPaymentsFile={downloadPaymentsFile}
                />
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      )}
    </Box>
  )
}
