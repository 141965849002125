// @flow
import { combineReducers } from 'redux-immutable'

import id from './id'
import identity from './identity'

export const getReducers = (namespace: string) => ({
  id: id(namespace),
  identity: identity(namespace),
})

export const makeReducer = (namespace: string) =>
  combineReducers(getReducers(namespace))

export default makeReducer
