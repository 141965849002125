// @flow
// $FlowOptOut
import { useEffect } from 'react'

type Props = {|
  +setId: (string) => void,
  +getIdentity: () => void,
  +accountId: string,
|}

export const AccountIdEvents = ({ setId, getIdentity, accountId }: Props) => {
  useEffect(() => {
    setId(accountId.toLowerCase())
    getIdentity()
  }, [accountId])

  return null
}
