// @flow
import type { Saga } from 'redux-saga'
import { call, select, takeLatest } from 'redux-saga/effects'

import { GET_IDENTITY } from 'modules/pageIdAndIdentity'
import { NAMESPACE, pageActions } from 'routes/profile/profileWrapper/actions'
import { pageSelectors } from 'routes/profile/profileWrapper/selectors'
import { addNamespace } from 'utils'
import { getAndSetFlow } from 'utils/services'

function* fetchProfileIdentityFlow(): Generator<*, void, string> {
  const profileId = yield select(pageSelectors.getId)
  yield call(
    getAndSetFlow,
    `/et/profiles/${profileId}/identity`,
    pageActions.setIdentity,
  )
}

export default function* watchProfileIdentity(): Saga<*> {
  yield takeLatest(
    addNamespace(NAMESPACE, GET_IDENTITY),
    fetchProfileIdentityFlow,
  )
}
