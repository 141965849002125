// @flow
import React from 'react'

import { Button } from '@mui/material'
import { Formik, Form } from 'formik'
import styled from 'styled-components'

import { CardWidthWrapper } from 'components/section/CardWidthWrapper'
import { Section } from 'components/styledComponentsTranslatedClasses/Section'
import { SectionHeader } from 'components/styledComponentsTranslatedClasses/SectionHeader'

const SectionWrapper = styled(Section)`
  padding: 16px;
`
const StyledButtonGroup = styled.div`
  display: flex;
  justify-content: flex-end;
`
const StyledPrimaryButton = styled(Button)`
  padding: 8px;
  margin: 4px;
`

const StyledSecondaryButton = styled(Button)`
  padding: 8px;
  margin: 4px;
`

type Props = {|
  +cancelEdit: () => void,
  +deleteChangeHistory: () => void,
|}

export const DeleteChangeHistoryConfirmation = ({
  cancelEdit,
  deleteChangeHistory,
}: Props) => (
  <CardWidthWrapper>
    <SectionWrapper>
      <Formik
        initialValues={{}}
        onSubmit={() => {
          deleteChangeHistory()
          cancelEdit()
        }}
      >
        {({ handleSubmit }) => (
          <Form>
            <SectionHeader>
              <h3>Are you sure you want to delete the change history?</h3>
            </SectionHeader>

            <StyledButtonGroup>
              <StyledSecondaryButton onClick={cancelEdit} variant="outlined">
                Cancel
              </StyledSecondaryButton>
              <StyledPrimaryButton onClick={handleSubmit} variant="contained">
                Delete
              </StyledPrimaryButton>
            </StyledButtonGroup>
          </Form>
        )}
      </Formik>
    </SectionWrapper>
  </CardWidthWrapper>
)
