// @flow
import * as React from 'react'

import { Paragraph } from '@elder/common'
import styled from 'styled-components'

import { Entry } from './Entry'
import { Value } from './Value'

const List = styled.ul`
  list-style: none;
  margin: 0;
  padding: 0;
`

export const IndentedList = ({
  label,
  list,
}: {
  label: string,
  list: Array<string>,
}) =>
  list &&
  list.length > 0 && (
    <Entry indented>
      <Paragraph bold label={label} />
      <Value
        value={
          <List>
            {list.map((item) => (
              <li key={item}>{item}</li>
            ))}
          </List>
        }
      />
    </Entry>
  )
