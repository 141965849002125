// @flow

import { addNamespace } from 'utils'

const NAMESPACE = 'DATE'

export const SET_CURRENT_DATE = addNamespace(NAMESPACE, 'SET_CURRENT_DATE')

export const setCurrentDate = (date: string) => ({
  type: SET_CURRENT_DATE,
  date,
})
