// @flow
// $FlowOptOut
import React, { useEffect } from 'react'

import { connect } from 'react-redux'
import type { Match } from 'react-router'

import { LoadingScreen } from 'components/LoadingScreen'

import { setSFId as setSFIdAction } from './actions'

type Props = {|
  +match: Match,
  +setSFId: (string) => void,
|}

const mapActionCreators = (dispatch) => ({
  setSFId: (salesforceId) => dispatch(setSFIdAction(salesforceId)),
})

const mapStateToProps = () => ({})

export const SalesforceContainer = connect(
  mapStateToProps,
  mapActionCreators,
)(({ match: { params: { salesforceId } } = {}, setSFId }: Props) => {
  useEffect(() => {
    // $FlowOptOut - Shouldn't be able to get here unless the route contains salesforceId
    setSFId(salesforceId)
  }, [])
  return (
    <LoadingScreen text="Importing the account from Salesforce and into ET3, please wait..." />
  )
})
