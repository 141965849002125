// @flow

import { AXIOS_INSTANCE } from '@elder/et-facade-et'
import * as Sentry from '@sentry/react'
import { stringify } from 'query-string'
import type { Saga } from 'redux-saga'
import { eventChannel } from 'redux-saga'
import { take, put } from 'redux-saga/effects'

import { restart } from 'app/actions'
import { getConfig } from 'app/config'
import { ACCESS_TOKEN } from 'app/login/sagas'

import { generateSpanId } from './utils'

export function* connectAxios(): Saga<*> {
  const axiosEvents = eventChannel((emitter) => {
    AXIOS_INSTANCE.interceptors.request.use(
      (baseConfig) => {
        const accessToken = localStorage.getItem(ACCESS_TOKEN)
        if (accessToken) {
          const spanId = generateSpanId()
          const traceId = generateSpanId() + spanId

          Sentry.addBreadcrumb({
            message: `trace-id: ${traceId} (${baseConfig.url})`,
          })

          return {
            ...baseConfig,
            baseURL: getConfig().rest.baseEndpoint,
            headers: {
              authorization: `Bearer ${accessToken}`,
              'X-B3-TraceId': traceId,
              'X-B3-SpanId': spanId,
              ...baseConfig.headers,
            },
            paramsSerializer: (params) =>
              stringify(params, { arrayFormat: 'repeat' }),
          }
        } else {
          throw new Error(
            "Can't use @elder/et-facade-et without authorization token!",
          )
        }
      },
      (error) => {
        Promise.reject(error)
      },
    )

    AXIOS_INSTANCE.interceptors.response.use((response) => {
      // TODO: Do we care about the special Elder error handling that we have in serviceClient/fetch?
      if (response.status === 401) {
        emitter('SESSION_EXPIRED')
      }

      return response
    })

    // We never unsubscribe until application ends
    return () => {}
  })

  while (true) {
    const axiosEvent = yield take(axiosEvents)
    switch (axiosEvent) {
      case 'SESSION_EXPIRED':
        // We have received a 401, session is now dead to us, reload the app
        yield put(restart())
        break
      default:
      // Nothing we're interested in
    }
  }
}
