// @flow
import type { Saga } from 'redux-saga'
import { call, put, takeLatest } from 'redux-saga/effects'

import { setLoading, clearLoading } from 'features/loading/actions'
import type { CommunicationPreferencesToUpdate } from 'features/relatedAccounts/types'
import {
  applicationError,
  applicationSuccess,
} from 'features/snackbar/snackbar'
import { putFlow, describeServiceError } from 'utils/services'

import {
  UPDATE_PROFILE_COMMUNICATION_PREFERENCES,
  NAMESPACE,
  setProfileCommunicationPreferences,
} from './store/actions'

function* updateProfileCommunicationPreferences({
  profileId,
  accountId,
  communicationPreferences,
}: {
  +profileId: string,
  +accountId: string,
  +communicationPreferences: CommunicationPreferencesToUpdate,
}): Generator<*, void, *> {
  const endpoint = `/et/profiles/${profileId}/communicationPreferences`
  try {
    yield put(setLoading(NAMESPACE))
    const response = yield call(putFlow, endpoint, {
      accountId,
      communicationPreferences,
    })
    const { accountLinks } = response

    yield put(setProfileCommunicationPreferences(accountLinks))

    applicationSuccess({
      title: 'Preferences saved',
      message: 'Communication preferences were updated successfully.',
    })
  } catch (error) {
    applicationError(
      describeServiceError(error, `Error with service call ${endpoint}`),
    )
  } finally {
    yield put(clearLoading(NAMESPACE))
  }
}

export function* communicationPreferencesSaga(): Saga<*> {
  yield takeLatest(
    UPDATE_PROFILE_COMMUNICATION_PREFERENCES,
    updateProfileCommunicationPreferences,
  )
}
