// @flow
import type { RefDataEnum } from '@elder/common'

import { makeAction } from 'elder/actions'

import type {
  Invoice,
  ManualTransactionType,
} from 'features/carerFinances/finance/types'
import { addNamespace } from 'utils'

export const NAMESPACE = 'PROFESSIONAL_FINANCE'
export const GET_INVOICES = 'GET_INVOICES'
export const SET_INVOICES = 'SET_INVOICES'
export const CLOSE_INVOICE = 'CLOSE_INVOICE'
export const CREATE_MANUAL_TRANSACTION = 'CREATE_MANUAL_TRANSACTION'
export const CANCEL_TRANSACTION = 'CANCEL_TRANSACTION'
export const INVOICES_ERROR = 'INVOICES_ERROR'

export const invoicesError = makeAction(addNamespace(NAMESPACE, INVOICES_ERROR))

export const getInvoices = makeAction(
  addNamespace(NAMESPACE, GET_INVOICES),
  (carerId: string) => ({
    carerId,
  }),
)

export const setInvoices = makeAction(
  addNamespace(NAMESPACE, SET_INVOICES),
  (invoices: Array<Invoice>) => ({ invoices }),
)

export const closeInvoice = makeAction(
  addNamespace(NAMESPACE, CLOSE_INVOICE),
  (carerId: string, regulatoryModel: RefDataEnum) => ({
    carerId,
    regulatoryModel,
  }),
)

export const createManualTransaction = makeAction(
  addNamespace(NAMESPACE, CREATE_MANUAL_TRANSACTION),
  (carerId: string, regulatoryModel: string, data: ManualTransactionType) => ({
    carerId,
    regulatoryModel,
    data,
  }),
)

export const cancelTransaction = makeAction(
  addNamespace(NAMESPACE, CANCEL_TRANSACTION),
  (carerId: string, regulatoryModel: RefDataEnum, transactionId: string) => ({
    carerId,
    regulatoryModel,
    transactionId,
  }),
)
