// @flow

import type { Option as ComponentLibraryOption } from '@elder/common'

import type { Option } from 'domain/option'

export const adaptOptionToComponentLibrary = (
  options: Array<Option>,
): Array<ComponentLibraryOption> =>
  options.map(({ value, label }) => ({ value, label }))
