/* eslint-disable @typescript-eslint/no-use-before-define */
import React, { useEffect, useState } from 'react'

import {
  useEtGetCustomerOverviewUsingGET as getAccount,
  useEtGetCarerIdentityUsingGET as getProfessional,
  useEtGetProfileOverviewUsingGET as getProfile,
} from '@elder/et-facade-et'
import { GroupOutlined } from '@mui/icons-material'
import {
  Avatar,
  Box,
  Button,
  Container,
  Stack,
  styled,
  Typography,
} from '@mui/material'
import { chain, isObject } from 'lodash'
import moment from 'moment'
import { Link } from 'react-router-dom'

import * as routes from 'routes'
import { formatLink } from 'utils/links'

const RECENTS = 'recents'

export type RecentsType = 'carer' | 'account' | 'profile'

interface Entry {
  date: string
  id: string
}

type Recents = Record<RecentsType, Entry[]>

const readRecents = (): Recents => ({
  carer: [],
  account: [],
  profile: [],
  ...JSON.parse(localStorage.getItem(RECENTS) || '{}'),
})

export const useLogVisitToRecents = (type: RecentsType, id?: string) => {
  useEffect(() => {
    if (!id) return
    const recents = readRecents()
    const date = new Date().toISOString()
    const newEntry = { date, id }
    const updatedEntries = chain([newEntry, ...recents[type]])
      .uniqBy('id')
      .slice(0, 5)
      .value()
    const updatedRecents = {
      ...recents,
      [type]: updatedEntries,
    }
    localStorage.setItem(RECENTS, JSON.stringify(updatedRecents))
  }, [type, id])
}

const useRecents = () => {
  const initial = readRecents()
  const [recents, setRecents] = useState<Recents>(initial)
  const refresh = () => {
    setRecents(readRecents())
  }
  const clear = () => {
    localStorage.removeItem(RECENTS)
    refresh()
  }
  return { recents, refresh, clear }
}

export const RecentsPage = () => {
  const { recents, refresh, clear } = useRecents()
  return (
    <Container sx={{ mt: 2 }}>
      <Stack spacing={2}>
        <Stack spacing={1}>
          <Typography variant="h1">Recents</Typography>
          <Typography color="text.secondary">
            This is a dev tool. It is not visible in staging or production.
          </Typography>
          <Stack direction="row" spacing={1}>
            <Button onClick={clear} variant="outlined" color="warning">
              Clear all recents
            </Button>
            <Button onClick={refresh}>Refresh</Button>
          </Stack>
        </Stack>
        <Box>
          <Typography variant="h2">Carers</Typography>
          <List>
            {recents.carer.map((entry) => (
              <li key={entry.id}>
                <RecentCarer id={entry.id} date={entry.date} />
              </li>
            ))}
          </List>
        </Box>
        <Box>
          <Typography variant="h2">Accounts</Typography>
          <List>
            {recents.account.map((entry) => (
              <li key={entry.id}>
                <RecentAccount id={entry.id} date={entry.date} />
              </li>
            ))}
          </List>
        </Box>
        <Box>
          <Typography variant="h2">Profiles</Typography>
          <List>
            {recents.profile.map((entry) => (
              <li key={entry.id}>
                <RecentProfile id={entry.id} date={entry.date} />
              </li>
            ))}
          </List>
        </Box>
      </Stack>
    </Container>
  )
}

const List = styled('ol')({
  listStyleType: 'none',
  padding: 0,
  'li:not(:last-child)': { marginBottom: '8px' },
})

const makeName = (name: { firstName?: string; lastName?: string } = {}) =>
  [name.firstName, name.lastName].filter(Boolean).join(' ')

const RecentCarer = ({ id, date }: Entry) => {
  const { isPending, isError, data } = getProfessional(id)
  const to = formatLink(routes.carer, { carerId: id })

  if (isPending) {
    return <Link to={to}>{id}</Link>
  }

  if (isError) {
    return <Link to={to}>{id}</Link>
  }

  const name =
    makeName({ firstName: data.firstName, lastName: data.lastName }) ||
    'Unnamed Carer'
  return (
    <Stack spacing={2} direction="row">
      <Link to={to}>
        <Avatar src={data.profilePicture} alt={name} />
      </Link>
      <Stack>
        <Link to={to}>{name}</Link>
        <Typography color="text.secondary">
          <code>{id}</code> &middot; Last accessed: {moment(date).fromNow()}
        </Typography>
      </Stack>
    </Stack>
  )
}

interface Contact {
  name?: string | { firstName?: string; lastName?: string }
  company?: string
  email?: string
}

const getContactName = (contact: Contact = {}) =>
  isObject(contact.name)
    ? makeName(contact.name)
    : contact.name || contact.company || contact.email

const getAccountName = (contacts: Contact[] = []) =>
  contacts.map(getContactName).filter(Boolean).join(', ')

const RecentAccount = ({ id, date }: Entry) => {
  const { isPending, isError, data } = getAccount(id)
  const to = formatLink(routes.account, { accountId: id })

  if (isPending) {
    return <Link to={to}>{id}</Link>
  }

  if (isError) {
    return <Link to={to}>{id}</Link>
  }

  const name = getAccountName(data.contacts) || 'Unnamed account'
  return (
    <Stack spacing={2} direction="row">
      <Link to={to}>
        <Avatar alt={name}>
          <GroupOutlined />
        </Avatar>
      </Link>
      <Stack>
        <Link to={to}>{name}</Link>
        <Typography color="text.secondary">
          <code>{id}</code> &middot; Last accessed: {moment(date).fromNow()}
        </Typography>
      </Stack>
    </Stack>
  )
}

const RecentProfile = ({ id, date }: Entry) => {
  const { isPending, isError, data } = getProfile(id)
  const to = formatLink(routes.profile, { profileId: id })

  if (isPending) {
    return <Link to={to}>{id}</Link>
  }

  if (isError) {
    return <Link to={to}>{id}</Link>
  }

  const name = getContactName(data.contactInfo) || 'Unnamed profile'
  return (
    <Stack spacing={2} direction="row">
      <Link to={to}>
        <Avatar alt={name} />
      </Link>
      <Stack>
        <Link to={to}>{name}</Link>
        <Typography color="text.secondary">
          <code>{id}</code> &middot; Last accessed: {moment(date).fromNow()}
        </Typography>
      </Stack>
    </Stack>
  )
}
