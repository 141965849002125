// @flow

/**
 * Generates a random 64-bit lowercase hex string for end-to-end tracing.
 */
export const generateSpanId = () =>
  Math.round(Math.random() * 0xffffffff)
    .toString(16)
    .padStart(8, '0') +
  Math.round(Math.random() * 0xffffffff)
    .toString(16)
    .padStart(8, '0')
