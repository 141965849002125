// @flow
import Immutable from 'immutable'

import { SET_ACCOUNT_MANAGERS } from 'routes/account/summary/actions'

export default (
  state: Array<Object> = Immutable.fromJS([]),
  action: Object,
) => {
  switch (action.type) {
    case SET_ACCOUNT_MANAGERS:
      return Immutable.fromJS(action.accountManagers)
    default:
      return state
  }
}
