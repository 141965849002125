// @flow

import type { Medicine } from 'features/recipient/medication/domain'
import { NAMESPACE } from 'features/recipient/medication/store/index'
import { addNamespace } from 'utils'
import { ElderServiceError } from 'utils/services'

export const GET_MEDICINES = addNamespace(NAMESPACE, 'GET_MEDICINES')
export const GET_MEDICINES_ERROR = addNamespace(
  NAMESPACE,
  'GET_MEDICINES_ERROR',
)
export const SET_MEDICINES = addNamespace(NAMESPACE, 'SET_MEDICINES')

export const getMedicines = (
  queryText: string,
  paginationToken?: string,
  maxSize?: string,
) => ({
  type: GET_MEDICINES,
  queryText,
  paginationToken,
  maxSize,
})

export const getMedicinesError = (error: ElderServiceError) => ({
  type: GET_MEDICINES_ERROR,
  error,
})

export const setMedicines = (medicines: Array<Medicine>) => ({
  type: SET_MEDICINES,
  medicines,
})
