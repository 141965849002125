// @flow
import { enqueueSnackbar } from 'notistack'
import type { Saga } from 'redux-saga'
import { take, call, select, put } from 'redux-saga/effects'

import { pageSelectors } from 'routes/profile/profileWrapper/selectors'
import {
  pageActions,
  updateOverviewValidationFail,
  UPDATE_OVERVIEW,
} from 'routes/profile/summary/actions'
import { expectedBasicProfileFields } from 'routes/profiles/shared/validFields'
import { manageErrorWithFieldValidation } from 'utils/fieldValidationErrors'
import { fetchFlow } from 'utils/services'

export function* putProfileOverview(data: Object): Generator<*, void, *> {
  const profileId = yield select(pageSelectors.getId)
  const endpoint = `/et/profiles/${profileId}/profile`
  const method = 'PUT'

  try {
    const responseBody = yield call(fetchFlow, endpoint, method, data)
    yield put(pageActions.set(responseBody))
    enqueueSnackbar('Details updated successfully', { variant: 'success' })
  } catch (error) {
    yield call(
      manageErrorWithFieldValidation,
      error,
      'overview',
      expectedBasicProfileFields,
      updateOverviewValidationFail,
    )
  }
}

export function* watchProfileOverview(): Saga<*> {
  while (true) {
    const payload = yield take(UPDATE_OVERVIEW)
    yield call(putProfileOverview, payload.data)
  }
}
