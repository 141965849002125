// @flow
import type { Name } from 'domain/name'

export const ACCEPTED = 'ACCEPTED'
export const REJECTED = 'REJECTED'
export const PENDING = 'PENDING'
export type AcceptanceStatus = 'ACCEPTED' | 'REJECTED' | 'PENDING'

export const SENT_EMAIL = 'SENT'
type EmailStatus = 'SENT' | 'REMINDER_SENT' | 'DELAYED' | 'NOT_SENT'

export const WORK = 'WORK'
export const CHARACTER = 'CHARACTER'
export type ReferenceType = 'WORK' | 'CHARACTER'

export const EMAIL_UPLOAD = 'EMAIL_UPLOAD'
type CollectionMethod = 'FORM' | 'PHONE_CALL' | 'EMAIL_UPLOAD'

export type Signature = {|
  +by: string,
  +at: string,
|}

export type RefereeDetails = {|
  +name: Name,
  +email: ?string,
  +phoneNumber: ?string,
  +jobTitle: string,
  +relationship?: string,
|}

export const yesNoNotGiven = {
  YES: 'YES',
  NO: 'NO',
  NOT_GIVEN: 'NOT_GIVEN',
}

export const yesNoNotSure = {
  YES: 'YES',
  NO: 'NO',
  NOT_SURE: 'NOT_SURE',
}

export type WorkReferenceDetails = {|
  +personalDetails: RefereeDetails,
  +referenceType: ReferenceType,
  +statedDatesAreAccurate: $Keys<typeof yesNoNotSure>,
  +startMonth?: string,
  +endMonth?: string,
  +effectiveEndMonth?: string,
  +conditionsAndSkillsAreAccurate: $Keys<typeof yesNoNotGiven>,
  +inaccurateConditionsOrSkillsDescription?: string,
  +issuesReported?: $Keys<typeof yesNoNotSure>,
  +issuesComment?: string,
  +safeToWorkWithVulnerableAdultsOption: $Keys<typeof yesNoNotGiven>,
  +unsuitableForVulnerableAdultsReason?: string,
  +additionalInformation: string,
  +agreementToShareWithCarerAndClients: boolean,
  +ongoing?: boolean,
  +documentId?: string,
|}

export type CharacterReferenceDetails = {|
  +additionalInformation?: string,
  +agreementToShareWithCarerAndClients: boolean,
  +documentId?: string,
  +knownSince: string,
  +personalDetails: RefereeDetails,
  +referenceType: ReferenceType,
  +relationship: string,
  +safeToWorkWithVulnerableAdultsOption: $Keys<typeof yesNoNotGiven>,
  +unsuitableForVulnerableAdultsReason: ?string,
  +documentId?: string,
|}

export type ReferenceResponseDetails =
  | WorkReferenceDetails
  | CharacterReferenceDetails

export type ReferenceResponse = {|
  +responseId: string,
  +acceptanceStatus: AcceptanceStatus,
  +collectionMethod: CollectionMethod,
  +createdAt: string,
  +details: ReferenceResponseDetails,
  +rejectionReason?: string,
  +elderComment?: string,
  +archived: boolean,
|}

export type Reference = {|
  +id: string,
  +emailStatus: EmailStatus,
  +createdSignature?: Signature,
  +lastUpdatedSignature?: Signature,
  +personalDetails: RefereeDetails,
  +responses: Array<ReferenceResponse>,
|}
