// @flow
import type { Saga } from 'redux-saga'
import { call, put, select, takeLatest } from 'redux-saga/effects'

import {
  getCarerIdentity,
  setCarerIdentity,
} from 'features/carerDetailsHeader/store/actions'
import { getId } from 'features/carerDetailsHeader/store/selectors'
import {
  finishedLoading,
  setCarer,
  startedLoading,
} from 'features/carerPageGenerics/store/actions'
import { clearLoading, setLoading } from 'features/loading/actions'
import {
  applicationError,
  applicationSuccess,
} from 'features/snackbar/snackbar'
import {
  describeServiceError,
  externalFetchFlow,
  fetchFlow,
  putFlow,
} from 'utils/services'

import {
  NAMESPACE,
  REMOVE_PROFILE_PICTURE,
  UPDATE_BASIC_PROFILE,
  UPDATE_CARER_DETAILS,
  UPDATE_CONTACT_DETAILS,
  UPDATE_PROFILE_PICTURE,
  UPDATE_STATUS,
  UPDATE_WORKER_STATUS,
} from './store/actions'

// TODO: Move the sagas to the new saga file.

export function* putProfessionalStatus({ status }: Object): Saga<*> {
  const proId = yield select(getId)
  const endpoint = `/et/professionals/${proId}/status`
  const method = 'PUT'

  try {
    yield put(setLoading(NAMESPACE))
    const responseBody = yield call(fetchFlow, endpoint, method, status)
    yield put(setCarer(responseBody))

    applicationSuccess({
      title: 'Professional updated',
      message: 'Professional status was updated successfully.',
    })
  } catch (error) {
    applicationError(
      describeServiceError(error, `Error with service call ${endpoint}`),
    )
  } finally {
    yield put(clearLoading(NAMESPACE))
  }
}

export function* putProfessionalCarerDetails({ value }: Object): Saga<*> {
  const proId = yield select(getId)
  const endpoint = `/et/professionals/${proId}/carerdetails`
  const method = 'PUT'

  try {
    yield put(setLoading(NAMESPACE))
    const responseBody = yield call(fetchFlow, endpoint, method, value)
    yield put(setCarer(responseBody))

    applicationSuccess({
      title: 'Professional saved',
      message: 'Professional carer details was updated successfully.',
    })
  } catch (error) {
    applicationError(
      describeServiceError(error, `Error with service call ${endpoint}`),
    )
  } finally {
    yield put(clearLoading(NAMESPACE))
  }
}

export function* putProfessionalBasicProfile({ value }: Object): Saga<*> {
  const proId = yield select(getId)
  const endpoint = `/et/professionals/${proId}/basicinfo/profile`
  const method = 'PUT'

  try {
    yield put(setLoading(NAMESPACE))
    const responseBody = yield call(fetchFlow, endpoint, method, value)
    yield put(setCarer(responseBody))

    applicationSuccess({
      title: 'Professional saved',
      message: 'Professional basic profile was updated successfully.',
    })

    yield put(getCarerIdentity(proId))
  } catch (error) {
    applicationError(
      describeServiceError(error, `Error with service call ${endpoint}`),
    )
  } finally {
    yield put(clearLoading(NAMESPACE))
  }
}

export function* putProfessionalContactDetails({ value }: Object): Saga<*> {
  const proId = yield select(getId)
  const endpoint = `/et/professionals/${proId}/basicinfo/contactDetails`
  const method = 'PUT'

  try {
    yield put(setLoading(NAMESPACE))
    const responseBody = yield call(fetchFlow, endpoint, method, value)
    yield put(setCarer(responseBody))

    applicationSuccess({
      title: 'Professional saved',
      message: 'Professional contact details was updated successfully.',
    })

    yield put(getCarerIdentity(proId))
  } catch (error) {
    applicationError(
      describeServiceError(error, `Error with service call ${endpoint}`),
    )
  } finally {
    yield put(clearLoading(NAMESPACE))
  }
}

function* removeProfilePicture(): Saga<*> {
  const proId = yield select(getId)
  const endpoint = `/et/professionals/${proId}/profile-picture`
  const method = 'DELETE'

  try {
    yield put(setLoading(NAMESPACE))
    const responseBody = yield call(fetchFlow, endpoint, method)
    yield put(setCarerIdentity(responseBody))

    applicationSuccess({
      title: 'Professional saved',
      message: 'Professional profile picture was removed successfully.',
    })
  } catch (error) {
    applicationError(
      describeServiceError(error, `Error with service call ${endpoint}`),
    )
  } finally {
    yield put(clearLoading(NAMESPACE))
  }
}

function* getUploadData() {
  const endpoint = '/et/images/request-upload'
  const method = 'POST'
  try {
    const uploadData = yield call(fetchFlow, endpoint, method)
    return uploadData
  } catch (error) {
    applicationError(
      describeServiceError(error, `Error with service call ${endpoint}`),
    )
  }
  return null
}

function* uploadProfilePicture(picture, destinationUrl) {
  const method = 'PUT'
  const headers = { 'Content-Type': 'image/jpeg' }
  try {
    yield call(externalFetchFlow, destinationUrl, method, headers, picture)
    return true
  } catch (error) {
    applicationError(
      describeServiceError(
        error,
        `Failed to upload the image to ${destinationUrl}`,
      ),
    )

    return false
  }
}

function* updateProfilePicture({ picture }): Saga<*> {
  yield put(startedLoading())

  const uploadData = yield call(getUploadData)
  if (!uploadData) {
    yield put(finishedLoading())
    return
  }

  const success = yield call(uploadProfilePicture, picture, uploadData.url)
  if (!success) {
    yield put(finishedLoading())
    return
  }
  const proId = yield select(getId)
  const endpoint = `/et/professionals/${proId}/profile-picture/${uploadData.id}`
  const method = 'PUT'

  try {
    const responseBody = yield call(fetchFlow, endpoint, method)
    yield put(setCarerIdentity(responseBody))

    applicationSuccess({
      title: 'Professional saved',
      message: 'Professional profile picture was updated successfully.',
    })
  } catch (error) {
    applicationError(
      describeServiceError(error, `Error with service call ${endpoint}`),
    )
  }

  yield put(finishedLoading())
}

function* putCarerWorkerStatus({ workerStatus }): Saga<*> {
  const professionalId = yield select(getId)
  const endpoint = `/et/professionals/${professionalId}/workerStatus`

  try {
    const response = yield call(putFlow, endpoint, { workerStatus })

    yield put(setCarer(response))

    applicationSuccess({
      title: 'Carer status saved',
      message: `Successfully updated carer worker status to ${workerStatus}`,
    })
  } catch (error) {
    applicationError(
      describeServiceError(
        error,
        `Error with service call ${endpoint}, unable to update carer status`,
      ),
    )
  } finally {
    yield put(clearLoading(NAMESPACE))
  }
}

export function* sagas(): Saga<*> {
  yield takeLatest(UPDATE_PROFILE_PICTURE, updateProfilePicture)
  yield takeLatest(REMOVE_PROFILE_PICTURE, removeProfilePicture)
  yield takeLatest(UPDATE_BASIC_PROFILE, putProfessionalBasicProfile)
  yield takeLatest(UPDATE_CONTACT_DETAILS, putProfessionalContactDetails)
  yield takeLatest(UPDATE_CARER_DETAILS, putProfessionalCarerDetails)
  yield takeLatest(UPDATE_STATUS, putProfessionalStatus)
  yield takeLatest(UPDATE_WORKER_STATUS, putCarerWorkerStatus)
}
