// @flow
import * as React from 'react'

import { colors, Paragraph } from '@elder/common'
import { Link as RouterLink } from 'react-router-dom'
import styled from 'styled-components'

const StyledRouterLink = styled(RouterLink)`
  align-items: baseline;
  display: flex;
  color: ${colors.blue800};
  cursor: pointer;
`

type Props = {|
  +children?: React.Node,
  +text: string,
  +page: string,
  +className?: string,
|}

export const Link = ({ children, text, page, className }: Props) => (
  <StyledRouterLink to={page} className={className}>
    <Paragraph label={text} level="p2" color={colors.blue600} />
    {children}
  </StyledRouterLink>
)
