// @flow
import { connect } from 'react-redux'

import { getCanImpersonateCustomer } from 'app/login/selectors'

import { impersonateCustomer, pageActions } from './actions'
import { ProfileWrapper } from './ProfileWrapper'
import { pageSelectors, getProfileFullName } from './selectors'

const mapActionCreators = {
  impersonateCustomer: (callbackUrl) => impersonateCustomer(callbackUrl),
  setId: (profileId) => pageActions.setId(profileId),
  getIdentity: () => pageActions.getIdentity(),
}

const mapStateToProps = (state) => ({
  profile: {
    profileId: pageSelectors.getId(state),
    fullName: getProfileFullName(state),
  },
  canImpersonate: getCanImpersonateCustomer(state),
})

export default connect(mapStateToProps, mapActionCreators)(ProfileWrapper)
