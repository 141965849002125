// @flow
import type { Saga } from 'redux-saga'
import { take, call, select, put } from 'redux-saga/effects'

import {
  applicationError,
  applicationSuccess,
} from 'features/snackbar/snackbar'
import { UPDATE_STATUS } from 'modules/pageEdit'
import { pageSelectors } from 'routes/profile/profileWrapper/selectors'
import { NAMESPACE, pageActions } from 'routes/profile/summary/actions'
import { addNamespace } from 'utils'
import { fetchFlow, describeServiceError } from 'utils/services'

export function* putProfileStatus(data: Object): Generator<*, void, *> {
  const profileId = yield select(pageSelectors.getId)
  const endpoint = `/et/profiles/${profileId}/status`
  const method = 'PUT'

  try {
    const responseBody = yield call(fetchFlow, endpoint, method, data)
    yield put(pageActions.set(responseBody))
    applicationSuccess({
      title: 'Profile saved',
      message: 'Profile status was updated successfully.',
    })
  } catch (error) {
    applicationError(
      describeServiceError(error, `Error with service call ${endpoint}`),
    )
  }
}

export function* watchProfileStatus(): Saga<*> {
  const updateStatus = addNamespace(NAMESPACE, UPDATE_STATUS)

  while (true) {
    const payload = yield take(updateStatus)
    yield call(putProfileStatus, payload.data)
  }
}
