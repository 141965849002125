// @flow
import * as Sentry from '@sentry/react'
import type { Saga } from 'redux-saga'

import { call, select, takeLatest, put, all } from 'elder/effects'

import { carerDocumentsClient } from 'app/saga/serviceClients'
import type { File, FileDetails } from 'domain/file'
import { setActiveWorkflow } from 'features/activeWorkflow/actions'
import { getId } from 'features/carerDetailsHeader/store/selectors'
import type {
  FileToUpload,
  EvidenceMetadata,
} from 'features/carerDocuments/domain'
import {
  getEvidences,
  openFiles,
  setEvidences,
  addEvidence,
  deleteEvidence,
  editEvidence,
  evidencesButtonSpinnerActions,
  setDocumentToPreview,
  verifyEvidence,
  clearDocumentToPreview,
} from 'features/carerDocuments/store/actions'
import { documentIdSelectorBuilder } from 'features/carerDocuments/store/selectors'
import {
  applicationError,
  applicationSuccess,
} from 'features/snackbar/snackbar'
import { describeServiceError, externalFetchFlow } from 'utils/services'

function* handleGetEvidences({ carerId }: { +carerId: string }): Saga<*> {
  try {
    const { evidence } = yield* call(carerDocumentsClient.getEvidence, {
      carerId,
    })
    yield* put(setEvidences(evidence, carerId))
  } catch (error) {
    applicationError(describeServiceError(error, 'Failed to get documents'))
  }
}

function* openDocumentFiles({ documentId }: { +documentId: string }): Saga<*> {
  try {
    const { files } = yield* call(carerDocumentsClient.getDocument, {
      documentId,
    })
    yield* all(
      files.map(({ downloadUrl }) => put(setDocumentToPreview(downloadUrl))),
    )
  } catch (error) {
    applicationError(
      describeServiceError(error, `Failed to open document id ${documentId}`),
    )
  }
}

function* handleOpenEvidence({ evidenceId }: { +evidenceId: string }): Saga<*> {
  try {
    const documents: Array<string> = yield* select(
      documentIdSelectorBuilder(evidenceId),
    )
    yield* all(
      documents.map((documentId) =>
        call(openDocumentFiles, {
          documentId,
        }),
      ),
    )
  } catch (error) {
    applicationError(describeServiceError(error, 'Failed to open files'))
  }
}

export function* postFileToGCS({
  file,
  url,
}: {
  +file: FileDetails,
  +url: string,
}): Saga<*> {
  const method = 'PUT'
  const headers = { 'Content-Type': file.type }
  try {
    yield* call(externalFetchFlow, url, method, headers, file)
  } catch (error) {
    const message = `Failed to upload the file to ${url}.\nError Details: ${JSON.stringify(
      error,
    )}`
    // not gracefully retrying or cleaning up.
    applicationError(describeServiceError(error, message))

    Sentry.captureException(message, error)
    // We throw this error so that it will also kill the parent saga and not try to 'complete' the upload
    throw error
  }
}

function* uploadFiles({
  formFiles,
  files,
  carerId,
  evidenceId,
}: {|
  +formFiles: Array<FileDetails>,
  +files: Array<FileToUpload>,
  +carerId: string,
  +evidenceId: string,
|}): Saga<*> {
  yield* all(
    formFiles.map((file, index) =>
      call(postFileToGCS, {
        file,
        url: files[index].uploadUrl,
      }),
    ),
  )

  yield* call(carerDocumentsClient.evidenceCompleteUpload, {
    carerId,
    evidenceId,
  })
}

function* handleAddEvidence({
  filesToUpload,
  metadata,
}: {
  +filesToUpload: Array<FileDetails>,
  +metadata: EvidenceMetadata,
}): Saga<*> {
  yield* put(evidencesButtonSpinnerActions.setStartLoading())
  const carerId = yield* select(getId)
  try {
    const request = {
      data: {
        document: {
          files: filesToUpload.map((file) => ({
            contentType: file.type,
          })),
        },
        metadata,
      },
      carerId,
    }
    const { evidenceId, files } = yield* call(
      carerDocumentsClient.evidencePreUpload,
      request,
    )

    yield* call(uploadFiles, {
      formFiles: filesToUpload,
      files,
      carerId,
      evidenceId,
    })

    yield* put(getEvidences(carerId))
    yield* put(setActiveWorkflow(''))
  } catch (error) {
    // not gracefully retrying or cleaning up.
    applicationError(
      describeServiceError(error, 'Failed to upload the document'),
    )
  } finally {
    yield* put(evidencesButtonSpinnerActions.setStopLoading())
  }
}

export function* handleDeleteEvidence({
  evidenceId,
}: {
  +evidenceId: string,
}): Saga<*> {
  yield* put(evidencesButtonSpinnerActions.setStartLoading())
  const carerId = yield* select(getId)

  try {
    yield* call(carerDocumentsClient.deleteEvidence, {
      carerId,
      evidenceId,
    })
    yield* put(getEvidences(carerId))
    yield* put(setActiveWorkflow(''))
  } catch (error) {
    applicationError(describeServiceError(error, 'Failed to delete document'))
  } finally {
    yield* put(evidencesButtonSpinnerActions.setStopLoading())
  }
}

export function* handleEditEvidence({
  evidenceId,
  metadata,
  addedDocuments,
  removedDocuments,
}: {
  +evidenceId: string,
  +metadata: EvidenceMetadata,
  +addedDocuments: Array<File>,
  +removedDocuments: Array<string>,
}): Saga<*> {
  yield* put(evidencesButtonSpinnerActions.setStartLoading())
  const carerId = yield* select(getId)

  try {
    const { files } = yield* call(carerDocumentsClient.editEvidence, {
      carerId,
      evidenceId,
      metadata,
      addedDocuments: {
        files: addedDocuments.map((document) => ({
          contentType: document.file.type,
        })),
      },
      removedDocuments,
    })

    if (files && files.length > 0) {
      yield* call(uploadFiles, {
        formFiles: addedDocuments.map((file) => file.file),
        files,
        carerId,
        evidenceId,
      })
    }

    yield* put(clearDocumentToPreview())
    yield* put(getEvidences(carerId))
    yield* put(setActiveWorkflow(''))
  } catch (error) {
    applicationError(describeServiceError(error, 'Failed to update document'))
  } finally {
    yield* put(evidencesButtonSpinnerActions.setStopLoading())
  }
}

export function* handleVerifyEvidence({
  evidenceId,
}: {
  +evidenceId: string,
}): Saga<*> {
  yield* put(evidencesButtonSpinnerActions.setStartLoading())
  const carerId = yield* select(getId)

  try {
    yield* call(carerDocumentsClient.verifyDocument, {
      carerId,
      evidenceId,
    })

    applicationSuccess({
      title: 'Evidence verified',
      message: 'You have successfully verified an evidence',
    })

    yield* put(clearDocumentToPreview())
    yield* put(getEvidences(carerId))
    yield* put(setActiveWorkflow(''))
  } catch (error) {
    applicationError(describeServiceError(error, 'Failed to verify document'))
  } finally {
    yield* put(evidencesButtonSpinnerActions.setStopLoading())
  }
}
export function* carerDocumentsSaga(): Saga<*> {
  yield* takeLatest(getEvidences, handleGetEvidences)
  yield* takeLatest(openFiles, handleOpenEvidence)
  yield* takeLatest(addEvidence, handleAddEvidence)
  yield* takeLatest(deleteEvidence, handleDeleteEvidence)
  yield* takeLatest(editEvidence, handleEditEvidence)
  yield* takeLatest(verifyEvidence, handleVerifyEvidence)
}
