// @flow
import { replace } from 'connected-react-router'
import { connect } from 'react-redux'

import { createNew, clearValidation as clearValidationAction } from './actions'
import { ProfileNew } from './ProfileNew'
import { getProfileNewValidationErrorJs } from './selectors'

const mapActionCreators = (dispatch) => ({
  cancel: () => dispatch(replace('/profiles')),
  postNewProfile: (data) => dispatch(createNew(data)),
  // $FlowOptOut
  clearValidation: (data) => dispatch(clearValidationAction(data)),
})

const mapStateToProps = (state) => ({
  validationErrors: getProfileNewValidationErrorJs(state),
})

export default connect(mapStateToProps, mapActionCreators)(ProfileNew)
