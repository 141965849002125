// @flow
import { endpointBuilder, noParameters } from 'elder/services'

import {
  updateHierarchyRequest,
  generateHierarchyRequest,
} from 'features/accountExecutive/domain'

export const accountExecutiveService = {
  updateHierarchy: endpointBuilder(updateHierarchyRequest, noParameters)
    .withHttpMethod('PUT')
    .withUrlTemplate(
      '/et/accounts/{accountId}/accountManagerHierarchy',
      ({ accountId }) => ({
        accountId,
      }),
    )
    .withBodyBuilder(({ hierarchy }) => ({ hierarchy }))
    .withNoResponseBody()
    .build(),
  generateHierarchy: endpointBuilder(generateHierarchyRequest, noParameters)
    .withHttpMethod('PUT')
    .withUrlTemplate(
      '/et/accounts/{accountId}/allocateAccountManagerHierarchy',
      ({ accountId }) => ({
        accountId,
      }),
    )
    .withNoResponseBody()
    .build(),
}
