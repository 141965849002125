// @flow
import { colors } from '@elder/common'
import styled from 'styled-components'

export const AccountSection = styled.div`
  flex: 0 0 50%;
  margin-bottom: 30px;

  h3 {
    text-transform: capitalize;

    span {
      color: ${colors.lightSmoke};
      font-size: 16px;
    }
  }
`
