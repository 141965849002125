// @flow
import React from 'react'

import { Paragraph, colors } from '@elder/common'
import styled from 'styled-components'

import { AccountEntry } from 'components/styledComponentsTranslatedClasses/AccountEntry'
import { LocalDates } from 'domain/dates'
import type { Dates } from 'routes/account/summary/Summary/props'

const StyledParagraph = styled(Paragraph)`
  color: ${colors.marceline};
`

type Props = {|
  +dates: Dates,
|}

export const CareDatesSection = ({ dates: { startDate, notSure } }: Props) => (
  <div>
    {(!!startDate || notSure) && (
      <AccountEntry>
        <span>Requested start date:</span>
        {!!startDate && (
          <StyledParagraph
            level="p2"
            label={LocalDates.toPrettyLongDate(startDate)}
          />
        )}
        {notSure && <StyledParagraph level="p2" label="Not sure" />}
      </AccountEntry>
    )}
  </div>
)
