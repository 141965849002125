import { useCallback, useMemo, useState } from 'react'

import { times } from 'lodash'
import type { Moment } from 'moment'
import moment from 'moment'

const MONDAY = 1
const FRIDAY = 5

const getNearestMonday = (date: Moment) => {
  if (date.isoWeekday() === MONDAY) return date
  if (date.isoWeekday() >= FRIDAY) return date.startOf('isoWeek').add(1, 'week')
  return date.startOf('isoWeek')
}

export const useTimeline = (initialFocus: Moment | string) => {
  const [focus, _setFocus] = useState<Moment>(
    getNearestMonday(
      typeof initialFocus === 'string' ? moment(initialFocus) : initialFocus,
    ),
  )

  const setFocus = useCallback((date: Moment | string) => {
    const nearestMonday = getNearestMonday(
      typeof date === 'string' ? moment(date) : date,
    )
    _setFocus(nearestMonday)
  }, [])

  const goBackwardsInTime = useCallback(
    () => setFocus(focus.clone().subtract(1, 'week')),
    [focus, setFocus],
  )
  const goForwardsInTime = useCallback(
    () => setFocus(focus.clone().add(1, 'week')),
    [focus, setFocus],
  )

  const timelineDates = useMemo(
    () =>
      times(3, (i) =>
        focus
          .clone()
          .add(i + 1, 'weeks')
          .format('ll'),
      ),
    [focus],
  )

  return {
    focus,
    setFocus,
    goBackwardsInTime,
    goForwardsInTime,
    timelineDates,
  }
}
