// @flow
/* eslint-disable flowtype/no-weak-types */
import { createSelector } from 'reselect'

import { NAMESPACE } from './actions'

const documentsSelector = (state) => state.get(NAMESPACE).toJS()

export const evidencesSelector = createSelector(
  documentsSelector,
  (state) => state.evidences,
)

export const evidenceSelector = createSelector(
  [documentsSelector, (_, id) => id],
  (state, id) => state.evidences.find((ev) => ev.id === id) || {},
)

export const evidenceSpinnerSelector = createSelector(
  documentsSelector,
  (state) => state.loading,
)

export const documentIdSelectorBuilder =
  (evidenceId: string) => (state: Object) =>
    evidencesSelector(state).find(({ id }) => id === evidenceId).documents || []

export const documentsToPreviewSelector = createSelector(
  documentsSelector,
  (state) => state.documentsToPreview,
)
