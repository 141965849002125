// @flow
import { instant, refDataEnum } from '@elder/common'
import { Type, arrayOf, string, object, boolean } from 'flow-validator'

import type { FlowOfType } from 'elder/types'

import { localDate } from 'domain/dates'

const amount: Type<*> = object({
  amount: string,
  currency: string,
  displayText: string,
})

const invoiceEntry: Type<*> = object({
  accountId: string.optional(),
  accountPrimaryContactName: string.optional(),
  amountCharged: amount.optional(),
  amountPaid: amount.optional(),
  cancellable: boolean,
  createdBy: string.optional(),
  description: string,
  detailedView: boolean,
  internalDescription: string.optional(),
  professionalId: string.optional(),
  professionalName: string.optional(),
  status: string,
  summarizedView: boolean,
  timeStamp: instant.optional(),
  transactionId: string.optional(),
  visitId: string.optional(),
})

const invoice: Type<*> = object({
  entries: arrayOf(invoiceEntry),
  expectedPaymentDate: localDate.optional(),
  hasStagedPayments: boolean,
  id: string,
  invoiceDate: localDate.optional(),
  invoicePdfUrl: string.optional(),
  invoiceStatus: string,
  invoiceTotal: amount,
  overdue: boolean,
  paymentMethod: string.optional(),
  regulatoryModel: refDataEnum,
})

export const invoicesResponse: Type<*> = object({
  invoices: arrayOf(invoice),
})

export const manualTransaction: Type<*> = object({
  amount: string,
  type: string,
  params: object({
    type: string.optional(),
    professionalId: string.optional(),
    professionalName: string.optional(),
    dateOfTravel: string.optional(),
    travelMode: string.optional(),
    chargeDescription: string.optional(),
    creditDescription: string.optional(),
    paymentDescription: string.optional(),
    discountDescription: string.optional(),
    internalDescription: string.optional(),
  }),
})

export type InvoiceEntry = FlowOfType<typeof invoiceEntry>
export type Invoice = FlowOfType<typeof invoice>
export type PrettyInvoice = $ReadOnly<{|
  ...Invoice,
  +type: 'STATEMENT',
  +invoiceStatusColor: string,
  +prettyInvoiceStatus: string,
  +prettyRegulatoryModel: string,
|}>

export type ManualTransactionType = FlowOfType<typeof manualTransaction>

export const REGULATORY_MODELS = {
  INTRODUCTORY: 'INTRODUCTORY',
  REGULATED: 'REGULATED',
}

export const PRETTY_REGULATORY_MODELS = {
  INTRODUCTORY: 'Introductory',
  REGULATED: 'Regulated',
}

export type Statement = PrettyInvoice
