// @flow
import type { Saga } from 'redux-saga'
import { take, call, select, put } from 'redux-saga/effects'

import {
  applicationError,
  applicationSuccess,
} from 'features/snackbar/snackbar'
import { pageSelectors } from 'routes/account/selectors'
import {
  CANCEL_DIRECT_DEBIT,
  pageActions,
} from 'routes/account/summary/actions'
import { fetchFlow, describeServiceError } from 'utils/services'

export function* deleteAccountCancelDirectDebit(): Generator<*, void, *> {
  const acctId = yield select(pageSelectors.getId)
  const endpoint = `/et/accounts/${acctId}/paymentDetails/directDebit`
  const method = 'DELETE'

  try {
    const responseBody = yield call(fetchFlow, endpoint, method)
    yield put(pageActions.set(responseBody))
    applicationSuccess({
      title: 'Account updated',
      message: 'Direct debit request has been canceled successfully.',
    })
  } catch (error) {
    applicationError(
      describeServiceError(error, `Error with service call ${endpoint}`),
    )
  }
}

export function* watchAccountCancelDirectDebit(): Saga<*> {
  while (true) {
    yield take(CANCEL_DIRECT_DEBIT)
    yield call(deleteAccountCancelDirectDebit)
  }
}
