/* @flow */

import { ValidationError, VType } from 'flow-validator'
import moment from 'moment'

// A date (only) without time zone information
/** @deprecated Use moment.js directly */
export opaque type LocalDate = string
/** @deprecated Use moment.js directly */
export const localDateFormat = 'YYYY-MM-DD'
const localDatePattern = /^\d\d\d\d-\d\d(-\d\d|)$/

/** @deprecated Use moment.js directly */
export class LocalDateType extends VType<LocalDate> {
  constructor(parse: (value: mixed) => LocalDate) {
    super('localDate', parse)
  }
}

/** @deprecated Use moment.js directly */
export const localDate: LocalDateType = new LocalDateType(
  (value): LocalDate => {
    if (typeof value !== 'string') {
      throw new ValidationError({ expected: localDate, got: value })
    }

    if (!localDatePattern.test(value)) {
      throw new ValidationError({
        expected: localDate,
        got: value,
        description: 'expected to be in the ISO date format (yyyy-mm-dd)',
      })
    }

    return value
  },
)

/** @deprecated Use moment.js directly */
export class LocalDates {
  static ofString(dateStr: string): LocalDate {
    if (!moment(dateStr).isValid()) {
      throw new Error(`Provided string (${dateStr}) is not a valid date`)
    }
    return dateStr
  }

  static fromIsoString(dateStr: string): LocalDate {
    // Make sure the date is valid, and expose it as our opaque type.
    return moment(dateStr, localDateFormat).format(localDateFormat)
  }

  static toString(date: LocalDate): string {
    return LocalDates.momentOf(date).format(localDateFormat)
  }

  static momentOf(date: LocalDate) {
    return moment(date, localDateFormat)
  }

  static parseFormattedString(dateStr: string, dateFormat: string): string {
    return moment(dateStr, dateFormat).format(localDateFormat)
  }

  static toLongDate(date: LocalDate) {
    return LocalDates.momentOf(date).format('dddd D MMMM')
  }

  static toMiddleDate(date: LocalDate) {
    return LocalDates.momentOf(date).format('dddd D MMM.')
  }

  static toShortDateTime(date: string) {
    return moment(date).format('DD/MM/YYYY, HH:mm')
  }

  static toLongDateTime(date: string) {
    return moment(date).format('DD MMMM YYYY, h:mm a')
  }

  static toPrettyShortDateWithYear(date: string) {
    return LocalDates.momentOf(date).format('D MMM YY')
  }

  static toShortMiddleDate(date: LocalDate) {
    return LocalDates.momentOf(date).format('ddd D MMM.')
  }

  static toMonthYear(date: LocalDate) {
    return LocalDates.momentOf(date).format('MMM YYYY')
  }

  static toLongMonthYear(date: LocalDate) {
    return LocalDates.momentOf(date).format('MMMM YYYY')
  }

  static toShortMonthYear(date: LocalDate) {
    return LocalDates.momentOf(date).format("MMM 'YY")
  }

  static toMonthYearDigits(date: LocalDate) {
    return LocalDates.momentOf(date).format('MM/YYYY')
  }

  static toLongMonth(date: LocalDate) {
    return LocalDates.momentOf(date).format('MMMM')
  }

  static toShortDate(date: LocalDate) {
    return LocalDates.momentOf(date).format('DD/MM/YYYY')
  }

  static toPrettyShortDate(date: LocalDate) {
    return LocalDates.momentOf(date).format('D MMM')
  }

  static toPrettyMediumDate(date: LocalDate) {
    return LocalDates.momentOf(date).format('D MMM YYYY')
  }

  static toPrettyLongDate(date: LocalDate) {
    return LocalDates.momentOf(date).format('Do MMMM YYYY')
  }

  static isBefore(date: LocalDate, dateBefore: LocalDate): boolean {
    return LocalDates.momentOf(date).isBefore(LocalDates.momentOf(dateBefore))
  }

  static isEqualToOrAfter(date: LocalDate, dateAfter: LocalDate): boolean {
    return LocalDates.momentOf(date).isSameOrAfter(
      LocalDates.momentOf(dateAfter),
    )
  }

  static isAfter(date: LocalDate, dateAfter: LocalDate): boolean {
    // order to use in: is date after dateAfter
    return LocalDates.momentOf(date).isAfter(LocalDates.momentOf(dateAfter))
  }

  static isBetweenInclusive(
    date: LocalDate,
    firstDate: LocalDate,
    secondDate: LocalDate,
  ): boolean {
    return LocalDates.momentOf(date).isBetween(
      LocalDates.momentOf(firstDate),
      LocalDates.momentOf(secondDate),
      undefined,
      '[]',
    )
  }

  static isBetweenExclusive(
    date: LocalDate,
    firstDate: LocalDate,
    secondDate: LocalDate,
  ): boolean {
    return LocalDates.momentOf(date).isBetween(
      LocalDates.momentOf(firstDate),
      LocalDates.momentOf(secondDate),
      undefined,
      '()',
    )
  }

  static currentDate(): LocalDate {
    return LocalDates.fromIsoString(moment().format(localDateFormat))
  }

  static timestamp(): number {
    return moment().unix()
  }

  static compare(
    dateA: LocalDate,
    dateB: LocalDate,
    units: string = 'days',
  ): number {
    return LocalDates.momentOf(dateA).diff(LocalDates.momentOf(dateB), units)
  }

  static isSame(
    dateA: LocalDate,
    dateB: LocalDate,
    units: string = 'ms',
  ): boolean {
    return LocalDates.momentOf(dateA).isSame(LocalDates.momentOf(dateB), units)
  }

  static getDuration(
    earlierDate: LocalDate,
    laterDate: LocalDate,
  ): {| +years: number, +months: number, +days: number |} {
    const diffObject = moment.duration(
      LocalDates.momentOf(laterDate).diff(LocalDates.momentOf(earlierDate)),
    )
    return {
      years: diffObject.years(),
      months: diffObject.months(),
      days: diffObject.days(),
    }
  }

  static getYear(date: LocalDate): number {
    return LocalDates.momentOf(date).year()
  }

  // Flow doesnt like it when moment functions are used with opaque flow type
  // $FlowOptOut
  static addDays(currentDate: LocalDate, days: number): LocalDate {
    return LocalDates.momentOf(currentDate).add(days, 'days')
  }

  // Flow doesnt like it when moment functions are used with opaque flow type
  // $FlowOptOut
  static subtractDays(currentDate: LocalDate, days: number): LocalDate {
    return LocalDates.momentOf(currentDate).subtract(days, 'days')
  }

  // Flow doesnt like it when moment functions are used with opaque flow type
  // $FlowOptOut
  static add(currentDate: LocalDate, amount: number, units: string): LocalDate {
    return LocalDates.momentOf(currentDate).add(amount, units)
  }

  static subtract(
    currentDate: LocalDate,
    amount: number,
    units: string,
  ): LocalDate {
    const result = LocalDates.momentOf(currentDate).subtract(amount, units)
    return LocalDates.fromIsoString(result.format(localDateFormat))
  }

  // Flow doesnt like it when moment functions are used with opaque flow type
  // $FlowOptOut
  static getDay(currentDate: LocalDate): number {
    return LocalDates.momentOf(currentDate).format('D')
  }

  static getDateWeekStart(date: LocalDate): LocalDate {
    return LocalDates.fromIsoString(
      // Flow doesnt like it when moment functions are used with opaque flow type
      // $FlowOptOut
      LocalDates.momentOf(date).startOf('isoWeek'),
    )
  }

  static getDateWeekEnd(date: LocalDate): LocalDate {
    // Flow doesnt like it when moment functions are used with opaque flow type
    // $FlowOptOut
    return LocalDates.fromIsoString(LocalDates.momentOf(date).endOf('isoWeek'))
  }

  static getStartOf(date: LocalDate, unit: string): LocalDate {
    return LocalDates.momentOf(date).startOf(unit).format(localDateFormat)
  }
}
