// @flow
import styled from 'styled-components'

const SectionHeader = styled.div`
  align-items: center;
  border-bottom: 0;
  border-top: 2px solid #19305e;
  display: flex;
  justify-content: space-between;
  margin: 20px 0 10px;
  padding-bottom: 0;
  padding-top: 10px;
`
export default SectionHeader
