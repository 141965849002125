// @flow
import type { Node } from 'react'
import React from 'react'

import { typography, colors } from '@elder/common'
import { CircularProgress } from '@mui/material'
import styled, { css } from 'styled-components'

const Header = styled.tr`
  ${typography.label};
  text-align: left;
  color: ${colors.darkSmoke};
`

const HeaderCell = styled.th`
  padding: 8px 0;
  border-bottom: 1px solid ${colors.lightSmoke};
  &:not(:first-of-type) {
    padding: 8px 24px;
  }

  ${({ width }) =>
    width &&
    css`
      width: ${width};
    `};
`

const Wrapper = styled.table`
  width: 100%;
  border-spacing: 0;
  color: ${colors.marceline};
`

const Row = styled.tr`
  ${typography.paragraph1};
  text-align: left;
  width: 100%;
  &:not(:last-of-type) {
    td {
      border-bottom: 1px solid ${colors.lightSmoke};
    }
  }
`

const BodyCell = styled.td`
  padding: 8px 0;
  :not(:first-of-type) {
    padding: 8px 24px;
  }
`

const SpinnerWrapper = styled.div`
  display: flex;
`

type Props = {|
  +columnConfiguration: Array<{|
    +header?: ?string | Node,
    +width?: string,
  |}>,
  +rows: Array<{| id?: string, columns: Array<Node | string> |}>,
  +loading?: boolean,
|}

export const Table = ({
  columnConfiguration = [],
  rows = [],
  loading,
}: Props) => (
  <div>
    <Wrapper>
      <thead>
        <Header>
          {columnConfiguration.map(({ header, width }, index) => (
            // eslint-disable-next-line react/no-array-index-key
            <HeaderCell width={width} key={index}>
              {header}
            </HeaderCell>
          ))}
        </Header>
      </thead>
      <tbody>
        {!loading &&
          rows.map(({ id, columns }, index) => (
            <Row key={id || index}>
              {columns.map((cell, index) => (
                // eslint-disable-next-line react/no-array-index-key
                <BodyCell key={index}>{cell}</BodyCell>
              ))}
            </Row>
          ))}
      </tbody>
    </Wrapper>
    {loading && (
      <SpinnerWrapper>
        <CircularProgress sx={{ mx: 'auto' }} />
      </SpinnerWrapper>
    )}
  </div>
)
