// @flow
import { makeAction } from 'elder/actions'

import { addNamespace } from 'utils'

export const NAMESPACE = 'CARER_FEEDBACK'
export const PROFESSIONAL_SUMMARY = 'PROFESSIONAL_SUMMARY'

export const GET_TESTIMONIALS = addNamespace(NAMESPACE, 'GET_TESTIMONIALS')

export const SET_TESTIMONIAL_STATUS = addNamespace(
  NAMESPACE,
  'SET_TESTIMONIAL_STATUS',
)

export const SET_TESTIMONIAL_STATUS_LOADING_SPINNER = addNamespace(
  NAMESPACE,
  'SET_TESTIMONIAL_STATUS_LOADING_SPINNER',
)

export const SET_BLACKLIST = addNamespace(NAMESPACE, 'SET_BLACKLIST')

export const GET_BLACKLIST = addNamespace(NAMESPACE, 'GET_BLACKLIST')

export const getTestimonials = makeAction(
  GET_TESTIMONIALS,
  (carerId: string) => ({ carerId }),
)

export const setTestimonialStatus = (payload: Object, carerId: string) => ({
  type: SET_TESTIMONIAL_STATUS,
  payload,
  carerId,
})

export const setTestimonialStatusLoadingSpinner = (id: string) => ({
  type: SET_TESTIMONIAL_STATUS_LOADING_SPINNER,
  id,
})

export const setBlacklist = (blacklist: Object) => ({
  type: SET_BLACKLIST,
  blacklist,
})

export const getBlacklist = (carerId: string) => ({
  type: GET_BLACKLIST,
  carerId,
})
