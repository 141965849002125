// @flow
import { refDataEnum } from '@elder/common'
import { object, string, number, boolean, Type, arrayOf } from 'flow-validator'

import type { FlowOfType } from 'elder/types'

import { localDate } from 'domain/dates'
import { recipientDocumentsType } from 'domain/recipientDocumentsType'

export const name: Type<*> = object({
  firstName: string.optional(),
  lastName: string.optional(),
  salutation: string.optional(),
})

export const medicalConditionInfo: Type<*> = object({
  description: string.optional(),
  medicalCondition: refDataEnum.optional(),
})

export const medication: Type<*> = object({
  name: string,
  directions: string.optional(),
  supportRequired: boolean.optional(),
})

export const vaccinationRecords: Type<*> = object({
  comment: string.optional(),
  condition: refDataEnum,
  vaccinationDate: string.optional(),
  vaccineType: refDataEnum,
  id: string,
})

export const weight: Type<*> = object({
  weight: string,
  weightUnit: refDataEnum,
})

export const mobilityNeedDetail: Type<*> = object({
  mobilityNeed: refDataEnum,
  type: string.optional(),
  description: string.optional(),
  model: string.optional(),
  lastTested: localDate.optional(),
})

export const recipient: Type<*> = object({
  recipientId: string,
  infoComplete2018: boolean,
  status: string,
  name: name.optional(),
  dateOfBirth: localDate.optional(),
  gender: refDataEnum.normalizedOptional(),
  medicalConditionsInfo: arrayOf(medicalConditionInfo).optional(),
  medications: arrayOf(medication).optional(),
  medicationReminder: boolean.optional(),
  medicationPurpose: boolean.optional(),
  medicationRefill: string.optional(),
  lifeOutsideHome: string.optional(),
  nightTimeCare: string.optional(),
  personalProfile: string.optional(),
  foodDietNeeds: arrayOf(refDataEnum).optional(),
  foodDietDescription: string.optional(),
  feedingTubeType: refDataEnum.optional(),
  foodDeliveryType: refDataEnum.optional(),
  personalCare: string.optional(),
  attendanceRequirements: refDataEnum.normalizedOptional(),
  attendanceRequirementDetails: string.optional(),
  continenceDescription: string.optional(),
  continenceNeeds: arrayOf(refDataEnum).optional(),
  stomaType: refDataEnum.optional(),
  catheterType: refDataEnum.optional(),
  challengingBehaviour: string.optional(),
  physicalAbuseRisk: boolean.optional(),
  physicalAbuseDetails: string.optional(),
  verbalAbuseRisk: boolean.optional(),
  verbalAbuseDetails: string.optional(),
  mobilityDescription: string.optional(),
  mobilityNeeds: arrayOf(refDataEnum).optional(),
  mobilityNeedsDetails: arrayOf(mobilityNeedDetail).optional(),
  otherMobilityNeed: string.optional(),
  mobilityLevel: refDataEnum.optional(),
  carersRequiredForSupport: number.optional(),
  fallCount: number.optional(),
  lastFallDate: localDate.optional(),
  fallDetails: string.optional(),
  weight: weight.optional(),
  tipsForCarer: string.optional(),
  carePlans: arrayOf(recipientDocumentsType).optional(),
  otherDocuments: arrayOf(recipientDocumentsType).optional(),
  vaccinationInfo: arrayOf(vaccinationRecords).optional(),
})

export const recipientData = object({
  name: name.optional(),
  dateOfBirth: localDate.optional(),
  gender: refDataEnum.normalizedOptional(),
  additionalInfo: object({
    personalProfile: string.optional(),
  }),
  careAssessment: object({
    medicalConditionsInfo: arrayOf(medicalConditionInfo).optional(),
    medications: arrayOf(medication).optional(),
    medicationReminder: boolean.optional(),
    medicationPurpose: boolean.optional(),
    medicationRefill: string.optional(),
    lifeOutsideHome: string.optional(),
    vaccinationRecords: arrayOf(
      object({
        // basically the same without the `id` field
        comment: string.optional(),
        condition: refDataEnum,
        vaccinationDate: string.optional(),
        vaccineType: refDataEnum,
      }),
    ).optional(),
  }),
  dailyRoutine: object({
    nightTimeCare: string.optional(),
    personalProfile: string.optional(),
    foodDietNeeds: arrayOf(refDataEnum).optional(),
    foodDietDescription: string.optional(),
    feedingTubeType: refDataEnum.optional(),
    foodDeliveryType: refDataEnum.optional(),
    personalCare: string.optional(),
    attendanceRequirements: refDataEnum.normalizedOptional(),
    attendanceRequirementDetails: string.optional(),
    continenceDescription: string.optional(),
    continenceNeeds: arrayOf(refDataEnum).optional(),
    stomaType: refDataEnum.optional(),
    catheterType: refDataEnum.optional(),
    challengingBehaviour: string.optional(),
    physicalAbuseRisk: boolean.optional(),
    physicalAbuseDetails: string.optional(),
    verbalAbuseRisk: boolean.optional(),
    verbalAbuseDetails: string.optional(),
    mobilityDescription: string.optional(),
    mobilityNeeds: arrayOf(refDataEnum).optional(),
    mobilityNeedsDetails: arrayOf(mobilityNeedDetail).optional(),
    otherMobilityNeed: string.optional(),
    mobilityLevel: refDataEnum.optional(),
    carersRequiredForSupport: number.optional(),
    fallCount: number.optional(),
    lastFallDate: localDate.optional(),
    fallDetails: string.optional(),
    weight: weight.optional(),
  }),
})

export type Recipient = FlowOfType<typeof recipient>
export type RecipientData = FlowOfType<typeof recipientData>
export type MedicalCondition = FlowOfType<typeof medicalConditionInfo>
export type Medication = FlowOfType<typeof medication>
export type MobilityNeedDetail = FlowOfType<typeof mobilityNeedDetail>
export type VaccinationRecords = FlowOfType<typeof vaccinationRecords>

export type MedicalConditionDisplay = $ReadOnly<{|
  ...MedicalCondition,
  prettyMedicalCondition: string,
|}>

export type RecipientDisplay = $ReadOnly<{|
  ...Recipient,
  +prettyStatus: string,
  +prettyName: string,
  +prettyDateOfBirth: string,
  +prettyMedicalConditionsInfo: Array<MedicalConditionDisplay>,
  +prettyCatheterType: string,
  +prettyStomaType: string,
  +prettyFeedingTubeType: string,
  +prettyFoodDeliveryType: string,
  +prettyWeight: string,
  +prettyVaccinationInfo: Array<VaccinationRecords>,
|}>
