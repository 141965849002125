// @flow
import { addNamespace } from 'utils'

export const NAMESPACE = 'ACCOUNT_PAYMENTS'

export const CREATE_PAYMENT = addNamespace(NAMESPACE, 'CREATE_PAYMENT')

export const SET_CREATE_PAYMENT_CONFIRMATION = addNamespace(
  NAMESPACE,
  'SET_CREATE_PAYMENT_CONFIRMATION',
)

export const CONFIRM_CREATE_PAYMENT = addNamespace(
  NAMESPACE,
  'CONFIRM_CREATE_PAYMENT',
)

export const CANCEL_PAYMENT = addNamespace(NAMESPACE, 'CANCEL_PAYMENT')

export const SET_CANCEL_PAYMENT_CONFIRMATION = addNamespace(
  NAMESPACE,
  'SET_CANCEL_PAYMENT_CONFIRMATION',
)

export const CONFIRM_CANCEL_PAYMENT = addNamespace(
  NAMESPACE,
  'CONFIRM_CANCEL_PAYMENT',
)

export const GET_INVOICE_DETAILS = addNamespace(
  NAMESPACE,
  'GET_INVOICE_DETAILS',
)

export const SET_INVOICE_DETAILS = addNamespace(
  NAMESPACE,
  'SET_INVOICE_DETAILS',
)

export const SET_ACCOUNT_DETAILS = addNamespace(
  NAMESPACE,
  'SET_ACCOUNT_DETAILS',
)

export const CLOSE_INVOICE = addNamespace(NAMESPACE, 'CLOSE_INVOICE')

export const createPayment = (invoiceId: string) => ({
  type: CREATE_PAYMENT,
  invoiceId,
})

export const setCreatePaymentConfirmation = (value: Object) => ({
  type: SET_CREATE_PAYMENT_CONFIRMATION,
  value,
})

export const confirmCreatePayment = (invoiceId: string, accountId: string) => ({
  type: CONFIRM_CREATE_PAYMENT,
  invoiceId,
  accountId,
})

export const cancelPayment = (invoiceId: string, paymentId: string) => ({
  type: CANCEL_PAYMENT,
  invoiceId,
  paymentId,
})

export const setCancelPaymentConfirmation = (value: Object) => ({
  type: SET_CANCEL_PAYMENT_CONFIRMATION,
  value,
})

export const confirmCancelPayment = (
  invoiceId: string,
  paymentId: string,
  accountId: string,
) => ({
  type: CONFIRM_CANCEL_PAYMENT,
  invoiceId,
  paymentId,
  accountId,
})

export const getInvoiceDetails = (accountId: string) => ({
  type: GET_INVOICE_DETAILS,
  accountId,
})

export const setInvoiceDetails = (value: Object) => ({
  type: SET_INVOICE_DETAILS,
  value,
})

export const closeInvoice = (accountId: string) => ({
  type: CLOSE_INVOICE,
  accountId,
})

export const setAccountDetails = (accountDetails: { [string]: any }) => ({
  type: SET_ACCOUNT_DETAILS,
  accountDetails,
})
