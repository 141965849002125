// @flow
import { addNamespace } from 'utils'

export const NAMESPACE = 'CREATE_ACCOUNT'

export const CREATE = addNamespace(NAMESPACE, 'CREATE')

export const createNew = (data: Object) => ({
  type: CREATE,
  data,
})
