// @flow

import amplitude from 'amplitude-js'

export const logAmplitudeEvent = (name: string, properties?: any) => {
  amplitude.getInstance().logEvent(name, properties)
}

export const setAmplitudeUserProperty = (name: string, value: any) => {
  const identify = new amplitude.Identify()
  identify.set(name, value)
  amplitude.getInstance().identify(identify)
}
