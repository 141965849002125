import { spareBedroomsOptions } from 'utils/selectOptions'

export const prettyYesNoNull = (yesNoNull) => {
  if (!(typeof yesNoNull === 'boolean')) {
    return ''
  }

  return yesNoNull ? 'Yes' : 'No'
}

export const prettyLocation = ({ postcode, city, address }) =>
  (address || '') +
  (address && postcode ? ', ' : '') +
  (postcode || '') +
  ((address || postcode) && city ? ' ' : '') +
  (city ? `(${city})` : '')

export const prettyAddressArray = (address1, address2, city, postcode) => {
  const addressArray = []

  if (address1) {
    addressArray.push(address1)
  }
  if (address2) {
    addressArray.push(address2)
  }
  if (city || postcode) {
    let cityPostcode
    if (city && postcode) {
      cityPostcode = `${city}, ${postcode}`
    } else {
      cityPostcode = city || postcode
    }
    addressArray.push(cityPostcode)
  }

  return addressArray
}

export const prettyTime = (timeOfDay = '') => timeOfDay.slice(0, -3)

export const prettyDuration = (duration = '') => duration.slice(2)

export const prettyTimeAndDuration = (timeOfDay, duration) =>
  prettyTime(timeOfDay) + (duration ? ` (${prettyDuration(duration)})` : '')

export const priceNoteFromBasis = (basis) => {
  if (!basis) {
    return ''
  }

  switch (basis) {
    case 'DAILY':
      return '/day'
    case 'HOURLY':
      return '/h'
    default:
      return basis
  }
}

export const prettyDayOfWeek = (dayOfWeek) => {
  if (!dayOfWeek) {
    return ''
  }

  switch (dayOfWeek) {
    case 'MONDAY':
      return 'Monday'
    case 'TUESDAY':
      return 'Tuesday'
    case 'WEDNESDAY':
      return 'Wednesday'
    case 'THURSDAY':
      return 'Thursday'
    case 'FRIDAY':
      return 'Friday'
    case 'SATURDAY':
      return 'Saturday'
    case 'SUNDAY':
      return 'Sunday'
    default:
      return dayOfWeek
  }
}

export const prettyStatus = (status) => {
  if (!status) {
    return ''
  }

  switch (status) {
    case 'NEW':
      return 'New'
    case 'ACTIVE':
      return 'Active'
    case 'ARCHIVED':
      return 'Archived'
    case 'PENDING':
      return 'Pending'
    case 'ONBOARDING':
      return 'Onboarding'
    case 'FAILED':
      return 'Failed'
    case 'REJECTED':
      return 'Rejected'
    case 'TEMP_OUT':
      return 'Temporarily Unavailable'
    case 'LIVE':
      return 'Live'
    case 'CANCELLED':
      return 'Cancelled'
    case 'DRAFT':
      return 'Draft'
    default:
      return status
  }
}

export const prettyPaymentMethod = (paymentMethod) => {
  if (!paymentMethod) {
    return ''
  }

  switch (paymentMethod) {
    case 'BANK_TRANSFER':
      return 'Bank Transfer'
    case 'STRIPE_BANK_TRANSFER':
      return 'Bank Transfer (Stripe)'
    case 'CARD':
      return 'Card'
    case 'STRIPE_CARD':
      return 'Card (v2)'
    case 'DIRECT_DEBIT':
      return 'Direct Debit'
    case 'MANUAL_INVOICING':
      return 'Manual Invoicing'
    default:
      return paymentMethod
  }
}

export const prettyYesNoSometimes = (val) => {
  if (!val) {
    return ''
  }

  switch (val) {
    case 'YES':
      return 'Yes'
    case 'NO':
      return 'No'
    case 'SOMETIMES':
      return 'Sometimes'
    default:
      return val
  }
}

export const prettyGender = (gender) => {
  if (!gender) {
    return ''
  }

  switch (gender) {
    case 'FEMALE':
      return 'Female'
    case 'MALE':
      return 'Male'
    case 'OTHER':
      return 'Other'
    default:
      return gender
  }
}

export const prettyPets = (pets) => {
  if (!pets) {
    return ''
  }

  switch (pets) {
    case 'DOGS':
      return 'Dogs'
    case 'CATS':
      return 'Cats'
    case 'OTHER':
      return 'Other'
    case 'NO':
      return 'No'
    case 'NONE':
      return 'None'
    default:
      return pets
  }
}

export const prettySpareBedrooms = (spareBedrooms) => {
  const spareBedroomsString = spareBedrooms ? spareBedrooms.toString() : null
  const selectedOption = spareBedroomsOptions.find(
    ({ value }) => value === spareBedroomsString,
  )

  if (selectedOption) {
    return selectedOption.label
  }

  return ''
}

export const getPrettyTextForOptions = (options, value) => {
  if (!value) {
    return ''
  }
  const selectedOption = options.find(
    ({ value: optionValue }) => optionValue === value,
  )

  if (selectedOption) {
    return selectedOption.label
  }

  return value
}
