// @flow
import React from 'react'

import { colors } from '@elder/common'
import { CloseOutlined } from '@mui/icons-material'
import {
  Checkbox,
  FormControl,
  FormControlLabel,
  FormGroup,
  FormLabel,
  Stack,
  TextField,
  Typography,
  Radio,
  RadioGroup,
  Autocomplete,
  Button,
  InputAdornment,
  IconButton,
} from '@mui/material'
import { DatePicker } from '@mui/x-date-pickers'
import { identity, without } from 'lodash'
import moment from 'moment'

import type { PrimaryFilterOptions } from 'features/professionals/domain'
import type {
  IFilterValues,
  SetFilter,
} from 'features/professionals/ProfessionalsTable/ProfessionalsTable'
import { FocusKeyInputAdornment } from 'utils/usePressKeyToFocus'

const JAVA_DATE_FORMAT = 'YYYY-MM-DD'

const POSTCODE_REGEX = /^[A-Z]{1,2}[0-9][0-9A-Z]?\s?[0-9][A-Z]{2}$/i

export const isPostCodeValid = (postcode: string) =>
  postcode.length === 0 || postcode.match(POSTCODE_REGEX)

type Props = {|
  +filters: IFilterValues,
  +setFilter: SetFilter,
  +filterOptions: PrimaryFilterOptions,
  +resetFilters: () => void,
  +dirty: boolean,
  +queryInputFieldRef: $FlowFixMe,
|}

export const Filters = ({
  filters,
  setFilter,
  filterOptions,
  resetFilters,
  dirty,
  queryInputFieldRef,
}: Props) => (
  <Stack
    sx={{
      width: '420px',
      background: colors.blue200,
      height: '100%',
      p: 2,
      overflow: 'auto',
      '.MuiTextField-root': {
        background: '#ffffff',
      },
    }}
    spacing={2}
  >
    <Stack
      sx={{ justifyContent: 'space-between', minHeight: 35 }}
      direction="row"
    >
      <Typography variant="h3">Filters</Typography>
      {dirty && (
        <Button onClick={resetFilters} variant="contained" size="small">
          Reset filters
        </Button>
      )}
    </Stack>
    <Stack spacing={2}>
      <TextField
        label="Search"
        value={filters.query}
        onChange={(e) => setFilter('query', e.target.value)}
        size="small"
        placeholder="Name, phone, email or id"
        inputRef={queryInputFieldRef}
        autoComplete="off"
        // type="text"
        InputProps={{
          endAdornment:
            filters.query.length > 0 ? (
              <InputAdornment>
                <IconButton
                  onClick={() => {
                    setFilter('query', '')
                    queryInputFieldRef.current.focus()
                  }}
                  edge="end"
                >
                  <CloseOutlined />
                </IconButton>
              </InputAdornment>
            ) : (
              <FocusKeyInputAdornment label="/" />
            ),
        }}
      />

      <TextField
        label="Postcode (rank by distance)"
        value={filters.postcode}
        onChange={(e) => setFilter('postcode', e.target.value)}
        {...(isPostCodeValid(filters.postcode)
          ? {}
          : { helperText: 'Invalid post code', color: 'warning' })}
        size="small"
      />

      <FormControl component="fieldset">
        <FormLabel component="legend">Prefer Carers Who...</FormLabel>
        <FormGroup row>
          <FormControlLabel
            label="Recently Activated"
            control={
              <Checkbox
                name="preferRecentlyActivated"
                checked={filters.preferRecentlyActivated}
                onChange={(e) =>
                  setFilter('preferRecentlyActivated', e.target.checked)
                }
                size="small"
              />
            }
          />
          <FormControlLabel
            label="Recently Logged In"
            control={
              <Checkbox
                name="preferRecentlyLoggedIn"
                checked={filters.preferRecentlyLoggedIn}
                onChange={(e) =>
                  setFilter('preferRecentlyLoggedIn', e.target.checked)
                }
                size="small"
              />
            }
          />
        </FormGroup>
      </FormControl>

      <FormControl component="fieldset">
        <FormLabel component="legend">Status</FormLabel>
        <FormGroup row>
          {filterOptions.carerStatusOptions.options.map(({ value, label }) => (
            <FormControlLabel
              key={value}
              label={label}
              control={
                <Checkbox
                  name={`status.${value}`}
                  checked={filters.status.includes(value)}
                  onChange={(e) =>
                    setFilter(
                      'status',
                      e.target.checked
                        ? [...filters.status, value]
                        : without(filters.status, value),
                    )
                  }
                  size="small"
                />
              }
            />
          ))}
        </FormGroup>
      </FormControl>

      <FormControl component="fieldset">
        <FormLabel component="legend">Carer Worker Status</FormLabel>
        <FormGroup row>
          {filterOptions.carerWorkerStatusOptions.options.map(
            ({ value, label }) => (
              <FormControlLabel
                key={value}
                label={label}
                control={
                  <Checkbox
                    name={`workerStatus.${value}`}
                    checked={filters.workerStatus.includes(value)}
                    onChange={(e) =>
                      setFilter(
                        'workerStatus',
                        e.target.checked
                          ? [...filters.workerStatus, value]
                          : without(filters.workerStatus, value),
                      )
                    }
                    size="small"
                  />
                }
              />
            ),
          )}
        </FormGroup>
      </FormControl>

      <FormControlLabel
        label="Include restricted carers"
        control={
          <Checkbox
            name="includeRestricted"
            checked={filters.includeRestricted}
            onChange={(e) => setFilter('includeRestricted', e.target.checked)}
            size="small"
            sx={{ ml: '-11px' }}
          />
        }
      />

      <Stack direction="row" spacing={1}>
        <DatePicker
          label="Available from"
          value={filters.fromDate ? moment(filters.fromDate) : null}
          onChange={(newValue) => {
            setFilter(
              'fromDate',
              newValue && newValue.isValid()
                ? newValue.format(JAVA_DATE_FORMAT)
                : null,
            )
          }}
          slotProps={{ inputProps: { size: 'small' } }}
        />
        <DatePicker
          label="Available to"
          value={filters.toDate ? moment(filters.toDate) : null}
          onChange={(newValue) => {
            setFilter(
              'toDate',
              newValue && newValue.isValid()
                ? newValue.format(JAVA_DATE_FORMAT)
                : null,
            )
          }}
          slotProps={{ inputProps: { size: 'small' } }}
        />
      </Stack>

      <Autocomplete
        multiple
        id="conditionExpertise"
        options={filterOptions.conditionExpertiseOptions.options}
        getOptionLabel={(option) => option.label}
        filterSelectedOptions
        renderInput={(params) => (
          <TextField {...params} label="Condition Expertise" />
        )}
        value={filters.conditionExpertise
          .map((value) =>
            filterOptions.conditionExpertiseOptions.options.find(
              (option) => option.value === value,
            ),
          )
          .filter(identity)}
        onChange={(_, options) => {
          setFilter(
            'conditionExpertise',
            options.map((option) => option.value),
          )
        }}
        size="small"
      />

      <Autocomplete
        multiple
        id="careExpertise"
        options={filterOptions.careExpertiseOptions.options}
        getOptionLabel={(option) => option.label}
        filterSelectedOptions
        renderInput={(params) => (
          <TextField {...params} label="Care Expertise" />
        )}
        value={filters.careExpertise
          .map((value) =>
            filterOptions.careExpertiseOptions.options.find(
              (option) => option.value === value,
            ),
          )
          .filter(identity)}
        onChange={(_, options) => {
          setFilter(
            'careExpertise',
            options.map((option) => option.value),
          )
        }}
        size="small"
      />

      <FormControl>
        <FormLabel id="filters-driving">Driving</FormLabel>
        <RadioGroup
          aria-labelledby="filters-driving"
          name="driving"
          value={filters.driving}
          onChange={(e) => setFilter('driving', e.target.value)}
          row
        >
          {filterOptions.drivingOptions.map(({ value, label }) => (
            <FormControlLabel
              key={value}
              value={value}
              control={<Radio size="small" />}
              label={label}
            />
          ))}
        </RadioGroup>
      </FormControl>

      <FormControl component="fieldset">
        <FormLabel component="legend">DBS/PVG</FormLabel>
        <FormGroup row>
          <FormControlLabel
            label="Can work in England, Wales &amp; NI"
            control={
              <Checkbox
                name="hasDbs"
                checked={filters.hasDbs}
                onChange={(e) => setFilter('hasDbs', e.target.checked)}
                size="small"
              />
            }
          />
          <FormControlLabel
            label="Can work in Scotland"
            control={
              <Checkbox
                name="hasPvg"
                checked={filters.hasPvg}
                onChange={(e) => setFilter('hasPvg', e.target.checked)}
                size="small"
              />
            }
          />
        </FormGroup>
      </FormControl>

      <FormControl component="fieldset">
        <FormLabel component="legend">Care Environment</FormLabel>
        <FormGroup row>
          <FormControlLabel
            label="Cats ok"
            control={
              <Checkbox
                name="workWithPets.cats"
                checked={filters.workWithPets.includes('CATS')}
                onChange={(e) =>
                  setFilter(
                    'workWithPets',
                    e.target.checked
                      ? [...filters.workWithPets, 'CATS']
                      : without(filters.workWithPets, 'CATS'),
                  )
                }
                size="small"
              />
            }
          />
          <FormControlLabel
            label="Dogs ok"
            control={
              <Checkbox
                name="workWithPets.dogs"
                checked={filters.workWithPets.includes('DOGS')}
                onChange={(e) =>
                  setFilter(
                    'workWithPets',
                    e.target.checked
                      ? [...filters.workWithPets, 'DOGS']
                      : without(filters.workWithPets, 'DOGS'),
                  )
                }
                size="small"
              />
            }
          />
          <FormControlLabel
            label="Smokers ok"
            control={
              <Checkbox
                name="workWithSmokers"
                checked={filters.workWithSmokers}
                onChange={(e) => setFilter('workWithSmokers', e.target.checked)}
                size="small"
              />
            }
          />
        </FormGroup>
      </FormControl>

      <FormControl component="fieldset">
        <FormLabel component="legend">Placement type preference</FormLabel>
        <FormGroup row>
          <FormControlLabel
            label="Live-in"
            control={
              <Checkbox
                name="careTypes.hourly"
                checked={filters.careTypes.includes('LIVE_IN')}
                onChange={(e) =>
                  setFilter(
                    'careTypes',
                    e.target.checked
                      ? [...filters.careTypes, 'LIVE_IN']
                      : without(filters.careTypes, 'LIVE_IN'),
                  )
                }
                size="small"
              />
            }
          />
          <FormControlLabel
            label="Hourly"
            control={
              <Checkbox
                name="careTypes.hourly"
                checked={filters.careTypes.includes('HOURLY')}
                onChange={(e) =>
                  setFilter(
                    'careTypes',
                    e.target.checked
                      ? [...filters.careTypes, 'HOURLY']
                      : without(filters.careTypes, 'HOURLY'),
                  )
                }
                size="small"
              />
            }
          />
        </FormGroup>
      </FormControl>

      <FormControl>
        <FormLabel id="filters-gender">Gender</FormLabel>
        <RadioGroup
          aria-labelledby="filters-gender"
          name="gender"
          value={filters.gender}
          onChange={(e) => setFilter('gender', e.target.value)}
          row
        >
          {filterOptions.genderOptions.map(({ value, label }) => (
            <FormControlLabel
              key={value}
              value={value}
              control={<Radio size="small" />}
              label={label}
            />
          ))}
        </RadioGroup>
      </FormControl>

      <Autocomplete
        multiple
        id="languages"
        options={filterOptions.languagesOptions.options}
        getOptionLabel={(option) => option.label}
        filterSelectedOptions
        renderInput={(params) => (
          <TextField {...params} label="Preferred Languages" />
        )}
        value={filters.languages
          .map((value) =>
            filterOptions.languagesOptions.options.find(
              (option) => option.value === value,
            ),
          )
          .filter(identity)}
        onChange={(_, options) => {
          setFilter(
            'languages',
            options.map((option) => option.value),
          )
        }}
        size="small"
      />

      <Autocomplete
        multiple
        id="onboardingLocation"
        options={filterOptions.citiesOptions.options}
        getOptionLabel={(option) => option.label}
        filterSelectedOptions
        renderInput={(params) => (
          <TextField {...params} label="Onboarding City" />
        )}
        value={filters.onboardingLocation
          .map((value) =>
            filterOptions.citiesOptions.options.find(
              (option) => option.value === value,
            ),
          )
          .filter(identity)}
        onChange={(_, options) => {
          setFilter(
            'onboardingLocation',
            options.map((option) => option.value),
          )
        }}
        size="small"
      />
    </Stack>
  </Stack>
)
