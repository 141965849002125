// @flow
import { createSelector } from 'reselect'

import { NAMESPACE } from 'features/relatedAccounts/store/actions'

const getRelatedAccountsStateSelector = (state) => state.get(NAMESPACE)

const getRelatedAccountsState = createSelector(
  getRelatedAccountsStateSelector,
  (state) => state.toJS(),
)

export const getCustomerRelatedAccounts = createSelector(
  [getRelatedAccountsState],
  (accountState): Object => accountState.communicationPreferences, // eslint-disable-line flowtype/no-weak-types
)
