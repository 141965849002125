// @flow
import {
  GET_AUTOCOMPLETE_PROFILES,
  GET_AUTOCOMPLETE_PROFILES_FAIL,
  SET_AUTOCOMPLETE_PROFILES,
} from 'modules/autocompleteProfiles/actions'
import { addNamespace } from 'utils'

export const makeReducer = (namespace: string) => {
  const initialState = false
  const getAutocompleteProfiles = addNamespace(
    namespace,
    GET_AUTOCOMPLETE_PROFILES,
  )
  const getAutocompleteProfilesFail = addNamespace(
    namespace,
    GET_AUTOCOMPLETE_PROFILES_FAIL,
  )
  const setAutocompleteProfiles = addNamespace(
    namespace,
    SET_AUTOCOMPLETE_PROFILES,
  )

  return (state: boolean = initialState, action: Object) => {
    switch (action.type) {
      case getAutocompleteProfiles:
        return true
      case getAutocompleteProfilesFail:
      case setAutocompleteProfiles:
        return false
      default:
        return state
    }
  }
}

export default makeReducer
