// @flow
/* eslint-disable flowtype/no-weak-types */
import { createSelector } from 'reselect'

import { prettyCompactDateTime } from 'utils/dateDecorators'

export const makeSelectors = (changelogsSelector: (Object) => Object) => ({
  getChangelogs: createSelector([changelogsSelector], (changelogs) =>
    changelogs
      .map((changelog) => ({
        user: changelog.get('user') || '',
        impersonator: changelog.get('impersonator') || '',
        timestamp: prettyCompactDateTime(changelog.get('timestamp')) || '',
        description: changelog.get('description') || '',
      }))
      .toArray(),
  ),
})

export default makeSelectors
