// @flow
import type { Node } from 'react'
import React from 'react'

import {
  FormikInput,
  FormikTextArea,
  FormikSelect,
  FormikCheckbox,
  Overline,
} from '@elder/common'
import styled from 'styled-components'

import type { EnumMap } from 'elder/types'

import { DateInput } from 'components/forms/DateInput'
import { DocumentUpload } from 'components/forms/DocumentUpload'
import { RadioButtons } from 'components/forms/RadioButtons'
import { WithRefData } from 'containers/WithRefData'
import type { Field, FieldType } from 'domain/fields'
import { adaptOptionToComponentLibrary } from 'utils/adaptOptionToComponentLibrary'

const StyledDateInput = styled(DateInput)`
  max-width: 400px;
`

const DocumentUploadWrapper = styled.div`
  grid-column: 1 / -1;
`

const dynamicFieldMapper: EnumMap<FieldType, (Field) => Node> = {
  TEXT: ({ name, label, errorLabel, disabled, info }: Field) => (
    <FormikInput
      name={name}
      label={label}
      errorLabel={errorLabel}
      disabled={disabled}
      info={info}
    />
  ),
  TEXT_AREA: ({ name, label, errorLabel, disabled, info }: Field) => (
    <FormikTextArea
      name={name}
      label={label}
      errorLabel={errorLabel}
      disabled={disabled}
      info={info}
    />
  ),
  NUMBER: ({ name, label, errorLabel, disabled, info }: Field) => (
    <FormikInput
      name={name}
      label={label}
      errorLabel={errorLabel}
      type="number"
      disabled={disabled}
      info={info}
    />
  ),
  DATE: ({ name, label, errorLabel, disabled, info }: Field) => (
    <StyledDateInput
      name={name}
      label={label}
      errorLabel={errorLabel}
      disabled={disabled}
      info={info}
    />
  ),
  SELECT: ({
    name,
    label,
    errorLabel,
    disabled,
    options = [],
    placeholder,
    multi,
    info,
  }: Field) => (
    <FormikSelect
      name={name}
      label={label}
      errorLabel={errorLabel}
      disabled={disabled}
      options={adaptOptionToComponentLibrary(options)}
      isMulti={multi}
      info={info}
      placeholder={placeholder}
    />
  ),
  RADIO: ({ name, label, errorLabel, options, disabled, info }: Field) => (
    <RadioButtons
      name={name}
      label={label}
      errorLabel={errorLabel}
      options={options || []}
      disabled={disabled}
      info={info}
    />
  ),
  CHECKBOX: ({ name, label, errorLabel, disabled }: Field) => (
    <FormikCheckbox
      name={name}
      label={label}
      errorLabel={errorLabel}
      disabled={disabled}
    />
  ),
  FILE_UPLOAD: ({
    name,
    value,
    label,
    errorLabel,
    disabled,
    maxValue,
    documentsToPreview,
    clearDocumentToPreview = () => undefined,
  }: Field) => (
    <DocumentUploadWrapper>
      <Overline label={label} />
      <DocumentUpload
        name={name}
        value={value}
        error={errorLabel}
        maxSize={maxValue}
        disabled={disabled}
        documentsToPreview={documentsToPreview || []}
        clearDocumentToPreview={clearDocumentToPreview}
      />
    </DocumentUploadWrapper>
  ),
}

export const DynamicField = (field: Field) =>
  field.refData ? (
    <WithRefData
      name={field.refData}
      render={({ options }) =>
        dynamicFieldMapper[field.type]({ ...field, options })
      }
    />
  ) : (
    dynamicFieldMapper[field.type](field)
  )
