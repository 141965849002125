// @flow
import { makeAction } from 'elder/actions'

import type { ElderUsers, UserStatus } from 'features/elderUsers/domain'
import { makeActions as makeFiltersActions } from 'modules/tableFilters'
import { addNamespace } from 'utils'

export const NAMESPACE = 'ELDER_USERS'

export const elderUsersFilterAction = makeFiltersActions(NAMESPACE)

export const GET_ELDER_USERS = addNamespace(NAMESPACE, 'GET_ELDER_USERS')
export const UPDATE_USER_STATUS = addNamespace(NAMESPACE, 'UPDATE_USER_STATUS')
export const SET_ELDER_USERS = addNamespace(NAMESPACE, 'SET_ELDER_USERS')
export const GET_ELDER_USERS_ERROR = addNamespace(
  NAMESPACE,
  'GET_ELDER_USERS_ERROR',
)
export const UPDATE_USER_STATUS_ERROR = addNamespace(
  NAMESPACE,
  'UPDATE_USER_STATUS_ERROR',
)
export const UPDATE_MAX_LOAD = addNamespace(NAMESPACE, 'UPDATE_MAX_LOAD')

export const getElderUsers = makeAction(GET_ELDER_USERS)

export const setElderUsers = makeAction(
  SET_ELDER_USERS,
  (users: ElderUsers) => ({
    users,
  }),
)

export const getElderUsersError = makeAction(GET_ELDER_USERS_ERROR)

export const updateUserStatus = makeAction(
  UPDATE_USER_STATUS,
  (username: string, status: UserStatus) => ({ username, status }),
)

export const updateUserStatusError = makeAction(UPDATE_USER_STATUS_ERROR)

export const updateMaxLoad = makeAction(
  UPDATE_MAX_LOAD,
  (username: string, maxLoad: number) => ({ username, maxLoad }),
)
