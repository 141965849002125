// @flow
import type { Saga } from 'redux-saga'
import { call, select, takeLatest } from 'redux-saga/effects'

import { GET_CHANGELOG } from 'modules/changelog'
import { pageSelectors } from 'routes/account/selectors'
import { addNamespace } from 'utils'
import { getAndSetFlow } from 'utils/services'

import { NAMESPACE, changelogActions } from './actions'

function* fetchAccountChangelogFlow(): Generator<Object, void, string> {
  const accountId = yield select(pageSelectors.getId)
  yield call(
    getAndSetFlow,
    `/et/accounts/${accountId}/changelog`,
    changelogActions.setChangelog,
  )
}

export function* watchAccountChangelog(): Saga<*> {
  yield takeLatest(
    addNamespace(NAMESPACE, GET_CHANGELOG),
    fetchAccountChangelogFlow,
  )
}

export default watchAccountChangelog
