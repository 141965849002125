// @flow
import React from 'react'

import { colors, spacings } from '@elder/common'
import {
  CheckOutlined,
  CloseOutlined as InvalidIcon,
} from '@mui/icons-material'
import {
  Link,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
  styled,
} from '@mui/material'
import { Link as RouterLink } from 'react-router-dom'

import * as routes from 'routes'
import { formatLink } from 'utils/links'

const SubheaderTableCell = styled(TableCell)`
  padding: ${spacings.tiny};
  border-top: 2px solid ${colors.marceline};
  border-bottom: 1px solid ${colors.marceline};
`
type Props = {|
  +infoComplete: boolean,
  +partnerName: ?string,
  +accountId: string,
|}
export const ThirdPartyPlacementInfo = ({
  infoComplete,
  partnerName,
  accountId,
}: Props) => (
  <TableContainer>
    <Table>
      <TableHead>
        <TableRow>
          <TableCell sx={{ p: 1 }}>
            <Typography variant="h6" sx={{ fontWeight: 700 }}>
              Placement details
            </Typography>
          </TableCell>
        </TableRow>
        <TableRow>
          <SubheaderTableCell>Done</SubheaderTableCell>
          <SubheaderTableCell>Partner</SubheaderTableCell>
        </TableRow>
      </TableHead>
      <TableBody>
        <TableRow>
          <TableCell>
            {infoComplete ? <CheckOutlined /> : <InvalidIcon />}
          </TableCell>
          <TableCell>
            <>
              <Typography variant="body2">{partnerName || ''}</Typography>
              <Link
                component={RouterLink}
                to={formatLink(routes.partner, { accountId })}
              >
                {accountId}
              </Link>
            </>
          </TableCell>
        </TableRow>
      </TableBody>
    </Table>
  </TableContainer>
)
