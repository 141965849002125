// @flow

import * as Yup from 'yup'

import type { Template } from 'features/notes/domain'
import { createTagOptions, createAdapter } from 'features/notes/helpers'

const initialField = {
  type: 'DATE',
  name: 'week',
  label: 'Which week does this review relate to?',
  validation: Yup.string().required('Required'),
}

const weeklyReviewFields = [
  {
    type: 'TEXT_AREA',
    name: 'cooking',
    label: "How was the carer's cooking?",
    info: 'Did they cook the planned meals? Did the CR enjoy the food?',
    validation: Yup.string().required('Required'),
  },
  {
    type: 'TEXT_AREA',
    name: 'eventsAndVisits',
    label: 'Were there any events or visits in the past week?',
    info: 'Did the carer provide adequate support?',
    validation: Yup.string().required('Required'),
  },
  {
    type: 'TEXT_AREA',
    name: 'crAndCarerBonding',
    label:
      'What did the carer and care recipient do together socially last week?',
    info: 'Did the planned activities happen? Were they enjoyable?',
    validation: Yup.string().required('Required'),
  },
  {
    type: 'TEXT_AREA',
    name: 'concernOccurrence',
    label: 'Did any of the stated concerns occur?',
    info: 'How did the carer help to avoid these? What could be improved?',
    validation: Yup.string().required('Required'),
  },
  {
    type: 'TEXT_AREA',
    name: 'comments',
    label: 'Are there any other comments regarding how this week went?',
    validation: Yup.string(),
  },
  {
    type: 'TEXT',
    name: 'timeAndDateForNextContact',
    label: 'What date and time works best for you for our next call?',
    validation: Yup.string().required('Required'),
  },
]

const firstWeekSections = [
  {
    fields: [initialField],
  },
  {
    title: 'First week success',
    fields: [
      {
        type: 'TEXT_AREA',
        name: 'impressionMade',
        label:
          'How well did the carer get on with the CR and any of their friends or family?',
        info: 'What went well and what could be improved?',
        validation: Yup.string().required('Required'),
      },
      {
        type: 'TEXT_AREA',
        name: 'petsLookedAfter',
        label: '(If pets) Was the pet well looked after?',
        info: 'What went well? Can anything be improved?',
        validation: Yup.string(),
      },
      {
        type: 'TEXT_AREA',
        name: 'crsPersonalCare',
        label: "How was the quality of the carer's personal care?",
        info: 'Was the CR comfortable with how care was delivered? What could improve?',
        validation: Yup.string().required('Required'),
      },
    ],
  },
  {
    title: 'Weekly Review',
    fields: [
      ...weeklyReviewFields,
      {
        type: 'SELECT',
        name: 'tags',
        label: 'Tags',
        multi: true,
        options: createTagOptions('Review', 'First Week', 'COVID-19'),
        initialValue: ['Review', 'First Week'],
      },
    ],
  },
]

const weeklySections = [
  {
    fields: [
      initialField,
      ...weeklyReviewFields,
      {
        type: 'SELECT',
        name: 'tags',
        label: 'Tags',
        multi: true,
        options: createTagOptions('Review', 'COVID-19'),
        initialValue: ['Review'],
      },
    ],
  },
]

const weeklyReviewTitle = 'Weekly Review'

export const weeklyReview: Template = {
  value: 'WEEKLY_REVIEW',
  label: weeklyReviewTitle,
  adapter: ({ tags, ...textValues }) => ({
    title: weeklyReviewTitle,
    tags,
    text: createAdapter(weeklySections, textValues),
  }),
  sections: weeklySections,
}

const firstWeekReviewTitle = 'First Week Review'

export const firstWeekReview: Template = {
  value: 'FIRST_WEEK_REVIEW',
  label: firstWeekReviewTitle,
  adapter: ({ tags, ...textValues }) => ({
    title: firstWeekReviewTitle,
    tags,
    text: createAdapter(firstWeekSections, textValues),
  }),
  sections: firstWeekSections,
}
