// @flow
import type { Node } from 'react'
import React from 'react'

import { AppBar, Box, Breadcrumbs, Toolbar, Typography } from '@mui/material'
import { Link } from 'react-router-dom'

import { logAmplitudeEvent } from 'features/analytics/amplitude'

type Breadcrumb = {|
  +title: Node,
  +to: string,
|}

type Props = {|
  +breadcrumbs: Array<Breadcrumb>,
  +children?: Node,
|}

/** Optional toolbar containing breadcrumbs and a slot for page controls. Note
 * that you probably have to customize the styles of the children to achieve
 * contrast. Unfortunately this can't be easily automated with Mui:
 * https://github.com/mui/material-ui/issues/21861 */
export const BreadcrumbsBar = ({ breadcrumbs, children }: Props) => (
  <AppBar
    position="static"
    sx={{
      zIndex: 1150,
      position: 'relative',
      borderWidth: 0,
    }}
  >
    <Toolbar>
      <Breadcrumbs
        aria-label="breadcrumb"
        sx={{ color: 'rgba(255,255,255,0.6)' }}
      >
        {(breadcrumbs || []).map(({ title, to }, index) => (
          <Typography
            component={Link}
            key={to}
            to={to}
            onClick={() => logAmplitudeEvent('Breadcrumb Clicked')}
            color="inherit"
            {...(index === breadcrumbs.length - 1
              ? {
                  'aria-current': 'page',
                  sx: { color: 'rgba(255,255,255,0.87)' },
                }
              : {})}
          >
            {title}
          </Typography>
        ))}
      </Breadcrumbs>
      {children && (
        <Box
          sx={{
            ml: 'auto',
            '.MuiDivider-root': {
              borderColor: 'rgba(255,255,255,0.23)',
            },
            '.MuiTextField-root': {
              minWidth: '300px',
            },
          }}
        >
          {children}
        </Box>
      )}
    </Toolbar>
  </AppBar>
)
