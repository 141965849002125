// @flow
import { enumFromObjectKeys } from '@elder/common'

import type { FlowOfType } from 'elder/types'

import type { Name } from 'domain/name'

export const TESTIMONIAL_STATUS = {
  APPROVED: 'APPROVED',
  REJECTED: 'REJECTED',
  PENDING: 'PENDING',
}

export const testimonialStatus = enumFromObjectKeys(TESTIMONIAL_STATUS)

export type TestimonialStatus = FlowOfType<typeof testimonialStatus>

export type Testimonial = {|
  testimonialId: string,
  solutionId: string,
  placementId: string,
  accountId: string,
  rating: number,
  review: string,
  startDate: string,
  endDate?: ?string,
  reviewer: {|
    profileId: string,
    name: Name,
  |},
  area: string,
  status: TestimonialStatus,
  submittedDate?: string,
|}

export type Testimonials = Array<Testimonial>
