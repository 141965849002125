// @flow
import { elderLightThemeOptions } from '@elder/common'
import { createTheme } from '@mui/material/styles'
import { deepmerge } from '@mui/utils'

const etMuiTheme = {}

export const muiTheme = createTheme(
  deepmerge(elderLightThemeOptions, etMuiTheme),
)
