// @flow
import * as React from 'react'

type Props = {|
  +val?: ?React.Node,
|}

export const EntryShowBoolean = ({ val }: Props) => {
  let text
  if (val === null || val === undefined) {
    text = ''
  } else if (val) {
    text = 'Yes'
  } else {
    text = 'No'
  }
  return <div>{text}</div>
}
