// @flow
import Immutable from 'immutable'

import { SET, CANCEL_EDIT } from 'modules/pageEdit'
import {
  NAMESPACE,
  UPDATE_OVERVIEW,
  UPDATE_OVERVIEW_VALIDATION_FAIL,
} from 'routes/profile/summary/actions'
import { addNamespace } from 'utils'

const InitialStateRecord = Immutable.Record({
  overview: Immutable.Map(),
})

export const profileValidationErrorReducer = (
  state: Object = new InitialStateRecord(),
  action: Object,
) => {
  switch (action.type) {
    case UPDATE_OVERVIEW_VALIDATION_FAIL:
      return state.set('overview', Immutable.fromJS(action.error.overview))
    case addNamespace(NAMESPACE, SET):
    case addNamespace(NAMESPACE, CANCEL_EDIT):
    case UPDATE_OVERVIEW:
      return new InitialStateRecord()
    default:
      return state
  }
}

export default profileValidationErrorReducer
