// @flow
import * as Sentry from '@sentry/react'
import type { Saga } from 'redux-saga'
import createSagaMiddleware from 'redux-saga'
import { call, fork } from 'redux-saga/effects'

import { sagas as appFlow } from 'app'
import { accountExecutiveHierarchySagas } from 'features/accountExecutive/saga'
import { saga as customerPaymentsSaga } from 'features/accountPayments/saga'
import { billingAccountsSaga } from 'features/billingAccounts/saga'
import { carelogsSaga } from 'features/carelogs/sagas/carelogs'
import { sagas as carerBasicInfoSagaOld } from 'features/carerBasicInfo/oldSaga'
import { carerBasicInfoSaga } from 'features/carerBasicInfo/saga'
import { saga as carerChangelogSaga } from 'features/carerChangelog/sagas'
import { sagas as carerDetailsHeaderSaga } from 'features/carerDetailsHeader/saga'
import { carerDocumentsSaga } from 'features/carerDocuments/saga'
import { saga as carerFeedbackSaga } from 'features/carerFeedback/saga'
import { carerFinanceSaga } from 'features/carerFinances/finance/saga'
import { saga as carerFinancesSaga } from 'features/carerFinances/saga'
import { sagas as carerMatchingSaga } from 'features/carerMatching/saga'
import { carerMessagingSaga } from 'features/carerNotifications/saga'
import { saga as carerPreferencesSaga } from 'features/carerPreferences/saga'
import { sagas as carerSkillsSaga } from 'features/carerSkills/store/skills/saga'
import { workExperiencesSaga } from 'features/carerSkills/store/workExperience/saga'
import { elderUsersSagas } from 'features/elderUsers/saga'
import { experimentSaga } from 'features/experiments/saga'
import { notesSaga } from 'features/notes/saga'
import { saga as carersSaga } from 'features/professionals'
import { saga as carersNewSaga } from 'features/professionals/professionalNew'
import { medicationSaga } from 'features/recipient/medication/saga'
import { recipientDetailSaga } from 'features/recipient/saga'
import { recipientDocumentsSagas } from 'features/recipientDocuments/store/sagas'
import { communicationPreferencesSaga } from 'features/relatedAccounts/sagas'
import { saga as accountSaga } from 'routes/account'
import { saga as accountChangelogSaga } from 'routes/account/changelog'
import { saga as accountContactsSaga } from 'routes/account/contacts'
import { saga as accountEnvironmentSaga } from 'routes/account/environment'
import { watchTransactionNewFlow } from 'routes/account/finance/transactionNew/sagas'
import { saga as accountSummarySaga } from 'routes/account/summary'
import { careModelTypeSagas } from 'routes/account/summary/sagas/careModelTypeFlag'
import { saga as createAccountSaga } from 'routes/accounts/createAccount'
import { saga as paymentsSaga } from 'routes/payments'
import { saga as profileChangelogSaga } from 'routes/profile/changelog'
import { saga as profileWrapperSaga } from 'routes/profile/profileWrapper'
import { saga as profileSummarySaga } from 'routes/profile/summary'
import { saga as profileNewSaga } from 'routes/profiles/profileNew'
import { saga as salesforceSaga } from 'routes/salesforce'
import { keepCurrentDateAccurate } from 'saga/currentDate'

export default function* (): Saga<*> {
  yield fork(customerPaymentsSaga)
  yield fork(watchTransactionNewFlow)
  yield fork(carerPreferencesSaga)
  yield fork(carerBasicInfoSagaOld)
  yield fork(carerBasicInfoSaga)
  yield fork(carerDocumentsSaga)
  yield fork(carerFinancesSaga)
  yield fork(carerFeedbackSaga)
  yield fork(carerMatchingSaga)
  yield fork(carerSkillsSaga)
  yield fork(carerDetailsHeaderSaga)
  yield fork(carerMessagingSaga)
  yield fork(keepCurrentDateAccurate)
  yield fork(workExperiencesSaga)
  yield fork(notesSaga)
  yield fork(salesforceSaga)
  yield fork(profileNewSaga)
  yield fork(profileWrapperSaga)
  yield fork(profileSummarySaga)
  yield fork(profileChangelogSaga)
  yield fork(paymentsSaga)
  yield fork(createAccountSaga)
  yield fork(accountSaga)
  yield fork(accountSummarySaga)
  yield fork(accountChangelogSaga)
  yield fork(accountEnvironmentSaga)
  yield fork(recipientDetailSaga)
  yield fork(accountContactsSaga)
  yield fork(carersSaga)
  yield fork(carersNewSaga)
  yield fork(carerChangelogSaga)
  yield fork(carerFinanceSaga)
  yield fork(carelogsSaga)
  yield fork(elderUsersSagas)
  yield fork(accountExecutiveHierarchySagas)
  yield fork(communicationPreferencesSaga)
  yield fork(recipientDocumentsSagas)
  yield fork(careModelTypeSagas)
  yield fork(billingAccountsSaga)
  yield fork(medicationSaga)
  yield fork(experimentSaga)
  yield call(appFlow)
}

export const initializeSagas = (rootSaga: Object) => {
  const sagaMiddleware = createSagaMiddleware({
    onError(e) {
      Sentry.captureException(e)
      if (__DEV__) {
        // eslint-disable-next-line no-console
        console.error(e)
      }
    },
  })
  const runRootSaga = () => {
    sagaMiddleware.run(rootSaga)
  }

  return { sagaMiddleware, runRootSaga }
}
