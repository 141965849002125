import { useEffect, useState } from 'react'

import { CloseableDialog } from '@elder/common'
import type { SalesforceUser } from '@elder/et-facade-et'
import {
  useEtUpdateAssigneeAndTeamForElderChatUsingPUT as useAssignConversation,
  useEtGetAvailableSalesforceUsersUsingGET,
} from '@elder/et-facade-et'
import { LoadingButton } from '@mui/lab'
import { Autocomplete, Avatar, Box, TextField, Typography } from '@mui/material'
import { orderBy } from 'lodash'
import { useSnackbar } from 'notistack'
import type { ConversationData as Conversation, Inbox } from 'talkjs/all'

export const DEFAULT_ASSIGNEE_CARERS = 'salesforce-integration@elder.org'
export const DEFAULT_ASSIGNEE_CUSTOMERS = 'account-management@elder.org'

interface AssignDialogProps {
  inbox: Inbox | undefined
}

export const AssignConversationDialog = ({ inbox }: AssignDialogProps) => {
  const { enqueueSnackbar } = useSnackbar()

  const [assignee, setAssignee] = useState<string>('')
  const mutation = useAssignConversation()
  const [conversation, setConversation] = useState<Conversation | null>(null)
  const currentAssignee = conversation?.custom.assignee
  const defaultAssignee =
    conversation?.custom.conversationType === 'CARER_TO_ELDER'
      ? DEFAULT_ASSIGNEE_CARERS
      : DEFAULT_ASSIGNEE_CUSTOMERS

  const elderUsersQuery = useEtGetAvailableSalesforceUsersUsingGET()
  const users = elderUsersQuery.data || []

  useEffect(() => {
    if (!inbox) return

    const sub = inbox.onCustomConversationAction(({ action, conversation }) => {
      if (action !== 'assign') return
      if (!conversation) return
      setConversation(conversation)
      setAssignee(conversation.custom.assignee || defaultAssignee)
    })

    return () => sub.unsubscribe()
  }, [inbox, mutation, defaultAssignee])

  useEffect(() => {
    if (!mutation.isSuccess) return
    enqueueSnackbar(`Conversation assigned to ${assignee}`, {
      variant: 'success',
    })
    setConversation(null)
    setAssignee('')
    mutation.reset()
  }, [mutation, inbox, enqueueSnackbar, assignee])

  useEffect(() => {
    if (!mutation.error) return
    enqueueSnackbar(
      `Failed to assign conversation: ${mutation.error.message}`,
      { variant: 'error' },
    )
  }, [enqueueSnackbar, mutation.error])

  const submitDisabled =
    !assignee.trim() ||
    (Boolean(currentAssignee) && assignee.trim() === currentAssignee)

  const submit = () => {
    if (!conversation) throw new Error('No conversation to reassign')
    mutation.mutate({
      conversationId: conversation.id,
      data: { newAssignee: assignee },
    })
  }

  return (
    <CloseableDialog
      title="Assign conversation"
      open={Boolean(conversation)}
      onClose={() => setConversation(null)}
      maxWidth={false}
      extraActions={
        <LoadingButton
          onClick={submit}
          loading={mutation.isPending}
          disabled={submitDisabled}
          variant="contained"
        >
          Assign conversation
        </LoadingButton>
      }
      sx={{ alignItems: 'start' }}
    >
      <Typography>
        <strong>Current assignee:</strong>{' '}
        {conversation?.custom.assignee || 'None'}
      </Typography>
      <Autocomplete
        renderInput={(params) => (
          <TextField label="New assignee" autoFocus {...params} />
        )}
        value={users.find((user) => user.email === assignee)}
        onChange={(_, user) => setAssignee(user?.email || defaultAssignee)}
        options={orderBy(users, [getLastname])}
        getOptionLabel={getUserLabel}
        renderOption={renderOption}
        loading={elderUsersQuery.isPending}
        sx={{ minWidth: 520 }}
        slotProps={{ popper: { sx: { zIndex: 2000 } } }}
      />
    </CloseableDialog>
  )
}

const renderOption = (
  props: React.HTMLAttributes<HTMLLIElement>,
  { photoUrl, name, email }: SalesforceUser,
) => (
  <Box
    component="li"
    sx={{ display: 'flex', alignContent: 'center', gap: 2 }}
    {...props}
  >
    <Avatar sx={{ w: 32, h: 32, fontSize: 16 }} src={photoUrl} alt={name}>
      {name
        .split(/\s+/)
        .map((part) => part[0])
        .join('')}
    </Avatar>
    <div>
      <Typography>{name}</Typography>
      <Typography variant="body2" color="text.secondary">
        &lt;{email}&gt;
      </Typography>
    </div>
  </Box>
)

const getLastname = (u: SalesforceUser) => u.name.split(/\s/).at(-1)
const getUserLabel = (u: SalesforceUser) => `${u.name} <${u.email}>`
