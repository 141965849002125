// @flow
/* eslint-disable react/prop-types */
import * as React from 'react'

import { ThemeProvider } from '@mui/material/styles'
import { LocalizationProvider } from '@mui/x-date-pickers'
import { AdapterMoment } from '@mui/x-date-pickers/AdapterMoment'
import * as Sentry from '@sentry/react'
import { QueryClient, QueryClientProvider } from '@tanstack/react-query'
import { ConnectedRouter } from 'connected-react-router/immutable'
import { SnackbarProvider } from 'notistack'
import { parse, stringify } from 'query-string'
import { createRoot } from 'react-dom/client'
import { Provider } from 'react-redux'
import { useLocation } from 'react-router-dom'
import type { Saga } from 'redux-saga'
import { QueryParamProvider } from 'use-query-params'
import { ReactRouter5Adapter } from 'use-query-params/adapters/react-router-5'

import { getConfig } from 'app/config'
import { initializeValidators } from 'app/validation'

import { muiTheme } from './muiTheme'
import { initializeStore } from './store/initializeStore'

const { sentry: sentryConfig, environment } = getConfig()

Sentry.init({
  dsn: sentryConfig.projectUrl,
  whitelist: sentryConfig.whitelist,
  environment,
  release: __VERSION__,
  tags: { git_commit: __GIT_COMMIT__ },
})
/* eslint-disable no-underscore-dangle */
window.__VERSION__ = __VERSION__
window.__GIT_COMMIT__ = __GIT_COMMIT__
/* eslint-enable no-underscore-dangle */

const queryClient = new QueryClient({
  defaultOptions: { queries: { refetchOnWindowFocus: false } },
})

const ScrollToTop = () => {
  const { pathname } = useLocation()

  // $FlowOptOut
  React.useEffect(() => {
    window.scrollTo(0, 0)
    const mainLayout = document.getElementById('main-layout')
    if (mainLayout) mainLayout.scrollTop = 0
  }, [pathname])

  return null
}

const runApp = (
  Main: React.ComponentType<any>,
  ErrorComponent: React.ComponentType<any>,
  rootReducer: Function,
  rootSaga: () => Saga<*>,
) => {
  const { store, history, runRootSaga } = initializeStore({
    rootReducer,
    rootSaga,
  })

  runRootSaga()
  initializeValidators()

  const renderMain = () => {
    const container = document.getElementById('root')
    if (container) {
      const root = createRoot(container)
      root.render(<App />)
    }
  }

  const App = () => (
    <Provider store={store}>
      <ThemeProvider theme={muiTheme}>
        <Sentry.ErrorBoundary fallback={ErrorComponent}>
          <QueryClientProvider client={queryClient}>
            <ConnectedRouter history={history}>
              <QueryParamProvider
                adapter={ReactRouter5Adapter}
                options={{
                  searchStringToObject: parse,
                  objectToSearchString: stringify,
                }}
              >
                <SnackbarProvider maxSnack={3}>
                  <LocalizationProvider
                    dateAdapter={AdapterMoment}
                    adapterLocale="en_GB"
                  >
                    <ScrollToTop />
                    <Main />
                  </LocalizationProvider>
                </SnackbarProvider>
              </QueryParamProvider>
            </ConnectedRouter>
          </QueryClientProvider>
        </Sentry.ErrorBoundary>
      </ThemeProvider>
    </Provider>
  )

  renderMain()
}

const modules: any = Promise.all([
  import(/* webpackChunkName: 'ui' */ 'routes/index'),
  import(/* webpackChunkName: 'reducers' */ 'store/reducer'),
  import(/* webpackChunkName: 'sagas' */ 'store/saga'),
])

const startApp = () =>
  modules.then(([componentModule, reducerModule, sagaModule]) => {
    const { Main, ErrorComponent } = componentModule
    const rootReducer = reducerModule.appReducer
    const rootSaga = sagaModule.default

    runApp(Main, ErrorComponent, rootReducer, rootSaga)
  })

startApp()
