// @flow
import { object, arrayOf, string } from 'flow-validator'

import { endpointBuilder } from 'elder/services'

import { medicine } from 'features/recipient/medication/domain'

// Number of medicines to fetch by default
const DEFAULT_MAX_SIZE = 20
const medicineRequest = object({
  queryText: string.optional(),
  paginationToken: string.optional(),
  maxSize: string.optional(),
})
const medicineResponse = object({
  items: arrayOf(medicine),
})
export const medicationService = {
  getMedicines: endpointBuilder(medicineRequest, medicineResponse)
    .withUrlTemplate(
      '/et/medicines/?queryText={queryText}&token={paginationToken}&max={maxSize}',
      ({ queryText, paginationToken, maxSize }) => ({
        queryText,
        paginationToken,
        maxSize: maxSize || DEFAULT_MAX_SIZE,
      }),
    )
    .build(),
}
