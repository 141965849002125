// @flow
import * as Yup from 'yup'

import type { Template } from 'features/notes/domain'
import { createAdapter } from 'features/notes/helpers'

export const sections = [
  // Sections also used in tempInitialTransfer
  {
    fields: [
      {
        type: 'TEXT_AREA',
        name: 'othersInvolved',
        label: 'Who is arranging care for the CR? What is the family dynamic?',
        validation: Yup.string().required('Required'),
      },
      {
        type: 'TEXT_AREA',
        name: 'discriminationQuestion',
        label:
          'What was the customers response to being asked if there is risk of discrimination?',
        validation: Yup.string().required('Required'),
      },
      {
        type: 'TEXT_AREA',
        name: 'clinicalDiscussions',
        label:
          'What Clinical Discussions have been had? Have you discussed Elder’s Introductory Status?',
        validation: Yup.string().required('Required'),
      },
      {
        type: 'TEXT_AREA',
        name: 'reasonToBuy',
        label:
          "Which value best describes the customer's reason for choosing Elder?",
        info: 'Reasons to buy include choice, control, support and value.',
        validation: Yup.string().required('Required'),
      },
    ],
  },
  {
    title: 'Carer Details',
    fields: [
      {
        type: 'TEXT_AREA',
        name: 'introductions',
        label: 'Have the customer and carer spoken?',
        validation: Yup.string().required('Required'),
      },
      {
        type: 'TEXT_AREA',
        name: 'carerConfirmation',
        label: 'How have you confirmed the placement with the carer?',
        validation: Yup.string().required('Required'),
      },
    ],
  },
  {
    title: 'Pricing and Billing',
    fields: [
      {
        type: 'TEXT_AREA',
        name: 'pricing',
        label: 'What is the agreed weekly rate? How is the care funded?',
        validation: Yup.string().required('Required'),
      },
    ],
  },
  {
    title: 'Final Checks',
    fields: [
      {
        type: 'CHECKBOX',
        name: 'homeAddress',
        label: 'Added billing contact home address',
        validation: Yup.boolean(),
      },
      {
        type: 'CHECKBOX',
        name: 'introCallExplained',
        label:
          'Discussed the Family Support Specialist Introduction Call and the next steps',
        validation: Yup.boolean(),
      },
      {
        type: 'CHECKBOX',
        name: 'followUpEmail',
        label: 'Sent the follow up email confirming next steps',
        validation: Yup.boolean(),
      },
    ],
  },
]

const permSections = [
  {
    title: 'Overview',
    fields: [
      {
        type: 'TEXT_AREA',
        name: 'overview',
        label:
          'Why is the CR looking for LIC? What is their previous experience with care?',
        validation: Yup.string().required('Required'),
      },
      {
        type: 'TEXT_AREA',
        name: 'churnRisk',
        label: 'What is the risk of the account churning in 30 Days?',
        info: 'For example, CR refusal, low care needs, family disagree, matching challenges',
        validation: Yup.string().required('Required'),
      },
    ],
  },
  ...sections,
]

const title = 'Initial Account Transfer - Perm'

export const initialTransfer: Template = {
  value: 'INITIAL_TRANSFER',
  label: title,
  adapter: ({ tags, ...textValues }) => ({
    title,
    tags,
    text: createAdapter(permSections, textValues),
  }),
  sections: permSections,
}
