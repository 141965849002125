// @flow
/* eslint-disable flowtype/no-weak-types */
import { getApp } from 'store'

import { APP_STATES } from './reducers/appState'

export const getAppState = (state: Object) => getApp(state).get('appState')

export const getLoginDetails = (state: Object) =>
  getApp(state).get('loginDetails')

const getBreadcrumbState = (state: Object) =>
  getApp(state).get('breadcrumbState')

export const getBreadcrumbNavList = (state: Object) =>
  getBreadcrumbState(state).get('path')

export const getBreadcrumbHasSearch = (state: Object) =>
  getBreadcrumbState(state).get('hasSearch')

export const getReferenceDataState = (state: Object) =>
  getApp(state).get('referenceData')

export const getReferenceDataJSState = (state: Object) =>
  getApp(state).get('referenceData').toJS()

export const getIsApplicationReady = (state: Object) =>
  getAppState(state) === APP_STATES.APPLICATION_READY
