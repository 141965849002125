// @flow
import styled from 'styled-components'

/**
 * A FormButtons represents a section of a form containing buttons such as save and cancel.
 */
export const FormButtons = styled.div`
  display: flex;
  justify-content: flex-end;
  margin-top: 20px;

  & > div {
    margin-right: 15px;
  }
`

export default FormButtons
