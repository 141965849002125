// @flow
import React from 'react'

import styled from 'styled-components'

const ErrorMessage = styled.div`
  color: #ef6461;
  display: block;
  width: 100%;
`

type Props = {|
  +errorMessages: Array<string>,
|}

export const FormsyErrorMessages = ({ errorMessages }: Props) => {
  if (!errorMessages || errorMessages.length === 0) {
    return null
  }

  return (
    <div>
      {errorMessages.map((errorMessage, index) => (
        // eslint-disable-next-line react/no-array-index-key
        <ErrorMessage key={index.toString()}>{errorMessage}</ErrorMessage>
      ))}
    </div>
  )
}

export default FormsyErrorMessages
