// @flow

import React from 'react'

import { ElderModalDialog } from 'components/ModalDialog/ModalDialog'
import type { NoteRequestParams, Note, Template } from 'features/notes/domain'

import { EditNoteDialog } from './EditNoteDialog'

export type Props = {|
  +close: () => void,
  +editNote: (NoteRequestParams) => void,
  +isSaving: boolean,
  +templates?: Array<Template>,
  +note: Note,
|}

export const EditNoteModal = ({
  close,
  editNote,
  isSaving,
  templates,
  note,
}: Props) => (
  <ElderModalDialog heading="Edit note">
    <EditNoteDialog
      cancel={close}
      edit={editNote}
      saving={isSaving}
      templates={templates}
      note={note}
    />
  </ElderModalDialog>
)
