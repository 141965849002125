// @flow
import { omit } from 'lodash'
import { connect } from 'react-redux'

import { getCanCreatePro } from 'app'
import {
  getLanguagesOptions,
  getCareExpertiseOptions,
  getMedicalConditionsOptions,
  getOnboardingLocationOptions,
  getEthnicitiesOptions,
  getCountriesOptions,
  getCarerStatusOptions,
  getCarerWorkerStatusOptions,
} from 'app/referenceData'
import { ProfessionalsTable } from 'features/professionals/ProfessionalsTable/ProfessionalsTable'
import { matchDrivingOptions, matchGenderOptions } from 'utils/groupOptions'

import { downloadCsv as downloadCsvAction } from './actions'
import { downloadCsvButtonSpinnerSelectors } from './selectors'

const mapActionCreators = (dispatch) => ({
  downloadCsv: (params) =>
    dispatch(downloadCsvAction(omit(params, ['from', 'size']))),
})

const mapStateToProps = (state) => ({
  canCreatePro: getCanCreatePro(state),
  downloadCsvInProgress:
    downloadCsvButtonSpinnerSelectors.getLoadingInProgress(state),
  options: {
    drivingOptions: matchDrivingOptions,
    genderOptions: matchGenderOptions,
    languagesOptions: getLanguagesOptions(state),
    careExpertiseOptions: getCareExpertiseOptions(state),
    citiesOptions: getOnboardingLocationOptions(state),
    ethnicitiesOptions: getEthnicitiesOptions(state),
    nationalitiesOptions: getCountriesOptions(state),
    conditionExpertiseOptions: getMedicalConditionsOptions(state),
    carerStatusOptions: getCarerStatusOptions(state),
    carerWorkerStatusOptions: getCarerWorkerStatusOptions(state),
  },
})

export default connect(mapStateToProps, mapActionCreators)(ProfessionalsTable)
