// @flow
import type { Saga } from 'redux-saga'
import { call, put, takeLatest } from 'redux-saga/effects'

import { NAMESPACE } from 'features/carerBasicInfo/store'
import { setCarer } from 'features/carerPageGenerics/store/actions'
import { clearLoading, setLoading } from 'features/loading/actions'
import {
  applicationError,
  applicationSuccess,
} from 'features/snackbar/snackbar'
import { fetchFlow, describeServiceError } from 'utils/services'

import { UPDATE_BANK_DETAILS } from './store/actions'

export function* putProfessionalBankDetails({
  data,
  carerId,
}: {
  +data: Object,
  +carerId: string,
}): Generator<Object, void, Object> {
  const endpoint = `/et/professionals/${carerId}/bankdetails`
  const method = 'PUT'

  try {
    yield put(setLoading(NAMESPACE))
    const responseBody = yield call(fetchFlow, endpoint, method, data)
    yield put(setCarer(responseBody))
    applicationSuccess({
      title: 'Professional saved',
      message: 'Professional bank details were updated successfully.',
    })
  } catch (error) {
    applicationError(
      describeServiceError(error, `Error with service call ${endpoint}`),
    )
  } finally {
    yield put(clearLoading(NAMESPACE))
  }
}

export function* saga(): Saga<*> {
  yield takeLatest(UPDATE_BANK_DETAILS, putProfessionalBankDetails)
}
