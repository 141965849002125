// @flow
import { call, select, takeLatest } from 'redux-saga/effects'

import { GET } from 'modules/pageEdit'
import { NAMESPACE, pageActions } from 'routes/account/environment/actions'
import { pageSelectors } from 'routes/account/selectors'
import { addNamespace } from 'utils'
import { getAndSetFlow } from 'utils/services'

function* fetchAccountEnvironmentFlow(): Generator<Object, void, string> {
  const accountId = yield select(pageSelectors.getId)
  yield call(
    getAndSetFlow,
    `/et/accounts/${accountId}/environment`,
    pageActions.set,
  )
}

export function* watchAccountEnvironment(): Generator<Object, void, Object> {
  yield takeLatest(addNamespace(NAMESPACE, GET), fetchAccountEnvironmentFlow)
}
