// @flow
import { type Node } from 'react'

/**
 * Placeholder component used to identify elements that should appear as
 * "actions" for a summary card.
 */
type Props = {|
  +children: Node,
|}

export const SummaryCardActions = ({ children }: Props) => children
