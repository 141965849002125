// @flow
import { object, string, Type, arrayOf, boolean } from 'flow-validator'

import type { FlowOfType } from 'elder/types'

import type { Field } from 'domain/fields'
import type { GenericOption } from 'domain/option'

export type NoteRequestParams = {|
  +title: string,
  +text: string,
  +tags: Array<string>,
|}

export type TemplateTypes =
  | 'DEFAULT'
  | 'INITIAL_TRANSFER'
  | 'TEMP_INITIAL_TRANSFER'
  | 'FIRST_WEEK_PLAN'
  | 'WEEKLY_PLAN'
  | 'FIRST_WEEK_REVIEW'
  | 'WEEKLY_REVIEW'
  | 'CA_CALL'
  | 'INITIAL_SALES_CALL'
  | 'MATCHING_REQUIREMENTS'

export type Section = {|
  +title?: string,
  +info?: string,
  +fields: Array<Field>,
|}

export type Template = $ReadOnly<{|
  ...GenericOption<TemplateTypes>,
  +adapter: (Object) => NoteRequestParams,
  +sections: Array<Section>,
|}>

export const getUniqueNotesEntityId = (
  notesEntityType: string,
  notesEntityId: string,
) => `${notesEntityType}:${notesEntityId}`

export const getNotesRequest: Type<*> = object({
  type: string,
  id: string,
})

const entity = object({
  displayText: string,
  identifier: string,
  type: string,
})

const note = object({
  archived: boolean,
  createdAt: string,
  createdBy: string,
  id: string,
  pinned: boolean,
  tags: arrayOf(string),
  text: string,
  title: string,
  relatedEntities: arrayOf(entity),
  parentEntity: entity,
})

export type Note = FlowOfType<typeof note>

export const getNotesResponse: Type<*> = object({
  notes: arrayOf(note),
})

export const saveNoteRequest: Type<*> = object({
  tags: arrayOf(string),
  text: string.minLength(0).maxLength(8192),
  title: string.minLength(0).maxLength(255),
  parentEntity: entity,
  relatedEntities: arrayOf(entity).optional(),
})

export const pinNoteRequest: Type<*> = object({
  noteId: string,
  pinned: boolean,
  pinEntity: object({ entityType: string, entityId: string }),
})

export const archiveNoteRequest: Type<*> = object({
  noteId: string,
  archived: boolean,
})

export const editNoteRequest: Type<*> = object({
  noteId: string,
  parentEntity: entity,
  tags: arrayOf(string),
  text: string.minLength(0).maxLength(8192),
  title: string.minLength(0).maxLength(255),
})
