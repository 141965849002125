import { createContext, useContext } from 'react'

import type { TransformedPlacement } from './transformSolution'

export interface PlacementContext {
  placement: TransformedPlacement
}

export const placementContext = createContext<PlacementContext | null>(null)

export const usePlacementContext = () => {
  const context = useContext(placementContext)
  if (!context) throw new Error('usePlacementContext used outside Provider')
  return context
}
