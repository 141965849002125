// @flow
import { object, string, arrayOf, Type } from 'flow-validator'

import { endpointBuilder } from 'elder/services'

import { recipient } from 'features/recipient/domain'

export const completeUploadDocumentResponse: Type<*> = recipient

export const downloadDocumentRequest: Type<*> = object({
  documentId: string,
})

export const downloadDocumentResponse: Type<*> = object({
  id: string,
  name: string,
  files: arrayOf(
    object({ id: string, contentType: string, downloadUrl: string }),
  ),
})

const documentMetadata = object({
  type: string, // TODO: maybe use ref data here in the future
  documentTypeName: string.optional(),
  comments: string.optional(),
})

export const preUploadRequestData = object({
  document: object({
    files: arrayOf(
      object({
        contentType: string,
      }),
    ),
  }),
  metadata: documentMetadata,
})

const beginUploadDocumentRequest: Type<*> = object({
  accountId: string,
  recipientId: string,
  data: preUploadRequestData,
})

const beginUploadDocumentResponse: Type<*> = object({
  documentId: string,
  files: arrayOf(
    object({ fileId: string, contentType: string, uploadUrl: string }),
  ),
})

const completeUploadDocumentRequest: Type<*> = object({
  accountId: string,
  recipientId: string,
  payload: object({
    documentId: string,
    metadata: documentMetadata,
  }),
})

export const recipientDocumentsService = {
  beginUploadDocument: endpointBuilder(
    beginUploadDocumentRequest,
    beginUploadDocumentResponse,
  )
    .withUrlTemplate(
      '/et/accounts/{accountId}/recipients/{recipientId}/document',
      ({ accountId, recipientId }) => ({ accountId, recipientId }),
    )
    .withHttpMethod('POST')
    .withBodyBuilder(({ data }) => data)
    .build(),
  completeUploadDocument: endpointBuilder(
    completeUploadDocumentRequest,
    completeUploadDocumentResponse,
  )
    .withUrlTemplate(
      '/et/accounts/{accountId}/recipients/{recipientId}/document/complete',
      ({ recipientId, accountId }) => ({
        recipientId,
        accountId,
      }),
    )
    .withHttpMethod('POST')
    .withBodyBuilder(({ payload }) => payload)
    .build(),
  downloadDocument: endpointBuilder(
    downloadDocumentRequest,
    downloadDocumentResponse,
  )
    .withUrlTemplate('/et/claims/document/{documentId}')
    .build(),
}
