// @flow
import * as React from 'react'

import styled from 'styled-components'

const StyledValue = styled.div`
  margin-bottom: 10px;
  white-space: pre-wrap;
  p {
    color: #19305e;
    margin-bottom: 5px;
  }
`

export const Value = ({ value }: {| value: any |}) => (
  <StyledValue>
    {value === undefined || value === null || value === '' ? '—' : value}
  </StyledValue>
)
