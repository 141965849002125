// @flow
import { replace } from 'connected-react-router'
import { connect } from 'react-redux'

import { createNew } from './actions'
import CreateAccountModule from './CreateAccount'

const mapActionCreators = (dispatch) => ({
  cancel: () => dispatch(replace('/accounts')),
  postNewAccount: (data) => dispatch(createNew(data)),
})

export default connect(null, mapActionCreators)(CreateAccountModule)
