export function ElderServiceError(
  message,
  status,
  errorCode,
  details,
  response,
) {
  this.isElderError = true
  this.message = message
  this.status = status
  this.errorCode = errorCode
  this.details = details
  this.response = response
}

ElderServiceError.prototype = Error

export function NativeServiceError(message, status, response) {
  this.isNativeServiceError = true
  this.message = message
  this.status = status
  this.response = response
}

NativeServiceError.prototype = Error

export const describeServiceError = (error, title = null) => {
  const message =
    error.errorCode === 'CONFLICT'
      ? 'The solution has changed since the preview was created - please go back to the previous step and try again.'
      : error.message

  if (error.isElderError) {
    return {
      title: title || `Service returned error ${error.errorCode}`,
      message,
      details: error.details,
    }
  }
  if (error.isNativeServiceError) {
    return {
      title: title || 'Unhandled error in service call',
      message,
      details: error.response,
    }
  }
  return {
    title: title || 'Internal error',
    message,
    details: error,
  }
}
