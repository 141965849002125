// @flow
import type { Saga } from 'redux-saga'
import { call, select, takeLatest } from 'redux-saga/effects'

import { GET_CHANGELOG } from 'modules/changelog'
import { pageSelectors } from 'routes/profile/profileWrapper/selectors'
import { addNamespace } from 'utils'
import { getAndSetFlow } from 'utils/services'

import { NAMESPACE, changelogActions } from './actions'

function* fetchProfileChangelogFlow(): Generator<*, void, *> {
  const profileId = yield select(pageSelectors.getId)
  yield call(
    getAndSetFlow,
    `/et/profiles/${profileId}/changelog`,
    changelogActions.setChangelog,
  )
}

export function* watchProfileChangelog(): Saga<*> {
  yield takeLatest(
    addNamespace(NAMESPACE, GET_CHANGELOG),
    fetchProfileChangelogFlow,
  )
}

export default watchProfileChangelog
