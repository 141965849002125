// @flow
import { connect } from 'react-redux'

import {
  downloadPaymentsFile,
  getPaymentBatches as getPaymentBatchesAction,
} from './actions'
import { OutboundPaymentsTable } from './OutboundPaymentsTable'
import { getPaymentBatches, getIsLoading } from './selectors'

const mapActionCreators = {
  downloadPaymentsFile,
  getPaymentBatchesAction,
}

const mapStateToProps = (state) => ({
  paymentBatches: getPaymentBatches(state),
  isLoading: getIsLoading(state),
})

export default connect(
  mapStateToProps,
  mapActionCreators,
)(OutboundPaymentsTable)
