import type React from 'react'
import { useState } from 'react'

import { MatchingIcon } from '@elder/common'
import { useEtGetCarerIdentityUsingGET } from '@elder/et-facade-et'
import { CircleOutlined } from '@mui/icons-material'
import { Avatar, Box, Checkbox, Skeleton, Stack, alpha } from '@mui/material'
import moment from 'moment'
import styled from 'styled-components'

import type { TimelineBlock } from 'components/solutions/SolutionTimeline/SolutionTimeline'

import { getVisitingPlacementTimingText, type VisitingData } from './types'

export const colors = {
  selectedBorder: '#598DFD',
  unselectedBorder: '#0310291F',
}

export const BaseRectBlock = styled.div`
  position: absolute;
  box-sizing: border-box;
  #padding: 4px 8px;
  margin-left: 0.3%;
  height: ${({ height }) => height}px;
  left: ${({ from, daysDisplayed }) => (100 * from) / daysDisplayed}%;
  width: ${({ from, to, daysDisplayed }) =>
    (100 * (to - from)) / daysDisplayed - 0.55}%;
  border-radius: 4px;
  transition: all 0.1s ease;
  display: flex;
  flex-direction: column;
  > * {
    flex: 1;
  }
`

const RectBlock = styled(BaseRectBlock)`
  color: ${({ color }) => color};
  background-color: ${({ bgcolor }) => bgcolor};
  border: 2px solid
    ${({ isSelected }) =>
      isSelected ? colors.selectedBorder : colors.unselectedBorder};
  border-right-color: ${({ block }) =>
    block.isInfinite ? 'transparent' : undefined};
  box-shadow: ${({ isSelected }) =>
    isSelected ? '2px 2px  3px #00000066' : 'none'};
`

export interface Props {
  block: TimelineBlock<VisitingData>
  height: number
  isSelected?: boolean
  onClick?: (
    event: React.MouseEvent<HTMLDivElement, MouseEvent>,
    block: TimelineBlock<VisitingData>,
  ) => void
  daysDisplayed: number
  onCheckChange?: (checked: boolean) => void
  checked?: boolean
}

export const VisitingBlock = ({
  block,
  height,
  isSelected,
  onClick,
  daysDisplayed,
  onCheckChange,
  checked,
}: Props) => {
  const placementColors = block.colors

  const [hovered, setHovered] = useState(false)

  return (
    <Box
      sx={{
        overflow: 'visible',
        cursor: 'pointer',
      }}
      onClick={(e) => onClick?.(e, block)}
      onMouseEnter={() => setHovered(true)}
      onMouseLeave={() => setHovered(false)}
    >
      <RectBlock
        block={block}
        isSelected={isSelected}
        height={height}
        daysDisplayed={daysDisplayed}
        bgcolor={placementColors.default}
        color={placementColors.text}
        from={block.from}
        to={block.to}
      >
        {block.data && (
          <Stack
            direction="row"
            alignItems="center"
            lineHeight={1}
            mt="-2px"
            position="relative"
            useFlexGap
          >
            {onCheckChange && (hovered || checked) ? (
              <Checkbox
                checked={checked}
                onChange={(e) => onCheckChange?.(e.target.checked)}
                onClick={(e) => e.stopPropagation()}
                size="medium"
                sx={{ p: 0, svg: { fontSize: '1.25rem' }, width: 26, ml: 1 }}
              />
            ) : (
              <Visual data={block.data} />
            )}
            <Status data={block.data} />
            <Timing data={block.data} />
          </Stack>
        )}
        {block.data.placement.type === 'single' && (
          <Box
            position="absolute"
            left={`${((moment(block.data.placement.details.date).isoWeekday() - 1) / 7) * 100}%`}
            width={`${100 / 7}%`}
            height="100%"
            bgcolor="#0000000F"
            top={0}
            bottom={0}
          >
            <Box
              sx={{
                fontSize: 8,
                position: 'absolute',
                right: 0.4,
                bottom: 0.4,
                color: alpha(placementColors.text, 0.6),
              }}
            >
              {moment(block.data.placement.details.date).format('ddd')}
            </Box>
          </Box>
        )}
      </RectBlock>
    </Box>
  )
}

const Visual = ({ data }: { data: VisitingData }) => {
  const { isMatching } = data
  const { carerId } = data.placement

  const { carer, isLoading } = useCarer(data.placement.id, carerId)

  if (isMatching) {
    if (isLoading) {
      return <Skeleton variant="circular" width={28} height={28} />
    } else if (carer) {
      const name = `${carer.firstName} ${carer.lastName}`
      return (
        <Box>
          <Avatar
            src={carer.profilePicture}
            sx={{ width: 28, height: 28, fontSize: 12 }}
            alt={name}
          >
            {name
              .split(/\s+/)
              .map((part) => part[0])
              .join('')}
          </Avatar>
        </Box>
      )
    } else {
      return (
        <Box ml={1}>
          <MatchingIcon sx={{ fontSize: 26, mt: '2px' }} />
        </Box>
      )
    }
  } else {
    return (
      <Box ml={1}>
        <CircleOutlined sx={{ fontSize: 26, mt: '2px' }} />
      </Box>
    )
  }
}

const getStatusText = (
  isMatching: boolean,
  assignee: boolean,
  assigneeName?: string,
) => {
  if (assignee) {
    return assigneeName || 'Assigned'
  } else if (isMatching) {
    return 'Matching in progress'
  } else {
    return 'Matching not started'
  }
}

const Status = ({ data }: { data: VisitingData }) => {
  const { isMatching } = data
  const assignee = data.placement.carerId
  const assigneeName = data.placement.carerName

  const text = getStatusText(isMatching, Boolean(assignee), assigneeName)

  return (
    <Box flex={1} overflow="hidden" whiteSpace="nowrap">
      {text}
    </Box>
  )
}

const Timing = ({ data }: { data: VisitingData }) => {
  const { placement } = data

  const text = getVisitingPlacementTimingText(placement)

  return (
    <Box overflow="hidden" whiteSpace="nowrap">
      {text}
    </Box>
  )
}

const useCarer = (placementId: string, carerId?: string) => {
  const query = useEtGetCarerIdentityUsingGET(carerId!, {
    query: { enabled: Boolean(carerId) },
  })
  const carer = query.data
  const isLoading = Boolean(carerId) && query.isLoading

  return { carer, isLoading }
}
