// @flow
import { makeAction } from 'elder/actions'

import type {
  Restriction,
  AppliedPlacement,
} from 'features/carerMatching/domain'
import { makeActions as makeActionsButtonSpinner } from 'modules/buttonSpinner'
import { addNamespace } from 'utils'

export const NAMESPACE = 'CARER_MATCHING'
export const PROFESSIONAL_SUMMARY = 'PROFESSIONAL_SUMMARY'
export const RESTRICTION = 'RESTRICTION'

export const restrictionButtonSpinnerActions = makeActionsButtonSpinner(
  NAMESPACE,
  RESTRICTION,
)

export const ADD_RESTRICTION = addNamespace(NAMESPACE, 'ADD_RESTRICTION')

export const EDIT_RESTRICTION = addNamespace(NAMESPACE, 'EDIT_RESTRICTION')

export const DELETE_RESTRICTION = addNamespace(NAMESPACE, 'DELETE_RESTRICTION')

export const GET_RESTRICTIONS = addNamespace(NAMESPACE, 'GET_RESTRICTIONS')

export const CANCEL_APPLIED_PLACEMENT = addNamespace(
  NAMESPACE,
  'CANCEL_APPLIED_PLACEMENT',
)

export const GET_APPLIED_PLACEMENTS = addNamespace(
  NAMESPACE,
  'GET_APPLIED_PLACEMENTS',
)

export const CANCEL_APPLIED_PLACEMENT_COMPLETE = addNamespace(
  NAMESPACE,
  'CANCEL_APPLIED_PLACEMENT_COMPLETE',
)

export const SET_APPLIED_PLACEMENTS = addNamespace(
  NAMESPACE,
  'SET_APPLIED_PLACEMENTS',
)

export const CLEAR_LOADING = addNamespace(NAMESPACE, 'CLEAR_LOADING')

export const getRestrictions = makeAction(
  GET_RESTRICTIONS,
  (carerId: string) => ({ carerId }),
)

export const addRestriction = makeAction(
  ADD_RESTRICTION,
  (restriction: Restriction, carerId: string) => ({ restriction, carerId }),
)

export const editRestriction = makeAction(
  EDIT_RESTRICTION,
  (restriction: Restriction, carerId: string) => ({ restriction, carerId }),
)

export const deleteRestriction = makeAction(
  DELETE_RESTRICTION,
  (restrictionId: string, carerId: string) => ({ restrictionId, carerId }),
)

export const cancelAppliedPlacement = makeAction(
  CANCEL_APPLIED_PLACEMENT,
  (matchRequestId: string, carerId: string) => ({ matchRequestId, carerId }),
)

export const getAppliedPlacements = makeAction(
  GET_APPLIED_PLACEMENTS,
  (carerId: string) => ({ carerId }),
)

export const cancelAppliedPlacementComplete = makeAction(
  CANCEL_APPLIED_PLACEMENT_COMPLETE,
  (matchRequestId: string) => ({ matchRequestId }),
)

export const setAppliedPlacements = makeAction(
  SET_APPLIED_PLACEMENTS,
  ({
    appliedPlacements,
  }: {|
    +appliedPlacements: Array<AppliedPlacement>,
  |}) => ({ appliedPlacements }),
)

export const clearLoading = makeAction(CLEAR_LOADING)
