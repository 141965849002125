// @flow
/* eslint-disable flowtype/no-weak-types */
import { createSelector } from 'reselect'

import { getPrettyName } from 'utils/accountDecorators'

import { NAMESPACE } from './actions'

export const getProfessionalState = (state: Object) => state.get(NAMESPACE)

export const getId = createSelector(getProfessionalState, (professionalState) =>
  professionalState.get('id'),
)
export const getIdentity = createSelector(
  getProfessionalState,
  (professionalState) => professionalState.get('identity'),
)
export const getIdentityToJS = createSelector(getIdentity, (state) =>
  state.toJS(),
)

export const getProfessionalIdentity = createSelector(
  [getIdentityToJS],
  (identity) => ({
    professionalId: identity.professionalId,
    fullName: getPrettyName(identity),
    firstName: identity.firstName,
    // MAGIC: '=s160' takes an image a circle of size 160px.
    profilePicture: identity.profilePicture
      ? `${identity.profilePicture}=s160`
      : null,
    starred: identity.starred,
  }),
)

export const getHasProfilePicture = createSelector(
  [getIdentityToJS],
  (identity) => !!identity.profilePicture,
)

export const getImpersonationLink = (state: Object) =>
  getProfessionalState(state).get('impersonationLink')
