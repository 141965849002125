// @flow

import type { Saga } from 'redux-saga'
import { takeLatest, put } from 'redux-saga/effects'

import {
  GET_CUSTOMER_CARELOGS,
  getCustomerCarelogsCompleted,
  getCustomerCarelogsFailed,
} from 'features/carelogs/store/actions'
import { applicationError } from 'features/snackbar/snackbar'
import { getFlow, describeServiceError } from 'utils/services'

const serviceError = (error, message) =>
  applicationError(describeServiceError(error, message))

function* getCustomerCarelogs({ customerId }): Saga<*> {
  try {
    const endpoint = `/et/accounts/${customerId}/carelog/`
    const response = yield getFlow(endpoint)
    yield put(
      getCustomerCarelogsCompleted({
        customerId,
        response,
      }),
    )
  } catch (error) {
    yield put(getCustomerCarelogsFailed({ customerId }))
    serviceError(error, 'Unable to get care logs for customer')
  }
}

export function* carelogsSaga(): Saga<*> {
  yield takeLatest(GET_CUSTOMER_CARELOGS, getCustomerCarelogs)
}
