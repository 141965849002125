// @flow
import type { Saga } from 'redux-saga'
import { takeLatest, call, put, select, all } from 'redux-saga/effects'

import { getId } from 'features/carerDetailsHeader/store/selectors'
import { postFileToGCS } from 'features/carerDocuments/saga'
import {
  REJECT_REFERENCE,
  GET_WORK_EXPERIENCES,
  SAVE_DECISION,
  ACCEPT_REFERENCE,
  ARCHIVE_REFERENCE,
  UNARCHIVE_REFERENCE,
  setWorkExperiences,
  clearLoading,
  setActiveSection,
  SET_ARCHIVED,
  REQUEST_WORK_REFERENCE,
  REQUEST_CHARACTER_REFERENCE,
  UPLOAD_REFERENCE,
  OPEN_FILES,
} from 'features/carerSkills/store/workExperience/actions'
import { makeGetReferenceIdsForWorkExperience } from 'features/carerSkills/store/workExperience/selectors'
import {
  applicationError,
  applicationSuccess,
} from 'features/snackbar/snackbar'
import {
  getFlow,
  postFlow,
  describeServiceError,
  putFlow,
} from 'utils/services'

const serviceError = (error, message) =>
  applicationError(describeServiceError(error, message))

function* getWorkExperiences({
  carerId,
}: {
  +carerId: string,
}): Generator<Object, void, Object> {
  const endpoint = `/et/carer/${carerId}/evidence/workexperience`
  try {
    const workExperiencesResponse = yield call(getFlow, endpoint)
    yield put(setWorkExperiences({ workExperiencesResponse, carerId }))
  } catch (error) {
    serviceError(error, `Error with service call ${endpoint}`)
  } finally {
    yield put(clearLoading())
  }
}

function* rejectReference({
  id,
  body,
}: {
  +id: string,
  +body: Object,
}): Generator<*, void, *> {
  const carerId = yield select(getId)
  const endpoint = `/et/carer/${carerId}/evidence/referenceresponse/${id}/reject`
  try {
    yield call(putFlow, endpoint, body, 'none')
    yield call(getWorkExperiences, { carerId })
    // TODO: according to ActiveSection type in
    // features/carerSkills/store/workExperience/reducer
    // should be an object: { name: 'NONE' }
    // $FlowOptOut
    yield put(setActiveSection('NONE'))
  } catch (error) {
    serviceError(error, `Error with service call ${endpoint}`)
  } finally {
    yield put(clearLoading())
  }
}

function* acceptReference({
  id,
  body,
}: {
  +id: string,
  +body: Object,
}): Generator<*, void, *> {
  const carerId = yield select(getId)
  const endpoint = `/et/carer/${carerId}/evidence/referenceresponse/${id}/accept`
  try {
    yield call(putFlow, endpoint, body, 'none')
    yield call(getWorkExperiences, { carerId })
    // TODO: according to ActiveSection type in
    // features/carerSkills/store/workExperience/reducer
    // should be an object: { name: 'NONE' }
    // $FlowOptOut
    yield put(setActiveSection('NONE'))
  } catch (error) {
    serviceError(error, `Error with service call ${endpoint}`)
  } finally {
    yield put(clearLoading())
  }
}

function* archiveReference({ id }: { +id: string }): Generator<*, void, *> {
  const carerId = yield select(getId)
  const endpoint = `/et/carer/${carerId}/evidence/referenceresponse/${id}/archive`
  try {
    yield call(putFlow, endpoint, null, 'none')
    yield call(getWorkExperiences, { carerId })
  } catch (error) {
    serviceError(error, `Error with service call ${endpoint}`)
  } finally {
    yield put(clearLoading())
  }
}

function* unarchiveReference({ id }: { +id: string }): Generator<*, void, *> {
  const carerId = yield select(getId)
  const endpoint = `/et/carer/${carerId}/evidence/referenceresponse/${id}/unarchive`
  try {
    yield call(putFlow, endpoint, null, 'none')
    yield call(getWorkExperiences, { carerId })
  } catch (error) {
    serviceError(error, `Error with service call ${endpoint}`)
  } finally {
    yield put(clearLoading())
  }
}

function* makeDecision({
  elderDecision,
  id,
}: {
  +elderDecision: Object,
  +id: string,
}): Generator<*, void, *> {
  const carerId = yield select(getId)
  const basedOnReferenceResponses = yield select(
    makeGetReferenceIdsForWorkExperience({ workExperienceId: id, carerId }),
  )
  const endpoint = `/et/carer/${carerId}/evidence/workexperience/${id}/elderdecision`
  try {
    yield call(
      putFlow,
      endpoint,
      { decision: elderDecision, basedOnReferenceResponses },
      'none',
    )
    yield call(getWorkExperiences, { carerId })
    applicationSuccess({
      title: 'Decision saved',
      message: 'Your decision has been saved.',
    })
  } catch (error) {
    serviceError(error, `Error with service call ${endpoint}`)
  } finally {
    yield put(clearLoading())
  }
}

function* requestWorkReference({
  refereeDetails,
  workExperienceId,
}: {
  +refereeDetails: Object,
  +workExperienceId: string,
}): Generator<*, void, *> {
  const carerId = yield select(getId)
  const endpoint = `/et/carer/${carerId}/evidence/workexperience/${workExperienceId}/reference/emailReferee`
  try {
    yield call(postFlow, endpoint, { referee: refereeDetails }, 'none')
    yield call(getWorkExperiences, { carerId })
    // TODO: according to ActiveSection type in
    // features/carerSkills/store/workExperience/reducer
    // should be an object: { name: 'NONE' }
    // $FlowOptOut
    yield put(setActiveSection('NONE'))
  } catch (error) {
    serviceError(error, `Error with service call ${endpoint}`)
  } finally {
    yield put(clearLoading())
  }
}

function* requestCharacterReference({
  body,
}: {
  +body: Object,
}): Generator<*, void, *> {
  const carerId = yield select(getId)
  const endpoint = `/et/carer/${carerId}/evidence/characterReference/emailReferee`
  const payload = { referee: body }
  try {
    yield call(postFlow, endpoint, payload, 'none')
    yield call(getWorkExperiences, { carerId })
    // TODO: according to ActiveSection type in
    // features/carerSkills/store/workExperience/reducer
    // should be an object: { name: 'NONE' }
    // $FlowOptOut
    yield put(setActiveSection('NONE'))
  } catch (error) {
    serviceError(error, `Error with service call ${endpoint}`)
  } finally {
    yield put(clearLoading())
  }
}

function* setArchivedWorkExperience({
  archived,
  workExperienceId,
  carerId,
}: {
  +archived: string,
  +workExperienceId: string,
  +carerId: string,
}): Generator<Object, void, Object> {
  const archivedStatus = archived ? 'archived' : 'unarchived'
  const archiveOrUnarchive = archived ? 'archive' : 'unarchive'
  const endpoint = `/et/carer/${carerId}/evidence/workexperience/${workExperienceId}/${archiveOrUnarchive}`
  try {
    const workExperiencesResponse = yield call(putFlow, endpoint)
    yield put(
      setWorkExperiences({
        workExperiencesResponse,
        carerId,
      }),
    )
    applicationSuccess({
      title: 'Success',
      message: `Experience was ${archivedStatus}`,
    })
  } catch (error) {
    serviceError(error, `Unable to ${archivedStatus} note`)
  }
}

function* uploadReference({ body }: { +body: Object }): Generator<*, void, *> {
  const { files: filesToUpload, fields, elderComment } = body
  const carerId = yield select(getId)
  try {
    const { documentId, files } = yield call(
      postFlow,
      `/et/carer/${carerId}/evidence/reference/manual`,
      {
        files: filesToUpload.map((file) => ({
          contentType: file.type,
        })),
      },
    )
    yield all(
      filesToUpload.map((file, index) =>
        call(postFileToGCS, {
          file,
          url: files[index].uploadUrl,
        }),
      ),
    )
    const { referenceType, ...restOfFields } = fields
    const completePayload = {
      referenceResponse: {
        documentId,
        type: referenceType,
        ...restOfFields,
      },
    }
    if (body.workExperienceId) {
      // $FlowOptOut
      completePayload.workExperienceId = body.workExperienceId
    }
    const { responseId } = yield call(
      postFlow,
      `/et/carer/${carerId}/evidence/reference/manual/complete`,
      completePayload,
    )
    const acceptPayload = { id: responseId }
    if (elderComment) {
      // $FlowOptOut
      acceptPayload.body = { elderComment }
    }
    // $FlowOptOut
    yield call(acceptReference, acceptPayload)
    yield call(getWorkExperiences, { carerId })
  } catch (error) {
    applicationError(
      describeServiceError(error, 'Failed to upload the document'),
    )
  }
}

function* openDocumentFiles({
  id: documentId,
}): Generator<Object, void, Object> {
  try {
    const { files } = yield call(getFlow, `/et/claims/document/${documentId}`)

    yield all(files.map(({ downloadUrl }) => call(window.open, downloadUrl)))
  } catch (error) {
    applicationError(describeServiceError(error, 'Failed to open files'))
  }
}

export function* workExperiencesSaga(): Saga<*> {
  yield takeLatest(GET_WORK_EXPERIENCES, getWorkExperiences)
  yield takeLatest(ACCEPT_REFERENCE, acceptReference)
  yield takeLatest(ARCHIVE_REFERENCE, archiveReference)
  yield takeLatest(UNARCHIVE_REFERENCE, unarchiveReference)
  yield takeLatest(REJECT_REFERENCE, rejectReference)
  yield takeLatest(SAVE_DECISION, makeDecision)
  yield takeLatest(REQUEST_WORK_REFERENCE, requestWorkReference)
  yield takeLatest(REQUEST_CHARACTER_REFERENCE, requestCharacterReference)
  yield takeLatest(SET_ARCHIVED, setArchivedWorkExperience)
  yield takeLatest(UPLOAD_REFERENCE, uploadReference)
  yield takeLatest(OPEN_FILES, openDocumentFiles)
}
