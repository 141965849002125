// @flow
import type { Node } from 'react'
import React from 'react'

import { Header } from '@elder/common'
import { chain } from 'lodash'
import { useSelector } from 'react-redux'
import { Match, useLocation } from 'react-router-dom'
import styled from 'styled-components'

import { ContentPage } from 'components'
import { BreadcrumbsBar } from 'features/header/BreadcrumbsBar'
import { useLogVisitToRecents } from 'features/recents/recents'
import * as routes from 'routes'
import { getJsAccountSummary } from 'routes/account/summary/selectors'
import { formatLink } from 'utils/links'
import { useSetWindowTitle } from 'utils/useSetWindowTitle'

import { AccountIdEvents } from './AccountIdEvents'

const StyledHeader = styled(Header)`
  font-weight: 500;
`
type Props = {|
  +children: Node,
  +getIdentity: () => void,
  +setId: () => void,
  +match: Match,
|}

export const Account = ({
  children,
  getIdentity,
  setId,
  match: {
    params: { accountId },
  },
}: Props) => {
  const isBillingAccount = accountId[0] === 'b' || accountId[0] === 'B'
  const location = useLocation()

  useLogVisitToRecents('account', accountId)

  const primaryContactName = usePrimaryContactName()
  useSetWindowTitle(`${primaryContactName || accountId} - ET3`)

  return (
    <>
      <BreadcrumbsBar
        breadcrumbs={[
          { title: 'Accounts', to: routes.accounts },
          {
            title: <code>{accountId}</code>,
            to: formatLink(routes.account, {
              accountId,
            }),
          },
          ...(location.pathname.endsWith('/solutions')
            ? [
                {
                  title: 'Solutions',
                  to: formatLink(routes.solutions, { accountId }),
                },
              ]
            : []),
        ]}
      />
      <ContentPage>
        <StyledHeader
          level="h2"
          label={
            isBillingAccount
              ? `Billing Account #${accountId}`
              : `Customer Account #${accountId}`
          }
        />
        {!isBillingAccount && (
          <AccountIdEvents
            getIdentity={getIdentity}
            setId={setId}
            accountId={accountId}
          />
        )}
        {children}
      </ContentPage>
    </>
  )
}

const usePrimaryContactName = () => {
  const accountSummary = useSelector(getJsAccountSummary)
  if (!accountSummary) return null
  const { contacts } = accountSummary
  const sortedContacts = chain(contacts)
    .sortBy((c) => c.roles.includes('SECONDARY_POINT_OF_CONTACT'))
    .sortBy((c) => c.roles.includes('PRIMARY_POINT_OF_CONTACT'))
    .reverse()
    .value()
  const firstContact = sortedContacts[0]
  return firstContact?.name
}
