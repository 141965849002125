// @flow
export const INIT = '~ INIT ~'
export const LOGIN = '~ LOGIN ~'
export const APPLICATION_READY = '~ APPLICATION READY ~'
export const RESTART = '~ RESTART ~'

export const init = () => ({
  type: INIT,
})

export const login = () => ({
  type: LOGIN,
})

export const applicationReady = () => ({
  type: APPLICATION_READY,
})

export const restart = () => ({
  type: RESTART,
})
