const storageAvailability = (type) => {
  try {
    const storage = window[type]
    const x = '__storage_test__'

    storage.setItem(x, x)
    storage.removeItem(x)

    return true
  } catch (e) {
    return false
  }
}

const isLocalStorageAvailable = () => storageAvailability('localStorage')

export const getLocalItem = (id) => {
  if (isLocalStorageAvailable()) {
    return localStorage.getItem(id)
  }

  return null
}

export const setLocalItem = (id, value) => {
  if (isLocalStorageAvailable()) {
    localStorage.setItem(id, value)
  }
}

export const removeLocalItem = (id) => {
  if (isLocalStorageAvailable()) {
    localStorage.removeItem(id)
  }
}
