import React from 'react'

import type { AccountOverview } from '@elder/et-facade-et/lib/models/accountOverview'
import { Stack, Typography } from '@mui/material'

import { AccountEntry } from 'components/styledComponentsTranslatedClasses/AccountEntry'

interface Props {
  account: AccountOverview
}

const formatCareRequestType = (careRequestType: string = 'LIVE_IN') => {
  const careRequestTypes: { [key: string]: string } = {
    LIVE_IN: 'Live in',
    VISITING: 'Visiting',
  }
  return careRequestTypes[careRequestType] || careRequestType
}

export const CareRequestType = ({ account }: Props) => {
  return (
    <AccountEntry>
      <span>Care request type</span>
      <Stack direction="row" justifyContent="space-between" alignItems="center">
        <Typography variant="body1">
          {formatCareRequestType(account.careRequestType)}
        </Typography>
      </Stack>
    </AccountEntry>
  )
}
