// @flow
import {
  SET,
  EDIT_SECTION,
  EDIT_COMPLETED,
  CANCEL_EDIT,
} from 'modules/pageEdit/actions'
import { addNamespace } from 'utils'

export const makeValueReducer = (namespace: string) => {
  const initialState = ''
  const set = addNamespace(namespace, SET)
  const editSection = addNamespace(namespace, EDIT_SECTION)
  const editCompleted = addNamespace(namespace, EDIT_COMPLETED)
  const cancelEdit = addNamespace(namespace, CANCEL_EDIT)

  return (state: string = initialState, action: Object) => {
    switch (action.type) {
      case set:
      case cancelEdit:
      case editCompleted:
        return initialState
      case editSection:
        return action.section
      default:
        return state
    }
  }
}

export default makeValueReducer
