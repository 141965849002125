// @flow

import React from 'react'

import { Form, RadioGroup } from 'formsy-react-components'
import styled from 'styled-components'

import { ButtonSubmit, GenericButton } from 'components'
import { Section } from 'components/styledComponentsTranslatedClasses/Section'
import { SectionHeader } from 'components/styledComponentsTranslatedClasses/SectionHeader'
import { booleanOptions, setValueBoolean } from 'utils/groupOptions'

const Wrapper = styled.div`
  display: flex;
  justify-content: flex-end;
  margin-top: 20px;

  > :not(:last-child) {
    margin-right: 15px;
  }
`

const RadioWrapper = styled.div`
  div {
    display: flex;
    div {
      flex-grow: 1;
    }
  }
  label {
    text-align: right;
    margin-left: 15px;
    display: block;
  }
`
type Props = {|
  +canAccessPortalWithReadOnlyPermissions: boolean,
  +updateCanAccessPortalWithReadOnlyPermissions: (readOnly: boolean) => void,
  +cancelEdit: () => void,
  +isSaving: boolean,
|}

const PortalReadOnlyAccessForm = ({
  canAccessPortalWithReadOnlyPermissions,
  updateCanAccessPortalWithReadOnlyPermissions,
  cancelEdit,
  isSaving,
}: Props) => (
  <Section>
    <Form
      onSubmit={(data) => {
        updateCanAccessPortalWithReadOnlyPermissions(
          data.canAccessPortalWithReadOnlyPermissions,
        )
      }}
    >
      <SectionHeader>
        <h3>My Elder Access When Archived</h3>
      </SectionHeader>
      <RadioWrapper>
        <RadioGroup
          layout="vertical"
          label="Retain read-only My Elder access after archive?"
          name="canAccessPortalWithReadOnlyPermissions"
          type="inline"
          value={setValueBoolean(canAccessPortalWithReadOnlyPermissions)}
          options={booleanOptions}
        />
      </RadioWrapper>
      <Wrapper>
        <GenericButton
          text="Cancel"
          onClick={cancelEdit}
          disabled={isSaving}
          quiet
        />
        <ButtonSubmit text="Save" isLoading={isSaving} />
      </Wrapper>
    </Form>
  </Section>
)

export default PortalReadOnlyAccessForm
