// @flow
import React from 'react'

import { LoadingButton } from '@mui/lab'

type Props = {|
  +text: string,
  +disabled?: boolean,
  +isLoading?: boolean,
|}

const ButtonSubmit = ({ text, disabled, isLoading }: Props) => (
  <LoadingButton type="submit" loading={isLoading} disabled={disabled}>
    {text}
  </LoadingButton>
)

export default ButtonSubmit
