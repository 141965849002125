// @flow

import * as Yup from 'yup'

import type { Template } from 'features/notes/domain'
import { createAdapter } from 'features/notes/helpers'
import { sections as permSections } from 'features/notes/templates/initialTransfer'

const sections = [
  {
    title: 'Overview',
    fields: [
      {
        type: 'TEXT_AREA',
        name: 'situation',
        label:
          'What’s the customer’s situation and why are they looking at temporary live in care?' +
          'What is the length of time, and why? (Rehabilitation, respite, holiday cover?)',
        validation: Yup.string().required('Required'),
      },
      {
        type: 'TEXT_AREA',
        name: 'permPotential',
        label:
          'What potential does this account have to turn perm? Do you have any tips for the AE’s check in calls?',
        validation: Yup.string().required('Required'),
      },
      {
        type: 'TEXT_AREA',
        name: 'carePostElder',
        label:
          'Have you discussed what care/support is in place the day our carer leaves the customer?',
        validation: Yup.string().required('Required'),
      },
    ],
  },
  ...permSections,
]

const title = 'Initial Account Transfer - Flexi'

export const tempInitialTransfer: Template = {
  value: 'TEMP_INITIAL_TRANSFER',
  label: title,
  adapter: ({ tags, ...textValues }) => ({
    title,
    tags,
    text: createAdapter(sections, textValues),
  }),
  sections,
}
