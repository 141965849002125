// @flow
import type { Saga } from 'redux-saga'
import { call, put, select, takeLatest } from 'redux-saga/effects'

import { applicationError } from 'features/snackbar/snackbar'
import { pageSelectors } from 'routes/account/selectors'
import {
  UPDATE_ACCOUNT_MANAGER,
  UPDATE_SALES_EXECUTIVE,
  GET_ACCOUNT_MANAGERS,
  pageActions,
  setAccountManagers,
} from 'routes/account/summary/actions'
import {
  getSalesExecutive,
  getAccountManager as getAccountManagerSelector,
} from 'routes/account/summary/selectors'
import { putFlow, describeServiceError, getAndSetFlow } from 'utils/services'

function* updateRelationshipContacts(
  accountManager,
  salesExecutive,
): Generator<*, void, *> {
  const accountId = yield select(pageSelectors.getId)
  const endpoint = `/et/accounts/${accountId}/relationshipContacts`
  const data = { accountManager, salesExecutive }

  try {
    const accountSummary = yield call(putFlow, endpoint, data)
    yield put(pageActions.set(accountSummary))
  } catch (error) {
    applicationError(
      describeServiceError(error, `Error with service call ${endpoint}`),
    )
  }
}

function* updateAccountManager(action): Generator<Object, void, Object> {
  const accountManager = action.email
  const salesExecutive = yield select(getSalesExecutive)

  yield updateRelationshipContacts(accountManager, salesExecutive)
}

function* getAccountManagers(): Generator<*, void, *> {
  const endpoint = '/et/accounts/salesforceUsers/available'
  yield call(getAndSetFlow, endpoint, setAccountManagers)
}

function* updateSalesExecutive(action): Generator<Object, void, Object> {
  const salesExecutive = action.email
  const accountManager = yield select(getAccountManagerSelector)

  yield updateRelationshipContacts(accountManager, salesExecutive)
}

export default function* (): Saga<*> {
  yield takeLatest(UPDATE_SALES_EXECUTIVE, updateSalesExecutive)
  yield takeLatest(UPDATE_ACCOUNT_MANAGER, updateAccountManager)
  yield takeLatest(GET_ACCOUNT_MANAGERS, getAccountManagers)
}
