// @flow
import Immutable from 'immutable'
import { combineReducers } from 'redux-immutable'

import {
  GET_CONTACTS,
  GET_COMPLETED,
  UPDATE_COMPLETED,
  SET_STATE,
  GET_STARTING,
  UPDATE_STARTING,
  GET_FAILED,
  UPDATE_FAILED,
} from './actions'

const idReducer = () => {
  const initialState = null

  return (state = initialState, action) => {
    switch (action.type) {
      case GET_CONTACTS:
        return action.id
      default:
        return state
    }
  }
}

const dataReducer = () => {
  const initialState = null

  return (state = initialState, action) => {
    switch (action.type) {
      case GET_CONTACTS:
        return initialState
      case GET_COMPLETED:
      case UPDATE_COMPLETED:
      case SET_STATE:
        return Immutable.fromJS(action.data)
      default:
        return state
    }
  }
}

const isLoadingReducer = () => {
  const initialState = false

  return (state = initialState, action) => {
    switch (action.type) {
      case GET_STARTING:
      case UPDATE_STARTING:
        return true
      case GET_COMPLETED:
      case GET_FAILED:
      case UPDATE_COMPLETED:
      case UPDATE_FAILED:
      case SET_STATE:
        return false
      default:
        return state
    }
  }
}

const errorReducer = () => {
  const initialState = null

  return (state = initialState, action) => {
    switch (action.type) {
      case GET_STARTING:
        return null
      case GET_FAILED:
        return action.error
      default:
        return state
    }
  }
}

const reducer = combineReducers({
  id: idReducer(),
  data: dataReducer(),
  isLoading: isLoadingReducer(),
  error: errorReducer(),
})

export default reducer
