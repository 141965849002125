// @flow
import { combineReducers } from 'redux-immutable'

import editMode from './editMode'
import isLoading from './isLoading'
import value from './value'

export const makeReducer = (namespace: string) =>
  combineReducers({
    value: value(namespace),
    editMode: editMode(namespace),
    isLoading: isLoading(namespace),
  })

export default makeReducer
