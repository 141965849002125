// @flow
import * as React from 'react'

import {
  CheckOutlined,
  CloseOutlined as InvalidIcon,
} from '@mui/icons-material'
import styled from 'styled-components'

const Wrapper = styled.span`
  svg {
    width: 20px;
    margin-left: 7px;
    margin-bottom: -8px;
  }
`

export const Indicator = ({
  completed,
  text = 'Completed',
}: {|
  +completed: boolean,
  +text?: string,
|}) => (
  <Wrapper>
    {text}:
    {completed ? (
      <CheckOutlined
        sx={{
          fontSize: '24px',
        }}
      />
    ) : (
      <InvalidIcon
        sx={{
          fontSize: '24px',
        }}
      />
    )}
  </Wrapper>
)
