// @flow
import { object, Type } from 'flow-validator'

import type { FlowOfType } from 'elder/types'
import { enumFromObjectKeys } from 'elder/types'

export const EXPERIMENT_GROUPS = {
  TEST: 'TEST',
  CONTROL: 'CONTROL',
}

const experimentGroups = enumFromObjectKeys(EXPERIMENT_GROUPS)

export const testGroup: Type<*> = object({
  group: experimentGroups.optional(),
})

export type TestGroup = $Exact<FlowOfType<typeof testGroup>>

export type TestGroups = { [string]: string }
