// @flow
import { createSelector } from 'reselect'

import { NAMESPACE } from './actions'

const getProfessionalNewValidationError = (state) =>
  state.get(NAMESPACE).get('professionalNewValidationError')

export const getProfessionalNewValidationErrorJs = createSelector(
  [getProfessionalNewValidationError],
  (professionalNewValidationError) => professionalNewValidationError.toJS(),
)
