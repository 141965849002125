// @flow

import type { Saga } from 'redux-saga'

import { call, select, takeLatest, put } from 'elder/effects'

import { carerMessagingClient } from 'app/saga/serviceClients'
import { getId } from 'features/carerDetailsHeader/store/selectors'
import { setLoading, clearLoading } from 'features/loading/actions'
import {
  applicationError,
  applicationSuccess,
} from 'features/snackbar/snackbar'
import { describeServiceError } from 'utils/services'

import { NAMESPACE, sendCarerMessage } from './actions'

export function* handleSendCarerMessage({
  title,
  body,
  url,
}: {
  +title: string,
  +body: string,
  +url: ?string,
}): Saga<*> {
  const carerId = yield* select(getId)

  try {
    yield* put(setLoading(NAMESPACE))

    yield* call(carerMessagingClient.sendCarerMessage, {
      carerId,
      title,
      body,
      url,
    })

    applicationSuccess({
      title: 'Message sent',
      message: 'Message successfully triggered',
    })
  } catch (error) {
    applicationError(
      describeServiceError(error, 'Failed to send message to carer'),
    )
  } finally {
    yield* put(clearLoading(NAMESPACE))
  }
}

export function* carerMessagingSaga(): Saga<*> {
  yield* takeLatest(sendCarerMessage, handleSendCarerMessage)
}
