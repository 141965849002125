// @flow
import type { Saga } from 'redux-saga'
import { take, call, select, put } from 'redux-saga/effects'

import { clearLoading, setLoading } from 'features/loading/actions'
import {
  applicationError,
  applicationSuccess,
} from 'features/snackbar/snackbar'
import { pageSelectors } from 'routes/account/selectors'
import { pageActions, UPDATE_FUNDING } from 'routes/account/summary/actions'
import { describeServiceError, fetchFlow } from 'utils/services'

export function* putFunding(data: Object): Generator<*, void, *> {
  const acctId = yield select(pageSelectors.getId)
  const endpoint = `/et/accounts/${acctId}/funding`
  const method = 'PUT'
  yield put(setLoading(UPDATE_FUNDING))
  try {
    const responseBody = yield call(fetchFlow, endpoint, method, data)
    yield put(pageActions.set(responseBody))
    applicationSuccess({
      title: 'Account updated',
      message: 'Funding were updated successfully.',
    })
  } catch (error) {
    applicationError(
      describeServiceError(error, `Error with service call ${endpoint}`),
    )
  } finally {
    yield put(clearLoading(UPDATE_FUNDING))
  }
}

export function* watchFunding(): Saga<*> {
  while (true) {
    const payload = yield take(UPDATE_FUNDING)
    yield call(putFunding, payload.data)
  }
}
