// @flow
import React from 'react'

import { EditOutlined } from '@mui/icons-material'
import { IconButton } from '@mui/material'
import styled from 'styled-components'

import { AccountEntry } from 'components/styledComponentsTranslatedClasses/AccountEntry'

const AccountEntrySmallEdit = styled.div`
  align-items: center;
  display: flex;
  justify-content: space-between;

  & > div {
    margin-right: 10px;

    &:last-child {
      margin-right: 0;
    }
  }
`

type Props = {|
  +isAutomatedCommunicationsEnabled: boolean,
  +canEditAutomatedComms: boolean,
  +editSection: (string) => void,
|}

export const AutomatedCommunicationsEnabled = ({
  isAutomatedCommunicationsEnabled,
  canEditAutomatedComms,
  editSection,
}: Props) => (
  <AccountEntry>
    <span>Automated Communications Enabled:</span>
    <AccountEntrySmallEdit>
      {isAutomatedCommunicationsEnabled ? 'Yes' : 'No'}
      {canEditAutomatedComms && (
        <IconButton
          onClick={() => editSection('automatedCommunicationsEnabled')}
          title="Edit recommended resources"
          color="primary"
        >
          <EditOutlined
            sx={{
              fontSize: '24px',
            }}
          />
        </IconButton>
      )}
    </AccountEntrySmallEdit>
  </AccountEntry>
)
