// @flow

import * as Yup from 'yup'

import type { Template } from 'features/notes/domain'
import { createTagOptions, createAdapter } from 'features/notes/helpers'

const initialField = {
  type: 'DATE',
  name: 'week',
  label: 'Which week does this plan relate to?',
  validation: Yup.string().required('Required'),
}

const weeklyPlanFields = [
  {
    type: 'TEXT_AREA',
    name: 'meals',
    label: 'What meals would the CR like the carer to cook this week?',
    validation: Yup.string().required('Required'),
  },
  {
    type: 'TEXT_AREA',
    name: 'eventsHappening',
    label:
      'Are there any events or visits happening that the carer could support with?',
    validation: Yup.string().required('Required'),
  },
  {
    type: 'TEXT_AREA',
    name: 'activities',
    label:
      'What could the carer and CR do together this week to maximise the CRs enjoyment?',
    validation: Yup.string().required('Required'),
  },
  {
    type: 'TEXT_AREA',
    name: 'concerns',
    label:
      'What are the main concerns for this week? How might these be avoided?',
    validation: Yup.string().required('Required'),
  },
  {
    type: 'TEXT_AREA',
    name: 'comments',
    label: 'Are there any other comments regarding this week?',
    validation: Yup.string(),
  },
]

const firstWeekSections = [
  {
    fields: [initialField],
  },
  {
    title: 'First week success',
    fields: [
      {
        type: 'TEXT_AREA',
        name: 'firstImpressions',
        label:
          'How might the carer make a great first impression with the care recipient?',
        info: 'Are there any tips on how the carer can best get on with the carer recipient?',
        validation: Yup.string().required('Required'),
      },
      {
        type: 'TEXT_AREA',
        name: 'extraPeopleToMeet',
        label: 'Who else will the carer meet during the first week of care?',
        info: 'Are there any tips on how the carer can best get on with these people?',
        validation: Yup.string().required('Required'),
      },
      {
        type: 'TEXT_AREA',
        name: 'pets',
        label:
          '(If pets) Are there any tips on how the carer should take care of any pets?',
        info: 'E.g letting them out at certain times or encouraging interactions with the CR',
        validation: Yup.string(),
      },
      {
        type: 'TEXT_AREA',
        name: 'crComfortableAsPossible',
        label:
          'How can the carer make the CR as comfortable as possible during personal care?',
        validation: Yup.string().required('Required'),
      },
    ],
  },
  {
    title: 'Weekly Plan',
    fields: [
      ...weeklyPlanFields,
      {
        type: 'SELECT',
        name: 'tags',
        label: 'Tags',
        multi: true,
        options: createTagOptions('Plan', 'First Week', 'COVID-19'),
        initialValue: ['Plan', 'First Week'],
      },
    ],
  },
]

const weeklySections = [
  {
    fields: [
      initialField,
      ...weeklyPlanFields,
      {
        type: 'SELECT',
        name: 'tags',
        label: 'Tags',
        multi: true,
        options: createTagOptions('Plan', 'COVID-19'),
        initialValue: ['Plan'],
      },
    ],
  },
]

const weeklyPlanTitle = 'Weekly Plan'

export const weeklyPlan: Template = {
  value: 'WEEKLY_PLAN',
  label: weeklyPlanTitle,
  adapter: ({ tags, ...textValues }) => ({
    title: weeklyPlanTitle,
    tags,
    text: createAdapter(weeklySections, textValues),
  }),
  sections: weeklySections,
}

const firstWeekPlanTitle = 'First Week Plan'

export const firstWeekPlan: Template = {
  value: 'FIRST_WEEK_PLAN',
  label: firstWeekPlanTitle,
  adapter: ({ tags, ...textValues }) => ({
    title: firstWeekPlanTitle,
    tags,
    text: createAdapter(firstWeekSections, textValues),
  }),
  sections: firstWeekSections,
}
