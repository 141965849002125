// @flow
import { endpointBuilder, noParameters } from 'elder/services'

import {
  getEvidenceRequest,
  getEvidenceResponse,
  preUploadRequest,
  preUploadResponse,
  carerAndEvidenceIdRequest,
  editEvidenceRequest,
  documentRequest,
  documentResponse,
} from 'features/carerDocuments/domain'

export const carerDocumentsService = {
  getEvidence: endpointBuilder(getEvidenceRequest, getEvidenceResponse)
    .withUrlTemplate('/et/claims/carer/{carerId}/evidence', ({ carerId }) => ({
      carerId,
    }))
    .build(),
  evidencePreUpload: endpointBuilder(preUploadRequest, preUploadResponse)
    .withHttpMethod('POST')
    .withBodyBuilder(({ data }) => data)
    .withUrlTemplate('/et/claims/carer/{carerId}/evidence', ({ carerId }) => ({
      carerId,
    }))
    .build(),
  evidenceCompleteUpload: endpointBuilder(
    carerAndEvidenceIdRequest,
    noParameters,
  )
    .withHttpMethod('PUT')
    .withUrlTemplate(
      '/et/claims/carer/{carerId}/evidence/{evidenceId}/complete',
      ({ carerId, evidenceId }) => ({
        carerId,
        evidenceId,
      }),
    )
    .withNoResponseBody()
    .build(),
  editEvidence: endpointBuilder(editEvidenceRequest, preUploadResponse)
    .withHttpMethod('PUT')
    .withUrlTemplate(
      '/et/claims/carer/{carerId}/evidence/{evidenceId}',
      ({ carerId, evidenceId }) => ({
        carerId,
        evidenceId,
      }),
    )
    .withBodyBuilder(({ metadata, addedDocuments, removedDocuments }) => ({
      metadata,
      addedDocuments,
      removedDocuments,
    }))
    .build(),
  deleteEvidence: endpointBuilder(carerAndEvidenceIdRequest, noParameters)
    .withHttpMethod('DELETE')
    .withUrlTemplate(
      '/et/claims/carer/{carerId}/evidence/{evidenceId}',
      ({ carerId, evidenceId }) => ({
        carerId,
        evidenceId,
      }),
    )
    .withNoResponseBody()
    .build(),
  getDocument: endpointBuilder(documentRequest, documentResponse)
    .withUrlTemplate('/et/claims/document/{documentId}', ({ documentId }) => ({
      documentId,
    }))
    .build(),
  verifyDocument: endpointBuilder(carerAndEvidenceIdRequest, noParameters)
    .withHttpMethod('PUT')
    .withUrlTemplate(
      '/et/claims/carer/{carerId}/evidence/{evidenceId}/signOff',
      ({ carerId, evidenceId }) => ({
        evidenceId,
        carerId,
      }),
    )
    .withNoResponseBody()
    .build(),
}
