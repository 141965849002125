import { useState } from 'react'

import type { AccountManagerTypeFacadeParamsAccountManagerType } from '@elder/et-facade-et'
import { useEtSetAccountManagerTypeUsingPUT } from '@elder/et-facade-et'
import { ToggleButtonGroup, ToggleButton, Typography } from '@mui/material'
import { useSnackbar } from 'notistack'

import { AccountEntry } from 'components/styledComponentsTranslatedClasses/AccountEntry'

export const SalesforceOwner = ({
  accountId,
  accountManagerType,
}: {
  accountId: string
  accountManagerType: string
}) => {
  const [owner, setOwner] = useState(accountManagerType)
  const { enqueueSnackbar } = useSnackbar()
  const mutation = useEtSetAccountManagerTypeUsingPUT({
    mutation: {
      onSuccess: () => {
        enqueueSnackbar('Salesforce owner updated', {
          variant: 'success',
        })
      },
      onError: () => {
        enqueueSnackbar('Error updating Salesforce owner', {
          variant: 'error',
        })
      },
    },
  })

  const handleChange = (
    event: React.MouseEvent<HTMLElement>,
    newValue: 'AccountExecutive' | 'SalesExecutive',
  ) => {
    setOwner(newValue)
    mutation.mutate({
      accountId,
      data: {
        accountManagerType:
          newValue as AccountManagerTypeFacadeParamsAccountManagerType,
      },
    })
  }

  return (
    <AccountEntry>
      <span>Salesforce Owner</span>
      <ToggleButtonGroup
        value={owner}
        exclusive
        onChange={handleChange}
        disabled={mutation.isPending}
      >
        <ToggleButton value="SalesExecutive" color="primary">
          <Typography variant="body1">SE</Typography>
        </ToggleButton>
        <ToggleButton value="AccountExecutive" color="primary">
          <Typography variant="body1">AE</Typography>
        </ToggleButton>
      </ToggleButtonGroup>
    </AccountEntry>
  )
}
