// @flow
import { endpointBuilder, noParameters } from 'elder/services'

import {
  elderUsers,
  updateUserRequest,
  updateMaxLoadRequest,
} from 'features/elderUsers/domain'

export const elderUsersService = {
  getElderUsers: endpointBuilder(noParameters, elderUsers)
    .withUrlTemplate('/et/user/search')
    .build(),
  updateStatus: endpointBuilder(updateUserRequest, noParameters)
    .withHttpMethod('PUT')
    .withUrlTemplate('/et/user/{username}/status', ({ username }) => ({
      username,
    }))
    .withBodyBuilder(({ status }) => ({ status }))
    .withNoResponseBody()
    .build(),
  updateMaxLoad: endpointBuilder(updateMaxLoadRequest, noParameters)
    .withHttpMethod('PUT')
    .withUrlTemplate('/et/user/{username}/maxWorkload', ({ username }) => ({
      username,
    }))
    .withBodyBuilder(({ maxLoad }) => ({
      primaryAccountManagerMaxLoad: maxLoad,
    }))
    .withNoResponseBody()
    .build(),
}
