// @flow
import { combineReducers } from 'redux-immutable'

import { makeReducer } from 'modules/pageEdit'
import { NAMESPACE } from 'routes/account/environment/actions'

import accountEnvironmentValidationError from './accountEnvironmentValidationError'

export default combineReducers({
  accountEnvironment: makeReducer(NAMESPACE),
  accountEnvironmentValidationError,
})
