import { useMemo } from 'react'

import type { ProfessionalAutocompleteEntry } from '@elder/et-facade-et'
import { useEtAutocompleteCarersUsingGET as useGetCarersAutocomplete } from '@elder/et-facade-et'

export const useCarerAutocomplete = (textQuery?: string) => {
  const query = useGetCarersAutocomplete(
    { textQuery },
    { query: { enabled: typeof textQuery === 'string' } },
  )

  const carers = useMemo(() => {
    if (!query.data) return null
    return query.data.items.map(transformCarerAutocomplete)
  }, [query.data])

  return { carers, query }
}

export interface AutocompleteCarer {
  value: string
  label: string
  metadata: {
    professionalName: string
  }
}

const transformCarerAutocomplete = (
  carerEntry: ProfessionalAutocompleteEntry,
) => {
  const name = carerEntry.professionalName || 'Unknown name'
  return {
    value: carerEntry.professionalId,
    label: `${name} (${carerEntry.professionalId})`,
    metadata: {
      professionalName: name,
    },
  } as AutocompleteCarer
}
