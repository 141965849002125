// @flow
import { colors } from '@elder/common'
import styled from 'styled-components'

export const SectionHeader = styled.div`
  align-items: center;
  border-bottom: 2px solid ${colors.marceline};
  display: flex;
  justify-content: space-between;
  margin: 20px 0 10px;
  padding-bottom: 10px;
  h3 {
    text-transform: none;
  }
`
