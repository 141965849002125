// @flow
import { type Saga } from 'redux-saga'
import {
  fork,
  takeLatest,
  put,
  take,
  cancel,
  call,
  select,
} from 'redux-saga/effects'

import { setActiveWorkflow } from 'features/activeWorkflow/actions'
import {
  applicationError,
  applicationSuccess,
} from 'features/snackbar/snackbar'
import { pageActions } from 'routes/account/summary/actions'
import { manageErrorWithFieldValidation } from 'utils/fieldValidationErrors'
import {
  describeServiceError,
  getFlow as rawGetFlow,
  putFlow,
} from 'utils/services'

import {
  UPDATE_COMPLETED,
  GET_CONTACTS,
  UPDATE,
  SET_STATE,
  getStartingAction,
  getCompletedAction,
  getFailedAction,
  updateStartingAction,
  updateCompletedAction,
  updateValidationFailedAction,
  updateFailedAction,
} from './actions'
import { idSelector } from './selectors'

import { params } from '.'

function* getFlow(id: string): Generator<Object, void, Object> {
  const url = params.getUrlFactory(id)
  yield put(getStartingAction())
  try {
    const result = yield call(rawGetFlow, url)
    yield put(getCompletedAction(result))
  } catch (error) {
    const formattedError = describeServiceError(
      error,
      `Error in service call ${url}`,
    )
    yield put(getFailedAction(formattedError))
    applicationError(formattedError)
  }
}

function* updateFlow(data = {}): Generator<Object, void, *> {
  const id = yield select(idSelector)
  const url = params.updateUrlFactory(id)
  yield put(updateStartingAction())

  try {
    const result = yield call(putFlow, url, { contacts: [], ...data }) // ensure contacts is always sent
    yield put(updateCompletedAction(result))
    applicationSuccess({
      title: params.updateConfirmationTitle || 'Update successful',
      message:
        params.updateConfirmationText || 'Update was completed successfully.',
    })
    yield put(setActiveWorkflow(''))
  } catch (error) {
    if (params.validationField) {
      yield call(
        manageErrorWithFieldValidation,
        error,
        params.validationField,
        params.expectedValidationFields,
        updateValidationFailedAction,
      )
    } else {
      const formattedError = describeServiceError(
        error,
        `Error in service call ${url}`,
      )
      yield put(updateFailedAction(formattedError))
      applicationError(formattedError)
    }
  }
}

function* watchFlow(): Generator<Object, void, Object> {
  let runningOperation = null
  while (true) {
    const action = yield take([GET_CONTACTS, UPDATE, SET_STATE])

    // We're either requesting a new action (get or update), or are explicitly setting the
    // current state, either way, cancel any ongoing operation
    if (runningOperation) {
      yield cancel(runningOperation)
    }

    switch (action.type) {
      case GET_CONTACTS:
        runningOperation = yield fork(getFlow, action.id)
        break
      case UPDATE:
        runningOperation = yield fork(updateFlow, action.updateParams)
        break
      default:
    }
  }
}

function* handleCloseEditOnUpdate(): Generator<Object, void, Object> {
  yield put(pageActions.editCompleted())
}

export function* contactsSaga(): Saga<*> {
  yield fork(watchFlow)
  yield takeLatest(UPDATE_COMPLETED, handleCloseEditOnUpdate)
}

export default contactsSaga
