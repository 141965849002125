// @flow
// $FlowOptOut
import React, { useEffect } from 'react'

import type { ReferenceDataOptions } from '@elder/common'
import { connect } from 'react-redux'

import { getCanEdit } from 'app'
import {
  getContactRolesOptions,
  getPrettyContactRoles,
} from 'app/referenceData'
import type { Contact } from 'domain/contacts'
import { setActiveWorkflow as setActiveWorkflowAction } from 'features/activeWorkflow/actions'
import { getActiveWorkflow } from 'features/activeWorkflow/selectors'
import type { ActiveWorkflow } from 'features/activeWorkflow/types'
import { autocompleteProfilesActions } from 'routes/account/summary/actions'
import {
  autocompleteProfilesSelectors,
  pageSelectors,
} from 'routes/account/summary/selectors'

import { getAction, updateAction } from './actions'
import ContactsSummary from './ContactsSummary'
import { getContactsSummary } from './selectors'

import { SECTION_ID } from '.'

const mapActionCreators = (dispatch) => ({
  getData: (accountId) => dispatch(getAction(accountId)),
  updateContacts: (data) => dispatch(updateAction(data)),
  updateProfileSuggestions: (queryString) =>
    dispatch(autocompleteProfilesActions.getAutocompleteProfiles(queryString)),
  setActiveWorkflow: (name, payload) =>
    dispatch(setActiveWorkflowAction(name, payload)),
})

const mapStateToProps = (state) => ({
  activeWorkflow: getActiveWorkflow(state),
  contacts: getContactsSummary(state),
  canEdit: getCanEdit(state),
  defocused: !!(
    pageSelectors.getEditMode(state) &&
    // $FlowOptOut
    !pageSelectors.getIsEditing(state, SECTION_ID)
  ),
  profileSuggestions:
    autocompleteProfilesSelectors.getJsAutocompleteProfiles(state),
  loadingProfileSuggestions: autocompleteProfilesSelectors.getIsLoading(state),
  displayContactRole: getPrettyContactRoles(state),
  contactRolesOptions: getContactRolesOptions(state),
})

export type ProfileSuggestion = {|
  +fullName?: string,
  +label: string,
  +value: string,
  +email: string,
  +phoneNumber: Array<{| +number: string, +type: string |}>,
|}

type Props = {|
  +getData: (string) => void,
  +updateContacts: ({| +contacts: Array<Contact> |}) => void,
  +updateProfileSuggestions: (string) => void,
  +contacts: Array<Contact>,
  +canEdit: boolean,
  +profileSuggestions: Array<ProfileSuggestion>,
  +loadingProfileSuggestions: boolean,
  +displayContactRole: (string) => string,
  +contactRolesOptions: ReferenceDataOptions,
  +accountId: string,
  +activeWorkflow: ActiveWorkflow,
  +setActiveWorkflow: (string, ?string) => void,
|}

const ContactsSummaryContainer = ({
  getData,
  updateContacts,
  updateProfileSuggestions,
  contacts,
  canEdit,
  profileSuggestions,
  loadingProfileSuggestions,
  displayContactRole,
  contactRolesOptions,
  accountId,
  setActiveWorkflow,
  activeWorkflow,
}: Props) => {
  useEffect(() => {
    if (accountId) {
      getData(accountId)
    }
  }, [accountId])
  return (
    <ContactsSummary
      contacts={contacts}
      displayContactRole={displayContactRole}
      contactRolesOptions={contactRolesOptions}
      profileSuggestions={profileSuggestions}
      loadingProfileSuggestions={loadingProfileSuggestions}
      canEdit={canEdit}
      updateContacts={updateContacts}
      updateProfileSuggestions={updateProfileSuggestions}
      activeWorkflow={activeWorkflow}
      setActiveWorkflow={setActiveWorkflow}
    />
  )
}

export default connect(
  mapStateToProps,
  mapActionCreators,
)(ContactsSummaryContainer)
