// @flow
import { combineReducers } from 'redux-immutable'

import { makeReducer as makeAutocompleteProfilesReducer } from 'modules/autocompleteProfiles'
import { makeReducer as makeButtonSpinnerReducer } from 'modules/buttonSpinner'
import { makeReducer as makePageEditReducer } from 'modules/pageEdit'
import {
  NAMESPACE,
  CARE_INFO_CARER,
  READ_ONLY_ACCESS,
  UPDATE_APPLICATION_QUESTION,
  CLEAR_LOADING,
} from 'routes/account/summary/actions'

import accountManagers from './accountManagers'
import accountValidationError from './accountValidationError'

export const buttonSpinnerCareInfoCarer = 'buttonSpinnerCareInfoCarer'

export const isSavingReadOnlyAccess = 'isSavingReadOnlyAccess'

const updatingApplicationQuestion = (state = false, action) => {
  switch (action.type) {
    case UPDATE_APPLICATION_QUESTION:
      return true
    case CLEAR_LOADING:
      return false
    default:
      return state
  }
}

export default combineReducers({
  account: makePageEditReducer(NAMESPACE),
  [buttonSpinnerCareInfoCarer]: makeButtonSpinnerReducer(
    NAMESPACE,
    CARE_INFO_CARER,
  ),
  [isSavingReadOnlyAccess]: makeButtonSpinnerReducer(
    NAMESPACE,
    READ_ONLY_ACCESS,
  ),
  autocompleteProfiles: makeAutocompleteProfilesReducer(NAMESPACE),
  accountValidationError,
  accountManagers,
  updatingApplicationQuestion,
})
