// @flow
import { push } from 'connected-react-router'
import { connect } from 'react-redux'

import GenericButtonLink from 'components/GenericButton'

const mapActionCreators = (dispatch, props) => ({
  onClick: () => {
    dispatch(push(props.goto))
  },
})

const mapStateToProps = (state, props) => ({
  text: props.text,
  quiet: props.quiet,
  loud: props.loud,
  large: props.large,
})

export default connect(mapStateToProps, mapActionCreators)(GenericButtonLink)
