import type React from 'react'
import type { ChangeEvent } from 'react'
import { useCallback, useEffect, useState } from 'react'

import { useEtSetSkipVisitingPostcodeValidationUsingPUT as setSkipVisitingPostcodeValidation } from '@elder/et-facade-et'
import type { AccountOverview } from '@elder/et-facade-et/lib/models/accountOverview'
import { Stack, Switch, Typography } from '@mui/material'
import { enqueueSnackbar } from 'notistack'

import { AccountEntry } from 'components/styledComponentsTranslatedClasses/AccountEntry'

interface Props {
  account: AccountOverview
}

export const SkipVisitingPostcodeValidation = ({ account }: Props) => {
  const [checked, setChecked] = useState(false)

  const enqueueSuccessSnackbar = useCallback(() => {
    enqueueSnackbar('Skip visiting postcode validation successfully changed', {
      variant: 'success',
    })
  }, [])

  const enqueueErrorSnackbar = useCallback(() => {
    enqueueSnackbar('Error setting skip postcode validation', {
      variant: 'error',
    })
  }, [])

  const setCheckedWhenDefined = useCallback(
    (
      skipVisitingPostcodeValidation: boolean | undefined,
      displaySuccessfullyChangedMessage: boolean,
    ) => {
      if (skipVisitingPostcodeValidation !== undefined) {
        setChecked(skipVisitingPostcodeValidation)
        if (displaySuccessfullyChangedMessage) {
          enqueueSuccessSnackbar()
        }
      }
    },
    [enqueueSuccessSnackbar],
  )

  useEffect(() => {
    const { skipVisitingPostcodeValidation } = account
    setCheckedWhenDefined(skipVisitingPostcodeValidation, false)
  }, [account, setCheckedWhenDefined])

  const mutation = setSkipVisitingPostcodeValidation({
    mutation: {
      onSuccess: (account: AccountOverview) => {
        const { skipVisitingPostcodeValidation } = account
        setCheckedWhenDefined(skipVisitingPostcodeValidation, true)
      },
      onError: () => {
        enqueueErrorSnackbar()
      },
    },
  })

  const handleChange = (event: ChangeEvent<HTMLInputElement>) => {
    mutation.mutate({
      accountId: account.accountId,
      data: { skipVisitingPostcodeValidation: event.target.checked },
    })
  }
  return (
    <AccountEntry>
      <span>Skip visiting postcode validation</span>
      <Stack direction="row" justifyContent="space-between" alignItems="center">
        <Typography variant="body1">
          <Switch
            checked={checked}
            onChange={handleChange}
            inputProps={{ 'aria-label': 'controlled' }}
            disabled={mutation.isPending}
          />
        </Typography>
      </Stack>
    </AccountEntry>
  )
}
