// @flow
import moment from 'moment'

export const phoneTypeOptions = [
  { value: 'UNKNOWN', label: 'Unknown' },
  { value: 'MOBILE', label: 'Mobile' },
  { value: 'LANDLINE', label: 'Landline' },
  { value: 'OFFICE', label: 'Office' },
  { value: 'OTHER', label: 'Other' },
]

export const basicGenderOptions = [
  { value: 'FEMALE', label: 'Female' },
  { value: 'MALE', label: 'Male' },
]

export const genderOptions = [
  ...basicGenderOptions,
  { value: 'OTHER', label: 'Other' },
]

export const billingCycleOptions = [
  { value: 'NONE', label: 'None' },
  { value: 'WEEKLY', label: 'Weekly' },
  { value: 'MONTHLY', label: 'Monthly' },
]

export const billingDayOfMonthOptions = [
  { value: '1', label: '1' },
  { value: '2', label: '2' },
  { value: '3', label: '3' },
  { value: '4', label: '4' },
  { value: '5', label: '5' },
  { value: '6', label: '6' },
  { value: '7', label: '7' },
]

export const paymentTypeOptions = [
  { value: 'CARD', label: 'Credit/Debit Card' },
  { value: 'BANK_TRANSFER', label: 'Bank Transfer' },
  { value: 'STRIPE_BANK_TRANSFER', label: 'Bank Transfer (Stripe)' },
  { value: 'DIRECT_DEBIT', label: 'Direct Debit' },
  { value: 'DIRECT_DEBIT_MANDATE', label: 'Direct Debit (mandate)' },
  { value: 'MANUAL_INVOICING', label: 'Manual Invoicing' },
]

const makeTimeOptions = () => {
  const newTimeOptions = []

  for (let hours = 0; hours < 24; hours += 1) {
    for (let minutes = 0; minutes < 60; minutes += 30) {
      const hoursString = hours < 10 ? `0${hours}` : hours.toString()
      const minutesString = minutes < 10 ? `0${minutes}` : minutes.toString()

      newTimeOptions.push({
        value: `${hoursString}:${minutesString}:00`,
        label: `${hours}:${minutesString}`,
      })
    }
  }

  return newTimeOptions
}
export const timeOptions = makeTimeOptions()

const makeYearOptions = () => {
  const startYear = 1900
  const thisYear = parseInt(moment().format('YYYY'), 10)
  const newYearOptions = []

  for (let year = startYear; year <= thisYear; year += 1) {
    newYearOptions.push({
      value: year,
      label: year.toString(),
    })
  }

  return newYearOptions
}
export const yearOptions = makeYearOptions()

export const spareBedroomsOptions = [
  { value: '1', label: '1' },
  { value: '2', label: '2' },
  { value: '3', label: '3' },
  { value: '4', label: '4+' },
]
