// @flow
import styled from 'styled-components'

export const EntrySmallEdit = styled.div`
  align-items: center;
  display: flex;
  justify-content: space-between;

  & > div {
    margin-right: 10px;

    &:last-child {
      margin-right: 0;
    }
  }
`
