// @flow
// $FlowOptOut
import React, { useEffect, useState } from 'react'

import { useDebounce, useLocalStorage } from '@elder/common'
import { useEtCarerSearchUsingGET } from '@elder/et-facade-et'
import { FileDownloadOutlined, AddOutlined } from '@mui/icons-material'
import { LoadingButton } from '@mui/lab'
import {
  Avatar,
  Box,
  Button,
  CircularProgress,
  Divider,
  Stack,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableFooter,
  TableHead,
  TablePagination,
  TableRow,
} from '@mui/material'
import { isEqual } from 'lodash'
import moment from 'moment'
import { Link, Link as RouterLink, useLocation } from 'react-router-dom'
import styled from 'styled-components'

import { logAmplitudeEvent } from 'features/analytics/amplitude'
import { BreadcrumbsBar } from 'features/header/BreadcrumbsBar'
import { CenterBox } from 'features/layout/CenterBox'
import {
  stickyMuiTableFooter,
  stickyMuiTableHeader,
} from 'features/layout/stickyTable'
import type { PrimaryFilterOptions } from 'features/professionals/domain'
import {
  Filters,
  isPostCodeValid,
} from 'features/professionals/ProfessionalsTable/Filters'
import * as routes from 'routes'
import { usePressKeyToFocus } from 'utils/usePressKeyToFocus'

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  height: 100%;
`

const PageContent = styled.div`
  height: 100%;
  display: flex;
  overflow: hidden;
`

const Safe = styled.div`
  color: #09dcc1;
`

const Loud = styled.div`
  color: #ef6461;
`

export type IFilterValues = {|
  +status: Array<string>,
  +workerStatus: Array<string>,
  +includeRestricted: boolean,
  +query: string,
  +postcode: string,
  +preferRecentlyActivated: boolean,
  +preferRecentlyLoggedIn: boolean,
  +preferStarred: boolean,
  +carerIds: Array<string>,
  +fromDate: string | null,
  +toDate: string | null,
  +languages: Array<string>,
  +onboardingLocation: Array<string>,
  +ethnicity: Array<string>,
  +nationalities: Array<string>,
  +careExpertise: Array<string>,
  +conditionExpertise: Array<string>,
  +workWithSmokers: boolean,
  +workWithPets: Array<string>,
  +hasDbs: boolean,
  +hasPvg: boolean,
  +driving: string,
  +gender: string,
  +careTypes: Array<string>,
|}

const initialFilterValues: IFilterValues = {
  status: ['NEW', 'ACTIVE'],
  workerStatus: [],
  includeRestricted: false,
  query: '',
  postcode: '',
  preferRecentlyActivated: false,
  preferRecentlyLoggedIn: false,
  preferStarred: false,
  carerIds: [],
  fromDate: null,
  toDate: null,
  languages: [],
  onboardingLocation: [],
  ethnicity: [],
  nationalities: [],
  careExpertise: [],
  conditionExpertise: [],
  workWithSmokers: false,
  workWithPets: [],
  hasDbs: false,
  hasPvg: false,
  driving: 'NOT_REQUIRED',
  gender: 'ANY',
  careTypes: [],
}

const getParams = (
  page: number,
  pageSize: number,
  filters: IFilterValues,
  matchRequestId?: string,
  carerIds?: Array<string>,
): $FlowFixMe => ({
  ...filters,
  from: page * pageSize,
  size: pageSize,
  postcode: isPostCodeValid(filters.postcode) ? filters.postcode : '',
  gender: filters.gender === 'ANY' ? undefined : filters.gender,
  driving: filters.driving === 'NOT_REQUIRED' ? undefined : filters.driving,
  matchRequestId,
  carerIds,
})

export type SetFilter = <T: $Keys<IFilterValues>>(
  key: T,
  value: $ElementType<IFilterValues, T>,
) => void

type Props = {|
  +options: PrimaryFilterOptions,
  +canCreatePro: boolean,
  +downloadCsvInProgress: boolean,
  +downloadCsv: (params: $FlowFixMe) => void,
|}

export const ProfessionalsTable = ({
  options,
  canCreatePro,
  downloadCsvInProgress,
  downloadCsv,
}: Props) => {
  const [rawFilters, setFilters] = useLocalStorage(
    'professionalsTableFilters',
    initialFilterValues,
  )

  /*
   * Merge the filters from local storage with all of our initial filters
   * This ensures that if we add a filter then the user will always have a value
   * for it.
   */
  const filters = { ...initialFilterValues, ...rawFilters }

  const setFilter: SetFilter = (key, value) => {
    setFilters({ ...filters, [key]: value })
    if (key === 'query' && filters.query !== '') return
    if (key === 'postcode' && filters.postcode !== '') return
    logAmplitudeEvent('Carers Table Filter Changed', { filter: key })
  }

  const { hash } = useLocation()

  const [, matchRequestId] =
    /matchRequestIdFilter=([a-z0-9_-]+)/i.exec(hash) || []

  const carerIds = Array.from(hash.matchAll(/&carerFilterId=p[0-9]+/g))
    .map((match) => match[0].split('='))
    .map((parts) => parts[1])

  const [page, setPage] = useState(0)
  const [pageSize, setPageSize] = useState(25)

  const debouncedFilters = useDebounce(filters, 100)
  const params = getParams(
    page,
    pageSize,
    (debouncedFilters: $FlowFixMe),
    matchRequestId,
    carerIds,
  )
  const professionalsQuery = useEtCarerSearchUsingGET(params)

  const { data } = professionalsQuery

  const resetFilters = () => {
    setFilters(initialFilterValues)
    logAmplitudeEvent('Carers Table Filter Cleared')
  }

  useEffect(() => {
    if (data && page * pageSize > data.totalCount) {
      setPage(0)
    }
  })

  const { inputRef } = usePressKeyToFocus(191)

  const [imageReviewMode, setImageReviewMode] = useState(false)
  const [showImageReviewDoneCarers, setShowImageReviewDoneCarers] =
    useState(false)
  const [doneCarerIds, setDoneCarerIds] = useLocalStorage(
    'image-review-done-carer-ids',
    [],
  )
  const markCarerAsDone = (carerId: string) =>
    setDoneCarerIds([...doneCarerIds, carerId])
  const unmarkCarerAsDone = (carerId: string) =>
    setDoneCarerIds(doneCarerIds.filter((id) => id !== carerId))
  useEffect(() => {
    const toggleImageReviewMode = (active: boolean, showDone: boolean) => {
      setImageReviewMode(active)
      setShowImageReviewDoneCarers(showDone)
    }
    window.toggleImageReviewMode = toggleImageReviewMode
    return () => {
      window.toggleImageReviewMode = undefined
    }
  }, [])

  return (
    <Wrapper>
      <BreadcrumbsBar
        breadcrumbs={[{ title: 'Carers', to: routes.professionals }]}
      >
        <Stack
          spacing={4}
          direction="row"
          divider={<Divider orientation="vertical" flexItem />}
        >
          <LoadingButton
            onClick={() => {
              downloadCsv(params)
              logAmplitudeEvent('Carers Table CSV Downloaded')
            }}
            startIcon={<FileDownloadOutlined />}
            color="inherit"
            loading={downloadCsvInProgress}
            sx={{ '.MuiLoadingButton-loadingIndicator': { color: 'white' } }}
          >
            Download CSV
          </LoadingButton>
          {canCreatePro ? (
            <Button
              to={routes.carersNew}
              onClick={() =>
                logAmplitudeEvent('Carers Table Add New Carer Clicked')
              }
              component={Link}
              startIcon={<AddOutlined />}
              color="inherit"
            >
              Add New Carer
            </Button>
          ) : null}
        </Stack>
      </BreadcrumbsBar>
      <PageContent>
        <Filters
          filters={(filters: $FlowFixMe)}
          setFilter={setFilter}
          filterOptions={options}
          resetFilters={resetFilters}
          dirty={!isEqual(filters, initialFilterValues)}
          queryInputFieldRef={inputRef}
        />
        <Box sx={{ overflow: 'auto', height: '100%', flex: 1 }}>
          {professionalsQuery.isPending && (
            <CenterBox>
              <CircularProgress />
            </CenterBox>
          )}
          {professionalsQuery.isError && (
            <CenterBox>
              There was an error when fetching the list of carers
            </CenterBox>
          )}
          {data && data.totalCount === 0 && (
            <CenterBox>No results to display</CenterBox>
          )}

          {data && data.items.length > 0 && (
            <TableContainer sx={{ overflow: 'visible' }}>
              <Table
                aria-label="Professionals"
                size="small"
                sx={{
                  ...stickyMuiTableHeader,
                  ...stickyMuiTableFooter,
                }}
              >
                <TableHead>
                  <TableRow>
                    <TableCell>&nbsp;</TableCell>
                    <TableCell>Name</TableCell>
                    <TableCell>Contact</TableCell>
                    <TableCell>Distance to postcode</TableCell>
                    <TableCell>Weeks</TableCell>
                    <TableCell>Activation date</TableCell>
                    <TableCell>Last portal login</TableCell>
                    <TableCell>Last job ends</TableCell>
                    {imageReviewMode && (
                      <TableCell>
                        Done (
                        {showImageReviewDoneCarers
                          ? 'Showing ALL carers'
                          : 'Showing NOT DONE carers'}
                        )
                      </TableCell>
                    )}
                  </TableRow>
                </TableHead>
                <TableBody>
                  {data.items
                    .filter(
                      (row) =>
                        !imageReviewMode ||
                        showImageReviewDoneCarers ||
                        !(doneCarerIds || []).includes(row.professionalId),
                    )
                    .map((row) => (
                      <TableRow key={row.professionalId}>
                        <TableCell>
                          <Avatar
                            src={row.profilePicture}
                            alt={row.name}
                            width={400}
                            height={400}
                            sx={
                              imageReviewMode
                                ? { width: 200, height: 200 }
                                : undefined
                            }
                          />
                        </TableCell>
                        <TableCell component="th" scope="row">
                          <Box>
                            <RouterLink
                              to={`/professionals/${row.professionalId}`}
                            >
                              {row.name}
                            </RouterLink>
                          </Box>
                          <Box>Age: {row.age || '?'}</Box>
                        </TableCell>
                        <TableCell>
                          {row.phone && (
                            <Box>
                              <a href={`tel:${row.phone}`}>{row.phone}</a>
                            </Box>
                          )}
                          <Box>
                            {row.email && (
                              <a href={`mailto:${row.email}`}>{row.email}</a>
                            )}
                          </Box>
                        </TableCell>
                        <TableCell>
                          {row.distance
                            ? `${row.distance.toFixed(1)} miles`
                            : 'n/a'}
                        </TableCell>
                        <TableCell>{row.completedWeeks || '-'}</TableCell>
                        <TableCell>
                          {row.activationDate && (
                            <Box>{moment(row.activationDate).format('ll')}</Box>
                          )}
                          <Box>{row.status}</Box>
                        </TableCell>
                        <TableCell>
                          {row.lastLoginToProPortal
                            ? moment(row.lastLoginToProPortal).fromNow()
                            : 'Never logged in'}
                        </TableCell>
                        <TableCell>
                          {row.lastJobEnds && (
                            <Box>{moment(row.lastJobEnds).format('ll')}</Box>
                          )}
                          <Box>
                            {row.available && row.available ? (
                              <Safe>Available</Safe>
                            ) : (
                              <Loud>Unavailable</Loud>
                            )}
                          </Box>
                        </TableCell>
                        {imageReviewMode && (
                          <TableCell>
                            {doneCarerIds.includes(row.professionalId) ? (
                              <Button
                                onClick={() =>
                                  unmarkCarerAsDone(row.professionalId)
                                }
                                color="secondary"
                                variant="contained"
                              >
                                Mark not reviewed
                              </Button>
                            ) : (
                              <Button
                                onClick={() =>
                                  markCarerAsDone(row.professionalId)
                                }
                                color="secondary"
                                variant="contained"
                              >
                                Mark reviewed
                              </Button>
                            )}
                          </TableCell>
                        )}
                      </TableRow>
                    ))}
                </TableBody>
                <TableFooter>
                  <TableRow>
                    <TablePagination
                      count={data.totalCount}
                      rowsPerPage={pageSize}
                      page={page}
                      onPageChange={(_, newPage) => setPage(newPage)}
                      onRowsPerPageChange={(e) =>
                        setPageSize(Number(e.target.value))
                      }
                      colSpan={9}
                    />
                  </TableRow>
                </TableFooter>
              </Table>
            </TableContainer>
          )}
        </Box>
      </PageContent>
    </Wrapper>
  )
}
