// @flow
import { connect } from 'react-redux'

import { Link } from './Link'

const mapStateToProps = (state, props) => ({
  text: props.text,
  page: props.goto,
})

export default connect(mapStateToProps)(Link)
