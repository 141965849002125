// @flow

import * as Yup from 'yup'

import type { Field } from 'domain/fields'

export const createValidation = (fields: Array<Field>): Object =>
  Yup.object(
    fields.reduce(
      (accumulator, field) => ({
        ...accumulator,
        [field.name]: field.validation || Yup.mixed(),
      }),
      {},
    ),
  )

export const createInitialValues = (fields: Array<Field>): Object =>
  fields.reduce((accumulator, field) => {
    if (![null, undefined].includes(field.initialValue)) {
      accumulator[field.name] = field.initialValue
    }
    return accumulator
  }, {})
