// @flow
/* eslint-disable react/prop-types */
// $FlowOptOut
import React, { useState } from 'react'

import { Header, Select } from '@elder/common'
import { LoadingButton } from '@mui/lab'
import { Button } from '@mui/material'
import { Form, Formik } from 'formik'
import styled from 'styled-components'

import { DynamicField } from 'components/forms/DynamicField'
import type { NoteRequestParams, Template } from 'features/notes/domain'
import { defaultTemplate } from 'features/notes/templates/defaultTemplate'
import { createValidation, createInitialValues } from 'utils/dynamicForms'

const Buttons = styled.div`
  display: flex;
  justify-content: flex-end;
  align-items: center;
  margin-top: 16px;
`

const StyledSubmitButton = styled(LoadingButton)`
  width: 120px;
`

const StyledHeader = styled(Header)`
  margin: 16px 0;
`

const Content = styled.div`
  margin-top: 16px;
`

const StyledSelect = styled(Select)`
  margin-bottom: 16px;
`

type Props = {|
  +cancel: () => void,
  +saveNote: (NoteRequestParams) => void,
  +saving: boolean,
  +templates?: Array<Template>,
|}

export const AddNoteDialog = ({
  cancel,
  saveNote,
  saving,
  templates = [defaultTemplate],
}: Props) => {
  const [currentTemplate, setCurrentTemplate] = useState('DEFAULT')
  const selectedTemplate = templates.find(
    ({ value }) => value === currentTemplate,
  )
  const aggregatedFields = selectedTemplate
    ? selectedTemplate.sections.reduce((accumulator, { fields }) => {
        accumulator.push(...fields)
        return accumulator
      }, [])
    : []

  return (
    <Content>
      {templates.length > 1 && (
        <StyledSelect
          label="Template"
          options={templates.map(({ value, label }) => ({ value, label }))}
          placeholder="Choose a template type"
          value={currentTemplate}
          onChange={(templateType) => {
            if (!Array.isArray(templateType)) {
              setCurrentTemplate(templateType.value)
            }
          }}
        />
      )}
      {selectedTemplate && (
        <Formik
          enableReinitialize
          initialValues={{ tags: [], ...createInitialValues(aggregatedFields) }}
          validationSchema={createValidation(aggregatedFields)}
          onSubmit={(values) => {
            saveNote(selectedTemplate.adapter(values))
          }}
        >
          {({ errors, submitCount }) => (
            <Form>
              {selectedTemplate.sections.map(({ fields, title, info }) => (
                <div key={title}>
                  {title && <StyledHeader level="h3" label={title} />}
                  {info && <StyledHeader level="h3" label={info} />}
                  {fields.map((props) => (
                    <DynamicField
                      key={props.name}
                      {...props}
                      errorLabel={
                        submitCount > 0 ? errors[props.name] : undefined
                      }
                    />
                  ))}
                </div>
              ))}
              <Buttons>
                <Button disabled={saving} onClick={cancel} variant="text">
                  Cancel
                </Button>
                <StyledSubmitButton
                  type="submit"
                  disabled={saving}
                  loading={saving}
                  variant="contained"
                >
                  Save
                </StyledSubmitButton>
              </Buttons>
            </Form>
          )}
        </Formik>
      )}
    </Content>
  )
}
