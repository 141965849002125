// @flow
import { makeAction } from 'elder/actions'

import { addNamespace } from 'utils'

export const SET_START_LOADING = 'SET_START_LOADING'
export const SET_STOP_LOADING = 'SET_STOP_LOADING'

export const makeActions = (namespace: string, id: ?string) => ({
  setStartLoading: makeAction(addNamespace(namespace, id, SET_START_LOADING)),

  setStopLoading: makeAction(addNamespace(namespace, id, SET_STOP_LOADING)),
})

export default makeActions
