// @flow
/* eslint-disable flowtype/no-weak-types */

export const makeSelectors = (stateSelector: (Object) => Object) => ({
  get: (state: Object) => stateSelector(state).get('value'),

  getEditMode: (state: Object) => stateSelector(state).get('editMode'),

  getIsLoading: (state: Object) => stateSelector(state).get('isLoading'),

  getIsEditing: (state: Object, section: Object) =>
    stateSelector(state).get('editMode') === section,
})

export default makeSelectors
