// @flow
// $FlowOptOut
import React, { useState } from 'react'

import type { Option } from '@elder/common'
import {
  spacings,
  FormikSelect,
  FormikTextArea,
  FormikInput,
  getValue,
} from '@elder/common'
import { LoadingButton } from '@mui/lab'
import { Button } from '@mui/material'
import { Formik } from 'formik'
import styled from 'styled-components'
import * as Yup from 'yup'

import { StyledFormForGlobalOverride } from 'components/forms/StyledFormForGlobalOverride'
import { FormButtons } from 'components/styledComponentsTranslatedClasses/FormButtons'
import { Section } from 'components/styledComponentsTranslatedClasses/Section'

const FieldWrapper = styled.div`
  margin-bottom: ${spacings.small};
`

const isFundingProofNeeded = (fundingMethodTypeOptions, fundingMethodType) =>
  fundingMethodTypeOptions.some(
    (fundingMethodTypeOption) =>
      fundingMethodTypeOption.value === fundingMethodType,
  ) && ['DIRECT_PAYMENTS', 'COMMISSIONED_SERVICES'].includes(fundingMethodType)

const isFundingLocalAuthorityNeeded = (fundingSource) =>
  ['PART_COUNCIL', 'FULLY_COUNCIL'].includes(fundingSource)

const validationSchema = Yup.object({
  fundingSource: Yup.string().required('Required'),
  fundingLocalAuthority: Yup.string().when('fundingSource', {
    is: (value) => isFundingLocalAuthorityNeeded(value),
    then: Yup.string().required('Required'),
    otherwise: Yup.string().nullable(),
  }),
  fundingMethodType: Yup.string(),
  fundingProof: Yup.string().matches(
    /(https?:\/\/)?(drive|docs).google.com/,
    'Please enter a valid Google Doc or Google Drive link',
  ),
})

type Props = {|
  +fundingSource: ?string,
  +fundingMethodType: ?string,
  +fundingProof: ?string,
  +fundingLocalAuthority: ?string,
  +fundingSourceOptions: Array<Option>,
  +fundingMethodTypeOptions: Array<Option>,
  +isUpdating: boolean,
  +onCancel: () => void,
  +onUpdate: ({|
    +fundingSource: ?string,
    +fundingLocalAuthority: ?string,
    +fundingMethodType: ?string,
    +fundingProof: ?string,
  |}) => void,
|}

export const FundingForm = ({
  fundingSource,
  fundingMethodType,
  fundingProof,
  fundingLocalAuthority,
  fundingSourceOptions,
  fundingMethodTypeOptions,
  isUpdating,
  onCancel,
  onUpdate,
}: Props) => {
  const [selectedFundingSource, setSelectedFundingSource] =
    useState(fundingSource)

  const initialValues = {
    fundingSource: getValue(fundingSource, ''),
    fundingMethodType: getValue(fundingMethodType, ''),
    fundingProof: getValue(fundingProof, ''),
    fundingLocalAuthority: getValue(fundingLocalAuthority, ''),
  }

  const selectedFundingSourceOption = fundingSourceOptions.find(
    (fundingSourceOption) =>
      fundingSourceOption.value === selectedFundingSource,
  )
  const selectedFundingSourceMethodTypes =
    selectedFundingSourceOption &&
    selectedFundingSourceOption.metadata &&
    selectedFundingSourceOption.metadata.fundingMethodTypes
  const filteredFundingMethodTypeOptions = selectedFundingSourceMethodTypes
    ? fundingMethodTypeOptions.filter(
        (option) =>
          option.value &&
          selectedFundingSourceMethodTypes.includes(option.value),
      )
    : []

  const handleSubmit = (data) => {
    onUpdate({
      fundingSource: data.fundingSource,
      fundingLocalAuthority: isFundingLocalAuthorityNeeded(data.fundingSource)
        ? data.fundingLocalAuthority
        : null,
      fundingMethodType: data.fundingMethodType,
      fundingProof: data.fundingProof.length > 0 ? data.fundingProof : null,
    })
  }

  return (
    <Section>
      <Formik
        initialValues={initialValues}
        validationSchema={validationSchema}
        onSubmit={handleSubmit}
      >
        {({ submitCount, errors, values }) => (
          <StyledFormForGlobalOverride>
            <FieldWrapper>
              <FormikSelect
                name="fundingSource"
                label="Funding Source"
                options={fundingSourceOptions}
                errorLabel={submitCount > 0 ? errors.fundingSource : null}
                onChangeCallback={(value) => setSelectedFundingSource(value)}
              />
            </FieldWrapper>
            {isFundingLocalAuthorityNeeded(values.fundingSource) && (
              <FieldWrapper>
                <FormikInput
                  name="fundingLocalAuthority"
                  label="Local Authority Name"
                  errorLabel={
                    submitCount > 0 ? errors.fundingLocalAuthority : null
                  }
                />
              </FieldWrapper>
            )}
            <FieldWrapper>
              <FormikSelect
                name="fundingMethodType"
                label="Funding Method"
                options={filteredFundingMethodTypeOptions}
                errorLabel={submitCount > 0 ? errors.fundingMethodType : null}
              />
            </FieldWrapper>
            {isFundingProofNeeded(
              filteredFundingMethodTypeOptions,
              values.fundingMethodType,
            ) && (
              <FormikTextArea
                name="fundingProof"
                label="Proof of funding"
                placeholder="https://drive.google.com/file/d/xxxxxxxxxxxxx/view"
                errorLabel={submitCount > 0 ? errors.fundingProof : null}
              />
            )}
            <FormButtons>
              <Button onClick={onCancel} variant="text">
                Cancel
              </Button>
              <LoadingButton
                type="submit"
                loading={isUpdating}
                variant="contained"
              >
                Save
              </LoadingButton>
            </FormButtons>
          </StyledFormForGlobalOverride>
        )}
      </Formik>
    </Section>
  )
}
