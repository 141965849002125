// @flow
import { addNamespace } from 'utils'

export const NAMESPACE = 'ACCOUNT_FROM_SALESFORCE'

export const SET_SF_ID = addNamespace(NAMESPACE, 'SET_SF_ID')

export const setSFId = (sfId: string) => ({
  type: SET_SF_ID,
  sfId,
})
