// @flow
import React from 'react'

import { typography, colors } from '@elder/common'
import styled from 'styled-components'

type Props = {|
  +errorLabel: string,
|}

const Label = styled.div`
  ${typography.paragraph1};
  /* don't affect layout */
  display: block;
  position: absolute;
  color: ${colors.errorDark};
`

export const ErrorLabel = ({ errorLabel }: Props) => <Label>{errorLabel}</Label>
