// @flow

import * as Yup from 'yup'

import type { Template } from 'features/notes/domain'
import { createTagOptions } from 'features/notes/helpers'

export const defaultTemplate: Template = {
  value: 'DEFAULT',
  label: 'General',
  adapter: (props) => props,
  sections: [
    {
      fields: [
        {
          type: 'TEXT',
          name: 'title',
          label: 'Note title',
          validation: Yup.string()
            .required('Required')
            .test(
              'title-len',
              'Less than 256 characters please',
              (val = '') => val.length <= 255,
            ),
        },
        {
          type: 'TEXT_AREA',
          name: 'text',
          label: 'Note text',
          validation: Yup.string()
            .required('Required')
            .test(
              'text-len',
              'Less than 8191 characters please',
              (val = '') => val.length < 8191,
            ),
        },
        {
          type: 'SELECT',
          name: 'tags',
          label: 'Tags',
          multi: true,
          options: createTagOptions(
            'Matching',
            'Billing',
            'Complaint',
            'Compliment',
            'Check-In',
            'Cust. Expectations',
            'Care needs',
            'COVID-19',
          ),
        },
      ],
    },
  ],
}
