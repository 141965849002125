// @flow
import { combineReducers } from 'redux-immutable'

import autocompleteProfiles from './autocompleteProfiles'
import isLoading from './isLoading'

export const makeReducer = (namespace: string) =>
  combineReducers({
    autocompleteProfiles: autocompleteProfiles(namespace),
    isLoading: isLoading(namespace),
  })
