// @flow

import { NAMESPACE } from 'features/carelogs/store'
import type { SurveyResponse } from 'features/carelogs/types'
import { addNamespace } from 'utils'

export const GET_CUSTOMER_CARELOGS = addNamespace(
  NAMESPACE,
  'GET_CUSTOMER_CARELOGS',
)
export const GET_CUSTOMER_CARELOGS_COMPLETED = addNamespace(
  NAMESPACE,
  'GET_CUSTOMER_CARELOGS_COMPLETED',
)
export const GET_CUSTOMER_CARELOGS_FAILED = addNamespace(
  NAMESPACE,
  'GET_CUSTOMER_CARELOGS_FAILED',
)

export const getCustomerCarelogs = ({
  customerId,
}: {
  +customerId: string,
}) => ({
  type: GET_CUSTOMER_CARELOGS,
  customerId,
})

export const getCustomerCarelogsCompleted = ({
  customerId,
  response,
}: {
  +customerId: string,
  +response: SurveyResponse,
}) => ({
  type: GET_CUSTOMER_CARELOGS_COMPLETED,
  customerId,
  response,
})

export const getCustomerCarelogsFailed = ({
  customerId,
}: {
  +customerId: string,
}) => ({
  type: GET_CUSTOMER_CARELOGS_FAILED,
  customerId,
})
