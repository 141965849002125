// @flow
import { colors } from '@elder/common'
import styled from 'styled-components'

export const Entry = styled.div`
  align-items: center;
  border-bottom: 1px solid ${colors.blue200};
  display: flex;
  line-height: 32px;
  padding: 10px 0;
  white-space: pre-wrap;

  > span,
  > label,
  > div {
    width: 50%;
  }

  span,
  label,
  strong {
    color: ${({ labelColor = colors.lightSmoke }) => labelColor};
  }

  input[type='text'],
  input[type='email'],
  input[type='date'],
  textarea {
    width: 100%;
  }
`
