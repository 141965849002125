import { createContext, useContext } from 'react'

import type { AccountOverview } from '@elder/et-facade-et'

export interface AccountContext {
  account: AccountOverview
}

export const accountContext = createContext<AccountContext | null>(null)

export const useAccountContext = () => {
  const context = useContext(accountContext)
  if (!context) throw new Error('useAccountContext used outside of Provider')
  return context.account
}
