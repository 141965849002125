// @flow
import { makeAction } from 'elder/actions'

import { addNamespace } from 'utils'

export const NAMESPACE = 'CARER_MESSAGING'

export const SEND_CARER_MESSAGE = addNamespace(NAMESPACE, 'SEND_CARER_MESSAGE')

export const sendCarerMessage = makeAction(
  SEND_CARER_MESSAGE,
  (params: Object) => ({
    ...params,
  }),
)
