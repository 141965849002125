// @flow
import { createSelector } from 'reselect'

import { NAMESPACE } from './actions'

const getActiveWorkflowState = (state) => state.get(NAMESPACE)
export const getActiveWorkflow = createSelector(
  [getActiveWorkflowState],
  (state) => state.activeWorkflow.toJS(),
)
