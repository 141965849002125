import type { PlacementDetails } from '@elder/et-facade-et'
import type { Moment } from 'moment'
import moment from 'moment'

import type { TimelineBlockColor } from 'components/solutions/SolutionTimeline/SolutionTimeline'
import {
  prettyCompactDateWithDow,
  prettyDurationWeeks,
} from 'utils/dateDecorators'

interface Args {
  placementSet: PlacementDetails[]
  momentViewStart: Moment
  daysInView: number
  colorSelector: (placement: PlacementDetails) => TimelineBlockColor
  placementsLookup: { [key: string]: PlacementDetails }
}

export interface TransformedPlacementForTimeline extends PlacementDetails {
  colors: TimelineBlockColor
  prettyStartDateInclusive: string
  from: number
  prettyEndDateExclusive?: string
  to?: number
  isInfinite?: true
  prettyDuration: string
  isLeftCutoff?: true
  isRightCutoff?: true
  rateBreakdown: PlacementDetails['rateBreakdown'] & {
    daysFromStart: number
    displayedRate: string
    prettyStartDate: string
    prettyEndDate: string
  }
}

interface TransformedPlacementsForTimeline {
  placements: TransformedPlacementForTimeline[]
  placementsBeforeView: TransformedPlacementForTimeline[]
  placementsAfterView: TransformedPlacementForTimeline[]
}

export const transformPlacementsForTimeline = ({
  placementSet,
  momentViewStart,
  daysInView,
  colorSelector,
  placementsLookup,
}: Args): TransformedPlacementsForTimeline => {
  const placementsBeforeView: TransformedPlacementForTimeline[] = []
  const placementsAfterView: TransformedPlacementForTimeline[] = []

  const placements = placementSet.map((basePlacement) => {
    const placement = { ...basePlacement } as $TSFixMe
    placement.colors = colorSelector(placement)
    const momentStartDate = moment(placement.effectiveStartDateInclusive)
    const diffFromStartDate = momentStartDate.diff(momentViewStart, 'days')

    placement.prettyStartDateInclusive = prettyCompactDateWithDow(
      placement.effectiveStartDateInclusive,
    )

    placement.from = diffFromStartDate

    if (placement.effectiveEndDateExclusive) {
      const momentEndDate = moment(placement.effectiveEndDateExclusive)
      const diffToEndDate = momentEndDate.diff(momentViewStart, 'days')
      placement.prettyEndDateExclusive = prettyCompactDateWithDow(
        placement.effectiveEndDateExclusive,
      )
      placement.to = diffToEndDate
    } else {
      placement.prettyEndDateExclusive = 'End of solution'
      placement.to = daysInView + 1 // Always just beyond reach!
      placement.isInfinite = true
    }

    placement.prettyDuration =
      placement.effectiveStartDateInclusive &&
      placement.effectiveEndDateExclusive
        ? `(${prettyDurationWeeks(
            placement.effectiveStartDateInclusive,
            placement.effectiveEndDateExclusive,
          )})`
        : ''

    if (placement.from < 0 && placement.to <= 0) {
      placementsBeforeView.push(placement)
    } else if (placement.from >= daysInView && placement.to > daysInView) {
      placementsAfterView.push(placement)
    } else {
      if (placement.from < 0 && placement.to > 0) {
        placement.isLeftCutoff = true
      }
      if (placement.from < daysInView && placement.to > daysInView) {
        placement.isRightCutoff = true
      }
    }

    let lastPartialRate: $TSFixMe = null
    let firstInViewRate: $TSFixMe = null

    placement.rateBreakdown = placement.rateBreakdown.map((baseRateDetails) => {
      const rateDetails = { ...baseRateDetails } as $TSFixMe
      const effectivePlacementStart = moment.max(
        momentViewStart,
        momentStartDate,
      )
      const rateDetailsStart = moment(rateDetails.startDateInclusive)
      rateDetails.daysFromStart = rateDetailsStart.diff(
        effectivePlacementStart,
        'days',
      )
      rateDetails.displayedRate =
        rateDetails &&
        rateDetails.rateComponentTotals &&
        rateDetails.rateComponentTotals.providerRate &&
        rateDetails.rateComponentTotals.providerRate.displayText
      rateDetails.prettyStartDate = rateDetails.startDateInclusive
        ? prettyCompactDateWithDow(rateDetails.startDateInclusive)
        : ''
      rateDetails.prettyEndDate = rateDetails.endDateExclusive
        ? prettyCompactDateWithDow(rateDetails.endDateExclusive)
        : '∞'
      if (placement.from + rateDetails.daysFromStart < 0) {
        lastPartialRate = rateDetails
      }
      if (!firstInViewRate && placement.from + rateDetails.daysFromStart >= 0) {
        firstInViewRate = rateDetails
      }

      return rateDetails
    })

    // Final touch-up, display the rate for the leftmost "broken" bar, if it fits in view
    if (
      lastPartialRate &&
      ((firstInViewRate &&
        placement.from + firstInViewRate.daysFromStart > 1) ||
        !firstInViewRate)
    ) {
      lastPartialRate.daysFromStart = 0
    }

    // eslint-disable-next-line no-param-reassign
    placementsLookup[placement.id] = placement

    return placement as TransformedPlacementForTimeline
  })

  return {
    placements,
    placementsBeforeView,
    placementsAfterView,
  }
}
