// @flow
/* eslint-disable flowtype/no-weak-types */
import { every } from 'lodash'

import type { ElderUser } from 'features/elderUsers/domain'
import { USER_STATUS } from 'features/elderUsers/domain'
import { getRelationshipContacts } from 'routes/account/summary/selectors'

export const getAccountExecutiveHierarchy = (
  state: Object,
): Array<ElderUser> => {
  const relationshipContacts = getRelationshipContacts(state)
  return relationshipContacts
    ? relationshipContacts.detailedAccountManagerHierarchy
    : []
}

export const getFallbackAccountExecutive = (
  state: Object,
): ElderUser | null => {
  const relationshipContacts = getRelationshipContacts(state)
  return relationshipContacts
    ? relationshipContacts.failoverDetailedAccountManager
    : null
}

export const areAccountExecutivesUnavailable = (
  accountExecutives: Array<ElderUser>,
): boolean =>
  every(
    accountExecutives,
    (ae: ElderUser) => ae.disabled || ae.elderStatus === USER_STATUS.AWAY,
  )
