// @flow
import Immutable from 'immutable'

import { addNamespace } from 'utils'

import { GET_CHANGELOG, SET_CHANGELOG } from './actions'

export const makeReducer = (namespace: string) => {
  const initialState = Immutable.List()
  const getChangelog = addNamespace(namespace, GET_CHANGELOG)
  const setChangelog = addNamespace(namespace, SET_CHANGELOG)

  return (state: Array<Object> = initialState, action: Object) => {
    switch (action.type) {
      case getChangelog:
        return initialState
      case setChangelog:
        return Immutable.fromJS(action.changelogs)
      default:
        return state
    }
  }
}

export default makeReducer
