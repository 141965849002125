// @flow
import { combineReducers } from 'redux-immutable'

import { makeReducer } from 'modules/pageEdit'
import { NAMESPACE } from 'routes/profile/summary/actions'

import profileValidationError from './profileValidationError'
import setIsExpanded from './setIsExpanded'

const profile = makeReducer(NAMESPACE)

export default combineReducers({
  profile,
  profileValidationError,
  setIsExpanded,
})
