import { createContext, useContext, useEffect, useState } from 'react'

import { useEtGetTalkJsConfigForCustomerPortalFromEtUsingGET as useConfigForElderUser } from '@elder/et-facade-et'
import Talk from 'talkjs'
import type { Session } from 'talkjs/all'

import { getConfig } from 'app/config'

export const elderUser = { id: 'elder', name: 'elder', role: 'elder' }

const sessionContext = createContext<Session | null>(null)

interface Props {
  children: React.ReactNode
}

export const ChatSessionProvider = ({ children }: Props) => {
  const [session, setSession] = useState<Session | null>(null)

  const configQuery = useConfigForElderUser(elderUser.id)

  useEffect(() => {
    if (!configQuery.data) return

    let session: Session | undefined
    const init = async () => {
      await Talk.ready

      const currentTalkUser = new Talk.User(elderUser)

      session = new Talk.Session({
        appId: getConfig().talkJSAppId,
        me: currentTalkUser,
        signature: configQuery.data.signature,
      })

      setSession(session)
    }

    init()

    return () => {
      if (session) session.destroy()
    }
  }, [configQuery.data])

  return (
    <sessionContext.Provider value={session}>
      {children}
    </sessionContext.Provider>
  )
}

export const useChatSession = () => {
  return useContext(sessionContext)
}
