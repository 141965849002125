import type { ReactNode } from 'react'
import React from 'react'

import styled from 'styled-components'

interface Props {
  children: ReactNode
  height: number
  side: string
}

const StyledTimelineDots = styled.div`
  ${({ $height, side }) => `
    align-items: center;
    display: flex;
    height: ${$height}px;
    overflow: hidden;
    position: absolute;
    width: 120px;
    height: 100%;
    top: -1px;
    ${side}: -120px;
    justify-content: ${side === 'left' ? 'flex-end' : 'flex-start'};


    > div {
      display: flex;
      width: auto;
      height: 100%;
      position: relative;
      overflow: hidden;
      align-items: center;
      justify-content: ${side === 'left' ? 'flex-end' : 'flex-start'};
    }
  `}
`

const StyledDottedLine = styled.div`
  border-top: 1px dotted #73819c;
  height: 0;
  position: absolute;
  width: 100%;
`

export const TimelineDots = ({ children, height, side }: Props) => (
  <StyledTimelineDots side={side} $height={height}>
    <div>
      <StyledDottedLine side={side} />
      {children}
    </div>
  </StyledTimelineDots>
)
