// @flow
import addNamespace from 'utils/addNamespace'

import { NAMESPACE } from '.'

export const GET_CONTACTS = addNamespace(NAMESPACE, 'GET')
export const GET_STARTING = addNamespace(NAMESPACE, 'GET_STARTING')
export const GET_COMPLETED = addNamespace(NAMESPACE, 'GET_COMPLETED')
export const GET_FAILED = addNamespace(NAMESPACE, 'GET_FAILED')
export const UPDATE = addNamespace(NAMESPACE, 'UPDATE')
export const UPDATE_STARTING = addNamespace(NAMESPACE, 'UPDATE_STARTING')
export const UPDATE_COMPLETED = addNamespace(NAMESPACE, 'UPDATE_COMPLETED')
export const UPDATE_FAILED = addNamespace(NAMESPACE, 'UPDATE_FAILED')
export const UPDATE_VALIDATION_FAILED = addNamespace(
  NAMESPACE,
  'UPDATE_VALIDATION_FAILED',
)
export const SET_STATE = addNamespace(NAMESPACE, 'SET_STATE')

export const getAction = (id: string) => ({ type: GET_CONTACTS, id })

export const getStartingAction = () => ({ type: GET_STARTING })

export const getCompletedAction = (data: Object) => ({
  type: GET_COMPLETED,
  data,
})

export const getFailedAction = (error: Object) => ({ type: GET_FAILED, error })

export const updateAction = (updateParams: Object) => ({
  type: UPDATE,
  updateParams,
})

export const updateStartingAction = () => ({ type: UPDATE_STARTING })

export const updateCompletedAction = (data: Object) => ({
  type: UPDATE_COMPLETED,
  data,
})

export const updateFailedAction = (error: Object) => ({
  type: UPDATE_FAILED,
  error,
})

export const updateValidationFailedAction = (error: Object) => ({
  type: UPDATE_VALIDATION_FAILED,
  error,
})

export const setStateAction = (data: Object) => ({ type: SET_STATE, data })
