// @flow
import type { Saga } from 'redux-saga'
import { take, call, select, put } from 'redux-saga/effects'

import { applicationSuccess } from 'features/snackbar/snackbar'
import { pageSelectors } from 'routes/account/selectors'
import {
  pageActions,
  UPDATE_BILLING_CYCLE,
  updateBillingCycleValidationFail,
} from 'routes/account/summary/actions'
import { expectedBillingCycle } from 'routes/account/summary/validFields'
import { manageErrorWithFieldValidation } from 'utils/fieldValidationErrors'
import { fetchFlow } from 'utils/services'

export function* putBillingCycle(data: Object): Generator<*, void, *> {
  const acctId = yield select(pageSelectors.getId)
  const endpoint = `/et/accounts/${acctId}/billingCycle`
  const method = 'PUT'

  try {
    const responseBody = yield call(fetchFlow, endpoint, method, data)
    yield put(pageActions.set(responseBody))
    applicationSuccess({
      title: 'Account updated',
      message: 'Bank and payment details were updated successfully.',
    })
  } catch (error) {
    yield call(
      manageErrorWithFieldValidation,
      error,
      'billingCycle',
      expectedBillingCycle,
      updateBillingCycleValidationFail,
    )
  }
}

export function* watchBillingCycle(): Saga<*> {
  while (true) {
    const payload = yield take(UPDATE_BILLING_CYCLE)
    yield call(putBillingCycle, payload.data)
  }
}
