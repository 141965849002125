// @flow
import { createSelector } from 'reselect'

import { getReferenceDataState } from 'app/selectors'

export const makeGetReferenceDataDecorator = (id: string) =>
  createSelector([getReferenceDataState], (referenceData) => {
    const jsValues = referenceData
      .get(id)
      .get('values')
      .reduce((result, value) => {
        result[value.get('name')] = value.get('displayText') // eslint-disable-line no-param-reassign
        return result
      }, {})

    const decorator = (key) => {
      if (!key) {
        return ''
      }
      return jsValues[key] || key
    }

    return decorator
  })

const getReferenceDataFromEnum = (refData, includeDisabled) =>
  refData
    .get('values')
    .filter((entry) => includeDisabled || !entry.get('disabled'))
    .reduce((result, value) => {
      result.push({
        value: value.get('name'),
        label: value.get('displayText'),
        metadata: value.get('metadata') && value.get('metadata').toJS(),
      })
      return result
    }, [])

const getReferenceDataFromRange = (refData) => {
  const retRefData = []
  const valMax = parseFloat(refData.get('max'))
  const incr = parseFloat(refData.get('increment'))
  if (refData.get('metadata').get('allowsZero')) {
    retRefData.push({
      value: '0.00',
      label: '0.00',
    })
  }
  for (let val = parseFloat(refData.get('min')); val <= valMax; val += incr) {
    const value = val.toFixed(2)
    retRefData.push({
      value,
      label: value,
    })
  }

  return retRefData
}

export const makeGetReferenceDataOptions = (
  id: string,
  includeDisabled?: boolean,
) =>
  createSelector([getReferenceDataState], (referenceData) => {
    const refData = referenceData.get(id)
    let retRefData = []
    if (refData.get('type') === 'enum') {
      retRefData = getReferenceDataFromEnum(refData, includeDisabled)
    } else if (refData.get('type') === 'range') {
      retRefData = getReferenceDataFromRange(refData)
    }
    return {
      default: refData.get('metadata').get('default') || null,
      options: retRefData,
    }
  })
