// @flow
import { addNamespace } from 'utils'

export const NAMESPACE = 'PROFILE_NEW'

export const CREATE = addNamespace(NAMESPACE, 'CREATE')
export const CREATE_VALIDATION_FAIL = addNamespace(
  NAMESPACE,
  'CREATE_VALIDATION_FAIL',
)
export const CLEAR_VALIDATION = addNamespace(NAMESPACE, 'CLEAR_VALIDATION')

export const createNew = (data: Object) => ({
  type: CREATE,
  data,
})

export const createNewValidationFail = (error: Object) => ({
  type: CREATE_VALIDATION_FAIL,
  error,
})

export const clearValidation = () => ({
  type: CLEAR_VALIDATION,
})
