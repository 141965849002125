// @flow

// These are heavily inspired by Java 8 (aka Joda Time) date types.

import moment from 'moment'

export opaque type Instant = string

export const instantFormat = 'YYYY-MM-DDTHH:mm:ssZ'
const instantPattern = /^\d\d\d\d-\d\d-\d\dT\d\d:\d\d:\d\d(\.\d\d\d)?Z$/

export class Instants {
  static ofString(instantStr: string): Instant {
    if (!instantPattern.test(instantStr)) {
      throw new Error(
        `Provided string (${instantStr}) is not an ISO datetime in UTC`,
      )
    }

    return instantStr
  }

  static now(): Instant {
    return moment().format(instantFormat)
  }

  static momentOf(date: Instant) {
    return moment(date, instantFormat)
  }

  static toLongDateAndTime(instantItem: Instant) {
    return Instants.momentOf(instantItem).format('h:mma, dddd D MMMM')
  }

  static toString(instantItem: Instant) {
    return Instants.momentOf(instantItem).toISOString()
  }

  static compare(left: Instant, right: Instant): number {
    return Instants.momentOf(right).diff(Instants.momentOf(left))
  }
}
