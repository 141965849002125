// @flow
import {
  addOrDeleteFromParamArray,
  getParameterValues,
} from 'utils/urlManipulation'

import { NAMESPACE } from './actions'
import ProfessionalsContainer from './ProfessionalsContainer'
import reducer from './reducers'
import saga from './sagas'

// We want to remove the id parameters on a refresh so AMs don't get stuck with only preferred carers or eligible carers
// for a particular placement in their carer tables.
getParameterValues('carerFilterId').forEach((id) =>
  addOrDeleteFromParamArray('carerFilterId', id),
)
getParameterValues('matchRequestIdFilter').forEach((id) =>
  addOrDeleteFromParamArray('matchRequestIdFilter', id),
)

export { ProfessionalsContainer, saga, reducer, NAMESPACE }
