// @flow
import queryString from 'query-string'

const ifStringConvertToArray = (value) =>
  typeof value === 'string' ? [value] : value || []

export const addOrReplaceParam = (key: string, value: string) => {
  const parsed = queryString.parse(window.location.hash)

  const newQueryParams = { ...parsed, [key]: value }

  window.location.hash = queryString.stringify(newQueryParams)

  return parsed[key] !== newQueryParams[key]
}

// This method will only delete a param if it's handed a name value pair that is identical to one already in the url.
export const addOrDeleteFromParamArray = (key: string, value: string) => {
  const parsed = queryString.parse(window.location.hash)

  const safeOldValues = ifStringConvertToArray(parsed[key])

  if (safeOldValues.includes(value)) {
    // Delete the value
    window.location.hash = queryString.stringify({
      ...parsed,
      [key]: safeOldValues.filter((val) => val !== value),
    })
  } else {
    // Add the value
    window.location.hash = queryString.stringify({
      ...parsed,
      [key]: [...safeOldValues, value],
    })
  }
}

export const getParameterValues = (key: string): Array<string> =>
  ifStringConvertToArray(queryString.parse(window.location.hash)[key])

export const queryToObject = (search: string): Object => {
  const parsed = queryString.parse(search)
  return Object.keys(parsed).reduce(
    (acc, curr) => ({
      ...acc,
      [curr]: ifStringConvertToArray(parsed[curr]).join(','),
    }),
    {},
  )
}
