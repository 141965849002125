/**
 * Given a react router route, potentially with placeholders like :carerId,
 * format a full path with the placeholders substituted with values
 */
export function formatLink(
  path: string,
  values: { [varName: string]: string },
) {
  let result = path
  // eslint-disable-next-line no-restricted-syntax, guard-for-in
  for (const varName in values) {
    const varValue = values[varName]
    const varPattern = new RegExp(`:${varName}(\\([^)]*\\))?`)
    result = result.replace(varPattern, varValue)
  }
  return result
}
