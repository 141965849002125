// @flow
import { makeAction } from 'elder/actions'

import { addNamespace } from 'utils'

export const NAMESPACE = 'CARER_PAGE_GENERICS'

export const SET_CARER = addNamespace(NAMESPACE, 'SET_CARER')
export const GETTING_CARER = addNamespace(NAMESPACE, 'GETTING_CARER')
export const STARTED_LOADING = addNamespace(NAMESPACE, 'STARTED_LOADING')
export const FINISHED_LOADING = addNamespace(NAMESPACE, 'FINISHED_LOADING')

export const setCarer = makeAction(SET_CARER, (value: Object) => ({
  value,
}))

export const gettingCarer = makeAction(GETTING_CARER)

export const startedLoading = makeAction(STARTED_LOADING)

export const finishedLoading = makeAction(FINISHED_LOADING)
