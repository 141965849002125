// @flow
export const expectedUpdateFields = {
  'accountOverview.internalNotes': true,
  'accountOverview.address.address1': true,
  'accountOverview.address.address2': true,
  'accountOverview.address.city': true,
  'accountOverview.address.postcode': true,
  'accountOverview.addressInfo': true,
  'accountOverview.familyVisits': true,
  'accountOverview.familyVisitsText': true,
  'accountOverview.drivingRequirements': true,
  'accountOverview.localTransport': true,
  'accountOverview.localTransportText': true,
  'accountOverview.localAmenities': true,
  'accountOverview.wifi': true,
  'accountOverview.phoneReception': true,
  'accountOverview.pets': true,
  'accountOverview.petsText': true,
  'accountOverview.smoking': true,
  'accountOverview.carerBedroom': true,
  'accountOverview.carerBathroom': true,
  'accountOverview.furtherDetails': true,
}
