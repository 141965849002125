// @flow
import type { Saga } from 'redux-saga'
import { call, put, select, takeLatest } from 'redux-saga/effects'

import { applicationError } from 'features/snackbar/snackbar'
import { pageSelectors } from 'routes/account/selectors'
import {
  DELETE_CHANGE_HISTORY,
  pageActions,
} from 'routes/account/summary/actions'
import { postFlow, describeServiceError } from 'utils/services'

function* deleteChangeHistory(): Generator<*, void, *> {
  const accountId = yield select(pageSelectors.getId)
  const endpoint = `/et/accounts/${accountId}/clearHistory`

  try {
    const accountSummary = yield call(postFlow, endpoint, {})
    yield put(pageActions.set(accountSummary))
  } catch (error) {
    applicationError(
      describeServiceError(error, `Error with service call ${endpoint}`),
    )
  }
}

export default function* (): Saga<*> {
  yield takeLatest(DELETE_CHANGE_HISTORY, deleteChangeHistory)
}
