// @flow
import { object, string, Type } from 'flow-validator'

import type { FlowOfType } from 'elder/types'

export const medicine: Type<*> = object({
  name: string,
})

export type Medicine = $Exact<FlowOfType<typeof medicine>>
