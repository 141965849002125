// @flow
/* eslint-disable flowtype/no-weak-types */
export const makeSelectors = (stateSelector: (Object) => Object) => ({
  getId: (state: Object) => stateSelector(state).get('id'),
  getIdentity: (state: Object) => stateSelector(state).get('identity'),
  getIdentityToJS: (state: Object) =>
    stateSelector(state).get('identity').toJS(),
})

export default makeSelectors
