// @flow
import React from 'react'

import type { InputWrapperProps } from '@elder/common'
import { colors, typography, InputWrapper } from '@elder/common'
import { RadioButtonChecked, RadioButtonUnchecked } from '@mui/icons-material'
import { Field } from 'formik'
import styled, { css } from 'styled-components'

import type { GenericOption } from 'domain/option'

const StyledRadioButtonWrapper = styled.div`
  ${({ horizontal }) =>
    horizontal
      ? css`
          margin-right: 32px;
        `
      : css`
          margin-top: 16px;

          :first-of-type {
            margin-top: 0;
          }
        `} ${typography.paragraph1};

  display: flex;
  align-items: center;
`

const HiddenInput = styled.input`
  display: none;
`

const LabelText = styled.span`
  display: inline-block;
  margin-left: 16px;
  color: ${({ colour }) => colour};
`

const StyledLabel = styled.label`
  cursor: ${({ disabled }) => (disabled ? 'not-allowed' : 'pointer')};
  display: flex;

  ${({ disabled }) =>
    disabled &&
    css`
      opacity: 0.7;
    `};
`

const IsHorizontal = styled.div`
  ${({ horizontal }) =>
    horizontal &&
    css`
      display: flex;
    `};
`

const PositionRadioButtonIcon = styled.div`
  margin-top: 2px;
`

type Props = $ReadOnly<{|
  +name: string,
  +horizontal?: boolean,
  +options: Array<GenericOption<string>>,
  ...$Exact<InputWrapperProps>,
|}>

export const RadioButtons = ({
  name,
  options,
  disabled,
  horizontal,
  errorLabel,
  ...inputWrapperProps
}: Props) => (
  <InputWrapper
    {...inputWrapperProps}
    disabled={disabled}
    errorLabel={errorLabel}
  >
    <Field name={name}>
      {({ field: { value }, form: { setFieldValue } }) => (
        <IsHorizontal horizontal={horizontal}>
          {options.map(({ value: optionValue, label: optionLabel, colour }) => {
            const checked = value === optionValue

            let textColour = colors.marceline

            if (checked && colour) {
              textColour = colour
            }

            return (
              <StyledRadioButtonWrapper
                key={optionValue}
                horizontal={!!horizontal}
              >
                <StyledLabel disabled={disabled}>
                  <HiddenInput
                    disabled={disabled}
                    aria-invalid={!!errorLabel}
                    checked={checked}
                    onChange={() => setFieldValue(name, optionValue)}
                    type="radio"
                  />

                  <PositionRadioButtonIcon>
                    {checked ? (
                      <RadioButtonChecked />
                    ) : (
                      <RadioButtonUnchecked />
                    )}
                  </PositionRadioButtonIcon>

                  <LabelText colour={textColour}>{optionLabel}</LabelText>
                </StyledLabel>
              </StyledRadioButtonWrapper>
            )
          })}
        </IsHorizontal>
      )}
    </Field>
  </InputWrapper>
)
