// @flow
import { makeAction } from 'elder/actions'

import type { FileDetails, File } from 'domain/file'
import type { Evidence, EvidenceMetadata } from 'features/carerDocuments/domain'
import { makeActions as makeActionsButtonSpinner } from 'modules/buttonSpinner'
import { addNamespace } from 'utils'

export const NAMESPACE = 'CARER_DOCUMENTS'

export const EVIDENCES = 'EVIDENCES'

export const GET_EVIDENCES = addNamespace(NAMESPACE, 'GET_EVIDENCES')

export const SET_EVIDENCES = addNamespace(NAMESPACE, 'SET_EVIDENCES')

export const ADD_EVIDENCE = addNamespace(NAMESPACE, 'ADD_EVIDENCE')

export const OPEN_FILES = addNamespace(NAMESPACE, 'OPEN_FILES')

export const DELETE_EVIDENCE = addNamespace(NAMESPACE, 'DELETE_EVIDENCE')

export const EDIT_EVIDENCE = addNamespace(NAMESPACE, 'EDIT_EVIDENCE')

export const SET_DOCUMENT_TO_PREVIEW = addNamespace(
  NAMESPACE,
  'SET_DOCUMENT_TO_PREVIEW ',
)

export const CLEAR_DOCUMENT_TO_PREVIEW = addNamespace(
  NAMESPACE,
  'CLEAR_DOCUMENT_TO_PREVIEW',
)

export const VERIFY_EVIDENCE = addNamespace(NAMESPACE, 'VERIFY_EVIDENCE')

export const evidencesButtonSpinnerActions = makeActionsButtonSpinner(
  NAMESPACE,
  EVIDENCES,
)

export const getEvidences = makeAction(GET_EVIDENCES, (carerId: string) => ({
  carerId,
}))

export const setEvidences = makeAction(
  SET_EVIDENCES,
  (evidences: Array<Evidence>, carerId: string) => ({
    evidences,
    carerId,
  }),
)

export const addEvidence = makeAction(
  ADD_EVIDENCE,
  (filesToUpload: Array<FileDetails>, metadata: EvidenceMetadata) => ({
    filesToUpload,
    metadata,
  }),
)

export const openFiles = makeAction(OPEN_FILES, (evidenceId: string) => ({
  evidenceId,
}))

export const deleteEvidence = makeAction(
  DELETE_EVIDENCE,
  (evidenceId: string) => ({
    evidenceId,
  }),
)

export const editEvidence = makeAction(
  EDIT_EVIDENCE,
  (
    evidenceId: string,
    metadata: EvidenceMetadata,
    addedDocuments: Array<File>,
    removedDocuments: Array<string>,
  ) => ({
    evidenceId,
    metadata,
    addedDocuments,
    removedDocuments,
  }),
)

export const setDocumentToPreview = makeAction(
  SET_DOCUMENT_TO_PREVIEW,
  (downloadUrl: ?string) => ({
    downloadUrl,
  }),
)

export const clearDocumentToPreview = makeAction(CLEAR_DOCUMENT_TO_PREVIEW)

export const verifyEvidence = makeAction(
  VERIFY_EVIDENCE,
  (evidenceId: string) => ({ evidenceId }),
)
