// @flow
import Immutable from 'immutable'
import { combineReducers } from 'redux-immutable'

import { CLEAR_VALIDATION, CREATE_VALIDATION_FAIL } from './actions'

const initialState = Immutable.Map()

export const professionalNewValidationErrorReducer = (
  state: Object = initialState,
  action: Object,
) => {
  switch (action.type) {
    case CREATE_VALIDATION_FAIL:
      return Immutable.Map(action.error.professionalNewValidationError)
    case CLEAR_VALIDATION:
      return initialState
    default:
      return state
  }
}

export default combineReducers({
  professionalNewValidationError: professionalNewValidationErrorReducer,
})
