// @flow
import { connect } from 'react-redux'

import { getCanEdit } from 'app'
import { getCanClearAccountHistory } from 'app/login/selectors'
import { getPrettyContactRoles } from 'app/referenceData'
import { pageActions as wrapperPageActions } from 'routes/profile/profileWrapper/actions'
import SummaryModule from 'routes/profile/summary/Summary'

import {
  pageActions,
  updateOverview,
  deleteChangeHistory,
  sendPasswordReset,
} from './actions'
import {
  pageSelectors,
  getPrettyProfileSummary,
  getJsProfileValidationError,
} from './selectors'

const mapActionCreators = {
  updateStatus: pageActions.updateStatus,
  editSection: pageActions.editSection,
  cancelEdit: pageActions.cancelEdit,
  deleteChangeHistory,
  updateOverview,
  sendPasswordReset,
  // $FlowOptOut
  getData: () => pageActions.get(),
  setId: (profileId) => wrapperPageActions.setId(profileId),
}

const mapStateToProps = (state, props) => ({
  profile: getPrettyProfileSummary(state),
  canEdit: getCanEdit(state),
  sectionEditMode: pageSelectors.getEditMode(state),
  validationErrors: getJsProfileValidationError(state),
  changelogUrl: `${props.match.url}/changelog`,
  canClearAccountHistory: getCanClearAccountHistory(state),
  displayContactRole: getPrettyContactRoles(state),
})

export default connect(mapStateToProps, mapActionCreators)(SummaryModule)
