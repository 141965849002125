// @flow

import { routerMiddleware } from 'connected-react-router/immutable'
import * as historyImport from 'history'
import Immutable from 'immutable'
import type { Middleware } from 'redux'
import { applyMiddleware, createStore } from 'redux'
import { composeWithDevTools } from 'redux-devtools-extension/logOnlyInProduction'

import { initializeSagas } from './saga'

export const initializeStore = ({
  rootSaga,
  rootReducer,
  customMiddleware,
}: {
  rootReducer: any,
  rootSaga: any,
  customMiddleware?: Middleware<{}, { type: string }>,
} = {}) => {
  const { sagaMiddleware, runRootSaga } = initializeSagas(rootSaga)

  const history = historyImport.createBrowserHistory()
  const initialState = Immutable.Map()
  const middleware = [
    sagaMiddleware,
    routerMiddleware(history),
    customMiddleware,
  ].filter(Boolean)
  const composeEnhancers = composeWithDevTools({})

  const store = createStore(
    rootReducer(history),
    initialState,
    composeEnhancers(applyMiddleware(...middleware)),
  )

  return { store, history, runRootSaga }
}
