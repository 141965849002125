// @flow
import Immutable from 'immutable'

import {
  UPDATE_ACCT_ENV,
  UPDATE_ACCT_ENV_VALIDATION_FAIL,
} from 'routes/account/environment/actions'

const InitialStateRecord = Immutable.Record({
  acctEnv: Immutable.Map(),
})

export const accountEnvironmentValidationErrorReducer = (
  state: Object = new InitialStateRecord(),
  action: Object,
) => {
  switch (action.type) {
    case UPDATE_ACCT_ENV_VALIDATION_FAIL:
      return state.set('acctEnv', Immutable.fromJS(action.error.acctEnv))
    case UPDATE_ACCT_ENV:
      return new InitialStateRecord()
    default:
      return state
  }
}

export default accountEnvironmentValidationErrorReducer
