// @flow
import type { Saga } from 'redux-saga'
import { call, put, takeLatest } from 'redux-saga/effects'

import { setCarer } from 'features/carerPageGenerics/store/actions'
import { applicationError } from 'features/snackbar/snackbar'
import { putFlow, getFlow } from 'utils/services'
import { describeServiceError } from 'utils/services/errors'

import {
  setTestimonialStatusLoadingSpinner,
  SET_TESTIMONIAL_STATUS,
  setBlacklist,
  GET_BLACKLIST,
} from './store/actions'

function* setTestimonialStatus({
  payload: { testimonialId, status, rejectionReason },
  carerId,
}: {
  +payload: {
    +testimonialId: string,
    +status: Object,
    +rejectionReason: Object,
  },
  +carerId: string,
}): Generator<Object, void, Object> {
  const endpoint = `/et/professionals/${carerId}/testimonials/${testimonialId}/setStatus`
  try {
    yield put(setTestimonialStatusLoadingSpinner(testimonialId))
    const responseBody = yield call(putFlow, endpoint, {
      status,
      rejectionReason,
    })
    yield put(setCarer(responseBody))
  } catch (error) {
    applicationError(
      describeServiceError(error, `Error with service call ${endpoint}`),
    )
  } finally {
    // $FlowOptOut
    yield put(setTestimonialStatusLoadingSpinner(null))
  }
}

function* handleGetBlacklist({
  carerId,
}: {
  +carerId: string,
}): Generator<Object, void, Object> {
  const endpoint = `/et/professionals/${carerId}/blacklist`
  try {
    const response = yield call(getFlow, endpoint)
    yield put(setBlacklist(response.entries))
  } catch (error) {
    applicationError(
      describeServiceError(error, `Error with service call ${endpoint}`),
    )
  }
}

export function* saga(): Saga<*> {
  yield takeLatest(SET_TESTIMONIAL_STATUS, setTestimonialStatus)
  yield takeLatest(GET_BLACKLIST, handleGetBlacklist)
}
