// @flow
import Immutable from 'immutable'
import { combineReducers } from 'redux-immutable'

import { GET_PAYMENT_BATCHES, SET_PAYMENT_BATCHES } from './actions'

const initialState = Immutable.List()

const rowReducer = (state = initialState, action) => {
  switch (action.type) {
    case GET_PAYMENT_BATCHES:
      return initialState
    case SET_PAYMENT_BATCHES:
      return Immutable.fromJS(action.paymentBatches)
    default:
      return state
  }
}

const loadingReducer = (state = false, action) => {
  switch (action.type) {
    case GET_PAYMENT_BATCHES:
      return true
    case SET_PAYMENT_BATCHES:
      return false
    default:
      return state
  }
}

export default combineReducers({
  paymentBatches: rowReducer,
  isLoading: loadingReducer,
})
