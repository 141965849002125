import React from 'react'

import {
  KeyboardDoubleArrowLeft,
  KeyboardDoubleArrowRight,
} from '@mui/icons-material'
import { ButtonBase, IconButton, Stack } from '@mui/material'
import { times } from 'lodash'
import type { Moment } from 'moment'
import moment from 'moment'

import type { TransformedSolution } from 'routes/account/solutions/contexts/transformSolution'

export interface DateGuide {
  key: string
  position: number
  date: Moment
  prettyDate: string
  type: 'monday' | 'today' | 'solution'
  showLabel: boolean
}

interface SolutionDateLabelsProps {
  dateGuides: DateGuide[]
  goBackwardsInTime: () => void
  goForwardsInTime: () => void
  setFocus: (date: Moment) => void
}

export const SolutionDateLabels = ({
  dateGuides,
  goBackwardsInTime,
  goForwardsInTime,
  setFocus,
}: SolutionDateLabelsProps) => (
  <Stack
    direction="row"
    justifyContent="space-between"
    spacing={0}
    position="relative"
  >
    <IconButton
      onClick={goBackwardsInTime}
      size="small"
      sx={{ position: 'absolute', left: '-40px', top: '-5px' }}
    >
      <KeyboardDoubleArrowLeft />
    </IconButton>

    {dateGuides
      .filter((date) => date.showLabel)
      .map((entry) => (
        <ButtonBase
          key={entry.key}
          onClick={() => setFocus(entry.date)}
          sx={{
            fontWeight: entry.type === 'today' ? 'bold' : 'normal',
            background: 'white',
            m: 0.5,
          }}
        >
          {entry.prettyDate}
        </ButtonBase>
      ))}

    <IconButton
      onClick={goForwardsInTime}
      size="small"
      sx={{ position: 'absolute', right: '-40px', top: '-5px' }}
    >
      <KeyboardDoubleArrowRight />
    </IconButton>
  </Stack>
)

export const makeDateGuides = (
  focus: Moment,
  solution?: TransformedSolution,
): DateGuide[] => {
  const defaultEntries = times(5).map((i) => {
    const x = i - 2
    const date = focus.clone().add(x, 'weeks')
    return {
      key: date.format('YYYY-MM-DD'),
      position: i * 7,
      date: date.clone(),
      prettyDate: date.format("DD MMM 'YY"),
      type: 'monday',
      showLabel: true,
    } as const
  })

  const solutionEntries = solution
    ? ([
        {
          key: 'solution-start',
          position:
            moment(solution.startDateInclusive).diff(focus, 'days') + 14,
          date: moment(solution.startDateInclusive),
          prettyDate: 'Solution Start',
          type: 'solution',
          showLabel: false,
        },
        ...(solution.endDateExclusive
          ? ([
              {
                key: 'solution-end',
                position:
                  moment(solution.endDateExclusive).diff(focus, 'days') + 14,
                date: moment(solution.endDateExclusive),
                prettyDate: 'Solution End',
                type: 'solution',
                showLabel: false,
              },
            ] as const)
          : ([] as const)),
      ] as const)
    : ([] as const)

  const today = moment().startOf('day')
  const daysFromFocusToToday = today.diff(focus, 'days')

  const todayEntry = {
    key: 'today',
    position: daysFromFocusToToday + 14 + 1,
    date: today.clone(),
    prettyDate: 'Today',
    type: 'today',
    showLabel: false,
  } as const

  if (Math.abs(daysFromFocusToToday) <= 21) {
    return [todayEntry, ...defaultEntries, ...solutionEntries]
  } else {
    return [...defaultEntries, ...solutionEntries]
  }
}

export type DateRangeEntryType = DateGuide['type']
