// @flow
import type { Saga } from 'redux-saga'
import { fork } from 'redux-saga/effects'

import { watchTransactionNew } from './watchTransactionNew'

export function* watchTransactionNewFlow(): Saga<*> {
  yield fork(watchTransactionNew)
}

export default watchTransactionNewFlow
