// @flow
import { makeActions } from 'modules/pageEdit'
import { addNamespace } from 'utils'

export const NAMESPACE = 'PROFILE_SUMMARY'

export const pageActions = makeActions(NAMESPACE)

export const UPDATE_OVERVIEW = addNamespace(NAMESPACE, 'UPDATE_OVERVIEW')
export const UPDATE_OVERVIEW_VALIDATION_FAIL = addNamespace(
  NAMESPACE,
  'UPDATE_OVERVIEW_VALIDATION_FAIL',
)
export const DELETE_CHANGE_HISTORY = addNamespace(
  NAMESPACE,
  'DELETE_CHANGE_HISTORY',
)
export const SEND_PASSWORD_RESET = addNamespace(
  NAMESPACE,
  'SEND_PASSWORD_RESET',
)

export const SET_IS_EXPANDED = addNamespace(NAMESPACE, 'SET_IS_EXPANDED')

export const updateOverview = (data: Object) => ({
  type: UPDATE_OVERVIEW,
  data,
})

export const updateOverviewValidationFail = (error: Object) => ({
  type: UPDATE_OVERVIEW_VALIDATION_FAIL,
  error,
})

export const deleteChangeHistory = () => ({
  type: DELETE_CHANGE_HISTORY,
})

export const sendPasswordReset = (email: string) => ({
  type: SEND_PASSWORD_RESET,
  email,
})

export const setIsExpanded = (index: number) => ({
  type: SET_IS_EXPANDED,
  index,
})
