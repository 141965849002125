import React from 'react'

import { Form, Input } from 'formsy-react-components'
import RPT from 'prop-types'
import Select from 'react-select'
import styled from 'styled-components'

import { ButtonSubmit, GenericButton } from 'components'
import { forms } from 'messages/forms'
import { phoneTypeOptions } from 'utils/selectOptions'

const ProfileSection = styled.div`
  flex: 1 0 100%;
  margin-bottom: 30px;

  h3 {
    text-transform: capitalize;

    span {
      color: #73819c;
      font-size: 16px;
    }
  }
  @media only screen and (min-width: 1051px) {
    flex: 0.4 0 40%;
  }

  select,
  input[type='text'],
  input[type='email'] {
    transition: border-color 0.3s ease;
    background-color: #ffffff;
    border: solid 2px #cad9f8;
    box-sizing: border-box;
    padding: 8px 10px;
    width: 100%;

    &:focus {
      border: solid 2px #19305e;
    }

    &:disabled {
      opacity: 0.5;
    }

    &::placeholder {
      color: #cad9f8;
      text-transform: capitalize;
    }
  }

  .profile__section--full {
    flex: 1 0 100%;
    margin-bottom: 30px;

    h3 {
      text-transform: capitalize;

      span {
        color: #73819c;
        font-size: 16px;
      }
    }
  }

  .profile__header {
    align-items: center;
    border-bottom: 2px solid #19305e;
    display: flex;
    justify-content: space-between;
    margin: 20px 0 10px;
    padding-bottom: 10px;
  }

  .profile__entry {
    align-items: center;
    border-bottom: 1px solid #eaf0fc;
    display: flex;
    line-height: 32px;
    padding: 10px 0;
    white-space: pre-wrap;

    > span,
    > label,
    > div {
      width: 50%;
    }

    span,
    label,
    strong {
      color: #73819c;
    }

    input[type='text'],
    input[type='email'],
    input[type='date'],
    textarea {
      width: 100%;
    }
  }

  .profile__entry__small-edit {
    align-items: center;
    display: flex;
    justify-content: space-between;

    & > div {
      margin-right: 10px;

      &:last-child {
        margin-right: 0;
      }
    }
  }

  .profile__link {
    color: #3e86fa;
    cursor: pointer;
  }

  // TODO: Use src/components/styledComponentsTranslatedClasses/FormButtons instead
  .profile__form-buttons {
    display: flex;
    justify-content: flex-end;
    margin-top: 20px;

    & > div:not(:last-child) {
      margin-right: 15px;
    }
  }

  .profile__table {
    border-collapse: collapse;
    width: 100%;

    .profile__head {
      tr > th {
        border-bottom: 1px solid #19305e;
        font-size: 16px;
        padding: 10px;
        text-align: center;
        text-transform: capitalize;
      }
    }

    .profile__body {
      tr > td {
        border-bottom: 1px solid #eaf0fc;
        padding: 10px;
        text-align: center;
      }
    }
  }

  .profile__change {
    white-space: pre-wrap;
  }

  .profile__phone__wrapper {
    border-bottom: 1px solid #eaf0fc;
    display: flex;
    flex-direction: column;
    padding: 10px 0;
  }

  .profile__phone__entry {
    align-items: center;
    display: flex;
    justify-content: space-between;
    margin-bottom: 8px;
  }

  .profile__phone__select {
    margin: 0px 5px;
    width: 100%;

    > div {
      border: 2px solid #cad9f8;
      border-radius: 0px;
    }
  }

  .is-open .Select-control {
    border-color: #19305e;
  }

  .Select-placeholder {
    color: #cad9f8;
  }

  .Select-arrow,
  .Select-arrow-zone:hover > .Select-arrow {
    border-top-color: #cad9f8;
  }

  .is-open > .Select-control .Select-arrow {
    border-bottom-color: #cad9f8;
  }

  /**
 * react-select related
 */
  .Select-control {
    transition: border-color 0.3s ease;
    border: 2px solid #cad9f8;
    border-radius: 0px;

    &:hover {
      box-shadow: 0 0 0 0;
    }
  }

  /**
 * formsy related
 */
  .form-group {
    &.has-feedback {
      .validation-message {
        color: #ef6461;
      }
    }

    &.has-error {
      input[type='text'],
      input[type='email'],
      input[type='date'],
      textarea {
        border-color: #ef6461;
      }
    }
  }

  .form-group.row {
    align-items: center;
    border-bottom: 1px solid #eaf0fc;
    display: flex;
    line-height: 32px;
    padding: 10px 0;
    white-space: pre-wrap;

    > span,
    > label,
    > div {
      width: 50%;
    }

    span,
    label,
    strong {
      color: #73819c;
    }

    input[type='text'],
    input[type='email'],
    input[type='date'],
    textarea {
      width: 100%;
    }

    &.has-feedback {
      .validation-message {
        display: block;
        line-height: 20px;
        text-transform: none;
        width: 100%;
      }
    }

    &.is-date-picker > div > div {
      display: block;

      & > div {
        display: block;

        & > div {
          display: block;
        }
      }
    }

    .radio-inline {
      margin-right: 15px;

      &:last-child {
        margin-right: 0px;
      }
    }
  }

  .form-group__container {
    .form-group {
      border-bottom: 0;
      padding: 0;

      .checkbox,
      .radio {
        line-height: 25px;
      }
    }

    .form-group__entry {
      padding-top: 15px;

      > .control-label {
        margin-right: 3%;
        text-align: right;
        width: 47%;
      }
    }
  }
`

// eslint-disable-next-line no-restricted-properties
class OverviewForm extends React.Component {
  constructor(props) {
    super(props)

    this.state = {
      canSubmit: true,
      phoneNumbers: props.profile.phoneNumbers,
    }
  }

  UNSAFE_componentWillReceiveProps(props) {
    this.form.formsyForm.updateInputsWithError(props.validationErrors)
  }

  setValid = () => this.setState({ canSubmit: true })

  setInvalid = () => this.setState({ canSubmit: false })

  submitForm = (data) => {
    if (this.state.canSubmit) {
      data.phoneNumbers = this.state.phoneNumbers // eslint-disable-line no-param-reassign
      this.props.submit(data)
    }
  }

  addPhone = () => {
    const newPhones = this.state.phoneNumbers.slice()
    newPhones.push({
      number: '',
      type: 'UNKNOWN',
    })
    this.setState({ phoneNumbers: newPhones })
  }

  removePhone = (index) => {
    const newPhones = this.state.phoneNumbers.slice()
    newPhones.splice(index, 1)
    this.setState({ phoneNumbers: newPhones })
  }

  editPhoneNumber = (index, newNumber) => {
    const newPhones = this.state.phoneNumbers.slice()
    newPhones[index].number = newNumber
    this.setState({ phoneNumbers: newPhones })
  }

  editPhoneType = (index, newType) => {
    const newPhones = this.state.phoneNumbers.slice()
    newPhones[index].type = newType
    this.setState({ phoneNumbers: newPhones })
  }

  render() {
    return (
      <ProfileSection>
        <Form
          ref={(form) => {
            this.form = form
          }}
          onSubmit={this.submitForm}
          onValid={this.setValid}
          onInvalid={this.setInvalid}
          validatePristine
        >
          <div className="profile__header">
            <h3>Overview (edit mode)</h3>
          </div>
          <Input
            name="firstName"
            label="First name:"
            value={this.props.profile.firstName}
            validationErrors={{
              isDefaultRequiredValue: forms.validation.isRequired,
            }}
            required
          />
          <Input
            name="lastName"
            label="Last name:"
            value={this.props.profile.lastName}
            validationErrors={{
              isDefaultRequiredValue: forms.validation.isRequired,
            }}
            required
          />
          <Input
            name="companyName"
            label="Company:"
            value={this.props.profile.company}
          />
          <Input
            name="emailAddress"
            label="Email:"
            type="email"
            value={this.props.profile.email}
            validations={{
              isEmail: true,
              matchRegexp: /^((?!noemail).)*$/i,
            }}
            validationErrors={{
              isEmail: forms.validation.isEmail,
              matchRegexp: forms.validation.isNotNoEmail,
            }}
          />
          <Input
            name="address1"
            label="Address 1:"
            value={this.props.profile.address.address1}
          />
          <Input
            name="address2"
            label="Address 2:"
            value={this.props.profile.address.address2}
          />
          <Input
            name="city"
            label="City:"
            value={this.props.profile.address.city}
          />
          <Input
            name="postcode"
            label="Postcode:"
            value={this.props.profile.address.postcode}
          />
          <div className="profile__header">
            <h3>Phone Numbers (edit mode)</h3>
            <GenericButton text="Add Number" onClick={this.addPhone} />
          </div>
          <div className="profile__phone__wrapper">
            {this.state.phoneNumbers.map((phoneNumber, index) => (
              // eslint-disable-next-line react/no-array-index-key
              <div key={index.toString()} className="profile__phone__entry">
                <input
                  type="text"
                  onChange={(event) =>
                    this.editPhoneNumber(index, event.currentTarget.value)
                  }
                  value={phoneNumber.number}
                />
                <Select
                  className="profile__phone__select"
                  name={`phoneType${index}`}
                  value={phoneNumber.type}
                  options={phoneTypeOptions}
                  onChange={(event) => this.editPhoneType(index, event.value)}
                  clearable={false}
                />
                <GenericButton
                  text="X"
                  onClick={() => this.removePhone(index)}
                  loud
                />
              </div>
            ))}
          </div>
          <div className="profile__form-buttons">
            <GenericButton text="Cancel" onClick={this.props.cancel} quiet />
            <ButtonSubmit text="Save" disabled={!this.state.canSubmit} />
          </div>
        </Form>
      </ProfileSection>
    )
  }
}

OverviewForm.propTypes = {
  profile: RPT.shape({
    firstName: RPT.string,
    lastName: RPT.string,
    company: RPT.string,
    email: RPT.string,
    address: RPT.shape({
      address1: RPT.string,
      address2: RPT.string,
      postcode: RPT.string,
      city: RPT.string,
    }).isRequired,
    phoneNumbers: RPT.arrayOf(
      RPT.shape({
        number: RPT.string,
        type: RPT.string,
      }).isRequired,
    ),
  }),
  submit: RPT.func.isRequired,
  cancel: RPT.func.isRequired,
  validationErrors: RPT.object.isRequired, // eslint-disable-line react/forbid-prop-types
}

OverviewForm.defaultProps = {
  profile: {
    phoneNumbers: [],
    address: {},
  },
}

export default OverviewForm
