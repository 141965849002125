// @flow
import { WebAuth } from 'auth0-js'
import { call, cancel, put, fork, take } from 'redux-saga/effects'

import { getConfig } from 'app/config'
import { removeLocalItem } from 'utils/storage'

import { login, applicationReady, INIT, RESTART } from './actions'
import { authenticationFlow, ACCESS_TOKEN } from './login/sagas'
import { referenceDataFlow } from './referenceData/sagas'
import { connectAxios } from './saga/axiosWrapper'

const { auth0 } = getConfig()

function* appFlow(webAuth) {
  yield put(login())

  yield call(authenticationFlow, webAuth)
  yield call(referenceDataFlow)
  yield fork(connectAxios)

  yield put(applicationReady())
}

export function* mainFlow(): Generator<Object, void, Object> {
  const webAuth = new WebAuth({
    clientID: auth0.clientId,
    domain: auth0.domain,
    responseType: 'token',
    redirectUri: `${window.location.protocol}//${window.location.host}`,
    audience: 'https://api.elder.org',
    scope: 'openid email email_verified name roles',
  })

  yield take(INIT)
  const appFlowTask = yield fork(appFlow, webAuth)

  while (true) {
    // Check for app termination.
    yield take(RESTART)

    removeLocalItem(ACCESS_TOKEN)
    yield cancel(appFlowTask)
    webAuth.logout({
      returnTo: `${window.location.protocol}//${window.location.host}`,
      client_id: auth0.clientId,
    })
  }
}
