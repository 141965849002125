// @flow
import { makeActions as makeAutocompleteProfilesActions } from 'modules/autocompleteProfiles'
import { makeActions as makeActionsButtonSpinner } from 'modules/buttonSpinner'
import { makeActions as makePageEditActions } from 'modules/pageEdit'
import { addNamespace } from 'utils'

export const NAMESPACE = 'ACCOUNT_SUMMARY'
export const CARE_INFO_CARER = 'CARE_INFO_CARER'
export const READ_ONLY_ACCESS = 'READ_ONLY_ACCESS'

export const pageActions = makePageEditActions(NAMESPACE)
export const autocompleteProfilesActions =
  makeAutocompleteProfilesActions(NAMESPACE)
export const buttonSpinnerCareInfoCarerActions = makeActionsButtonSpinner(
  NAMESPACE,
  CARE_INFO_CARER,
)

export const readOnlyAccessSpinnerActions = makeActionsButtonSpinner(
  NAMESPACE,
  READ_ONLY_ACCESS,
)

export const DOWNLOAD_CARE_INFO_PDF = addNamespace(
  NAMESPACE,
  'DOWNLOAD_CARE_INFO_PDF',
)
export const DOWNLOAD_CARE_INFO_CARER_PDF = addNamespace(
  NAMESPACE,
  'DOWNLOAD_CARE_INFO_CARER_PDF',
)
export const CANCEL_ARCHIVE = addNamespace(NAMESPACE, 'CANCEL_ARCHIVE')
export const CANCEL_DIRECT_DEBIT = addNamespace(
  NAMESPACE,
  'CANCEL_DIRECT_DEBIT',
)
export const UPDATE_BASIC_ACCT_INFO = addNamespace(
  NAMESPACE,
  'UPDATE_BASIC_ACCT_INFO',
)
export const UPDATE_CONTACTS = addNamespace(NAMESPACE, 'UPDATE_CONTACTS')
export const UPDATE_CONTACTS_VALIDATION_FAIL = addNamespace(
  NAMESPACE,
  'UPDATE_CONTACTS_VALIDATION_FAIL',
)
export const UPDATE_BASIC_ACCT_INFO_VALIDATION_FAIL = addNamespace(
  NAMESPACE,
  'UPDATE_BASIC_ACCT_INFO_VALIDATION_FAIL',
)
export const UPDATE_BILLING_CYCLE = addNamespace(
  NAMESPACE,
  'UPDATE_BILLING_CYCLE',
)
export const UPDATE_BILLING_CYCLE_VALIDATION_FAIL = addNamespace(
  NAMESPACE,
  'UPDATE_BILLING_CYCLE_VALIDATION_FAIL',
)
export const UPDATE_PAYMENT_TYPE = addNamespace(
  NAMESPACE,
  'UPDATE_PAYMENT_TYPE',
)
export const UPDATE_PAYMENT_TYPE_VALIDATION_FAIL = addNamespace(
  NAMESPACE,
  'UPDATE_PAYMENT_TYPE_VALIDATION_FAIL',
)
export const UPDATE_FUNDING = addNamespace(NAMESPACE, 'UPDATE_FUNDING')
export const UPDATE_FUNDING_VALIDATION_FAIL = addNamespace(
  NAMESPACE,
  'UPDATE_FUNDING_VALIDATION_FAIL',
)
export const UPDATE_PAYMENT_ASSURANCE_REQUIRED = addNamespace(
  NAMESPACE,
  'UPDATE_PAYMENT_ASSURANCE_REQUIRED',
)

export const ADD_BLACKLISTED_CARER = addNamespace(
  NAMESPACE,
  'ADD_BLACKLISTED_CARER',
)
export const REMOVE_BLACKLISTED_CARER = addNamespace(
  NAMESPACE,
  'REMOVE_BLACKLISTED_CARER',
)
export const SET_ACTIVE_WORKFLOW = addNamespace(
  NAMESPACE,
  'SET_ACTIVE_WORKFLOW',
)
export const CLEAR_ACTIVE_WORKFLOW = addNamespace(
  NAMESPACE,
  'CLEAR_ACTIVE_WORKFLOW',
)
export const UPDATE_ACCOUNT_MANAGER = addNamespace(
  NAMESPACE,
  'UPDATE_ACCOUNT_MANAGER',
)
export const UPDATE_SALES_EXECUTIVE = addNamespace(
  NAMESPACE,
  'UPDATE_SALES_EXECUTIVE',
)
export const UPDATE_RECOMMENDED_RESOURCE = addNamespace(
  NAMESPACE,
  'UPDATE_RECOMMENDED_RESOURCE',
)
export const DELETE_CHANGE_HISTORY = addNamespace(
  NAMESPACE,
  'DELETE_CHANGE_HISTORY',
)
export const GET_ACCOUNT_MANAGERS = addNamespace(
  NAMESPACE,
  'GET_ACCOUNT_MANAGERS',
)
export const SET_ACCOUNT_MANAGERS = addNamespace(
  NAMESPACE,
  'SET_ACCOUNT_MANAGERS',
)
export const UPDATE_ARCHIVE_DETAILS = addNamespace(
  NAMESPACE,
  'UPDATE_ARCHIVE_DETAILS',
)
export const UPDATE_AUTOMATED_COMMUNICATIONS = addNamespace(
  NAMESPACE,
  'UPDATE_AUTOMATED_COMMUNICATIONS',
)
export const UPDATE_CAN_ACCESS_PORTAL_WITH_READ_ONLY_PERMISSIONS = addNamespace(
  NAMESPACE,
  'UPDATE_CAN_ACCESS_PORTAL_WITH_READ_ONLY_PERMISSIONS',
)
export const REACTIVATE_ACCOUNT = addNamespace(NAMESPACE, 'REACTIVATE_ACCOUNT')
export const UPDATE_APPLICATION_QUESTION = addNamespace(
  NAMESPACE,
  'UPDATE_APPLICATION_QUESTION',
)

export const CLEAR_LOADING = addNamespace(NAMESPACE, 'CLEAR_LOADING')

export const UPDATE_CARE_MODEL_TYPE = addNamespace(
  NAMESPACE,
  'UPDATE_CARE_MODEL_TYPE',
)

export const UPDATE_CARE_MODEL_ERROR = addNamespace(
  NAMESPACE,
  'UPDATE_CARE_MODEL_ERROR',
)

export const cancelArchive = () => ({
  type: CANCEL_ARCHIVE,
})

export const updateBasicAccountInfo = (data: Object) => ({
  type: UPDATE_BASIC_ACCT_INFO,
  data,
})

export const updateContacts = (data: Object) => ({
  type: UPDATE_CONTACTS,
  data,
})

export const updateContactsValidationFail = (data: Object) => ({
  type: UPDATE_CONTACTS_VALIDATION_FAIL,
  data,
})

export const updateExpectedStartDateValidationFail = (error: Object) => ({
  type: UPDATE_BASIC_ACCT_INFO_VALIDATION_FAIL,
  error,
})

export const updateBillingCycle = (data: Object) => ({
  type: UPDATE_BILLING_CYCLE,
  data,
})

export const updateBillingCycleValidationFail = (error: Object) => ({
  type: UPDATE_BILLING_CYCLE_VALIDATION_FAIL,
  error,
})

export const updatePaymentType = (data: Object) => ({
  type: UPDATE_PAYMENT_TYPE,
  data,
})

export const updatePaymentTypeValidationFail = (error: Object) => ({
  type: UPDATE_PAYMENT_TYPE_VALIDATION_FAIL,
  error,
})

export const updateFunding = (data: Object) => ({
  type: UPDATE_FUNDING,
  data,
})

export const updateFundingValidationFail = (error: Object) => ({
  type: UPDATE_FUNDING_VALIDATION_FAIL,
  error,
})

export const updatePaymentAssuranceRequired = (
  paymentAssuranceRequired: boolean,
) => ({
  type: UPDATE_PAYMENT_ASSURANCE_REQUIRED,
  paymentAssuranceRequired,
})

export const cancelDirectDebit = () => ({
  type: CANCEL_DIRECT_DEBIT,
})

export const addBlacklistedCarer = (
  accountId: string,
  professionalId: string,
  reason: Object,
) => ({
  type: ADD_BLACKLISTED_CARER,
  accountId,
  professionalId,
  reason,
})

export const removeBlacklistedCarer = (
  accountId: string,
  professionalId: string,
) => ({
  type: REMOVE_BLACKLISTED_CARER,
  accountId,
  professionalId,
})

export const setActiveWorkflow = (name: string, payload: Object) => ({
  type: SET_ACTIVE_WORKFLOW,
  name,
  payload,
})

export const clearActiveWorkflow = () => ({
  type: CLEAR_ACTIVE_WORKFLOW,
})

export const updateAccountManager = (email: string) => ({
  type: UPDATE_ACCOUNT_MANAGER,
  email,
})

export const getAccountManagers = () => ({
  type: GET_ACCOUNT_MANAGERS,
})

export const setAccountManagers = (accountManagers: Object) => ({
  type: SET_ACCOUNT_MANAGERS,
  accountManagers,
})

export const updateRecommendedResources = (recommendedResources: Object) => ({
  type: UPDATE_RECOMMENDED_RESOURCE,
  recommendedResources,
})

export const deleteChangeHistory = () => ({
  type: DELETE_CHANGE_HISTORY,
})

export const updateArchiveDetails = (details: Object) => ({
  type: UPDATE_ARCHIVE_DETAILS,
  details,
})

export const updateAutomatedCommunications = (isEnabled: boolean) => ({
  type: UPDATE_AUTOMATED_COMMUNICATIONS,
  isEnabled,
})

export const updateCanAccessPortalWithReadOnlyPermissions = (
  canAccessPortalWithReadOnlyPermissions: boolean,
) => ({
  type: UPDATE_CAN_ACCESS_PORTAL_WITH_READ_ONLY_PERMISSIONS,
  canAccessPortalWithReadOnlyPermissions,
})

export const updateApplicationQuestion = (value: Object) => ({
  type: UPDATE_APPLICATION_QUESTION,
  updateApplicationQuestion: value,
})

export const reactivateAccount = () => ({ type: REACTIVATE_ACCOUNT })

export const updateCareModelType = (
  accountId: string,
  regulatoryModel: Object,
) => ({
  type: UPDATE_CARE_MODEL_TYPE,
  accountId,
  regulatoryModel,
})

export const updateCareModelError = () => ({
  type: UPDATE_CARE_MODEL_ERROR,
})
