// @flow
import type { Saga } from 'redux-saga'
import { call, put, select, takeLatest } from 'redux-saga/effects'

import { applicationError } from 'features/snackbar/snackbar'
import {
  SEND_PASSWORD_RESET,
  pageActions,
} from 'routes/profile/summary/actions'
import { getJsProfileSummary } from 'routes/profile/summary/selectors'
import { postFlow, describeServiceError } from 'utils/services'

function* sendPasswordReset({
  email,
}: {
  +email: string,
}): Generator<Object, void, Object> {
  const endpoint = '/customer/account/portalv3/resetpassword'
  const profile = yield select(getJsProfileSummary)

  try {
    yield put(
      pageActions.set({
        ...profile,
        resetPasswordEmailStatus: 'REQUESTING',
      }),
    )
    yield call(postFlow, endpoint, { email })
    yield put(
      pageActions.set({
        ...profile,
        resetPasswordEmailStatus: 'REQUESTED',
      }),
    )
  } catch (error) {
    yield put(
      pageActions.set({
        ...profile,
        resetPasswordEmailStatus: 'FAILED, REFRESH TO REQUEST AGAIN.',
      }),
    )
    applicationError(
      describeServiceError(error, `Error with service call ${endpoint}`),
    )
  }
}

export default function* (): Saga<*> {
  yield takeLatest(SEND_PASSWORD_RESET, sendPasswordReset)
}
