// @flow
import type { File } from 'domain/file'
import type {
  RefereeDetails,
  Reference,
  ReferenceResponseDetails,
  Signature,
} from 'features/carerSkills/domain/referenceDetails'
import { yesNoNotGiven } from 'features/carerSkills/domain/referenceDetails'

export const GETTING = 'GETTING'
export const ACCEPTING = 'ACCEPTING'
export const UPDATING = 'UPDATING'
export const REJECTING = 'REJECTING'
export const DECIDING = 'DECIDING'
export const UPLOADING = 'UPLOADING'

export type LoadingStates =
  | 'ACCEPTING'
  | 'REJECTING'
  | 'DECIDING'
  | 'GETTING'
  | 'UPLOADING'

export const CARE = 'Care'
export const NONCARE = 'NonCare'
export const BREAK = 'Break'

export type WorkType = 'Care' | 'NonCare' | 'Break'

export type DecisionDetails = {|
  +startMonth: ?string,
  +endMonth: ?string,
  +conditions: Array<string>,
  +specialistSkills: Array<string>,
  +unsuitableForVulnerableAdults: ?boolean,
  +safeToWorkWithVulnerableAdultsOption: $Keys<typeof yesNoNotGiven>,
  +comments: ?string,
  +verified: ?boolean,
  +type: WorkType,
|}

export type ElderDecision = {|
  +details: DecisionDetails,
  +signature: Signature,
|}

export type WorkExperience = {|
  +createdAt: string,
  +details: {
    +startMonth: string,
    +effectiveEndMonth: string,
    +companyName: string,
    +typeOfCare?: string,
    +prettyTypeOfCare?: string,
    +conditions?: Array<string>,
    +prettyConditions?: Array<string>,
    +specialistSkills?: Array<string>,
    +prettySpecialistSkills?: Array<string>,
    +description: ?string,
    +refereeDetails?: RefereeDetails,
    +ongoing: ?boolean,
    +type: WorkType,
    +jobTitle?: string,
  },
|}

export type FullWorkExperience = {|
  +id: string,
  +experience: WorkExperience,
  +elderDecision: ?ElderDecision,
  +references: Array<Reference>,
  +archived?: boolean,
|}

export type WorkExperienceResponse = {|
  +experiences: Array<FullWorkExperience>,
  +nrReferees: number,
  +nrYearsExperienceTotal: number,
  +nrYearsExperienceVerified: number,
  +characterReferences: Array<Reference>,
|}

export type ReferenceUploadData = {|
  +files: Array<File>,
  +fields: ReferenceResponseDetails,
  +elderComment: string,
  +workExperienceId?: string,
|}
