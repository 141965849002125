// @flow

import * as Sentry from '@sentry/react'
import type { Saga } from 'redux-saga'

import { call } from 'elder/effects'
import type { ServiceEndpoint } from 'elder/services'
import { validate } from 'elder/validator'

import {
  fetchNoResponseBody,
  fetchJson,
  fetchText,
  fetchBlob,
  fetchFile,
} from 'app/saga/fetch'

import { generateSpanId } from './utils'

export type EndpointClient<TRequest, TResponse> = (TRequest) => Saga<TResponse>

/**
 * Creates a client proxy for an individual endpoint.
 */
function makeEndpointClient<TReq, TRes>(
  serviceEndpoint: ServiceEndpoint<TReq, TRes>,
): EndpointClient<TReq, TRes> {
  const {
    requestType,
    responseType,
    httpMethod,
    urlBuilder,
    headerBuilder,
    bodyBuilder,
    rawRequestBody,
    formDataBody,
    externalService,
  } = serviceEndpoint
  let bodyFormatter = null
  const defaultHeaders: { [string]: string } = {}

  switch (httpMethod) {
    case 'POST':
    case 'PUT':
    case 'PATCH':
      bodyFormatter = (request) => {
        const payload = bodyBuilder ? bodyBuilder(request) : request
        if (formDataBody) {
          const formData: Object = new FormData()
          for (const [key, value] of Object.entries(payload)) {
            formData.append(key, value)
          }
          return formData
        } else if (rawRequestBody && payload) {
          return payload
        } else if (payload) {
          return JSON.stringify(payload)
        } else {
          return null
        }
      }
      if (!formDataBody) {
        defaultHeaders['Content-Type'] = 'application/json'
      }
      break
    default:
      bodyFormatter = null
  }

  function* client(request: TReq): Saga<TRes> {
    const validRequest = validate(requestType, request)
    const endpoint = urlBuilder(validRequest)
    const body = bodyFormatter ? bodyFormatter(request) : null
    const headers = headerBuilder
      ? { ...defaultHeaders, ...headerBuilder(request) }
      : defaultHeaders

    if (!externalService) {
      const spanId = generateSpanId()
      const traceId = generateSpanId() + spanId

      headers['X-B3-TraceId'] = traceId
      headers['X-B3-SpanId'] = spanId

      Sentry.addBreadcrumb({ message: `trace-id: ${traceId} (${endpoint})` })
    }

    let bodyParser = fetchJson
    if (serviceEndpoint.noResponseBody) {
      bodyParser = fetchNoResponseBody
    } else if (serviceEndpoint.textResponseBody) {
      bodyParser = fetchText
    } else if (serviceEndpoint.blobResponseBody) {
      bodyParser = fetchBlob
    } else if (serviceEndpoint.fileResponseBody) {
      bodyParser = fetchFile
    }
    const response = yield* call(
      bodyParser,
      endpoint,
      httpMethod,
      headers,
      body,
      !serviceEndpoint.externalService,
    )

    return validate(responseType, response)
  }

  return client
}

export type ServiceClient<S: { [string]: ServiceEndpoint<any, any> }> = $ObjMap<
  S,
  typeof makeEndpointClient,
>

/**
 * Creates a client proxy for en entire service (collection of service endpoints).
 */
export default function createServiceClient<
  S: { [string]: ServiceEndpoint<any, any> },
>(service: S): ServiceClient<S> {
  const serviceClient = {}
  Object.keys(service).forEach((endpointKey) => {
    serviceClient[endpointKey] = makeEndpointClient(service[endpointKey])
  })
  return serviceClient
}
