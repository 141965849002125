// @flow
/* eslint-disable flowtype/no-weak-types */
import { createSelector } from 'reselect'

import { Instants } from 'domain/instant'
import type { Note } from 'features/notes/domain'
import { getUniqueNotesEntityId } from 'features/notes/domain'

export type EntityKeyProps = {
  +notesEntityType: string,
  +notesEntityId: string,
  +noteId?: string,
}

const toJs = (immutable) => immutable && immutable.toJS()

const getNotesState = (state) => state.get('notesState')

const getNotesStateByEntity = (
  state: any,
  { notesEntityType, notesEntityId }: EntityKeyProps,
) =>
  getNotesState(state)
    .get('notesByEntity')
    .get(getUniqueNotesEntityId(notesEntityType, notesEntityId))

const makeGetNotesByEntitySelector =
  <T>(
    subSelector: (any, EntityKeyProps) => T,
  ): (() => (any, EntityKeyProps) => T) =>
  () =>
    createSelector([getNotesStateByEntity, (props) => props], subSelector)

export const makeGetNotesStatusByEntity = makeGetNotesByEntitySelector(
  (notesEntityState) =>
    (notesEntityState && notesEntityState.get('status')) || 'INITIAL',
)

export const makeGetNotesByEntity = makeGetNotesByEntitySelector(
  (notesEntityState): Array<Note> => {
    const notes = toJs(notesEntityState && notesEntityState.get('notes')) || []
    return notes.map(({ createdAt, ...restOfProps }) => ({
      createdAt: Instants.ofString(createdAt),
      ...restOfProps,
    }))
  },
)

export const savingSelector = createSelector([getNotesState], (notesState) =>
  notesState.get('savingStatus'),
)

export const notesUpdatingSelector = createSelector(
  [getNotesState],
  (notesState) => toJs(notesState.get('notesUpdating')),
)

export const noteToEditSelector = createSelector(
  [getNotesState],
  (notesState): ?string => notesState.get('editNoteModal'),
)
