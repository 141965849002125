// @flow
import { makeAction } from 'elder/actions'

import type { NoteRequestParams, Note } from 'features/notes/domain'
import { NAMESPACE } from 'features/notes/store/index'
import { addNamespace } from 'utils'

export const GET_NOTES = addNamespace(NAMESPACE, 'GET_NOTES')
export const GET_NOTES_COMPLETED = addNamespace(
  NAMESPACE,
  'GET_NOTES_COMPLETED',
)
export const GET_NOTES_FAILED = addNamespace(NAMESPACE, 'GET_NOTES_FAILED')
export const SAVE_NOTE = addNamespace(NAMESPACE, 'SAVE_NOTE')
export const SAVING_NOTE = addNamespace(NAMESPACE, 'SAVING_NOTE')
export const SAVING_NOTE_COMPLETED = addNamespace(
  NAMESPACE,
  'SAVING_NOTE_COMPLETED',
)
export const SAVING_NOTE_FAILED = addNamespace(NAMESPACE, 'SAVING_NOTE_FAILED')
export const SET_PINNED_NOTE = addNamespace(NAMESPACE, 'SET_PINNED_NOTE')
export const SET_ARCHIVED_NOTE = addNamespace(NAMESPACE, 'SET_ARCHIVED_NOTE')
export const COMPLETE_ACTION = addNamespace(NAMESPACE, 'COMPLETE_ACTION')
export const EDIT_NOTE = addNamespace(NAMESPACE, 'EDIT_NOTE')
export const EDIT_NOTE_MODAL = addNamespace(NAMESPACE, 'EDIT_NOTE_MODAL')

export const getNotes = makeAction(
  GET_NOTES,
  ({
    notesEntityType,
    notesEntityId,
  }: {
    +notesEntityType: string,
    +notesEntityId: string,
  }) => ({
    notesEntityType,
    notesEntityId,
  }),
)

export const getNotesCompleted = makeAction(
  GET_NOTES_COMPLETED,
  ({
    notesEntityType,
    notesEntityId,
    notes,
  }: {
    +notesEntityType: string,
    +notesEntityId: string,
    +notes: Array<Note>,
  }) => ({
    notesEntityType,
    notesEntityId,
    notes,
  }),
)

export const getNotesFailed = makeAction(
  GET_NOTES_FAILED,
  ({
    notesEntityType,
    notesEntityId,
  }: {
    +notesEntityType: string,
    +notesEntityId: string,
  }) => ({
    notesEntityType,
    notesEntityId,
  }),
)

export const saveNote = makeAction(
  SAVE_NOTE,
  ({
    notesEntityType,
    notesEntityId,
    noteParams,
    onSuccess,
  }: {|
    +notesEntityType: string,
    +notesEntityId: string,
    +noteParams: NoteRequestParams,
    +onSuccess?: () => void,
  |}) => ({
    notesEntityType,
    notesEntityId,
    noteParams,
    onSuccess,
  }),
)
export const savingNote = makeAction(SAVING_NOTE)

export const savingNoteCompleted = makeAction(SAVING_NOTE_COMPLETED)

export const savingNoteFailed = makeAction(SAVING_NOTE_FAILED)

export const setPinnedNote = makeAction(
  SET_PINNED_NOTE,
  ({
    notesEntityType,
    notesEntityId,
    noteId,
    pinned,
  }: {|
    +notesEntityType: string,
    +notesEntityId: string,
    +noteId: string,
    +pinned: boolean,
  |}) => ({ notesEntityType, notesEntityId, noteId, pinned }),
)

export const setArchived = makeAction(
  SET_ARCHIVED_NOTE,
  ({
    notesEntityType,
    notesEntityId,
    noteId,
    archive,
  }: {|
    +notesEntityType: string,
    +notesEntityId: string,
    +noteId: string,
    +archive: boolean,
  |}) => ({ notesEntityType, notesEntityId, noteId, archive }),
)

export const editNoteModal = makeAction(
  EDIT_NOTE_MODAL,
  ({ noteId, isOpen }: {| +noteId: string, +isOpen: boolean |}) => ({
    noteId,
    isOpen,
  }),
)

export const editNote = makeAction(
  EDIT_NOTE,
  ({
    notesEntityType,
    notesEntityId,
    noteId,
    noteParams,
  }: {|
    +notesEntityType: string,
    +notesEntityId: string,
    +noteId: string,
    +noteParams: NoteRequestParams,
  |}) => ({ notesEntityType, notesEntityId, noteId, noteParams }),
)

export const completeAction = makeAction(
  COMPLETE_ACTION,
  ({ noteId }: {| +noteId: string |}) => ({ noteId }),
)
