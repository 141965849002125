// @flow
import { makeAction } from 'elder/actions'

import { addNamespace } from 'utils'

export const NAMESPACE = 'CARER_SKILLS'

export const GET_SKILLS_AND_EXPERTISE = addNamespace(
  NAMESPACE,
  'GET_SKILLS_AND_EXPERTISE',
)

export const getSkillsAndExpertise = makeAction(
  GET_SKILLS_AND_EXPERTISE,
  (carerId: string) => ({
    carerId,
  }),
)

export const UPDATE_SKILLS_AND_EXPERTISE = addNamespace(
  NAMESPACE,
  'UPDATE_SKILLS_AND_EXPERTISE',
)

export const updateSkillsAndExpertise = (value: Object, carerId: string) => ({
  type: UPDATE_SKILLS_AND_EXPERTISE,
  value,
  carerId,
})
