// @flow
import { createSelector } from 'reselect'

import {
  getPrettyMedicalConditions,
  getPrettyFeedingTubeTypes,
  getPrettyFoodDeliveryTypes,
  getPrettyWeightUnits,
  getPrettyStomaTypes,
  getPrettyCatheterTypes,
  getPrettyCareRecipientStatus,
} from 'app/referenceData'
import type { RecipientDisplay } from 'features/recipient/domain'
import { NAMESPACE } from 'features/recipient/recipientExisting/actions'
import { makeSelectors as makeButtonSpinnerSelectors } from 'modules/buttonSpinner'
import { makeSelectors } from 'modules/pageEdit'
import { getPrettyName } from 'utils/accountDecorators'
import { prettyCompactDate } from 'utils/dateDecorators'

const getAccountRecipientState = (state) => state.get(NAMESPACE)

export const changingStatusSelectors = makeButtonSpinnerSelectors((state) =>
  getAccountRecipientState(state).get('changingStatus'),
)

export const getActiveWorkflow = createSelector(
  [getAccountRecipientState],
  (state) => state.get('activeWorkflow').toJS(),
)

export const pageSelectors = makeSelectors((state) =>
  getAccountRecipientState(state).get('recipient'),
)
const getRecipientValidationError = (state) =>
  getAccountRecipientState(state).get('recipientValidationError')

const getJsAccountRecipient = createSelector(
  [pageSelectors.get],
  (accountRecipient) => accountRecipient.toJS(),
)

export const getRecipientId = createSelector(
  [getJsAccountRecipient],
  (accountRecipient) => accountRecipient.recipientId || '',
)

export const getAccountRecipient = createSelector(
  [
    getJsAccountRecipient,
    getPrettyMedicalConditions,
    getPrettyFeedingTubeTypes,
    getPrettyFoodDeliveryTypes,
    getPrettyStomaTypes,
    getPrettyCatheterTypes,
    getPrettyWeightUnits,
    getPrettyCareRecipientStatus,
  ],
  (
    accountRecipient,
    prettyMedicalConditions,
    prettyFeedingTubeTypes,
    prettyFoodDeliveryTypes,
    prettyStomaTypes,
    prettyCatheterTypes,
    prettyWeightUnits,
    prettyCareRecipientStatus,
  ): RecipientDisplay => {
    const medicalConditionsInfoPretty = (
      accountRecipient.medicalConditionsInfo || []
    ).map((medicalCondition) => ({
      ...medicalCondition,
      prettyMedicalCondition: prettyMedicalConditions(
        medicalCondition.medicalCondition,
      ),
    }))

    const prettyFeedingTubeType =
      (accountRecipient.feedingTubeType &&
        prettyFeedingTubeTypes(accountRecipient.feedingTubeType)) ||
      ''

    const prettyFoodDeliveryType =
      (accountRecipient.foodDeliveryType &&
        prettyFoodDeliveryTypes(accountRecipient.foodDeliveryType)) ||
      ''

    const prettyWeight =
      (accountRecipient.weight &&
        `${accountRecipient.weight.weight} ${prettyWeightUnits(
          accountRecipient.weight.weightUnit,
        )}`) ||
      ''

    const prettyStomaType =
      (accountRecipient.stomaType &&
        prettyStomaTypes(accountRecipient.stomaType)) ||
      ''

    const prettyCatheterType =
      (accountRecipient.catheterType &&
        prettyCatheterTypes(accountRecipient.catheterType)) ||
      ''

    return {
      ...accountRecipient,
      prettyStatus: prettyCareRecipientStatus(accountRecipient.status),
      prettyName:
        (accountRecipient.name && getPrettyName(accountRecipient.name)) || '',
      prettyDateOfBirth: prettyCompactDate(accountRecipient.dateOfBirth) || '',
      prettyMedicalConditionsInfo: medicalConditionsInfoPretty,
      prettyFeedingTubeType,
      prettyFoodDeliveryType,
      prettyCatheterType,
      prettyStomaType,
      prettyWeight,
    }
  },
)

export const getJsRecipientValidationErrors = createSelector(
  [getRecipientValidationError],
  (recipientValidatioError) => ({
    recipientInfo: recipientValidatioError.get('recipientInfo').toJS(),
  }),
)
