// @flow
import type { Saga } from 'redux-saga'
import { fork } from 'redux-saga/effects'

import { makeSagas as makeSagasProfiles } from 'modules/autocompleteProfiles'
import { NAMESPACE } from 'routes/account/summary/actions'

import blacklistedCarers from './blacklistedCarers'
import changeHistory from './changeHistory'
import recommendedResources from './recommendedResources'
import relationshipContacts from './relationshipContacts'
import { watchUpdateArchiveDetails } from './updateArchiveDetails'
import { watchAccount } from './watchAccount'
import { watchAccountCancelArchive } from './watchAccountCancelArchive'
import { watchAccountCancelDirectDebit } from './watchAccountCancelDirectDebit'
import { watchAccountStatus } from './watchAccountStatus'
import { watchBasicAccountInfo } from './watchBasicAccountInfo'
import { watchBillingCycle } from './watchBillingCycle'
import { watchDownloadCareInfoCarerPdf } from './watchDownloadCareInfoCarerPdf'
import { watchFunding } from './watchFunding'
import { watchPaymentType } from './watchPaymentType'

const watchAutocompleteProfiles = makeSagasProfiles(NAMESPACE)

export function* summarySaga(): Saga<*> {
  yield fork(watchAccount)
  yield fork(watchAccountStatus)
  yield fork(watchAccountCancelArchive)
  yield fork(watchAccountCancelDirectDebit)
  yield fork(watchBasicAccountInfo)
  yield fork(watchBillingCycle)
  yield fork(watchPaymentType)
  yield fork(watchFunding)
  yield fork(watchDownloadCareInfoCarerPdf)
  yield fork(watchAutocompleteProfiles)
  yield fork(blacklistedCarers)
  yield fork(relationshipContacts)
  yield fork(recommendedResources)
  yield fork(changeHistory)
  yield fork(watchUpdateArchiveDetails)
}

export default summarySaga
