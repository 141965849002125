// @flow
import { makeActions as makeActionsButtonSpinner } from 'modules/buttonSpinner'
import { makeActions as makePageActions } from 'modules/pageEdit'
import { addNamespace } from 'utils'

export const NAMESPACE = 'RECIPIENTS'
export const pageActions = makePageActions(NAMESPACE)

export const UPDATE_RECIPIENT = addNamespace(NAMESPACE, 'UPDATE_RECIPIENT')

export const UPDATE_RECIPIENT_VALIDATION_FAIL = addNamespace(
  NAMESPACE,
  'UPDATE_RECIPIENT_VALIDATION_FAIL',
)
export const DELETE_RECIPIENT = addNamespace(NAMESPACE, 'DELETE_RECIPIENT')
export const CHANGE_STATUS = addNamespace(NAMESPACE, 'CHANGE_STATUS')

export const CHANGING_STATUS = 'CHANGING_STATUS'
export const changingStatusActions = makeActionsButtonSpinner(
  NAMESPACE,
  CHANGING_STATUS,
)

export const SET_ACTIVE_WORKFLOW = addNamespace(
  NAMESPACE,
  'SET_ACTIVE_WORKFLOW',
)

export const CLEAR_ACTIVE_WORKFLOW = addNamespace(
  NAMESPACE,
  'CLEAR_ACTIVE_WORKFLOW',
)

export const updateRecipient = (data: Object) => ({
  type: UPDATE_RECIPIENT,
  data,
})

export const updateRecipientValidationFail = (error: Object) => ({
  type: UPDATE_RECIPIENT_VALIDATION_FAIL,
  error,
})

export const deleteRecipient = () => ({
  type: DELETE_RECIPIENT,
})

export const changeStatus = ({ status }: { +status: string }) => ({
  type: CHANGE_STATUS,
  status,
})

export const setActiveWorkflow = (name: string, payload: Object) => ({
  type: SET_ACTIVE_WORKFLOW,
  name,
  payload,
})

export const clearActiveWorkflow = () => ({ type: CLEAR_ACTIVE_WORKFLOW })
