// @flow
import Immutable from 'immutable'

import {
  GET_AUTOCOMPLETE_PROFILES,
  GET_AUTOCOMPLETE_PROFILES_FAIL,
  SET_AUTOCOMPLETE_PROFILES,
} from 'modules/autocompleteProfiles/actions'
import { addNamespace } from 'utils'

export const makeReducer = (namespace: string) => {
  const initialState = Immutable.List()
  const getAutocompleteProfiles = addNamespace(
    namespace,
    GET_AUTOCOMPLETE_PROFILES,
  )
  const getAutocompleteProfilesFail = addNamespace(
    namespace,
    GET_AUTOCOMPLETE_PROFILES_FAIL,
  )
  const setAutocompleteProfiles = addNamespace(
    namespace,
    SET_AUTOCOMPLETE_PROFILES,
  )

  return (state: Object = initialState, action: Object) => {
    switch (action.type) {
      case getAutocompleteProfiles:
      case getAutocompleteProfilesFail:
        return initialState
      case setAutocompleteProfiles:
        return Immutable.fromJS(action.autocompleteProfiles)
      default:
        return state
    }
  }
}

export default makeReducer
