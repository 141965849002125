// @flow
import type { Option } from 'domain/option'
import type { Section } from 'features/notes/domain'

export const createTagOptions = (...tags: Array<string>): Array<Option> =>
  tags.map((text) => ({
    label: text,
    value: text,
  }))

export const createAdapter = (
  sections: Array<Section>,
  values: Object,
): string =>
  sections
    .reduce((accumulator, { fields, title }) => {
      const fieldsString = fields
        .reduce((nestedAccumulator, { label, name, type }) => {
          const value = values[name]
          if (type !== 'CHECKBOX' && value) {
            nestedAccumulator.push(`###${label}\n${value}`)
          } else if (type === 'CHECKBOX' && value) {
            nestedAccumulator.push(`- [x]${label}`)
          } else if (type === 'CHECKBOX' && !value) {
            nestedAccumulator.push(`- [ ]${label}`)
          }
          return nestedAccumulator
        }, [])
        .join('\n\n')

      let sectionString = title ? `##${title}` : ''

      if (fieldsString.length > 0) {
        sectionString = [sectionString, fieldsString].join('\n')
        accumulator.push(sectionString)
      }

      return accumulator
    }, [])
    .join('\n\n')
