// @flow
import React from 'react'

import type { Match } from 'react-router'

import { AccountContainer } from 'routes/account'

import { NAMESPACE } from './actions'
import ChangelogContainer from './ChangelogContainer'
import reducer from './reducers'
import saga from './sagas'

type Props = {|
  +match: Match,
|}

const ChangelogContainerWithHeader = ({ match }: Props) => (
  <AccountContainer match={match}>
    <ChangelogContainer />
  </AccountContainer>
)

export {
  ChangelogContainerWithHeader as ChangelogContainer,
  saga,
  reducer,
  NAMESPACE,
}
