// @flow

import type { DocumentToUpload } from 'features/recipientDocuments/domain'
import { addNamespace } from 'utils'

export const NAMESPACE = 'RECIPIENT_DOCUMENTS'

export const UPLOAD = addNamespace(NAMESPACE, 'UPLOAD')

export const DOWNLOAD = addNamespace(NAMESPACE, 'DOWNLOAD')

export const UPLOAD_STARTED = addNamespace(NAMESPACE, 'UPLOAD_STARTED')

export const UPLOAD_COMPLETED = addNamespace(NAMESPACE, 'UPLOAD_COMPLETED')

export const uploadRecipientDocuments = ({
  accountId,
  recipientId,
  document,
  getRecipientInfo,
}: {
  +accountId: string,
  +recipientId: string,
  +document: DocumentToUpload,
  +getRecipientInfo: () => void,
}) => ({
  type: UPLOAD,
  accountId,
  recipientId,
  document,
  getRecipientInfo,
})

export const downloadRecipientDocuments = (documentId: string) => ({
  type: DOWNLOAD,
  documentId,
})

export const uploadStarted = () => ({ type: UPLOAD_STARTED })

export const uploadCompleted = () => ({ type: UPLOAD_COMPLETED })
