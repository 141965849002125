// @flow
import { push } from 'connected-react-router'
import type { Saga } from 'redux-saga'

import { takeLatest, put, call } from 'elder/effects'

import { billingAccountsClient } from 'app/saga/serviceClients'
import type {
  BillingAccountPdfDetails,
  PayeeDetails,
  PaymentMethodsPayloads,
} from 'features/billingAccounts/domain'
import { paymentMethods } from 'features/billingAccounts/domain'
import type { Cycle } from 'features/billingAccounts/store/actions'
import {
  getAccountDetails,
  updateDisplayName,
  updatePayeeDetails,
  updateBillingCycle,
  editPaymentMethod,
  setAccountDetails,
  cancelDirectDebit,
  createNewBillingAccount,
  editInvoiceDetails,
  setUpdatedDirectDebitAccountDetails,
  setUpdatedBankTransferAccountDetails,
  setUpdatedManualInvoicingAccountDetails,
  setUpdatedMandateAccountDetails,
  setUpdatedStripeAccountDetails,
} from 'features/billingAccounts/store/actions'
import {
  applicationError,
  applicationSuccess,
} from 'features/snackbar/snackbar'
import * as routes from 'routes'
import { formatLink } from 'utils/links'
import { describeServiceError } from 'utils/services'

const serviceError = (error, message) =>
  applicationError(describeServiceError(error, message))

function* handleGetAccountDetails({ id }): Saga<*> {
  try {
    const accountDetails = yield* call(
      billingAccountsClient.getBillingAccount,
      {
        id,
      },
    )
    yield* put(setAccountDetails(accountDetails))
  } catch (error) {
    serviceError(error, `Failed getting account details for ${id}`)
  }
}

function* handleUpdateDisplayName({
  id,
  displayName,
}: {
  +id: string,
  +displayName: ?string,
}): Saga<*> {
  try {
    const accountDetailsUpdated = yield* call(
      billingAccountsClient.updateDisplayName,
      {
        id,
        displayName,
      },
    )

    yield* put(setAccountDetails(accountDetailsUpdated))
    applicationSuccess({
      title: 'Success!',
      message: 'Display name successfully updated',
    })
  } catch (error) {
    serviceError(error, `Failed updating display name for ${id}`)
  }
}

function* handleUpdatePayeeDetails({
  id,
  details,
}: {
  +id: string,
  +details: PayeeDetails,
}): Saga<*> {
  try {
    const accountDetailsUpdated = yield* call(
      billingAccountsClient.updatePayeeDetails,
      {
        id,
        details,
      },
    )

    yield* put(setAccountDetails(accountDetailsUpdated))
    applicationSuccess({
      title: 'Success!',
      message: 'Account details successfully updated',
    })
  } catch (error) {
    serviceError(error, `Failed updating account details for ${id}`)
  }
}

function* handleUpdateBillingCycle({
  id,
  cycle,
}: {
  +id: string,
  +cycle: Cycle,
}): Saga<*> {
  try {
    const accountDetailsUpdated = yield* call(
      billingAccountsClient.updateBillingCycle,
      {
        id,
        cycle,
      },
    )

    yield* put(setAccountDetails(accountDetailsUpdated))
    applicationSuccess({
      title: 'Success!',
      message: `Billing account cycle successfully updated to ${cycle.type}`,
    })
  } catch (error) {
    serviceError(error, `Failed updating billing cycle for ${id}`)
  }
}

function* handleEditPaymentMethod({
  id,
  paymentMethod,
}: {
  +id: string,
  +paymentMethod: PaymentMethodsPayloads,
}): Saga<*> {
  try {
    if (paymentMethod.type === paymentMethods.mandate) {
      const accountDetailsUpdated = yield* call(
        billingAccountsClient.editDirectDebitMandate,
        {
          id,
          payload: paymentMethod.payload,
        },
      )
      yield* put(setUpdatedMandateAccountDetails(accountDetailsUpdated))
    } else if (paymentMethod.type === paymentMethods.stripe) {
      const accountDetailsUpdated = yield* call(
        billingAccountsClient.editStripe,
        {
          id,
          payload: paymentMethod.payload,
        },
      )
      yield* put(setUpdatedStripeAccountDetails(accountDetailsUpdated))
    } else if (paymentMethod.type === paymentMethods.directDebit) {
      const accountDetailsUpdated = yield* call(
        billingAccountsClient.editDirectDebit,
        {
          id,
          payload: paymentMethod.payload,
        },
      )
      yield* put(setUpdatedDirectDebitAccountDetails(accountDetailsUpdated))
    } else if (paymentMethod.type === paymentMethods.bankTransfer) {
      const accountDetailsUpdated = yield* call(
        billingAccountsClient.editBankTransfer,
        {
          id,
          payload: paymentMethod.payload,
        },
      )

      yield* put(setUpdatedBankTransferAccountDetails(accountDetailsUpdated))
    } else if (paymentMethod.type === paymentMethods.stripeBankTransfer) {
      const accountDetailsUpdated = yield* call(
        billingAccountsClient.editStripeBankTransfer,
        {
          id,
          payload: paymentMethod.payload,
        },
      )

      yield* put(setUpdatedBankTransferAccountDetails(accountDetailsUpdated))
    } else if (paymentMethod.type === paymentMethods.manualInvoicing) {
      const accountDetailsUpdated = yield* call(
        billingAccountsClient.editManualInvoicing,
        {
          id,
          payload: paymentMethod.payload,
        },
      )
      yield* put(setUpdatedManualInvoicingAccountDetails(accountDetailsUpdated))
    }
    yield* put(getAccountDetails(id))
    applicationSuccess({
      title: 'Success!',
      message: `Payment method successfully updated to ${
        paymentMethods[paymentMethod.type]
      }`,
    })
  } catch (error) {
    serviceError(error, 'Failed while updating payment type')
  }
}

function* handleCancelDirectDebit({ id }): Saga<*> {
  try {
    const accountDetailsUpdated = yield* call(
      billingAccountsClient.cancelDirectDebit,
      { id },
    )
    yield* put(setAccountDetails(accountDetailsUpdated))
    applicationSuccess({
      title: 'Success!',
      message: 'Direct debit successfully cancelled',
    })
  } catch (error) {
    serviceError(error, 'Failed while cancelling direct debit')
  }
}

function* handleCreateNewBillingAccount(): Saga<*> {
  try {
    const { id } = yield* call(billingAccountsClient.createBillingAccount)

    yield* put(
      push(formatLink(routes.billingAccountDetails, { accountId: id })),
    )

    applicationSuccess({
      title: 'Success!',
      message: 'New billing account successfully created',
    })
  } catch (error) {
    serviceError(error, 'Failed to create new billing account')
  }
}

function* handleEditInvoiceDetails({
  billingAccountId,
  pdfPreview,
}: {
  +billingAccountId: string,
  +pdfPreview: BillingAccountPdfDetails,
}): Saga<*> {
  try {
    const accountDetailsUpdated = yield* call(
      billingAccountsClient.editInvoiceDetails,
      {
        billingAccountId,
        pdfPreview,
      },
    )

    yield* put(setAccountDetails(accountDetailsUpdated))
    applicationSuccess({
      title: 'Success!',
      message: 'Invoice details successfuly updated',
    })
  } catch (error) {
    serviceError(error, 'Failed to edit invoice details')
  }
}

export function* billingAccountsSaga(): Saga<*> {
  yield* takeLatest(getAccountDetails, handleGetAccountDetails)
  yield* takeLatest(updateDisplayName, handleUpdateDisplayName)
  yield* takeLatest(updatePayeeDetails, handleUpdatePayeeDetails)
  yield* takeLatest(updateBillingCycle, handleUpdateBillingCycle)
  yield* takeLatest(editPaymentMethod, handleEditPaymentMethod)
  yield* takeLatest(cancelDirectDebit, handleCancelDirectDebit)
  yield* takeLatest(createNewBillingAccount, handleCreateNewBillingAccount)
  yield* takeLatest(editInvoiceDetails, handleEditInvoiceDetails)
}
