// @flow
// $FlowOptOut
import React, { useEffect } from 'react'

import { ContentPage } from 'components'
import { BreadcrumbsBar } from 'features/header/BreadcrumbsBar'
import * as routes from 'routes'
import OverviewForm from 'routes/profiles/shared/OverviewForm'

type Props = {
  postNewProfile: () => void,
  clearValidation: () => void,
  cancel: () => void,
  validationErrors: Object,
}

export const ProfileNew = ({
  postNewProfile,
  cancel,
  validationErrors,
  clearValidation,
}: Props) => {
  useEffect(() => {
    clearValidation()
  }, [])

  return (
    <>
      <BreadcrumbsBar
        breadcrumbs={[
          { title: 'Profiles', to: routes.profiles },
          {
            title: 'New',
            to: routes.newProfile,
          },
        ]}
      />
      <ContentPage>
        <h2>Create New Profile</h2>
        <OverviewForm
          submit={postNewProfile}
          cancel={cancel}
          validationErrors={validationErrors}
        />
      </ContentPage>
    </>
  )
}
