// @flow
import { makeAction } from 'elder/actions'

import { addNamespace } from 'utils'

export const NAMESPACE = 'ELDER_USERS'

export const UPDATE_ACCOUNT_EXECUTIVE_HIERARCHY = addNamespace(
  NAMESPACE,
  'UPDATE_ACCOUNT_EXECUTIVE_HIERARCHY',
)

export const UPDATE_ACCOUNT_EXECUTIVE_HIERARCHY_ERROR = addNamespace(
  NAMESPACE,
  'UPDATE_ACCOUNT_EXECUTIVE_HIERARCHY_ERROR',
)

export const GENERATE_ACCOUNT_EXECUTIVE_HIERARCHY = addNamespace(
  NAMESPACE,
  'GENERATE_ACCOUNT_EXECUTIVE_HIERARCHY',
)

export const GENERATE_ACCOUNT_EXECUTIVE_HIERARCHY_ERROR = addNamespace(
  NAMESPACE,
  'GENERATE_ACCOUNT_EXECUTIVE_HIERARCHY_ERROR',
)

export const updateAccountExecutiveHierarchy = makeAction(
  UPDATE_ACCOUNT_EXECUTIVE_HIERARCHY,
  (accountId: string, hierarchy: Array<string>) => ({
    accountId,
    hierarchy,
  }),
)

export const updateAccountExecutiveHierarchyError = makeAction(
  UPDATE_ACCOUNT_EXECUTIVE_HIERARCHY_ERROR,
  (error: Object) => ({
    error,
  }),
)

export const generateAccountExecutiveHierarchy = makeAction(
  GENERATE_ACCOUNT_EXECUTIVE_HIERARCHY,
  (accountId: string) => ({
    accountId,
  }),
)

export const generateAccountExecutiveHierarchyError = makeAction(
  GENERATE_ACCOUNT_EXECUTIVE_HIERARCHY,
  (error: Object) => ({
    error,
  }),
)
