// @flow
import { makeAction } from 'elder/actions'

import { addNamespace } from 'utils'

export const NAMESPACE = 'CARER_FINANCES'

export const GET_CARER_FINANCES = addNamespace(NAMESPACE, 'GET_CARER_FINANCES')

export const UPDATE_BANK_DETAILS = addNamespace(
  NAMESPACE,
  'UPDATE_BANK_DETAILS',
)

export const UPDATE_BANK_DETAILS_VALIDATION_FAIL = addNamespace(
  NAMESPACE,
  'UPDATE_BANK_DETAILS_VALIDATION_FAIL',
)

export const getCarerFinances = makeAction(
  GET_CARER_FINANCES,
  (carerId: string) => ({
    carerId,
  }),
)

export const updateBankDetails = (data: Object, carerId: string) => ({
  type: UPDATE_BANK_DETAILS,
  data,
  carerId,
})

export const updateBankDetailsValidationFail = (error: Object) => ({
  type: UPDATE_BANK_DETAILS_VALIDATION_FAIL,
  error,
})
