import { createContext, useContext } from 'react'

import type {
  PlacementAddon,
  PlacementOneOffPaymentDetails,
  SlotDetails,
  SolutionBillingSetup,
  SolutionPreviewResponse,
} from '@elder/et-facade-et'
import type { Moment } from 'moment'

interface EditSolutionWorkflow {
  name: 'editSolution'
}

interface NewSolutionWorkflow {
  name: 'newSolutionControl'
}

interface ActivateSolutionWorkflow {
  name: 'activateSolution'
}

interface ChangeDateWorkflow {
  name: 'changeDateControl'
}

interface PreviewConfirmation extends SolutionPreviewResponse {
  name: 'previewConfirmation'
  matchingSettingsEnabled: boolean
}

interface CancelSolutionWorkflow {
  name: 'cancelSolution'
}

interface AddPlacementWorkflow {
  name: 'addPlacement'
  slot: SlotDetails
}

interface EditPlacementWorkflow {
  name: 'editPlacement'
  slot: SlotDetails
}

interface AddSuspensionWorkflow {
  name: 'addSuspension'
  slot: SlotDetails
}

interface EditSuspensionWorkflow {
  name: 'editSuspension'
  slot: SlotDetails
}

interface AddVisitPlacementWorkflow {
  name: 'addVisitPlacement'
  slot: SlotDetails
  date?: Moment
}

interface EditVisitPlacementWorkflow {
  name: 'editVisitPlacement'
  slot: SlotDetails
}

interface DeleteVisitPlacementWorkflow {
  name: 'deleteVisitPlacement'
  slot: SlotDetails
}

interface AddAddOnWorkflow {
  name: 'addEditDeleteAddOn'
  slot: SlotDetails
  mode: 'add'
  bounds: 'slot' | 'placement'
}

interface EditDeleteAddOnWorkflow {
  name: 'addEditDeleteAddOn'
  slot: SlotDetails
  mode: 'edit'
  bounds: 'slot' | 'placement'
  addOn: PlacementAddon
}

type AddEditDeleteAddOnWorkflow = AddAddOnWorkflow | EditDeleteAddOnWorkflow

interface AddOneOffWorkflow {
  name: 'addEditDeleteOneOff'
  mode: 'add'
}

interface EditDeleteOneOffWorkflow {
  name: 'addEditDeleteOneOff'
  mode: 'edit'
  oneOff: PlacementOneOffPaymentDetails
}

type AddEditDeleteOneOffWorkflow = AddOneOffWorkflow | EditDeleteOneOffWorkflow

interface MessageCarersWorkflow {
  name: 'messageCarers'
}

interface UnassignPlacementWorkflow {
  name: 'unassignPlacementCarer'
}

interface ViewBillingSetupWorkflow {
  name: 'viewBillingSetup'
  billingSetup: SolutionBillingSetup
}

interface CarerBiddingWorkflow {
  name: 'carerBidding'
  selectedCarers: Array<string>
  lowestPossibleBid?: number
  highestPossibleBid?: number
  precision: number
  onSubmit: (bid: number) => void
}

interface EditMatchingNoteWorkflow {
  name: 'editNote'
}

interface EditHoursBulkWorkflow {
  name: 'editHoursBulk'
  placementIds: string[]
}

export type Workflow =
  | EditSolutionWorkflow
  | NewSolutionWorkflow
  | ActivateSolutionWorkflow
  | ChangeDateWorkflow
  | PreviewConfirmation
  | CancelSolutionWorkflow
  | AddPlacementWorkflow
  | EditPlacementWorkflow
  | AddSuspensionWorkflow
  | EditSuspensionWorkflow
  | AddVisitPlacementWorkflow
  | EditVisitPlacementWorkflow
  | DeleteVisitPlacementWorkflow
  | AddEditDeleteAddOnWorkflow
  | AddEditDeleteOneOffWorkflow
  | MessageCarersWorkflow
  | UnassignPlacementWorkflow
  | ViewBillingSetupWorkflow
  | CarerBiddingWorkflow
  | EditMatchingNoteWorkflow
  | EditHoursBulkWorkflow

export type WorkflowName = Workflow['name']

export interface WorkflowContext {
  activeWorkflow: Workflow | null
  setActiveWorkflow: (workflow: Workflow | WorkflowName) => void
  clearActiveWorkflow: () => void
}

export const workflowContext = createContext<WorkflowContext | null>(null)

export const useWorkflowContext = () => {
  const context = useContext(workflowContext)
  if (!context) throw new Error('useWorkflowContext used outside of Provider')
  return context
}
