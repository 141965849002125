// @flow

import type {
  Action,
  ActionCreator0,
  ActionCreator1,
  ActionCreator2,
  ActionCreator3,
  ActionCreator4,
} from './types'

// TODO: Add cases for more arguments as needed
type MakeActionFn = {
  <AT: string, P: Object, T1, T2, T3, T4>(
    actionType: AT,
    payloadFactory: (T1, T2, T3, T4) => P,
  ): ActionCreator4<AT, Action<AT, P>, T1, T2, T3, T4>,
  <AT: string, P: Object, T1, T2, T3>(
    actionType: AT,
    payloadFactory: (T1, T2, T3) => P,
  ): ActionCreator3<AT, Action<AT, P>, T1, T2, T3>,
  <AT: string, P: Object, T1, T2>(
    actionType: AT,
    payloadFactory: (T1, T2) => P,
  ): ActionCreator2<AT, Action<AT, P>, T1, T2>,
  <AT: string, P: Object, T1>(
    actionType: AT,
    payloadFactory: (T1) => P,
  ): ActionCreator1<AT, Action<AT, P>, T1>,
  <AT: string, P: Object>(
    actionType: AT,
    payloadFactory: () => P,
  ): ActionCreator0<AT, Action<AT, P>>,
  <AT: string>(actionType: AT): ActionCreator0<AT, Action<AT, {}>>,
}

export const makeAction: MakeActionFn = (
  type: string,
  payloadFactory = () => ({}),
) => {
  const actionCreator = (...args) => ({ type, ...payloadFactory(...args) })
  actionCreator.actionType = type
  // "Any" cop-out, Flow can't consolidate this with our callable interface
  return (actionCreator: any)
}
