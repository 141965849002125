// @flow
import type { Saga } from 'redux-saga'
import { call, put, takeLatest } from 'redux-saga/effects'

import { setCarer } from 'features/carerPageGenerics/store/actions'
import {
  UPDATE_SKILLS_AND_EXPERTISE,
  NAMESPACE,
} from 'features/carerSkills/store/skills/actions'
import { clearLoading, setLoading } from 'features/loading/actions'
import {
  applicationError,
  applicationSuccess,
} from 'features/snackbar/snackbar'
import { fetchFlow, describeServiceError } from 'utils/services'

export function* putProfessionalSkills({
  value,
  carerId,
}: {
  +value: Object,
  +carerId: string,
}): Generator<*, void, *> {
  const endpoint = `/et/professionals/${carerId}/skills`
  const method = 'PUT'
  try {
    yield put(setLoading(NAMESPACE))
    const responseBody = yield call(fetchFlow, endpoint, method, value)
    yield put(setCarer(responseBody))
    applicationSuccess({
      title: 'Professional saved',
      message: 'Professional skills & expertise was updated successfully.',
    })
  } catch (error) {
    applicationError(
      describeServiceError(error, `Error with service call ${endpoint}`),
    )
  } finally {
    yield put(clearLoading(NAMESPACE))
  }
}

export function* sagas(): Saga<*> {
  yield takeLatest(UPDATE_SKILLS_AND_EXPERTISE, putProfessionalSkills)
}
