// @flow
import { createSelector } from 'reselect'

import {
  makeGetReferenceDataDecorator,
  makeGetReferenceDataOptions,
} from 'app/referenceData/selectors'

export const getPrettyContactRoles =
  makeGetReferenceDataDecorator('accountRole')
export const getContactRolesOptions = makeGetReferenceDataOptions('accountRole')

export const getPrettyCareExpertise =
  makeGetReferenceDataDecorator('careExpertise')
export const getCareExpertiseOptions =
  makeGetReferenceDataOptions('careExpertise')

export const getPrettyCareTypes = makeGetReferenceDataDecorator('careTypes')
export const getCareTypesOptions = makeGetReferenceDataOptions('careTypes')

export const getPrettyDriving = makeGetReferenceDataDecorator('driving')
export const getDrivingOptions = makeGetReferenceDataOptions('driving')

export const getPrettyInterest = makeGetReferenceDataDecorator('interests')
export const getInterestsOptions = makeGetReferenceDataOptions('interests')

export const getPrettyJobTypeInterests =
  makeGetReferenceDataDecorator('jobTypeInterests')
export const getJobTypeInterestsOptions =
  makeGetReferenceDataOptions('jobTypeInterests')

export const getLanguagesOptions = makeGetReferenceDataOptions('languages')

export const getPrettyOnboardingLocation =
  makeGetReferenceDataDecorator('onboardingLocation')
export const getOnboardingLocationOptions =
  makeGetReferenceDataOptions('onboardingLocation')

export const getPrettyEthnicity = makeGetReferenceDataDecorator('ethnicity')
export const getEthnicitiesOptions = makeGetReferenceDataOptions('ethnicity')

export const getPrettyCountries = makeGetReferenceDataDecorator('countries')
export const getCountriesOptions = makeGetReferenceDataOptions('countries')

export const getPrettyCarerStudentLoanPlans = makeGetReferenceDataDecorator(
  'carerStudentLoanPlans',
)
export const getCarerStudentLoanPlans = makeGetReferenceDataOptions(
  'carerStudentLoanPlans',
)

export const getPrettyVisaTypes = makeGetReferenceDataDecorator('visaType')
export const getVisaTypesOptions = makeGetReferenceDataOptions('visaType')

export const getPrettyMedicalConditions =
  makeGetReferenceDataDecorator('medicalConditions')
export const getMedicalConditionsOptions =
  makeGetReferenceDataOptions('medicalConditions')

const sortRefDataByLabel = (a, b) => a.label.localeCompare(b.label)

export const getMedicalConditionsOptionsSorted = createSelector(
  [getMedicalConditionsOptions],
  (medicalConditionsOptions) => ({
    ...medicalConditionsOptions,
    options: medicalConditionsOptions.options.sort(sortRefDataByLabel),
  }),
)

export const getPrettyPets = makeGetReferenceDataDecorator('pets')
export const getPetsOptions = makeGetReferenceDataOptions('pets')

export const getPrettyPetFriendly = makeGetReferenceDataDecorator('petFriendly')
export const getPetFriendlyOptions = makeGetReferenceDataOptions('petFriendly')
export const getEthnicitiesAtRiskOptions =
  makeGetReferenceDataOptions('ethnicitiesAtRisk')
export const getPrettyEthnicitiesAtRisk =
  makeGetReferenceDataDecorator('ethnicitiesAtRisk')

export const getPrettyGender = makeGetReferenceDataDecorator(
  'needsAssessmentGenders',
)
export const getGenderOptions = makeGetReferenceDataOptions(
  'needsAssessmentGenders',
)

export const getPrettyGenderPreference =
  makeGetReferenceDataDecorator('genderPreference')
export const getGenderPreferenceOptions =
  makeGetReferenceDataOptions('genderPreference')

export const getPrettyGenders = makeGetReferenceDataDecorator('genders')
export const getGendersOptions = makeGetReferenceDataOptions('genders')

export const getPrettyDrivingRequirements = makeGetReferenceDataDecorator(
  'drivingRequirements',
)
export const getDrivingRequirementsOptions = makeGetReferenceDataOptions(
  'drivingRequirements',
)

export const getPrettyCustomerChurnReasons = makeGetReferenceDataDecorator(
  'customerChurnReasons',
)
export const getCustomerChurnReasonsOptions = makeGetReferenceDataOptions(
  'customerChurnReasons',
)

export const getOnwardProviderOptions =
  makeGetReferenceDataOptions('onwardProvider')

export const getPrettyCustomerChurnOutcomes = makeGetReferenceDataDecorator(
  'customerChurnOutcomes',
)
export const getCustomerChurnOutcomesOptions = makeGetReferenceDataOptions(
  'customerChurnOutcomes',
)

export const getPrettyProChurnReasons =
  makeGetReferenceDataDecorator('proChurnReasons')
export const getProChurnReasonsOptions =
  makeGetReferenceDataOptions('proChurnReasons')

export const getPrettyPlacementTypes =
  makeGetReferenceDataDecorator('placementType')
export const getPlacementTypesOptions =
  makeGetReferenceDataOptions('placementType')

export const getPrettyPlacementRequirement = makeGetReferenceDataDecorator(
  'placementRequirement',
)
export const getPlacementRequirementOptions = makeGetReferenceDataOptions(
  'placementRequirement',
)

export const getPrettyFundingSource =
  makeGetReferenceDataDecorator('fundingSources')
export const getPrettyFundingSourceOptions =
  makeGetReferenceDataOptions('fundingSources')

export const getPrettyFundingMethodType =
  makeGetReferenceDataDecorator('fundingMethodType')
export const getPrettyFundingMethodTypeOptions =
  makeGetReferenceDataOptions('fundingMethodType')

export const getPrettyInvoiceStatus =
  makeGetReferenceDataDecorator('invoiceStatus')
export const getPrettyInvoiceStatusOptions =
  makeGetReferenceDataOptions('invoiceStatus')

export const getPrettyManualTransaction =
  makeGetReferenceDataDecorator('manualTransaction')
export const getPrettyManualTransactionOptions =
  makeGetReferenceDataOptions('manualTransaction')

export const getPrettyTravelModes = makeGetReferenceDataDecorator('travelModes')
export const getTravelModesOptions = makeGetReferenceDataOptions('travelModes')

export const getSolutionPlansOptions =
  makeGetReferenceDataOptions('solutionPlans')

export const getSolutionCareTypes =
  makeGetReferenceDataOptions('solutionCareTypes')

export const getSolutionPlansOptionsWithDisabled = makeGetReferenceDataOptions(
  'solutionPlans',
  true,
)

export const getSolutionTariffOptions =
  makeGetReferenceDataOptions('solutionTariffs')

export const getSolutionServiceTypeOptions =
  makeGetReferenceDataOptions('serviceTypes')

export const getAddOnOptions = makeGetReferenceDataOptions('addonTypes')
export const getAddOnOptionsWithDisabled = makeGetReferenceDataOptions(
  'addonTypes',
  true,
)

export const getOneOffOptions =
  makeGetReferenceDataOptions('oneOffPaymentTypes')
export const getOneOffOptionsWithDisabled = makeGetReferenceDataOptions(
  'oneOffPaymentTypes',
  true,
)

export const getPrettyOneOffOptions =
  makeGetReferenceDataDecorator('oneOffPaymentTypes')

export const getPrettySolutionStartDateChangeReasons =
  makeGetReferenceDataDecorator('solutionStartDateChangeReasons')
export const getSolutionStartDateChangeReasonsOptions =
  makeGetReferenceDataOptions('solutionStartDateChangeReasons')

export const getPrettySolutionEndingReasons = makeGetReferenceDataDecorator(
  'solutionEndingReasons',
)
export const getSolutionEndingReasonsOptions = makeGetReferenceDataOptions(
  'solutionEndingReasons',
)

export const getPrettySolutionEndingOutcomes = makeGetReferenceDataDecorator(
  'solutionEndingOutcomes',
)

export const getSuspensionReasonOptions =
  makeGetReferenceDataOptions('suspensionReason')

export const getPrettySuspensionReason =
  makeGetReferenceDataDecorator('suspensionReason')

const PRIORITY_REASONS = ['CUSTOMER_REJECTED_CARER', 'ELDER_DECISION_NOT_MATCH']
const lexicalOptionComparer = (a, b) => (a.value < b.value ? -1 : 1)
export const getUnassignPlacementCarerOptions = (state: Object) => {
  const rawReasons = makeGetReferenceDataOptions('unassignReasons')(state)

  // Loop over options and unpack secondary reasons
  return {
    ...rawReasons,
    options: [
      // We want some reasons to appear first, as they are used most often
      ...rawReasons.options
        .filter((option) => PRIORITY_REASONS.includes(option.value))
        .sort(lexicalOptionComparer),
      ...rawReasons.options
        .filter((option) => !PRIORITY_REASONS.includes(option.value))
        .sort(lexicalOptionComparer),
    ].map((option) => ({
      label: option.label,
      value: option.value,
      secondaryReasons: (option.metadata.secondaryReasons || '')
        .split(',')
        .map((secondaryReason) => secondaryReason.trim()),
      requiresDescription: !!option.metadata.requiresDescription,
    })),
  }
}

export const getUnassignPlacementCarerSecondaryOptions =
  makeGetReferenceDataOptions('unassignSecondaryReasons')

export const getLanguageClarityOptions =
  makeGetReferenceDataOptions('languageClarity')
export const getPrettyLanguageClarity =
  makeGetReferenceDataDecorator('languageClarity')

export const getLanguageFluencyOptions =
  makeGetReferenceDataOptions('languageFluency')
export const getPrettyLanguageFluency =
  makeGetReferenceDataDecorator('languageFluency')

export const getcarerFoodOptions = makeGetReferenceDataOptions('carerFood')
export const getPrettyCarerFood = makeGetReferenceDataDecorator('carerFood')

export const getThirdPartyCarerFoodOptions = makeGetReferenceDataOptions(
  'thirdPartyCarerFood',
)

export const getFoodDietNeedsOptions =
  makeGetReferenceDataOptions('foodDietNeeds')
export const getMobilityNeedsOptions =
  makeGetReferenceDataOptions('mobilityNeeds')

export const getContinenceNeedsOptions =
  makeGetReferenceDataOptions('continenceNeeds')

export const getPrettyLanguageFluencyPreference = makeGetReferenceDataDecorator(
  'preferredLanguageFluency',
)
export const getPreferredLanguageFluencyOptions = makeGetReferenceDataOptions(
  'preferredLanguageFluency',
)

export const getPrettyCarerExperiencePreference = makeGetReferenceDataDecorator(
  'preferredCarerExperience',
)
export const getPreferredCarerExperienceOptions = makeGetReferenceDataOptions(
  'preferredCarerExperience',
)

export const getPrettyCustomerLearningResources = makeGetReferenceDataDecorator(
  'customerLearningResources',
)
export const getCustomerLearningResourcesOptions = makeGetReferenceDataOptions(
  'customerLearningResources',
)
export const getStagesOfMatching = makeGetReferenceDataOptions('matchingStage')

export const getRestrictionReasons = makeGetReferenceDataOptions(
  'carerRestrictionReasons',
)
export const getPrettyRestrictionReason = makeGetReferenceDataDecorator(
  'carerRestrictionReasons',
)

export const getFullCareCertificateIssuingOrganizationOptions =
  makeGetReferenceDataOptions('fullCareCertificateIssuingOrganizations')

export const getMedicationAdministrationOptions = makeGetReferenceDataOptions(
  'medicationAdministration',
)

export const getFeedingTubeTypesOptions =
  makeGetReferenceDataOptions('feedingTubeTypes')

export const getPrettyFeedingTubeTypes =
  makeGetReferenceDataDecorator('feedingTubeTypes')

export const getFoodDeliveryTypesOptions =
  makeGetReferenceDataOptions('foodDeliveryTypes')

export const getPrettyFoodDeliveryTypes =
  makeGetReferenceDataDecorator('foodDeliveryTypes')

export const getAttendanceRequirementsOptions = makeGetReferenceDataOptions(
  'attendanceRequirements',
)

export const getCatheterTypesOptions =
  makeGetReferenceDataOptions('catheterTypes')

export const getPrettyCatheterTypes =
  makeGetReferenceDataDecorator('catheterTypes')

export const getManualLiftingRequirementsOptions = makeGetReferenceDataOptions(
  'manualLiftingRequirements',
)

export const getMobilityLevelOptions =
  makeGetReferenceDataOptions('mobilityLevel')

export const getStomaTypesOptions = makeGetReferenceDataOptions('stomaTypes')

export const getPrettyStomaTypes = makeGetReferenceDataDecorator('stomaTypes')

export const getWeightUnitsOptions = makeGetReferenceDataOptions('weightUnits')

export const getPrettyWeightUnits = makeGetReferenceDataDecorator('weightUnits')

export const getPrettyCarerMatchingPresentation = makeGetReferenceDataDecorator(
  'carerMatchingPresentation',
)
export const getCarerMatchingPresentationOptions = makeGetReferenceDataOptions(
  'carerMatchingPresentation',
)

export const getPrettyCareRecipientStatus = makeGetReferenceDataDecorator(
  'careRecipientStatus',
)
export const getPrettyWorkExperienceTypes = makeGetReferenceDataDecorator(
  'workExperienceTypes',
)

export const getCareRecipientStatusOptions = makeGetReferenceDataOptions(
  'careRecipientStatus',
)

export const getSpecialistSkillsOptions =
  makeGetReferenceDataOptions('specialistSkills')

export const getPrettySpecialistSkills =
  makeGetReferenceDataDecorator('specialistSkills')

export const getPrettyPaymentMethods =
  makeGetReferenceDataDecorator('paymentMethod')

export const getCustomerCommunicationCategories = makeGetReferenceDataOptions(
  'customerCommunicationCategories',
)

export const getLockingModeStrategyOptions = makeGetReferenceDataOptions(
  'lockingModeStrategy',
)

export const getCarerFilterTypeStrategyOptions = makeGetReferenceDataOptions(
  'carerFilterTypeStrategy',
)

export const getCurrentLockingModeOptions =
  makeGetReferenceDataOptions('lockingMode')

export const getCurrentCarerFilterTypeOptions =
  makeGetReferenceDataOptions('carerFilterType')

export const getVideoRatingOptions = makeGetReferenceDataOptions('videoRatings')

export const getVideoRatingReasonsOptions =
  makeGetReferenceDataOptions('videoRatingReasons')

export const getPrettyCarerVideoQualityRating =
  makeGetReferenceDataDecorator('videoRatings')

export const getPrettyCarerVideoQualityReasons =
  makeGetReferenceDataDecorator('videoRatingReasons')

export const getPrettyCareAppraisalReview = makeGetReferenceDataDecorator(
  'careAppraisalReview',
)

export const getRegulatoryModelOptions =
  makeGetReferenceDataOptions('regulatoryModels')

export const getPrettyRegulatoryModelOption =
  makeGetReferenceDataDecorator('regulatoryModels')

export const getCarerEligibilityModifiers = makeGetReferenceDataOptions(
  'carerEligibilityModifiers',
)

export const getCarerEligibilityModifierTypes = makeGetReferenceDataOptions(
  'carerEligibilityModifierTypes',
)

export const getPlacementEndDateChangeReasons = makeGetReferenceDataOptions(
  'placementEndDateChangeReason',
)

export const getCarerQualityBucketOptions =
  makeGetReferenceDataOptions('carerQualityBucket')

export const getPrettyCarerQualityBucket =
  makeGetReferenceDataDecorator('carerQualityBucket')

export const getCarerStatusOptions = makeGetReferenceDataOptions(
  'carerStatus',
  false,
)

export const getPrettyCarerStatus = makeGetReferenceDataDecorator('carerStatus')

export const getCarerWorkerStatusOptions =
  makeGetReferenceDataOptions('carerWorkerStatus')

export const getPrettyCarerWorkerStatus =
  makeGetReferenceDataDecorator('carerWorkerStatus')

export const getBillingArrangementTypes = makeGetReferenceDataOptions(
  'billingArrangementType',
)

export const getPrettyBillingArrangementTypes = makeGetReferenceDataDecorator(
  'billingArrangementType',
)

export const getSuspensionCoverOptions =
  makeGetReferenceDataOptions('suspensionCover')

export const getPrettySuspensionCover =
  makeGetReferenceDataDecorator('suspensionCover')
export const getPrettyCarerBenefitTier =
  makeGetReferenceDataDecorator('carerBenefitTier')

export const getCertificationProviderOptions = makeGetReferenceDataOptions(
  'certificationProvider',
)

export const getCertificationOptions =
  makeGetReferenceDataOptions('certification')
