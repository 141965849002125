// @flow
import type { Saga } from 'redux-saga'
import { takeLatest, call, put, all } from 'redux-saga/effects'

import { recipientDocumentsClient } from 'app/saga/serviceClients'
import { setActiveWorkflow } from 'features/activeWorkflow/actions'
import { postFileToGCS } from 'features/carerDocuments/saga'
import type { DocumentToUpload } from 'features/recipientDocuments/domain'
import {
  UPLOAD as UPLOAD_RECIPIENT_DOCUMENTS,
  DOWNLOAD as DOWNLOAD_RECIPIENT_DOCUMENTS,
  uploadStarted,
  uploadCompleted,
} from 'features/recipientDocuments/store/actions'
import {
  applicationError,
  applicationSuccess,
} from 'features/snackbar/snackbar'
import { describeServiceError } from 'utils/services'

function* handleUploadRecipientDocuments({
  accountId,
  recipientId,
  document,
  getRecipientInfo,
}: {
  +accountId: string,
  +recipientId: string,
  +document: DocumentToUpload,
  +getRecipientInfo: () => void,
}): Saga<*> {
  yield put(uploadStarted())
  const { comments, fileToUpload, type, documentTypeName } = document

  try {
    const request = {
      data: {
        metadata: { type, comments, documentTypeName },
        document: {
          files: [{ contentType: fileToUpload.file.type }],
        },
      },
      recipientId,
      accountId,
    }

    const { documentId, files } = yield call(
      recipientDocumentsClient.beginUploadDocument,
      request,
    )

    const [fileInformation] = files
    const url = fileInformation.uploadUrl
    yield call(postFileToGCS, {
      file: fileToUpload.file,
      url,
    })

    const payload = {
      metadata: {
        type,
        comments,
        documentTypeName,
      },
      documentId,
    }

    yield call(recipientDocumentsClient.completeUploadDocument, {
      accountId,
      recipientId,
      payload,
    })

    applicationSuccess({
      title: 'Upload Complete',
      message: 'Successfully uploaded recipient documents',
    })

    yield put(uploadCompleted())
    yield put(setActiveWorkflow(''))
  } catch (error) {
    applicationError(
      describeServiceError(error, 'Failed to upload the document'),
    )
  }
  // $FlowOptOut
  yield put(getRecipientInfo())
}

function* handleDownloadRecipientDocuments({
  documentId,
}: {
  +documentId: string,
}): Saga<*> {
  try {
    const { files } = yield call(recipientDocumentsClient.downloadDocument, {
      documentId,
    })

    yield all(files.map(({ downloadUrl }) => call(window.open, downloadUrl)))
  } catch (error) {
    applicationError(describeServiceError(error, 'Failed to open files'))
  }
}

export function* recipientDocumentsSagas(): Saga<*> {
  yield takeLatest(UPLOAD_RECIPIENT_DOCUMENTS, handleUploadRecipientDocuments)
  yield takeLatest(
    DOWNLOAD_RECIPIENT_DOCUMENTS,
    handleDownloadRecipientDocuments,
  )
}
