// @flow
import { colors } from '@elder/common'
import styled from 'styled-components'

export const Status = styled.div`
  height: 32px;
  width: 96px;
  border-radius: 15px;
  background-color: ${({ backgroundColour }) => backgroundColour};
  color: ${colors.white};
  font-weight: bold;
  text-align: center;
  display: flex;
  align-items: center;
  justify-content: center;
`
