import { useEffect, useMemo } from 'react'

import { get, partialRight } from 'lodash'
import { useSnackbar } from 'notistack'

export const useNotifications = () => {
  const { enqueueSnackbar } = useSnackbar()
  const value = useMemo(
    () => ({
      showError: partialRight(enqueueSnackbar, { variant: 'error' }),
      showSuccess: partialRight(enqueueSnackbar, { variant: 'success' }),
    }),
    [enqueueSnackbar],
  )
  return value
}

interface EtError {
  response: {
    data: {
      message: string
      responseType: 'ELDER_ERROR'
      timestamp: string
      errorCode: string
    }
    status: number
  }
}

const isEtError = (error: unknown): error is EtError => {
  if (!error) return false
  return get(error, 'response.data.responseType') === 'ELDER_ERROR'
}

export const getErrorMessage = (error: ActionReactions['error']) => {
  if (!error) return null
  if (isEtError(error))
    return (
      <span
        // eslint-disable-next-line react/no-danger
        dangerouslySetInnerHTML={{ __html: error.response.data.message }}
      />
    )
  if (typeof error === 'string') return error
  return error.message
}

interface ActionReactions {
  error?: Error | string | null | false
  success?: string | null | false
}

/** If `error` or `success` passed to this hook become truthy, they will be
 * displayed as an appropriate snackbar message */
export const useActionNotifications = ({ error, success }: ActionReactions) => {
  const { showError, showSuccess } = useNotifications()

  useEffect(() => {
    const message = getErrorMessage(error)
    if (!message) return
    showError(message)
  }, [error, showError])

  useEffect(() => {
    if (!success) return
    showSuccess(success)
  }, [success, showSuccess])
}
