/* eslint-disable react/forbid-prop-types */

import React from 'react'

import { Select, MenuItem } from '@mui/material'
import { Form, Input, Textarea } from 'formsy-react-components'
import PropTypes from 'prop-types'
import styled from 'styled-components'

import { ButtonSubmit, GenericButton } from 'components'
import { StripeCard } from 'features/stripeCard/StripeCard'
import { forms } from 'messages/forms'
import { styles } from 'routes/accounts/createAccount/styles'

const Wrapper = styled.div`
  ${styles}
`

// eslint-disable-next-line no-restricted-properties
class PaymentTypeForm extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      paymentType: this.props.paymentDetails.paymentMethodType,
      mandateId: null,
    }
  }

  UNSAFE_componentWillMount() {
    this.setState({
      canPTSubmit: true,
      generateToken: false,
      stripeToken: '',
      cardLast4: '',
      description: this.props.paymentDetails.description || '',
    })
  }

  UNSAFE_componentWillReceiveProps(props) {
    this.form.formsyForm.updateInputsWithError(props.validationErrors)
  }

  setPTValid = () => this.setState({ canPTSubmit: true })

  setPTInvalid = () => this.setState({ canPTSubmit: false })

  submitPTForm = (data) => {
    if (this.state.canPTSubmit) {
      this.props.updatePaymentType(data)
    }
  }

  cardComplete = (complete, callback) => {
    this.setState({
      generateToken: complete && callback,
    })
  }

  collectCardToken = (obj) => {
    if (obj && obj.token && obj.token.card) {
      this.setState({
        stripeToken: obj.token.id,
        cardLast4: obj.token.card.last4,
      })
      this.form.formsyForm.submit()
    }
  }

  render() {
    return (
      <Wrapper className="acct__section acct__section--in-edit">
        <div className="acct__header">
          <h3>Payment Type (edit mode)</h3>
        </div>
        <div className="acct__entry">
          {/* eslint-disable-next-line jsx-a11y/label-has-associated-control */}
          <label htmlFor="payment-type">Payment Type:</label>
          <Select
            id="payment-type"
            name="selectPaymentType"
            value={this.state.paymentType}
            onChange={(e) => this.setState({ paymentType: e.target.value })}
          >
            {this.props.paymentTypeOptions.map(({ value, label }) => (
              <MenuItem key={value} value={value}>
                {label}
              </MenuItem>
            ))}
          </Select>
        </div>
        {this.state.paymentType === 'CARD' &&
          this.props.paymentDetails.stripeKey && (
            <div className="acct__entry">
              <span>Card Details:</span>
              <StripeCard
                ref={(stripe) => {
                  // eslint-disable-next-line react/no-unused-class-component-methods
                  this.stripe = stripe
                }}
                stripeKey={this.props.paymentDetails.stripeKey}
                callback={this.collectCardToken}
                cardComplete={this.cardComplete}
              />
            </div>
          )}
        <Form
          ref={(form) => {
            this.form = form
          }}
          onSubmit={this.submitPTForm}
          onValid={this.setPTValid}
          onInvalid={this.setPTInvalid}
          validatePristine
        >
          <Input
            type="hidden"
            name="paymentType"
            value={this.state.paymentType}
          />
          {this.state.paymentType === 'CARD' && (
            <Input
              type="hidden"
              name="stripeCardToken"
              value={this.state.stripeToken}
              validationErrors={{
                isDefaultRequiredValue: forms.validation.isRequired,
              }}
              required
            />
          )}
          {this.state.paymentType === 'CARD' && (
            <Input
              type="hidden"
              name="cardLast4"
              value={this.state.cardLast4}
              validationErrors={{
                isDefaultRequiredValue: forms.validation.isRequired,
              }}
              required
            />
          )}
          {this.state.paymentType === 'DIRECT_DEBIT_MANDATE' && (
            <Input
              name="mandateId"
              value={this.state.mandateId || ''}
              label="Go Cardless mandate"
              validationErrors={{
                isDefaultRequiredValue: forms.validation.isRequired,
              }}
              required
            />
          )}
          {this.state.paymentType === 'MANUAL_INVOICING' && (
            <Textarea
              rows={8}
              name="description"
              value={this.state.description || ''}
              label="Invoice arrangement"
              validationErrors={{
                isDefaultRequiredValue: forms.validation.isRequired,
              }}
              required
            />
          )}

          <div className="acct__form-buttons">
            <GenericButton
              text="Cancel"
              onClick={this.props.cancelEdit}
              quiet
            />
            {this.state.paymentType === 'CARD' && (
              <GenericButton
                disabled={!this.state.generateToken}
                text="Save Card"
                onClick={this.state.generateToken}
              />
            )}
            {this.state.paymentType !== 'CARD' && (
              <ButtonSubmit
                text={
                  this.state.paymentType === 'DIRECT_DEBIT'
                    ? 'Send direct debit request'
                    : 'Save'
                }
                disabled={!this.state.canPTSubmit}
              />
            )}
          </div>
        </Form>
      </Wrapper>
    )
  }
}

PaymentTypeForm.propTypes = {
  paymentDetails: PropTypes.object.isRequired,
  paymentTypeOptions: PropTypes.array.isRequired,
  updatePaymentType: PropTypes.func.isRequired,
  cancelEdit: PropTypes.func.isRequired,
  validationErrors: PropTypes.object.isRequired,
}

export default PaymentTypeForm
