import type {
  SlotDetails,
  VisitingRegularVisitBlock,
  VisitingSingleVisit,
} from '@elder/et-facade-et'
import moment from 'moment'

export interface VisitingData {
  isMatching: boolean
  timing: string
  placement: VisitingPlacement
}

export interface RegularVisitBlock
  extends Omit<VisitingRegularVisitBlock, 'id'> {
  type: 'regular'
  id: string
}

export interface SingleVisit extends Omit<VisitingSingleVisit, 'id'> {
  type: 'single'
  id: string
}

export type VisitingPlacement = RegularVisitBlock | SingleVisit

export type VisitingPlacementType = VisitingPlacement['type']

export const getVisitingPlacementsFromSolution = (
  slot: SlotDetails,
): VisitingPlacement[] => {
  const { regularVisitBlocks } = slot
  const { singleVisits } = slot

  return [
    ...regularVisitBlocks.map((block) => ({
      ...block,
      type: 'regular' as const,
      id: block.id as unknown as string,
    })),
    ...singleVisits.map((visit) => ({
      ...visit,
      type: 'single' as const,
      id: visit.id as unknown as string,
    })),
  ]
}

export const getVisitingPlacementTimingText = (
  placement: VisitingPlacement,
) => {
  const hours =
    placement.type === 'single'
      ? placement.details.durationHours
      : placement.details.hoursPerWeek

  return `${hours} hrs`
}

const dateFormat = 'll'

export const getVisitingPlacementDateText = (placement: VisitingPlacement) => {
  if (placement.type === 'single') {
    return moment(placement.details.date).format('dddd LL')
  }

  const start = moment(placement.details.startDateInclusive).format(dateFormat)
  const end = placement.details.endDateExclusive
    ? moment(placement.details.endDateExclusive).format(dateFormat)
    : 'Ongoing'
  return `${start} to ${end}`
}
