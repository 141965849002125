/* eslint-disable no-param-reassign */
import type {
  FacadeDateRangeAmountComponent,
  FacadeWeeklyBreakdown,
  MatchPublication,
  PlacementDetails,
  PlacementRateDetails,
  SlotDetails,
  SolutionBillingSetup,
  SolutionDetails,
  SolutionDetailsBillingSetups,
  SolutionRateDetails,
} from '@elder/et-facade-et'
import { keyBy } from 'lodash'
import type { Moment } from 'moment'
import moment from 'moment'

import type { TimelineBlockColor } from 'components/solutions/SolutionTimeline/SolutionTimeline'
import { LocalDates } from 'domain/dates'
import {
  solutionRateColors as untypedSolutionRateColors,
  placementColorNotAssigned,
  placementColors,
  placementColorSuspended,
  placementColorUnavailable,
} from 'features/solutionsTimeline/placement-colors'
import type { VisitingPlacement } from 'features/visiting/types'
import {
  prettyCompactDateWithDow,
  prettyShortDate,
  prettyDurationWeeks,
  prettyCompactDateTime,
} from 'utils/dateDecorators'

const solutionRateColors: TimelineBlockColor[] = untypedSolutionRateColors

export const TOTAL_DAYS_IN_VIEW = 28

export type OptionLookupFn = (key: string) => string

export type TransformedBillingSetup = SolutionBillingSetup & {
  endDateExclusive?: string
  startDateInclusive?: string
}

interface TransformedBillingSetupsOptions {
  billingSetups?: SolutionDetailsBillingSetups
}

const transformBillingSetups = ({
  billingSetups = {},
}: TransformedBillingSetupsOptions) =>
  Object.keys(billingSetups).map((key) => {
    const billingSetup = billingSetups[key]

    const newBillingSetup = {
      ...billingSetup,
    }

    if (billingSetup.endDateExclusive) {
      newBillingSetup.endDateExclusive = LocalDates.ofString(
        billingSetup.endDateExclusive,
      )
    }

    if (billingSetup.startDateInclusive) {
      newBillingSetup.startDateInclusive = LocalDates.ofString(
        billingSetup.startDateInclusive,
      )
    }

    return newBillingSetup as TransformedBillingSetup
  })

interface TransformSolutionRatesOptions {
  solutionRates?: SolutionRateDetails[]
}

type TransformedSolutionRateDetails = SolutionRateDetails & {
  startDateInclusive?: string
  endDateExclusive?: string
}

const transformSolutionRates = ({
  solutionRates = [],
}: TransformSolutionRatesOptions) =>
  solutionRates.map((rate) => {
    const rateCopy = { ...rate }

    if (rateCopy.startDateInclusive) {
      rateCopy.startDateInclusive = LocalDates.ofString(
        rateCopy.startDateInclusive,
      )
    }

    if (rateCopy.endDateExclusive) {
      rateCopy.endDateExclusive = LocalDates.ofString(rateCopy.endDateExclusive)
    }

    return rateCopy as TransformedSolutionRateDetails
  })

interface TransformPlacementOptions {
  placement: PlacementDetails
  momentViewStart: Moment
  colorSelector: ReturnType<typeof makePlacementColorSelector>
  prettyOneOffOptions: OptionLookupFn
  prettySuspensionReason: OptionLookupFn
  prettySuspensionCover: OptionLookupFn
}

interface PlacementRateDetailsExtraProps {
  daysFromStart: number
  displayedRate: string
  prettyStartDate: string
  prettyEndDate: string
}

type TransformingPlacementRateDetails = PlacementRateDetails &
  Partial<PlacementRateDetailsExtraProps>
export type TransformedPlacementRateDetails = PlacementRateDetails &
  PlacementRateDetailsExtraProps

interface PlacementDetailsExtraProps {
  colors: TimelineBlockColor
  prettyDuration: string
  prettyStartDateInclusive: string
  prettyEndDateExclusive: string
  rateBreakdown: TransformedPlacementRateDetails
  oneOffPayments: (NonNullable<PlacementDetails['oneOffPayments']>[number] & {
    prettyPaymentType: string
  })[]
  prettySuspensionReason: string
  prettySuspensionCover: string
  isInfinite?: boolean
  signOff: $TSFixMe
  isSignedOff: boolean
  signedOffBy: string
  canBeSignedOff: boolean
  matchPublication?: MatchPublication
  publishedBy?: string | null
  publishedAt: string
  isMatchPublished: boolean
  changelog: $TSFixMe[] // ? Might not exist anymore
}

type TransformingPlacement = PlacementDetails &
  Partial<PlacementDetailsExtraProps>
export type TransformedPlacement = PlacementDetails & PlacementDetailsExtraProps

export const transformPlacement = ({
  placement: basePlacement,
  momentViewStart,
  colorSelector,
  prettyOneOffOptions,
  prettySuspensionReason,
  prettySuspensionCover,
}: TransformPlacementOptions): TransformedPlacement => {
  const placement = basePlacement as TransformingPlacement
  placement.colors = colorSelector(placement)

  placement.prettyDuration =
    placement.effectiveStartDateInclusive && placement.effectiveEndDateExclusive
      ? `(${prettyDurationWeeks(
          placement.effectiveStartDateInclusive,
          placement.effectiveEndDateExclusive,
        )})`
      : ''

  const momentStartDate = moment(placement.effectiveStartDateInclusive)
  // ? I think the from prop does not exist on placements anymore
  // let lastPartialRate = null
  // let firstInViewRate = null

  placement.prettyStartDateInclusive = prettyCompactDateWithDow(
    placement.effectiveStartDateInclusive,
  )

  if (placement.effectiveEndDateExclusive) {
    placement.prettyEndDateExclusive = prettyCompactDateWithDow(
      placement.effectiveEndDateExclusive,
    )
  } else {
    placement.prettyEndDateExclusive = 'End of solution'
    placement.isInfinite = true
  }

  placement.rateBreakdown?.forEach(
    (rateDetails: TransformingPlacementRateDetails) => {
      const effectivePlacementStart = moment.max(
        momentViewStart,
        momentStartDate,
      )
      const rateDetailsStart = moment(rateDetails.startDateInclusive)

      rateDetails.daysFromStart = rateDetailsStart.diff(
        effectivePlacementStart,
        'days',
      )

      // rateComponentTotals doesn't exist in types from API
      rateDetails.displayedRate =
        rateDetails.rateComponentTotals.providerRate.displayText

      rateDetails.prettyStartDate = rateDetails.startDateInclusive
        ? prettyCompactDateWithDow(rateDetails.startDateInclusive)
        : ''

      rateDetails.prettyEndDate = rateDetails.endDateExclusive
        ? prettyCompactDateWithDow(rateDetails.endDateExclusive)
        : '∞'

      // ? I think the from prop does not exist on placements anymore
      // if (placement.from + rateDetails.daysFromStart < 0) {
      //   lastPartialRate = rateDetails
      // }
      // if (!firstInViewRate && placement.from + rateDetails.daysFromStart >= 0) {
      //   firstInViewRate = rateDetails
      // }
    },
  )

  if (placement.oneOffPayments && prettyOneOffOptions) {
    placement.oneOffPayments = placement.oneOffPayments.map((oneOffPayment) => {
      const prettyPaymentType = prettyOneOffOptions(
        oneOffPayment.oneOffPaymentTypeId as unknown as string,
      )
      return {
        ...oneOffPayment,
        prettyPaymentType: prettyPaymentType || 'Unknown',
      } as NonNullable<PlacementDetails['oneOffPayments']>[number] & {
        prettyPaymentType: string
      }
    })
  }

  // ? I think the from prop does not exist on placements anymore
  // Final touch-up, display the rate for the leftmost "broken" bar, if it fits in view
  // if (
  //   lastPartialRate &&
  //   ((firstInViewRate &&
  //     placement.from + firstInViewRate.daysFromStart > 1) ||
  //     !firstInViewRate)
  // ) {
  //   lastPartialRate.daysFromStart = 0
  // }

  // Match sign-off
  placement.signOff = placement.signOff || null
  placement.isSignedOff = !!(placement.signOff && placement.signOff.by)
  placement.signedOffBy = (placement.signOff && placement.signOff.by) || ''
  placement.canBeSignedOff = placement.signOff && placement.signOff.enabled

  // Carer match publication
  // placement.matchPublication = placement.matchPublication
  placement.publishedBy =
    placement.matchPublication && placement.matchPublication.by
  placement.publishedAt =
    placement.matchPublication && prettyShortDate(placement.matchPublication.at)
  placement.isMatchPublished = !!placement.publishedBy

  // ? Not included in the PlacementDetails et-facade type
  const changelog = placement.changelog || []
  changelog.map((item, index) => {
    item.prettyTimestamp = prettyCompactDateTime(item.timestamp)
    item.id = `${placement.id}_changelog_${index}`
    return item
  })
  placement.changelog = changelog

  if (placement.type === 'SUSPENDED') {
    if (placement.suspensionReason) {
      placement.prettySuspensionReason = prettySuspensionReason(
        placement.suspensionReason,
      )
    }
    if (placement.suspensionCover) {
      placement.prettySuspensionCover = prettySuspensionCover(
        placement.suspensionCover,
      )
    }
  }

  return placement as TransformedPlacement
}

interface ColorLookup {
  [key: string]: TimelineBlockColor
}

const getProColor = (
  proColorLookup: ColorLookup,
  professionalId: string,
): TimelineBlockColor => {
  if (professionalId && !proColorLookup[professionalId]) {
    const usedColors = Object.keys(proColorLookup).length
    proColorLookup[professionalId] =
      placementColors[usedColors % placementColors.length]
  }

  return proColorLookup[professionalId]
}

export const makePlacementColorSelector =
  (proColorLookup: ColorLookup) =>
  (placement: $TSFixMe): TimelineBlockColor => {
    if (placement.professionalId) {
      return getProColor(proColorLookup, placement.professionalId)
    }
    if (placement.type === 'SUSPENDED') {
      return placementColorSuspended
    }
    return placementColorNotAssigned
  }

export const makeProPlacementColorSelector =
  (proColorLookup: ColorLookup, accountId: string) =>
  (proPlacements: $TSFixMe, placement: $TSFixMe): TimelineBlockColor => {
    if (placement.subscriberId === accountId) {
      // This pro, working for us, use the pro color
      return getProColor(proColorLookup, proPlacements.professionalId)
    }
    return placementColorUnavailable
  }

export type TransformedWeeklyAmount = FacadeDateRangeAmountComponent & {
  startDateInclusive: Moment
  endDateExclusive: Moment
  colors: TimelineBlockColor
}

export type TransformedWeeklyBreakdown = FacadeWeeklyBreakdown & {
  weeklyAmounts: TransformedWeeklyAmount[]
}

interface SolutionExtraProps {
  prettyStartDateInclusive: string
  prettyEndDateExclusive: string
  prettyDuration: string
  endDateChanged: {
    endingReasons: string[]
    endingOutcome: string
    closureDetails: string
  }
  billingSetups: TransformedBillingSetup[]
  solutionRates: TransformedSolutionRateDetails[]
  cancellationPermissions: {
    allowsUnsafeCancellation: boolean
    cancellationLockedDown: boolean
  }
  solutionPlanDescription: string
  weeklyBreakdown: TransformedWeeklyBreakdown | Record<string, never>
  tariffId?: string
  tariffDescription: string
  placementSlots: (SlotDetails & { visitingPlacements: VisitingPlacement[] })[]
  placementsLookup: { [id: string]: TransformedPlacement }
  solutionType: 'LIVE_IN' | 'VISITING'
}

export type TransformedSolution = Omit<
  SolutionDetails,
  'billingSetups' | 'weeklyBreakdown'
> &
  SolutionExtraProps

/** Recreates the solution structure used thoughout the solutions page */
export const transformSolution = (
  currentSolutionFromState: SolutionDetails,
  dateFocusFromState: $TSFixMe, // ? What is this?
  prettyOneOffOptions: OptionLookupFn,
  suspensionReasonOptions: $TSFixMe,
  prettySuspensionReason: OptionLookupFn,
  prettySuspensionCover: OptionLookupFn,
): TransformedSolution => {
  const currentSolution = currentSolutionFromState

  const prettyStartDateInclusive = currentSolution.startDateInclusive
    ? prettyCompactDateWithDow(currentSolution.startDateInclusive)
    : ''

  const prettyEndDateExclusive = currentSolution.endDateExclusive
    ? prettyCompactDateWithDow(currentSolution.endDateExclusive)
    : '∞'

  const prettyDuration =
    currentSolution.startDateInclusive && currentSolution.endDateExclusive
      ? `(${prettyDurationWeeks(
          currentSolution.startDateInclusive,
          currentSolution.endDateExclusive,
        )})`
      : ''

  const reasons = currentSolution.endingReasons || []
  if (currentSolution.endingReason) {
    reasons.push(currentSolution.endingReason)
  }

  const endDateChanged = {
    endingReasons: reasons,
    endingOutcome: currentSolution.endingOutcome || '',
    closureDetails: currentSolution.closureDetails || '',
  }

  const dateFocus = dateFocusFromState || ''
  const momentDateFocus = moment(dateFocus)
  const momentDateViewStart = momentDateFocus.subtract(
    TOTAL_DAYS_IN_VIEW / 2,
    'days',
  )
  const solutionPlanDescription = currentSolution.solutionPlanDescription || ''
  const { tariffId, tariffDescription } = currentSolution

  const mapProfessionalColor = {}

  const placementSlots = currentSolution.placementSlots || []
  const placementColorSelector =
    makePlacementColorSelector(mapProfessionalColor)
  const placementSlotsEdited = placementSlots.map((placementSlot) => {
    const placements = placementSlot.placements.map((placement) =>
      transformPlacement({
        placement,
        momentViewStart: momentDateViewStart,
        colorSelector: placementColorSelector,
        prettyOneOffOptions,
        prettySuspensionReason,
        prettySuspensionCover,
      }),
    )

    return {
      ...placementSlot,
      placements,
      id: placementSlot.slotId,
      visitingPlacements: (placementSlot as $TSFixMe)
        .visitingPlacements as VisitingPlacement[],
    }
  })

  const placements = placementSlotsEdited.flatMap((slot) => slot.placements)
  const placementsLookup = keyBy(placements, 'id')

  const cancellationPermissions = {
    allowsUnsafeCancellation:
      (currentSolution.cancellationPermissions &&
        currentSolution.cancellationPermissions.allowsUnsafeCancellation) ||
      false,
    cancellationLockedDown:
      (currentSolution.cancellationPermissions &&
        currentSolution.cancellationPermissions.cancellationLockedDown) ||
      false,
  }

  let colorIndex = 0

  const mapDefaultBreakdown = (defaultAmounts) => {
    colorIndex += 1

    const colors = solutionRateColors[colorIndex % solutionRateColors.length]
    return {
      ...defaultAmounts,
      colors,
    }
  }

  const weeklyBreakdown =
    currentSolution && currentSolution.weeklyBreakdown
      ? {
          ...currentSolution.weeklyBreakdown,

          weeklyAmounts: currentSolution.weeklyBreakdown.weeklyAmounts.map(
            (week, index) => {
              const newWeeklyAmount = {
                ...week,

                startDateInclusive: moment(
                  week.startDateInclusive,
                  'YYYY-MM-DD',
                ),
                endDateExclusive: moment(week.endDateExclusive, 'YYYY-MM-DD'),
                colors: solutionRateColors[index % solutionRateColors.length],
              }

              colorIndex = index
              return newWeeklyAmount as TransformedWeeklyAmount
            },
          ),
          // TODO: Type
          defaultAmounts: mapDefaultBreakdown(
            currentSolution.weeklyBreakdown.defaultAmounts,
          ),
        }
      : {}

  const billingSetups = transformBillingSetups(currentSolution)
  const solutionRates = transformSolutionRates(currentSolution)

  const solutionType = (
    currentSolution.solutionPlanId as unknown as string
  ).includes('SRV_VISITING_CARE')
    ? 'VISITING'
    : 'LIVE_IN'

  return {
    ...currentSolution,
    prettyStartDateInclusive,
    prettyEndDateExclusive,
    prettyDuration,
    endDateChanged,
    billingSetups,
    solutionRates,
    cancellationPermissions,
    solutionPlanDescription,
    weeklyBreakdown,
    tariffId,
    tariffDescription,
    placementSlots: placementSlotsEdited,
    placementsLookup,
    solutionType,
  } as TransformedSolution
}
