import type { ReactNode } from 'react'
import React from 'react'

import styled from 'styled-components'

interface Props {
  children: ReactNode
}

const StyledTimelinePanel = styled.div`
  display: flex;
  width: 100%;

  .timeline-gutter {
    width: 120px;
  }

  .timeline-main {
    flex: 1;
    position: relative;
  }
`

export const TimelinePanel = ({ children }: Props) => (
  <StyledTimelinePanel>
    <div className="timeline-gutter" />
    <div className="timeline-main">{children}</div>
    <div className="timeline-gutter" />
  </StyledTimelinePanel>
)
