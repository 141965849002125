// @flow

import { object, string, Type } from 'flow-validator'

// this is for types that are used in both care plan
// service clients and recipient service clients
// to avoid issues with circular references

export const recipientDocumentsType: Type<*> = object({
  documentId: string.optional(),
  documentMetadata: object({
    type: string,
    comments: string.optional(),
    documentTypeName: string.optional(),
  }).optional(),
  uploadedAt: string.optional(),
  uploadedBy: string.optional(),
})
