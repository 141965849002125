// @flow
import type { Saga } from 'redux-saga'
import { call, select, takeLatest } from 'redux-saga/effects'

import { GET_IDENTITY } from 'modules/pageIdAndIdentity'
import { addNamespace } from 'utils'
import { getAndSetFlow } from 'utils/services'

import { NAMESPACE, pageActions } from './actions'
import { pageSelectors } from './selectors'

function* fetchProfileIdentityFlow(): Generator<*, void, *> {
  const accountId = yield select(pageSelectors.getId)
  yield call(
    getAndSetFlow,
    `/et/accounts/${accountId}/identity`,
    pageActions.setIdentity,
  )
}

export function* accountSaga(): Saga<*> {
  yield takeLatest(
    addNamespace(NAMESPACE, GET_IDENTITY),
    fetchProfileIdentityFlow,
  )
}

export default accountSaga
