// @flow
import * as Sentry from '@sentry/react'
import { replace } from 'connected-react-router'
import type { Saga } from 'redux-saga'
import { take, call, put, select } from 'redux-saga/effects'

import {
  applicationError,
  applicationSuccess,
} from 'features/snackbar/snackbar'
import { CREATE } from 'routes/account/finance/transactionNew/actions'
import { pageSelectors } from 'routes/account/selectors'
import { describeServiceError, fetchFlow } from 'utils/services'

export function* postTransactionNew(data: Object): Generator<Object, void, *> {
  const acctId = yield select(pageSelectors.getId)
  const endpoint = `/et/accounts/${acctId}/invoices/manualTransaction`
  const method = 'POST'

  try {
    yield call(fetchFlow, endpoint, method, data)
    applicationSuccess({
      title: 'Transaction created',
      message: 'Transaction was created successfully.',
    })
    yield put(replace(`/accounts/${acctId}/finance`))
  } catch (error) {
    Sentry.captureException(error)
    applicationError(describeServiceError(error, 'Error with service call'))
  }
}

export function* watchTransactionNew(): Saga<*> {
  while (true) {
    const payload = yield take(CREATE)
    yield call(postTransactionNew, payload.data)
  }
}
