import React from 'react'

import { Form, RadioGroup } from 'formsy-react-components'
import PropTypes from 'prop-types'
import styled from 'styled-components'

import { ButtonSubmit, GenericButton } from 'components'
import { Section } from 'components/styledComponentsTranslatedClasses/Section'
import { SectionHeader } from 'components/styledComponentsTranslatedClasses/SectionHeader'
import { booleanOptions, setValueBoolean } from 'utils/groupOptions'

const Wrapper = styled.div`
  display: flex;
  justify-content: flex-end;
  margin-top: 20px;

  > :not(:last-child) {
    margin-right: 15px;
  }
`

const RadioWrapper = styled.div`
  div {
    display: flex;
    div {
      flex-grow: 1;
    }
  }
  label {
    text-align: right;
    margin-left: 15px;
    display: block;
  }
`

// eslint-disable-next-line no-restricted-properties
class AutomatedCommunicationsForm extends React.Component {
  submitForm = (data) => {
    this.props.updateAutomatedCommunications(
      data.automatedCommunicationsEnabled,
    )
    this.props.cancelEdit()
  }

  render() {
    const { automatedCommunicationsEnabled, cancelEdit } = this.props

    return (
      <Section>
        <Form onSubmit={this.submitForm}>
          <SectionHeader>
            <h3>Automated Communications (edit mode)</h3>
          </SectionHeader>
          <RadioWrapper>
            <RadioGroup
              layout="vertical"
              label="Automated Communications Enabled"
              name="automatedCommunicationsEnabled"
              type="inline"
              value={setValueBoolean(automatedCommunicationsEnabled)}
              options={booleanOptions}
            />
          </RadioWrapper>
          <Wrapper>
            <GenericButton text="Cancel" onClick={cancelEdit} quiet />
            <ButtonSubmit text="Save" />
          </Wrapper>
        </Form>
      </Section>
    )
  }
}

AutomatedCommunicationsForm.propTypes = {
  automatedCommunicationsEnabled: PropTypes.bool.isRequired,
  updateAutomatedCommunications: PropTypes.func.isRequired,
  cancelEdit: PropTypes.func.isRequired,
}

export default AutomatedCommunicationsForm
