// @flow
import React from 'react'

import { Link as RouterLink } from 'react-router-dom'
import styled from 'styled-components'

import { GenericButton } from 'components'

const Wrapper = styled.div`
  flex: 1 0 100%;
  margin-bottom: 30px;

  h3 {
    text-transform: capitalize;

    span {
      color: #73819c;
      font-size: 16px;
    }
  }

  ${({ editMode }) =>
    editMode &&
    `
    opacity: 0.1;
    pointer-events: none;
  `}

  .cs__header {
    align-items: center;
    border-bottom: 2px solid #19305e;
    display: flex;
    justify-content: space-between;
    margin: 20px 0 10px;
    padding-bottom: 10px;
  }

  .cs__link {
    color: #3e86fa;
    cursor: pointer;
  }

  .cs__entry {
    align-items: center;
    border-bottom: 1px solid #eaf0fc;
    display: flex;
    line-height: 32px;
    padding: 10px 0;
    white-space: pre-wrap;

    > span,
    > label,
    > div {
      width: 50%;
    }

    span,
    label,
    strong {
      color: #73819c;
    }

    input[type='text'],
    input[type='email'],
    input[type='date'],
    textarea {
      width: 100%;
    }
  }

  .cs__change {
    white-space: pre-wrap;
    word-wrap: anywhere;
  }

  .cs__delete {
    max-width: 120px;
  }
`

type Props = {|
  isEditModeOn: boolean,
  lastChangedBy: string,
  lastChangedAt: string,
  lastChangeDescription: string,
  changelogUrl: string,
  showClearAccountHistory: boolean,
  canClearAccountHistory: boolean,
  deleteAccountHistory: () => void,
|}

export const ChangeSummary = ({
  isEditModeOn,
  lastChangedBy,
  lastChangedAt,
  lastChangeDescription,
  changelogUrl,
  showClearAccountHistory,
  canClearAccountHistory,
  deleteAccountHistory,
}: Props) => (
  <Wrapper editMode={isEditModeOn}>
    <div className="cs__header">
      <RouterLink to={changelogUrl}>
        <h3 className="cs__link">Change History</h3>
      </RouterLink>
      {showClearAccountHistory && (
        <div className="cs__delete">
          <GenericButton
            text="Delete history"
            disabled={!canClearAccountHistory}
            onClick={deleteAccountHistory}
            loud
          />
        </div>
      )}
    </div>
    <div className="cs__entry">
      <span>Change by:</span>
      <div>{lastChangedBy}</div>
    </div>
    <div className="cs__entry">
      <span>Changed at:</span>
      <div>{lastChangedAt}</div>
    </div>
    <div className="cs__entry">
      <span>Change Description:</span>
      <div className="cs__change">{lastChangeDescription}</div>
    </div>
  </Wrapper>
)

export default ChangeSummary
