import { styled, Box } from '@mui/material'

/** Only works in containers whose height does not depend on their children */
export const CenterBox = styled(Box)({
  padding: '200px 20px',
  flex: '1',
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
})
