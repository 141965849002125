import React, { useCallback, useEffect, useMemo, useState } from 'react'

import type { SolutionDetails } from '@elder/et-facade-et'
import { useEtGetSolutionUsingGET as useGetSolution } from '@elder/et-facade-et'
import { Box, LinearProgress } from '@mui/material'
import { useQueryClient } from '@tanstack/react-query'
import moment from 'moment'
import { Link as RouterLink, useParams } from 'react-router-dom'
import { StringParam, useQueryParam } from 'use-query-params'

import { SummaryCard, SummaryCardActions } from 'components'
import { SolutionDateGuides } from 'components/solutions/SolutionDateGuides'
import {
  SolutionDateLabels,
  makeDateGuides,
} from 'components/solutions/SolutionDateLabels'
import { SolutionTabs } from 'components/solutions/SolutionTabs'
import { useGetSolutions } from 'components/solutions/SolutionTabsContainer'
import { TimelinePanel } from 'components/timelines/TimelinePanel'
import { useTimeline } from 'features/timeline/useTimeline'
import * as routes from 'routes'
import { formatLink } from 'utils/links'

import { AccountSolutionSummary } from './AccountSolutionSummary'
import { placementContext } from './contexts/placement'
import {
  SolutionContextProvider,
  useSolutionContext,
} from './contexts/solution'

interface Props {
  defocused: boolean
}

export const SolutionsSummary = ({ defocused }: Props) => {
  const { accountId } = useParams<{ accountId: string }>()
  const solutionsQuery = useGetSolutions(accountId)
  const solutions = solutionsQuery.data?.solutions
  const firstSolution = solutions?.[0]

  const [solutionId, setSolutionId] = useState<string>()
  useEffect(() => {
    if (!firstSolution) return
    if (solutionId) return
    setSolutionId(firstSolution.solutionId)
  }, [firstSolution, solutionId])

  const solutionQuery = useGetSolution(solutionId!, {
    query: { enabled: Boolean(solutionId) },
  })

  const currentSolution = solutionQuery.data
  const solutionDetailsUrl = solutionId
    ? formatLink(routes.solution, {
        accountId,
        solutionId,
      })
    : formatLink(routes.solutions, {
        accountId,
      })

  const queryClient = useQueryClient()
  const setSolutionData = useCallback(
    (solution: SolutionDetails) => {
      queryClient.setQueryData(solutionQuery.queryKey, solution)
    },
    [queryClient, solutionQuery.queryKey],
  )

  return (
    <SummaryCard
      heading="Solutions"
      defocused={defocused}
      actionChildren={
        <SummaryCardActions>
          <RouterLink to={solutionDetailsUrl}>Details</RouterLink>
        </SummaryCardActions>
      }
    >
      <Box pb={1}>
        <SolutionTabs
          solutions={solutions || []}
          solutionId={solutionId}
          setSolutionId={setSolutionId}
        />
      </Box>

      <div>
        {solutions?.length === 0 && (
          <div>
            <p>
              You do not appear to have any solutions yet, go to the{' '}
              <RouterLink to={solutionDetailsUrl}>
                solution details page
              </RouterLink>{' '}
              to create one!
            </p>
          </div>
        )}

        {solutionQuery.isLoading && <LinearProgress variant="indeterminate" />}

        {currentSolution && (
          <SolutionContextProvider
            solution={currentSolution}
            setSolutionData={setSolutionData}
          >
            <SolutionSummaryTimeline />
          </SolutionContextProvider>
        )}
      </div>
    </SummaryCard>
  )
}

const SolutionSummaryTimeline = () => {
  const { solution } = useSolutionContext()
  const placement = Object.values(solution.placementsLookup)[0]
  const [, setPlacementId] = useQueryParam('placementId', StringParam)
  const placementContextValue = useMemo(
    () => ({ placement, setPlacementId }),
    [placement, setPlacementId],
  )

  const { focus, goBackwardsInTime, goForwardsInTime, setFocus } = useTimeline(
    moment().startOf('day'),
  )

  const dateGuides = makeDateGuides(focus, solution)

  return (
    <placementContext.Provider value={placementContextValue}>
      <TimelinePanel>
        <SolutionDateGuides dateGuides={dateGuides} />

        <AccountSolutionSummary focus={focus} setFocus={setFocus} />

        <SolutionDateLabels
          dateGuides={dateGuides}
          goBackwardsInTime={goBackwardsInTime}
          goForwardsInTime={goForwardsInTime}
          setFocus={setFocus}
        />
      </TimelinePanel>
    </placementContext.Provider>
  )
}
