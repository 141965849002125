// @flow
import { connect } from 'react-redux'

import Account from './Account'
import { pageActions } from './actions'
import { pageSelectors } from './selectors'

const mapActionCreators = {
  getIdentity: pageActions.getIdentity,
  setId: (accountId) => pageActions.setId(accountId),
}

const mapStateToProps = (state) => ({
  account: {
    accountId: pageSelectors.getId(state),
  },
})

export default connect(mapStateToProps, mapActionCreators)(Account)
