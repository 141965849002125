// @flow
import type { Saga } from 'redux-saga'
import { fork } from 'redux-saga/effects'

import { watchDownloadCsv } from './watchDownloadCsv'

export function* allSagas(): Saga<*> {
  yield fork(watchDownloadCsv)
}

export default allSagas
