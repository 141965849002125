// @flow
import type { Node } from 'react'
// $FlowOptOut
import React, { useEffect, useState } from 'react'

import noScroll from 'no-scroll'
import styled, { css } from 'styled-components'

const Wrapper = styled.div`
  display: flex;
  position: fixed;
  top: 0;
  left: 0;
  padding: 32px;
  width: 100%;
  height: 100%;
  align-items: flex-start;
  justify-content: center;
  overflow: auto;
  transition: 0.2s;
  background-color: ${({ mount }) =>
    mount ? 'rgba(36, 40, 41, 0.75)' : 'unset'};
  z-index: ${({ takePriority }) => (takePriority ? 2100 : 2000)};
`

const PositionCenter = styled.div`
  margin: auto 0;
  transform: scale(0);
  transition: 0.2s;
  ${({ mount }) =>
    mount &&
    css`
      transform: scale(1);
    `};
`

type Props = {|
  +children: Node,
  +className?: string,
  +takePriority?: ?boolean,
|}

export const ModalWrapper = ({ children, className, takePriority }: Props) => {
  const [mount, setMount] = useState(false)
  useEffect(() => {
    noScroll.on()
    setTimeout(() => setMount(true), 10)
    return () => {
      setMount(false)
      noScroll.off()
    }
  }, [])

  return (
    <Wrapper className={className} mount={mount} takePriority={takePriority}>
      <PositionCenter mount={mount}>{children}</PositionCenter>
    </Wrapper>
  )
}
