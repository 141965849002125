// @flow
import Immutable from 'immutable'

import { SET_ROLES } from 'app/login/actions'

const initialState = Immutable.List()
export const rolesReducer = (
  state: Array<string> = initialState,
  action: Object,
) => {
  switch (action.type) {
    case SET_ROLES:
      return Immutable.fromJS(action.roles)
    default:
      return state
  }
}

export default rolesReducer
