// @flow
export const prettyMedicalTreatments = (treatment: string) => {
  if (!treatment) {
    return ''
  }

  switch (treatment) {
    case 'Stoma':
      return 'Stoma'
    case 'PEG':
      return 'PEG'
    case 'NONE':
      return 'None'
    default:
      return treatment
  }
}

export const prettyToileting = (toileting: string) => {
  if (!toileting) {
    return ''
  }

  switch (toileting) {
    case 'CATHETER':
      return 'Catheter'
    case 'PADS':
      return 'Pads'
    case 'URINARY_INCONTINENCE':
      return 'Urinary incontinence'
    case 'FAECAL_INCONTINENCE':
      return 'Faecal incontinence'
    case 'YES':
      return 'Yes'
    case 'NO_ASSISTANCE_REQUIRED':
      return 'No assistance required'
    default:
      return toileting
  }
}

export const prettyMobility = (mobility: string) => {
  if (!mobility) {
    return ''
  }

  switch (mobility) {
    case 'HOIST':
      return 'Hoist'
    case 'STAIRLIFT':
      return 'Stairlift'
    case 'WALKING_STICK':
      return 'Walking stick'
    case 'ZIMMER_FRAME':
      return 'Zimmer frame'
    case 'WHEELCHAIR':
      return 'Wheelchair'
    case 'NO_ASSISTANCE_REQUIRED':
      return 'No assistance required'
    default:
      return mobility
  }
}

export const prettyMedicationPrompting = (med: string) => {
  if (!med) {
    return ''
  }

  switch (med) {
    case 'YES':
      return 'Yes'
    case 'BLISTER_PACK':
      return 'Blister pack'
    case 'LOG_BOOK':
      return 'Log book'
    case 'NONE_REQUIRED':
      return 'None required'
    default:
      return med
  }
}

export const prettyDietaryRequirements = (req: string) => {
  if (!req) {
    return ''
  }

  switch (req) {
    case 'VEGETARIAN':
      return 'Vegetarian'
    case 'VEGAN':
      return 'Vegan'
    case 'HALAL':
      return 'Halal'
    case 'GLUTEN_FREE':
      return 'Gluten Free'
    case 'DAIRY_FREE':
      return 'Dairy Free'
    case 'DIABETIC':
      return 'Diabetic'
    case 'NONE':
      return 'None'
    default:
      return req
  }
}

export const prettyBillingCycle = (billingCycle: string) => {
  if (!billingCycle) {
    return ''
  }

  switch (billingCycle) {
    case 'NONE':
      return 'None'
    case 'WEEKLY':
      return 'Weekly'
    case 'MONTHLY':
      return 'Monthly'
    default:
      return billingCycle
  }
}

export const getPrettyName = (name: Object) =>
  [name.firstName, name.lastName].filter((value) => value).join(' ')
