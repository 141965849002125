// @flow
import React from 'react'

import { FormikInput, FormikTextArea } from '@elder/common'
import { LoadingButton } from '@mui/lab'
import { Button } from '@mui/material'
import { Formik } from 'formik'
import styled from 'styled-components'
import * as Yup from 'yup'

import type { Note, NoteRequestParams, Template } from 'features/notes/domain'

const Content = styled.div`
  margin-top: 16px;
`

const StyledSubmitButton = styled(LoadingButton)`
  width: 120px;
`

const Buttons = styled.div`
  display: flex;
  justify-content: flex-end;
  align-items: center;
  margin-top: 16px;
`

export type Props = {|
  +note: Note,
  +edit: (NoteRequestParams) => void,
  +cancel: () => void,
  +templates?: Array<Template>,
  +saving: boolean,
|}

export const EditNoteDialog = ({
  note,
  edit,
  templates = [],
  saving,
  cancel,
}: Props) => {
  const noteTemplateTitles = templates.map(({ label }) => label)

  return (
    <Formik
      validationSchema={Yup.object({
        title: Yup.string().required('Required.'),
        text: Yup.string().max(8192).required('Required.'),
      })}
      initialValues={note}
      onSubmit={({ title, text }) => {
        edit({ tags: note.tags, title, text })
      }}
      enableReinitialize
    >
      {({ errors, submitCount, handleSubmit }) => (
        <Content>
          <FormikInput
            disabled={noteTemplateTitles.includes(note.title)}
            name="title"
            label="Title"
            errorLabel={submitCount > 0 ? errors.title : null}
          />
          <FormikTextArea
            name="text"
            label="Text"
            errorLabel={submitCount > 0 ? errors.text : null}
          />
          <Buttons>
            <Button disabled={saving} onClick={cancel} variant="outlined">
              Cancel
            </Button>
            <StyledSubmitButton
              onClick={handleSubmit}
              disabled={saving}
              loading={saving}
              variant="contained"
            >
              Save
            </StyledSubmitButton>
          </Buttons>
        </Content>
      )}
    </Formik>
  )
}
