// @flow

import styled, { css } from 'styled-components'

export const Entry = styled.div`
  font-size: 14px;
  font-weight: 300;
  margin-bottom: 20px;
  h4 {
    font-size: 14px;
    margin-bottom: 5px;
    font-weight: 500;
  }

  ${({ indented }) =>
    indented &&
    css`
      margin-left: 35px;
      margin-right: 35px;
    `}

  ${({ hidden }) =>
    hidden &&
    css`
      display: none;
    `}
`
