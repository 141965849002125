// @flow
import { combineReducers } from 'redux-immutable'

import loginDetails from 'app/login/reducers'
import referenceData from 'app/referenceData/reducers'

import appState from './appState'
import { pathHistoryReducer } from './path'

export default combineReducers({
  appState,
  loginDetails,
  pathHistory: pathHistoryReducer,
  referenceData,
})
