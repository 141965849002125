import { put } from 'redux-saga/effects'

import { applicationError } from 'features/snackbar/snackbar'
import { describeServiceError } from 'utils/services'

const flatteningFieldValidationErrors = (nodeName, fieldValidationErrors) => {
  const flattenValErr = {}
  flattenValErr[nodeName] = {}

  // eslint-disable-next-line no-restricted-syntax
  for (const field in fieldValidationErrors) {
    if (Object.prototype.hasOwnProperty.call(fieldValidationErrors, field)) {
      const listErrors = fieldValidationErrors[field].errors.map(
        (errorInField) => errorInField,
      )
      const fieldBracket =
        field.indexOf('.') > -1
          ? `${field.replace('.', '[').replace(/\./g, '][')}]`
          : field
      flattenValErr[nodeName][fieldBracket] = listErrors
    }
  }

  return flattenValErr
}

const checkFieldExpected = (mapErrorFields, expectedFields) => {
  // eslint-disable-next-line no-restricted-syntax
  for (const errorField in mapErrorFields) {
    if (!Object.prototype.hasOwnProperty.call(expectedFields, errorField)) {
      return `The field ${errorField} is not part of the list of available fields`
    }
  }

  return null
}

export function* manageErrorWithFieldValidation(
  error,
  validationField,
  expectedFields,
  onValidationFail,
) {
  if (
    error.isElderError &&
    error.details &&
    error.details.validationDetails &&
    error.details.validationDetails.fieldValidationErrors
  ) {
    // this is a validation error
    const { fieldValidationErrors } = error.details.validationDetails
    const flattenedErrors = flatteningFieldValidationErrors(
      validationField,
      fieldValidationErrors,
    )
    const errorOnField = checkFieldExpected(
      flattenedErrors[validationField],
      expectedFields,
    )

    if (errorOnField) {
      applicationError(describeServiceError(error))
    }
    yield put(onValidationFail(flattenedErrors))
  } else {
    applicationError(describeServiceError(error))
  }
}
