// @flow
import { refDataEnum } from '@elder/common'
import {
  object,
  string,
  number,
  arrayOf,
  boolean,
  mapping,
} from 'flow-validator'

import type { FlowOfType } from 'elder/types'
import { enumFromObjectKeys } from 'elder/types'

import { appliedPlacementStatus } from 'domain/placement'
import { testimonialStatus } from 'domain/testimonials'

export const addRestrictionRequest = object({
  carerId: string,
  description: string.optional(),
  endDateInclusive: string.optional(),
  startDateInclusive: string,
  reason: string.optional(),
})

const bankDetails = object({
  sortCode: string.optional(),
  bankAccount: string.optional(),
  nationalInsuranceNumber: string.optional(),
})

const emergencyContact = object({
  name: string.optional(),
  relationship: string.optional(),
  phoneNumber: string.optional(),
})

const name = object({
  firstName: string.optional(),
  lastName: string.optional(),
  salutation: string.optional(),
})

const publishedCarerVideo = object({
  id: string,
  status: string,
  uploadUrl: string.optional(),
  url: string,
})

const basicInfo = object({
  address: object({
    address1: string.optional(),
    address2: string.optional(),
    city: string.optional(),
    coordinates: object({
      lat: number.optional(),
      long: number.optional(),
    }).optional(),
    country: string.optional(),
    postcode: string.optional(),
  }),
  carerVideoUrl: string.optional(),
  dateOfBirth: string.optional(),
  editedCarerVideoUrl: string.optional(),
  email: string.optional(),
  emergencyContact: emergencyContact.optional(),
  gender: string.optional(),
  hobbiesAndInterests: string.optional(),
  name: name.optional(),
  personalStatement: string.optional(),
  phone: string.optional(),
  publishedCarerVideo: publishedCarerVideo.optional(),
  videoRating: string.optional(),
  videoRatingReasons: arrayOf(string).optional(),
  workerInductionTrainingOn: string.optional(),
})

const carerDetails = object({
  dbsIssueDate: string.optional(),
  dbsClean: boolean.optional(),
  dbsUpdateService: boolean.optional(),
  driving: string.optional(),
  ethnicity: string.optional(),
  languageClarity: string.optional(),
  languageFluency: string.optional(),
  languages: arrayOf(string).optional(),
  nationality: string.optional(),
  onboardingLocation: string.optional(),
  pvgIssueDate: string.optional(),
  pvgClean: boolean.optional(),
  workWithGender: arrayOf(string).optional(),
  workWithPets: arrayOf(string).optional(),
  workWithSmokers: boolean.optional(),
})

const restriction = object({
  description: string.optional(),
  endDateInclusive: string.optional(),
  startDateInclusive: string,
  reason: string,
})

const review = object({
  reviewId: string.optional(),
  isDefault: boolean.optional(),
  profileId: string.optional(),
  profileName: string.optional(),
  description: string.optional(),
  prettyDate: string.optional(),
})

const skillsAndExpertise = object({
  careerStartDate: string.optional(),
  careExpertise: arrayOf(string).optional(),
  conditionExpertise: arrayOf(string).optional(),
})

const testimonial = object({
  testimonialId: string,
  solutionId: string,
  placementId: string,
  accountId: string,
  rating: number,
  review: string,
  startDate: string,
  endDate: string.optional(),
  reviewer: object({ profileId: string, name }),
  area: string,
  status: testimonialStatus,
})

export const carerMatchingBasicInfo = object({
  bankDetails,
  basicInfo,
  carerDetails,
  carerProfilePdfAvailable: boolean,
  changeSummary: object({
    lastChangedAt: string.optional(),
    lastChangedBy: string.optional(),
    lastChangeDescription: string.optional(),
  }),
  professionalId: string,
  restrictions: mapping(string, restriction).optional(),
  reviews: arrayOf(review),
  skillsAndExpertise,
  status: string,
  testimonials: arrayOf(testimonial),
  workerStatus: refDataEnum,
  accountManager: string.optional(),
})

export type CarerMatchingBasicInfo = FlowOfType<typeof carerMatchingBasicInfo>

export const deleteRestrictionRequest = object({
  carerId: string,
  restrictionId: string,
})

export const editRestrictionRequest = object({
  carerId: string,
  restrictionId: string,
  description: string.optional(),
  endDateInclusive: string.optional(),
  startDateInclusive: string,
  reason: string,
})

export const cancelAppliedPlacementRequest = object({
  carerId: string,
  matchRequestId: string,
})

export const getAppliedPlacementsRequest = object({
  carerId: string,
})

export const appliedPlacement = object({
  applicationContentApproved: boolean.optional(),
  customerAccountId: string,
  endDate: string.optional(),
  matchRequestId: string,
  placementAdId: string,
  placementId: string,
  postcodeSector: string.optional(),
  primaryContactName: object({
    firstName: string.optional(),
    lastName: string.optional(),
  }).optional(),
  rejectionReason: string.optional(),
  startDate: string,
  status: appliedPlacementStatus,
})

export type AppliedPlacement = FlowOfType<typeof appliedPlacement>

export const getAppliedPlacementsResponse = object({
  appliedPlacements: arrayOf(appliedPlacement),
})

const RESTRICTION_STATUS = {
  CURRENT: 'CURRENT',
  PAST: 'PAST',
  FUTURE: 'FUTURE',
}

const restrictionStatus = enumFromObjectKeys(RESTRICTION_STATUS)

export type RestrictionStatus = FlowOfType<typeof restrictionStatus>

export type Restriction = {|
  +description: string,
  +endDateInclusive: string,
  +startDateInclusive: string,
  +restrictionId: string,
  +reason: string,
  +prettyReason: string,
  +status: RestrictionStatus,
|}
