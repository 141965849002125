// @flow

/**
 * The functions in this file are deprecated because we should always use
 * `useSnackbar` from notistack instead. The functions below don't hook into the
 * SnackbarProvider configuration because they use the static `enqueueSnackbar`
 * function from notistack. This was necessary to replace the old
 * react-redux-toastr notifications that are still being used by the sagas. New
 * endpoint calls should be made through the hooks from @elder/et-facade-et instead of
 * sagas, and can use the `useSnackbar` hook.
 */

import { enqueueSnackbar } from 'notistack'

const anchorOrigin = {
  horizontal: 'left',
  vertical: 'bottom',
}

const TransitionProps = { direction: 'up' }

type Message = {|
  +title?: string,
  +message: string,
  +details?: string,
|}

const makeText = ({ title, message, details }: Message) => {
  const text = [title, message || details || 'unknown error']
    .filter((s) => s)
    .join(' -- ')
  return text
}

/** @deprecated See src/features/snackbar/snackbar..js for details */
export const applicationError = (message: Message) => {
  const text = makeText(message)
  enqueueSnackbar(text, { variant: 'error', anchorOrigin, TransitionProps })
}

/** @deprecated See src/features/snackbar/snackbar..js for details */
export const applicationWarning = (message: Message) => {
  const text = makeText(message)
  enqueueSnackbar(text, { variant: 'warning', anchorOrigin, TransitionProps })
}

/** @deprecated See src/features/snackbar/snackbar..js for details */
export const applicationSuccess = (message: Message) => {
  const text = makeText(message)
  enqueueSnackbar(text, { variant: 'success', anchorOrigin, TransitionProps })
}
