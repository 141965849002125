// @flow
import type { Node } from 'react'
import React from 'react'

import { Button, Link as MuiLink } from '@mui/material'
import { Link as RouterLink } from 'react-router-dom'

type Props = {|
  +label: Node,
  +to: string,
  +className?: string,
  +target?: string,
  +onClick?: () => void,
|}

type ButtonProps = {|
  +variant: 'contained' | 'outlined' | 'text' | 'link',
  ...Props,
|}

const LegacyLinkButton = ({ variant, label, ...props }: ButtonProps) => {
  const isLink = variant === 'link'
  const ButtonOrLink = isLink ? MuiLink : Button

  return (
    <ButtonOrLink
      component={RouterLink}
      {...(isLink ? {} : { variant })}
      {...props}
    >
      {label}
    </ButtonOrLink>
  )
}

/** @deprecated Use Mui's Buttons and react-router's Link directly */
export const PrimaryLink = ({ ...props }: Props) => (
  <LegacyLinkButton variant="contained" {...props} />
)

/** @deprecated Use Mui's Buttons and react-router's Link directly */
export const SecondaryLink = ({ ...props }: Props) => (
  <LegacyLinkButton variant="outlined" {...props} />
)

/** @deprecated Use Mui's Buttons and react-router's Link directly */
export const TertiaryLink = ({ ...props }: Props) => (
  <LegacyLinkButton variant="text" {...props} />
)

/** @deprecated Use Mui's Buttons and react-router's Link directly */
export const QuaternaryLink = ({ ...props }: Props) => (
  <LegacyLinkButton variant="link" {...props} />
)
