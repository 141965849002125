// @flow
import { Card } from '@mui/material'
import styled from 'styled-components'

import { CONTENT_WIDTH } from 'design'

export const CardWidthWrapper = styled(Card)`
  max-width: ${CONTENT_WIDTH};
  margin: 32px auto 0;
  overflow: visible;
`
