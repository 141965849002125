// @flow
import type { Saga } from 'redux-saga'
import { take, call, select, put } from 'redux-saga/effects'

import {
  applicationError,
  applicationSuccess,
} from 'features/snackbar/snackbar'
import { pageSelectors } from 'routes/account/selectors'
import {
  pageActions,
  UPDATE_PAYMENT_TYPE,
} from 'routes/account/summary/actions'
import { fetchFlow, describeServiceError } from 'utils/services'

export function* putPaymentType(data: Object): Generator<*, void, *> {
  const acctId = yield select(pageSelectors.getId)
  let endpoint = `/et/accounts/${acctId}/paymentDetails/`
  const method = 'PUT'

  switch (data.paymentType) {
    case 'CARD':
      endpoint = `${endpoint}card`
      break
    case 'BANK_TRANSFER':
      endpoint = `${endpoint}bankTransfer`
      break
    case 'STRIPE_BANK_TRANSFER':
      endpoint = `${endpoint}stripeBankTransfer`
      break
    case 'DIRECT_DEBIT':
      endpoint = `${endpoint}directDebit`
      break
    case 'DIRECT_DEBIT_MANDATE':
      endpoint = `${endpoint}directDebit/mandate`
      break
    case 'MANUAL_INVOICING':
      endpoint = `${endpoint}manualInvoicing`
      break
    default:
      return
  }

  try {
    const responseBody = yield call(fetchFlow, endpoint, method, data)
    yield put(pageActions.set(responseBody))
    applicationSuccess({
      title: 'Account updated',
      message: 'Bank and payment details were updated successfully.',
    })
  } catch (error) {
    applicationError(
      describeServiceError(error, `Error with service call ${endpoint}`),
    )
  }
}

export function* watchPaymentType(): Saga<*> {
  while (true) {
    const payload = yield take(UPDATE_PAYMENT_TYPE)
    yield call(putPaymentType, payload.data)
  }
}
