// @flow
import * as React from 'react'

import styled from 'styled-components'

const StyledHeading = styled.div`
  align-items: center;
  border-bottom: 2px solid #19305e;
  display: flex;
  justify-content: space-between;
  margin: 40px 0 25px;
  padding-bottom: 10px;
`

export const Heading = ({
  heading,
  children,
}: {|
  heading: string,
  children?: React.Node,
|}) => (
  <StyledHeading>
    <h3>{heading}</h3>
    {children}
  </StyledHeading>
)
