// @flow
import { connect } from 'react-redux'

import { getCanEdit } from 'app'
import {
  getPetsOptions,
  getDrivingRequirementsOptions,
  getGenderPreferenceOptions,
  getcarerFoodOptions,
  getPreferredLanguageFluencyOptions,
  getPreferredCarerExperienceOptions,
  getEthnicitiesAtRiskOptions,
} from 'app/referenceData'

import { AccountEnvironment } from './AccountEnvironment'
import { pageActions } from './actions'
import { getEnvironment } from './selectors'

const mapActionCreators = {
  editSection: pageActions.editSection,
  cancelEdit: pageActions.cancelEdit,
  getEnvironment: pageActions.get,
}

const mapStateToProps = (state) => ({
  environment: getEnvironment(state),
  canEdit: getCanEdit(state),
  options: {
    pets: getPetsOptions(state),
    driving: getDrivingRequirementsOptions(state),
    genderPreference: getGenderPreferenceOptions(state),
    carerFood: getcarerFoodOptions(state),
    languageFluency: getPreferredLanguageFluencyOptions(state),
    carerExperience: getPreferredCarerExperienceOptions(state),
    ethnicitiesAtRisk: getEthnicitiesAtRiskOptions(state),
  },
})

export default connect(mapStateToProps, mapActionCreators)(AccountEnvironment)
