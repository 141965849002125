// @flow
import React from 'react'

import { colors, Paragraph } from '@elder/common'
import { Circle, EditOutlined } from '@mui/icons-material'
import { IconButton, Chip } from '@mui/material'
import styled from 'styled-components'

import { TertiaryLink } from 'components/legacyLinkButton'
import type { Contact } from 'domain/contacts'
import { profiles } from 'routes'

const HeaderWrapper = styled.div`
  padding: 8px 0;
  border-bottom: 2px solid;
  display: flex;
  justify-content: space-between;
  align-items: center;
`
const List = styled.ul`
  list-style: none;
  margin: 0;
  padding: 0;
`
const ListItem = styled.li`
  display: flex;
  padding: 16px 0;
  border-bottom: 1px solid ${colors.blue600}30;
`
const LiElement = styled.span`
  flex: 1;
  display: flex;
  flex-direction: column;
`
const StyledTertiaryLink = styled(TertiaryLink)`
  margin: 0;
  padding: 0;
  font-size: 18px;
  font-weight: 700;
  font-size: 18px;
  line-height: 24px;
  color: ${colors.blue600};
`
const RolesColumn = styled.span`
  flex: 1;
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-end;
  gap: 8px;
`

type RoleInfo = {| +label: string, +color: string |}

const makeGetRoleDisplayInfo =
  (displayContactRole: (string) => string) =>
  (role): RoleInfo => {
    // NOTE: Ideally these should come from reference data, but worst case is
    // we don't get fancy colours, no risk of breaking functionality
    const getColor = (value) => {
      if (value === 'PRIMARY_POINT_OF_CONTACT') {
        return colors.blue600
      }
      if (value === 'PRIMARY_BILLING_CONTACT') {
        return colors.blue600
      }
      return colors.lightSmoke
    }

    return {
      color: getColor(role),
      label: displayContactRole(role),
    }
  }

type Props = {|
  +contacts: Array<Contact>,
  +openModal: () => void,
  +canEdit: boolean,
  +displayContactRole: (string) => string,
|}

export const UsersTable = ({
  contacts,
  openModal,
  canEdit,
  displayContactRole,
}: Props) => {
  const getRoleDisplayInfo = makeGetRoleDisplayInfo(displayContactRole)
  return (
    <div>
      <HeaderWrapper>
        <h3>Contacts</h3>
        {canEdit && (
          <IconButton onClick={openModal} title="Edit Contacts" color="primary">
            <EditOutlined
              sx={{
                fontSize: '24px',
              }}
            />
          </IconButton>
        )}
      </HeaderWrapper>
      <List>
        {contacts.map(
          ({
            relationship,
            email,
            phoneNumber,
            profile,
            roles: assignedRoles,
          }) => (
            <ListItem key={profile.id}>
              <LiElement>
                <StyledTertiaryLink
                  to={`${profiles}/${profile.id}`}
                  label={profile.text}
                />
                <Paragraph level="p2" label={relationship} />
              </LiElement>
              <LiElement>
                <Paragraph level="p2" label={email} />
                <Paragraph level="p2" label={phoneNumber} />
              </LiElement>
              <RolesColumn>
                {assignedRoles.map((role) => (
                  <Chip
                    label={getRoleDisplayInfo(role).label}
                    icon={<Circle />}
                    sx={{
                      '.MuiChip-icon': {
                        color: getRoleDisplayInfo(role).color,
                      },
                    }}
                    key={`${profile.id}_${role}`}
                  />
                ))}
              </RolesColumn>
            </ListItem>
          ),
        )}
      </List>
    </div>
  )
}
