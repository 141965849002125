// @flow
import React from 'react'

import { CloseOutlined, ArrowUpwardOutlined } from '@mui/icons-material'
import { Stack, IconButton } from '@mui/material'

import { ExecutiveCard } from 'features/accountInfo/ExecutivesHierarchy'
import type { ElderUser } from 'features/elderUsers/domain'
import { getPrettyName } from 'utils/accountDecorators'

type Props = {|
  +ae: ElderUser,
  +onMoveUp?: () => void,
  +onClose?: () => void,
  +isFirst: boolean,
|}

export const AccountExecutiveSelectionList = ({
  ae,
  onMoveUp,
  onClose,
  isFirst,
}: Props) => (
  <ExecutiveCard executive={ae}>
    <Stack direction="row" alignSelf="flex-end">
      {onClose ? (
        <IconButton
          color="inherit"
          onClick={onClose}
          aria-label={`Unassign ${getPrettyName(ae)} from this account`}
        >
          <CloseOutlined />
        </IconButton>
      ) : null}
      {onMoveUp ? (
        <IconButton
          color="inherit"
          onClick={onMoveUp}
          disabled={isFirst}
          aria-label={`Move ${getPrettyName(ae)} up`}
        >
          <ArrowUpwardOutlined />
        </IconButton>
      ) : null}
    </Stack>
  </ExecutiveCard>
)
