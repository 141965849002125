// @flow
import type { Saga } from 'redux-saga'
import { fork } from 'redux-saga/effects'

import { watchProfessionalNew } from './watchProfessionalNew'

export function* watchProfessionalNewFlow(): Saga<*> {
  yield fork(watchProfessionalNew)
}

export default watchProfessionalNewFlow
