// @flow
/* eslint-disable flowtype/no-weak-types */
import { createSelector } from 'reselect'

import { makeSelectors } from 'modules/pageEdit'
import { getPrettyName } from 'utils/accountDecorators'
import { prettyCompactDateTime } from 'utils/dateDecorators'
import { prettyAddressArray } from 'utils/decorators'

import { NAMESPACE } from './actions'

const getProfileState = (state) => state.get(NAMESPACE)

export const pageSelectors = makeSelectors((state) =>
  getProfileState(state).get('profile'),
)
const getProfileValidationError = (state) =>
  getProfileState(state).get('profileValidationError')

export const getJsProfileSummary = createSelector(
  [pageSelectors.get],
  (profileSummary) => profileSummary.toJS(),
)

const prettyPhoneType = (phoneType) => {
  switch (phoneType) {
    case 'UNKNOWN':
      return 'Unknown'
    case 'MOBILE':
      return 'Mobile'
    case 'LANDLINE':
      return 'Landline'
    case 'OFFICE':
      return 'Office'
    case 'OTHER':
      return 'Other'
    default:
      return phoneType
  }
}

const prettyPhoneNumbers = (phoneNumbers) => {
  if (!phoneNumbers) {
    return []
  }

  return phoneNumbers.map(
    (phoneNumber) =>
      `${phoneNumber.number} (${prettyPhoneType(phoneNumber.type)})`,
  )
}

export const getPrettyProfileSummary = createSelector(
  [getJsProfileSummary],
  (profileSummary) => {
    const contactInfo = profileSummary.contactInfo || {}
    const name = contactInfo.name || {}
    const address = contactInfo.address || {}
    const changelog = profileSummary.changelogSummary || {}

    return {
      status: profileSummary.status,
      firstName: name.firstName,
      lastName: name.lastName,
      fullName: getPrettyName(name),
      company: contactInfo.companyName,
      email: contactInfo.emailAddress,
      phoneNumbers: contactInfo.phoneNumbers || [],
      prettyPhoneNumbers: prettyPhoneNumbers(contactInfo.phoneNumbers),
      prettyAddress: prettyAddressArray(
        address.address1,
        address.address2,
        address.city,
        address.postcode,
      ),
      address,
      accounts: profileSummary.accountLinks || [],
      changelog: {
        ...changelog,
        lastChangedAt: prettyCompactDateTime(changelog.lastChangedAt),
      },
      resetPasswordEmailStatus: profileSummary.resetPasswordEmailStatus,
    }
  },
)

export const getJsProfileValidationError = createSelector(
  [getProfileValidationError],
  (validationErrors) => ({
    overview: validationErrors.get('overview').toJS(),
  }),
)

export const getIsExpanded = (state: Object) =>
  getProfileState(state).get('setIsExpanded')
