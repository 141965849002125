// @flow
import { makeActions } from 'modules/pageEdit'
import { addNamespace } from 'utils'

export const NAMESPACE = 'ACCOUNT_ENVIRONMENT'
export const pageActions = makeActions(NAMESPACE)

export const UPDATE_ACCT_ENV = addNamespace(NAMESPACE, 'UPDATE_ACCT_ENV')
export const UPDATE_ACCT_ENV_VALIDATION_FAIL = addNamespace(
  NAMESPACE,
  'UPDATE_ACCT_ENV_VALIDATION_FAIL',
)

export const updateAccountEnvironment = (data: Object) => ({
  type: UPDATE_ACCT_ENV,
  data,
})

export const updateAccountEnvironmentValidationFail = (error: Object) => ({
  type: UPDATE_ACCT_ENV_VALIDATION_FAIL,
  error,
})
