// @flow
export const permissionDeniedError = {
  title: 'Permission Denied',
  message: 'Please request access to ET3 from the product team.',
}

export const processAuth0Error = (auth0Error: any) => ({
  title: auth0Error.error,
  message: auth0Error.error_description,
  details: auth0Error,
})
